import Immutable from 'immutable'
import {
  FETCH_SESSION_REQUEST,
  FETCH_SESSION_SUCCESS,
  FETCH_SESSION_FAILURE,
  FETCH_SESSION_STATE_SUCCESS,
  FETCH_SESSION_STATE_FAILURE,
  FETCH_SESSION_SCOPES_REQUEST,
  FETCH_SESSION_SCOPES_SUCCESS,
  FETCH_SESSION_SCOPES_FAILURE,
  UPDATE_SESSION_SUCCESS,
  SET_NOTIFICATION,
  RESET_NOTIFICATION
} from './constants'

// The initial state of the App
const initialState = Immutable.fromJS({
  session: null,
  state: {},
  loading: false,
  scopes: Immutable.List([]),
  scopesLoading: false,
  notification: {}
})

function appReducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_SESSION_REQUEST:
      return state.set('loading', true)

    case FETCH_SESSION_SUCCESS:
      return state.set('session', action.payload.session)
        .set('loading', false)

    case FETCH_SESSION_FAILURE:
      return state.set('session', null).set('loading', false)

    case FETCH_SESSION_STATE_SUCCESS:
      return state.set('state', action.payload.state)

    case FETCH_SESSION_STATE_FAILURE:
      return state.set('state', null)

    case FETCH_SESSION_SCOPES_REQUEST:
      return state.set('scopesLoading', true)

    case FETCH_SESSION_SCOPES_SUCCESS:
      return state.set('scopes', action.payload.scopes).set('scopesLoading', false)

    case FETCH_SESSION_SCOPES_FAILURE:
      return state.set('scopes', Immutable.List([])).set('scopesLoading', false)

    // Update session
    case UPDATE_SESSION_SUCCESS:
      return state.set('session', action.payload.session)

    // Set Notification
    case SET_NOTIFICATION:
      return state.set('notification', action.notification)

    case RESET_NOTIFICATION:
      return state.set('notification', null)

    default:
      return state
  }
}

export default appReducer
