import React from 'react'
import PropTypes from 'prop-types'
import MDSpinner from 'react-md-spinner'
import styled from 'styled-components'
import { space, width, textAlign, flex, height } from 'styled-system'

const Wrapper = styled.div`
  align-items: center;
  justify-content: center;
  display: flex;
  z-index: 9;
  pointer-events: none;

  > div {
    display: flex;
    padding: ${props => props.padding};
  }

  ${space}
  ${width}
  ${height}
  ${textAlign}
  ${flex}
`

const Loader = ({
  size = 50,
  padding = '5rem',
  singleColor,
  ...rest
}) => (
  <Wrapper padding={padding} {...rest}>
    <div>
      <MDSpinner size={size} singleColor={singleColor} />
    </div>
  </Wrapper>
)

Loader.propTypes = {
  size: PropTypes.number,
  padding: PropTypes.any,
  singleColor: PropTypes.string
}

export default Loader
