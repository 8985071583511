/**
*
* Sequence Stats Component
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import {
  STATS_TOTAL,
  STATS_ONGOING,
  STATS_MESSAGED,
  STATS_OPEN,
  STATS_CLICKED,
  STATS_REPLIED,
  // STATS_INTERESTED,
  STATS_BOOKED,
  STATS_BOUNCED,
  STATS_ATTENTION
} from 'containers/Sequence/constants'

import Wrapper from './Wrapper'
import StatBox from './StatBox'

const Divider = styled.div`
  background: transparent;
  width: 1rem;
`

class Stats extends Component {
  selected (statFilter) {
    const filter = this.props.filter

    if (!filter) {
      return false
    }

    return filter.every((val) => {
      return statFilter.includes(val)
    }) && filter.length === statFilter.length
  }

  render () {
    const loading = this.props.statsLoading

    const filter = this.props.filter
    const stats = this.props.stats || new Map()

    let viewed = '-'
    let viewedCount
    let visited = '-'
    let visitedCount
    let replied = '-'
    let repliedCount
    // let interested
    let booked
    let bookedCount
    let bounced = '-'
    let error = '-'
    let ongoing

    let total = stats.get('total') || '-'
    const messaged = stats.get('messaged')

    if (messaged) {
      viewed = Math.round((stats.get('viewed') / messaged) * 100)
      viewedCount = stats.get('viewed')

      visited = Math.round((stats.get('visited') / messaged) * 100)
      visitedCount = stats.get('visited')

      replied = (stats.get('replied') / messaged) * 100
      repliedCount = stats.get('replied')

      booked = (stats.get('booked') / messaged) * 100
      bookedCount = stats.get('booked')

      // interested = (stats.get('interested') / messaged) * 100
    }

    if (total) {
      ongoing = stats.get('ongoing')
      bounced = stats.get('bounced')
      error = stats.get('error')
    } else {
      total = '-'
    }

    return (
      <Wrapper>
        <StatBox
          label='all&nbsp;contacts'
          value={total}
          loading={loading}
          type={STATS_TOTAL}
          selected={!filter || filter.length === 0}
          onClick={() => {
            this.props.filterContacts(null)
          }}
        />
        <Divider />
        <StatBox
          label='ongoing'
          value={ongoing}
          loading={loading}
          type={STATS_ONGOING}
          selected={this.selected(['ongoing'])}
          onClick={() => {
            this.props.filterContacts(['ongoing'])
          }}
        />
        <Divider />
        <StatBox
          label='messaged'
          value={messaged}
          loading={loading}
          type={STATS_MESSAGED}
          selected={this.selected(['messaged', '!bounced'])}
          onClick={() => {
            this.props.filterContacts(['messaged', '!bounced'])
          }}
        />
        <Divider />
        <StatBox
          label='opened'
          value={viewed}
          loading={loading}
          tooltip={messaged ? `${viewedCount} of ${messaged}` : null}
          type={STATS_OPEN}
          selected={this.selected(['opened', '!replied'])}
          percentage
          onClick={() => {
            this.props.filterContacts(['opened', '!replied'])
          }}
        />
        <Divider />
        {visitedCount > 0 &&
          <>
            <StatBox
              label='clicked'
              value={visited}
              loading={loading}
              tooltip={messaged ? `${visitedCount} of ${messaged}` : null}
              type={STATS_CLICKED}
              selected={this.selected(['visited', '!replied'])}
              percentage
              onClick={() => {
                this.props.filterContacts(['visited', '!replied'])
              }}
            />
            <Divider />
          </>}
        <StatBox
          label='replied'
          value={replied}
          loading={loading}
          tooltip={messaged ? `${repliedCount} of ${messaged}` : null}
          type={STATS_REPLIED}
          selected={this.selected(['replied'])}
          percentage
          decimalScale={1}
          onClick={() => {
            this.props.filterContacts(['replied'])
          }}
        />
        {stats.get('booked') > 0 && <Divider />}
        {stats.get('booked') > 0 &&
          <StatBox
            label='booked'
            value={booked}
            loading={loading}
            tooltip={`${bookedCount} of ${messaged}`}
            type={STATS_BOOKED}
            selected={this.selected(['booked'])}
            percentage
            decimalScale={1}
            onClick={() => {
              this.props.filterContacts(['booked'])
            }}
          />}
        <Divider />
        <StatBox
          label='bounced'
          value={bounced}
          loading={loading}
          type={STATS_BOUNCED}
          selected={this.selected(['bounced'])}
          onClick={() => {
            this.props.filterContacts(['bounced'])
          }}
        />
        <Divider />
        <StatBox
          label='need&nbsp;attention'
          value={error}
          loading={loading}
          type={STATS_ATTENTION}
          selected={this.selected(['all_errors'])}
          onClick={() => {
            this.props.filterContacts(['all_errors'])
          }}
        />
      </Wrapper>
    )
  }
}

Stats.propTypes = {
  stats: PropTypes.object,
  filter: PropTypes.array,
  filterContacts: PropTypes.func,
  loading: PropTypes.bool,
  statsLoading: PropTypes.bool
}

export default Stats
