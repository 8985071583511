/**
 * The Dashboard state selectors
 */

import Immutable from 'immutable'
import { createSelector } from 'reselect'

const selectDashboardDomain = () => state => state.get('dashboard')

const selectNewSequenceDomain = () => state => state.get('newSequence')

const selectLoading = () => createSelector(
  selectDashboardDomain(),
  dashboardState => dashboardState.get('loading')
)

const selectSequences = () => createSelector(
  selectDashboardDomain(),
  dashboardState => dashboardState.get('sequences')
)

const selectSequencesProper = () => createSelector(
  selectDashboardDomain(),
  dashboardState => {
    return Immutable.Map({
      data: dashboardState.get('sequences'),
      loading: dashboardState.get('loading'),
      error: null
    })
  }
)

const selectArchivedSequences = () => createSelector(
  selectDashboardDomain(),
  dashboardState => dashboardState.get('archivedSequences')
)

const selectSequencesStats = () => createSelector(
  selectDashboardDomain(),
  dashboardState => dashboardState.get('sequencesStats')
)

const selectSequencesStatus = () => createSelector(
  selectDashboardDomain(),
  dashboardState => dashboardState.get('sequencesStatus')
)

const selectStats = () => createSelector(
  selectDashboardDomain(),
  dashboardState => dashboardState.get('stats')
)

const selectTotal = () => createSelector(
  selectDashboardDomain(),
  dashboardState => dashboardState.get('total')
)

const selectError = () => createSelector(
  selectDashboardDomain(),
  dashboardState => dashboardState.get('error')
)

const selectReports = () => createSelector(
  selectDashboardDomain(),
  sequenceState => sequenceState.get('reports')
)

const selectSchedule = () => createSelector(
  selectDashboardDomain(),
  sequenceState => sequenceState.get('schedule')
)

const selectMessageCounts = () => createSelector(
  selectDashboardDomain(),
  sequenceState => sequenceState.get('messageCounts')
)

const selectNewSequence = () => createSelector(
  selectDashboardDomain(),
  selectNewSequenceDomain()
)

const selectSequencesCsvExport = () => createSelector(
  selectDashboardDomain(),
  sequenceState => sequenceState.get('sequencesCsvExport')
)

export {
  selectLoading,
  selectSequences,
  selectSequencesProper,
  selectArchivedSequences,
  selectError,
  selectTotal,
  selectStats,
  selectSequencesStats,
  selectNewSequence,
  selectReports,
  selectSchedule,
  selectSequencesStatus,
  selectMessageCounts,
  selectSequencesCsvExport
}
