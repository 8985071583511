/**
 * The global state selectors
 */

import { createSelector } from 'reselect'

const selectGlobal = () => state => state.get('global')

const selectLoading = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('loading')
)

const selectSession = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('session')
)

const selectScopes = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('scopes')
)

const selectState = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('state')
)

const selectSuccess = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('success')
)

const selectError = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('error')
)

const selectNotification = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('notification')
)

const selectTheme = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('theme')
)

const selectScopesLoading = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('scopesLoading')
)

const makeSelectLocationState = () => {
  let prevRoutingState
  let prevRoutingStateJS

  return (state) => {
    const routingState = state.get('route') // or state.route

    if (!routingState.equals(prevRoutingState)) {
      prevRoutingState = routingState
      prevRoutingStateJS = routingState.toJS()
    }

    return prevRoutingStateJS
  }
}

export {
  makeSelectLocationState,
  selectTheme,
  selectError,
  selectSuccess,
  selectSession,
  selectScopes,
  selectScopesLoading,
  selectState,
  selectLoading,
  selectNotification
}
