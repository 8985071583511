export const FETCH_TEAM_REPORTS_REQUEST = 'Reports/FETCH_TEAM_REPORTS_REQUEST'
export const FETCH_TEAM_REPORTS_SUCCESS = 'Reports/FETCH_TEAM_REPORTS_SUCCESS'
export const FETCH_TEAM_REPORTS_FAILURE = 'Reports/FETCH_TEAM_REPORTS_FAILURE'

export const FETCH_TEAM_REPORTS_CONTRAST_PERIOD_REQUEST = 'Reports/FETCH_TEAM_REPORTS_CONTRAST_PERIOD_REQUEST'
export const FETCH_TEAM_REPORTS_CONTRAST_PERIOD_SUCCESS = 'Reports/FETCH_TEAM_REPORTS_CONTRAST_PERIOD_SUCCESS'
export const FETCH_TEAM_REPORTS_CONTRAST_PERIOD_FAILURE = 'Reports/FETCH_TEAM_REPORTS_CONTRAST_PERIOD_FAILURE'

export const FETCH_USER_REPORTS_REQUEST = 'Reports/FETCH_USER_REPORTS_REQUEST'
export const FETCH_USER_REPORTS_SUCCESS = 'Reports/FETCH_USER_REPORTS_SUCCESS'
export const FETCH_USER_REPORTS_FAILURE = 'Reports/FETCH_USER_REPORTS_FAILURE'

export const FETCH_SEQUENCE_REPORTS_REQUEST = 'Reports/FETCH_SEQUENCE_REPORTS_REQUEST'
export const FETCH_SEQUENCE_REPORTS_SUCCESS = 'Reports/FETCH_SEQUENCE_REPORTS_SUCCESS'
export const FETCH_SEQUENCE_REPORTS_FAILURE = 'Reports/FETCH_SEQUENCE_REPORTS_FAILURE'

export const FETCH_MY_REPORTS_REQUEST = 'Reports/FETCH_MY_REPORTS_REQUEST'
export const FETCH_MY_REPORTS_SUCCESS = 'Reports/FETCH_MY_REPORTS_SUCCESS'
export const FETCH_MY_REPORTS_FAILURE = 'Reports/FETCH_MY_REPORTS_FAILURE'

export const FETCH_BOOKED_REPORTS_REQUEST = 'Reports/FETCH_BOOKED_REPORTS_REQUEST'
export const FETCH_BOOKED_REPORTS_SUCCESS = 'Reports/FETCH_BOOKED_REPORTS_SUCCESS'
export const FETCH_BOOKED_REPORTS_FAILURE = 'Reports/FETCH_BOOKED_REPORTS_FAILURE'

export const FETCH_REPLIED_REPORTS_REQUEST = 'Reports/FETCH_REPLIED_REPORTS_REQUEST'
export const FETCH_REPLIED_REPORTS_SUCCESS = 'Reports/FETCH_REPLIED_REPORTS_SUCCESS'
export const FETCH_REPLIED_REPORTS_FAILURE = 'Reports/FETCH_REPLIED_REPORTS_FAILURE'

export const FETCH_CONTACTS_REPORTS_REQUEST = 'Reports/FETCH_CONTACTS_REPORTS_REQUEST'
export const FETCH_CONTACTS_REPORTS_SUCCESS = 'Reports/FETCH_CONTACTS_REPORTS_SUCCESS'
export const FETCH_CONTACTS_REPORTS_FAILURE = 'Reports/FETCH_CONTACTS_REPORTS_FAILURE'

export const FETCH_MESSAGED_REPORTS_REQUEST = 'Reports/FETCH_MESSAGED_REPORTS_REQUEST'
export const FETCH_MESSAGED_REPORTS_SUCCESS = 'Reports/FETCH_MESSAGED_REPORTS_SUCCESS'
export const FETCH_MESSAGED_REPORTS_FAILURE = 'Reports/FETCH_MESSAGED_REPORTS_FAILURE'

export const EXPORT_REPORT_CSV_REQUEST = 'Reports/EXPORT_REPORTS_CSV_REQUEST'
export const EXPORT_REPORT_CSV_SUCCESS = 'Reports/EXPORT_REPORTS_CSV_SUCCESS'
export const EXPORT_REPORT_CSV_FAILURE = 'Reports/EXPORT_REPORTS_CSV_FAILURE'

export const FETCH_BOUNCE_RATE_REPORT_REQUEST = 'Reports/FETCH_BOUNCE_RATE_REPORT_REQUEST'
export const FETCH_BOUNCE_RATE_REPORT_SUCCESS = 'Reports/FETCH_BOUNCE_RATE_REPORT_SUCCESS'
export const FETCH_BOUNCE_RATE_REPORT_FAILURE = 'Reports/FETCH_BOUNCE_RATE_REPORT_FAILURE'

export const FETCH_REPLY_HOURS_REQUEST = 'Reports/FETCH_REPLY_HOURS_REQUEST'
export const FETCH_REPLY_HOURS_SUCCESS = 'Reports/FETCH_REPLY_HOURS_SUCCESS'
export const FETCH_REPLY_HOURS_FAILURE = 'Reports/FETCH_REPLY_HOURS_FAILURE'

export const FETCH_OPEN_HOURS_REQUEST = 'Reports/FETCH_OPEN_HOURS_REQUEST'
export const FETCH_OPEN_HOURS_SUCCESS = 'Reports/FETCH_OPEN_HOURS_SUCCESS'
export const FETCH_OPEN_HOURS_FAILURE = 'Reports/FETCH_OPEN_HOURS_FAILURE'

export const FETCH_CONTACT_TASK_REPORTS_REQUEST = 'Reports/FETCH_CONTACT_TASK_REPORTS_REQUEST'
export const FETCH_CONTACT_TASK_REPORTS_SUCCESS = 'Reports/FETCH_CONTACT_TASK_REPORTS_SUCCESS'
export const FETCH_CONTACT_TASK_REPORTS_FAILURE = 'Reports/FETCH_CONTACT_TASK_REPORTS_FAILURE'

export const FETCH_LEADERBOARD_REQUEST = 'Reports/FETCH_LEADERBOARD_REQUEST'
export const FETCH_LEADERBOARD_SUCCESS = 'Reports/FETCH_LEADERBOARD_SUCCESS'
export const FETCH_LEADERBOARD_FAILURE = 'Reports/FETCH_LEADERBOARD_FAILURE'

export const FETCH_LEADERBOARD_CONTRAST_REQUEST = 'Reports/FETCH_LEADERBOARD_CONTRAST_REQUEST'
export const FETCH_LEADERBOARD_CONTRAST_SUCCESS = 'Reports/FETCH_LEADERBOARD_CONTRAST_SUCCESS'
export const FETCH_LEADERBOARD_CONTRAST_FAILURE = 'Reports/FETCH_LEADERBOARD_CONTRAST_FAILURE'

export const FETCH_LEADERBOARD_GROUPS_REQUEST = 'Reports/FETCH_LEADERBOARD_GROUPS_REQUEST'
export const FETCH_LEADERBOARD_GROUPS_SUCCESS = 'Reports/FETCH_LEADERBOARD_GROUPS_SUCCESS'
export const FETCH_LEADERBOARD_GROUPS_FAILURE = 'Reports/FETCH_LEADERBOARD_GROUPS_FAILURE'

export const FETCH_LEADERBOARD_GROUPS_CONTRAST_REQUEST = 'Reports/FETCH_LEADERBOARD_GROUPS_CONTRAST_REQUEST'
export const FETCH_LEADERBOARD_GROUPS_CONTRAST_SUCCESS = 'Reports/FETCH_LEADERBOARD_GROUPS_CONTRAST_SUCCESS'
export const FETCH_LEADERBOARD_GROUPS_CONTRAST_FAILURE = 'Reports/FETCH_LEADERBOARD_GROUPS_CONTRAST_FAILURE'

export const FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST = 'Reports/FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST'
export const FETCH_PERSONALIZED_MESSAGES_REPORTS_SUCCESS = 'Reports/FETCH_PERSONALIZED_MESSAGES_REPORTS_SUCCESS'
export const FETCH_PERSONALIZED_MESSAGES_REPORTS_FAILURE = 'Reports/FETCH_PERSONALIZED_MESSAGES_REPORTS_FAILURE'

export const FETCH_STEP_PERFORMANCE_REQUEST = 'Reports/FETCH_STEP_PERFORMANCE_REQUEST'
export const FETCH_STEP_PERFORMANCE_SUCCESS = 'Reports/FETCH_STEP_PERFORMANCE_SUCCESS'
export const FETCH_STEP_PERFORMANCE_FAILURE = 'Reports/FETCH_STEP_PERFORMANCE_FAILURE'

export const FETCH_SEQUENCES_REPORTS_REQUEST = 'Reports/FETCH_SEQUENCES_REPORTS_REQUEST'
export const FETCH_SEQUENCES_REPORTS_SUCCESS = 'Reports/FETCH_SEQUENCES_REPORTS_SUCCESS'
export const FETCH_SEQUENCES_REPORTS_FAILURE = 'Reports/FETCH_SEQUENCES_REPORTS_FAILURE'

export const FETCH_BOUNCE_REASON_REQUEST = 'Reports/FETCH_BOUNCE_REASON_REQUEST'
export const FETCH_BOUNCE_REASON_SUCCESS = 'Reports/FETCH_BOUNCE_REASON_SUCCESS'
export const FETCH_BOUNCE_REASON_FAILURE = 'Reports/FETCH_BOUNCE_REASON_FAILURE'

export const TEAM_REPORTS = [
  'lookups',
  'contacts',
  'messages',
  'replies',
  'booked',
  'tasks'
]

export const MESSAGE_REPORTS = [
  'messages',
  'messages_opened',
  'messages_clicked',
  'messages_replied',
  'messages_bounced'
]

export const PERSONALIZED_MESSAGE_REPORTS = [
  'messages_manual',
  'messages_manual_opened',
  'messages_manual_clicked',
  'messages_manual_replied',
  'messages_manual_bounced'
]

export const CONTACT_REPORTS = [
  'contacts',
  'contacts_messaged',
  'contacts_messaged_opened',
  'contacts_messaged_replied',
  'contacts_messaged_booked',
  'contacts_messaged_bounced'
]

export const PERSONALIZED_CONTACT_REPORTS = [
  'customized_contacts_messaged',
  'customized_contacts_messaged_opened',
  'customized_contacts_messaged_visited',
  'customized_contacts_messaged_replied',
  'customized_contacts_messaged_booked',
  'customized_contacts_messaged_bounced'
]

export const STEP_REPORTS = [
  'messages',
  'messages_opened',
  'messages_replied',
  'messages_bounced'
]

export const SEQUENCES_REPORTS = [
  'campaigns_active',
  'sent_message_last_7_days',
  'sent_message_last_30_days',
  'campaigns_total'
]
