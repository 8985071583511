import { fromJS } from 'immutable'
import { createSelector } from 'reselect'

const selectSearchDomain = () => state => state.get('navigation')

const selectLoading = () => createSelector(
  selectSearchDomain(),
  searchState => searchState.get('loading')
)

const selectResults = () => createSelector(
  selectSearchDomain(),
  searchState => searchState.get('results')
)

const selectRecentSequences = () => createSelector(
  selectSearchDomain(),
  searchState => fromJS(searchState.get('recentSequences') || [])
)

const selectChecklist = () => createSelector(
  selectSearchDomain(),
  searchState => fromJS(searchState.get('checklist'))
)

export {
  selectLoading,
  selectResults,
  selectRecentSequences,
  selectChecklist
}
