import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { formatHourRange } from 'utils/strings'

class TimeRangePicker extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  componentDidMount () {
    const {
      blocks
    } = this.props
    this.setState({
      timeBlocks: blocks
    })
    this.timeoutID = setTimeout(() => {
      this.setupCanvas()
      this.snapLineBlocks()
      this.drawStartAndEndLines()
    }, 100)
  }

  componentWillUnmount () {
    clearTimeout(this.timeoutID)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.blocks !== this.props.blocks) {
      this.setState({
        timeBlocks: nextProps.blocks
      }, () => {
        setTimeout(() => {
          this.setupCanvas()
          this.snapLineBlocks()
          this.drawStartAndEndLines()
        }, 100)
      })
    }
  }

  getCanvasCoordinates = (evt) => {
    const x = evt.clientX - this.context.canvas.getBoundingClientRect().left
    const y = evt.clientY - this.context.canvas.getBoundingClientRect().top
    return { x, y }
  }

  setupCanvas = () => {
    this.clearCanvas()
    this.context.canvas.addEventListener('mousedown', this.dragStart, false)
    this.context.canvas.addEventListener('mousemove', this.drag, false)
    this.context.canvas.addEventListener('mouseup', this.dragStop, false)
    this.context.canvas.addEventListener('mouseleave', this.dragStop, false)
  }

  drawBackground = () => {
    const {
      xPadding
    } = this.props

    const width = this.context.canvas.width - (xPadding * 2)
    const height = this.context.canvas.height - 20
    this.context.beginPath()
    this.context.lineWidth = '1'
    this.context.strokeStyle = '#CED1D5'
    this.context.fillStyle = '#FBFCFD'
    this.context.rect(xPadding, 1, width, height)
    this.context.stroke()
    this.context.fill()
  }

  drawGrid = () => {
    const {
      xPadding,
      yPadding
    } = this.props
    const yStep = (this.context.canvas.width - (xPadding * 2)) / 24.0
    const lineHeight = this.context.canvas.height - yPadding - 20

    if (this.props.hourlines) {
      let step = xPadding + yStep
      this.context.beginPath()
      this.context.strokeStyle = '#CED1D5'
      this.context.lineWidth = 0.5

      for (let i = 0; i < 23; i += 1) {
        this.context.moveTo(step, yPadding)
        this.context.lineTo(step, lineHeight)
        step += yStep
      }
      this.context.stroke()
    }
  }

  lineBlocksFromTime = (timeBlocks) => {
    const {
      xPadding
    } = this.props
    const width = this.context.canvas.width - (xPadding * 2)
    return timeBlocks.map((block) => {
      const {
        startTime,
        endTime
      } = block
      const startLine = Math.round(((width / 24) * startTime) + xPadding)
      const endLine = Math.round(((width / 24) * endTime) + xPadding)
      return {
        startLine,
        endLine
      }
    })
  }

  snapLineBlocks = () => {
    const {
      timeBlocks
    } = this.state

    const lineBlocks = this.lineBlocksFromTime(timeBlocks)

    this.setState({
      lineBlocks
    })
  }

  generateTimeBounds = () => {
    const {
      xPadding
    } = this.props
    const {
      lineBlocks
    } = this.state

    const timeBlocks = lineBlocks.map((block) => {
      const {
        startLine,
        endLine
      } = block

      const width = this.context.canvas.width - (xPadding * 2)

      let startTime = Math.round(((startLine - xPadding) / width) * 24)
      startTime = !isNaN(startTime) && startTime >= 0 ? startTime : 0

      const endTime = Math.round(((endLine - xPadding) / width) * 24)

      return {
        startTime,
        endTime
      }
    })

    this.setState({
      timeBlocks
    })
  }

  mergeRanges = (ranges) => {
    if (!(ranges && ranges.length)) {
      return []
    }
    // Stack of final ranges
    const stack = []

    // Sort according to start value
    ranges.sort((a, b) => (a.startTime - b.startTime))
    // Add first range to stack
    stack.push(ranges[0])

    ranges.slice(1).forEach((range) => {
      const top = stack[stack.length - 1]
      if (top.endTime < range.startTime) {
        // No overlap, push range onto stack
        stack.push(range)
      } else if (top.endTime < range.endTime) {
        // Update previous range
        top.endTime = range.endTime
      }
    })

    return stack
  }

  mergeBlocks = () => {
    let {
      timeBlocks
    } = this.state

    // Merge ranges then drop blocks that shouldn't exist
    timeBlocks = this.mergeRanges(timeBlocks)
      .filter(block => block.startTime < block.endTime)
    const lineBlocks = this.lineBlocksFromTime(timeBlocks)

    this.setState({
      timeBlocks,
      lineBlocks
    })
  }

  clearCanvas = () => {
    this.canvas.width = this.canvas.offsetWidth
    this.canvas.height = this.canvas.offsetHeight
    this.context.clearRect(0, 0, this.canvas.width, this.canvas.height)
    this.drawGrid()
  }

  drawBlock = (startLine, endLine) => {
    this.context.fillStyle = this.props.rangecolor
    this.context.fillRect(startLine, 1, endLine - startLine, this.context.canvas.height - 20)
  }

  drawTime = (xAxis, hour, position = 'center') => {
    const step = xAxis
    this.context.fillStyle = this.props.markercolor
    this.context.font = this.props.markerfont
    this.context.textAlign = position
    let hourText = ''
    let timeOfDayText = ''
    switch (true) {
      case hour === 0:
        hourText = '12'
        timeOfDayText = 'AM'
        break
      case hour === 12:
        hourText = '12'
        timeOfDayText = 'PM'
        break
      case hour < 12:
        hourText = hour
        timeOfDayText = 'AM'
        break
      case hour >= 12:
        hourText = `${hour % 12}`
        timeOfDayText = 'PM'
        break
      default:
        break
    }

    if (position === 'end') {
      hourText = `${hourText}:59`
    }

    const text = `${hourText} ${timeOfDayText}`

    this.context.fillText(text, step, this.context.canvas.height - 4)
  }

  drawBlocks = () => {
    const {
      lineBlocks,
      timeBlocks
    } = this.state

    lineBlocks.forEach((block, i) => {
      const {
        endLine,
        startLine
      } = block

      const time = timeBlocks[i]
      const {
        startTime,
        endTime
      } = time
      this.drawBlock(startLine, endLine)
      if (endTime - startTime > 3) {
        this.drawTime(startLine, startTime, 'start')
        this.drawTime(endLine, endTime - 1, 'end')
      } else {
        this.context.fillStyle = this.props.markercolor
        this.context.font = this.props.markerfont
        this.context.textAlign = 'center'

        let pos = (startLine + endLine) / 2
        if (startTime === 0) {
          pos = 0
          this.context.textAlign = 'start'
        }

        this.context.fillText(
          formatHourRange(startTime, endTime - 1).toUpperCase(),
          pos,
          this.context.canvas.height - 4
        )
      }
    })
  }

  drawStartAndEndLines = () => {
    this.clearCanvas()
    this.drawBackground()
    this.generateTimeBounds()
    this.drawBlocks()
    this.drawGrid()
  }

  moveClosestLine = (coords) => {
    const {
      xPadding
    } = this.props

    const {
      lineBlocks,
      activeBlock
    } = this.state

    const {
      startLine = 0,
      endLine = 24,
      initialStartLine,
      initialEndLine
    } = lineBlocks[activeBlock]

    const dragCoordinates = coords
    let distanceToStartLine = null
    let distanceToEndLine = null

    const width = (this.context.canvas.width - (xPadding * 2))
    const firstStep = xPadding
    const lastStep = firstStep + width

    if (dragCoordinates.x < firstStep) {
      dragCoordinates.x = firstStep
    }
    if (dragCoordinates.x > lastStep) {
      dragCoordinates.x = lastStep
    }

    if (startLine) {
      distanceToStartLine = Math.abs(dragCoordinates.x - startLine)
    }
    if (endLine) {
      distanceToEndLine = Math.abs(dragCoordinates.x - endLine)
    }

    const block = {
      startLine,
      endLine,
      initialStartLine,
      initialEndLine
    }

    if (startLine === null && endLine === null) {
      block.startLine = dragCoordinates.x
    } else if (startLine !== null && endLine === null) {
      if (dragCoordinates.x < startLine) {
        block.startLine = dragCoordinates.x
        block.endLine = startLine
      } else if (dragCoordinates.x > startLine) {
        block.startLine = startLine
        block.endLine = dragCoordinates.x
      }
    } else if (dragCoordinates.x <= initialEndLine && dragCoordinates.x >= initialStartLine) {
      block.startLine = initialStartLine
      block.endLine = initialEndLine
    } else if (distanceToStartLine < distanceToEndLine) {
      block.startLine = dragCoordinates.x
    } else if (distanceToStartLine >= distanceToEndLine) {
      block.endLine = dragCoordinates.x
    }

    lineBlocks[activeBlock] = block

    this.setState({
      lineBlocks
    }, this.drawStartAndEndLines)
  }

  moveClosestBlock = (coords) => {
    const {
      xPadding
    } = this.props

    const {
      lastMoveCoord,
      activeBlock,
      lineBlocks
    } = this.state

    const {
      startLine,
      endLine
    } = lineBlocks[activeBlock]

    const dragCoordinates = coords
    const distanceFromStart = dragCoordinates.x - lastMoveCoord.x

    const blockSize = endLine - startLine
    let newStart = startLine + distanceFromStart
    let newEnd = endLine + distanceFromStart

    const width = (this.context.canvas.width - (xPadding * 2))
    const firstStep = xPadding
    const lastStep = firstStep + width

    if (newStart <= firstStep) {
      newStart = firstStep
      newEnd = firstStep + blockSize
    }
    if (newEnd >= lastStep) {
      newStart = lastStep - blockSize
      newEnd = lastStep
    }

    const block = {
      startLine: newStart,
      endLine: newEnd
    }

    lineBlocks[activeBlock] = block

    this.setState({
      lineBlocks,
      lastMoveCoord: coords
    }, this.drawStartAndEndLines)
  }

  createBlock = (coords) => {
    const {
      xPadding
    } = this.props
    const {
      lineBlocks,
      timeBlocks
    } = this.state

    let startLine = coords.x
    const width = this.context.canvas.width - (xPadding * 2)
    const startTime = Math.floor(((startLine - xPadding) / width) * 24)
    const endTime = startTime + 1
    startLine = Math.floor(((width / 24) * startTime)) + xPadding
    const endLine = Math.floor(((width / 24) * endTime) + xPadding)

    const newIndex = lineBlocks.length
    lineBlocks[newIndex] = {
      startLine,
      endLine,
      initialStartLine: startLine,
      initialEndLine: endLine
    }
    timeBlocks[newIndex] = {
      startTime,
      endTime
    }
    this.setState({
      lineBlocks,
      timeBlocks
    }, this.drawStartAndEndLines)
    return newIndex
  }

  dragStart = (evt) => {
    const {
      lineBlocks
    } = this.state
    const {
      clickPadding
    } = this.props

    const coords = this.getCanvasCoordinates(evt)

    let activeBlock = lineBlocks.reduce((acc, block, i) => {
      const {
        startLine,
        endLine
      } = block
      if (acc !== null) { return acc }
      if (coords.x > startLine + clickPadding && coords.x < endLine - clickPadding) {
        return i
      }
      return null
    }, null)

    if (activeBlock !== null) {
      // Moves the closest block, set state to moving and record starting position of move
      return this.setState({
        moving: true,
        lastMoveCoord: coords,
        activeBlock
      })
    }

    activeBlock = lineBlocks.reduce((acc, block, i) => {
      const {
        startLine,
        endLine
      } = block
      if (acc !== null) { return acc }
      if ((coords.x <= startLine + clickPadding && coords.x >= startLine - clickPadding) ||
        (coords.x <= endLine + clickPadding && coords.x >= endLine - clickPadding)) {
        return i
      }
      return null
    }, null)

    if (activeBlock !== null) {
      return this.setState({
        dragging: true,
        activeBlock
      })
    }

    if (!activeBlock) {
      activeBlock = this.createBlock(coords)
      // Use newly created block for resizing
      return this.setState({
        dragging: true,
        activeBlock
      })
    }
  }

  drag = (evt) => {
    const {
      lineBlocks
    } = this.state
    const {
      clickPadding
    } = this.props

    // update time ranges based on initiated state
    const coords = this.getCanvasCoordinates(evt)

    if (this.state.dragging) {
      this.moveClosestLine(coords)
    } else if (this.state.moving) {
      this.moveClosestBlock(coords)
    }

    // Update the cursor based on position
    const padding = clickPadding

    const resizingCursor = lineBlocks.reduce((acc, block) => {
      const {
        startLine,
        endLine
      } = block
      if (acc) { return acc }
      return acc || (coords.x <= startLine + padding && coords.x >= startLine - padding) ||
        (coords.x <= endLine + padding && coords.x >= endLine - padding)
    }, false)
    const movingCursor = lineBlocks.reduce((acc, block) => {
      const {
        startLine,
        endLine
      } = block
      if (acc) { return acc }
      return acc || (coords.x > startLine + padding && coords.x < endLine - padding)
    }, false)

    if (resizingCursor) {
      this.canvas.style.cursor = 'ew-resize'
    } else if (movingCursor) {
      this.canvas.style.cursor = 'move'
    } else {
      this.canvas.style.cursor = 'pointer'
    }
  }

  dragStop = () => {
    this.mergeBlocks()
    this.drawStartAndEndLines()
    this.setState(
      { dragging: false, moving: false },
      () => this.props.timeupdate(this.state.timeBlocks)
    )
  }

  render () {
    return (
      <div style={{ ...this.props.style }}>
        <canvas
          ref={(element) => {
            if (element) {
              this.canvas = element
              this.context = element.getContext('2d')
            }
          }}
          id='TimePeriodSelectorCanvas'
          style={{
            height: '100%',
            width: '100%',
            userSelect: 'none'
          }}
        />
      </div>
    )
  }
}

TimeRangePicker.propTypes = {
  style: PropTypes.object,
  rangecolor: PropTypes.string,
  hourlines: PropTypes.bool,
  markercolor: PropTypes.string,
  markerfont: PropTypes.string,
  timeupdate: PropTypes.func,
  blocks: PropTypes.array,
  xPadding: PropTypes.number,
  yPadding: PropTypes.number,
  clickPadding: PropTypes.number
}

TimeRangePicker.defaultProps = {
  style: {
    width: '100%',
    height: '100px'
  },
  blocks: [],
  rangecolor: '#4D83E1',
  hourlines: true,
  markercolor: '#7A859F',
  markerfont: '300 12px soleil, sans-serif',
  xPadding: 1,
  yPadding: 10,
  clickPadding: 1
}

export default TimeRangePicker
