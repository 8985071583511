import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import Avatar from 'react-avatar'
import NumberFormat from 'react-number-format'
import {
  Column,
  AutoSizer,
  Table,
  SortIndicator
} from 'react-virtualized'
import { getGroupsFromMembers } from 'utils/groups'

import Loading from 'components/Loading'
import { FullContainer } from 'containers/Reports/styles'

import { AngleUp } from 'svg'

const TableContainer = styled.div`
  /* background-color: white; */

  .ReactVirtualized__Table { }

  .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 1rem;
    text-align: left;
  }

  .ReactVirtualized__Table__headerColumn:last-of-type, .ReactVirtualized__Table__rowColumn:last-of-type {
    margin-right: 1rem;
  }

  .ReactVirtualized__Table__headerRow {
    border: 1px solid ${props => props.theme.colors.gray30};
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.darkBlue};
    font-size: 15px;
    text-transform: capitalize;
    text-align: left;

    cursor: pointer;
    user-select: none;
  }

  .ReactVirtualized__Table__row {
    background-color: white;
    padding: 0;
    color: ${props => props.theme.colors.darkBlue};

    border: 1px solid ${props => props.theme.colors.gray30};
    border-bottom: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid ${props => props.theme.colors.gray30};
    }
  }
`

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  color: ${props => props.theme.colors.darkGray};
  font-size: 18px;
  font: ${props => props.theme.fonts.normal};
`

const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const UserCell = styled.div`
  display: flex;
  color: ${props => props.theme.colors.darkGray};
`

const GroupCell = styled.div`
  color: ${props => props.theme.colors.darkGray};
`

const UserDetails = styled.div`
  flex: 1;
  width: 0;
  font: ${props => props.theme.fonts.normal};
  margin-left: 0.75rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const TickerWrapper = styled.div`
  transform: translateY(-1.5px);
  padding-right: 1px;
  padding-left: 0.3rem;
`

const UserDetailName = styled.div`
  font: ${props => props.theme.fonts.formtitle};
  color: ${props => props.theme.colors.darkBlue};
`

class LeaderboardReport extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showContrastPeriodReport: false
    }
  }

  formatTableData = () => {
    const {
      users,
      leaderboard,
      leaderboardGroups,
      leaderboardContrast,
      leaderboardGroupsContrast,
      showLeaderboardGroupsData
    } = this.props

    let { sortBy, sortDirection } = this.state
    const tableRows = []

    const columnNames = ['lookups', 'contacts', 'messages', 'replies', 'booked', 'tasks']

    if (showLeaderboardGroupsData) {
      const groups = getGroupsFromMembers(users)
      groups
        .forEach(group => {
          const report = leaderboardGroups.getIn(['data', group]) || Immutable.Map()
          const contrast = leaderboardGroupsContrast.getIn(['data', group]) || Immutable.Map()

          const newRow = { group }
          columnNames.forEach(columnName => {
            newRow[`report_${columnName}`] = report.getIn([columnName])
            newRow[`contrast_${columnName}`] = contrast.getIn([columnName])
          })

          tableRows.push(newRow)
        })
    } else {
      users
        .get('data')
        .filter(user => {
          return leaderboard.hasIn(['data', user.get('id')])
        })
        .forEach(user => {
          // pull data from the `team report` and `contrast team report`
          const report = leaderboard.getIn(['data', user.get('id')]) || Immutable.Map()
          const contrast = leaderboardContrast.getIn(['data', user.get('id')]) || Immutable.Map()

          // `newRow` represents each row that get's added to the table
          // add the `team report` and `contrast team report` data to the `newRow`
          const newRow = { user, userName: user.get('full_name') }
          columnNames.forEach(columnName => {
            newRow[`report_${columnName}`] = report.getIn([columnName])
            newRow[`contrast_${columnName}`] = contrast.getIn([columnName])
          })

          tableRows.push(newRow)
        })
    }

    if (sortBy) {
      if (sortBy === 'user') {
        sortBy = 'userName'
      }

      let sort = Immutable.List(tableRows)
      sort = sort.sortBy(row => row[sortBy])

      if (sortDirection === 'DESC') {
        sort = sort.reverse()
      }

      return sort.toArray()
    }

    return tableRows
  }

  _numberCellRenderer = ({ dataKey, rowData }) => {
    const { showLeaderboardContrastData, theme } = this.props

    const key = dataKey.split('_')[1]
    const reportData = rowData[`report_${key}`] || 0
    const contrastData = rowData[`contrast_${key}`] || 0

    const renderPercent = () => {
      let percentDiff = 0
      if (!!contrastData && !!reportData) {
        const d = contrastData
        const n = contrastData - reportData
        percentDiff = ((Math.round(n / d * 100))).toFixed(0)
      } else if (!contrastData && !!reportData) {
        percentDiff = -100
      } else if (!!contrastData && !reportData) {
        percentDiff = 100
      }

      if (Math.abs(percentDiff) <= 1) {
        return null
      } else {
        const MAX_PERCENT = 1000
        return (
          <>
            <TickerWrapper>
              {percentDiff > 0
                ? <AngleUp color={theme.colors.red} degrees={180} width='18px' height='18px' />
                : <AngleUp color={theme.colors.green} width='18px' height='18px' />}
            </TickerWrapper>

            <NumberFormat
              displayType='text'
              style={{ fontSize: '.9rem' }}
              thousandSeparator=','
              suffix={Math.abs(percentDiff) <= MAX_PERCENT ? '%' : '%+'}
              value={Math.abs(percentDiff) <= MAX_PERCENT
                ? Math.abs(percentDiff)
                : MAX_PERCENT}
            />
          </>
        )
      }
    }

    return (
      <ColumnGroup>
        <RowGroup>
          <NumberFormat
            value={reportData}
            displayType='text'
            thousandSeparator=','
            style={{ fontWeight: showLeaderboardContrastData ? '600' : '300', fontSize: '18px' }}
          />
          {showLeaderboardContrastData && renderPercent()}
        </RowGroup>

        {showLeaderboardContrastData &&
          <>
            <RowGroup>
              <NumberFormat
                value={contrastData}
                displayType='text'
                thousandSeparator=','
                style={{ paddingRight: '.3rem', fontSize: '1rem' }}
              />
            </RowGroup>
          </>}
      </ColumnGroup>
    )
  }

  _userCellRenderer = ({ cellData }) => {
    return (
      <UserCell>
        <Avatar
          key={`user_${cellData.get('id')}`}
          src={cellData.get('photo_url' || '/bear_avatar.png')}
          size={44}
          round
        />
        <UserDetails>
          <UserDetailName>{cellData.get('full_name')}</UserDetailName>
          <div>{cellData.get('email')}</div>
        </UserDetails>
      </UserCell>
    )
  }

  _groupCellRenderer = ({ cellData }) => {
    return (
      <GroupCell>{cellData}</GroupCell>
    )
  }

  _headerRenderer ({ label, dataKey, sortBy, sortDirection }) {
    return (
      <div>
        {label}

        {// indicator displaying which way a column is sorted
          sortBy === dataKey &&
            <SortIndicator sortDirection={sortDirection} />
}
      </div>
    )
  }

  sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortBy,
      sortDirection
    })
  }

  render () {
    const { users, leaderboard, showLeaderboardGroupsData, leaderboardGroups } = this.props
    const { sortBy, sortDirection } = this.state

    if (!users || !leaderboard || users.get('loading') || leaderboard.get('loading') || leaderboardGroups.get('loading')) {
      return <Loading width='100%' />
    }

    const tableRows = this.formatTableData()
    const tableColumnWidth = 155

    return (
      <FullContainer>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              const firstColumnWidth = Math.max(tableColumnWidth, width - (tableColumnWidth * 3.5))

              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={70}
                  rowCount={tableRows.length}
                  rowGetter={({ index }) => tableRows[index]}
                  sort={this.sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  {showLeaderboardGroupsData
                    ? (<Column
                        key='group'
                        dataKey='group'
                        label='Group'
                        width={firstColumnWidth}
                        cellRenderer={this._groupCellRenderer}
                        headerRenderer={this._headerRenderer}
                       />)
                    : (<Column
                        key='user'
                        dataKey='user'
                        label='User'
                        width={firstColumnWidth}
                        cellRenderer={this._userCellRenderer}
                        headerRenderer={this._headerRenderer}
                       />)}

                  <Column
                    key='Lookups'
                    dataKey='report_lookups'
                    label='Lookups'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Contacts'
                    dataKey='report_contacts'
                    label='Contacts'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Messages'
                    dataKey='report_messages'
                    label='Messages'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Tasks'
                    dataKey='report_tasks'
                    label='Tasks'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Replies'
                    dataKey='report_replies'
                    label='Replies'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Booked'
                    dataKey='report_booked'
                    label='Booked'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />
                </Table>
              )
            }}
          </AutoSizer>
        </TableContainer>
      </FullContainer>
    )
  }
}

LeaderboardReport.propTypes = {
  actions: PropTypes.object,
  theme: PropTypes.object,
  leaderboard: PropTypes.object,
  leaderboardContrast: PropTypes.object,
  leaderboardGroups: PropTypes.object,
  leaderboardGroupsContrast: PropTypes.object,
  showLeaderboardContrastData: PropTypes.bool,
  showLeaderboardGroupsData: PropTypes.bool,
  users: PropTypes.object
}

export default LeaderboardReport
