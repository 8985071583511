import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { space, width, height, color } from 'styled-system'
import styled from 'styled-components'
import Toggle from 'react-toggle'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;

  .react-toggle {
    transform: ${props => props.small ? 'scale(0.8)' : ''};
  }

  ${space}
  ${width}
  ${height}
`

const Label = styled.label`
  width: 100%;
  max-width: 100%;
  color: ${props => props.condensed ? props.theme.labelColor : props.theme.titleColor};
  font: ${props => props.theme.fonts.formtitle};

  text-align: left;
  padding-top: 0;
  // margin-bottom: 0.25rem;

  font-size: ${props => props.small ? '12px' : props.condensed ? '13px' : ''};
  letter-spacing: 0.05em;
  text-transform: uppercase;

  margin-top: ${props => props.small ? '-2px' : ''};
  white-space: ${props => props.small ? 'nowrap' : ''};
  overflow: ${props => props.small ? 'hidden' : ''};
  text-overflow: ${props => props.small ? 'ellipsis' : ''};

  ${space}
  ${color}
`

const Description = styled.p`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin: 0;
  margin-top: 0.25rem;
  word-wrap: break-word;
  letter-spacing: 0.05em;
`

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-left: ${props => props.small ? '0.75rem' : props.condensed ? '1rem' : '1.5rem'};
  overflow: hidden;
`

const Link = styled.a`

`

class SwitchFieldForm extends PureComponent {
  render () {
    const {
      label,
      condensed,
      description,
      descriptionOn,
      inputId,
      onSave,
      value,
      disabled,
      helpUrl,
      small,
      ...rest
    } = this.props
    return (
      <Wrapper {...rest} small={small}>
        <Toggle
          id={inputId}
          checked={value}
          name={inputId}
          icons={false}
          disabled={!!disabled}
          className='interseller-switch'
          onChange={(e) => {
            onSave(e.target.checked)
          }}
        />
        <Container condensed={condensed} small={small}>
          {label &&
            <Label htmlFor={inputId} condensed={condensed} small={small}>{label}</Label>}
          {description && (!descriptionOn || (descriptionOn && !value)) &&
            <Description htmlFor={inputId}>
              {description} {helpUrl && <Link href={helpUrl} target='_new'>Learn more</Link>}
            </Description>}
          {descriptionOn && value &&
            <Description htmlFor={inputId}>
              {descriptionOn} {helpUrl && <Link href={helpUrl} target='_new'>Learn more</Link>}
            </Description>}
        </Container>
      </Wrapper>
    )
  }
}

SwitchFieldForm.propTypes = {
  label: PropTypes.string,
  condensed: PropTypes.bool,
  description: PropTypes.any,
  descriptionOn: PropTypes.any,
  value: PropTypes.bool,
  inputId: PropTypes.string,
  onSave: PropTypes.func,
  controlled: PropTypes.bool,
  helpUrl: PropTypes.string,
  disabled: PropTypes.bool,
  small: PropTypes.bool
}

export default SwitchFieldForm
