import base from './base'
import colors from './colors'

export default {
  ...base,

  // Backgrounds
  background: colors.gray20,
  containerBackground: colors.white,
  // highlightBackground: colors.blueGray,
  // highlightBackground: 'rgba(219, 226, 244, 0.5)',
  highlightBackground: 'rgb(238, 240, 249)',

  // Primaries
  primaryColor: colors.blue,
  titleColor: colors.darkBlue,
  borderColor: colors.gray30,
  labelColor: colors.gray70,

  // Accents
  shadow: '0 1px 4px rgba(225, 229, 237, 1)',

  'popover.shadow': '0 1px 4px rgba(225, 229, 237, 1)',
  'popover.border.color': colors.gray10,
  'popover.border.style': 'solid',
  'popover.border.width': '0px',
  'popover.border.radius': '3px',

  'popper.shadow': '0 0px 13px rgba(225, 229, 237, 1)',

  // Menu Theme
  menuBackground: colors.white,
  menuActiveLinkBackground: colors.blue,
  menuHeader: colors.gray70,
  menuDivider: colors.gray30,
  menuItemColor: colors.darkBlue,

  // State Theme
  totalColor: colors.blue,
  openColor: colors.blue,
  clickedColor: colors.blue,
  repliedColor: colors.green,
  bounceColor: colors.red,
  attentionColor: colors.red,

  // Elements
  // Avatar placeholder default colors
  avatarColors: [
    colors.lightBlue,
    colors.grayBlue,
    colors.orange,
    colors.yellow,
    colors.green,
    colors.gray40
  ]
}
