import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { differenceInDays } from 'date-fns'
import DateField from 'components/DateField'
import Modal from 'components/Modal'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding: 2rem 5rem 1rem 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class PauseModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      date: null
    }
  }

  handleKeyDown = (e) => {
    const {
      date
    } = this.state
    // If 'enter' is pressed
    if (e.keyCode === 13 && date !== null) {
      e.preventDefault()
      return this.props.onConfirm(date)
    }
    return false
  }

  fromNow () {
    const days = differenceInDays(this.state.date, Date.now())

    if (days === 0) {
      return 'today'
    }

    if (days === 1) {
      return '1 day'
    }

    return `${days} days`
  }

  render () {
    const {
      title,
      description,
      loading,
      onCancel,
      onConfirm,
      isOpen
    } = this.props

    const {
      date
    } = this.state

    if (loading) {
      return <Loading />
    }

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={() => {
          this.setState({
            date: null
          })
          onCancel()
        }}
      >
        <Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <InputContainer>
            <Title align='center'>{title}</Title>
            <Label mt='0.5rem' mb='0.5rem' align='center'>
              {description}
            </Label>
            <DateField
              label='Pause Until'
              subtitle='Choose a date to automatically resume emails on'
              placeholder='Select a date (optional)'
              dateFormat='MMM D, YYYY'
              timeFormat={false}
              inputId='date'
              isValidDate={current => (current.isAfter(new Date()))}
              closeOnSelect
              onValueChange={(value) => {
                this.setState({
                  date: value
                })
              }}
              mt='1.5rem'
            />
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              label={date ? `Pause for ${this.fromNow()}` : 'Pause Indefinitely'}
              onClick={() => {
                onConfirm(date || true)
                this.setState({
                  date: null
                })
              }}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

PauseModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool
}

PauseModal.defaultProps = {
  loading: false,
  isOpen: false
}

export default PauseModal
