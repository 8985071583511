/* eslint-disable camelcase */
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import Loading from 'components/Loading'
import Title from 'elements/Title'
import Label from 'elements/Label'
import Workflow from 'components/Workflow'
import CreateSequence from './CreateSequence'
import CopySequence from './CopySequence'
import CrmSettings from './CrmSettings'
import Divider from 'elements/Divider'
import Button from 'components/Button'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  outline: none;
`

const InputContainer = styled.div`
  padding: 3rem 0 1rem 0;
  width: 100%;
`

const ContentContainer = styled.div`
  background-color: ${props => props.theme.containerBackground};
  padding: 0;
  transition: width 0.3s ease-in, height 0.3s ease-in;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
`

const Spacing = styled.div`
  content: ' ';
  padding-bottom: 1rem;
`

const stepsNoCrm = [
  {
    title: 'Details'
  },
  {
    title: 'Copy Steps'
  }
]

const stepsWithCrm = stepsNoCrm.concat(
  [
    {
      title: 'CRM Sync'
    }
  ]
)

class CreateSequenceModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      shared: 'rw',
      step: 0,
      user_id: props.session.get('id'),
      crmSettings: Immutable.Map({ // need an Immutable.Map structure here to reuse the Settings/Integrations component
        crm_list: null,
        crm_stage: null,
        crm_disabled: null,
        crm_model: null
      })
    }
  }

  setCrmSettings = (params) => {
    this.setState((prevState) => {
      return {
        crmSettings: prevState.crmSettings.merge(params)
      }
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    // triggered when the sequence owner is changed
    if (nextProps.ownerCrm) {
      this.setCrmSettings({
        crm_list: nextProps.ownerCrm.getIn(['data', 'list']),
        crm_model: nextProps.ownerCrm.getIn(['data', 'model']),
        crm_stage: nextProps.ownerCrm.getIn(['data', 'stage'])
      })
    }
  }

  createSequence = () => {
    const params = {
      shared: this.state.shared,
      title: this.state.title,
      user_id: this.state.user_id,
      clone_campaign_id: this.state.clone_campaign_id,
      crm_model: this.state.crmSettings.get('crm_model'),
      crm_list: this.state.crmSettings.get('crm_list'),
      crm_stage: this.state.crmSettings.get('crm_stage'),
      crm_disabled: this.state.crmSettings.get('crm_disabled')
    }
    this.props.handleCreateSequence(params)
  }

  goNext = () => {
    let step = this.state.step + 1

    // advance next step if no sequences
    if (step === 1 && this.props.sequences.count() <= 0) {
      step = 2
    }

    if (step > 1) {
      step = 2
    }
    this.setState({ step })
  }

  goBack = () => {
    let step = this.state.step - 1

    // advance next step if no sequences
    if (step === 1 && this.props.sequences.count() <= 0) {
      step = 0
    }

    if (step < 0) {
      step = 0
    }

    this.setState({ step })
  }

  onClickNext = null

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13 && this.state.title && this.state.title.length > 0) {
      this.onClickNext()
    }
    return false
  }

  setCreateInfo = (info) => {
    this.setState(info)
  }

  render () {
    const {
      step,
      shared,
      title,
      user_id,
      clone_campaign_id,
      crmSettings
    } = this.state

    const {
      sequences,
      members,
      isManager,
      group,
      session,
      loading,
      closeModal,
      fetchOwnerCrm,
      refreshOwnerCrm,
      ownerCrm
    } = this.props

    const nameCounts = {}
    // eslint-disable-next-line array-callback-return
    members.get('data').map((user) => {
      const name = user.get('full_name') || user.get('email')
      nameCounts[name] = (nameCounts[name] || 0) + 1
    })

    const userOptions = members.get('data')
      .map((user, i) => {
        let name = user.get('full_name') || user.get('email')
        if (nameCounts[name] > 1) {
          name = `${name} (${user.get('email')})`
        }
        if (session.get('id') === user.get('id')) {
          name += ' (you)'
        }
        return {
          label: name,
          value: user.get('id'),
          index: i,
          crm_type: user.get('crm_type'),
          group: user.get('group')
        }
      })
      .filter((user) => {
        return (!group && isManager) || user.group === group
      })

    if (loading) {
      return <Loading />
    }

    const showSteps = sequences.count() > 0 || ownerCrm.getIn(['data', 'name'])
    const createSequenceText = 'create sequence'

    let content
    let backText
    let nextText
    switch (step) {
      case 2:
        content = (
          <CrmSettings
            session={session}
            loading={loading}
            setCrmSettings={this.setCrmSettings}
            crmSettings={crmSettings}
            ownerCrm={ownerCrm}
            refreshOwnerCrm={refreshOwnerCrm}
          />
        )
        backText = 'back'
        nextText = createSequenceText
        this.onClickNext = this.createSequence
        break
      case 1:
        content = (
          <CopySequence
            sequences={sequences}
            loading={loading}
            campaignId={clone_campaign_id}
            setCreateInfo={this.setCreateInfo}
          />
        )
        backText = 'back'
        nextText = ownerCrm.getIn(['data', 'name']) ? 'next' : createSequenceText
        // if owner crm we have a 3rd step before end of the process
        this.onClickNext = ownerCrm.getIn(['data', 'name']) ? this.goNext : this.createSequence
        break
      case 0:
      default:
        content = (
          <CreateSequence
            userOptions={userOptions}
            isManager={isManager}
            session={session}
            loading={loading}
            shared={shared}
            title={title}
            userId={user_id}
            setCreateInfo={this.setCreateInfo}
            fetchOwnerCrm={fetchOwnerCrm}
          />
        )
        backText = 'cancel'
        nextText = showSteps ? 'next' : createSequenceText
        this.onClickNext = showSteps ? this.goNext : this.createSequence
        break
    }

    return (
      <Wrapper onKeyDown={this.handleKeyDown} tabIndex='0'>
        <InputContainer>
          <Title align='center'>Add Sequence</Title>
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Build a new email sequence to engage your prospects
          </Label>
          {showSteps &&
            <Workflow
              steps={ownerCrm.getIn(['data', 'name']) ? stepsWithCrm : stepsNoCrm}
              currentStep={step}
              pt='2rem'
              pb='1rem'
            />}
          {!showSteps && <Spacing />}
          <ContentContainer currentStep={step}>
            {content && content}
            <Divider />
            <ButtonContainer>
              <Button
                label={backText}
                onClick={() => {
                  step === 0 ? closeModal() : this.goBack()
                }}
                mr='0.5rem'
              />
              <Button
                primary
                disabled={!title || (!title.length > 0)}
                label={nextText}
                onClick={() => {
                  this.onClickNext()
                }}
              />
            </ButtonContainer>
          </ContentContainer>
        </InputContainer>
      </Wrapper>
    )
  }
}

CreateSequenceModal.propTypes = {
  members: PropTypes.instanceOf(Immutable.Map),
  sequences: PropTypes.instanceOf(Immutable.List),
  isManager: PropTypes.bool,
  group: PropTypes.string,
  session: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  handleCreateSequence: PropTypes.func,
  onUpdateSequence: PropTypes.func,
  closeModal: PropTypes.func,
  createSequenceReset: PropTypes.func,
  newSequence: PropTypes.object,
  ownerCrm: PropTypes.object,
  fetchOwnerCrm: PropTypes.func,
  refreshOwnerCrm: PropTypes.func
}

CreateSequenceModal.defaultProps = {
  loading: false,
  sequences: Immutable.List([]),
  members: Immutable.Map({
    data: Immutable.List([])
  })
}

export default CreateSequenceModal
