/**
*
* ContactHeader
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import Checkbox from 'elements/Checkbox'
import HeaderLabel from 'elements/HeaderLabel'

import {
  HeaderWrapper,
  CheckboxColumn,
  IconColumn,
  SequenceColumn,
  AvatarPhotoColumn,
  UserColumn,
  BadgeColumn,
  ContactNumberColumn,
  StatColumn
} from '../Rows'

class SequenceHeaders extends Component {
  render () {
    const {
      selected,
      onSelected,
      sortFunction,
      currentKey
    } = this.props

    return (
      <HeaderWrapper>
        <CheckboxColumn>
          <Checkbox
            selected={selected}
            handleClick={onSelected}
          />
        </CheckboxColumn>
        <IconColumn>
          <HeaderLabel
            sortKey='active'
            onClick={sortFunction}
            currentKey={currentKey}
            pl='0'
            pr='0'
            justifyContent='center'
          >
            Active
          </HeaderLabel>
        </IconColumn>
        <SequenceColumn>
          <HeaderLabel
            sortKey='title'
            onClick={sortFunction}
            currentKey={currentKey}
            pl='0'
          >
            Title
          </HeaderLabel>
        </SequenceColumn>
        <AvatarPhotoColumn />
        <UserColumn>
          <HeaderLabel
            sortKey='owner'
            onClick={sortFunction}
            currentKey={currentKey}
            pl='0'
          >
            Owner
          </HeaderLabel>
        </UserColumn>
        <BadgeColumn />
        <ContactNumberColumn>
          <HeaderLabel
            sortKey='contacts_count'
            onClick={sortFunction}
            currentKey={currentKey}
            pl='0'
            pr='0'
            justifyContent='center'
          >
            Contacts
          </HeaderLabel>
        </ContactNumberColumn>
        <StatColumn>
          <HeaderLabel
            sortKey='viewed_contacts_count'
            onClick={sortFunction}
            currentKey={currentKey}
            pl='0'
            pr='0'
            justifyContent='center'
          >
            Open %
          </HeaderLabel>
        </StatColumn>
        <StatColumn>
          <HeaderLabel
            sortKey='replied_contacts_count'
            onClick={sortFunction}
            currentKey={currentKey}
            pl='0'
            pr='0'
            justifyContent='center'
          >
            Reply %
          </HeaderLabel>
        </StatColumn>
      </HeaderWrapper>
    )
  }
}

SequenceHeaders.propTypes = {
  sortFunction: PropTypes.func,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
  currentKey: PropTypes.string
}

export default SequenceHeaders
