import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'

import {
  TooltipContainer,
  TooltipLabel,
  TooltipValue,
  TooltipUnitLabel
} from 'containers/Reports/styles'

const HeatMapTooltipContainer = styled(TooltipContainer)`
  border: none;
  border-radius: 1px;

  &.__react_component_tooltip.place-left::after {
    border-left: 8px solid white !important;
  }

  &.__react_component_tooltip.place-right::after {
    border-right: 8px solid white !important;
  }

  &.__react_component_tooltip.place-top::after {
    border-top: 8px solid white !important;
  }

  &.__react_component_tooltip.place-bottom::after {
    border-bottom: 8px solid white !important;
  }

  &.__react_component_tooltip.show {
    opacity: 1.0!important;
  }
`

class HeatMapTooltip extends React.Component {
  render () {
    const { value, label, unitLabel, theme, valueColor } = this.props
    return (
      <HeatMapTooltipContainer theme={theme}>
        <TooltipValue theme={theme}>
          <NumberFormat
            value={value}
            displayType='text'
            thousandSeparator=','
            renderText={formattedValue => {
              if (valueColor) {
                return <span style={{ color: valueColor }}>{formattedValue}</span>
              } else {
                return <span>{formattedValue}</span>
              }
            }}
          /> <TooltipUnitLabel theme={theme}> {unitLabel} </TooltipUnitLabel>
        </TooltipValue>
        <TooltipLabel theme={theme}>{label}</TooltipLabel>
      </HeatMapTooltipContainer>
    )
  }
}

HeatMapTooltip.propTypes = {
  label: PropTypes.string,
  unitLabel: PropTypes.string,
  theme: PropTypes.object,
  value: PropTypes.number,
  valueColor: PropTypes.string
}

export default HeatMapTooltip
