import React from 'react'

/* eslint-disable */
const Outlook = ({
  height,
  width
}) => (
    <svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Login" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
            <g id="Login---default" transform="translate(-510.000000, -390.000000)" fillRule="nonzero" fill="#FFFFFF">
                <g id="Group" transform="translate(484.000000, 297.000000)">
                    <g id="button-outlook" transform="translate(0.000000, 75.000000)">
                        <g id="outlook" transform="translate(26.000000, 18.000000)">
                            <path d="M16.4666667,19.0664253 C16.4666667,16.8447932 16.4666667,14.6516434 16.4666667,12.4062109 C16.9527088,12.7311083 17.4063884,13.0343623 17.8600679,13.3375428 C18.2672781,13.6096429 18.3358003,13.6082702 18.7560863,13.335974 C21.0815649,11.8293126 23.4075213,10.3231658 25.7335028,8.81719065 C25.8039864,8.77157509 25.8767833,8.72931759 25.9792774,8.66666667 C25.9873743,8.76981028 25.9988156,8.84706994 25.9988156,8.92437863 C25.9996957,11.8220572 26.0010536,14.7197848 25.9986396,17.6174634 C25.9977846,18.5806845 25.4986416,19.0655429 24.5157446,19.0660086 C21.9307116,19.0671606 19.3456786,19.0664498 16.7606456,19.0664498 C16.6675309,19.0664253 16.5744413,19.0664253 16.4666667,19.0664253 Z" id="Shape"></path>
                            <path d="M16.4973619,5.20005286 C16.5918555,5.20005286 16.6765627,5.20005286 16.7612447,5.20005286 C19.4923777,5.20005286 22.2235358,5.1998295 24.9546939,5.20030104 C25.4430117,5.20040031 25.8208101,5.49490841 25.9743246,5.95792666 C26.0417482,6.1613549 25.9731421,6.26082304 25.8065455,6.37091303 C23.6908792,7.76830645 21.5796657,9.17225165 19.4672699,10.5744845 C19.1655242,10.7748105 18.8597281,10.9695775 18.5633411,11.1773488 C18.401122,11.2909876 18.2575701,11.2971672 18.0892628,11.1902786 C17.6065552,10.88361 17.1146902,10.5906654 16.6354796,10.2789588 C16.5555021,10.2269664 16.4723045,10.1083144 16.4718265,10.0200141 C16.4633482,8.44398501 16.4673986,6.86793113 16.4694616,5.29190206 C16.4695874,5.27423207 16.4798267,5.2565869 16.4973619,5.20005286 Z" id="Shape"></path>
                            <path d="M14.7333333,26 C9.81779492,24.9420488 4.92151522,23.888084 0.00674222345,22.8302344 C0.00674222345,22.7070137 0.00674222345,22.6024043 0.00674222345,22.4977695 C0.0067175328,16.0863066 0.00861871298,9.67484375 1.67551375e-06,3.26338086 C-0.000393374915,2.99776953 0.0689626659,2.90562695 0.321918393,2.85588672 C4.25148501,2.08393555 8.17900229,1.30093945 12.1070628,0.520685547 C12.9732355,0.348613281 13.8395317,0.177201172 14.7333333,0 C14.7333333,8.66889258 14.7333333,17.3205957 14.7333333,26 Z M11.684059,13.5642871 C11.6728443,12.3704925 11.5140538,11.2682414 10.9404327,10.2694106 C10.4773402,9.46307644 9.85177462,8.85107491 8.95198015,8.58754676 C7.20129492,8.07483447 5.52512295,8.91806144 4.70017652,10.740181 C4.17160935,11.9076687 4.05870961,13.14636 4.16072946,14.4144852 C4.24913551,15.5133224 4.55857062,16.528448 5.24754638,17.3968918 C6.61710084,19.1230212 9.10948742,19.1344964 10.4973703,17.4191537 C11.4162743,16.2834236 11.6658701,14.9315868 11.684059,13.5642871 Z" id="Shape"></path>
                            <path d="M6.06669932,13.4589671 C6.06459435,12.6516202 6.16352784,11.8700568 6.57929615,11.177524 C6.96299466,10.538464 7.5455012,10.2773892 8.18744212,10.4538985 C8.68634429,10.5910656 8.98772616,10.9784167 9.18858963,11.4673227 C9.53150129,12.3020322 9.59093568,13.1900239 9.48714838,14.0793225 C9.42957131,14.5723942 9.30265413,15.072109 9.1204382,15.5270908 C8.88500373,16.1150739 8.43241086,16.4515388 7.82786407,16.4662139 C7.21935499,16.4809706 6.79623167,16.1339963 6.4997036,15.5790661 C6.1498084,14.924242 6.06504011,14.2018145 6.06669932,13.4589671 Z" id="Shape"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Outlook;
