import React, { PureComponent } from 'react'
import Datetime from 'react-datetime'
import PropTypes from 'prop-types'
import { space, width, height, textAlign, flex } from 'styled-system'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
  ${flex}
`

const EmbedWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  border-radius: 3px;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray40};
  border-style: solid;
  background: ${props => props.theme.colors.gray10};

  ${space}
  ${width}
  ${height}
`

const Label = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.label};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  ${space}
  ${width}
  ${height}
  ${textAlign}
`

const Subtitle = styled.label`
  font: ${props => props.theme.fonts.small};
  color: ${props => props.theme.labelColor};
  text-align: left;
  margin-top: -0.1rem;
  margin-bottom: 0.5rem;
`

const Field = styled(Datetime)`
  .form-control {
    color: ${props => props.theme.titleColor};
    font: ${props => props.theme.fonts.normal};
    width: 100%;
    padding: ${props => (props.small ? '0.65rem' : '1rem')};
    padding-left: 0.8rem;
    padding-right: 0.8rem;
    background: white;

    border: none;
    border-radius: 0 3px 3px 0;
    border-left: ${props => `1px solid ${props.theme.colors.gray40}`};

    ${props => props.border && css`
      border-radius: 3px;
      border-width: 1px;
      border-color: ${props.theme.colors.gray40};
      border-style: solid;
    `}

    ${props => props.disabled && css`
      cursor: default;
      opacity: .5;
    `};
    ${width}
  }

  .rdtPicker {
    font-family: soleil, sans-serif;
    line-height: 1.5em;
    right: 0;
  }
`

class DateField extends PureComponent {
  render () {
    const {
      label,
      subtitle,
      placeholder,
      inputId,
      small = false,
      value,
      renderInput,
      disabled,
      isValidDate,
      timeFormat,
      dateFormat = true,
      embedLabel = false,
      closeOnSelect = false,
      ...rest
    } = this.props

    if (embedLabel) {
      return (
        <EmbedWrapper {...rest}>
          {label &&
            <Label htmlFor={inputId} width='35%' pl='1rem' pr='1rem' mb={0} align='center'>{label}</Label>}
          {subtitle &&
            <Subtitle>{subtitle}</Subtitle>}
          <Field
            id={inputId}
            value={value}
            width='8rem'
            onChange={(date) => {
              if (date.toISOString) {
                this.props.onValueChange(date.toISOString())
              } else {
                this.props.onValueChange(null)
              }
            }}
            isValidDate={isValidDate}
            small={small}
            inputProps={{
              disabled,
              placeholder: placeholder || 'Select a date'
            }}
            disabled={disabled}
            timeFormat={timeFormat}
            dateFormat={dateFormat}
          />
        </EmbedWrapper>
      )
    }

    return (
      <Wrapper {...rest}>
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        {subtitle &&
          <Subtitle>{subtitle}</Subtitle>}
        <Field
          id={inputId}
          value={value}
          onChange={(date) => {
            if (date.toISOString) {
              this.props.onValueChange(date.toISOString())
            } else {
              this.props.onValueChange(null)
            }
          }}
          isValidDate={isValidDate}
          small={small}
          inputProps={{
            disabled,
            placeholder: placeholder || 'Select a date'
          }}
          disabled={disabled}
          timeFormat={timeFormat}
          dateFormat={dateFormat}
          renderInput={renderInput}
          border
          closeOnSelect={closeOnSelect}
        />
      </Wrapper>
    )
  }
}

DateField.propTypes = {
  label: PropTypes.string,
  subtitle: PropTypes.string,
  inputId: PropTypes.string,
  placeholder: PropTypes.string,
  onValueChange: PropTypes.func,
  isValidDate: PropTypes.func,
  renderInput: PropTypes.func,
  small: PropTypes.bool,
  value: PropTypes.any,
  disabled: PropTypes.bool,
  embedLabel: PropTypes.bool,
  timeFormat: PropTypes.any,
  dateFormat: PropTypes.any,
  closeOnSelect: PropTypes.bool
}

export default DateField
