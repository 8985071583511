export const FETCH_TEMPLATES_REQUEST = 'Templates/FETCH_TEMPLATES_REQUEST'
export const FETCH_TEMPLATES_SUCCESS = 'Templates/FETCH_TEMPLATES_SUCCESS'
export const FETCH_TEMPLATES_FAILURE = 'Templates/FETCH_TEMPLATES_FAILURE'

export const SAVE_TEMPLATE_REQUEST = 'Templates/SAVE_TEMPLATE_REQUEST'
export const SAVE_TEMPLATE_SUCCESS = 'Templates/SAVE_TEMPLATE_SUCCESS'
export const SAVE_TEMPLATE_FAILURE = 'Templates/SAVE_TEMPLATE_FAILURE'

export const DELETE_TEMPLATE_REQUEST = 'Templates/DELETE_TEMPLATE_REQUEST'
export const DELETE_TEMPLATE_SUCCESS = 'Templates/DELETE_TEMPLATE_SUCCESS'
export const DELETE_TEMPLATE_FAILURE = 'Templates/DELETE_TEMPLATE_FAILURE'
