export const FETCH_SEQUENCES_REQUEST = 'Dashboard/FETCH_SEQUENCES_REQUEST'
export const FETCH_SEQUENCES_SUCCESS = 'Dashboard/FETCH_SEQUENCES_SUCCESS'
export const FETCH_SEQUENCES_FAILURE = 'Dashboard/FETCH_SEQUENCES_FAILURE'

export const FETCH_ARCHIVED_SEQUENCES_REQUEST = 'Dashboard/FETCH_ARCHIVED_SEQUENCES_REQUEST'
export const FETCH_ARCHIVED_SEQUENCES_SUCCESS = 'Dashboard/FETCH_ARCHIVED_SEQUENCES_SUCCESS'
export const FETCH_ARCHIVED_SEQUENCES_FAILURE = 'Dashboard/FETCH_ARCHIVED_SEQUENCES_FAILURE'

export const UPDATE_SEQUENCES_REQUEST = 'Dashboard/UPDATE_SEQUENCES_REQUEST'
export const UPDATE_SEQUENCES_SUCCESS = 'Dashboard/UPDATE_SEQUENCES_SUCCESS'
export const UPDATE_SEQUENCES_FAILURE = 'Dashboard/UPDATE_SEQUENCES_FAILURE'

export const DELETE_SEQUENCES_REQUEST = 'Dashboard/DELETE_SEQUENCES_REQUEST'
export const DELETE_SEQUENCES_SUCCESS = 'Dashboard/DELETE_SEQUENCES_SUCCESS'
export const DELETE_SEQUENCES_FAILURE = 'Dashboard/DELETE_SEQUENCES_FAILURE'

export const RESTORE_SEQUENCES_REQUEST = 'Dashboard/RESTORE_SEQUENCES_REQUEST'
export const RESTORE_SEQUENCES_SUCCESS = 'Dashboard/RESTORE_SEQUENCES_SUCCESS'
export const RESTORE_SEQUENCES_FAILURE = 'Dashboard/RESTORE_SEQUENCES_FAILURE'

export const FETCH_SEQUENCES_STATS_REQUEST = 'Dashboard/FETCH_SEQUENCES_STATS_REQUEST'
export const FETCH_SEQUENCES_STATS_SUCCESS = 'Dashboard/FETCH_SEQUENCES_STATS_SUCCESS'
export const FETCH_SEQUENCES_STATS_FAILURE = 'Dashboard/FETCH_SEQUENCES_STATS_FAILURE'

export const FETCH_SEQUENCES_STATUS_REQUEST = 'Dashboard/FETCH_SEQUENCES_STATUS_REQUEST'
export const FETCH_SEQUENCES_STATUS_SUCCESS = 'Dashboard/FETCH_SEQUENCES_STATUS_SUCCESS'
export const FETCH_SEQUENCES_STATUS_FAILURE = 'Dashboard/FETCH_SEQUENCES_STATUS_FAILURE'

export const FETCH_SEQUENCE_REPORTS_REQUEST = 'Dashboard/FETCH_SEQUENCE_REPORTS_REQUEST'
export const FETCH_SEQUENCE_REPORTS_SUCCESS = 'Dashboard/FETCH_SEQUENCE_REPORTS_SUCCESS'
export const FETCH_SEQUENCE_REPORTS_FAILURE = 'Dashboard/FETCH_SEQUENCE_REPORTS_FAILURE'

export const FETCH_MESSAGE_SCHEDULE_REQUEST = 'Dashboard/FETCH_MESSAGE_SCHEDULE_REQUEST'
export const FETCH_MESSAGE_SCHEDULE_SUCCESS = 'Dashboard/FETCH_MESSAGE_SCHEDULE_SUCCESS'
export const FETCH_MESSAGE_SCHEDULE_FAILURE = 'Dashboard/FETCH_MESSAGE_SCHEDULE_FAILURE'

export const CREATE_SEQUENCE_REQUEST = 'Dashboard/CREATE_SEQUENCE_REQUEST'
export const CREATE_SEQUENCE_SUCCESS = 'Dashboard/CREATE_SEQUENCE_SUCCESS'
export const CREATE_SEQUENCE_FAILURE = 'Dashboard/CREATE_SEQUENCE_FAILURE'
export const CREATE_SEQUENCE_RESET = 'Dashboard/CREATE_SEQUENCE_RESET'

// Fetch Message Counts
export const FETCH_MESSAGE_COUNTS_REQUEST = 'Dashboard/FETCH_MESSAGE_COUNTS_REQUEST'
export const FETCH_MESSAGE_COUNTS_SUCCESS = 'Dashboard/FETCH_MESSAGE_COUNTS_SUCCESS'
export const FETCH_MESSAGE_COUNTS_FAILURE = 'Dashboard/FETCH_MESSAGE_COUNTS_FAILURE'

export const FETCH_SEQUENCES_EXPORT_CSV_REQUEST = 'Dashboard/FETCH_SEQUENCES_EXPORT_CSV_REQUEST'
export const FETCH_SEQUENCES_EXPORT_CSV_SUCCESS = 'Dashboard/FETCH_SEQUENCES_EXPORT_CSV_SUCCESS'
export const FETCH_SEQUENCES_EXPORT_CSV_FAILURE = 'Dashboard/FETCH_SEQUENCES_EXPORT_CSV_FAILURE'
