import styled from 'styled-components'

export default styled.div`
  background: ${props => props.theme.background};
  -webkit-app-region: drag;
  width: 100%;
  max-width: 55rem;
  margin-top: 60px;
  margin-left: auto;
  margin-right: auto;
  display: flex;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: start;
  justify-content: center;
`
