import { debounce } from 'utils/browser'

import { CUSTOM_FIELD_REGEX } from './constants'

function deleteListItem (event, editor) {
  const isDeleteKey = event.keyCode === 8
  if (!isDeleteKey) {
    return false
  }

  const currentBlock = editor.value.startBlock
  if (currentBlock.text !== '') {
    return false
  }

  const parentBlock = editor.value.document.getParent(currentBlock.key)
  const isList = parentBlock.type === 'unordered_list' || parentBlock.type === 'ordered_list'
  if (!isList) {
    return false
  }

  const firstBlock = editor.value.document.nodes.get(0)
  const isFirstBlock = firstBlock.key === parentBlock.key
  if (!isFirstBlock) {
    return false
  }

  const hasExactlyOneListItem = parentBlock.nodes.count() === 1
  if (!hasExactlyOneListItem) {
    return false
  }

  editor.removeNodeByKey(parentBlock.key)
  return true
}

function exitList (event, editor) {
  const isEnterKey = event.keyCode === 13
  if (!isEnterKey) {
    return false
  }

  const isList = editor.isUnorderedListActive() || editor.isOrderedListActive()
  if (!isList) {
    return false
  }

  const listType = editor.isUnorderedListActive()
    ? 'unordered_list'
    : 'ordered_list'

  const currentBlock = editor.value.startBlock
  if (currentBlock.text === '') {
    editor
      .unwrapBlock(listType)
      .setBlocks('paragraph')
  }
}

function _findCustomFields (text) {
  const fields = []
  let match
  while ((match = CUSTOM_FIELD_REGEX.exec(text))) {
    const field = {
      text: match[0],
      start: match.index,
      end: match.index + match[0].length
    }
    fields.push(field)
  }
  return fields
}

function wrapCustomFields (editor) {
  return debounce((editor) => {
    const { value } = editor
    const { selection, startText, endText } = value
    const { start, end, isBackward, isExpanded } = selection
    if (startText && endText && isExpanded) {
      let endPtr = end.offset
      const endFields = _findCustomFields(endText.text)
      endFields.forEach(field => {
        if (endPtr > field.start && endPtr <= field.end) {
          endPtr = field.end
        }
      })
      const moveEnd = endPtr - end.offset
      let startPtr = start.offset
      const startFields = _findCustomFields(startText.text)
      startFields.forEach(field => {
        if (startPtr > field.start && startPtr <= field.end) {
          startPtr = field.start
        }
      })
      const moveStart = start.offset - startPtr
      setTimeout(function () {
        if (isBackward) {
          editor
            .moveFocusBackward(moveStart)
            .moveAnchorForward(moveEnd)
        } else {
          editor
            .moveAnchorBackward(moveStart)
            .moveFocusForward(moveEnd)
        }
      })
    }
  }, 50)
}

export {
  exitList,
  deleteListItem,
  wrapCustomFields
}
