import { keyframes } from 'styled-components'

export const fadeInRight = keyframes`
  0% {
    opacity: 0;
    transform: translateX(20px);
  }
  100% {
    opacity: 1;
    transform: translateX(0px);
  }
`

export const glow = (color, size) => (keyframes`
  to {
    border-color: ${color};
    box-shadow: ${`0 0 ${size}px ${color}`}
  }
`)

export const fadeIn = keyframes`
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
`

export const rotate = keyframes`
  0% {
    tranform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
`
