import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  flex: 1 1 auto;
  padding: 2rem;
  padding-top: 0.75rem;
  overflow: auto;
  max-width: 1400px;
  min-width: 1000px;
  margin: auto;
`

export default Wrapper
