/* eslint-disable react/no-find-dom-node */
import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import {
  subDays,
  subYears,
  startOfDay,
  startOfYear,
  endOfToday,
  format
} from 'date-fns'

import CalendarDatePicker from './components/CalendarDatePicker'
import Curtain from 'components/SlateEditor/components/Toolbar/ToolbarModal/Curtain'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.white};

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
`

const DateInput = styled.div`
  border: 1px solid ${props => props.theme.colors.gray60};
  border-radius: 3px;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;

  background-color: white;
  padding: .75rem 0rem .75rem 1rem;

  user-select: none;
  cursor: pointer;

  ${props => props.focused && css`
    border: 1px solid ${props => props.theme.colors.blue};
    box-shadow: 0 0 4px ${props => props.theme.colors.lightBlue};
  `};
`

const DateText = styled.div`
  margin-right: .25rem;
  color: ${props => props.theme.colors.github};
  transition: .2s;
  text-decoration: underline;
`

const DateSeperator = styled.div`
  margin-right: .25rem;
  color: ${props => props.theme.colors.github};
`

const DateShortcut = styled.div`
  color: ${props => props.theme.colors.blue};
  font: ${props => props.theme.fonts.xsmall};
  font-weight: 400;
  text-transform: uppercase;
  cursor: pointer;

  margin-right: 0.75rem;

  &:last-child {
    margin-right: 0;
  }

  &:hover {
    text-decoration: underline;
    text-decoration-color: ${props => props.theme.colors.blue};
  }
`

const DateShortcutRow = styled.div`
  background-color: ${props => props.theme.colors.gray20};
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100%;

  padding: 0.5rem;

  &:before {
    content: " ";
    flex: 1
  }

  &:after {
    content: " ";
    flex: 1;
  }
`

const DateDropDown = styled.div`
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.gray40};
  border-radius: 3px;
  box-shadow: ${props => props.theme.shadow};

  z-index: 100;
  position: absolute;
  left: ${props => props.position.x}px;
  top: ${props => props.position.y}px;
  width: 460px;

  padding: 1.5rem;

  display: flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;
`

class DateRange extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      showDateDropDown: false,
      dateDropDownPosition: { x: 0, y: 0 }
    }

    // refs
    this.dateInputRef = React.createRef()

    // class functions that need to be bound to `this`
    this.calculateDateDropDownPosition = this.calculateDateDropDownPosition.bind(this)
    this.toggleDateDropDown = this.toggleDateDropDown.bind(this)
  }

  componentDidMount () {
    this.calculateDateDropDownPosition()
  }

  calculateDateDropDownPosition = () => {
    const dateInputField = ReactDOM.findDOMNode(this.dateInputRef.current)
    const { bottom, left } = dateInputField.getBoundingClientRect()
    this.setState({
      dateDropDownPosition: { x: left, y: (bottom + 21) }
    })
  }

  toggleDateDropDown = () => {
    const { showDateDropDown } = this.state
    this.setState({ showDateDropDown: !showDateDropDown })
  }

  last30Days = () => {
    const { setDateRange } = this.props
    const today = endOfToday()
    const sub30Days = subDays(today, 30)

    setDateRange(sub30Days, today)
  }

  last90Days = () => {
    const { setDateRange } = this.props
    const today = endOfToday()
    const sub90Days = subDays(today, 90)

    setDateRange(sub90Days, today)
  }

  last365Days = () => {
    const { setDateRange } = this.props
    const today = endOfToday()
    const lastYear = subYears(today, 1)

    setDateRange(lastYear, today)
  }

  YTD = () => {
    const { setDateRange } = this.props
    const today = endOfToday()
    const jan1 = startOfDay(startOfYear(today))

    setDateRange(jan1, today)
  }

  renderDateDropDown = () => {
    const { showDateDropDown, dateDropDownPosition } = this.state
    if (!showDateDropDown) {
      return
    }

    const { theme, startDate, endDate, setDateRange } = this.props
    const { x, y } = dateDropDownPosition

    return (
      <>
        <Curtain onClick={() => this.setState({ showDateDropDown: false })} />
        <DateDropDown position={{ x, y }}>
          <DateShortcutRow>
            <DateShortcut onClick={this.last30Days}>Last 30 Days</DateShortcut>
            <DateShortcut onClick={this.last90Days}>Last 90 Days</DateShortcut>
            <DateShortcut onClick={this.last365Days}>Last 365 Days</DateShortcut>
            <DateShortcut onClick={this.YTD}>YTD</DateShortcut>
          </DateShortcutRow>
          <CalendarDatePicker
            theme={theme}
            startDate={startDate}
            endDate={endDate}
            setDateRange={setDateRange}
          />
        </DateDropDown>
      </>
    )
  }

  render () {
    const { startDate, endDate } = this.props
    const { showDateDropDown } = this.state

    return (
      <Wrapper>
        <DateInput
          ref={this.dateInputRef}
          onClick={this.toggleDateDropDown}
          focused={showDateDropDown}
        >
          <DateText>{format(startDate, 'MMM D, YYYY')}</DateText>
          <DateSeperator>to</DateSeperator>
          <DateText>{format(endDate, 'MMM D, YYYY')}</DateText>
        </DateInput>

        {this.renderDateDropDown()}
      </Wrapper>
    )
  }
}

DateRange.propTypes = {
  theme: PropTypes.object,
  startDate: PropTypes.object,
  endDate: PropTypes.object,
  setDateRange: PropTypes.func
}

export default DateRange
