import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { AllHtmlEntities } from 'html-entities'
import Popover from 'components/Popover'
import { getTextFromMarkdown } from 'components/SlateEditor/utils/showdown/'
import { Menu, MenuItem } from 'elements/Menu'
import DropdownArrow from 'elements/DropdownArrow'
import StepStat from './StepStat'

const Container = styled.div`
  padding: 1rem;
  padding-bottom: calc(${props => props.theme.padding} - 1px);
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
  max-width: 100%;
  width: 100%;
  background: ${props => (props.selected ? props.theme.highlightBackground : props.theme.containerBackground)};
  cursor: pointer;
`

const PreviewWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-right: 1rem;
  text-align: left;
  flex: 1;
  overflow: hidden;
`

const StatsWrapper = styled.div`
  flex: 0;
  display: grid;
  grid-template-columns: calc(${props => props.theme.grid.columnGap} + ${props => props.theme.grid.firstColumnGap}) repeat(3, ${props => props.theme.grid.columnGap});
  align-items: end;
`

const TaskStatsWrapper = styled.div`
  flex: 0;
  display: grid;
  grid-template-columns: calc(${props => props.theme.grid.columnGap} + 7px);
  align-items: end;
`

const PopoverWrapper = styled.div`
  flex: 0;
`

const Subject = styled.div`
  font: ${props => props.theme.fonts.small};
  font-weight: 700;
  color: ${props => props.theme.titleColor};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
`

const Body = styled.div`
  font: ${props => props.theme.fonts.small};
  color: ${props => props.theme.titleColor};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  width: 100%;
`

const EmptyBody = styled(Body)`
  color: ${props => props.theme.colors.gray50};
`

const DoubleBody = styled(Body)`
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const CaretContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  height: 90%;
  margin-top: auto;
  margin-bottom: auto;
  padding: .5rem 0;
`

const SelectedIndictator = styled.div`
  background-color: ${props => props.theme.colors.blue};

  width: 10px;
  height: 10px;
  border-radius: 100%;

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.75rem;

  position: relative;
`

class StepContent extends Component {
  render () {
    const {
      subject,
      step,
      selected = false,
      onClick,
      onRemove,
      onAddTest,
      stats = Immutable.Map({}),
      theme,
      readOnly
    } = this.props

    let viewed = '-'
    let visited = '-'
    let replied = '-'
    const tasksCompleted = stats.get('tasks_completed') || '-'

    let total = stats.get('total')
    const stepId = step && step.get('_id')

    if (total) {
      viewed = Math.round((stats.get('viewed') / total) * 100)
      visited = Math.round((stats.get('visited') / total) * 100)
      replied = Math.round((stats.get('replied') / total) * 100)
    } else {
      total = '-'
    }

    const entities = new AllHtmlEntities()
    const bodyMarkdown = entities.decode(step.get('body')).trim()
    const body = getTextFromMarkdown(bodyMarkdown)

    const manual = step.get('manual')
    const isMessage = !manual || manual === 'message'
    const isTask = manual === 'task'

    return (
      <Container selected={selected} onClick={onClick}>
        {selected && <SelectedIndictator />}
        {isMessage &&
          <PreviewWrapper>
            <Subject>{subject}</Subject>
            {body && <Body>{body}</Body>}
            {!body && <EmptyBody>(Empty)</EmptyBody>}
          </PreviewWrapper>}
        {!isMessage &&
          <PreviewWrapper>
            <DoubleBody>{body.replace(/\n/g, ' ')}</DoubleBody>
          </PreviewWrapper>}
        {isMessage &&
          <StatsWrapper>
            <StepStat label='Sent' value={total} />
            <StepStat label='Opened' value={viewed} percentage />
            <StepStat label='Clicked' value={visited} percentage />
            <StepStat label='Replied' value={replied} percentage />
          </StatsWrapper>}
        {isTask &&
          <TaskStatsWrapper>
            <StepStat label='Finished' value={tasksCompleted} />
          </TaskStatsWrapper>}
        <PopoverWrapper>
          <Popover
            ref={(element) => { this.popover = element }}
            minWidth='10rem'
            position='right'
            content={
              <Menu>
                {isMessage && onAddTest && stepId &&
                  <MenuItem onClick={onAddTest}>
                    Start A/B Test
                  </MenuItem>}
                <MenuItem
                  onClick={onRemove}
                  color={theme.colors.red}
                >
                  Remove
                </MenuItem>
              </Menu>
            }
          >
            {!readOnly &&
              <CaretContainer>
                <DropdownArrow />
              </CaretContainer>}
          </Popover>
        </PopoverWrapper>
      </Container>
    )
  }
}

StepContent.propTypes = {
  subject: PropTypes.string,
  step: PropTypes.object,
  stats: PropTypes.object,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  onRemove: PropTypes.func,
  onAddTest: PropTypes.func,
  readOnly: PropTypes.bool,
  theme: PropTypes.object
}

export default withTheme(StepContent)
