export default {
  /* Interseller gray scale colors */
  black: '#444444',
  blueGray: '#dbe2f4',
  darkGray: '#67748e',
  nearWhite: '#fefeff',
  white: '#fff',

  // Shades of Gray
  gray10: '#FBFCFD',
  gray20: '#F2F4F9',
  gray30: '#E7E9ED',
  gray40: '#C7CBD2',
  gray42: '#C2C3C7',
  gray50: '#BDBDBD',
  gray60: '#a7b2c5',
  gray70: '#7A859F',

  // Interseller Blues
  darkestBlue: '#1d3257',
  darkBlue: '#243e6d',
  grayBlue: '#5469b2',
  blue: '#4D83E1',
  lightBlue: '#629ef7',
  blueText: '#2A3E6A',

  // Talent Pool
  talentPoolHeaderBackground: '#F7F9FB',
  talentPoolHeaderBackgroundSelected: '#edf1f6',
  talentPoolBorderGray: '#E9EBEE',
  talentPoolSelectedBackground: '#f8f9fc',
  talentPoolBlue: '#588BE2',

  // Interseller Complimentary Colors
  green: '#1FC866',
  yellow: '#F6CE1E',
  darkYellow: '#C4A418',
  lightYellow: '#FEFBEB',
  red: '#E4542B',
  whiteGray: '#F7F9FB',

  purple: '#6D53E3',
  orange: '#FFBD46',

  // Integration colors
  google: '#DD4B39',
  outlook: '#3278D0',
  linkedin: '#0077B5',
  twitter: '#55ACEE',
  facebook: '#3B5998',
  github: '#333333'
}
