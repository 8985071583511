/**
*
* Zap
*
*/

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Label from 'elements/Label'
import { titlecase } from 'utils/strings'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
  margin: 1rem 0;
  padding: 1rem;

  border: ${props => `solid 1px ${props.theme.borderColor}`};
  border-radius: 3px;

  cursor: pointer;
`

const IconWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0.5rem;
  align-items: center;
  justify-content: center;
  border: 1px solid ${props => props.theme.borderColor};
  padding: 8px;
  border-radius: 3px;
`

const AppIcon = styled.div`
  background-image: ${props => `url(${props.src})`};
  background-size: 100%;
  width: 32px;
  height: 32px;
`

const IconContainer = styled.div`
  width: 30%;
  display: flex;
  justify-content: space-between;
  align-items: center;
  justify-content: flex-start;
  flex-direction: row;
`

const DescriptionContainer = styled.div`
  width: 60%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

const StateContainer = styled.div`
  width: 10%;
  display: flex;
  align-items: flex-start;
  justify-content: flex-start;
  flex-direction: column;
`

const StateText = styled.div`
  font-size: 13px;
  line-height: 24px;
  border: ${props => (props.active ? `solid 1px ${props.theme.colors.green}` : `solid 1px ${props.theme.colors.gray40}`)};
  border-radius: 3px;
  padding: 0 0.5rem;
  color: ${props => (props.active ? props.theme.colors.white : props.theme.colors.gray40)};
  background-color: ${props => (props.active ? props.theme.colors.green : props.theme.colors.white)};
  text-align: center;
  text-transform: uppercase;
`

class Zap extends PureComponent {
  render () {
    const { zap } = this.props

    const firstApp = zap.getIn(['steps', 0, 'app'])
    const secondApp = zap.getIn(['steps', 1, 'app'])

    return (
      <Wrapper onClick={() => {
        window.open(zap.get('url'))
      }}
      >
        <IconContainer>
          {firstApp &&
            <IconWrapper>
              <AppIcon src={firstApp.getIn(['images', 'url_64x64'])} />
            </IconWrapper>}
          {(firstApp && secondApp) &&
            <span>→</span>}
          {secondApp &&
            <IconWrapper>
              <AppIcon src={secondApp.getIn(['images', 'url_64x64'])} />
            </IconWrapper>}
        </IconContainer>
        <DescriptionContainer>
          <Label>
            {zap.get('title')}
          </Label>
        </DescriptionContainer>
        <StateContainer>
          <StateText active={zap.get('state') === 'on'}>
            {titlecase(zap.get('state'))}
          </StateText>
        </StateContainer>
      </Wrapper>
    )
  }
}

Zap.propTypes = {
  zap: PropTypes.object
}

export default Zap
