import { fromJS } from 'immutable'
import {
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  SAVE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_SUCCESS
} from './constants'

const initialArray = {
  loading: true,
  data: [],
  error: null
}

const initialState = fromJS({
  templates: initialArray
})

function templatesReducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_TEMPLATES_REQUEST:
      return state.set('templates', state.get('templates').merge({ loading: true }))
    case FETCH_TEMPLATES_SUCCESS:
      return state.set('templates', fromJS({
        data: action.payload.templates,
        loading: false,
        error: null
      }))
    case FETCH_TEMPLATES_FAILURE:
      return state.set('templates', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case SAVE_TEMPLATE_SUCCESS: {
      const newTemplate = action.payload.template
      const currentTemplates = state.get('templates').get('data')

      let updated = false
      let updatedTemplates = currentTemplates.map((t) => {
        if (t.get('id') === newTemplate.get('id')) {
          updated = true
          return newTemplate
        }

        return t
      })

      if (!updated) {
        updatedTemplates = updatedTemplates.unshift(newTemplate)
      }

      return state.set('templates', state.get('templates').merge({ data: updatedTemplates }))
    }
    case DELETE_TEMPLATE_SUCCESS: {
      const {
        template
      } = action

      const data = state.getIn(['templates', 'data']).filter(t => (t.get('_id') !== template.get('_id')))

      return state.set('templates', fromJS({
        data,
        loading: false,
        error: null
      }))
    }
    default:
      return state
  }
}

export default templatesReducer
