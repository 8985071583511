import * as Sentry from '@sentry/react'
import * as FullStory from '@fullstory/browser'

export const bootSession = function (session) {
  const featureFlags = session.get('feature_flags') || session.get('whitelist')

  Sentry.setUser({
    email: session.get('email'),
    id: session.get('_id')
  })

  if (window.zE && window.zE instanceof Function) {
    window.zE(function () {
      window.zE.identify({
        name: session.get('display_name'),
        email: session.get('email')
      })
    })
  }

  if (featureFlags.includes('session_recording')) {
    FullStory.init({ orgId: 'PRRPK', debug: true })
    FullStory.identify(session.get('_id'), {})
  }
}
