import React, { Component } from 'react'
import ReactDOMServer from 'react-dom/server'
import ReactTooltip from 'react-tooltip'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import HeatMapTooltip from '../HeatMapTooltip'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 602px;
  max-height: 192px;
`

const StyledTooltip = styled(ReactTooltip).attrs({
  className: 'styled-tooltip'
})`
  &.styled-tooltip {
    padding: .25rem;
    border: 1px solid ${props => props.theme.colors.gray30};
    background-color: ${props => props.theme.colors.white};
    color: ${props => props.theme.colors.blue};
  }
`

const DAY_OF_WEEK_MAP = {
  0: 'Sundays',
  1: 'Mondays',
  2: 'Tuesdays',
  3: 'Wednesdays',
  4: 'Thursdays',
  5: 'Fridays',
  6: 'Saturdays'
}

class HeatMap extends Component {
  constructor (props) {
    super(props)
    this.state = { max: this.getMax(props.dataMatrix) }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (this.props.dataMatrix !== nextProps.dataMatrix) {
      this.setState({ max: this.getMax(nextProps.dataMatrix) })
    }
  }

  getMax = (dataMatrix) => {
    let max = 0
    if (dataMatrix) {
      dataMatrix.forEach((x) => {
        x.forEach((y) => {
          if (y > max) {
            max = y
          }
        })
      })
    }

    return max
  }

  getFill = (x, y) => {
    const { colors, dataMatrix } = this.props
    const { max } = this.state
    const colorTotal = colors.length - 1
    const value = dataMatrix[x][y]
    return colors[Math.round((value / (max || 1)) * colorTotal)]
  }

  getSize = () => {
    const {
      containerWidth,
      containerHeight,
      dataMatrix
    } = this.props

    // there is 1px of vertical and horitzontial padding between each cell
    const horizontalPaddingPx = (dataMatrix.length) - 1
    const verticalPaddingPx = (dataMatrix[0])
      ? (dataMatrix[0].length) - 1
      : 1

    const sizedByWidth = (containerWidth - horizontalPaddingPx) / (dataMatrix.length + 1)
    const sizedByHeight = (containerHeight - verticalPaddingPx) / (dataMatrix[0] ? dataMatrix[0].length + 1 : 1)

    /* DESCRIPTION:
     *  The base ratio set to the heat map is 600px x 192px. If for some reason the
     *  screen the user is working one cant render this heatmap at full size,
     *  we have to consider the new ratio of the container rendering the heatmap.
     *
     *  To ensure that things are being rendered to scale, we take the min of:
     *    (currentContainerWidth / (theNumberOfHorizontalCells + horizontalPadding))
     *    (currentContainerHeight / (theNumberOfVerticalCells+ verticalPadding))
     *
     *  Taking the min ensures that we don't overflow either vertically or horizontally
     */
    return Math.min(sizedByWidth, sizedByHeight)
  }

  getBlocks = () => {
    const {
      dataMatrix,
      padding,
      theme,
      tooltipUnit,
      tooltipUnitSingle,
      tooltipValueColor = theme.colors.blue
    } = this.props

    const createCustomTooltip = (value, dayOfWeek, timeOfDay) => {
      const dateLabel = `${dayOfWeek} at ${timeOfDay}`

      const tooltip = (
        <HeatMapTooltip
          theme={theme}
          value={value}
          label={dateLabel}
          unitLabel={value === 1 ? tooltipUnitSingle : tooltipUnit}
          valueColor={tooltipValueColor}
        />
      )

      // converting HeatMapTooltip diectly to compiled HTML
      return ReactDOMServer.renderToStaticMarkup(tooltip)
    }

    const size = this.getSize()
    return dataMatrix.map((domain, x) => {
      const blocks = domain.map((value, y) => {
        const dayOfWeek = DAY_OF_WEEK_MAP[y]
        let timeOfDay = null
        if (x === 0) {
          timeOfDay = '12 AM'
        } else if (x > 0 && x < 12) {
          timeOfDay = `${x} AM`
        } else if (x === 12) {
          timeOfDay = '12 PM'
        } else if (x > 12) {
          timeOfDay = `${x % 12} PM`
        }
        const customTooltip = createCustomTooltip(value, dayOfWeek, timeOfDay)
        return (
          <rect
            key={`${x}${y}`}
            width={size}
            height={size}
            y={(size + padding) * y}
            fill={this.getFill(x, y)}
            data-tip={customTooltip}
            data-for='heatmap-tooltip'
            data-html
          />
        )
      })
      const translateX = (size + padding) * (x + 1)
      const translateY = (size + padding)
      return (
        <g key={x} transform={`translate(${translateX}, ${translateY})`}>
          {blocks}
        </g>
      )
    })
  }

  getDomainLabels = () => {
    const {
      domainLabels,
      padding
    } = this.props
    const size = this.getSize()
    return domainLabels.map((domain, i) => (
      <text
        key={i}
        x={(size + padding) * (i + 1.5)}
        y={size / 1.5}
        textAnchor='middle'
        fontFamily='soleil, sans-serif'
        fontSize='12'
        fontWeight='300'
        fill='#a7b2c5'
      >
        {domain}
      </text>
    ))
  }

  getRangeLabels = () => {
    const {
      rangeLabels,
      padding
    } = this.props

    const size = this.getSize()
    return rangeLabels.map((range, i) => (
      <text
        key={i}
        x={size / 2}
        y={(size + padding) * (i + 1.65)}
        textAnchor='middle'
        fontFamily='soleil, sans-serif'
        fontSize='12'
        fontWeight='300'
        fill='#a7b2c5'
      >
        {range}
      </text>
    ))
  }

  render () {
    const {
      containerWidth,
      containerHeight
    } = this.props

    return (
      <Wrapper>
        <svg viewBox={`0 0 ${containerWidth + 2} ${containerHeight}`}>
          {this.getBlocks()}
          {this.getDomainLabels()}
          {this.getRangeLabels()}
        </svg>

        <StyledTooltip
          id='heatmap-tooltip'
          html
          multiline
        />
      </Wrapper>
    )
  }
}

HeatMap.propTypes = {
  theme: PropTypes.object,
  style: PropTypes.object,
  colors: PropTypes.array, // Color spectrum
  padding: PropTypes.number,
  dataMatrix: PropTypes.array, // x | y
  rangeLabels: PropTypes.array, // y axis
  domainLabels: PropTypes.array, // x axis
  containerWidth: PropTypes.number,
  containerHeight: PropTypes.number,
  tooltipUnit: PropTypes.string,
  tooltipValueColor: PropTypes.string,
  tooltipUnitSingle: PropTypes.any
}

HeatMap.defaultProps = {
  containerWidth: 600,
  containerHeight: 192,
  padding: 1,
  colors: ['#f2f4f9', '#e9f0fb', '#bed1f4', '#92b3ed', '#6695e5', '#3b76de', '#215dc4', '#1a4899', '#12336d']
}

export default HeatMap
