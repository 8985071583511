import React, { Component } from 'react'
import { withTheme } from 'styled-components'
import { Tooltip } from 'react-tippy'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import { getFirstMessageStepIndex } from 'utils/sequences'
import Button from 'components/Button'
import { Wrapper, DropBar } from './Style'
import StepBox from './StepBox'

class StepList extends Component {
  constructor (props) {
    super(props)
    this.state = {
      dragOverButton: false
    }

    this.dragCounter = 0
  }

  stepHasBody = () => {
    const { sequence } = this.props
    const steps = sequence.get('steps') || Immutable.List([])
    let cannotAppendStep = false
    for (const step of steps) {
      const body = step.get('body')
      if (!body) {
        cannotAppendStep = true
      }
    }
    return cannotAppendStep
  }

  handleDragStart = (event) => {
    event.dataTransfer.setData('dragIndex', event.target.getAttribute('data-stepbox-index'))
  }

  handleOnDrop = (event) => {
    const draggedIndex = parseInt(event.dataTransfer.getData('dragIndex'))
    const droppedIndex = parseInt(event.target.closest('[data-stepbox-index]').getAttribute('data-stepbox-index'))
    const { updateStepOrder } = this.props
    updateStepOrder(draggedIndex, droppedIndex)
  }

  createStepRowsList = () => {
    const {
      sequence,
      stepStats = Immutable.Map({}),
      selectedStepIndex,
      testSelected,
      onStepSelected,
      onRemoveStep,
      onAddTestStep,
      onEditSchedule,
      readOnly = false
    } = this.props

    const steps = sequence.get('steps') || Immutable.List([])
    const otherSteps = sequence.get('other_steps') || Immutable.List([])
    const otherStepsMap = otherSteps.reduce((acc, step) => {
      acc[step.get('_step')] = step
      return acc
    }, {})

    let subject = sequence.get('subject')
    const sStats = stepStats || Immutable.Map({})
    const firstMessageStepIndex = getFirstMessageStepIndex(sequence)

    const stepRows = []
    steps.forEach((step, i) => {
      const stepIdx = i.toString()
      const stepId = step.get('_id') || stepIdx
      const stats = step.get('type') === 'message' ? sStats.get(stepId) : sStats.get(stepIdx)
      const stepIdxStats = sStats.get(stepIdx)
      const testStep = otherStepsMap[stepId]
      let testStats
      if (testStep) {
        testStats = sStats.get(testStep.get('_id'))
      }
      let stepSubject = subject
      if (step.get('subject')) {
        subject = step.get('subject')
        stepSubject = subject
      } else if (i !== firstMessageStepIndex) {
        stepSubject = `RE: ${subject}`
      }

      stepRows.push({
        stepId,
        sequence,
        step,
        stepStats,
        stepIdxStats,
        testStep,
        stepSubject,
        stats,
        testStats,
        selected: selectedStepIndex === i,
        testSelected,
        onStepSelected,
        onRemoveStep,
        onAddTestStep,
        onEditSchedule,
        readOnly
      })
    })

    return stepRows
  }

  renderStepRows = (stepRows) => {
    return stepRows.map((row, index) => {
      return (
        <StepBox
          key={row.stepId}
          sequence={row.sequence}
          step={row.step}
          stepStats={row.stepStats}
          testStep={row.testStep}
          stepIndex={index}
          subject={row.stepSubject}
          stats={row.stats}
          stepIdxStats={row.stepIdxStats}
          testStats={row.testStats}
          selected={row.selected}
          testSelected={row.testSelected}
          onClick={(selectedTestStep) => row.onStepSelected(index, selectedTestStep)}
          onRemove={(selectedTestStep) => row.onRemoveStep(index, selectedTestStep)}
          onAddTest={() => row.onAddTestStep(index)}
          onEditSchedule={() => row.onEditSchedule(index)}
          onDragStart={event => this.handleDragStart(event)}
          onDrop={(event, target) => this.handleOnDrop(event, target)}
          readOnly={row.readOnly}
        />
      )
    })
  }

  render () {
    const {
      onAddStep,
      readOnly
    } = this.props
    const { dragOverButton } = this.state
    const stepRows = this.createStepRowsList()

    return (
      <Wrapper>
        {this.renderStepRows(stepRows)}
        <DropBar visible={dragOverButton} />
        {!readOnly &&
          <Tooltip
            disabled={!this.stepHasBody()}
            title='Please fill out all of your other steps and save before adding another follow up email'
            position='top'
            trigger='mouseenter'
            arrow
          >
            <Button
              full
              primary
              label='Add Step'
              handleClick={onAddStep}
              disabled={this.stepHasBody() || readOnly}
              data-stepbox-index={stepRows.length}
              onDragOver={(event) => {
                event.preventDefault()
                event.stopPropagation()
              }}
              onDragEnter={(event) => {
                event.preventDefault()
                if (this.dragCounter === 0) {
                  this.setState({ dragOverButton: true })
                }
                this.dragCounter++
              }}
              onDragLeave={(event) => {
                event.preventDefault()
                this.dragCounter--
                if (this.dragCounter === 0) {
                  this.setState({ dragOverButton: false })
                }
              }}
              onDragEnd={() => {
                this.setState({ dragOverButton: false })
              }}
              onDrop={(event) => {
                event.preventDefault()
                event.persist()
                this.handleOnDrop(event)
                this.dragCounter = 0
                this.setState({ dragOverButton: false })
              }}
            />
          </Tooltip>}
      </Wrapper>
    )
  }
}

StepList.propTypes = {
  sequence: PropTypes.object,
  stepStats: PropTypes.object,
  selectedStepIndex: PropTypes.number,
  testSelected: PropTypes.bool,
  onStepSelected: PropTypes.func,
  theme: PropTypes.object,
  onAddStep: PropTypes.func,
  onRemoveStep: PropTypes.func,
  onAddTestStep: PropTypes.func,
  onEditSchedule: PropTypes.func,
  updateStepOrder: PropTypes.func,
  readOnly: PropTypes.bool
}

StepList.defaultProps = {
  selectedStepIndex: 0,
  stepStats: Immutable.Map({})
}

export default withTheme(StepList)
