import { fromJS } from 'immutable'
import { put, takeLatest, call } from 'redux-saga/effects'
import api, { messageFromError } from 'utils/api'

import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchTemplatesSuccess,
  fetchTemplatesFailure,
  saveTemplateSuccess,
  saveTemplateFailure,
  deleteTemplateSuccess,
  deleteTemplateFailure
} from './actions'
import {
  FETCH_TEMPLATES_REQUEST,
  SAVE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_REQUEST
} from './constants'

const get = (url, query) => api.get(url, {
  params: query
})
const post = (url, params) => api.post(url, params)
const update = (url, params) => api.put(url, params)
const remove = url => api.delete(url)

export function * fetchTemplates (action) {
  try {
    const filter = action.filter

    const params = {
      filter
    }

    const response = yield call(get, '/templates', params)
    const templates = fromJS(response.data)

    yield put(fetchTemplatesSuccess(templates))
  } catch (err) {
    yield put(fetchTemplatesFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * saveTemplate (action) {
  const payload = action.payload

  try {
    let url = '/templates'
    if (payload.id) {
      url = '/templates/' + payload.id
    }

    const response = yield call(payload.id ? update : post, url, payload)
    const template = fromJS(response.data)

    yield put(saveTemplateSuccess(template))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Template saved! 🚀'
    }))
  } catch (err) {
    yield put(saveTemplateFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * deleteTemplate (action) {
  const {
    template
  } = action
  try {
    yield call(remove, `/templates/${template.get('id')}`)
    yield put(deleteTemplateSuccess({
      template
    }))
    const name = template.get('name')
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Deleted template ${name}! 🚀`
    }))
  } catch (error) {
    yield put(deleteTemplateFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

function * fetchTemplatesRequest () {
  yield takeLatest(FETCH_TEMPLATES_REQUEST, fetchTemplates)
}

function * saveTemplateRequest () {
  yield takeLatest(SAVE_TEMPLATE_REQUEST, saveTemplate)
}

function * deleteTemplateRequest () {
  yield takeLatest(DELETE_TEMPLATE_REQUEST, deleteTemplate)
}

export default [
  fetchTemplatesRequest,
  saveTemplateRequest,
  deleteTemplateRequest
]
