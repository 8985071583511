import React from 'react'

/* eslint-disable */
const FileInvalid = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 28} height={height || 34}  viewBox="0 0 28 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Valid" fillRule="nonzero">
            <g id="upload">
                <g id="Group" stroke={color || "#7A859F"}>
                    <path d="M24.578,33.293 L2.715,33.293 C1.337,33.293 0.215,32.172 0.215,30.793 L0.215,2.5 C0.215,1.121 1.337,0 2.715,0 L18.592,0 C18.868,0 19.092,0.224 19.092,0.5 C19.092,0.776 18.868,1 18.592,1 L2.715,1 C1.888,1 1.215,1.673 1.215,2.5 L1.215,30.793 C1.215,31.62 1.888,32.293 2.715,32.293 L24.578,32.293 C25.405,32.293 26.078,31.62 26.078,30.793 L26.078,8.704 C26.078,8.428 26.302,8.204 26.578,8.204 C26.854,8.204 27.078,8.428 27.078,8.704 L27.078,30.794 C27.078,32.172 25.957,33.293 24.578,33.293 Z" id="Shape"></path>
                    <path d="M26.578,9.204 L18.592,9.204 C18.316,9.204 18.092,8.98 18.092,8.704 L18.092,0.717 C18.092,0.441 18.316,0.217 18.592,0.217 C18.868,0.217 19.092,0.441 19.092,0.717 L19.092,8.203 L26.578,8.203 C26.854,8.203 27.078,8.427 27.078,8.703 C27.078,8.979 26.855,9.204 26.578,9.204 Z" id="Shape"></path>
                    <path d="M26.578,9.204 C26.448,9.204 26.318,9.153 26.22,9.053 L18.234,0.85 C18.042,0.653 18.046,0.335 18.244,0.143 C18.441,-0.05 18.758,-0.045 18.951,0.153 L26.937,8.356 C27.129,8.553 27.125,8.871 26.927,9.063 C26.83,9.157 26.704,9.204 26.578,9.204 Z" id="Shape"></path>
                </g>
                <g id="close" transform="translate(8.000000, 11.000000)" fill={color || "#E4542B"}>
                    <path d="M7.06126618,6.00012508 L11.7801172,1.28107736 C12.0732943,0.988138173 12.0732943,0.512831203 11.7801172,0.219892014 C11.48694,-0.0732973379 11.0121532,-0.0732973379 10.718976,0.219892014 L6.00012508,4.93893973 L1.28102395,0.219892014 C0.987846824,-0.0732973379 0.513059974,-0.0732973379 0.219882846,0.219892014 C-0.073294282,0.512831203 -0.073294282,0.988138173 0.219882846,1.28107736 L4.93898397,6.00012508 L0.219882846,10.7191728 C-0.073294282,11.012112 -0.073294282,11.487419 0.219882846,11.7803581 C0.36647141,11.9267027 0.55858748,12 0.750453399,12 C0.942319318,12 1.13443539,11.9267027 1.28102395,11.780108 L6.00012508,7.06106027 L10.718976,11.780108 C10.8655646,11.9267027 11.0576807,12 11.2495466,12 C11.4414125,12 11.6335286,11.9267027 11.7801172,11.780108 C12.0732943,11.4871688 12.0732943,11.0118618 11.7801172,10.7189226 L7.06126618,6.00012508 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

export default FileInvalid;
