import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Table, WindowScroller } from 'react-virtualized'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  background: ${props => props.theme.containerBackground};

  .ReactVirtualized__Grid {
    outline: none;
  }
`

class VirtualTable extends Component {
  render () {
    const {
      headerRowRenderer,
      rowRenderer,
      rowCount,
      rowHeight,
      data,
      onScroll
    } = this.props

    return (
      <Wrapper>
        <WindowScroller>
          {({
            height,
            width,
            scrollTop,
            isScrolling
          }) => (
            <Table
              width={width}
              height={height}
              headerHeight={50}
              rowHeight={rowHeight}
              rowCount={rowCount}
              rowGetter={({ index }) => data.get(index)}
              scrollTop={scrollTop}
              headerRowRenderer={headerRowRenderer}
              rowRenderer={rowRenderer}
              onScroll={onScroll}
              isScrolling={isScrolling}
              autoHeight
              style={{
                pointerEvents: 'auto'
              }}
            />
          )}
        </WindowScroller>
      </Wrapper>
    )
  }
}

VirtualTable.propTypes = {
  data: PropTypes.object.isRequired,
  headerRowRenderer: PropTypes.func,
  rowRenderer: PropTypes.func,
  rowCount: PropTypes.number.isRequired,
  rowHeight: PropTypes.number,
  onScroll: PropTypes.func
}

export default VirtualTable
