import React from 'react'

/* eslint-disable */
const Bin = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
    <svg onClick={onClick} className={className} width="12px" height="17px" viewBox="0 0 12 17" version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Campaign-Sequence" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
            <g id="Campaign-Sequence---A/B" transform="translate(-1087.000000, -200.000000)" stroke="#7A859F">
                <g id="delete" transform="translate(1075.000000, 191.000000)">
                    <g id="348---Trash-Bin-(Outline)" transform="translate(12.000000, 10.000000)">
                        <path d="M11.0769231,1.55465587 L10.5804,13.7327935 C10.5804,13.7327935 10.5590769,14.7692308 9.58836923,14.7692308 L2.41163077,14.7692308 C1.44092308,14.7692308 1.4196,13.7327935 1.4196,13.7327935 L0.923076923,1.55465587 M8.03051538,1.55465587 C8.03051538,1.55465587 8.07011538,0 7.01513077,0 L5.73066923,0 L5.76163846,0 L4.47692308,0 C3.42193846,0 3.46153846,1.55465587 3.46153846,1.55465587" id="Stroke-268"></path>
                        <path d="M6,4.42218247 L6,12.9230769 M3.39130435,4.42218247 L3.39130435,12.9230769 M8.60869565,4.42218247 L8.60869565,12.9230769 M0,1.84615385 L12,1.84615385" id="Stroke-269" strokeLinecap="round"></path>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Bin;
