export const FETCH_SESSION_ADDRESSES_REQUEST = 'Settings/FETCH_SESSION_ADDRESSES_REQUEST'
export const FETCH_SESSION_ADDRESSES_SUCCESS = 'Settings/FETCH_SESSION_ADDRESSES_SUCCESS'
export const FETCH_SESSION_ADDRESSES_FAILURE = 'Settings/FETCH_SESSION_ADDRESSES_FAILURE'

export const FETCH_TEAM_SETTINGS_REQUEST = 'Settings/FETCH_TEAM_SETTINGS_REQUEST'
export const FETCH_TEAM_SETTINGS_SUCCESS = 'Settings/FETCH_TEAM_SETTINGS_SUCCESS'
export const FETCH_TEAM_SETTINGS_FAILURE = 'Settings/FETCH_TEAM_SETTINGS_FAILURE'

export const UPDATE_TEAM_SETTINGS_REQUEST = 'Settings/UPDATE_TEAM_SETTINGS_REQUEST'
export const UPDATE_TEAM_SETTINGS_SUCCESS = 'Settings/UPDATE_TEAM_SETTINGS_SUCCESS'
export const UPDATE_TEAM_SETTINGS_FAILURE = 'Settings/UPDATE_TEAM_SETTINGS_FAILURE'

export const FETCH_TEAM_DOMAIN_REQUEST = 'Settings/FETCH_TEAM_DOMAIN_REQUEST'
export const FETCH_TEAM_DOMAIN_SUCCESS = 'Settings/FETCH_TEAM_DOMAIN_SUCCESS'
export const FETCH_TEAM_DOMAIN_FAILURE = 'Settings/FETCH_TEAM_DOMAIN_FAILURE'

export const FETCH_BLOCKLIST_REQUEST = 'Settings/FETCH_BLOCKLIST_REQUEST'
export const FETCH_BLOCKLIST_SUCCESS = 'Settings/FETCH_BLOCKLIST_SUCCESS'
export const FETCH_BLOCKLIST_FAILURE = 'Settings/FETCH_BLOCKLIST_FAILURE'

export const FETCH_TEAM_BLOCKLIST_REQUEST = 'Settings/FETCH_TEAM_BLOCKLIST_REQUEST'
export const FETCH_TEAM_BLOCKLIST_SUCCESS = 'Settings/FETCH_TEAM_BLOCKLIST_SUCCESS'
export const FETCH_TEAM_BLOCKLIST_FAILURE = 'Settings/FETCH_TEAM_BLOCKLIST_FAILURE'

export const UPDATE_BLOCKLIST_REQUEST = 'Settings/UPDATE_BLOCKLIST_REQUEST'
export const UPDATE_BLOCKLIST_SUCCESS = 'Settings/UPDATE_BLOCKLIST_SUCCESS'
export const UPDATE_BLOCKLIST_FAILURE = 'Settings/UPDATE_BLOCKLIST_FAILURE'

export const UPDATE_TEAM_BLOCKLIST_REQUEST = 'Settings/UPDATE_TEAM_BLOCKLIST_REQUEST'
export const UPDATE_TEAM_BLOCKLIST_SUCCESS = 'Settings/UPDATE_TEAM_BLOCKLIST_SUCCESS'
export const UPDATE_TEAM_BLOCKLIST_FAILURE = 'Settings/UPDATE_TEAM_BLOCKLIST_FAILURE'

export const FETCH_CONFIG_REQUEST = 'Settings/FETCH_CONFIG_REQUEST'
export const FETCH_CONFIG_SUCCESS = 'Settings/FETCH_CONFIG_SUCCESS'
export const FETCH_CONFIG_FAILURE = 'Settings/FETCH_CONFIG_FAILURE'

export const RESUBSCRIBE_EMAIL_REQUEST = 'Settings/RESUBSCRIBE_EMAIL_REQUEST'

export const FETCH_CONTACT_CSV_REQUEST = 'Settings/FETCH_CONTACT_CSV_REQUEST'

export const FETCH_CALENDARS_REQUEST = 'Settings/FETCH_CALENDARS_REQUEST'
export const FETCH_CALENDARS_SUCCESS = 'Settings/FETCH_CALENDARS_SUCCESS'
export const FETCH_CALENDARS_FAILURE = 'Settings/FETCH_CALENDARS_FAILURE'

export const UPDATE_CALENDARS_REQUEST = 'Settings/UPDATE_CALENDARS_REQUEST'
export const UPDATE_CALENDARS_SUCCESS = 'Settings/UPDATE_CALENDARS_SUCCESS'
export const UPDATE_CALENDARS_FAILURE = 'Settings/UPDATE_CALENDARS_FAILURE'

export const FETCH_TEAM_LOGS_REQUEST = 'Settings/FETCH_TEAM_LOGS_REQUEST'
export const FETCH_TEAM_LOGS_SUCCESS = 'Settings/FETCH_TEAM_LOGS_SUCCESS'
export const FETCH_TEAM_LOGS_FAILURE = 'Settings/FETCH_TEAM_LOGS_FAILURE'

export const FETCH_PURGE_EMAIL_COUNT_REQUEST = 'Settings/FETCH_PURGE_EMAIL_COUNT_REQUEST'
export const FETCH_PURGE_EMAIL_COUNT_SUCCESS = 'Settings/FETCH_PURGE_EMAIL_COUNT_SUCCESS'
export const FETCH_PURGE_EMAIL_COUNT_FAILURE = 'Settings/FETCH_PURGE_EMAIL_COUNT_FAILURE'

export const SHOW_PURGE_MODAL = 'Settings/SHOW_PURGE_MODAL'
export const HIDE_PURGE_MODAL = 'Settings/HIDE_PURGE_MODAL'

export const DELETE_PURGE_EMAIL_REQUEST = 'Settings/DELETE_PURGE_EMAIL_REQUEST'
export const DELETE_PURGE_EMAIL_SUCCESS = 'Settings/DELETE_PURGE_EMAIL_SUCCESS'
export const DELETE_PURGE_EMAIL_FAILURE = 'Settings/DELETE_PURGE_EMAIL_FAILURE'

export const CSV_DOWNLOAD_OPTIONS = [
  {
    label: 'Bounced',
    value: 'bounced',
    index: 0
  },
  {
    label: 'Unsubscribed',
    value: 'unsubscribed',
    index: 1
  }
]

export const FETCH_CAMPAIGN_STEP_COPY_REQUEST = 'Settings/FETCH_CAMPAIGN_STEP_COPY_REQUEST'
