import React from 'react'
import Html from 'slate-html-serializer'

const TAG_TYPES = {
  INLINE: 'inline',
  BLOCK: 'block',
  MARK: 'mark',
  EMOJI: 'emoji',
  IMAGE: 'image'
}

const SLATE_TAGS = {
  BLOCK: {
    p: 'paragraph',
    li: 'list_item',
    ul: 'unordered_list',
    ol: 'ordered_list',
    blockquote: 'blockquote',
    img: 'image',
    div: 'div',
    h1: 'h1',
    h2: 'h2',
    h3: 'h3',
    h4: 'h4',
    h5: 'h5',
    h6: 'h6'
  },
  MARK: {
    strong: 'bold',
    b: 'bold',
    u: 'underline',
    em: 'italic',
    s: 'strikethrough'
  },
  INLINE: {
    a: 'link',
    span: 'span',
    time: 'time'
  }
}

//
// NOTE:
//  - Methods that "import as Slate object" are converting plain HTML into an
//    object structure that Slate uses to represent text
//
//  - Methods that "export as HTML" are converting slate objects into HTML.
//    The exported HTML will be converted to MarkDown before saving
//
class BodySerializer {
  static _importBlockAsSlateObject (el, next) {
    const element = el.tagName.toLowerCase()
    const block = SLATE_TAGS.BLOCK[element]

    if (!block) {
      return
    }

    const elementId = el.getAttribute('id')
    if (block === SLATE_TAGS.BLOCK.div && elementId === 'signature') {
      return null
    }

    let nodeData = {}
    if (block === TAG_TYPES.IMAGE) {
      nodeData = { className: el.getAttribute('class') }
      const srcAttr = el.getAttribute('src')
      if (srcAttr) {
        nodeData = { ...nodeData, src: srcAttr }
      }

      const hrefAttr = el.getAttribute('data-href')
      if (hrefAttr) {
        nodeData = { ...nodeData, href: hrefAttr, title: hrefAttr }
      }
    }

    return {
      object: TAG_TYPES.BLOCK,
      type: block,
      data: nodeData,
      nodes: next(el.childNodes)
    }
  }

  static _importMarkAsSlateObject (el, next) {
    const mark = SLATE_TAGS.MARK[el.tagName.toLowerCase()]
    if (mark) {
      return {
        object: 'mark',
        type: mark,
        nodes: next(el.childNodes)
      }
    }
  }

  static _importInlineAsSlateObject (el, next) {
    const tag = el.tagName.toLowerCase()
    const inline = SLATE_TAGS.INLINE[tag]
    switch (inline) {
      case 'time': {
        return {
          object: 'inline',
          type: inline,
          nodes: next(el.childNodes),
          data: {
            href: el.getAttribute('datetime')
          }
        }
      }

      case 'link': {
        return {
          object: 'inline',
          type: inline,
          nodes: next(el.childNodes),
          data: {
            href: el.getAttribute('href')
          }
        }
      }

      case 'span': {
        const emojiCode = el.getAttribute('code')
        const id = el.getAttribute('id')

        if (emojiCode !== null) {
          return {
            object: 'inline',
            type: 'emoji',
            nodes: next(el.childNodes),
            data: {
              code: el.getAttribute('code')
            }
          }
        } else if (id !== null) {
          return {
            object: 'inline',
            type: 'custom_field',
            data: {
              fieldValue: el.getAttribute('id')
            }
          }
        }
      }
    }
  }

  static _exportBlockAsHtml (slateObject, children) {
    if (slateObject.object !== TAG_TYPES.BLOCK) {
      return
    }

    switch (slateObject.type) {
      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6': {
        return React.createElement(slateObject.type, {}, children)
      }

      case TAG_TYPES.IMAGE: {
        const src = slateObject.data.get('src')
        const href = slateObject.data.get('href')

        if (href) {
          return <img src={src} title={href} data-href={href} />
        } else {
          return <img src={src} />
        }
      }

      case 'paragraph': {
        return <p className={slateObject.data.get('className')}>{children}</p>
      }

      case 'ordered_list': {
        return <ol className={slateObject.data.get('className')}>{children}</ol>
      }

      case 'unordered_list': {
        return <ul className={slateObject.data.get('className')}>{children}</ul>
      }

      case 'list_item': {
        return <li className={slateObject.data.get('className')}>{children}</li>
      }

      case 'blockquote': {
        return (
          <blockquote
            className={slateObject.data.get('className')}
          >
            {children}
          </blockquote>
        )
      }
    }
  }

  static _exportMarkAsHtml (slateObject, children) {
    if (slateObject.object !== 'mark') {
      return
    }

    switch (slateObject.type) {
      case 'bold': {
        return <b>{children}</b>
      }
      case 'italic': {
        return <em>{children}</em>
      }
      case 'underline': {
        return <u>{children}</u>
      }
    }
  }

  static _exportInlineAsHtml (slateObject, children) {
    if (slateObject.object !== 'inline') {
      return
    }

    switch (slateObject.type) {
      case 'custom_field': {
        return <span>{slateObject.data.get('fieldValue')}</span>
      }
      case 'time': {
        return (
          <time dateTime={slateObject.data.get('datetime')}>
            {children}
          </time>
        )
      }
      case 'emoji': {
        return (
          <span
            contentEditable={false}
            onDrop={e => e.preventDefault()}
          >
            {slateObject.data.get('code')}
          </span>
        )
      }
      case 'link': {
        return (
          <a href={slateObject.data.get('href')}>
            {children}
          </a>
        )
      }
    }
  }

  static createRules () {
    // NOTE:
    // - this is undocumentated inside slate, but the order of this
    //   array matters. `inline` tags must be parsed before `block`
    //   and `mark` tags.
    return [
      {
        serialize: BodySerializer._exportInlineAsHtml,
        deserialize: BodySerializer._importInlineAsSlateObject
      },
      {
        serialize: BodySerializer._exportBlockAsHtml,
        deserialize: BodySerializer._importBlockAsSlateObject
      },
      {
        serialize: BodySerializer._exportMarkAsHtml,
        deserialize: BodySerializer._importMarkAsSlateObject
      }
    ]
  }
}

//
// NOTE:
//  - Methods that "import as Slate object" are converting plain HTML into an
//    object structure that Slate uses to represent text
//
//  - Methods that "export as HTML" are converting the slate objects into HTML.
//    The exported HTML will be converted to MarkDown before saving
//

class SubjectSerializer {
  static _importBlockAsSlateObject (el, next) {
    const tagName = el.tagName.toLowerCase()
    const block = SLATE_TAGS.BLOCK[tagName]
    if (block) {
      return {
        object: TAG_TYPES.BLOCK,
        type: block,
        data: {
          className: el.getAttribute('class')
        },
        nodes: next(el.childNodes)
      }
    }
  }

  static _importMarkAsSlateObject (el, next) {

  }

  static _importInlineAsSlateObject (el, next) {
    const tag = el.tagName.toLowerCase()
    switch (tag) {
      case 'span': {
        const emojiCode = el.getAttribute('code')
        const id = el.getAttribute('id')

        if (emojiCode !== null) {
          return {
            object: 'inline',
            type: 'emoji',
            nodes: next(el.childNodes),
            data: {
              code: el.getAttribute('code')
            }
          }
        } else if (id !== null) {
          return {
            object: 'inline',
            type: 'custom_field',
            data: {
              fieldValue: el.getAttribute('id')
            }
          }
        } else {
          console.warn(`WARNING: Subject Line Desrializer - Do not support ${el.tagName.toLowerCase()} as inline tag. This function will silently return`)
        }
      }
    }
  }

  static _exportBlockAsHtml (slateObject, children) {
    if (slateObject.object !== 'block') {
      return
    }

    switch (slateObject.type) {
      case 'paragraph': {
        return <p className={slateObject.data.get('className')}>{children}</p>
      }
    }
  }

  static _exportMarkAsHtml (slateObject, children) {
    return <>{children}</>
  }

  static _exportInlineAsHtml (slateObject, children) {
    if (slateObject.object !== 'inline') {
      return
    }

    switch (slateObject.type) {
      case 'custom_field': {
        return (
          <span>{slateObject.data.get('fieldValue')}</span>
        )
      }
      case 'emoji': {
        return (
          <span
            contentEditable={false}
            onDrop={e => e.preventDefault()}
          >
            {slateObject.data.get('code')}
          </span>
        )
      }
    }
  }

  static createRules () {
    // NOTE:
    // - this is undocumentated inside slate, but the order of this
    //   array matters. `inline` tags must be parsed before `block`
    //   and `mark` tags.
    return [
      {
        serialize: SubjectSerializer._exportInlineAsHtml,
        deserialize: SubjectSerializer._importInlineAsSlateObject
      },
      {
        serialize: SubjectSerializer._exportBlockAsHtml,
        deserialize: SubjectSerializer._importBlockAsSlateObject
      },
      {
        serialize: SubjectSerializer._exportMarkAsHtml,
        deserialize: SubjectSerializer._importMarkAsSlateObject
      }
    ]
  }
}

const subjectRules = () => {
  return new Html({
    rules: SubjectSerializer.createRules()
  })
}

const bodyRules = () => {
  return new Html({
    rules: BodySerializer.createRules()
  })
}

export {
  subjectRules,
  bodyRules
}
