import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import emojis from './EmojiMap'

import { MenuHeader } from 'elements/Menu'

const Wrapper = styled.div`
  z-index: 100;
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  overflow-y: auto;
  width: 300px;
  height: 380px;
  margin-top: 1rem;
  box-shadow: ${props => props.theme.shadow};
`

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
`

const EmojiSectionBanner = styled(MenuHeader)`
  padding: 24px 0 10px 8px;
  text-align: left;
`

const EmojiSection = styled.div`
  padding: 0;
  padding-left: 18px;
  margin-bottom: 16px;
  text-align: left;
`

const StyledEmojiSpan = styled.span`
  width: 27px;
  height: 27px;
  text-align: center;
  position: relative;
  display: inline-block;
  margin: 5px;
  border-radius: 3px;
  font-size: 20px;
  line-height: 30px;
  background-color: ${props => props.theme.colors.white};
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.blueGray};
  }

  @media only screen and (-webkit-min-device-pixel-ratio: 2), not all, not all, not all, only screen and (min-resolution: 192dpi), only screen and (min-resolution: 2dppx) {
    padding-left: 3px;
    line-height: 26px;
  }
`

class EmojiMenu extends React.Component {
  constructor (props) {
    super(props)
    this.wrapper = React.createRef()
    this.handleEscapeKey = this.handleEscapeKey.bind(this)
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEscapeKey, false)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEscapeKey, false)
  }

  handleEscapeKey = event => {
    const ESCAPE_KEY = 27
    const { toggleToolbarMenuVisibility } = this.props
    if (event.keyCode === ESCAPE_KEY) {
      toggleToolbarMenuVisibility('showEmojiMenu')
    }
  }

  render () {
    const { editorRef, toggleToolbarMenuVisibility } = this.props

    const emojiMenu = Object.keys(emojis)
      .map(key => {
        return (
          <EmojiSection key={key}>
            <EmojiSectionBanner>
              {key.split('_').join(' ')}
            </EmojiSectionBanner>
            {emojis[key].map(emojiMetadata => {
              return (
                <StyledEmojiSpan
                  onMouseDown={() => {
                    editorRef.current.insertEmoji(emojiMetadata.emoji)
                  }}
                  key={emojiMetadata.name}
                >
                  {emojiMetadata.emoji}
                </StyledEmojiSpan>
              )
            })}
          </EmojiSection>
        )
      })

    return (
      <div>
        <Backdrop
          onClick={() => toggleToolbarMenuVisibility('showEmojiMenu')}
        />
        <Wrapper ref={this.wrapper}>
          {emojiMenu}
        </Wrapper>
      </div>
    )
  }
}

EmojiMenu.propTypes = {
  toggleToolbarMenuVisibility: PropTypes.func,
  editorRef: PropTypes.object,
  theme: PropTypes.object
}

export default withTheme(EmojiMenu)
