import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Loading from 'components/Loading'

import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding: 2rem 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

function ContactInaccuracyModal ({
  isOpen,
  contact,
  contactInaccuracyLoading,
  onCancel,
  onConfirm
}) {
  return (
    <Modal
      isOpen={isOpen}
      onModalClose={onCancel}
    >
      <Wrapper>
        <InputContainer>
          <Title align='center'>Report Incorrect Email Address</Title>
          {contactInaccuracyLoading
            ? <Loading />
            : (
              <Label mt='0.5rem' align='center'>
                {contact &&
                  'This will notify our data team and our data partners to investigate how ' + contact?.get('email') + ' appeared for ' + contact?.get('display_name') + '.'}
              </Label>
              )}
        </InputContainer>
        <Divider />
        <ButtonContainer>
          <Button
            label='Cancel'
            onClick={onCancel}
            mr='0.5rem'
          />
          <Button
            primary
            label='Report'
            onClick={() => {
              onConfirm(contact.get('_id'))
            }}
          />
        </ButtonContainer>
      </Wrapper>
    </Modal>
  )
}

ContactInaccuracyModal.propTypes = {
  isOpen: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  contact: PropTypes.oneOfType([
    PropTypes.bool,
    PropTypes.object
  ]),
  contactInaccuracyLoading: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default ContactInaccuracyModal
