import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

export const CardTypes = {
  Visa: 'visa',
  MasterCard: 'mastercard',
  Amex: 'amex',
  Discover: 'discover',
  DinersClub: 'diners',
  JCB: 'jcb',
  Unknown: 'unknown'
}

const Wrapper = styled.div`
  font-size: 18px;
  line-height: 21px;
  // margin-top: -2px;
`

class CardImage extends Component {
  cardImageForType = (type, height, color) => {
    const cardType = type || ''
    switch (cardType.toLowerCase()) {
      case CardTypes.Amex:
        return (
          <FontAwesomeIcon
            icon={['fab', 'cc-amex']}
            color={color}
            transform={{
              size: height
            }}
          />
        )
      case CardTypes.Discover:
        return (
          <FontAwesomeIcon
            icon={['fab', 'cc-discover']}
            color={color}
            transform={{
              size: height
            }}
          />
        )
      case CardTypes.MasterCard:
        return (
          <FontAwesomeIcon
            icon={['fab', 'cc-mastercard']}
            color={color}
            transform={{
              size: height
            }}
          />
        )
      case CardTypes.Visa:
        return (
          <FontAwesomeIcon
            icon={['fab', 'cc-visa']}
            color={color}
            transform={{
              size: height
            }}
          />
        )
      case CardTypes.JCB:
        return (
          <FontAwesomeIcon
            icon={['fab', 'cc-jcb']}
            color={color}
            transform={{
              size: height
            }}
          />
        )
      case CardTypes.DinersClub:
        return (
          <FontAwesomeIcon
            icon={['fab', 'cc-diners-club']}
            color={color}
            transform={{
              size: height
            }}
          />
        )
      default:
        return (
          <FontAwesomeIcon
            icon={['fad', 'credit-card-front']}
            color={color}
            transform={{
              size: height
            }}
          />
        )
    }
  }

  render () {
    const {
      height = 25,
      cardType,
      color
    } = this.props

    const card = this.cardImageForType(cardType, height, color)
    return (
      <Wrapper>
        {card && card}
      </Wrapper>
    )
  }
}

CardImage.propTypes = {
  cardType: PropTypes.string,
  height: PropTypes.number,
  color: PropTypes.string
}

export default CardImage
