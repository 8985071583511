/**
 * The team state selectors
 */

import { createSelector } from 'reselect'

const selectTeamDomain = () => state => state.get('team')

const selectMembers = () => createSelector(
  selectTeamDomain(),
  teamState => teamState.get('members')
)

const selectInvites = () => createSelector(
  selectTeamDomain(),
  teamState => teamState.get('invites')
)

const selectTeamCounts = () => createSelector(
  selectTeamDomain(),
  teamState => teamState.get('counts')
)

const selectOwnerCrm = () => createSelector(
  selectTeamDomain(),
  teamState => teamState.get('ownerCrm')
)

export {
  selectMembers,
  selectInvites,
  selectTeamCounts,
  selectOwnerCrm
}
