import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled, { withTheme } from 'styled-components'

import ConfirmModal from 'components/ConfirmModal'
import Loading from 'components/Loading'
import Button from 'components/Button'

import Label from 'elements/Label'
import Subtitle from 'elements/Subtitle'
import Title from 'elements/Title'
import ComponentError from 'elements/ComponentError'
import LogoImage from 'elements/LogoImage'

import { success } from 'utils/toast'

import CrmList from './CrmList'
import ConfigureCRM from './ConfigureCRM'

const ContentContainer = styled.div`
  background-color: ${props => props.theme.containerBackground};
  flex: 1 0 auto;
  border: ${props => `solid 1px ${props.theme.borderColor}`};
  padding: ${props => props.theme.padding};
  margin-bottom: 2rem;
  max-width: 42rem;
`

const TitleContainer = styled.div`
  padding: ${props => props.theme.padding};
`

const SubtitleContainer = styled.div`
  padding: ${props => props.theme.padding};
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  position: relative;
`

const ButtonContainer = styled.div`
  flex: 1 0 auto;
  align-items: end;
  justify-content: end;
  display: flex;
`

class Crm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showDeleteConfirmModal: false,
      showCloneSettingsModal: false
    }
  }

  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props

    if (actions) {
      actions.fetchCrmList()
      actions.fetchActiveCrm({ include_import_options: true })
      actions.fetchSequenceFields()
    }

    const { reconnected } = this.props.location.query
    if (reconnected !== undefined) {
      success('Successfully reconnected!')
    }
  }

  connectCRM = (type, params) => {
    const auth = params.auth
    this.props.actions.createCrm(
      type,
      auth
    )
    this.props.router.push('/integrations/crm')
  }

  updateCRM = (params) => {
    this.props.actions.updateCrm(params)
  }

  refreshCRM = (params) => {
    this.props.actions.refreshActiveCrm(params)
  }

  deleteCron = (params) => {
    this.props.actions.deleteCron(params)
  }

  render () {
    const {
      crmList,
      sequenceFields,
      activeCRM,
      children,
      state,
      session,
      theme
    } = this.props

    const {
      showDeleteConfirmModal,
      showCloneSettingsModal
    } = this.state

    const loadingList = crmList.get('loading')
    const loading = activeCRM.get('loading')
    const refreshing = activeCRM.get('refreshing')

    if (loading || loadingList) {
      return (
        <ContentContainer>
          <Loading />
        </ContentContainer>
      )
    }

    const description = "Connect your team's CRM or ATS to have Interseller sync all contacts over, deduplicate against existing accounts, and sync over all conversations."
    const crms = crmList.get('data')
    const crmData = activeCRM.get('data')
    const crmError = activeCRM.getIn(['data', 'error'])
    const crmRequestError = activeCRM.get('error')
    const name = activeCRM.getIn(['data', 'name']) || state.get('crm_name')
    const type = session.get('crm_type')

    const crmDisconnectModal = (
      <ConfirmModal
        isOpen={showDeleteConfirmModal}
        onCancel={() => {
          this.setState({
            showDeleteConfirmModal: false
          })
        }}
        onConfirm={() => {
          this.props.actions.deleteCrm()
          this.setState({
            showDeleteConfirmModal: false
          })
        }}
        title='Confirm Disconnect'
        description={`Are you sure you want to disconnect ${name} from Interseller?`}
      />
    )

    const cloneSettingsModal = (
      <ConfirmModal
        isOpen={showCloneSettingsModal}
        onCancel={() => {
          this.setState({
            showCloneSettingsModal: false
          })
        }}
        onConfirm={() => {
          this.props.actions.cloneSettings()
          this.setState({
            showCloneSettingsModal: false
          })
        }}
        title={`Clone ${name} Settings To Team`}
        description={`This will clone all of your settings to the rest of your team that uses ${name}. Are you sure you want to do this?`}
      />
    )

    if (crmError) {
      return (
        <ContentContainer>
          <SubtitleContainer>
            <Subtitle>Connected to {name}</Subtitle>
            <LogoImage type={type} ml={0} mr={0} mt='auto' mb='auto' />
            <ButtonContainer>
              <Button
                label='Disconnect'
                handleClick={() => {
                  this.setState({
                    showDeleteConfirmModal: true
                  })
                }}
                ml='auto'
              />
            </ButtonContainer>
          </SubtitleContainer>
          <ComponentError
            p='5rem'
            pb='2rem'
            message={`We no longer have access to your ${name} account. Syncing has been paused and may affect newly added contacts from receiving emails.`}
          />
          <ButtonContainer>
            <Button
              primary
              label={`Reconnect your ${name} account →`}
              handleClick={() => {
                this.props.router.push(`/integrations/crm/${type}`)
              }}
              ml='auto'
              mr='auto'
              mb='5rem'
            />
          </ButtonContainer>
          {crmDisconnectModal}
          {children && React.cloneElement(children, {
            crmList,
            sequenceFields,
            connectCRM: this.connectCRM
          })}
        </ContentContainer>
      )
    }

    if (crmRequestError) {
      return (
        <ContentContainer>
          <SubtitleContainer>
            <Subtitle>Connected to {name}</Subtitle>
            <LogoImage type={type} ml={0} mr={0} mt='auto' mb='auto' />
            <ButtonContainer>
              <Button
                label='Disconnect'
                handleClick={() => {
                  this.setState({
                    showDeleteConfirmModal: true
                  })
                }}
                ml='auto'
              />
            </ButtonContainer>
          </SubtitleContainer>
          <ComponentError
            p='5rem'
            message={`It looks like ${name} took too long or failed to load your settings. Please try again later.`}
          />
          {crmDisconnectModal}
        </ContentContainer>
      )
    }

    if (crmData) {
      return (
        <ContentContainer>
          <ConfigureCRM
            theme={theme}
            state={state}
            crm={crmData}
            refreshing={refreshing}
            sequenceFields={sequenceFields}
            onCRMUpdate={this.updateCRM}
            onCRMRefresh={this.refreshCRM}
            onCRMDisconnect={() => {
              this.setState({
                showDeleteConfirmModal: true
              })
            }}
            onDeleteCron={this.deleteCron}
            onCloneSettings={() => {
              this.setState({
                showCloneSettingsModal: true
              })
            }}
          />
          {crmDisconnectModal}
          {cloneSettingsModal}
          {children && React.cloneElement(children, {
            crmList,
            sequenceFields,
            connectCRM: this.connectCRM
          })}
        </ContentContainer>
      )
    }

    return (
      <ContentContainer>
        <TitleContainer>
          <Title>CRM / ATS</Title>
          <Label mt='0.5rem'>{description}</Label>
        </TitleContainer>
        <CrmList
          crms={crms}
          onClick={(crm) => {
            this.props.router.push(`/integrations/crm/${crm.get('type')}`)
          }}
        />
        {children && React.cloneElement(children, {
          crmList,
          sequenceFields,
          connectCRM: this.connectCRM
        })}
      </ContentContainer>
    )
  }
}

Crm.propTypes = {
  actions: PropTypes.object,
  crmList: PropTypes.object,
  sequenceFields: PropTypes.object,
  activeCRM: PropTypes.instanceOf(Immutable.Map),
  children: PropTypes.object,
  router: PropTypes.object,
  state: PropTypes.object,
  session: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.object
}

export default withTheme(Crm)
