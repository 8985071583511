import React, { PureComponent } from 'react'
import R from 'utils/ramda'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import ConfirmModal from 'components/ConfirmModal'
import Modal from 'components/Modal'
import Templates from 'components/Templates'
import SlateEditor from 'components/SlateEditor'
import api from 'utils/api'
import {
  getSelectedStep,
  getSelectedStepSubject,
  getFirstMessageStepIndex
} from 'utils/sequences'
import { error } from 'utils/toast'
import StepSettings from './StepSettings'
import SaveTemplate from './SaveTemplate'
import StepList from './StepList'
import RemoveStep from './RemoveStep'
import { pluralize } from 'utils/strings'
import { debounce } from 'utils/browser'

import { SLATE_EDITORS } from 'components/SlateEditor/utils/slate/constants'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 45% 55%;
  align-items: start;

  padding: 0rem 2rem 4rem 2rem;
`

const StepsContainer = styled.div`
  height: 100%;
  margin-right: .5rem;
`

const EditorContainer = styled.div`
  margin-top: 32px;
  border: 1px solid ${props => props.theme.colors.gray30};
`

class Steps extends PureComponent {
  constructor (props) {
    super(props)

    let sequence = props.sequence
    const steps = sequence.get('steps')
    // Create initial step in state if it doesn't exist
    if (!steps || steps.count() < 1) {
      sequence = sequence.set('steps', Immutable.List([(Immutable.Map({
        type: 'message'
      }))]))
    }

    this.state = {
      sequence,
      selectedStepIndex: 0,
      testSelected: false,
      showScheduleModal: false,
      showTemplateModal: false,
      saveTemplateModal: false,
      showSaveChangesModal: false,
      showDeleteStepModal: false
    }
  }

  draftPending = false

  needsSaving = () => {
    return this.draftPending && !this.state.showSaveChangesModal
  }

  touch = debounce(() => {
    try { api.get(`/campaigns/${this.props.params.id}/touch`) } catch (e) {}
  }, 30000, true)

  UNSAFE_componentWillMount () {
    this.props.router.setRouteLeaveHook(this.props.route, (nextLocation) => {
      if (this.needsSaving()) {
        this.setState({
          showSaveChangesModal: true,
          nextLocation
        })
        return false
      }
      return true
    })

    const query = this.props.location.query || {}
    this.updateSelectedStep(Number.parseInt(query.step, 10) || 0)

    this.props.actions.fetchSequenceSignature(this.props.params.id)
  }

  componentDidMount () {
    this.timer = setInterval(() => {
      if (!document.hasFocus()) {
        return
      }

      if (this.needsSaving()) {
        this.updateCampaignVersionStep()
        this.touch()
      }
    }, 60000)

    window.addEventListener('beforeunload', this.onBeforeUnload)
  }

  componentWillUnmount () {
    clearInterval(this.timer)
    this.timer = null

    window.removeEventListener('beforeunload', this.onBeforeUnload)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      sequence
    } = nextProps
    let newSequence = sequence

    if (newSequence && newSequence !== this.props.sequence) {
      const steps = newSequence.get('steps')
      // Create initial step in state if it doesn't exist
      if (!steps || steps.count() < 1) {
        newSequence = newSequence.set('steps', Immutable.List([(Immutable.Map({ }))]))
      }
      this.setState({
        sequence: newSequence
      })
    }
  }

  onBeforeUnload = (e) => {
    // Chrome requires a return value
    if (!this.needsSaving()) {
      return null
    }
    e.returnValue = ''
  }

  updateSelectedStep = (stepIndex, testSelected = false) => {
    const query = {
      contactId: this.props.location.query.contactId,
      step: stepIndex
    }
    if (!R.equals(query, this.props.location.query)) {
      this.props.router.push({
        ...this.props.location,
        query
      })
    }
    this.setState({
      selectedStepIndex: stepIndex,
      testSelected
    })
  }

  updateCampaignVersionStep = debounce(() => {
    const { params, sequence } = this.props
    const { selectedStepIndex } = this.state
    const sequenceId = params.id
    const stepIndex = selectedStepIndex
    const stepId = sequence.getIn(['steps', stepIndex, '_id'])
    try {
      const subject = this.editor.getMarkdown(SLATE_EDITORS.SUBJECT)
      const body = this.editor.getMarkdown(SLATE_EDITORS.BODY)
      const bodyParams = {
        _step: stepId,
        step: stepIndex,
        markdown: body,
        subject
      }
      api.post(`campaigns/${sequenceId}/versions`, bodyParams)
    } catch (err) { }
  }, 30000, true)

  updateStepOrder = (draggedIndex, droppedIndex) => {
    if (R.isNil(draggedIndex) || R.isNil(droppedIndex)) {
      return
    }
    const { selectedStepIndex } = this.state
    let sequence = this.updatedStepState()
    let stepRows = sequence.get('steps').toArray()
    const waitDays = stepRows.map(step => step.get('wait_days'))
    const selectedStep = stepRows[selectedStepIndex]
    const { stepStats } = this.props
    const draggedTotal = stepStats.getIn([`${draggedIndex}`, 'total'])
    const droppedTotal = stepStats.getIn([`${droppedIndex}`, 'total'])
    if (draggedTotal > 0 || droppedTotal > 0) {
      error('Steps that have emails sent or tasks completed cannot be moved')
      return
    }

    if (draggedIndex > droppedIndex) {
      stepRows.splice(droppedIndex, 0, stepRows.splice(draggedIndex, 1)[0])
    } else if (draggedIndex < droppedIndex - 1) {
      stepRows.splice(droppedIndex - 1, 0, stepRows.splice(draggedIndex, 1)[0])
    } else {
      return
    }

    stepRows = stepRows.map((step, index) => {
      return step.set('wait_days', waitDays[index])
    })

    const sequenceId = this.props.params.id
    sequence = sequence.set('steps', Immutable.List(stepRows))

    this.props.actions.updateSequence(sequenceId, sequence)
    this.draftPending = false

    let newSelectedStepIndex = null
    stepRows.forEach((step, i) => {
      if (selectedStep.get('_id') === step.get('_id')) {
        newSelectedStepIndex = i
      }

      return step
    })

    this.updateSelectedStep(newSelectedStepIndex)
  }

  addStep = () => {
    const {
      sequence
    } = this.state
    const steps = sequence.get('steps')

    // this adds a new step that's default to what we suggest to all customers
    const newSequence = sequence.set('steps', steps.push(Immutable.Map({
      wait_days: steps.count() > 2 ? 7 : 2,
      body: '',
      subject: null,
      type: 'message'
    })))

    this.setState({
      sequence: newSequence
    })
    this.updateSelectedStep(steps.count(), false)
  }

  addTestStep = (index) => {
    const {
      sequence
    } = this.state
    const step = sequence.getIn(['steps', index])
    const otherSteps = sequence.get('other_steps')

    const newSequence = sequence.set('other_steps', otherSteps.push(Immutable.Map({
      _step: step.get('_id'),
      subject: step.get('subject'),
      body: step.get('body'),
      type: 'message'
    })))

    this.setState({
      sequence: newSequence
    })
    this.updateSelectedStep(index, true)
  }

  removeSelectedStep = () => {
    const {
      selectedStepIndex,
      testSelected
    } = this.state

    this.tryRemoveStep(selectedStepIndex, testSelected)
  }

  tryRemoveStep = (index, testStep) => {
    const {
      stepStats
    } = this.props

    const {
      sequence
    } = this.state

    const stats = (stepStats || Immutable.Map({})).get(String(index))
    const total = (stats || Immutable.Map({})).get('total')

    const step = sequence.get('steps').get(String(index))
    const otherSteps = (sequence.get('other_steps') || Immutable.List([]))
      .filter(s => s.get('_step') === step.get('_id'))

    if (total && !testStep && !otherSteps.count()) {
      return this.setState({
        showDeleteStepModal: true,
        deleteStepIndex: index,
        deleteTestStep: testStep
      })
    }

    this.removeStep(index, testStep)
  }

  removeStep = (index, testStep) => {
    const {
      sequence
    } = this.state
    const sequenceId = sequence.get('id')
    const steps = sequence.get('steps')
    const step = sequence.getIn(['steps', index])
    let newSequence = sequence
    let newIndex = index

    if (testStep) {
      const otherSteps = (sequence.get('other_steps') || Immutable.List([]))
        .filter(s => s.get('_step') !== step.get('_id'))
      // Remove test step from other steps
      newSequence = sequence.set('other_steps', otherSteps)
    } else {
      // Remove first step, first check if there is a test step to replace it with
      const tStep = (sequence.get('other_steps') || Immutable.List([]))
        .find(s => s.get('_step') === step.get('_id'))
      if (tStep) {
        newSequence = sequence.setIn(['steps', index], tStep.set('wait_days', step.get('wait_days')))
      } else {
        newIndex = Math.max(index - 1, 0)

        // If removing last step in sequence replace with empty step
        if (steps.count() <= 1) {
          newSequence = sequence.set('steps', Immutable.List([Immutable.Map({})])).set('subject', '')
        } else {
          newSequence = sequence.set('steps', steps.remove(index))

          // if we're deleting the first step of the sequence, make sure that the
          // the next step takes its wait days to be immediate
          if (index === 0 && !step.get('wait_days')) {
            newSequence = newSequence.setIn(['steps', 0], newSequence.getIn(['steps', 0]).set('wait_days', null))
          }
        }
      }
    }

    this.setState({
      sequence: newSequence
    })

    this.updateSelectedStep(newIndex, false)
    this.props.actions.updateSequence(sequenceId, newSequence)
  }

  toggleThread = () => {
    const {
      sequence,
      selectedStepIndex,
      testSelected
    } = this.state

    const steps = sequence.get('steps')
    let step = steps.get(selectedStepIndex)
    let newSequence
    if (testSelected) {
      const otherSteps = sequence.get('other_steps')
      const testIndex = otherSteps.findIndex(s => s.get('_step') === step.get('_id'))
      step = otherSteps.get(testIndex)
      const stepSubject = step.get('subject')
      if (stepSubject != null) {
        step = step.set('subject', null)
      } else {
        step = step.set('subject', '')
      }
      newSequence = sequence.set('other_steps', otherSteps.set(testIndex, step))
    } else {
      const stepSubject = step.get('subject')
      if (stepSubject != null) {
        newSequence = sequence.setIn(['steps', selectedStepIndex], step.set('subject', null))
      } else {
        newSequence = sequence.setIn(['steps', selectedStepIndex], step.set('subject', ''))
      }
    }

    this.setState({
      sequence: newSequence
    })
  }

  updateStep = (callback = () => {}) => {
    const sequence = this.updatedStepState()
    this.setState({
      sequence
    }, callback)
  }

  updatedStepState = (
    subject = this.editor.getMarkdown(SLATE_EDITORS.SUBJECT),
    body = this.editor.getMarkdown(SLATE_EDITORS.BODY)
  ) => {
    const {
      sequence,
      selectedStepIndex,
      testSelected
    } = this.state

    const {
      newThread
    } = getSelectedStepSubject(sequence, selectedStepIndex, testSelected)

    const firstMessageStepIndex = getFirstMessageStepIndex(sequence)

    let updatedSequence = sequence
    const steps = sequence.get('steps')
    let selectedStep = steps.get(selectedStepIndex)

    if (testSelected) {
      const otherSteps = sequence.get('other_steps')
      const testIndex = otherSteps.findIndex(s => s.get('_step') === selectedStep.get('_id'))
      selectedStep = otherSteps.get(testIndex)
      if (selectedStepIndex === firstMessageStepIndex || newThread) {
        selectedStep = selectedStep.set('subject', subject)
      }
      selectedStep = selectedStep.set('body', body)
      updatedSequence = updatedSequence.set('other_steps', otherSteps.set(testIndex, selectedStep))
    } else {
      if (selectedStepIndex === firstMessageStepIndex) {
        updatedSequence = updatedSequence.set('subject', subject)
        selectedStep = selectedStep.set('subject', subject)
      } else if (newThread) {
        selectedStep = selectedStep.set('subject', subject)
      }
      selectedStep = selectedStep.set('body', body)
      updatedSequence = updatedSequence.set('steps', steps.set(selectedStepIndex, selectedStep))
    }

    return updatedSequence
  }

  handleSaveSequence = (subjectMarkdown, bodyMarkdown, callback) => {
    const newSequence = this.updatedStepState(subjectMarkdown, bodyMarkdown)
    const sequenceId = this.props.params.id
    this.props.actions.updateSequence(sequenceId, newSequence, undefined, callback)
    this.draftPending = false
  }

  handleToggleSignature = (e) => {
    const {
      sequence,
      selectedStepIndex
    } = this.state

    let updatedSequence = sequence
    const steps = sequence.get('steps')
    let selectedStep = steps.get(selectedStepIndex)
    const disableSignature = selectedStep.get('disable_signature')
      ? selectedStep.get('disable_signature')
      : false

    selectedStep = selectedStep.set('disable_signature', !disableSignature)
    updatedSequence = updatedSequence.set('steps', steps.set(selectedStepIndex, selectedStep))

    this.draftPending = true

    this.setState({
      sequence: updatedSequence
    })
  }

  saveStepChangesCallback = null

  handleShowSaveChangesModal = (callback) => {
    this.setState({
      showSaveChangesModal: true
    })

    if (callback) {
      this.saveStepChangesCallback = callback
    }
  }

  handleOnEditStepSettings = (index) => {
    const cb = () => {
      this.setState({
        scheduleModalIndex: index,
        showScheduleModal: true,
        showSaveChangesModal: false
      })

      this.updateSelectedStep(index)
    }

    if (this.draftPending) {
      this.handleShowSaveChangesModal(cb)
    } else {
      cb()
    }
  }

  handleOnStepSelected = (index, testStepSelected) => {
    // Update step state before switching
    const cb = () => {
      this.setState({ transitioning: true })
      this.updateSelectedStep(index, testStepSelected)
      this.setState({
        transitioning: false,
        showSaveChangesModal: false
      })
    }

    if (this.draftPending) {
      this.handleShowSaveChangesModal(cb)
    } else {
      cb()
    }
  }

  reloadEditor = () => {
    this.setState({ reload: true }, () => {
      this.setState({ reload: false })
    })
  }

  render () {
    const {
      stepStats,
      templates,
      actions,
      session,
      theme,
      addresses,
      isArchived,
      sequenceSignature
    } = this.props

    const {
      sequence,
      selectedStepIndex,
      testSelected,
      showTemplateModal,
      scheduleModalIndex,
      showScheduleModal,
      saveTemplateModal,
      showSaveChangesModal,
      showDeleteStepModal,
      transitioning,
      reload
    } = this.state

    // number of unset customized messages for a given sequence step
    const stats = (stepStats || Immutable.Map({})).get(String(selectedStepIndex))
    const numOfPendingCustomizedMessages = (stats || Immutable.Map({})).get('manual_unsent')
    const warningMsg = (numOfPendingCustomizedMessages > 0)
      ? `You have ${numOfPendingCustomizedMessages} personalized ${pluralize('message', 'messages', numOfPendingCustomizedMessages)} that ${pluralize('hasn\'t', 'haven\'t', numOfPendingCustomizedMessages)} been sent. Changing your step's copy will not affect any of your personalized messages.`
      : null

    const step = sequence?.getIn(['steps', selectedStepIndex])
    const isStepMessage = step?.get('type') === 'message'
    const isStepCompleted = isStepMessage ? stats?.get('sent') > 0 : stats?.get('tasks_completed') > 0

    if (!sequence.getIn(['steps', selectedStepIndex])) {
      this.updateSelectedStep(0, false)
      return (<div />)
    }

    const selectedStep = getSelectedStep(sequence, selectedStepIndex, testSelected)
    const body = selectedStep.get('body')

    const {
      subject,
      threadLabel,
      newThread
    } = getSelectedStepSubject(sequence, selectedStepIndex, testSelected)

    const signature = sequenceSignature.getIn(['data', 'signature_html'])

    const isAlias = selectedStep?.get('gmail_send_as')
      ? !!selectedStep.get('gmail_send_as')
      : false

    const isManualTask = selectedStep?.get('manual') === 'task'

    const showSignaturePreview = Boolean(signature && !isAlias && !isManualTask)

    const isSignatureDisabled = selectedStep?.get('disable_signature')
      ? selectedStep?.get('disable_signature')
      : false

    return (
      <Wrapper>
        <StepsContainer>
          <StepList
            sequence={sequence}
            stepStats={stepStats}
            selectedStepIndex={selectedStepIndex}
            testSelected={testSelected}
            readOnly={isArchived}
            isStepCompleted={isStepCompleted}
            updateStepOrder={(draggedIndex, droppedIndex) => this.updateStepOrder(draggedIndex, droppedIndex)}
            onStepSelected={this.handleOnStepSelected}
            onRemoveStep={(index, testStep) => {
              this.updateStep(() => { this.tryRemoveStep(index, testStep) })
            }}
            onAddTestStep={(index) => {
              this.updateStep(() => { this.addTestStep(index) })
            }}
            onAddStep={() => {
              this.updateStep(this.addStep)
            }}
            onEditSchedule={this.handleOnEditStepSettings}
          />
        </StepsContainer>
        {!transitioning &&
          <EditorContainer>
            <SlateEditor
              readOnly={isArchived}
              hideToolbar={isArchived}
              innerRef={(element) => (this.editor = element)}
              session={this.props.session}
              ogp={this.props.ogp}
              actions={this.props.actions}
              isBrandedDomain={session.get('branded_domain_enabled')}
              onSave={this.handleSaveSequence}
              subjectDisabled={!newThread}
              threadLabel={threadLabel}
              toggleThread={this.toggleThread}
              onSaveTemplate={() => {
                this.setState({
                  saveTemplateModal: true
                })
              }}
              onShowTemplates={() => {
                actions.fetchTemplates()
                this.setState({
                  showTemplateModal: true
                })
              }}
              hasEnteredKeyStroke={() => {
                this.draftPending = true
                this.touch()
              }}
              subjectPlaceholder="Your message's subject line..."
              bodyPlaceholder={selectedStep.get('manual') === 'task' ? 'Add some notes on the task that you need to complete' : 'Compose a personalized message to your contacts...'}
              hideSubjectLine={selectedStep.get('manual') === 'task'}
              backgroundColor={selectedStep.get('manual') === 'task' ? theme.colors.lightYellow : null}
              subject={reload ? '' : subject}
              body={reload ? '' : body}
              customFields={sequence.get('fields')}
              warningMsg={warningMsg}
              steps={sequence.get('steps')}
              selectedStepIndex={selectedStepIndex}
              showSignature={showSignaturePreview}
              signature={signature}
              selectedStep={selectedStep}
              onToggleSignature={this.handleToggleSignature}
              isSignatureDisabled={isSignatureDisabled}
              needsSaving={this.needsSaving}
            />
          </EditorContainer>}
        <Modal
          isOpen={showScheduleModal}
          onModalClose={() => {
            this.setState({ showScheduleModal: false })
          }}
        >
          <StepSettings
            session={session}
            sequence={sequence}
            addresses={addresses}
            selectedStepIndex={scheduleModalIndex}
            stats={stats}
            isStepCompleted={isStepCompleted}
            handleClose={() => {
              this.setState({ showScheduleModal: false })
            }}
            onEditSchedule={(newSequence) => {
              this.setState({
                sequence: newSequence,
                showScheduleModal: false
              }, () => {
                this.handleSaveSequence()
              })
            }}
          />
        </Modal>
        <Modal
          isOpen={showTemplateModal}
          width='700px'
          onModalClose={() => {
            this.setState({
              showTemplateModal: false
            })
          }}
        >
          <Templates
            templates={templates}
            onTemplateSelected={(template) => {
              if (template) {
                const newSequence = this.updatedStepState(template.get('subject'), template.get('markdown'))

                this.setState({
                  sequence: newSequence,
                  showTemplateModal: false
                })

                this.draftPending = true
              }
            }}
            onDeleteTemplate={(template) => {
              actions.deleteTemplate(template)
            }}
          />
        </Modal>
        <Modal
          isOpen={saveTemplateModal}
          onModalClose={() => {
            this.setState({
              saveTemplateModal: false
            })
          }}
        >
          <SaveTemplate
            onSaveTemplate={(params) => {
              const subject = this.editor.getMarkdown(SLATE_EDITORS.SUBJECT)
              const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)
              this.props.actions.createTemplate({
                name: params.name,
                subject,
                markdown,
                shared: params.shared || false
              })
              this.setState({
                saveTemplateModal: false
              })
            }}
          />
        </Modal>
        <ConfirmModal
          isOpen={showSaveChangesModal}
          onCancel={() => {
            if (this.saveStepChangesCallback) {
              this.reloadEditor()
              this.saveStepChangesCallback()
              this.saveStepChangesCallback = null
            } else {
              this.props.router.push(this.state.nextLocation)
            }
            this.draftPending = false
          }}
          onConfirm={() => {
            // undefined values are handled in this.updatedStepState
            this.handleSaveSequence(undefined, undefined, () => {
              if (this.saveStepChangesCallback) {
                this.saveStepChangesCallback()
                this.saveStepChangesCallback = null
              } else {
                this.props.router.push(this.state.nextLocation)
              }
            })
          }}
          cancelLabel='Discard Changes'
          confirmLabel='Save Changes'
          title='Unsaved changes'
          description='Do you want to save changes to your sequence steps?'
        />
        <Modal
          isOpen={showDeleteStepModal}
          onModalClose={() => {
            this.setState({
              showDeleteStepModal: false
            })
          }}
        >
          <RemoveStep
            stepIndex={this.state.deleteStepIndex}
            onConfirm={() => {
              this.removeStep(this.state.deleteStepIndex, this.state.deleteTestStep)
              this.setState({
                showDeleteStepModal: false
              })
            }}
          />
        </Modal>
      </Wrapper>
    )
  }
}

Steps.propTypes = {
  theme: PropTypes.object,
  sequence: PropTypes.object,
  stepStats: PropTypes.object,
  actions: PropTypes.any,
  templates: PropTypes.object,
  router: PropTypes.object,
  location: PropTypes.any,
  route: PropTypes.any,
  params: PropTypes.object,
  session: PropTypes.object,
  ogp: PropTypes.instanceOf(Immutable.Map),
  isArchived: PropTypes.bool,
  addresses: PropTypes.object,
  sequenceSignature: PropTypes.object
}

export default withTheme(Steps)
