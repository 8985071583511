import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Label from 'elements/Label'
import Title from 'elements/Title'
import Divider from 'elements/Divider'
import DangerLabel from 'elements/DangerLabel'

import Input from 'components/Input'
import Button from 'components/Button'

import {
  Wrapper,
  Window,
  Curtain,
  ButtonContainer,
  TabContainer
} from '../ToolbarModal'

import {
  slateHelpers,
  slateConstants
} from 'components/SlateEditor/utils/slate/'

const StyledTitle = styled(Title)`
  text-align: center;
`

const InputWrapper = styled.div`
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const InvalidLabel = styled(Label)`
  color: ${props => props.theme.colors.red};
  font: ${props => props.theme.fonts.normal};
  text-align: center;
`

export function UrlImageModal ({
  editorRef,
  isBrandedDomain,
  setToolbarMenuVisibility
}) {
  const [imageLinkInput, setImageLinkInput] = useState('')
  const [insertAttempted, setInsertAttempted] = useState(false)

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeKey, false)
    window.addEventListener('keydown', handleEnterKey, false)
    return () => {
      window.removeEventListener('keydown', handleEscapeKey, false)
      window.removeEventListener('keydown', handleEnterKey, false)
    }
  })

  const handleEscapeKey = (event) => {
    const ESCAPE_KEY = 27
    if (event.keyCode === ESCAPE_KEY) {
      setToolbarMenuVisibility('showImageMenu', slateConstants.IMAGE_MENUS.NONE)
    }
  }

  const handleEnterKey = (event) => {
    const ENTER_KEY = 13
    if (imageLinkInput && event.keyCode === ENTER_KEY) {
      handleInsertImage(event)
    }
  }

  const handleInsertImage = (event) => {
    event.preventDefault()

    if (imageLinkInput) {
      Promise
        .resolve()
        .then(() => {
          if (slateHelpers.isValidUrl(imageLinkInput)) {
            editorRef
              .current
              .insertImage(imageLinkInput)

            return true
          } else {
            setInsertAttempted(false)
            return false
          }
        })
        .then((success) => {
          if (success) {
            setToolbarMenuVisibility('showImageMenu', slateConstants.IMAGE_MENUS.NONE)
          }
        })
    }
  }

  return (
    <Wrapper>
      <Curtain
        visible
        onClick={() => {
          setToolbarMenuVisibility('showImageMenu', slateConstants.IMAGE_MENUS.NONE)
        }}
      />
      <Window>
        <TabContainer>
          <StyledTitle>Add Image</StyledTitle>

          {insertAttempted
            ? <InvalidLabel mt='0.5rem' mb='0.5rem'>The URL you provided is invalid, please add a valid URL</InvalidLabel>
            : <Label mt='0.5rem' mb='0.5rem' align='center'>Upload an image or add an image with a link</Label>}

          {!isBrandedDomain &&
            <DangerLabel>
              Adding images may decrease your chances of your email being delivered. We recommend setting up a <a href='https://help.interseller.io/article/12-branded-domains' target='_new'>branded domain</a>.
            </DangerLabel>}

          <InputWrapper>
            <Input
              label='Image URL'
              type='text'
              value={imageLinkInput}
              onValueChange={(value) => {
                setImageLinkInput(value)
              }}
            />
          </InputWrapper>
        </TabContainer>

        <Divider />

        <ButtonContainer>
          <Button
            label='cancel'
            mr='0.5rem'
            handleClick={() => {
              setToolbarMenuVisibility('showImageMenu', slateConstants.IMAGE_MENUS.NONE)
            }}
          />
          <Button
            primary
            disabled={!imageLinkInput}
            label='add image'
            handleClick={handleInsertImage}
          />
        </ButtonContainer>
      </Window>
    </Wrapper>
  )
}

UrlImageModal.propTypes = {
  editorRef: PropTypes.object,
  isBrandedDomain: PropTypes.bool,
  setToolbarMenuVisibility: PropTypes.func
}

export default UrlImageModal
