import Immutable from 'immutable'
import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'
import { Tooltip } from 'react-tippy'
import R from 'utils/ramda'

import DropDown from 'components/DropDown'
import Input from 'components/Input'
import Loading from 'components/Loading'
import Button from 'components/Button'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import DangerLabel from 'elements/DangerLabel'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding: 0 3.5rem;
  width: 100%;

  ${space}
`

const SelectorWrapper = styled.div`
  border-radius: 3px;

  margin-top: 1.5rem;

  background-color: ${props => props.theme.colors.gray10};
  border: 1px solid ${props => props.theme.colors.gray40};
  padding: 1.5rem;
  padding-bottom: 1.75rem;
`

const AdvancedSelectorWrapper = styled(SelectorWrapper)`
  background-color: ${props => props.theme.colors.lightYellow};
  border-color: ${props => props.theme.colors.darkYellow};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const AdvancedLabel = styled.div`
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  letter-spacing: 0.05rem;
  color: ${props => props.theme.colors.darkYellow};
`

class StepSettings extends PureComponent {
  constructor (props) {
    super(props)
    this.state = this.updateStateFromProps(props)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    this.setState(this.updateStateFromProps(newProps))
  }

  updateStateFromProps = (props) => {
    const {
      sequence,
      selectedStepIndex
    } = props

    if (sequence) {
      let waitDays = sequence.getIn(['steps', selectedStepIndex, 'wait_days'])
      if (waitDays === null || waitDays === undefined) {
        waitDays = -1
      }

      const manual = sequence.getIn(['steps', selectedStepIndex, 'manual'])
      const gmailSendAs = sequence.getIn(['steps', selectedStepIndex, 'gmail_send_as'])
      const disableSignature = sequence.getIn(['steps', selectedStepIndex, 'disableSignature'])

      return {
        gmailSendAs,
        disableSignature,
        waitDays: waitDays + 1,
        manual
      }
    }
    return {}
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13) {
      e.preventDefault()
      return this.updateScheduleInSequence()
    }
    return false
  }

  updateScheduleInSequence = () => {
    const {
      sequence,
      selectedStepIndex,
      onEditSchedule
    } = this.props
    const {
      waitDays,
      manual,
      gmailSendAs,
      disableSignature
    } = this.state

    const steps = sequence.get('steps')
    const selectedStep = steps.get(selectedStepIndex)
      .set('gmail_send_as', gmailSendAs)
      .set('wait_days', waitDays < 1 ? null : waitDays - 1)
      .set('manual', manual)
      .set('disable_signature', disableSignature)
    onEditSchedule(sequence.set('steps', steps.set(selectedStepIndex, selectedStep)))
  }

  render () {
    const {
      session,
      loading,
      handleClose,
      selectedStepIndex,
      stats,
      sequence,
      addresses,
      isStepCompleted
    } = this.props

    const {
      manual,
      waitDays,
      gmailSendAs
    } = this.state

    if (loading) {
      return <Loading />
    }

    const options = Immutable.List([
      {
        label: 'Email',
        value: 'message'
      },
      {
        label: 'Manual Task',
        value: 'task'
      }
    ])

    let selectedOption = manual
    if (!selectedOption) {
      selectedOption = 'message'
    }

    let previousOption = sequence.getIn(['steps', selectedStepIndex, 'manual'])
    if (!previousOption) {
      previousOption = 'message'
    }

    const total = (stats || Immutable.Map({})).get('total')

    // Build options for alias dropdown
    const addressValue = gmailSendAs ? gmailSendAs.get('email') : null
    let addressOptions = Immutable.List([])
    let addressMap = Immutable.Map({})
    if (addresses) {
      addressOptions = addresses.get('data').map((option, i) => {
        return {
          label: option.get('email'),
          value: option.get('email'),
          index: i
        }
      })
      const byEmail = R.groupBy(option => (option.get('email')))
      addressMap = byEmail(addresses.get('data'))
    }

    const featureFlags = session.get('feature_flags') || session.get('whitelist')
    const showAliases = addressOptions.count() > 0 && featureFlags.includes('sequence_email_settings')

    let daysSincePreviousMessage = 0
    let lastMessageStepIdx = null
    for (lastMessageStepIdx = selectedStepIndex - 1; lastMessageStepIdx >= 0; lastMessageStepIdx--) {
      const step = sequence.getIn(['steps', lastMessageStepIdx])
      const stepType = step.get('manual') || 'message'
      if (stepType === 'message') {
        break
      }

      if (!R.isNil(step.get('wait_days'))) {
        daysSincePreviousMessage += (step.get('wait_days') || 1)
      }
    }

    const canScheduleImmediate = selectedStepIndex === 0 ||
      daysSincePreviousMessage > 0 ||
      lastMessageStepIdx === -1 ||
      manual === 'task'

    const inputs = (
      <>
        <Input
          label='Days to wait'
          description={selectedOption === 'task'
            ? 'Choose how long to wait before needing to complete this task'
            : 'Choose how long to wait to send this message if there is no reply'}
          inputId='waitDays'
          type='number'
          min={canScheduleImmediate ? 0 : 1}
          value={waitDays}
          onValueChange={(value) => {
            this.setState({
              waitDays: value
            })
          }}
        />

        {selectedOption === 'message' &&
          <>
            <SwitchFieldForm
              label='Require Personalized Message'
              description='This step is currently automated. Enabling this will require that all contacts have a personalized email for this step.'
              descriptionOn='Personalization is required for this step. Contacts that reach this step will be paused until personalized. Personalization tasks can be found in your tasks queue.'
              condensed='true'
              small='true'
              inputId='manual'
              value={manual === 'message'}
              mt='1.5rem'
              onSave={(value) => {
                if (value) {
                  this.setState({
                    manual: 'message'
                  })
                } else {
                  this.setState({
                    manual: null
                  })
                }
              }}
            />
          </>}
      </>
    )

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <InputContainer pt='2rem' pb='2.5rem'>
          <Title align='center' mb='1.5rem'>Step Settings</Title>
          <Tooltip
            style={{ display: 'block' }}
            disabled={!isStepCompleted}
            title='Switching the type of step after it has started can cause adverse effects. We recommend creating a new sequence instead.'
            position='top'
            trigger='mouseenter'
            arrow
          >
            <DropDown
              options={options}
              clearable={false}
              disabled={isStepCompleted}
              label='Step Type'
              value={selectedOption}
              height='46px'
              onOptionChange={(option) => {
                if (option.value === 'message') {
                  this.setState({
                    manual: null
                  })
                } else {
                  this.setState({
                    manual: option.value
                  })
                }
              }}
            />
          </Tooltip>

          {selectedOption !== previousOption && total > 0 &&
            <DangerLabel>
              Switching the type of step after it has started can cause adverse effects. We recommend creating a new sequence instead.
            </DangerLabel>}

          <SelectorWrapper>{inputs}</SelectorWrapper>

          {selectedOption === 'message' && showAliases &&
            <AdvancedSelectorWrapper>
              <AdvancedLabel>ADVANCED SETTINGS</AdvancedLabel>
              <DropDown
                mt='1rem'
                label='Send as'
                description="Set the Gmail alias that your emails get sent as for this step. If this setting is left blank, it will use your sequence's email setting value instead. Your signature will not be used when an alias is selected."
                helpUrl='https://help.interseller.io/article/29-changing-the-send-as-email-for-your-account'
                options={addressOptions}
                value={addressValue}
                clearable
                inputId='gmail_send_as'
                onOptionChange={(option) => {
                  const gmailAlias = option ? addressMap.get(option.value).first() : null
                  this.setState({ gmailSendAs: gmailAlias, disableSignature: !!gmailAlias })
                }}
              />
            </AdvancedSelectorWrapper>}
        </InputContainer>
        <Divider />
        <ButtonContainer>
          <Button
            mr='0.5rem'
            label='cancel'
            handleClick={handleClose}
          />
          <Button
            primary
            label='save'
            handleClick={() => {
              this.updateScheduleInSequence()
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

StepSettings.propTypes = {
  session: PropTypes.object,
  sequence: PropTypes.object,
  selectedStepIndex: PropTypes.number,
  loading: PropTypes.bool,
  onEditSchedule: PropTypes.func,
  handleClose: PropTypes.func,
  stats: PropTypes.object,
  addresses: PropTypes.object,
  isStepCompleted: PropTypes.bool
}

StepSettings.defaultProps = {
  loading: false
}

export default StepSettings
