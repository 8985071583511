import styled from 'styled-components'

export default styled.div`
  background: ${props => props.theme.background};
  -webkit-app-region: drag;
  min-height: 100vh;
  min-width: 800px;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  display: flex;
  overflow: hidden;
`
