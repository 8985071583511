import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { space, width, border, borderColor, position, color } from 'styled-system'
import styled from 'styled-components'
import Immutable from 'immutable'
import VirtualizedSelect from 'react-virtualized-select'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;

  ${space}
  ${width}
  ${position}
  ${color}
  ${border}
`

const Label = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.label};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

const Field = styled(VirtualizedSelect)`
  .Select-control {
    color: ${props => props.theme.titleColor};
    font: ${props => props.theme.fonts.normal};
    width: 100%;
    height: ${props => props.height};
    border-radius: 3px;
    border-width: 1px;
    border-color: ${props => props.theme.colors.gray60};
    border-style: solid;
    text-align: left;

    display: flex;

    ${borderColor}
  }

  .Select-control >:first-child {
    flex: 1
  }

  .Select-menu-outer, .Select-control, .Select-input {
    color: ${props => props.theme.titleColor};
    font: ${props => props.theme.fonts.normal};

    input {
      color: ${props => props.theme.titleColor};
      font: ${props => props.theme.fonts.normal};
      padding: 0;
    }
  }

  .Select-menu-outer {
    border-top: transparent;
    z-index: 100;
  }

  .Select-placeholder, .Select-value {
    display: flex;
    align-items: center;
  }

  .Select-placeholder, .Select-input {
    padding: 0;
    padding-left: 1rem;
  }

  .Select-value-label {
    padding-left: 0.36rem;
  }

  .VirtualizedSelectOption {
    padding-left: 1rem;
    padding-right: 1rem;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .VirtualizedSelectHeader {
    background-color: ${props => props.theme.colors.gray20};
    font: ${props => props.theme.fonts.label};
    text-transform: uppercase;
  }

  .Select-input {
    top: 0;
    bottom: 0;
    position: absolute;
    margin-top: auto;
    margin-bottom: auto;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .Select-input input {
    height: 100%;
  }

  .Select-clear-zone, .Select-arrow-zone {
    display: flex;

    height: 100%;

    flex-direction: column;
    justify-content: center;

    padding-top: 3px;
  }

  .Select-clear-zone {
    padding-top: 0;
    padding-bottom: 3px;
  }
`

class DropDown extends Component {
  constructor (props) {
    super(props)
    this.state = {
      selectValue: props.value
    }
  }

  optionRenderer ({ focusedOption, focusedOptionIndex, focusOption, key, labelKey, option, optionIndex, options, selectValue, style, valueArray, valueKey }) {
    const classNames = ['VirtualizedSelectOption']

    if (option.type === 'header') {
      classNames.push('VirtualizedSelectHeader')

      return (
        <div
          className={classNames.join(' ')}
          key={key}
          style={style}
        >
          {option.label}
        </div>
      )
    }

    if (option === focusedOption) {
      classNames.push('VirtualizedSelectFocusedOption')
    }

    return (
      <div
        className={classNames.join(' ')}
        key={key}
        onClick={() => selectValue(option)}
        onMouseEnter={() => focusOption(option)}
        style={style}
      >
        {option.label}
      </div>
    )
  }

  render () {
    const {
      label,
      description,
      inputId,
      options,
      clearable = true,
      searchable = true,
      onOptionChange,
      placeholder,
      height = '56px',
      controlled = false,
      value,
      disabled = false,
      autoFocus,
      borderColor,
      ...rest
    } = this.props

    return (
      <Wrapper {...rest}>
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        {description &&
          <Description>{description}</Description>}
        <Field
          id={inputId}
          options={options.toArray()}
          clearable={clearable}
          searchable={searchable}
          height={height}
          onChange={(selectValue) => {
            this.setState({ selectValue })
            onOptionChange(selectValue)
          }}
          optionRenderer={this.optionRenderer}
          value={controlled ? value : this.state.selectValue}
          placeholder={placeholder}
          disabled={disabled}
          autofocus={autoFocus}
          borderColor={borderColor}
        />
      </Wrapper>
    )
  }
}

DropDown.propTypes = {
  options: PropTypes.instanceOf(Immutable.List),
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  inputId: PropTypes.string,
  onOptionChange: PropTypes.func,
  clearable: PropTypes.bool,
  searchable: PropTypes.bool,
  placeholder: PropTypes.string,
  height: PropTypes.any,
  controlled: PropTypes.bool,
  disabled: PropTypes.bool,
  autoFocus: PropTypes.bool,
  borderColor: PropTypes.string
}

export default DropDown
