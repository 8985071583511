export const getGroupsFromMembers = (members) => {
  let result

  if (members) {
    const membersData = members && members.get && members.get('data') ? members.get('data') : members

    result = membersData
      .filter(member => member.get('group'))
      .map(g => g.get('group'))
      .toSet()
      .sort((a, b) => {
        const isNumA = !isNaN(a)
        const isNumB = !isNaN(b)

        if (isNumA && isNumB) {
          return a - b
        }

        if (!isNumA && !isNumB) {
          return a > b
        }

        if (isNumA && !isNumB) {
          return -1
        } else {
          return 1
        }
      })
  }

  return result
}

export const getGroupLabel = (value) => {
  let result = value

  if (value) {
    if (!isNaN(value)) {
      result = `Group ${value}`
    }
  }

  return result
}
