import { fromJS } from 'immutable'
import { put, takeLatest, call } from 'redux-saga/effects'

import api, { messageFromError } from 'utils/api'
import { guessBrowserTimezone } from 'utils/dates'

import {
  fetchSessionSuccess,
  fetchSessionFailure,
  fetchSessionStateSuccess,
  fetchSessionStateFailure,
  fetchSessionScopesSuccess,
  fetchSessionScopesFailure,
  updateSessionSuccess,
  updateSessionFailure,
  setNotification
} from './actions'
import {
  FETCH_SESSION_REQUEST,
  FETCH_SESSION_STATE_REQUEST,
  FETCH_SESSION_SCOPES_REQUEST,
  UPDATE_SESSION_REQUEST,
  UPDATE_TIMEZONE_REQUEST,
  NOTIFICATION_TYPES,
  DELETE_AUTH_TOKEN_REQUEST
} from './constants'

const get = (url, params) => api.get(url, {
  params
})

const update = (url, params) => api.put(url, params)

const del = (url, params) => api.delete(url, params)

export function * fetchSession () {
  let session = {}

  try {
    const response = yield call(api.get, '/me', {
      headers: {
        'x-timezone': guessBrowserTimezone()
      },
      timeout: 20000
    })
    session = fromJS(response.data)
    yield put(fetchSessionSuccess({
      session
    }))
  } catch (error) {
    yield put(fetchSessionFailure(error))
  }
}

export function * fetchSessionState () {
  try {
    const response = yield call(api.get, '/me/state', {
      timeout: 20000
    })
    const state = fromJS(response.data)
    yield put(fetchSessionStateSuccess({
      state
    }))
  } catch (error) {
    yield put(fetchSessionStateFailure(error))
  }
}

export function * fetchSessionScopes () {
  try {
    const response = yield call(get, '/me/scopes')
    const scopes = fromJS(response.data)
    yield put(fetchSessionScopesSuccess({
      scopes
    }))
  } catch (error) {
    yield put(fetchSessionScopesFailure(error))
  }
}

export function * updateSession (action) {
  const params = action.params

  try {
    const response = yield call(update, '/me', params)
    const session = fromJS(response.data)

    yield put(updateSessionSuccess({ session }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `${action.success || 'Settings updated!'} 🚀`
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(updateSessionFailure(error))
  }
}

export function * updateTimezone (action) {
  const timezone = action.timezone
  try {
    yield call(update, '/me/timezone', { timezone })
  } catch (error) {
    // console.error('Error when updating timezone', error);
  }
}

export function * deleteAuthToken () {
  try {
    yield call(del, '/me/auth_token')
    yield fetchSessionState()
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Successfully Disconnected Your Email Account'
    }))
  } catch (err) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

function * fetchSessionRequest () {
  yield takeLatest(FETCH_SESSION_REQUEST, fetchSession)
}

function * fetchSessionStateRequest () {
  yield takeLatest(FETCH_SESSION_STATE_REQUEST, fetchSessionState)
}

function * updateSessionRequest () {
  yield takeLatest(UPDATE_SESSION_REQUEST, updateSession)
}

function * updateTimezoneRequest () {
  yield takeLatest(UPDATE_TIMEZONE_REQUEST, updateTimezone)
}

function * fetchSessionScopesRequest () {
  yield takeLatest(FETCH_SESSION_SCOPES_REQUEST, fetchSessionScopes)
}

function * deleteAuthTokenRequest () {
  yield takeLatest(DELETE_AUTH_TOKEN_REQUEST, deleteAuthToken)
}

export default [
  fetchSessionRequest,
  fetchSessionStateRequest,
  fetchSessionScopesRequest,
  updateSessionRequest,
  updateTimezoneRequest,
  deleteAuthTokenRequest
]
