import Immutable, { fromJS } from 'immutable'
import { createSelector } from 'reselect'

const selectTasksDomain = () => state => state.get('tasks')

const selectTasks = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('tasks')
)

const selectContactErrors = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('contactErrors')
)

const selectContactErrorsStats = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('contactErrorStats')
)

const selectContactMessages = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('messages')
)

const selectSentimentScore = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('sentimentScore')
)

const selectRepliedContact = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('repliedContact')
)

const selectManualTasks = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('manualTasks')
)

const selectContactTask = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('contactTask')
)

const selectSequenceTasks = () => createSelector(
  [selectTasks(), selectManualTasks()],
  (tasks, manualTasks) => {
    if (tasks.get('loading') || manualTasks.get('loading')) {
      return fromJS({
        loading: true,
        error: null,
        data: []
      })
    }

    return fromJS({
      loading: false,
      error: tasks.get('error') || manualTasks.get('error'),
      data: Immutable.List([]).concat(tasks.get('data'), manualTasks.get('data'))
    })
  }
)

const selectPauseContactTask = () => createSelector(
  selectTasksDomain(),
  tasksState => tasksState.get('pauseContactTask')
)

export {
  selectTasks,
  selectContactMessages,
  selectContactErrors,
  selectContactErrorsStats,
  selectSentimentScore,
  selectRepliedContact,
  selectManualTasks,
  selectContactTask,
  selectSequenceTasks,
  selectPauseContactTask
}
