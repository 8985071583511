import styled from 'styled-components'
import { space } from 'styled-system'

const Header = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 10px;
  text-align: center;
  margin-bottom: 1rem;

  ${space};
`

export default Header
