import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import TabbedModal from 'components/TabbedModal'
import AddContact from './AddContact'
import LookupContact from './LookupContact'

class CreateContact extends Component {
  constructor (props) {
    super(props)
    this.state = this.getInitialState()
  }

  getInitialState = () => ({
    name: '',
    company: '',
    email: '',
    phone_number: '',
    url: '',
    title: '',
    location: '',
    raw: this.props.defaultValues || Immutable.Map({})
  })

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13 && this.state.email && this.state.email.length > 0) {
      e.preventDefault()
      return this.props.handleSave(this.state)
    }
    return false
  }

  render () {
    const {
      loading,
      handleSave,
      onCancel,
      newContacts,
      customFields,
      defaultValues,
      ...rest
    } = this.props

    const { raw } = this.state

    const createContact = (
      <AddContact
        raw={raw}
        customFields={customFields}
        defaultValues={defaultValues}
        onValueChange={(params) => {
          this.setState(params)
        }}
        {...this.state}
      />
    )

    const lookupContact = (
      <LookupContact
        onValueChange={(params) => {
          this.setState(params)
        }}
        {...this.state}
      />
    )

    const tabs = [
      {
        label: 'Lookup Contact',
        content: lookupContact
      },
      {
        label: 'Add Contact',
        content: createContact
      }
    ]

    return (
      <TabbedModal
        tabs={tabs}
        width='40rem'
        onCancel={() => {
          onCancel()
          this.setState(this.getInitialState())
        }}
        loading={newContacts.get('loading') || false}
        confirmText='Add Contact'
        disabled={!this.state.email || this.state.email.length < 1}
        onConfirm={() => {
          handleSave(this.state)
          this.setState(this.getInitialState())
        }}
        {...rest}
      />
    )
  }
}

CreateContact.propTypes = {
  loading: PropTypes.bool,
  handleSave: PropTypes.func,
  onCancel: PropTypes.func,
  newContacts: PropTypes.object,
  customFields: PropTypes.object,
  defaultValues: PropTypes.instanceOf(Immutable.Map)
}

export default CreateContact
