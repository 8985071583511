import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import Avatar from 'react-avatar'
import styled from 'styled-components'
import { timeFromNow } from 'utils/strings'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { SEARCH_TABS } from '../../../constants'

import SequenceStatusLabel from 'components/SequenceStatusLabel'

import SearchTabs from './SearchTabs'

const Wrapper = styled.div`
  display: ${props => (props.open ? 'flex' : 'none')};
  flex-direction: column;
  position: absolute;
  width: 50%;
  top: 100%;
  left: 0;
  z-index: 10000;

  background-color: ${props => props.theme.containerBackground};
  border: ${props => `solid 1px ${props.theme.background}`};
`

const SuggestionWrapper = styled.div`
  padding: 0.5rem ${props => props.theme.padding};
  background-color: ${props => (props.selected ? props.theme.highlightBackground : props.theme.containerBackground)};
  transition: background-color 0.2s;
  display: flex;
  align-items: center;
  grid-gap: 0.75rem;
  cursor: pointer;
`

const SuggestionTitle = styled.p`
  color: ${props => props.theme.colors.blueText};
  font-size: 15px;
  font-weight: 600;
  margin: 0;

  small {
    font-size: 12px;
    font-weight: 500;
    white-space: nowrap;
  }
`

const SequenceLabel = styled(SequenceStatusLabel)`
  color: ${props => props.theme.colors.blueText};
  font-weight: 600;
`

const Label = styled.p`
  color: ${props => props.theme.colors.gray70};
  font: ${props => props.theme.fonts.normal};
  margin: 0;
`

const SectionHeader = styled.h3`
  color: ${props => props.theme.colors.gray70};
  font-size: 11px;
  font-weight: 500;
  text-transform: uppercase;
  margin: 0;
  padding: 1rem 1rem 0.25rem;
  letter-spacing: 0.02em;
`

const EmptyResultsContainer = styled.div`
  padding: 2rem;
  color: #8E97AD;
  font: ${props => props.theme.fonts.normal};
  text-align: center;
`

const IconContainer = styled.span`
  min-width: 14px;
  width: 14px;
  height: 14px;
  margin-left: 0.5rem;
  margin-right: 0.3rem;
  border-radius: 100%;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  vertical-align: middle;
  font-size: 8.5px;
  background-color: ${props => props.bgColor};

  svg {
    flex: 1;
  }
`

class SearchSuggestions extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      selectedIndex: 0,
      suggestions: [],
      activeTab: SEARCH_TABS.ALL
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      recentSequences,
      results,
      searchText
    } = newProps

    let suggestions = []
    const campaigns = results.get('campaigns')
    const contacts = results.get('contacts')

    if (this.props.results !== results) {
      this.sequences = []
      this.contacts = []

      if (campaigns && campaigns.count()) {
        const campaignRows = campaigns.map((campaign) => {
          const sequenceOwner = campaign.getIn(['_user', 'full_name'])
          const sequenceId = campaign.get('_id')
          const sequenceTitle = campaign.get('title')
          const label = (
            <>
              <SequenceLabel
                label={this.highlightSearchText(sequenceTitle)}
                sequence={campaign}
              />
              <Label>{this.highlightSearchText(sequenceOwner)}</Label>
            </>
          )
          return {
            id: sequenceId,
            label,
            link: `/sequence/${sequenceId}/contacts`
          }
        }).take(5).toArray()

        this.sequences = campaignRows
        suggestions = suggestions.concat([{ header: 'Sequences' }, ...campaignRows])
      }

      if (contacts && contacts.count()) {
        const contactRows = contacts.map((contact) => {
          const name = contact.get('name')
          const email = contact.get('email')
          const photoUrl = contact.get('image_url')
          const company = contact.get('company')
          const contactId = contact.get('_id')
          const sequence = contact.get('_campaign')
          const sequenceId = contact.getIn(['_campaign', '_id'])
          const sequenceTitle = contact.getIn(['_campaign', 'title'])
          const lastMessagedAt = contact.get('last_messaged_at')
          const repliedAt = contact.get('replied_at')
          const bookedAt = contact.get('booked_at')
          const activityTime = bookedAt || repliedAt || lastMessagedAt
          const activity = bookedAt ? 'Booked' : repliedAt ? 'Replied' : 'Messaged'
          const icon = bookedAt ? 'calendar' : repliedAt ? 'reply' : 'paper-plane'
          const bgColor = (repliedAt || bookedAt)
            ? this.props.theme.colors.green
            : this.props.theme.colors.yellow
          const contactName = (company)
            ? `${name} · ${company}`
            : String(name)
          const title = (
            <>
              {this.highlightSearchText(contactName)}
              {activityTime &&
                <>
                  <IconContainer bgColor={bgColor}>
                    <FontAwesomeIcon
                      icon={['fas', icon]}
                      color='white'
                      size='xs'
                    />
                  </IconContainer>
                  <small>{activity} {timeFromNow(activityTime)}</small>
                </>}
            </>
          )
          const label = (
            <SequenceStatusLabel
              label={this.highlightSearchText(sequenceTitle)}
              sequence={sequence}
            />
          )
          const avatar = (
            <Avatar
              email={photoUrl ? null : email}
              maxInitials={2}
              src={photoUrl}
              name={name}
              size={32}
              textSizeRatio={2}
              round
              colors={this.props.theme.avatarColors}
            />
          )

          return {
            id: contactId,
            avatar,
            title,
            label,
            link: `/sequence/${sequenceId}/contacts/${contactId}/detail`
          }
        }).toArray()

        this.contacts = contactRows
        suggestions = suggestions.concat([{ header: 'Contacts' }, ...contactRows])
      }

      this.setState({ suggestions })
    }

    if (searchText.length === 0 && recentSequences.count() > 0) {
      const recentSequenceRows = recentSequences.map((campaign) => {
        const sequenceTitle = campaign.get('title')
        const sequenceId = campaign.get('_id')
        return {
          id: sequenceId,
          title: sequenceTitle,
          link: `/sequence/${sequenceId}/contacts`
        }
      }).toArray()

      suggestions = suggestions.concat([{ header: 'Recent Sequences' }, ...recentSequenceRows])
      this.setState({
        suggestions
      })
    }
  }

  highlightSearchText (text) {
    const { searchText } = this.props

    if (text) {
      const parts = text.split(new RegExp(`(${searchText})`, 'gi'))

      return parts.map((part, idx) =>
        part.toLowerCase() === searchText.toLowerCase()
          ? <mark key={idx}>{part}</mark>
          : part)
    }

    return text
  }

  onKeyDown = () => {
    const {
      selectedIndex,
      suggestions
    } = this.state
    const newIndex = Math.min(selectedIndex + 1, suggestions.length - 1)

    this.setState({
      selectedIndex: newIndex
    })
  }

  onKeyUp = () => {
    const newIndex = Math.max(this.state.selectedIndex - 1, 0)

    this.setState({
      selectedIndex: newIndex
    })
  }

  onKeyEnter = () => {
    const {
      suggestions,
      selectedIndex
    } = this.state
    const {
      onResultSelected
    } = this.props

    onResultSelected(suggestions[selectedIndex])
  }

  render () {
    const { open, onResultSelected, searchText, results } = this.props
    const { selectedIndex, suggestions, activeTab } = this.state
    const totalContacts = results.get('totalContacts')
    const totalCampaigns = results.get('totalCampaigns')
    const totalResults = totalContacts + totalCampaigns
    let rows

    switch (activeTab) {
      case SEARCH_TABS.SEQUENCES:
        rows = this.sequences || []
        break
      case SEARCH_TABS.CONTACTS:
        rows = this.contacts || []
        break
      default:
        rows = suggestions || []
        break
    }

    const suggestionRows = rows.map((suggestion, i) => {
      if (suggestion.header) {
        return (
          <SectionHeader key={suggestion.header}>
            {suggestion.header}
          </SectionHeader>
        )
      }

      return (
        <SuggestionWrapper
          selected={i === selectedIndex}
          key={suggestion.id}
          onMouseEnter={() => {
            this.setState({
              selectedIndex: i
            })
          }}
          onClick={() => {
            const selectedOption = rows[i]
            onResultSelected(selectedOption)
          }}
        >
          {!!(suggestion.avatar) && suggestion.avatar}
          <div>
            <SuggestionTitle>{suggestion.title}</SuggestionTitle>
            {!!(suggestion.label) && suggestion.label}
          </div>
        </SuggestionWrapper>
      )
    })

    return (
      <Wrapper open={open}>
        {(suggestionRows.length > 0 ||
          (suggestionRows.length === 0 && searchText.length > 0)) &&
            <SearchTabs
              activeTab={activeTab}
              setActiveTab={(activeTab) => {
                this.setState({ activeTab })
              }}
              totalResults={totalResults}
              totalContacts={totalContacts}
              totalCampaigns={totalCampaigns}
            />}
        {suggestionRows.length > 0 && suggestionRows}
        {suggestionRows.length === 0 && searchText.length > 0 &&
          <EmptyResultsContainer>
            No Results Found
          </EmptyResultsContainer>}
      </Wrapper>
    )
  }
}

SearchSuggestions.propTypes = {
  results: PropTypes.instanceOf(Immutable.Map),
  open: PropTypes.bool,
  theme: PropTypes.object,
  onResultSelected: PropTypes.func,
  searchText: PropTypes.string
}

SearchSuggestions.defaultProps = {
  open: false
}

export default SearchSuggestions
