import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { space, width, height, color } from 'styled-system'

const Bar = styled.div`
  border-radius: 4px;
  position: relative;

  ${space}
  ${width}
  ${height}
  ${color}
`

const InnerBar = styled.div`
  border-radius: 4px;
  position: absolute;
  left: 0;
  bottom: 0;
  right: 0;
  z-index: 1;

  ${width}
  ${height}
  ${color}
`

const Progress = ({
  percentage = 0,
  backgroundColor,
  barWidth = 63,
  barHeight = 10,
  barColor,
  theme,
  ...rest
}) => (
  <Bar bg={backgroundColor || theme.background} width={barWidth} height={barHeight} {...rest}>
    <InnerBar
      bg={barColor || theme.primaryColor}
      width={barWidth * Math.min(Math.max(percentage, 0), 1)}
      height={barHeight}
    />
  </Bar>
)

Progress.propTypes = {
  percentage: PropTypes.number,
  barWidth: PropTypes.number,
  barHeight: PropTypes.number,
  barColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  theme: PropTypes.object
}

export default withTheme(Progress)
