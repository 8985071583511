/**
*
* SequenceRow
*
*/

import React, { Component } from 'react'
import R from 'utils/ramda'
import { pluralize } from 'utils/strings'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import styled, { withTheme } from 'styled-components'
import Toggle from 'react-toggle'
import { Tooltip } from 'react-tippy'
import Avatar from 'react-avatar'
import format from 'date-fns/format'

import Checkbox from 'elements/Checkbox'
import PercentageCircle from 'elements/PercentageCircle'
import SequenceStatus from 'elements/SequenceStatus'
import Badge from 'elements/Badge'

import { ArchiveIcon } from 'svg'

import {
  Wrapper,
  SequenceLabel,
  SequenceColumn,
  SequenceTitle,
  CheckboxColumn,
  ContactErrorsBadge,
  ContactTasksBadge,
  AvatarPhotoColumn,
  UserColumn,
  UserName,
  UserEmail,
  StatColumn,
  BadgeColumn,
  ContactNumberColumn,
  IconColumn
} from '../Rows'

const StatusSwitch = styled(Toggle)`
  display: block;
`

class SequenceRow extends Component {
  shouldComponentUpdate (nextProps) {
    const current = R.pick(['sequence', 'stats', 'status', 'selected'], this.props)
    const newChanges = R.pick(['sequence', 'stats', 'status', 'selected'], nextProps)
    return !R.equals(current, newChanges)
  }

  render () {
    const {
      selected,
      sequence,
      onSelected,
      onClick,
      stats,
      onToggleStatus,
      archived,
      theme,
      status
    } = this.props

    const title = sequence.get('title')
    const active = sequence.get('active')

    const contactsCount = (stats && stats.get('contacts_count')) || 0
    const messagedContactsCount = (stats && stats.get('messaged_contacts_count')) || 0
    const viewedContactsCount = (stats && stats.get('viewed_contacts_count'))
    const repliedContactsCount = (stats && stats.get('replied_contacts_count'))
    const erroredContactsCount = (stats && stats.get('errored_contacts_count'))

    const tasksCount = (status && status.get('manual'))

    let openRate
    let replyRate
    if (contactsCount > 0) {
      if (viewedContactsCount) {
        openRate = Math.round((viewedContactsCount / messagedContactsCount) * 100)
      }
      if (repliedContactsCount) {
        replyRate = Math.round((repliedContactsCount / messagedContactsCount) * 100)
      }
    }

    const photoUrl = sequence.getIn(['_user', 'photo_url'])

    const ownerName = sequence.getIn(['_user', 'full_name'])
    const ownerEmail = sequence.getIn(['_user', 'email'])

    const sharingBadge = sequence.get('shared') !== null
      ? (<Badge color='#FFF' backgroundColor={theme.colors.blue}>Shared</Badge>)
      : <Badge>Private</Badge>

    return (
      <Wrapper selected={selected} onClick={onClick}>
        <CheckboxColumn>
          <Checkbox
            selected={selected}
            handleClick={onSelected}
          />
        </CheckboxColumn>
        {!archived &&
          <IconColumn
            onClick={(e) => {
              e.stopPropagation()
            }}
            pl='1rem'
          >
            <Tooltip
              // options
              title={active ? 'Sequence is actively sending messages' : 'Sequence is currently paused'}
              position='top'
              trigger='mouseenter'
              arrow
            >
              <StatusSwitch
                id='sequence-status'
                checked={active}
                icons={false}
                className='interseller-switch'
                onChange={(e) => {
                  onToggleStatus(e.target.checked)
                }}
              />
            </Tooltip>
          </IconColumn>}
        {archived &&
          <IconColumn
            onClick={(e) => {
              e.stopPropagation()
            }}
          >
            <ArchiveIcon />
          </IconColumn>}
        <SequenceColumn>
          <SequenceTitle>{title}</SequenceTitle>
          {!archived && <SequenceStatus state={status} sequence={sequence} loading={status && status.get('loading')} />}
          {archived && <SequenceLabel>Archived on {format(Date.parse(sequence.get('deleted_at')), 'MMMM D, YYYY')}</SequenceLabel>}
        </SequenceColumn>
        <AvatarPhotoColumn>
          <Avatar
            src={photoUrl || '/bear_avatar.png'}
            name={ownerName}
            maxInitials={2}
            size={38}
            textSizeRatio={2}
            round
            colors={theme.avatarColors}
          />
        </AvatarPhotoColumn>
        <UserColumn>
          <UserName>{ownerName}</UserName>
          <UserEmail title={ownerEmail}>{ownerEmail}</UserEmail>
        </UserColumn>
        <BadgeColumn>
          {sharingBadge}
        </BadgeColumn>
        <ContactNumberColumn>
          {stats && stats.get('contacts_count') !== undefined && <NumberFormat value={contactsCount || 0} displayType='text' thousandSeparator=',' />}
          {erroredContactsCount > 0 &&
            <ContactErrorsBadge
              color='#FFF'
              tooltip={`${erroredContactsCount} ${pluralize('contact needs', 'contacts need', erroredContactsCount)} attention`}
            >
              {erroredContactsCount}
            </ContactErrorsBadge>}
          {tasksCount > 0 &&
            <ContactTasksBadge
              color='#FFF'
              tooltip={`${tasksCount} ${pluralize('task', 'tasks', tasksCount)} pending`}
            >
              {tasksCount}
            </ContactTasksBadge>}
        </ContactNumberColumn>
        <StatColumn>
          {openRate &&
            <PercentageCircle percent={openRate} />}
        </StatColumn>
        <StatColumn>
          {replyRate &&
            <PercentageCircle percent={replyRate} minGreen={10} minYellow={5} />}
        </StatColumn>
      </Wrapper>
    )
  }
}

SequenceRow.propTypes = {
  sequence: PropTypes.object,
  stats: PropTypes.object,
  selected: PropTypes.bool,
  onSelected: PropTypes.func,
  onClick: PropTypes.func,
  onToggleStatus: PropTypes.func,
  archived: PropTypes.bool,
  theme: PropTypes.object,
  status: PropTypes.object
}

export default withTheme(SequenceRow)
