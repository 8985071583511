import React from 'react'

/* eslint-disable */
const AngleUp= ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg
    aria-hidden="true"
    focusable="false"
    data-prefix="far"
    data-icon="angle-up"
    style={{ ...style, transformOrigin: 'center', transform: `rotate(${degrees}deg)` }}
    onClick={onClick}
    className={className}
    width={width || 13}
    height={height || 13}
    viewBox="0 0 320 512"
  >
      <path
        fill={color || "currentColor"}
        d="M168.5 164.2l148 146.8c4.7 4.7 4.7 12.3 0 17l-19.8 19.8c-4.7 4.7-12.3 4.7-17 0L160 229.3 40.3 347.8c-4.7 4.7-12.3 4.7-17 0L3.5 328c-4.7-4.7-4.7-12.3 0-17l148-146.8c4.7-4.7 12.3-4.7 17 0z"
      >
      </path>
  </svg>
)


export default AngleUp;
