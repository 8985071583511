import styled from 'styled-components'

const Wrapper = styled.div`
  transition: background-color 0.3s ease, color .3s ease;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: start;
  overflow: visible;
  width: 210px;
  padding-bottom: 3rem;
`

export default Wrapper
