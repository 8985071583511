import React from 'react'

/* eslint-disable */
const Server = ({
  color = '#FFFFFF',
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 26} height={height || 26}  viewBox="0 0 173.467 173.468" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
        <g>
            <path d="M99.46,12.72v10.145c0.365,0,0.718-0.082,1.084,0l66.14,13.226c3.009,0.597,4.957,3.528,4.36,6.533    c-0.523,2.643-2.85,4.463-5.444,4.463c-0.347,0-0.718-0.042-1.09-0.113L99.46,33.969v6.141c0.292,0,0.584-0.073,0.889-0.024    l66.141,10.774c3.032,0.494,5.091,3.343,4.591,6.366c-0.45,2.726-2.801,4.665-5.48,4.665c-0.292,0-0.59-0.024-0.895-0.073    L99.454,51.186v4.896c0.269-0.009,0.53-0.088,0.798-0.052l66.141,9.621c3.026,0.436,5.127,3.261,4.701,6.293    c-0.408,2.762-2.777,4.75-5.493,4.75c-0.268,0-0.523-0.018-0.804-0.055l-65.343-9.502v5.593c0.214-0.006,0.421-0.088,0.652-0.064    l66.146,7.77c3.051,0.362,5.218,3.118,4.865,6.166c-0.329,2.819-2.729,4.902-5.518,4.902c-0.207,0-0.426-0.019-0.645-0.036    l-65.502-7.688v76.97h74.014V27.523L99.46,12.72z M161.125,108.935c-2.54,0-4.622-2.076-4.622-4.621    c0-2.558,2.082-4.628,4.622-4.628c2.557,0,4.627,2.07,4.627,4.628C165.752,106.858,163.682,108.935,161.125,108.935z" fill={color} />
            <polygon points="36.55,99.277 81.01,99.277 83.171,99.277 83.278,99.277 83.278,108.831 83.171,108.898 83.171,160.735     95.758,160.735 95.758,12.72 36.55,42.326   " fill={color} />
            <polygon points="0,109.361 0,155.523 76.937,155.523 76.937,109.361 38.465,134.363   " fill={color} />
            <polygon points="38.465,130.515 76.937,105.513 0,105.513   " fill={color} />
        </g>
    </g>
  </svg>
);

export default Server;
