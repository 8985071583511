import React from 'react'

/* eslint-disable */
const Steps = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  current,
  contact,
  total,
  className,
}) => {
    let currentColor = '#2A3E6A';
    let currentBackground = 'white';
    if (contact) {
        const finished = contact.get('finished');
        const lastMessaged = contact.get('last_messaged_at');
        const delayed = contact.get('delayed');
        const needsAttention = contact.get('needs_manual_attention');
        const error = contact.get('state') === 'bounced' || contact.get('state') === 'unsubscribed' || contact.get('error');
        if (error) {
            currentColor = 'white';
            currentBackground = '#E4542B'
        } else if (delayed || needsAttention) {
            currentColor = 'white';
            currentBackground = '#F6CE1E'
        } else if (finished) {
            currentColor = 'white';
            currentBackground = '#1FC866'
        } else if (current < total) {
            currentColor = 'white';
            currentBackground = '#4D83E1'
        }
    }
    return (
        <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 62} height={height || 32}  viewBox="0 0 62 32" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
            <defs>
                <rect id="path-1" x="0" y="0" width="60" height="30" rx="3"></rect>
            </defs>
            <g id="Campaign-Contacts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
                <g transform="translate(-1006.000000, -616.000000)" id="Group-4">
                    <g transform="translate(66.000000, 617.000000)">
                        <g id="step" transform="translate(941.000000, 0.000000)">
                            <g id="Rectangle-3">
                                <mask id="mask-2" fill="white">
                                    <use xlinkHref="#path-1"></use>
                                </mask>
                                <rect fill={currentBackground} stroke="#C7CBD2" x="-0.5" y="-0.5" width="61" height="31" rx="3"></rect>
                                <polygon fill="#FBFCFD" mask="url(#mask-2)" points="33.7792969 0 60 0 60 30 26.4023438 30"></polygon>
                                <path d="M26.5,29.5 L33.5,0.5" id="Line-3" stroke="#C7CBD2" strokeLinecap="square" mask="url(#mask-2)"></path>
                            </g>
                            <text id="11" fontFamily="soleil" fontSize="13" fontWeight="normal" letterSpacing="0.200000018" fill={currentColor}>
                                <tspan x="12.3055" y="19">{current}</tspan>
                            </text>
                            <text id="12" fontFamily="soleil" fontSize="13" fontWeight="normal" letterSpacing="0.200000018" fill="#979EAE">
                                <tspan x="42.533" y="19">{total}</tspan>
                            </text>
                        </g>
                    </g>
                </g>
            </g>
        </svg>
    )
};

export default Steps;
