/**
*
* StatBox
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import NumberFormat from 'react-number-format'
import { Tooltip } from 'react-tippy'
import Loading from 'components/Loading'

import {
  STATS_TOTAL,
  STATS_ONGOING,
  STATS_MESSAGED,
  STATS_OPEN,
  STATS_CLICKED,
  STATS_REPLIED,
  STATS_INTERESTED,
  STATS_BOOKED,
  STATS_BOUNCED,
  STATS_ATTENTION
} from 'containers/Sequence/constants'

const Wrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  flex: 1 0 auto;
  align-items: center;
  background: ${props => props.theme.containerBackground};
  padding: ${props => props.theme.padding};
  flex-grow: 1;
  flex-basis: 8rem;
  cursor: ${props => (props.selected ? 'cursor' : 'pointer')};
  border-style: solid;
  border-width: 1px;

  ${props => !props.selected && css`
    border-color: ${props.theme.borderColor};
    color: ${props.theme.labelColor};

    opacity: 1;
    transition: transform 0.25s ease-out;

    &:active {
      transform: scale(.90);
    }

    &:hover,
    &:focus {
      box-shadow: 1px 1px 5px ${props.theme.colors.gray60};
      transition: all .2s ease;
    }
  `};

  ${props => props.type === STATS_TOTAL && props.selected && css`
    border-color: ${props.theme.totalColor};
    color: ${props.theme.totalColor};
  `};
  ${props => props.type === STATS_ONGOING && props.selected && css`
    border-color: ${props.theme.totalColor};
    color: ${props.theme.totalColor};
  `};
  ${props => props.type === STATS_MESSAGED && props.selected && css`
    border-color: ${props.theme.totalColor};
    color: ${props.theme.totalColor};
  `};
  ${props => props.type === STATS_OPEN && props.selected && css`
    border-color: ${props.theme.openColor};
    color: ${props.theme.openColor};
  `};
  ${props => props.type === STATS_CLICKED && props.selected && css`
    border-color: ${props.theme.clickedColor};
    color: ${props.theme.clickedColor};
  `};
  ${props => props.type === STATS_REPLIED && props.selected && css`
    border-color: ${props.theme.repliedColor};
    color: ${props.theme.repliedColor};
  `};
  ${props => props.type === STATS_INTERESTED && props.selected && css`
    border-color: ${props.theme.repliedColor};
    color: ${props.theme.repliedColor};
  `};
  ${props => props.type === STATS_BOUNCED && props.selected && css`
    border-color: ${props.theme.bounceColor};
    color: ${props.theme.bounceColor};
  `};
  ${props => props.type === STATS_ATTENTION && props.selected && css`
    border-color: ${props.theme.attentionColor};
    color: ${props.theme.attentionColor};
  `};
  ${props => props.type === STATS_BOOKED && props.selected && css`
    border-color: ${props.theme.repliedColor};
    color: ${props.theme.repliedColor};
  `};
`

const Label = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const Value = styled.div`
  font: ${props => props.theme.fonts.number};
  color: ${props => props.theme.titleColor};
  text-align: center;
  margin-top: .25rem;
`

class StatBox extends Component {
  render () {
    const {
      tooltip,
      label,
      value,
      selected = false,
      type,
      textColor,
      decimalScale = 0,
      loading = false
    } = this.props

    if (loading) {
      return (
        <Wrapper>
          <Loading flex='1' padding='1rem' size={27} />
        </Wrapper>
      )
    }

    let valueLabel = ((this.props.percentage &&
      <Value textColor={textColor}><NumberFormat value={value} displayType='text' decimalScale={decimalScale} suffix='%' /></Value>) ||
        <Value textColor={textColor}><NumberFormat value={value} displayType='text' thousandSeparator=',' /></Value>
    )

    if (tooltip) {
      valueLabel = (
        <Tooltip
          title={tooltip}
          position='bottom'
          trigger='mouseenter'
          arrow
        >
          {valueLabel}
        </Tooltip>
      )
    }

    return (
      <Wrapper selected={selected} type={type} onClick={this.props.onClick}>
        <Label>{label}</Label>
        {valueLabel}
      </Wrapper>
    )
  }
}

StatBox.propTypes = {
  selected: PropTypes.bool,
  type: PropTypes.oneOf([
    STATS_TOTAL,
    STATS_ONGOING,
    STATS_MESSAGED,
    STATS_OPEN,
    STATS_CLICKED,
    STATS_REPLIED,
    STATS_BOUNCED,
    STATS_ATTENTION,
    STATS_BOOKED
  ]),
  tooltip: PropTypes.string,
  textColor: PropTypes.any,
  label: PropTypes.string,
  value: PropTypes.any,
  percentage: PropTypes.bool,
  onClick: PropTypes.func,
  decimalScale: PropTypes.number,
  loading: PropTypes.bool
}

export default StatBox
