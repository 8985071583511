import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { withRouter } from 'react-router'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled, { withTheme } from 'styled-components'

// import { Tooltip } from 'react-tippy'
import { Logo } from 'svg'

import {
  selectLoading,
  selectResults,
  selectRecentSequences,
  selectChecklist
} from './selectors'
import {
  fetchSearch,
  fetchSearchReset,
  fetchChecklist
} from './actions'

import Wrapper from './Wrapper'
import SectionNav from './components/SectionNav'
import SearchBox from './components/SearchBox'
import UserAvatar from './components/UserAvatar'
import OnboardingChecklist from './components/OnboardingChecklist'

const NavigationWrapper = styled.div``

const Divider = styled.div`
  background-color: ${props => props.theme.borderColor};
  width: 1px;
  height: 100%;
`

const LogoContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  height: 100%;
  padding: ${props => props.theme.padding};
  cursor: pointer;

  &:hover,
  &:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: .8;
    transition: opacity .15s ease-out;
  }
`

const DevBanner = styled.div`
  height: 8px;
  width: 100%;
  background: repeating-linear-gradient(-45deg, ${props => props.theme.colors.yellow}, ${props => props.theme.colors.yellow} 8px, ${props => props.theme.colors.darkestBlue} 8px, ${props => props.theme.colors.darkestBlue} 16px);
`

class Navigation extends Component {
  UNSAFE_componentWillMount () {
    this.props.actions.fetchChecklist()
  }

  fetchSearch = (query) => {
    // Fetch people with query
    if (query.length > 0) {
      this.props.actions.fetchSearch({
        query
      })
    }
  }

  render () {
    const {
      results,
      loading,
      router,
      theme,
      location,
      state,
      session,
      handleLogout,
      actions,
      recentSequences,
      checklist
    } = this.props

    const isDev = !(window.location.hostname === 'app.interseller.io')

    return (
      <NavigationWrapper>
        {isDev && <DevBanner />}
        <Wrapper>
          <LogoContainer onClick={() => router.push('/')}>
            {/* <Tooltip
              key='logo'
              title='Honoring Pride month and<br/>the LGBTQ+ community'
              position='bottom'
              trigger='mouseenter'
              arrow
            >
              <img src='/logo-lgbtq.svg' width='37' height='37' />
            </Tooltip> */}
            <Logo width={37} height={35} />
          </LogoContainer>
          <SectionNav session={session} />
          <Divider />
          <SearchBox
            results={results}
            loading={loading}
            router={router}
            theme={theme}
            onChange={this.fetchSearch}
            actions={actions}
            recentSequences={recentSequences}
            location={location}
          />
          <Divider />
          {session &&
            <UserAvatar
              handleLogout={handleLogout}
              session={session}
              state={state}
              router={router}
            />}
        </Wrapper>
        <OnboardingChecklist
          checklist={checklist}
          location={location}
          router={router}
          session={session}
          state={state}
        />
      </NavigationWrapper>
    )
  }
}

Navigation.propTypes = {
  actions: PropTypes.object,
  router: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.object,
  session: PropTypes.object,
  state: PropTypes.object,
  handleLogout: PropTypes.func,
  results: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  recentSequences: PropTypes.instanceOf(Immutable.List),
  checklist: PropTypes.instanceOf(Immutable.Map)
}

// Specifies the default values for props:
Navigation.defaultProps = {
  results: Immutable.List([]),
  loading: false
}

const mapStateToProps = createStructuredSelector({
  results: selectResults(),
  loading: selectLoading(),
  recentSequences: selectRecentSequences(),
  checklist: selectChecklist()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSearch,
      fetchSearchReset,
      fetchChecklist
    }, dispatch)
  }
}

export default withRouter(connect(mapStateToProps, mapDispatchToProps)(withTheme(Navigation)))
