import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import DropDown from 'components/DropDown'
import Loading from 'components/Loading'

const Container = styled.div`
  padding: 0 3rem 3rem 3rem;
`

class CopySequence extends Component {
  render () {
    const {
      sequences,
      loading,
      setCreateInfo,
      campaignId
    } = this.props

    if (loading) {
      return <Loading />
    }

    const sequenceOptions = sequences.map((sequence, i) => (
      {
        label: sequence.get('title'),
        value: sequence.get('_id'),
        index: i
      }
    ))

    return (
      <Container>
        {sequenceOptions.count() > 0 &&
          <DropDown
            label='Copy Steps From Sequence'
            description='Copy steps and settings from the selected sequence'
            inputId='clone_campaign_id'
            onOptionChange={(option) => {
              setCreateInfo({ clone_campaign_id: (option ? option.value : null) })
            }}
            value={campaignId}
            options={sequenceOptions}
            mt='1.5rem'
          />}
      </Container>
    )
  }
}

CopySequence.propTypes = {
  sequences: PropTypes.instanceOf(Immutable.List),
  setCreateInfo: PropTypes.func,
  campaignId: PropTypes.string,
  loading: PropTypes.bool
}

CopySequence.defaultProps = {
  sequences: Immutable.List([])
}

export default CopySequence
