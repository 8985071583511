import React, { Component } from 'react'

import styled from 'styled-components'
import Checkmark from 'svg/Checkmark'

const Wrapper = styled.div`
  color: white;
  background-color: ${props => props.theme.colors.blue};
  text-align: center;
  border-radius: 9999px;
  flex: 0 0 auto;
  display: flex;
  padding: .25rem;

  svg {
    fill: white;
    margin: auto;
  }
`

class Verified extends Component {
  render () {
    return (
      <Wrapper>
        <Checkmark width={6} height={6} />
      </Wrapper>
    )
  }
}

export default Verified
