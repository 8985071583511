import React from 'react'
import PropTypes from 'prop-types'

import { Bar } from 'recharts'
import Graph from 'containers/Reports/components/Graph'
import ReportTable from 'containers/Reports/components/ReportTable'

class SequenceReport extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      // aboslute positon on screen where graph is rendered
      graphRegion: { x: 0, y: 0, width: 0, height: 0 },
      tableRegion: { x: 0, y: 0, width: 0, height: 0 }
    }
  }

  formatGraphData = () => {
    const { bookedReports } = this.props
    const resp = bookedReports.getIn(['data', 'report', 'day'])
    const graphData = []
    if (resp) {
      resp.entrySeq().forEach(([date, meetings]) => {
        const year = date.substring(0, 4)
        const month = date.substring(4, 6)
        const day = date.substring(6, 8)
        const formattedDate = `${day}/${month}/${year}`
        graphData.push({ date: formattedDate, meetings })
      })
    }

    return graphData
  }

  render () {
    const {
      theme,
      bookedReports,
      sequenceStats,
      selectedDateRange
    } = this.props
    const { graphRegion, tableRegion } = this.state

    const data = bookedReports.get('data')
    const loading = bookedReports.get('loading')
    let graphData = []
    if (!loading && data) {
      graphData = this.formatGraphData()
    }

    return (
      <>
        <Graph
          theme={theme}
          graphRegion={graphRegion}
          graphData={graphData}
          selectedDateRange={selectedDateRange}
        >
          <Bar dataKey='value' fill={this.props.theme.colors.blue} />
        </Graph>
        <ReportTable
          tableRegion={tableRegion}
          sequenceStats={sequenceStats}
        />
      </>
    )
  }
}

SequenceReport.propTypes = {
  graphMenuRef: PropTypes.object,
  graphRegion: PropTypes.object,
  tableRegion: PropTypes.object,
  bookedReports: PropTypes.object,
  repliedReports: PropTypes.object,
  sequenceStats: PropTypes.object,
  theme: PropTypes.object,
  selectedDateRange: PropTypes.any
}

export default SequenceReport
