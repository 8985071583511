import React from 'react'
import styled, { withTheme } from 'styled-components'
import PropTypes from 'prop-types'

import GraphTooltip from './components/GraphTooltip'

import 'react-toggle/style.css'
import {
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
  ComposedChart
} from 'recharts'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  width: 100%;
  height: 100%;

  background-color: ${props => props.theme.colors.white};
`

const GraphContainer = styled.div`
  display: flex;
  flex-direction: row;
  height: 100%;

  padding: 1.5rem 1.5rem 0.75rem 1rem;

  .recharts-cartesian-axis-tick-line {
    display: none;
  }
`

const GraphSizingContainer = styled.div`
  flex: 1;
  width: 0;
`

class Graph extends React.Component {
  render () {
    const {
      graphData,
      unitLabel,
      unitLabelPlural,
      defaultTheme = true,
      selectedDateRange,
      xAxisDomain,
      xAxisDomainType,
      yAxisDomain,
      yAxisDomainType
    } = this.props

    if (!graphData || graphData.length < 1) {
      return (<></>)
    }

    const graphValues = graphData[selectedDateRange]

    return (
      <Wrapper>
        <GraphContainer>
          <GraphSizingContainer>
            <ResponsiveContainer>
              <ComposedChart data={graphValues}>

                {defaultTheme === true &&
                  <CartesianGrid
                    stroke={this.props.theme.colors.gray30}
                    vertical={false}
                  />}
                {defaultTheme === true &&
                  <XAxis
                    dataKey='date'
                    tick={{ stroke: this.props.theme.colors.gray70, fill: this.props.theme.colors.gray70, strokeWidth: 0, fontSize: '13px', fontWeight: 300 }}
                    axisLine={{ stroke: this.props.theme.colors.gray40 }}
                    type={xAxisDomainType}
                    domain={xAxisDomain}
                  />}
                {defaultTheme === true &&
                  <YAxis
                    width={30}
                    tick={{ stroke: this.props.theme.colors.gray70, fill: this.props.theme.colors.gray70, strokeWidth: 0, fontSize: '13px', fontWeight: 300 }}
                    axisLine={{ stroke: this.props.theme.colors.gray40, strokeWidth: 1 }}
                    type={yAxisDomainType}
                    domain={yAxisDomain}
                  />}
                {defaultTheme === true &&
                  <Tooltip
                    cursor={false}
                    animationDuration={0}
                    content={
                      <GraphTooltip
                        unitLabel={unitLabel}
                        unitLabelPlural={unitLabelPlural}
                        dateRange={selectedDateRange}
                      />
                      }
                  />}

                {this.props.children}

              </ComposedChart>
            </ResponsiveContainer>
          </GraphSizingContainer>
        </GraphContainer>
      </Wrapper>
    )
  }
}

Graph.propTypes = {
  theme: PropTypes.object,
  defaultTheme: PropTypes.bool,
  graphData: PropTypes.object,
  unitLabel: PropTypes.string,
  unitLabelPlural: PropTypes.string,
  selectedDateRange: PropTypes.string,
  yAxisDomain: PropTypes.array,
  xAxisDomain: PropTypes.array,
  yAxisDomainType: PropTypes.string,
  xAxisDomainType: PropTypes.string,
  children: PropTypes.any
}

export default withTheme(Graph)
