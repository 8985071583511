import React, { useState, useEffect, useRef } from 'react'
import { withRouter } from 'react-router'
import styled from 'styled-components'
import PropTypes from 'prop-types'
import Immutable, { fromJS } from 'immutable'
import { HotTable, HotColumn } from '@handsontable/react'
import Button from 'components/Button'
import ToolbarButton from 'components/ToolbarButton'
import NumberFormat from 'react-number-format'
import EmptyState from 'components/EmptyState'
import { Satellite } from 'svg'
import Loading from 'components/Loading'

import TagsCellRenderer from './components/TagsCellRenderer'
import NameCellRenderer from './components/NameCellRenderer'
import DateCellRenderer from './components/DateCellRenderer'
import SocialLinksCellRenderer from './components/SocialLinksCellRenderer'

import {
  registerCellType,
  DateCellType
} from 'handsontable/cellTypes'

import {
  AutoColumnSize,
  AutoRowSize,
  Autofill,
  ManualColumnResize,
  registerPlugin
} from 'handsontable/plugins'

import 'handsontable/dist/handsontable.full.css'

registerCellType(DateCellType)
registerPlugin(AutoColumnSize)
registerPlugin(AutoRowSize)
registerPlugin(Autofill)
registerPlugin(ManualColumnResize)

const Wrapper = styled.div`
  width: 100%;
  height: calc(100vh - 182px);
  margin-left: 1rem;
  padding-right: 1.5rem;
  overflow: hidden;
`

const InnerContent = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
`

const Header = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1rem;
  background: white;
  border: 1px solid ${props => props.theme.colors.talentPoolBorderGray};
  border-bottom: none;
`

const HeaderActions = styled.div`
  display: flex;
  align-items: center;
`

const PersonsCountWrapper = styled.div`
  display: flex;
  font-size: 14px;
  font-weight: 700;
  color: ${props => props.theme.colors.blueText};

  span {
    font-weight: 600;
  }
`

const SelectedWrapper = styled.div`
  font-weight: 600;
  font-size: 14px;
  color: ${props => props.theme.colors.blueText};
  padding: 0 1rem;
`

const TableWrapper = styled.div`
  flex: 1;
  width: 100%;
  height: 100%;
  overflow: hidden;

  .handsontable {
    z-index: 1;
    font: ${props => props.theme.fonts.normal};
    font-weight: 500;
    color: ${props => props.theme.colors.blueText};

    tr:first-child {
      th {
        border: 1px solid ${props => props.theme.colors.talentPoolBorderGray};
        padding-left: 0.2rem;
      }

      td {
        border-top: 1px solid ${props => props.theme.colors.talentPoolBorderGray};
      }

      td {
        border-top: 1px solid ${props => props.theme.colors.talentPoolBorderGray};
      }
    }

    td:first-of-type {
      border-left: none;
    }

    th {
      background-color: ${props => props.theme.colors.talentPoolHeaderBackground};
      border-color: ${props => props.theme.colors.talentPoolBorderGray};
      color: #7A859F;
      font-weight: 700;
      font-size: 11px;
      max-height: 25px;
      text-align: left;
      text-transform: uppercase;
      padding: 0 10px;
      padding-top: 10px;

      label {
        margin-left: 0.5rem;
      }

      .checker {
        position: relative;
        margin: 5px;
        left: 0.4rem;
        top: 0.2em;
      }

      &.ht__highlight {
        background-color: ${props => props.theme.colors.talentPoolHeaderBackgroundSelected};
      }

      &.ht__active_highlight {
        color: ${props => props.theme.colors.talentPoolBlue};
        border-color: ${props => props.theme.colors.talentPoolBlue};
        background-color: white;
      }
    }

    td {
      font-size: 13px;
      border-color: ${props => props.theme.colors.talentPoolBorderGray};
      height: 35px;
      padding: 0 8px 0 8px;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;

      /* &.edited {
        background-color: #FDF3C6;
      } */
      >div {
        height: 100%
      }

      input[type="checkbox"] {
        position: relative;
        top: 2px;
      }
    }

    .handsontableInput {
      color: ${props => props.theme.colors.blueText};
      font-weight: 500;
      line-height: 1.2;
      padding: 6px 6px 0 6px;
    }

    th, td {
      input[type="checkbox"],
      .checker {
        &:before {
          position: relative;
          display: block;
          width: 0.7rem;
          height: 0.7rem;
          border: 1px solid ${props => props.theme.colors.gray40};
          content: "";
          background: ${props => props.theme.colors.white};
        }

        &:after {
          position: relative;
          display: block;
          left: 2px;
          top: -11px;
          width: 0.7rem;
          height: 0.7rem;
        }

        &:checked:before {
          background: none;
          border-style: solid;
          border-width: 1px;
          border-color: ${props => props.theme.colors.blue};
        }
      }
    }

    td.area:before {
      background-color: white;
    }

    .currentRow {
      background-color: ${props => props.theme.colors.talentPoolSelectedBackground};
    }

    .changed {
      background-color: #FDF3C6;
    }
  }

`

let hotTable
let talentList = Immutable.Set()

function TalentPoolTable ({
  actions,
  onAddTalent,
  onAddToCampaign,
  isLoading,
  talentPool,
  onClearFilters,
  onDeleteTalent,
  selectedIds,
  setSelectedIds,
  isEnabled,
  talentPoolHasNoEntries,
  isEditMode,
  setIsEditMode,
  editedRows,
  handleSave,
  isTalentPoolPopulating
}) {
  hotTable = useRef(null)

  const [allSelected, setAllSelected] = useState(false)

  const updateEditedRows = (val) => {
    editedRows = editedRows.add(val)
  }

  useEffect(() => {
    // add select all in Handsontable
    document.addEventListener('mousedown', handleSelectAll)
    return function cleanup () {
      document.removeEventListener('mousedown', handleSelectAll)
    }
  }, [])

  useEffect(() => {
    talentList = talentPool.getIn(['data', 'people']) || Immutable.Set()

    hotTable?.current?.hotInstance?.loadData(JSON.parse(JSON.stringify(talentList)))
  }, [talentPool])

  // useEffect(() => {
  //   const { query } = location
  //   const hasFilters = !!Object.keys(query).length
  //   console.log('hasFilters: ', hasFilters)
  // }, [location.query])

  const onAfterChange = (changes, src) => {
    if (changes && changes.length) {
      if (changes.length) {
        if (hotTable?.current?.hotInstance) {
          const hot = hotTable.current.hotInstance

          if (!isEditMode) {
            setIsEditMode(true)
          }

          changes.forEach(change => {
            // changes: Arrays[[row, prop, oldVal, newVal],...]
            // deconstructing each values from change
            const rowNum = change[0]
            const rowData = hot.getSourceDataAtRow(rowNum)

            const isRowEdited = editedRows.some(row => row.id === rowData.id)

            if (editedRows.size && isRowEdited) {
              editedRows = editedRows.map(row => {
                if (row.id === rowData.id) {
                  return rowData
                }
                return row
              })
            } else {
              updateEditedRows(rowData)
            }
          })
        }
      }
    }
  }

  const afterGetColHeader = (i, th) => {
    if (i === 0) {
      th.innerHTML = `<input type='checkbox' class='checker colHeader columnSorting sortAction' ${allSelected ? 'checked' : null}>
      <label for="checker">Name</label>`
      th.style.lineHeight = '-5px'
    }
  }

  const setRowAsSelected = (params, all = false) => {
    if (all) {
      if (params?.val) {
        setSelectedIds(talentList.map(talent => talent.get('id')))
        setAllSelected(true)
      } else {
        setSelectedIds(Immutable.Set())
        setAllSelected(false)
      }
    } else {
      if (params?.id) {
        if (selectedIds?.includes(params.id)) {
          setSelectedIds(fromJS([...selectedIds.filter(rowId => rowId !== params.id)]))
        } else {
          setSelectedIds(fromJS([...selectedIds, params.id]))
        }
        setAllSelected(false)
      }
    }
  }

  const handleCancel = () => {
    setIsEditMode(false)
    editedRows = Immutable.Set()
    hotTable.current.hotInstance.loadData(JSON.parse(JSON.stringify(talentList.toJS())))
  }

  const handleAddToSequence = () => onAddToCampaign()

  const afterScrollVertically = () => {
    if (hotTable?.current?.hotInstance) {
      const hot = hotTable.current.hotInstance
      const lastVisibleRow = hot.getPlugin('autoRowSize').getLastVisibleRow()
      const rowCount = hot.countRows()

      if (!isLoading && (lastVisibleRow === rowCount - 1) && talentPool.getIn(['data', 'scrollId'])) {
        hot.suspendRender()
        actions.fetchMoreTalents({ scrollId: talentPool.getIn(['data', 'scrollId']) })
        hot.resumeRender()
      }
    }
  }

  const onBeforeChange = (changes) => {
    changes.forEach(c => {
      const prevVal = c[2]

      if (!prevVal) {
        c[2] = ''
      }
    })
  }

  const handleSelectAll = e => {
    if (e?.target?.nodeName === 'INPUT' && e?.target?.className?.includes('checker')) {
      e.stopPropagation()
      const checked = e.target.checked
      const params = {
        val: !checked
      }
      setRowAsSelected(params, true)
    }
  }

  const selectedCount = selectedIds.size
  const candidatesCount = talentPool?.getIn(['data', 'total']) || 0

  return (
    <Wrapper>
      {!isEnabled && !isLoading && !isTalentPoolPopulating &&
        <EmptyState
          bg='white'
          mt='0'
          height='100%'
          title='Talent Pool'
          description='Talent pool is currently not enabled for your team, contact support for more information.'
          icon={<Satellite />}
        />}
      {!candidatesCount && !isLoading && isEnabled && !isTalentPoolPopulating &&
        <EmptyState
          bg='white'
          mt='0'
          height='100%'
          icon={<Satellite />}
          title={!talentPoolHasNoEntries ? 'No Candidates Found' : 'Nothing Yet'}
          description={!talentPoolHasNoEntries ? "We couldn't find any candidates that match your filters. Try updating your filters for clearing them." : 'There are no candidates in your talent pool. Add a candidate to start using your talent pool.'}
        >
          {!talentPoolHasNoEntries && (
            <Button
              label='Clear Filters'
              onClick={onClearFilters}
            />
          )}
          {/* Uncomment when adding back add contact */}
          {/* <Button
            label={!talentPoolHasNoEntries ? 'Clear Filters' : 'Add Contact'}
            onClick={!talentPoolHasNoEntries ? onClearFilters : onAddTalent}
          /> */}
        </EmptyState>}
      {isTalentPoolPopulating &&
        <EmptyState
          bg='white'
          mt='0'
          height='100%'
          title='Creating your Talent Pool.'
          description='Please check back in a minute.'
          icon={<Loading />}
        />}
      {candidatesCount > 0 && isEnabled && !isTalentPoolPopulating &&
        <InnerContent>
          <Header>
            <PersonsCountWrapper>
              <NumberFormat
                value={candidatesCount}
                thousandSeparator
                decimalSeparator='.'
                fixedDecimalScale
                displayType='text'
                suffix=' Candidates'
              />
            </PersonsCountWrapper>
            <HeaderActions>
              {selectedCount > 0 && <SelectedWrapper> {selectedCount} selected </SelectedWrapper>}
              {/* eslint-disable react/jsx-indent */}
              {selectedCount > 0 && !isEditMode
                ? <>
                    <Button
                      label='Add To Sequence'
                      onClick={handleAddToSequence}
                      mr='0.5rem'
                      small
                    />
                    <ToolbarButton
                      tooltip='Remove Candidate'
                      icon={['fal', 'trash-alt']}
                      onClick={onDeleteTalent}
                      key='remove'
                    />
                  </>
                : <>
                    {/* Uncomment when we re-implement adding contact */}
                    {/* <Button
                      disabled={isEditMode && selectedCount}
                      label='Add Contact'
                      onClick={onAddTalent}
                      small
                    /> */}
                    <Button
                      disabled={!isEditMode}
                      label='Cancel'
                      onClick={handleCancel}
                      mr='0.5rem'
                      ml='0.5rem'
                      small
                    />
                    <Button
                      disabled={!isEditMode}
                      label='Save Changes'
                      onClick={handleSave}
                      primary
                      small
                    />
                  </>}
              {/* eslint-enable react/jsx-indent */}
            </HeaderActions>
          </Header>
          <TableWrapper>
            <HotTable
              key='hotTable'
              id='talent-pool'
              ref={hotTable}
              beforeChange={onBeforeChange}
              afterChange={onAfterChange}
              currentRowClassName='currentRow'
              stretchH='all'
              colWidths={208}
              manualColumnResize
              licenseKey='8479c-c3fe4-2c291-14812-d7029'
              afterGetColHeader={afterGetColHeader}
              selectionMode='multiple'
              fixedColumnsLeft='1'
              rowHeights='36'
              afterScrollVertically={afterScrollVertically}
              viewportRowRenderingOffset='200'
            >
              <HotColumn data='name' title='Name'>
                <NameCellRenderer
                  hot-renderer
                  setRowAsSelected={setRowAsSelected}
                  selectedRowIds={selectedIds}
                />
              </HotColumn>
              <HotColumn data='email' title='Email' className='htMiddle' />
              <HotColumn data='email2' title='Email 2' className='htMiddle' />
              <HotColumn data='email3' title='Email 3' className='htMiddle' />
              <HotColumn data='location' title='Location' className='htMiddle' />
              <HotColumn data='linkedin' title='LinkedIn'>
                <SocialLinksCellRenderer hot-renderer />
              </HotColumn>
              <HotColumn data='notes' title='Tags'>
                <TagsCellRenderer hot-renderer />
              </HotColumn>
              <HotColumn
                data='last_messaged_at'
                title='Last Messaged'
                type='date'
                dateFormat='MM/DD/YYYY'
              >
                <DateCellRenderer hot-renderer />
              </HotColumn>
              <HotColumn
                data='replied_at'
                title='Last Replied'
                type='date'
                dateFormat='MM/DD/YYYY'
              >
                <DateCellRenderer hot-renderer />
              </HotColumn>
              <HotColumn data='role' title='Title' className='htMiddle' />
            </HotTable>
          </TableWrapper>
        </InnerContent>}
    </Wrapper>
  )
}

TalentPoolTable.propTypes = {
  actions: PropTypes.object,
  data: PropTypes.object,
  isEnabled: PropTypes.bool,
  isLoading: PropTypes.bool,
  onAddTalent: PropTypes.func,
  onDeleteTalent: PropTypes.func,
  onSetSelectedIds: PropTypes.func,
  onAddToCampaign: PropTypes.func,
  onClearFilters: PropTypes.func,
  onSelectTalent: PropTypes.func,
  selectedIds: PropTypes.object,
  setSelectedIds: PropTypes.func,
  talentPool: PropTypes.object,
  talentPoolHasNoEntries: PropTypes.bool,
  isEditMode: PropTypes.bool,
  setIsEditMode: PropTypes.func,
  editedRows: PropTypes.object,
  handleSave: PropTypes.func,
  isTalentPoolPopulating: PropTypes.bool
}

export default withRouter(TalentPoolTable)
