const emojis = {
  smileys_and_people: [
    { name: 'grinning-face', emoji: '😀' },
    { name: 'smiling-face-with-open-mouth', emoji: '😃' },
    { name: 'smiling-face-with-open-mouth-and-smiling-eyes', emoji: '😄' },
    { name: 'grinning-face-with-smiling-eyes', emoji: '😁' },
    { name: 'smiling-face-with-open-mouth-and-tightly-closed-eyes', emoji: '😆' },
    { name: 'smiling-face-with-open-mouth-and-cold-sweat', emoji: '😅' },
    { name: 'rolling-on-the-floor-laughing', emoji: '🤣' },
    { name: 'face-with-tears-of-joy', emoji: '😂' },
    { name: 'slightly-smiling-face', emoji: '🙂' },
    { name: 'upside-down-face', emoji: '🙃' },
    { name: 'winking-face', emoji: '😉' },
    { name: 'smiling-face-with-smiling-eyes', emoji: '😊' },
    { name: 'smiling-face-with-halo', emoji: '😇' },
    { name: 'smiling-face-with-smiling-eyes-and-three-hearts', emoji: '🥰' },
    { name: 'smiling-face-with-heart-shaped-eyes', emoji: '😍' },
    { name: 'grinning-face-with-star-eyes', emoji: '🤩' },
    { name: 'face-throwing-a-kiss', emoji: '😘' },
    { name: 'kissing-face', emoji: '😗' },
    { name: 'kissing-face-with-closed-eyes', emoji: '😚' },
    { name: 'kissing-face-with-smiling-eyes', emoji: '😙' },
    { name: 'face-savouring-delicious-food', emoji: '😋' },
    { name: 'face-with-stuck-out-tongue', emoji: '😛' },
    { name: 'face-with-stuck-out-tongue-and-winking-eye', emoji: '😜' },
    { name: 'grinning-face-with-one-large-and-one-small-eye', emoji: '🤪' },
    { name: 'face-with-stuck-out-tongue-and-tightly-closed-eyes', emoji: '😝' },
    { name: 'money-mouth-face', emoji: '🤑' },
    { name: 'hugging-face', emoji: '🤗' },
    { name: 'smiling-face-with-smiling-eyes-and-hand-covering-mouth', emoji: '🤭' },
    { name: 'face-with-finger-covering-closed-lips', emoji: '🤫' },
    { name: 'thinking-face', emoji: '🤔' },
    { name: 'zipper-mouth-face', emoji: '🤐' },
    { name: 'face-with-one-eyebrow-raised', emoji: '🤨' },
    { name: 'neutral-face', emoji: '😐' },
    { name: 'expressionless-face', emoji: '😑' },
    { name: 'face-without-mouth', emoji: '😶' },
    { name: 'smirking-face', emoji: '😏' },
    { name: 'unamused-face', emoji: '😒' },
    { name: 'face-with-rolling-eyes', emoji: '🙄' },
    { name: 'grimacing-face', emoji: '😬' },
    { name: 'lying-face', emoji: '🤥' },
    { name: 'relieved-face', emoji: '😌' },
    { name: 'pensive-face', emoji: '😔' },
    { name: 'sleepy-face', emoji: '😪' },
    { name: 'drooling-face', emoji: '🤤' },
    { name: 'sleeping-face', emoji: '😴' },
    { name: 'face-with-medical-mask', emoji: '😷' },
    { name: 'face-with-thermometer', emoji: '🤒' },
    { name: 'face-with-head-bandage', emoji: '🤕' },
    { name: 'nauseated-face', emoji: '🤢' },
    { name: 'face-with-open-mouth-vomiting', emoji: '🤮' },
    { name: 'sneezing-face', emoji: '🤧' },
    { name: 'overheated-face', emoji: '🥵' },
    { name: 'freezing-face', emoji: '🥶' },
    { name: 'face-with-uneven-eyes-and-wavy-mouth', emoji: '🥴' },
    { name: 'dizzy-face', emoji: '😵' },
    { name: 'shocked-face-with-exploding-head', emoji: '🤯' },
    { name: 'face-with-cowboy-hat', emoji: '🤠' },
    { name: 'face-with-party-horn-and-party-hat', emoji: '🥳' },
    { name: 'smiling-face-with-sunglasses', emoji: '😎' },
    { name: 'nerd-face', emoji: '🤓' },
    { name: 'face-with-monocle', emoji: '🧐' },
    { name: 'confused-face', emoji: '😕' },
    { name: 'worried-face', emoji: '😟' },
    { name: 'slightly-frowning-face', emoji: '🙁' },
    { name: 'face-with-open-mouth', emoji: '😮' },
    { name: 'hushed-face', emoji: '😯' },
    { name: 'astonished-face', emoji: '😲' },
    { name: 'flushed-face', emoji: '😳' },
    { name: 'face-with-pleading-eyes', emoji: '🥺' },
    { name: 'frowning-face-with-open-mouth', emoji: '😦' },
    { name: 'anguished-face', emoji: '😧' },
    { name: 'fearful-face', emoji: '😨' },
    { name: 'face-with-open-mouth-and-cold-sweat', emoji: '😰' },
    { name: 'disappointed-but-relieved-face', emoji: '😥' },
    { name: 'crying-face', emoji: '😢' },
    { name: 'loudly-crying-face', emoji: '😭' },
    { name: 'face-screaming-in-fear', emoji: '😱' },
    { name: 'confounded-face', emoji: '😖' },
    { name: 'persevering-face', emoji: '😣' },
    { name: 'disappointed-face', emoji: '😞' },
    { name: 'face-with-cold-sweat', emoji: '😓' },
    { name: 'weary-face', emoji: '😩' },
    { name: 'tired-face', emoji: '😫' },
    { name: 'face-with-look-of-triumph', emoji: '😤' },
    { name: 'pouting-face', emoji: '😡' },
    { name: 'angry-face', emoji: '😠' },
    { name: 'serious-face-with-symbols-covering-mouth', emoji: '🤬' },
    { name: 'smiling-face-with-horns', emoji: '😈' },
    { name: 'imp', emoji: '👿' },
    { name: 'skull', emoji: '💀' },
    { name: 'skull-and-crossbones', emoji: '☠' },
    { name: 'pile-of-poo', emoji: '💩' },
    { name: 'clown-face', emoji: '🤡' },
    { name: 'japanese-ogre', emoji: '👹' },
    { name: 'japanese-goblin', emoji: '👺' },
    { name: 'ghost', emoji: '👻' },
    { name: 'extraterrestrial-alien', emoji: '👽' },
    { name: 'alien-monster', emoji: '👾' },
    { name: 'robot-face', emoji: '🤖' },
    { name: 'smiling-cat-face-with-open-mouth', emoji: '😺' },
    { name: 'grinning-cat-face-with-smiling-eyes', emoji: '😸' },
    { name: 'cat-face-with-tears-of-joy', emoji: '😹' },
    { name: 'smiling-cat-face-with-heart-shaped-eyes', emoji: '😻' },
    { name: 'cat-face-with-wry-smile', emoji: '😼' },
    { name: 'kissing-cat-face-with-closed-eyes', emoji: '😽' },
    { name: 'weary-cat-face', emoji: '🙀' },
    { name: 'crying-cat-face', emoji: '😿' },
    { name: 'pouting-cat-face', emoji: '😾' },
    { name: 'kiss-mark', emoji: '💋' },
    { name: 'waving-hand-sign', emoji: '👋' },
    { name: 'raised-back-of-hand', emoji: '🤚' },
    { name: 'raised-hand-with-fingers-splayed', emoji: '🖐' },
    { name: 'raised-hand', emoji: '✋' },
    { name: 'raised-hand-with-part-between-middle-and-ring-fingers', emoji: '🖖' },
    { name: 'ok-hand-sign', emoji: '👌' },
    { name: 'victory-hand', emoji: '✌' },
    { name: 'hand-with-index-and-middle-fingers-crossed', emoji: '🤞' },
    { name: 'i-love-you-hand-sign', emoji: '🤟' },
    { name: 'sign-of-the-horns', emoji: '🤘' },
    { name: 'call-me-hand', emoji: '🤙' },
    { name: 'white-left-pointing-backhand-index', emoji: '👈' },
    { name: 'white-right-pointing-backhand-index', emoji: '👉' },
    { name: 'white-up-pointing-backhand-index', emoji: '👆' },
    { name: 'reversed-hand-with-middle-finger-extended', emoji: '🖕' },
    { name: 'white-down-pointing-backhand-index', emoji: '👇' },
    { name: 'white-up-pointing-index', emoji: '☝' },
    { name: 'thumbs-up-sign', emoji: '👍' },
    { name: 'thumbs-down-sign', emoji: '👎' },
    { name: 'raised-fist', emoji: '✊' },
    { name: 'fisted-hand-sign', emoji: '👊' },
    { name: 'left-facing-fist', emoji: '🤛' },
    { name: 'right-facing-fist', emoji: '🤜' },
    { name: 'clapping-hands-sign', emoji: '👏' },
    { name: 'person-raising-both-hands-in-celebration', emoji: '🙌' },
    { name: 'open-hands-sign', emoji: '👐' },
    { name: 'palms-up-together', emoji: '🤲' },
    { name: 'handshake', emoji: '🤝' },
    { name: 'person-with-folded-hands', emoji: '🙏' },
    { name: 'writing-hand', emoji: '✍' },
    { name: 'nail-polish', emoji: '💅' },
    { name: 'selfie', emoji: '🤳' },
    { name: 'flexed-biceps', emoji: '💪' },
    { name: 'leg', emoji: '🦵' },
    { name: 'foot', emoji: '🦶' },
    { name: 'ear', emoji: '👂' },
    { name: 'nose', emoji: '👃' },
    { name: 'brain', emoji: '🧠' },
    { name: 'tooth', emoji: '🦷' },
    { name: 'bone', emoji: '🦴' },
    { name: 'eyes', emoji: '👀' },
    { name: 'eye', emoji: '👁' },
    { name: 'tongue', emoji: '👅' },
    { name: 'mouth', emoji: '👄' },
    { name: 'baby', emoji: '👶' },
    { name: 'child', emoji: '🧒' },
    { name: 'boy', emoji: '👦' },
    { name: 'girl', emoji: '👧' },
    { name: 'adult', emoji: '🧑' },
    { name: 'person-with-blond-hair', emoji: '👱' },
    { name: 'man', emoji: '👨' },
    { name: 'bearded-person', emoji: '🧔' },
    { name: 'blond-man', emoji: '👱‍♂️' },
    { name: 'man-red-haired', emoji: '👨‍🦰' },
    { name: 'man-curly-haired', emoji: '👨‍🦱' },
    { name: 'man-white-haired', emoji: '👨‍🦳' },
    { name: 'man-bald', emoji: '👨‍🦲' },
    { name: 'woman', emoji: '👩' },
    { name: 'blonde-woman', emoji: '👱‍♀️' },
    { name: 'woman-red-haired', emoji: '👩‍🦰' },
    { name: 'woman-curly-haired', emoji: '👩‍🦱' },
    { name: 'woman-white-haired', emoji: '👩‍🦳' },
    { name: 'woman-bald', emoji: '👩‍🦲' },
    { name: 'older-adult', emoji: '🧓' },
    { name: 'older-man', emoji: '👴' },
    { name: 'older-woman', emoji: '👵' },
    { name: 'person-frowning', emoji: '🙍' },
    { name: 'man-frowning', emoji: '🙍‍♂️' },
    { name: 'woman-frowning', emoji: '🙍‍♀️' },
    { name: 'person-with-pouting-face', emoji: '🙎' },
    { name: 'man-pouting', emoji: '🙎‍♂️' },
    { name: 'woman-pouting', emoji: '🙎‍♀️' },
    { name: 'face-with-no-good-gesture', emoji: '🙅' },
    { name: 'man-gesturing-not-ok', emoji: '🙅‍♂️' },
    { name: 'woman-gesturing-not-ok', emoji: '🙅‍♀️' },
    { name: 'face-with-ok-gesture', emoji: '🙆' },
    { name: 'man-gesturing-ok', emoji: '🙆‍♂️' },
    { name: 'woman-gesturing-ok', emoji: '🙆‍♀️' },
    { name: 'information-desk-person', emoji: '💁' },
    { name: 'man-tipping-hand', emoji: '💁‍♂️' },
    { name: 'woman-tipping-hand', emoji: '💁‍♀️' },
    { name: 'happy-person-raising-one-hand', emoji: '🙋' },
    { name: 'man-raising-hand', emoji: '🙋‍♂️' },
    { name: 'woman-raising-hand', emoji: '🙋‍♀️' },
    { name: 'person-bowing-deeply', emoji: '🙇' },
    { name: 'man-bowing-deeply', emoji: '🙇‍♂️' },
    { name: 'woman-bowing-deeply', emoji: '🙇‍♀️' },
    { name: 'face-palm', emoji: '🤦' },
    { name: 'man-facepalming', emoji: '🤦‍♂️' },
    { name: 'woman-facepalming', emoji: '🤦‍♀️' },
    { name: 'shrug', emoji: '🤷' },
    { name: 'man-shrugging', emoji: '🤷‍♂️' },
    { name: 'woman-shrugging', emoji: '🤷‍♀️' },
    { name: 'male-health-worker', emoji: '👨‍⚕️' },
    { name: 'female-health-worker', emoji: '👩‍⚕️' },
    { name: 'male-student', emoji: '👨‍🎓' },
    { name: 'female-student', emoji: '👩‍🎓' },
    { name: 'male-teacher', emoji: '👨‍🏫' },
    { name: 'female-teacher', emoji: '👩‍🏫' },
    { name: 'male-judge', emoji: '👨‍⚖️' },
    { name: 'female-judge', emoji: '👩‍⚖️' },
    { name: 'male-farmer', emoji: '👨‍🌾' },
    { name: 'female-farmer', emoji: '👩‍🌾' },
    { name: 'male-cook', emoji: '👨‍🍳' },
    { name: 'female-cook', emoji: '👩‍🍳' },
    { name: 'male-mechanic', emoji: '👨‍🔧' },
    { name: 'female-mechanic', emoji: '👩‍🔧' },
    { name: 'male-factory-worker', emoji: '👨‍🏭' },
    { name: 'female-factory-worker', emoji: '👩‍🏭' },
    { name: 'male-office-worker', emoji: '👨‍💼' },
    { name: 'female-office-worker', emoji: '👩‍💼' },
    { name: 'male-scientist', emoji: '👨‍🔬' },
    { name: 'female-scientist', emoji: '👩‍🔬' },
    { name: 'male-technologist', emoji: '👨‍💻' },
    { name: 'female-technologist', emoji: '👩‍💻' },
    { name: 'male-singer', emoji: '👨‍🎤' },
    { name: 'female-singer', emoji: '👩‍🎤' },
    { name: 'male-artist', emoji: '👨‍🎨' },
    { name: 'female-artist', emoji: '👩‍🎨' },
    { name: 'male-pilot', emoji: '👨‍✈️' },
    { name: 'female-pilot', emoji: '👩‍✈️' },
    { name: 'male-astronaut', emoji: '👨‍🚀' },
    { name: 'female-astronaut', emoji: '👩‍🚀' },
    { name: 'male-firefighter', emoji: '👨‍🚒' },
    { name: 'female-firefighter', emoji: '👩‍🚒' },
    { name: 'police-officer', emoji: '👮' },
    { name: 'male-police-officer', emoji: '👮‍♂️' },
    { name: 'female-police-officer', emoji: '👮‍♀️' },
    { name: 'sleuth-or-spy', emoji: '🕵' },
    { name: 'male-sleuth', emoji: '🕵️‍♂️' },
    { name: 'female-sleuth', emoji: '🕵️‍♀️' },
    { name: 'guardsman', emoji: '💂' },
    { name: 'male-guard', emoji: '💂‍♂️' },
    { name: 'female-guard', emoji: '💂‍♀️' },
    { name: 'construction-worker', emoji: '👷' },
    { name: 'male-construction-worker', emoji: '👷‍♂️' },
    { name: 'female-construction-worker', emoji: '👷‍♀️' },
    { name: 'prince', emoji: '🤴' },
    { name: 'princess', emoji: '👸' },
    { name: 'man-with-turban', emoji: '👳' },
    { name: 'man-wearing-turban', emoji: '👳‍♂️' },
    { name: 'woman-wearing-turban', emoji: '👳‍♀️' },
    { name: 'man-with-gua-pi-mao', emoji: '👲' },
    { name: 'person-with-headscarf', emoji: '🧕' },
    { name: 'man-in-tuxedo', emoji: '🤵' },
    { name: 'bride-with-veil', emoji: '👰' },
    { name: 'pregnant-woman', emoji: '🤰' },
    { name: 'breast-feeding', emoji: '🤱' },
    { name: 'baby-angel', emoji: '👼' },
    { name: 'father-christmas', emoji: '🎅' },
    { name: 'mother-christmas', emoji: '🤶' },
    { name: 'superhero', emoji: '🦸' },
    { name: 'man-superhero', emoji: '🦸‍♂️' },
    { name: 'woman-superhero', emoji: '🦸‍♀️' },
    { name: 'supervillain', emoji: '🦹' },
    { name: 'man-supervillain', emoji: '🦹‍♂️' },
    { name: 'woman-supervillain', emoji: '🦹‍♀️' },
    { name: 'mage', emoji: '🧙' },
    { name: 'man-mage', emoji: '🧙‍♂️' },
    { name: 'woman-mage', emoji: '🧙‍♀️' },
    { name: 'fairy', emoji: '🧚' },
    { name: 'man-fairy', emoji: '🧚‍♂️' },
    { name: 'woman-fairy', emoji: '🧚‍♀️' },
    { name: 'vampire', emoji: '🧛' },
    { name: 'man-vampire', emoji: '🧛‍♂️' },
    { name: 'woman-vampire', emoji: '🧛‍♀️' },
    { name: 'merperson', emoji: '🧜' },
    { name: 'merman', emoji: '🧜‍♂️' },
    { name: 'merwoman', emoji: '🧜‍♀️' },
    { name: 'elf', emoji: '🧝' },
    { name: 'man-elf', emoji: '🧝‍♂️' },
    { name: 'woman-elf', emoji: '🧝‍♀️' },
    { name: 'genie', emoji: '🧞' },
    { name: 'man-genie', emoji: '🧞‍♂️' },
    { name: 'woman-genie', emoji: '🧞‍♀️' },
    { name: 'zombie', emoji: '🧟' },
    { name: 'man-zombie', emoji: '🧟‍♂️' },
    { name: 'woman-zombie', emoji: '🧟‍♀️' },
    { name: 'face-massage', emoji: '💆' },
    { name: 'man-getting-face-massage', emoji: '💆‍♂️' },
    { name: 'woman-getting-face-massage', emoji: '💆‍♀️' },
    { name: 'haircut', emoji: '💇' },
    { name: 'man-getting-haircut', emoji: '💇‍♂️' },
    { name: 'woman-getting-haircut', emoji: '💇‍♀️' },
    { name: 'pedestrian', emoji: '🚶' },
    { name: 'man-walking', emoji: '🚶‍♂️' },
    { name: 'woman-walking', emoji: '🚶‍♀️' },
    { name: 'runner', emoji: '🏃' },
    { name: 'man-running', emoji: '🏃‍♂️' },
    { name: 'woman-running', emoji: '🏃‍♀️' },
    { name: 'dancer', emoji: '💃' },
    { name: 'man-dancing', emoji: '🕺' },
    { name: 'man-in-business-suit-levitating', emoji: '🕴' },
    { name: 'woman-with-bunny-ears', emoji: '👯' },
    { name: 'men-with-bunny-ears-partying', emoji: '👯‍♂️' },
    { name: 'women-with-bunny-ears-partying', emoji: '👯‍♀️' },
    { name: 'person-in-steamy-room', emoji: '🧖' },
    { name: 'man-in-steamy-room', emoji: '🧖‍♂️' },
    { name: 'woman-in-steamy-room', emoji: '🧖‍♀️' },
    { name: 'person-in-lotus-position', emoji: '🧘' },
    { name: 'two-women-holding-hands', emoji: '👭' },
    { name: 'man-and-woman-holding-hands', emoji: '👫' },
    { name: 'two-men-holding-hands', emoji: '👬' },
    { name: 'kiss', emoji: '💏' },
    { name: 'kiss-man-man', emoji: '👨‍❤️‍💋‍👨' },
    { name: 'kiss-woman-woman', emoji: '👩‍❤️‍💋‍👩' },
    { name: 'couple-with-heart', emoji: '💑' },
    { name: 'couple-with-heart-man-man', emoji: '👨‍❤️‍👨' },
    { name: 'couple-with-heart-woman-woman', emoji: '👩‍❤️‍👩' },
    { name: 'family', emoji: '👪' },
    { name: 'family-man-woman-boy', emoji: '👨‍👩‍👦' },
    { name: 'family-man-woman-girl', emoji: '👨‍👩‍👧' },
    { name: 'family-man-woman-girl-boy', emoji: '👨‍👩‍👧‍👦' },
    { name: 'family-man-woman-boy-boy', emoji: '👨‍👩‍👦‍👦' },
    { name: 'family-man-woman-girl-girl', emoji: '👨‍👩‍👧‍👧' },
    { name: 'family-man-man-boy', emoji: '👨‍👨‍👦' },
    { name: 'family-man-man-girl', emoji: '👨‍👨‍👧' },
    { name: 'family-man-man-girl-boy', emoji: '👨‍👨‍👧‍👦' },
    { name: 'family-man-man-boy-boy', emoji: '👨‍👨‍👦‍👦' },
    { name: 'family-man-man-girl-girl', emoji: '👨‍👨‍👧‍👧' },
    { name: 'family-woman-woman-boy', emoji: '👩‍👩‍👦' },
    { name: 'family-woman-woman-girl', emoji: '👩‍👩‍👧' },
    { name: 'family-woman-woman-girl-boy', emoji: '👩‍👩‍👧‍👦' },
    { name: 'family-woman-woman-boy-boy', emoji: '👩‍👩‍👦‍👦' },
    { name: 'family-woman-woman-girl-girl', emoji: '👩‍👩‍👧‍👧' },
    { name: 'family-man-boy', emoji: '👨‍👦' },
    { name: 'family-man-boy-boy', emoji: '👨‍👦‍👦' },
    { name: 'family-man-girl', emoji: '👨‍👧' },
    { name: 'family-man-girl-boy', emoji: '👨‍👧‍👦' },
    { name: 'family-man-girl-girl', emoji: '👨‍👧‍👧' },
    { name: 'family-woman-boy', emoji: '👩‍👦' },
    { name: 'family-woman-boy-boy', emoji: '👩‍👦‍👦' },
    { name: 'family-woman-girl', emoji: '👩‍👧' },
    { name: 'family-woman-girl-boy', emoji: '👩‍👧‍👦' },
    { name: 'family-woman-girl-girl', emoji: '👩‍👧‍👧' },
    { name: 'speaking-head-in-silhouette', emoji: '🗣' },
    { name: 'bust-in-silhouette', emoji: '👤' },
    { name: 'busts-in-silhouette', emoji: '👥' },
    { name: 'footprints', emoji: '👣' },
    { name: 'luggage', emoji: '🧳' },
    { name: 'closed-umbrella', emoji: '🌂' },
    { name: 'umbrella', emoji: '☂' },
    { name: 'spool-of-thread', emoji: '🧵' },
    { name: 'ball-of-yarn', emoji: '🧶' },
    { name: 'eyeglasses', emoji: '👓' },
    { name: 'dark-sunglasses', emoji: '🕶' },
    { name: 'goggles', emoji: '🥽' },
    { name: 'lab-coat', emoji: '🥼' },
    { name: 'necktie', emoji: '👔' },
    { name: 't-shirt', emoji: '👕' },
    { name: 'jeans', emoji: '👖' },
    { name: 'scarf', emoji: '🧣' },
    { name: 'gloves', emoji: '🧤' },
    { name: 'coat', emoji: '🧥' },
    { name: 'socks', emoji: '🧦' },
    { name: 'dress', emoji: '👗' },
    { name: 'kimono', emoji: '👘' },
    { name: 'bikini', emoji: '👙' },
    { name: 'womans-clothes', emoji: '👚' },
    { name: 'purse', emoji: '👛' },
    { name: 'handbag', emoji: '👜' },
    { name: 'pouch', emoji: '👝' },
    { name: 'school-satchel', emoji: '🎒' },
    { name: 'mans-shoe', emoji: '👞' },
    { name: 'athletic-shoe', emoji: '👟' },
    { name: 'hiking-boot', emoji: '🥾' },
    { name: 'flat-shoe', emoji: '🥿' },
    { name: 'high-heeled-shoe', emoji: '👠' },
    { name: 'womans-sandal', emoji: '👡' },
    { name: 'womans-boots', emoji: '👢' },
    { name: 'crown', emoji: '👑' },
    { name: 'womans-hat', emoji: '👒' },
    { name: 'top-hat', emoji: '🎩' },
    { name: 'graduation-cap', emoji: '🎓' },
    { name: 'billed-cap', emoji: '🧢' },
    { name: 'helmet-with-white-cross', emoji: '⛑' },
    { name: 'lipstick', emoji: '💄' },
    { name: 'ring', emoji: '💍' },
    { name: 'briefcase', emoji: '💼' }
  ],

  animals_and_nature: [
    { name: 'see-no-evil-monkey', emoji: '🙈' },
    { name: 'hear-no-evil-monkey', emoji: '🙉' },
    { name: 'speak-no-evil-monkey', emoji: '🙊' },
    { name: 'collision-symbol', emoji: '💥' },
    { name: 'dizzy-symbol', emoji: '💫' },
    { name: 'splashing-sweat-symbol', emoji: '💦' },
    { name: 'dash-symbol', emoji: '💨' },
    { name: 'monkey-face', emoji: '🐵' },
    { name: 'monkey', emoji: '🐒' },
    { name: 'gorilla', emoji: '🦍' },
    { name: 'dog-face', emoji: '🐶' },
    { name: 'dog', emoji: '🐕' },
    { name: 'poodle', emoji: '🐩' },
    { name: 'wolf-face', emoji: '🐺' },
    { name: 'fox-face', emoji: '🦊' },
    { name: 'raccoon', emoji: '🦝' },
    { name: 'cat-face', emoji: '🐱' },
    { name: 'cat', emoji: '🐈' },
    { name: 'lion-face', emoji: '🦁' },
    { name: 'tiger-face', emoji: '🐯' },
    { name: 'tiger', emoji: '🐅' },
    { name: 'leopard', emoji: '🐆' },
    { name: 'horse-face', emoji: '🐴' },
    { name: 'horse', emoji: '🐎' },
    { name: 'unicorn-face', emoji: '🦄' },
    { name: 'zebra-face', emoji: '🦓' },
    { name: 'deer', emoji: '🦌' },
    { name: 'cow-face', emoji: '🐮' },
    { name: 'ox', emoji: '🐂' },
    { name: 'water-buffalo', emoji: '🐃' },
    { name: 'cow', emoji: '🐄' },
    { name: 'pig-face', emoji: '🐷' },
    { name: 'pig', emoji: '🐖' },
    { name: 'boar', emoji: '🐗' },
    { name: 'pig-nose', emoji: '🐽' },
    { name: 'ram', emoji: '🐏' },
    { name: 'sheep', emoji: '🐑' },
    { name: 'goat', emoji: '🐐' },
    { name: 'dromedary-camel', emoji: '🐪' },
    { name: 'bactrian-camel', emoji: '🐫' },
    { name: 'llama', emoji: '🦙' },
    { name: 'giraffe-face', emoji: '🦒' },
    { name: 'elephant', emoji: '🐘' },
    { name: 'rhinoceros', emoji: '🦏' },
    { name: 'hippopotamus', emoji: '🦛' },
    { name: 'mouse-face', emoji: '🐭' },
    { name: 'mouse', emoji: '🐁' },
    { name: 'rat', emoji: '🐀' },
    { name: 'hamster-face', emoji: '🐹' },
    { name: 'rabbit-face', emoji: '🐰' },
    { name: 'rabbit', emoji: '🐇' },
    { name: 'chipmunk', emoji: '🐿' },
    { name: 'hedgehog', emoji: '🦔' },
    { name: 'bat', emoji: '🦇' },
    { name: 'bear-face', emoji: '🐻' },
    { name: 'koala', emoji: '🐨' },
    { name: 'panda-face', emoji: '🐼' },
    { name: 'kangaroo', emoji: '🦘' },
    { name: 'badger', emoji: '🦡' },
    { name: 'paw-prints', emoji: '🐾' },
    { name: 'turkey', emoji: '🦃' },
    { name: 'chicken', emoji: '🐔' },
    { name: 'rooster', emoji: '🐓' },
    { name: 'hatching-chick', emoji: '🐣' },
    { name: 'baby-chick', emoji: '🐤' },
    { name: 'front-facing-baby-chick', emoji: '🐥' },
    { name: 'bird', emoji: '🐦' },
    { name: 'penguin', emoji: '🐧' },
    { name: 'dove-of-peace', emoji: '🕊' },
    { name: 'eagle', emoji: '🦅' },
    { name: 'duck', emoji: '🦆' },
    { name: 'swan', emoji: '🦢' },
    { name: 'owl', emoji: '🦉' },
    { name: 'peacock', emoji: '🦚' },
    { name: 'parrot', emoji: '🦜' },
    { name: 'frog-face', emoji: '🐸' },
    { name: 'crocodile', emoji: '🐊' },
    { name: 'turtle', emoji: '🐢' },
    { name: 'lizard', emoji: '🦎' },
    { name: 'snake', emoji: '🐍' },
    { name: 'dragon-face', emoji: '🐲' },
    { name: 'dragon', emoji: '🐉' },
    { name: 'sauropod', emoji: '🦕' },
    { name: 't-rex', emoji: '🦖' },
    { name: 'spouting-whale', emoji: '🐳' },
    { name: 'whale', emoji: '🐋' },
    { name: 'dolphin', emoji: '🐬' },
    { name: 'fish', emoji: '🐟' },
    { name: 'tropical-fish', emoji: '🐠' },
    { name: 'blowfish', emoji: '🐡' },
    { name: 'shark', emoji: '🦈' },
    { name: 'octopus', emoji: '🐙' },
    { name: 'spiral-shell', emoji: '🐚' },
    { name: 'snail', emoji: '🐌' },
    { name: 'butterfly', emoji: '🦋' },
    { name: 'bug', emoji: '🐛' },
    { name: 'ant', emoji: '🐜' },
    { name: 'honeybee', emoji: '🐝' },
    { name: 'lady-beetle', emoji: '🐞' },
    { name: 'cricket', emoji: '🦗' },
    { name: 'spider', emoji: '🕷' },
    { name: 'spider-web', emoji: '🕸' },
    { name: 'scorpion', emoji: '🦂' },
    { name: 'mosquito', emoji: '🦟' },
    { name: 'microbe', emoji: '🦠' },
    { name: 'bouquet', emoji: '💐' },
    { name: 'cherry-blossom', emoji: '🌸' },
    { name: 'white-flower', emoji: '💮' },
    { name: 'rosette', emoji: '🏵' },
    { name: 'rose', emoji: '🌹' },
    { name: 'wilted-flower', emoji: '🥀' },
    { name: 'hibiscus', emoji: '🌺' },
    { name: 'sunflower', emoji: '🌻' },
    { name: 'blossom', emoji: '🌼' },
    { name: 'tulip', emoji: '🌷' },
    { name: 'seedling', emoji: '🌱' },
    { name: 'evergreen-tree', emoji: '🌲' },
    { name: 'deciduous-tree', emoji: '🌳' },
    { name: 'palm-tree', emoji: '🌴' },
    { name: 'cactus', emoji: '🌵' },
    { name: 'ear-of-rice', emoji: '🌾' },
    { name: 'herb', emoji: '🌿' },
    { name: 'shamrock', emoji: '☘' },
    { name: 'four-leaf-clover', emoji: '🍀' },
    { name: 'maple-leaf', emoji: '🍁' },
    { name: 'fallen-leaf', emoji: '🍂' },
    { name: 'leaf-fluttering-in-wind', emoji: '🍃' },
    { name: 'mushroom', emoji: '🍄' },
    { name: 'chestnut', emoji: '🌰' },
    { name: 'crab', emoji: '🦀' },
    { name: 'lobster', emoji: '🦞' },
    { name: 'shrimp', emoji: '🦐' },
    { name: 'squid', emoji: '🦑' },
    { name: 'earth-globe-europe-africa', emoji: '🌍' },
    { name: 'earth-globe-americas', emoji: '🌎' },
    { name: 'earth-globe-asia-australia', emoji: '🌏' },
    { name: 'globe-with-meridians', emoji: '🌐' },
    { name: 'new-moon-symbol', emoji: '🌑' },
    { name: 'waxing-crescent-moon-symbol', emoji: '🌒' },
    { name: 'first-quarter-moon-symbol', emoji: '🌓' },
    { name: 'waxing-gibbous-moon-symbol', emoji: '🌔' },
    { name: 'full-moon-symbol', emoji: '🌕' },
    { name: 'waning-gibbous-moon-symbol', emoji: '🌖' },
    { name: 'last-quarter-moon-symbol', emoji: '🌗' },
    { name: 'waning-crescent-moon-symbol', emoji: '🌘' },
    { name: 'crescent-moon', emoji: '🌙' },
    { name: 'new-moon-with-face', emoji: '🌚' },
    { name: 'first-quarter-moon-with-face', emoji: '🌛' },
    { name: 'last-quarter-moon-with-face', emoji: '🌜' },
    { name: 'black-sun-with-rays', emoji: '☀' },
    { name: 'full-moon-with-face', emoji: '🌝' },
    { name: 'sun-with-face', emoji: '🌞' },
    { name: 'white-medium-star', emoji: '⭐' },
    { name: 'glowing-star', emoji: '🌟' },
    { name: 'shooting-star', emoji: '🌠' },
    { name: 'cloud', emoji: '☁' },
    { name: 'sun-behind-cloud', emoji: '⛅' },
    { name: 'thunder-cloud-and-rain', emoji: '⛈' },
    { name: 'white-sun-with-small-cloud', emoji: '🌤' },
    { name: 'white-sun-behind-cloud', emoji: '🌥' },
    { name: 'white-sun-behind-cloud-with-rain', emoji: '🌦' },
    { name: 'cloud-with-rain', emoji: '🌧' },
    { name: 'cloud-with-snow', emoji: '🌨' },
    { name: 'cloud-with-lightning', emoji: '🌩' },
    { name: 'cloud-with-tornado', emoji: '🌪' },
    { name: 'fog', emoji: '🌫' },
    { name: 'wind-blowing-face', emoji: '🌬' },
    { name: 'rainbow', emoji: '🌈' },
    { name: 'umbrella', emoji: '☂' },
    { name: 'umbrella-with-rain-drops', emoji: '☔' },
    { name: 'high-voltage-sign', emoji: '⚡' },
    { name: 'snowman-without-snow', emoji: '⛄' },
    { name: 'comet', emoji: '☄' },
    { name: 'fire', emoji: '🔥' },
    { name: 'droplet', emoji: '💧' },
    { name: 'water-wave', emoji: '🌊' },
    { name: 'christmas-tree', emoji: '🎄' },
    { name: 'sparkles', emoji: '✨' },
    { name: 'tanabata-tree', emoji: '🎋' },
    { name: 'pine-decoration', emoji: '🎍' }
  ],

  food_and_drink: [
    { name: 'grapes', emoji: '🍇' },
    { name: 'melon', emoji: '🍈' },
    { name: 'watermelon', emoji: '🍉' },
    { name: 'tangerine', emoji: '🍊' },
    { name: 'lemon', emoji: '🍋' },
    { name: 'banana', emoji: '🍌' },
    { name: 'pineapple', emoji: '🍍' },
    { name: 'mango', emoji: '🥭' },
    { name: 'red-apple', emoji: '🍎' },
    { name: 'green-apple', emoji: '🍏' },
    { name: 'pear', emoji: '🍐' },
    { name: 'peach', emoji: '🍑' },
    { name: 'cherries', emoji: '🍒' },
    { name: 'strawberry', emoji: '🍓' },
    { name: 'kiwifruit', emoji: '🥝' },
    { name: 'tomato', emoji: '🍅' },
    { name: 'coconut', emoji: '🥥' },
    { name: 'avocado', emoji: '🥑' },
    { name: 'aubergine', emoji: '🍆' },
    { name: 'potato', emoji: '🥔' },
    { name: 'carrot', emoji: '🥕' },
    { name: 'ear-of-maize', emoji: '🌽' },
    { name: 'hot-pepper', emoji: '🌶' },
    { name: 'cucumber', emoji: '🥒' },
    { name: 'leafy-green', emoji: '🥬' },
    { name: 'broccoli', emoji: '🥦' },
    { name: 'mushroom', emoji: '🍄' },
    { name: 'peanuts', emoji: '🥜' },
    { name: 'chestnut', emoji: '🌰' },
    { name: 'bread', emoji: '🍞' },
    { name: 'croissant', emoji: '🥐' },
    { name: 'baguette-bread', emoji: '🥖' },
    { name: 'pretzel', emoji: '🥨' },
    { name: 'bagel', emoji: '🥯' },
    { name: 'pancakes', emoji: '🥞' },
    { name: 'cheese-wedge', emoji: '🧀' },
    { name: 'meat-on-bone', emoji: '🍖' },
    { name: 'poultry-leg', emoji: '🍗' },
    { name: 'cut-of-meat', emoji: '🥩' },
    { name: 'bacon', emoji: '🥓' },
    { name: 'hamburger', emoji: '🍔' },
    { name: 'french-fries', emoji: '🍟' },
    { name: 'slice-of-pizza', emoji: '🍕' },
    { name: 'hot-dog', emoji: '🌭' },
    { name: 'sandwich', emoji: '🥪' },
    { name: 'taco', emoji: '🌮' },
    { name: 'burrito', emoji: '🌯' },
    { name: 'stuffed-flatbread', emoji: '🥙' },
    { name: 'cooking', emoji: '🍳' },
    { name: 'shallow-pan-of-food', emoji: '🥘' },
    { name: 'pot-of-food', emoji: '🍲' },
    { name: 'bowl-with-spoon', emoji: '🥣' },
    { name: 'green-salad', emoji: '🥗' },
    { name: 'popcorn', emoji: '🍿' },
    { name: 'salt-shaker', emoji: '🧂' },
    { name: 'canned-food', emoji: '🥫' },
    { name: 'bento-box', emoji: '🍱' },
    { name: 'rice-cracker', emoji: '🍘' },
    { name: 'rice-ball', emoji: '🍙' },
    { name: 'cooked-rice', emoji: '🍚' },
    { name: 'curry-and-rice', emoji: '🍛' },
    { name: 'steaming-bowl', emoji: '🍜' },
    { name: 'spaghetti', emoji: '🍝' },
    { name: 'roasted-sweet-potato', emoji: '🍠' },
    { name: 'oden', emoji: '🍢' },
    { name: 'sushi', emoji: '🍣' },
    { name: 'fried-shrimp', emoji: '🍤' },
    { name: 'fish-cake-with-swirl-design', emoji: '🍥' },
    { name: 'moon-cake', emoji: '🥮' },
    { name: 'dango', emoji: '🍡' },
    { name: 'dumpling', emoji: '🥟' },
    { name: 'fortune-cookie', emoji: '🥠' },
    { name: 'takeout-box', emoji: '🥡' },
    { name: 'soft-ice-cream', emoji: '🍦' },
    { name: 'shaved-ice', emoji: '🍧' },
    { name: 'ice-cream', emoji: '🍨' },
    { name: 'doughnut', emoji: '🍩' },
    { name: 'cookie', emoji: '🍪' },
    { name: 'birthday-cake', emoji: '🎂' },
    { name: 'shortcake', emoji: '🍰' },
    { name: 'cupcake', emoji: '🧁' },
    { name: 'pie', emoji: '🥧' },
    { name: 'chocolate-bar', emoji: '🍫' },
    { name: 'candy', emoji: '🍬' },
    { name: 'lollipop', emoji: '🍭' },
    { name: 'custard', emoji: '🍮' },
    { name: 'honey-pot', emoji: '🍯' },
    { name: 'baby-bottle', emoji: '🍼' },
    { name: 'glass-of-milk', emoji: '🥛' },
    { name: 'hot-beverage', emoji: '☕' },
    { name: 'teacup-without-handle', emoji: '🍵' },
    { name: 'sake-bottle-and-cup', emoji: '🍶' },
    { name: 'bottle-with-popping-cork', emoji: '🍾' },
    { name: 'wine-glass', emoji: '🍷' },
    { name: 'cocktail-glass', emoji: '🍸' },
    { name: 'tropical-drink', emoji: '🍹' },
    { name: 'beer-mug', emoji: '🍺' },
    { name: 'clinking-beer-mugs', emoji: '🍻' },
    { name: 'clinking-glasses', emoji: '🥂' },
    { name: 'tumbler-glass', emoji: '🥃' },
    { name: 'cup-with-straw', emoji: '🥤' },
    { name: 'chopsticks', emoji: '🥢' },
    { name: 'fork-and-knife-with-plate', emoji: '🍽' },
    { name: 'fork-and-knife', emoji: '🍴' },
    { name: 'spoon', emoji: '🥄' }
  ],

  activity: [
    { name: 'man-in-business-suit-levitating', emoji: '🕴' },
    { name: 'person-climbing', emoji: '🧗' },
    { name: 'man-climbing', emoji: '🧗‍♂️' },
    { name: 'woman-climbing', emoji: '🧗‍♀️' },
    { name: 'horse-racing', emoji: '🏇' },
    { name: 'skier', emoji: '⛷' },
    { name: 'snowboarder', emoji: '🏂' },
    { name: 'golfer', emoji: '🏌' },
    { name: 'man-golfing', emoji: '🏌️‍♂️' },
    { name: 'woman-golfing', emoji: '🏌️‍♀️' },
    { name: 'surfer', emoji: '🏄' },
    { name: 'man-surfing', emoji: '🏄‍♂️' },
    { name: 'woman-surfing', emoji: '🏄‍♀️' },
    { name: 'rowboat', emoji: '🚣' },
    { name: 'man-rowing-boat', emoji: '🚣‍♂️' },
    { name: 'woman-rowing-boat', emoji: '🚣‍♀️' },
    { name: 'swimmer', emoji: '🏊' },
    { name: 'man-swimming', emoji: '🏊‍♂️' },
    { name: 'woman-swimming', emoji: '🏊‍♀️' },
    { name: 'person-with-ball', emoji: '⛹' },
    { name: 'man-with-ball', emoji: '⛹️‍♂️' },
    { name: 'woman-with-ball', emoji: '⛹️‍♀️' },
    { name: 'weight-lifter', emoji: '🏋' },
    { name: 'man-weight-lifting', emoji: '🏋️‍♂️' },
    { name: 'woman-weight-lifting', emoji: '🏋️‍♀️' },
    { name: 'bicyclist', emoji: '🚴' },
    { name: 'man-biking', emoji: '🚴‍♂️' },
    { name: 'woman-biking', emoji: '🚴‍♀️' },
    { name: 'mountain-bicyclist', emoji: '🚵' },
    { name: 'man-mountain-biking', emoji: '🚵‍♂️' },
    { name: 'woman-mountain-biking', emoji: '🚵‍♀️' },
    { name: 'person-doing-cartwheel', emoji: '🤸' },
    { name: 'man-doing-cartwheel', emoji: '🤸‍♂️' },
    { name: 'woman-doing-cartwheel', emoji: '🤸‍♀️' },
    { name: 'wrestlers', emoji: '🤼' },
    { name: 'men-wrestling', emoji: '🤼‍♂️' },
    { name: 'women-wrestling', emoji: '🤼‍♀️' },
    { name: 'water-polo', emoji: '🤽' },
    { name: 'man-playing-water-polo', emoji: '🤽‍♂️' },
    { name: 'woman-playing-water-polo', emoji: '🤽‍♀️' },
    { name: 'handball', emoji: '🤾' },
    { name: 'man-playing-handball', emoji: '🤾‍♂️' },
    { name: 'woman-playing-handball', emoji: '🤾‍♀️' },
    { name: 'juggling', emoji: '🤹' },
    { name: 'man-juggling', emoji: '🤹‍♂️' },
    { name: 'woman-juggling', emoji: '🤹‍♀️' },
    { name: 'person-in-lotus-position', emoji: '🧘' },
    { name: 'man-in-lotus-position', emoji: '🧘‍♂️' },
    { name: 'woman-in-lotus-position', emoji: '🧘‍♀️' },
    { name: 'circus-tent', emoji: '🎪' },
    { name: 'skateboard', emoji: '🛹' },
    { name: 'reminder-ribbon', emoji: '🎗' },
    { name: 'admission-tickets', emoji: '🎟' },
    { name: 'ticket', emoji: '🎫' },
    { name: 'military-medal', emoji: '🎖' },
    { name: 'trophy', emoji: '🏆' },
    { name: 'sports-medal', emoji: '🏅' },
    { name: 'first-place-medal', emoji: '🥇' },
    { name: 'second-place-medal', emoji: '🥈' },
    { name: 'third-place-medal', emoji: '🥉' },
    { name: 'soccer-ball', emoji: '⚽' },
    { name: 'baseball', emoji: '⚾' },
    { name: 'softball', emoji: '🥎' },
    { name: 'basketball-and-hoop', emoji: '🏀' },
    { name: 'volleyball', emoji: '🏐' },
    { name: 'american-football', emoji: '🏈' },
    { name: 'rugby-football', emoji: '🏉' },
    { name: 'tennis-racquet-and-ball', emoji: '🎾' },
    { name: 'flying-disc', emoji: '🥏' },
    { name: 'bowling', emoji: '🎳' },
    { name: 'cricket-bat-and-ball', emoji: '🏏' },
    { name: 'field-hockey-stick-and-ball', emoji: '🏑' },
    { name: 'ice-hockey-stick-and-puck', emoji: '🏒' },
    { name: 'lacrosse-stick-and-ball', emoji: '🥍' },
    { name: 'table-tennis-paddle-and-ball', emoji: '🏓' },
    { name: 'badminton-racquet-and-shuttlecock', emoji: '🏸' },
    { name: 'boxing-glove', emoji: '🥊' },
    { name: 'martial-arts-uniform', emoji: '🥋' },
    { name: 'flag-in-hole', emoji: '⛳' },
    { name: 'ice-skate', emoji: '⛸' },
    { name: 'fishing-pole-and-fish', emoji: '🎣' },
    { name: 'running-shirt-with-sash', emoji: '🎽' },
    { name: 'ski-and-ski-boot', emoji: '🎿' },
    { name: 'sled', emoji: '🛷' },
    { name: 'curling-stone', emoji: '🥌' },
    { name: 'direct-hit', emoji: '🎯' },
    { name: 'billiards', emoji: '🎱' },
    { name: 'video-game', emoji: '🎮' },
    { name: 'slot-machine', emoji: '🎰' },
    { name: 'game-die', emoji: '🎲' },
    { name: 'jigsaw-puzzle-piece', emoji: '🧩' },
    { name: 'black-chess-pawn', emoji: '♟' },
    { name: 'performing-arts', emoji: '🎭' },
    { name: 'artist-palette', emoji: '🎨' },
    { name: 'spool-of-thread', emoji: '🧵' },
    { name: 'ball-of-yarn', emoji: '🧶' },
    { name: 'musical-score', emoji: '🎼' },
    { name: 'microphone', emoji: '🎤' },
    { name: 'headphone', emoji: '🎧' },
    { name: 'saxophone', emoji: '🎷' },
    { name: 'guitar', emoji: '🎸' },
    { name: 'musical-keyboard', emoji: '🎹' },
    { name: 'trumpet', emoji: '🎺' },
    { name: 'violin', emoji: '🎻' },
    { name: 'drum-with-drumsticks', emoji: '🥁' },
    { name: 'clapper-board', emoji: '🎬' },
    { name: 'bow-and-arrow', emoji: '🏹' }
  ],

  travel_and_places: [
    { name: 'rowboat', emoji: '🚣' },
    { name: 'silhouette-of-japan', emoji: '🗾' },
    { name: 'snow-capped-mountain', emoji: '🏔' },
    { name: 'mountain', emoji: '⛰' },
    { name: 'volcano', emoji: '🌋' },
    { name: 'mount-fuji', emoji: '🗻' },
    { name: 'camping', emoji: '🏕' },
    { name: 'beach-with-umbrella', emoji: '🏖' },
    { name: 'desert', emoji: '🏜' },
    { name: 'desert-island', emoji: '🏝' },
    { name: 'national-park', emoji: '🏞' },
    { name: 'stadium', emoji: '🏟' },
    { name: 'classical-building', emoji: '🏛' },
    { name: 'building-construction', emoji: '🏗' },
    { name: 'house-buildings', emoji: '🏘' },
    { name: 'derelict-house-building', emoji: '🏚' },
    { name: 'house-building', emoji: '🏠' },
    { name: 'house-with-garden', emoji: '🏡' },
    { name: 'office-building', emoji: '🏢' },
    { name: 'japanese-post-office', emoji: '🏣' },
    { name: 'european-post-office', emoji: '🏤' },
    { name: 'hospital', emoji: '🏥' },
    { name: 'bank', emoji: '🏦' },
    { name: 'hotel', emoji: '🏨' },
    { name: 'love-hotel', emoji: '🏩' },
    { name: 'convenience-store', emoji: '🏪' },
    { name: 'school', emoji: '🏫' },
    { name: 'department-store', emoji: '🏬' },
    { name: 'factory', emoji: '🏭' },
    { name: 'japanese-castle', emoji: '🏯' },
    { name: 'european-castle', emoji: '🏰' },
    { name: 'wedding', emoji: '💒' },
    { name: 'tokyo-tower', emoji: '🗼' },
    { name: 'statue-of-liberty', emoji: '🗽' },
    { name: 'church', emoji: '⛪' },
    { name: 'mosque', emoji: '🕌' },
    { name: 'synagogue', emoji: '🕍' },
    { name: 'shinto-shrine', emoji: '⛩' },
    { name: 'kaaba', emoji: '🕋' },
    { name: 'fountain', emoji: '⛲' },
    { name: 'tent', emoji: '⛺' },
    { name: 'foggy', emoji: '🌁' },
    { name: 'night-with-stars', emoji: '🌃' },
    { name: 'cityscape', emoji: '🏙' },
    { name: 'sunrise-over-mountains', emoji: '🌄' },
    { name: 'sunrise', emoji: '🌅' },
    { name: 'cityscape-at-dusk', emoji: '🌆' },
    { name: 'sunset-over-buildings', emoji: '🌇' },
    { name: 'bridge-at-night', emoji: '🌉' },
    { name: 'carousel-horse', emoji: '🎠' },
    { name: 'ferris-wheel', emoji: '🎡' },
    { name: 'roller-coaster', emoji: '🎢' },
    { name: 'steam-locomotive', emoji: '🚂' },
    { name: 'railway-car', emoji: '🚃' },
    { name: 'high-speed-train', emoji: '🚄' },
    { name: 'high-speed-train-with-bullet-nose', emoji: '🚅' },
    { name: 'train', emoji: '🚆' },
    { name: 'metro', emoji: '🚇' },
    { name: 'light-rail', emoji: '🚈' },
    { name: 'station', emoji: '🚉' },
    { name: 'tram', emoji: '🚊' },
    { name: 'monorail', emoji: '🚝' },
    { name: 'mountain-railway', emoji: '🚞' },
    { name: 'tram-car', emoji: '🚋' },
    { name: 'bus', emoji: '🚌' },
    { name: 'oncoming-bus', emoji: '🚍' },
    { name: 'trolleybus', emoji: '🚎' },
    { name: 'minibus', emoji: '🚐' },
    { name: 'ambulance', emoji: '🚑' },
    { name: 'fire-engine', emoji: '🚒' },
    { name: 'police-car', emoji: '🚓' },
    { name: 'oncoming-police-car', emoji: '🚔' },
    { name: 'taxi', emoji: '🚕' },
    { name: 'oncoming-taxi', emoji: '🚖' },
    { name: 'automobile', emoji: '🚗' },
    { name: 'oncoming-automobile', emoji: '🚘' },
    { name: 'delivery-truck', emoji: '🚚' },
    { name: 'articulated-lorry', emoji: '🚛' },
    { name: 'tractor', emoji: '🚜' },
    { name: 'racing-car', emoji: '🏎' },
    { name: 'racing-motorcycle', emoji: '🏍' },
    { name: 'motor-scooter', emoji: '🛵' },
    { name: 'bicycle', emoji: '🚲' },
    { name: 'scooter', emoji: '🛴' },
    { name: 'bus-stop', emoji: '🚏' },
    { name: 'railway-track', emoji: '🛤' },
    { name: 'fuel-pump', emoji: '⛽' },
    { name: 'police-cars-revolving-light', emoji: '🚨' },
    { name: 'horizontal-traffic-light', emoji: '🚥' },
    { name: 'vertical-traffic-light', emoji: '🚦' },
    { name: 'construction-sign', emoji: '🚧' },
    { name: 'anchor', emoji: '⚓' },
    { name: 'sailboat', emoji: '⛵' },
    { name: 'speedboat', emoji: '🚤' },
    { name: 'passenger-ship', emoji: '🛳' },
    { name: 'ferry', emoji: '⛴' },
    { name: 'motor-boat', emoji: '🛥' },
    { name: 'ship', emoji: '🚢' },
    { name: 'airplane', emoji: '✈' },
    { name: 'small-airplane', emoji: '🛩' },
    { name: 'airplane-departure', emoji: '🛫' },
    { name: 'airplane-arriving', emoji: '🛬' },
    { name: 'seat', emoji: '💺' },
    { name: 'helicopter', emoji: '🚁' },
    { name: 'suspension-railway', emoji: '🚟' },
    { name: 'mountain-cableway', emoji: '🚠' },
    { name: 'aerial-tramway', emoji: '🚡' },
    { name: 'satellite', emoji: '🛰' },
    { name: 'rocket', emoji: '🚀' },
    { name: 'flying-saucer', emoji: '🛸' },
    { name: 'shooting-star', emoji: '🌠' },
    { name: 'milky-way', emoji: '🌌' },
    { name: 'umbrella-on-ground', emoji: '⛱' },
    { name: 'fireworks', emoji: '🎆' },
    { name: 'firework-sparkler', emoji: '🎇' },
    { name: 'moon-viewing-ceremony', emoji: '🎑' },
    { name: 'banknote-with-yen-sign', emoji: '💴' },
    { name: 'banknote-with-dollar-sign', emoji: '💵' },
    { name: 'banknote-with-euro-sign', emoji: '💶' },
    { name: 'banknote-with-pound-sign', emoji: '💷' },
    { name: 'moyai', emoji: '🗿' },
    { name: 'passport-control', emoji: '🛂' },
    { name: 'customs', emoji: '🛃' },
    { name: 'baggage-claim', emoji: '🛄' },
    { name: 'left-luggage', emoji: '🛅' }
  ],

  objects: [
    { name: 'love-letter', emoji: '💌' },
    { name: 'hole', emoji: '🕳' },
    { name: 'bomb', emoji: '💣' },
    { name: 'bath', emoji: '🛀' },
    { name: 'sleeping-accommodation', emoji: '🛌' },
    { name: 'hocho', emoji: '🔪' },
    { name: 'amphora', emoji: '🏺' },
    { name: 'world-map', emoji: '🗺' },
    { name: 'compass', emoji: '🧭' },
    { name: 'brick', emoji: '🧱' },
    { name: 'barber-pole', emoji: '💈' },
    { name: 'oil-drum', emoji: '🛢' },
    { name: 'bellhop-bell', emoji: '🛎' },
    { name: 'luggage', emoji: '🧳' },
    { name: 'hourglass', emoji: '⌛' },
    { name: 'hourglass-with-flowing-sand', emoji: '⏳' },
    { name: 'watch', emoji: '⌚' },
    { name: 'alarm-clock', emoji: '⏰' },
    { name: 'stopwatch', emoji: '⏱' },
    { name: 'timer-clock', emoji: '⏲' },
    { name: 'mantelpiece-clock', emoji: '🕰' },
    { name: 'thermometer', emoji: '🌡' },
    { name: 'umbrella-on-ground', emoji: '⛱' },
    { name: 'firecracker', emoji: '🧨' },
    { name: 'balloon', emoji: '🎈' },
    { name: 'party-popper', emoji: '🎉' },
    { name: 'confetti-ball', emoji: '🎊' },
    { name: 'japanese-dolls', emoji: '🎎' },
    { name: 'carp-streamer', emoji: '🎏' },
    { name: 'wind-chime', emoji: '🎐' },
    { name: 'red-gift-envelope', emoji: '🧧' },
    { name: 'ribbon', emoji: '🎀' },
    { name: 'wrapped-present', emoji: '🎁' },
    { name: 'crystal-ball', emoji: '🔮' },
    { name: 'nazar-amulet', emoji: '🧿' },
    { name: 'joystick', emoji: '🕹' },
    { name: 'teddy-bear', emoji: '🧸' },
    { name: 'frame-with-picture', emoji: '🖼' },
    { name: 'spool-of-thread', emoji: '🧵' },
    { name: 'ball-of-yarn', emoji: '🧶' },
    { name: 'shopping-bags', emoji: '🛍' },
    { name: 'prayer-beads', emoji: '📿' },
    { name: 'gem-stone', emoji: '💎' },
    { name: 'postal-horn', emoji: '📯' },
    { name: 'studio-microphone', emoji: '🎙' },
    { name: 'level-slider', emoji: '🎚' },
    { name: 'control-knobs', emoji: '🎛' },
    { name: 'radio', emoji: '📻' },
    { name: 'mobile-phone', emoji: '📱' },
    { name: 'mobile-phone-with-rightwards-arrow-at-left', emoji: '📲' },
    { name: 'black-telephone', emoji: '☎' },
    { name: 'telephone-receiver', emoji: '📞' },
    { name: 'pager', emoji: '📟' },
    { name: 'fax-machine', emoji: '📠' },
    { name: 'battery', emoji: '🔋' },
    { name: 'electric-plug', emoji: '🔌' },
    { name: 'personal-computer', emoji: '💻' },
    { name: 'desktop-computer', emoji: '🖥' },
    { name: 'printer', emoji: '🖨' },
    { name: 'keyboard', emoji: '⌨' },
    { name: 'three-button-mouse', emoji: '🖱' },
    { name: 'trackball', emoji: '🖲' },
    { name: 'minidisc', emoji: '💽' },
    { name: 'floppy-disk', emoji: '💾' },
    { name: 'optical-disc', emoji: '💿' },
    { name: 'dvd', emoji: '📀' },
    { name: 'abacus', emoji: '🧮' },
    { name: 'movie-camera', emoji: '🎥' },
    { name: 'film-frames', emoji: '🎞' },
    { name: 'film-projector', emoji: '📽' },
    { name: 'television', emoji: '📺' },
    { name: 'camera', emoji: '📷' },
    { name: 'camera-with-flash', emoji: '📸' },
    { name: 'video-camera', emoji: '📹' },
    { name: 'videocassette', emoji: '📼' },
    { name: 'left-pointing-magnifying-glass', emoji: '🔍' },
    { name: 'right-pointing-magnifying-glass', emoji: '🔎' },
    { name: 'candle', emoji: '🕯' },
    { name: 'electric-light-bulb', emoji: '💡' },
    { name: 'electric-torch', emoji: '🔦' },
    { name: 'izakaya-lantern', emoji: '🏮' },
    { name: 'notebook-with-decorative-cover', emoji: '📔' },
    { name: 'closed-book', emoji: '📕' },
    { name: 'open-book', emoji: '📖' },
    { name: 'green-book', emoji: '📗' },
    { name: 'blue-book', emoji: '📘' },
    { name: 'orange-book', emoji: '📙' },
    { name: 'books', emoji: '📚' },
    { name: 'notebook', emoji: '📓' },
    { name: 'page-with-curl', emoji: '📃' },
    { name: 'scroll', emoji: '📜' },
    { name: 'page-facing-up', emoji: '📄' },
    { name: 'newspaper', emoji: '📰' },
    { name: 'rolled-up-newspaper', emoji: '🗞' },
    { name: 'bookmark-tabs', emoji: '📑' },
    { name: 'bookmark', emoji: '🔖' },
    { name: 'label', emoji: '🏷' },
    { name: 'money-bag', emoji: '💰' },
    { name: 'banknote-with-yen-sign', emoji: '💴' },
    { name: 'banknote-with-dollar-sign', emoji: '💵' },
    { name: 'banknote-with-euro-sign', emoji: '💶' },
    { name: 'banknote-with-pound-sign', emoji: '💷' },
    { name: 'money-with-wings', emoji: '💸' },
    { name: 'credit-card', emoji: '💳' },
    { name: 'receipt', emoji: '🧾' },
    { name: 'envelope', emoji: '✉' },
    { name: 'e-mail-symbol', emoji: '📧' },
    { name: 'incoming-envelope', emoji: '📨' },
    { name: 'envelope-with-downwards-arrow-above', emoji: '📩' },
    { name: 'outbox-tray', emoji: '📤' },
    { name: 'inbox-tray', emoji: '📥' },
    { name: 'package', emoji: '📦' },
    { name: 'closed-mailbox-with-raised-flag', emoji: '📫' },
    { name: 'closed-mailbox-with-lowered-flag', emoji: '📪' },
    { name: 'open-mailbox-with-raised-flag', emoji: '📬' },
    { name: 'open-mailbox-with-lowered-flag', emoji: '📭' },
    { name: 'postbox', emoji: '📮' },
    { name: 'ballot-box-with-ballot', emoji: '🗳' },
    { name: 'pencil', emoji: '✏' },
    { name: 'black-nib', emoji: '✒' },
    { name: 'lower-left-fountain-pen', emoji: '🖋' },
    { name: 'lower-left-ballpoint-pen', emoji: '🖊' },
    { name: 'lower-left-paintbrush', emoji: '🖌' },
    { name: 'lower-left-crayon', emoji: '🖍' },
    { name: 'memo', emoji: '📝' },
    { name: 'file-folder', emoji: '📁' },
    { name: 'open-file-folder', emoji: '📂' },
    { name: 'card-index-dividers', emoji: '🗂' },
    { name: 'calendar', emoji: '📅' },
    { name: 'tear-off-calendar', emoji: '📆' },
    { name: 'spiral-note-pad', emoji: '🗒' },
    { name: 'spiral-calendar-pad', emoji: '🗓' },
    { name: 'card-index', emoji: '📇' },
    { name: 'chart-with-upwards-trend', emoji: '📈' },
    { name: 'chart-with-downwards-trend', emoji: '📉' },
    { name: 'bar-chart', emoji: '📊' },
    { name: 'clipboard', emoji: '📋' },
    { name: 'pushpin', emoji: '📌' },
    { name: 'round-pushpin', emoji: '📍' },
    { name: 'paperclip', emoji: '📎' },
    { name: 'linked-paperclips', emoji: '🖇' },
    { name: 'straight-ruler', emoji: '📏' },
    { name: 'triangular-ruler', emoji: '📐' },
    { name: 'black-scissors', emoji: '✂' },
    { name: 'card-file-box', emoji: '🗃' },
    { name: 'file-cabinet', emoji: '🗄' },
    { name: 'wastebasket', emoji: '🗑' },
    { name: 'lock', emoji: '🔒' },
    { name: 'open-lock', emoji: '🔓' },
    { name: 'lock-with-ink-pen', emoji: '🔏' },
    { name: 'closed-lock-with-key', emoji: '🔐' },
    { name: 'key', emoji: '🔑' },
    { name: 'old-key', emoji: '🗝' },
    { name: 'hammer', emoji: '🔨' },
    { name: 'pick', emoji: '⛏' },
    { name: 'hammer-and-pick', emoji: '⚒' },
    { name: 'hammer-and-wrench', emoji: '🛠' },
    { name: 'dagger-knife', emoji: '🗡' },
    { name: 'crossed-swords', emoji: '⚔' },
    { name: 'pistol', emoji: '🔫' },
    { name: 'shield', emoji: '🛡' },
    { name: 'wrench', emoji: '🔧' },
    { name: 'nut-and-bolt', emoji: '🔩' },
    { name: 'gear', emoji: '⚙' },
    { name: 'compression', emoji: '🗜' },
    { name: 'scales', emoji: '⚖' },
    { name: 'link-symbol', emoji: '🔗' },
    { name: 'chains', emoji: '⛓' },
    { name: 'toolbox', emoji: '🧰' },
    { name: 'magnet', emoji: '🧲' },
    { name: 'alembic', emoji: '⚗' },
    { name: 'test-tube', emoji: '🧪' },
    { name: 'petri-dish', emoji: '🧫' },
    { name: 'dna-double-helix', emoji: '🧬' },
    { name: 'microscope', emoji: '🔬' },
    { name: 'telescope', emoji: '🔭' },
    { name: 'satellite-antenna', emoji: '📡' },
    { name: 'syringe', emoji: '💉' },
    { name: 'pill', emoji: '💊' },
    { name: 'door', emoji: '🚪' },
    { name: 'bed', emoji: '🛏' },
    { name: 'couch-and-lamp', emoji: '🛋' },
    { name: 'toilet', emoji: '🚽' },
    { name: 'shower', emoji: '🚿' },
    { name: 'bathtub', emoji: '🛁' },
    { name: 'lotion-bottle', emoji: '🧴' },
    { name: 'safety-pin', emoji: '🧷' },
    { name: 'broom', emoji: '🧹' },
    { name: 'basket', emoji: '🧺' },
    { name: 'roll-of-paper', emoji: '🧻' },
    { name: 'bar-of-soap', emoji: '🧼' },
    { name: 'sponge', emoji: '🧽' },
    { name: 'fire-extinguisher', emoji: '🧯' },
    { name: 'smoking-symbol', emoji: '🚬' },
    { name: 'coffin', emoji: '⚰' },
    { name: 'funeral-urn', emoji: '⚱' },
    { name: 'moyai', emoji: '🗿' }
  ],

  symbols: [
    { name: 'heart-with-ribbon', emoji: '💝' },
    { name: 'sparkling-heart', emoji: '💖' },
    { name: 'growing-heart', emoji: '💗' },
    { name: 'beating-heart', emoji: '💓' },
    { name: 'revolving-hearts', emoji: '💞' },
    { name: 'two-hearts', emoji: '💕' },
    { name: 'heart-decoration', emoji: '💟' },
    { name: 'heavy-heart-exclamation-mark-ornament', emoji: '❣' },
    { name: 'broken-heart', emoji: '💔' },
    { name: 'heavy-black-heart', emoji: '❤' },
    { name: 'orange-heart', emoji: '🧡' },
    { name: 'yellow-heart', emoji: '💛' },
    { name: 'green-heart', emoji: '💚' },
    { name: 'blue-heart', emoji: '💙' },
    { name: 'purple-heart', emoji: '💜' },
    { name: 'black-heart', emoji: '🖤' },
    { name: 'hundred-points-symbol', emoji: '💯' },
    { name: 'anger-symbol', emoji: '💢' },
    { name: 'speech-balloon', emoji: '💬' },
    { name: 'eye-in-speech-bubble', emoji: '👁️‍🗨️' },
    { name: 'right-anger-bubble', emoji: '🗯' },
    { name: 'thought-balloon', emoji: '💭' },
    { name: 'sleeping-symbol', emoji: '💤' },
    { name: 'white-flower', emoji: '💮' },
    { name: 'hot-springs', emoji: '♨' },
    { name: 'barber-pole', emoji: '💈' },
    { name: 'octagonal-sign', emoji: '🛑' },
    { name: 'clock-face-twelve-oclock', emoji: '🕛' },
    { name: 'clock-face-twelve-thirty', emoji: '🕧' },
    { name: 'clock-face-one-oclock', emoji: '🕐' },
    { name: 'clock-face-one-thirty', emoji: '🕜' },
    { name: 'clock-face-two-oclock', emoji: '🕑' },
    { name: 'clock-face-two-thirty', emoji: '🕝' },
    { name: 'clock-face-three-oclock', emoji: '🕒' },
    { name: 'clock-face-three-thirty', emoji: '🕞' },
    { name: 'clock-face-four-oclock', emoji: '🕓' },
    { name: 'clock-face-four-thirty', emoji: '🕟' },
    { name: 'clock-face-five-oclock', emoji: '🕔' },
    { name: 'clock-face-five-thirty', emoji: '🕠' },
    { name: 'clock-face-six-oclock', emoji: '🕕' },
    { name: 'clock-face-six-thirty', emoji: '🕡' },
    { name: 'clock-face-seven-oclock', emoji: '🕖' },
    { name: 'clock-face-seven-thirty', emoji: '🕢' },
    { name: 'clock-face-eight-oclock', emoji: '🕗' },
    { name: 'clock-face-eight-thirty', emoji: '🕣' },
    { name: 'clock-face-nine-oclock', emoji: '🕘' },
    { name: 'clock-face-nine-thirty', emoji: '🕤' },
    { name: 'clock-face-ten-oclock', emoji: '🕙' },
    { name: 'clock-face-ten-thirty', emoji: '🕥' },
    { name: 'clock-face-eleven-oclock', emoji: '🕚' },
    { name: 'clock-face-eleven-thirty', emoji: '🕦' },
    { name: 'cyclone', emoji: '🌀' },
    { name: 'black-spade-suit', emoji: '♠' },
    { name: 'black-heart-suit', emoji: '♥' },
    { name: 'black-diamond-suit', emoji: '♦' },
    { name: 'black-club-suit', emoji: '♣' },
    { name: 'playing-card-black-joker', emoji: '🃏' },
    { name: 'mahjong-tile-red-dragon', emoji: '🀄' },
    { name: 'flower-playing-cards', emoji: '🎴' },
    { name: 'speaker-with-cancellation-stroke', emoji: '🔇' },
    { name: 'speaker', emoji: '🔈' },
    { name: 'speaker-with-one-sound-wave', emoji: '🔉' },
    { name: 'speaker-with-three-sound-waves', emoji: '🔊' },
    { name: 'public-address-loudspeaker', emoji: '📢' },
    { name: 'cheering-megaphone', emoji: '📣' },
    { name: 'postal-horn', emoji: '📯' },
    { name: 'bell', emoji: '🔔' },
    { name: 'bell-with-cancellation-stroke', emoji: '🔕' },
    { name: 'musical-note', emoji: '🎵' },
    { name: 'multiple-musical-notes', emoji: '🎶' },
    { name: 'automated-teller-machine', emoji: '🏧' },
    { name: 'put-litter-in-its-place-symbol', emoji: '🚮' },
    { name: 'potable-water-symbol', emoji: '🚰' },
    { name: 'wheelchair-symbol', emoji: '♿' },
    { name: 'mens-symbol', emoji: '🚹' },
    { name: 'womens-symbol', emoji: '🚺' },
    { name: 'restroom', emoji: '🚻' },
    { name: 'baby-symbol', emoji: '🚼' },
    { name: 'water-closet', emoji: '🚾' },
    { name: 'warning-sign', emoji: '⚠' },
    { name: 'children-crossing', emoji: '🚸' },
    { name: 'no-entry', emoji: '⛔' },
    { name: 'no-entry-sign', emoji: '🚫' },
    { name: 'no-bicycles', emoji: '🚳' },
    { name: 'no-smoking-symbol', emoji: '🚭' },
    { name: 'do-not-litter-symbol', emoji: '🚯' },
    { name: 'non-potable-water-symbol', emoji: '🚱' },
    { name: 'no-pedestrians', emoji: '🚷' },
    { name: 'no-one-under-eighteen-symbol', emoji: '🔞' },
    { name: 'radioactive-sign', emoji: '☢' },
    { name: 'biohazard-sign', emoji: '☣' },
    { name: 'upwards-black-arrow', emoji: '⬆' },
    { name: 'north-east-arrow', emoji: '↗' },
    { name: 'black-rightwards-arrow', emoji: '➡' },
    { name: 'south-east-arrow', emoji: '↘' },
    { name: 'downwards-black-arrow', emoji: '⬇' },
    { name: 'south-west-arrow', emoji: '↙' },
    { name: 'leftwards-black-arrow', emoji: '⬅' },
    { name: 'north-west-arrow', emoji: '↖' },
    { name: 'up-down-arrow', emoji: '↕' },
    { name: 'left-right-arrow', emoji: '↔' },
    { name: 'leftwards-arrow-with-hook', emoji: '↩' },
    { name: 'rightwards-arrow-with-hook', emoji: '↪' },
    { name: 'arrow-pointing-rightwards-then-curving-upwards', emoji: '⤴' },
    { name: 'arrow-pointing-rightwards-then-curving-downwards', emoji: '⤵' },
    { name: 'clockwise-downwards-and-upwards-open-circle-arrows', emoji: '🔃' },
    { name: 'anticlockwise-downwards-and-upwards-open-circle-arrows', emoji: '🔄' },
    { name: 'back-with-leftwards-arrow-above', emoji: '🔙' },
    { name: 'end-with-leftwards-arrow-above', emoji: '🔚' },
    { name: 'on-with-exclamation-mark-with-left-right-arrow-above', emoji: '🔛' },
    { name: 'soon-with-rightwards-arrow-above', emoji: '🔜' },
    { name: 'top-with-upwards-arrow-above', emoji: '🔝' },
    { name: 'place-of-worship', emoji: '🛐' },
    { name: 'atom-symbol', emoji: '⚛' },
    { name: 'om-symbol', emoji: '🕉' },
    { name: 'star-of-david', emoji: '✡' },
    { name: 'wheel-of-dharma', emoji: '☸' },
    { name: 'yin-yang', emoji: '☯' },
    { name: 'latin-cross', emoji: '✝' },
    { name: 'orthodox-cross', emoji: '☦' },
    { name: 'star-and-crescent', emoji: '☪' },
    { name: 'peace-symbol', emoji: '☮' },
    { name: 'menorah-with-nine-branches', emoji: '🕎' },
    { name: 'six-pointed-star-with-middle-dot', emoji: '🔯' },
    { name: 'aries', emoji: '♈' },
    { name: 'taurus', emoji: '♉' },
    { name: 'gemini', emoji: '♊' },
    { name: 'cancer', emoji: '♋' },
    { name: 'leo', emoji: '♌' },
    { name: 'virgo', emoji: '♍' },
    { name: 'libra', emoji: '♎' },
    { name: 'scorpius', emoji: '♏' },
    { name: 'sagittarius', emoji: '♐' },
    { name: 'capricorn', emoji: '♑' },
    { name: 'aquarius', emoji: '♒' },
    { name: 'pisces', emoji: '♓' },
    { name: 'ophiuchus', emoji: '⛎' },
    { name: 'twisted-rightwards-arrows', emoji: '🔀' },
    { name: 'clockwise-rightwards-and-leftwards-open-circle-arrows', emoji: '🔁' },
    { name: 'clockwise-rightwards-and-leftwards-open-circle-arrows-with-circled-one-overlay', emoji: '🔂' },
    { name: 'black-right-pointing-triangle', emoji: '▶' },
    { name: 'black-right-pointing-double-triangle', emoji: '⏩' },
    { name: 'black-left-pointing-triangle', emoji: '◀' },
    { name: 'black-left-pointing-double-triangle', emoji: '⏪' },
    { name: 'up-pointing-small-red-triangle', emoji: '🔼' },
    { name: 'black-up-pointing-double-triangle', emoji: '⏫' },
    { name: 'down-pointing-small-red-triangle', emoji: '🔽' },
    { name: 'black-down-pointing-double-triangle', emoji: '⏬' },
    { name: 'black-square-for-stop', emoji: '⏹' },
    { name: 'eject-symbol', emoji: '⏏' },
    { name: 'cinema', emoji: '🎦' },
    { name: 'low-brightness-symbol', emoji: '🔅' },
    { name: 'high-brightness-symbol', emoji: '🔆' },
    { name: 'antenna-with-bars', emoji: '📶' },
    { name: 'vibration-mode', emoji: '📳' },
    { name: 'mobile-phone-off', emoji: '📴' },
    { name: 'permanent-paper-sign', emoji: '♾' },
    { name: 'black-universal-recycling-symbol', emoji: '♻' },
    { name: 'trident-emblem', emoji: '🔱' },
    { name: 'name-badge', emoji: '📛' },
    { name: 'japanese-symbol-for-beginner', emoji: '🔰' },
    { name: 'heavy-large-circle', emoji: '⭕' },
    { name: 'white-heavy-check-mark', emoji: '✅' },
    { name: 'ballot-box-with-check', emoji: '☑' },
    { name: 'heavy-check-mark', emoji: '✔' },
    { name: 'heavy-multiplication-x', emoji: '✖' },
    { name: 'cross-mark', emoji: '❌' },
    { name: 'negative-squared-cross-mark', emoji: '❎' },
    { name: 'heavy-plus-sign', emoji: '➕' },
    { name: 'heavy-minus-sign', emoji: '➖' },
    { name: 'heavy-division-sign', emoji: '➗' },
    { name: 'curly-loop', emoji: '➰' },
    { name: 'double-curly-loop', emoji: '➿' },
    { name: 'part-alternation-mark', emoji: '〽' },
    { name: 'eight-spoked-asterisk', emoji: '✳' },
    { name: 'eight-pointed-black-star', emoji: '✴' },
    { name: 'sparkle', emoji: '❇' },
    { name: 'double-exclamation-mark', emoji: '‼' },
    { name: 'exclamation-question-mark', emoji: '⁉' },
    { name: 'black-question-mark-ornament', emoji: '❓' },
    { name: 'white-question-mark-ornament', emoji: '❔' },
    { name: 'white-exclamation-mark-ornament', emoji: '❕' },
    { name: 'heavy-exclamation-mark-symbol', emoji: '❗' },
    { name: 'copyright-sign', emoji: '©' },
    { name: 'registered-sign', emoji: '®' },
    { name: 'trade-mark-sign', emoji: '™' },
    { name: 'keycap-number-sign', emoji: '#️⃣' },
    { name: 'keycap-digit-zero', emoji: '0️⃣' },
    { name: 'keycap-digit-one', emoji: '1️⃣' },
    { name: 'keycap-digit-two', emoji: '2️⃣' },
    { name: 'keycap-digit-three', emoji: '3️⃣' },
    { name: 'keycap-digit-four', emoji: '4️⃣' },
    { name: 'keycap-digit-five', emoji: '5️⃣' },
    { name: 'keycap-digit-six', emoji: '6️⃣' },
    { name: 'keycap-digit-seven', emoji: '7️⃣' },
    { name: 'keycap-digit-eight', emoji: '8️⃣' },
    { name: 'keycap-digit-nine', emoji: '9️⃣' },
    { name: 'keycap-ten', emoji: '🔟' },
    { name: 'input-symbol-for-latin-capital-letters', emoji: '🔠' },
    { name: 'input-symbol-for-latin-small-letters', emoji: '🔡' },
    { name: 'input-symbol-for-numbers', emoji: '🔢' },
    { name: 'input-symbol-for-symbols', emoji: '🔣' },
    { name: 'input-symbol-for-latin-letters', emoji: '🔤' },
    { name: 'negative-squared-latin-capital-letter-a', emoji: '🅰' },
    { name: 'negative-squared-ab', emoji: '🆎' },
    { name: 'negative-squared-latin-capital-letter-b', emoji: '🅱' },
    { name: 'squared-cl', emoji: '🆑' },
    { name: 'squared-cool', emoji: '🆒' },
    { name: 'squared-free', emoji: '🆓' },
    { name: 'information-source', emoji: 'ℹ' },
    { name: 'squared-id', emoji: '🆔' },
    { name: 'circled-latin-capital-letter-m', emoji: 'Ⓜ' },
    { name: 'squared-new', emoji: '🆕' },
    { name: 'squared-ng', emoji: '🆖' },
    { name: 'negative-squared-latin-capital-letter-o', emoji: '🅾' },
    { name: 'squared-ok', emoji: '🆗' },
    { name: 'negative-squared-latin-capital-letter-p', emoji: '🅿' },
    { name: 'squared-sos', emoji: '🆘' },
    { name: 'squared-up-with-exclamation-mark', emoji: '🆙' },
    { name: 'squared-vs', emoji: '🆚' },
    { name: 'squared-katakana-koko', emoji: '🈁' },
    { name: 'squared-katakana-sa', emoji: '🈂' },
    { name: 'squared-cjk-unified-ideograph-6708', emoji: '🈷' },
    { name: 'squared-cjk-unified-ideograph-6709', emoji: '🈶' },
    { name: 'squared-cjk-unified-ideograph-6307', emoji: '🈯' },
    { name: 'circled-ideograph-advantage', emoji: '🉐' },
    { name: 'squared-cjk-unified-ideograph-5272', emoji: '🈹' },
    { name: 'squared-cjk-unified-ideograph-7121', emoji: '🈚' },
    { name: 'squared-cjk-unified-ideograph-7981', emoji: '🈲' },
    { name: 'circled-ideograph-accept', emoji: '🉑' },
    { name: 'squared-cjk-unified-ideograph-7533', emoji: '🈸' },
    { name: 'squared-cjk-unified-ideograph-5408', emoji: '🈴' },
    { name: 'squared-cjk-unified-ideograph-7a7a', emoji: '🈳' },
    { name: 'circled-ideograph-congratulation', emoji: '㊗' },
    { name: 'circled-ideograph-secret', emoji: '㊙' },
    { name: 'squared-cjk-unified-ideograph-55b6', emoji: '🈺' },
    { name: 'squared-cjk-unified-ideograph-6e80', emoji: '🈵' },
    { name: 'large-red-circle', emoji: '🔴' },
    { name: 'large-blue-circle', emoji: '🔵' },
    { name: 'medium-black-circle', emoji: '⚫' },
    { name: 'medium-white-circle', emoji: '⚪' },
    { name: 'black-large-square', emoji: '⬛' },
    { name: 'white-large-square', emoji: '⬜' },
    { name: 'black-medium-square', emoji: '◼' },
    { name: 'white-medium-square', emoji: '◻' },
    { name: 'black-medium-small-square', emoji: '◾' },
    { name: 'white-medium-small-square', emoji: '◽' },
    { name: 'black-small-square', emoji: '▪' },
    { name: 'white-small-square', emoji: '▫' },
    { name: 'large-orange-diamond', emoji: '🔶' },
    { name: 'large-blue-diamond', emoji: '🔷' },
    { name: 'small-orange-diamond', emoji: '🔸' },
    { name: 'small-blue-diamond', emoji: '🔹' },
    { name: 'up-pointing-red-triangle', emoji: '🔺' },
    { name: 'down-pointing-red-triangle', emoji: '🔻' },
    { name: 'diamond-shape-with-a-dot-inside', emoji: '💠' },
    { name: 'white-square-button', emoji: '🔳' },
    { name: 'black-square-button', emoji: '🔲' }
  ],

  flags: [
    { name: 'chequered-flag', emoji: '🏁' },
    { name: 'triangular-flag-on-post', emoji: '🚩' },
    { name: 'crossed-flags', emoji: '🎌' },
    { name: 'waving-black-flag', emoji: '🏴' },
    { name: 'waving-white-flag', emoji: '🏳' },
    { name: 'rainbow-flag', emoji: '🏳️‍🌈' },
    { name: 'pirate-flag', emoji: '🏴‍☠️' },
    { name: 'flag-for-ascension-island', emoji: '🇦🇨' },
    { name: 'flag-for-andorra', emoji: '🇦🇩' },
    { name: 'flag-for-united-arab-emirates', emoji: '🇦🇪' },
    { name: 'flag-for-afghanistan', emoji: '🇦🇫' },
    { name: 'flag-for-antigua-barbuda', emoji: '🇦🇬' },
    { name: 'flag-for-anguilla', emoji: '🇦🇮' },
    { name: 'flag-for-albania', emoji: '🇦🇱' },
    { name: 'flag-for-armenia', emoji: '🇦🇲' },
    { name: 'flag-for-angola', emoji: '🇦🇴' },
    { name: 'flag-for-antarctica', emoji: '🇦🇶' },
    { name: 'flag-for-argentina', emoji: '🇦🇷' },
    { name: 'flag-for-american-samoa', emoji: '🇦🇸' },
    { name: 'flag-for-austria', emoji: '🇦🇹' },
    { name: 'flag-for-australia', emoji: '🇦🇺' },
    { name: 'flag-for-aruba', emoji: '🇦🇼' },
    { name: 'flag-for-aland-islands', emoji: '🇦🇽' },
    { name: 'flag-for-azerbaijan', emoji: '🇦🇿' },
    { name: 'flag-for-bosnia-herzegovina', emoji: '🇧🇦' },
    { name: 'flag-for-barbados', emoji: '🇧🇧' },
    { name: 'flag-for-bangladesh', emoji: '🇧🇩' },
    { name: 'flag-for-belgium', emoji: '🇧🇪' },
    { name: 'flag-for-burkina-faso', emoji: '🇧🇫' },
    { name: 'flag-for-bulgaria', emoji: '🇧🇬' },
    { name: 'flag-for-bahrain', emoji: '🇧🇭' },
    { name: 'flag-for-burundi', emoji: '🇧🇮' },
    { name: 'flag-for-benin', emoji: '🇧🇯' },
    { name: 'flag-for-st-barthelemy', emoji: '🇧🇱' },
    { name: 'flag-for-bermuda', emoji: '🇧🇲' },
    { name: 'flag-for-brunei', emoji: '🇧🇳' },
    { name: 'flag-for-bolivia', emoji: '🇧🇴' },
    { name: 'flag-for-caribbean-netherlands', emoji: '🇧🇶' },
    { name: 'flag-for-brazil', emoji: '🇧🇷' },
    { name: 'flag-for-bahamas', emoji: '🇧🇸' },
    { name: 'flag-for-bhutan', emoji: '🇧🇹' },
    { name: 'flag-for-bouvet-island', emoji: '🇧🇻' },
    { name: 'flag-for-botswana', emoji: '🇧🇼' },
    { name: 'flag-for-belarus', emoji: '🇧🇾' },
    { name: 'flag-for-belize', emoji: '🇧🇿' },
    { name: 'flag-for-canada', emoji: '🇨🇦' },
    { name: 'flag-for-cocos-islands', emoji: '🇨🇨' },
    { name: 'flag-for-congo-kinshasa', emoji: '🇨🇩' },
    { name: 'flag-for-central-african-republic', emoji: '🇨🇫' },
    { name: 'flag-for-congo-brazzaville', emoji: '🇨🇬' },
    { name: 'flag-for-switzerland', emoji: '🇨🇭' },
    { name: 'flag-for-cote-divoire', emoji: '🇨🇮' },
    { name: 'flag-for-cook-islands', emoji: '🇨🇰' },
    { name: 'flag-for-chile', emoji: '🇨🇱' },
    { name: 'flag-for-cameroon', emoji: '🇨🇲' },
    { name: 'flag-for-china', emoji: '🇨🇳' },
    { name: 'flag-for-colombia', emoji: '🇨🇴' },
    { name: 'flag-for-clipperton-island', emoji: '🇨🇵' },
    { name: 'flag-for-costa-rica', emoji: '🇨🇷' },
    { name: 'flag-for-cuba', emoji: '🇨🇺' },
    { name: 'flag-for-cape-verde', emoji: '🇨🇻' },
    { name: 'flag-for-curacao', emoji: '🇨🇼' },
    { name: 'flag-for-christmas-island', emoji: '🇨🇽' },
    { name: 'flag-for-cyprus', emoji: '🇨🇾' },
    { name: 'flag-for-czech-republic', emoji: '🇨🇿' },
    { name: 'flag-for-germany', emoji: '🇩🇪' },
    { name: 'flag-for-diego-garcia', emoji: '🇩🇬' },
    { name: 'flag-for-djibouti', emoji: '🇩🇯' },
    { name: 'flag-for-denmark', emoji: '🇩🇰' },
    { name: 'flag-for-dominica', emoji: '🇩🇲' },
    { name: 'flag-for-dominican-republic', emoji: '🇩🇴' },
    { name: 'flag-for-algeria', emoji: '🇩🇿' },
    { name: 'flag-for-ceuta-melilla', emoji: '🇪🇦' },
    { name: 'flag-for-ecuador', emoji: '🇪🇨' },
    { name: 'flag-for-estonia', emoji: '🇪🇪' },
    { name: 'flag-for-egypt', emoji: '🇪🇬' },
    { name: 'flag-for-western-sahara', emoji: '🇪🇭' },
    { name: 'flag-for-eritrea', emoji: '🇪🇷' },
    { name: 'flag-for-spain', emoji: '🇪🇸' },
    { name: 'flag-for-ethiopia', emoji: '🇪🇹' },
    { name: 'flag-for-european-union', emoji: '🇪🇺' },
    { name: 'flag-for-finland', emoji: '🇫🇮' },
    { name: 'flag-for-fiji', emoji: '🇫🇯' },
    { name: 'flag-for-falkland-islands', emoji: '🇫🇰' },
    { name: 'flag-for-micronesia', emoji: '🇫🇲' },
    { name: 'flag-for-faroe-islands', emoji: '🇫🇴' },
    { name: 'flag-for-france', emoji: '🇫🇷' },
    { name: 'flag-for-gabon', emoji: '🇬🇦' },
    { name: 'flag-for-united-kingdom', emoji: '🇬🇧' },
    { name: 'flag-for-grenada', emoji: '🇬🇩' },
    { name: 'flag-for-georgia', emoji: '🇬🇪' },
    { name: 'flag-for-french-guiana', emoji: '🇬🇫' },
    { name: 'flag-for-guernsey', emoji: '🇬🇬' },
    { name: 'flag-for-ghana', emoji: '🇬🇭' },
    { name: 'flag-for-gibraltar', emoji: '🇬🇮' },
    { name: 'flag-for-greenland', emoji: '🇬🇱' },
    { name: 'flag-for-gambia', emoji: '🇬🇲' },
    { name: 'flag-for-guinea', emoji: '🇬🇳' },
    { name: 'flag-for-guadeloupe', emoji: '🇬🇵' },
    { name: 'flag-for-equatorial-guinea', emoji: '🇬🇶' },
    { name: 'flag-for-greece', emoji: '🇬🇷' },
    { name: 'flag-for-south-georgia-south-sandwich-islands', emoji: '🇬🇸' },
    { name: 'flag-for-guatemala', emoji: '🇬🇹' },
    { name: 'flag-for-guam', emoji: '🇬🇺' },
    { name: 'flag-for-guinea-bissau', emoji: '🇬🇼' },
    { name: 'flag-for-guyana', emoji: '🇬🇾' },
    { name: 'flag-for-hong-kong', emoji: '🇭🇰' },
    { name: 'flag-for-heard-mcdonald-islands', emoji: '🇭🇲' },
    { name: 'flag-for-honduras', emoji: '🇭🇳' },
    { name: 'flag-for-croatia', emoji: '🇭🇷' },
    { name: 'flag-for-haiti', emoji: '🇭🇹' },
    { name: 'flag-for-hungary', emoji: '🇭🇺' },
    { name: 'flag-for-canary-islands', emoji: '🇮🇨' },
    { name: 'flag-for-indonesia', emoji: '🇮🇩' },
    { name: 'flag-for-ireland', emoji: '🇮🇪' },
    { name: 'flag-for-israel', emoji: '🇮🇱' },
    { name: 'flag-for-isle-of-man', emoji: '🇮🇲' },
    { name: 'flag-for-india', emoji: '🇮🇳' },
    { name: 'flag-for-british-indian-ocean-territory', emoji: '🇮🇴' },
    { name: 'flag-for-iraq', emoji: '🇮🇶' },
    { name: 'flag-for-iran', emoji: '🇮🇷' },
    { name: 'flag-for-iceland', emoji: '🇮🇸' },
    { name: 'flag-for-italy', emoji: '🇮🇹' },
    { name: 'flag-for-jersey', emoji: '🇯🇪' },
    { name: 'flag-for-jamaica', emoji: '🇯🇲' },
    { name: 'flag-for-jordan', emoji: '🇯🇴' },
    { name: 'flag-for-japan', emoji: '🇯🇵' },
    { name: 'flag-for-kenya', emoji: '🇰🇪' },
    { name: 'flag-for-kyrgyzstan', emoji: '🇰🇬' },
    { name: 'flag-for-cambodia', emoji: '🇰🇭' },
    { name: 'flag-for-kiribati', emoji: '🇰🇮' },
    { name: 'flag-for-comoros', emoji: '🇰🇲' },
    { name: 'flag-for-st-kitts-nevis', emoji: '🇰🇳' },
    { name: 'flag-for-north-korea', emoji: '🇰🇵' },
    { name: 'flag-for-south-korea', emoji: '🇰🇷' },
    { name: 'flag-for-kuwait', emoji: '🇰🇼' },
    { name: 'flag-for-cayman-islands', emoji: '🇰🇾' },
    { name: 'flag-for-kazakhstan', emoji: '🇰🇿' },
    { name: 'flag-for-laos', emoji: '🇱🇦' },
    { name: 'flag-for-lebanon', emoji: '🇱🇧' },
    { name: 'flag-for-st-lucia', emoji: '🇱🇨' },
    { name: 'flag-for-liechtenstein', emoji: '🇱🇮' },
    { name: 'flag-for-sri-lanka', emoji: '🇱🇰' },
    { name: 'flag-for-liberia', emoji: '🇱🇷' },
    { name: 'flag-for-lesotho', emoji: '🇱🇸' },
    { name: 'flag-for-lithuania', emoji: '🇱🇹' },
    { name: 'flag-for-luxembourg', emoji: '🇱🇺' },
    { name: 'flag-for-latvia', emoji: '🇱🇻' },
    { name: 'flag-for-libya', emoji: '🇱🇾' },
    { name: 'flag-for-morocco', emoji: '🇲🇦' },
    { name: 'flag-for-monaco', emoji: '🇲🇨' },
    { name: 'flag-for-moldova', emoji: '🇲🇩' },
    { name: 'flag-for-montenegro', emoji: '🇲🇪' },
    { name: 'flag-for-st-martin', emoji: '🇲🇫' },
    { name: 'flag-for-madagascar', emoji: '🇲🇬' },
    { name: 'flag-for-marshall-islands', emoji: '🇲🇭' },
    { name: 'flag-for-macedonia', emoji: '🇲🇰' },
    { name: 'flag-for-mali', emoji: '🇲🇱' },
    { name: 'flag-for-myanmar', emoji: '🇲🇲' },
    { name: 'flag-for-mongolia', emoji: '🇲🇳' },
    { name: 'flag-for-macau', emoji: '🇲🇴' },
    { name: 'flag-for-northern-mariana-islands', emoji: '🇲🇵' },
    { name: 'flag-for-martinique', emoji: '🇲🇶' },
    { name: 'flag-for-mauritania', emoji: '🇲🇷' },
    { name: 'flag-for-montserrat', emoji: '🇲🇸' },
    { name: 'flag-for-malta', emoji: '🇲🇹' },
    { name: 'flag-for-mauritius', emoji: '🇲🇺' },
    { name: 'flag-for-maldives', emoji: '🇲🇻' },
    { name: 'flag-for-malawi', emoji: '🇲🇼' },
    { name: 'flag-for-mexico', emoji: '🇲🇽' },
    { name: 'flag-for-malaysia', emoji: '🇲🇾' },
    { name: 'flag-for-mozambique', emoji: '🇲🇿' },
    { name: 'flag-for-namibia', emoji: '🇳🇦' },
    { name: 'flag-for-new-caledonia', emoji: '🇳🇨' },
    { name: 'flag-for-niger', emoji: '🇳🇪' },
    { name: 'flag-for-norfolk-island', emoji: '🇳🇫' },
    { name: 'flag-for-nigeria', emoji: '🇳🇬' },
    { name: 'flag-for-nicaragua', emoji: '🇳🇮' },
    { name: 'flag-for-netherlands', emoji: '🇳🇱' },
    { name: 'flag-for-norway', emoji: '🇳🇴' },
    { name: 'flag-for-nepal', emoji: '🇳🇵' },
    { name: 'flag-for-nauru', emoji: '🇳🇷' },
    { name: 'flag-for-niue', emoji: '🇳🇺' },
    { name: 'flag-for-new-zealand', emoji: '🇳🇿' },
    { name: 'flag-for-oman', emoji: '🇴🇲' },
    { name: 'flag-for-panama', emoji: '🇵🇦' },
    { name: 'flag-for-peru', emoji: '🇵🇪' },
    { name: 'flag-for-french-polynesia', emoji: '🇵🇫' },
    { name: 'flag-for-papua-new-guinea', emoji: '🇵🇬' },
    { name: 'flag-for-philippines', emoji: '🇵🇭' },
    { name: 'flag-for-pakistan', emoji: '🇵🇰' },
    { name: 'flag-for-poland', emoji: '🇵🇱' },
    { name: 'flag-for-st-pierre-miquelon', emoji: '🇵🇲' },
    { name: 'flag-for-pitcairn-islands', emoji: '🇵🇳' },
    { name: 'flag-for-puerto-rico', emoji: '🇵🇷' },
    { name: 'flag-for-palestinian-territories', emoji: '🇵🇸' },
    { name: 'flag-for-portugal', emoji: '🇵🇹' },
    { name: 'flag-for-palau', emoji: '🇵🇼' },
    { name: 'flag-for-paraguay', emoji: '🇵🇾' },
    { name: 'flag-for-qatar', emoji: '🇶🇦' },
    { name: 'flag-for-reunion', emoji: '🇷🇪' },
    { name: 'flag-for-romania', emoji: '🇷🇴' },
    { name: 'flag-for-serbia', emoji: '🇷🇸' },
    { name: 'flag-for-russia', emoji: '🇷🇺' },
    { name: 'flag-for-rwanda', emoji: '🇷🇼' },
    { name: 'flag-for-saudi-arabia', emoji: '🇸🇦' },
    { name: 'flag-for-solomon-islands', emoji: '🇸🇧' },
    { name: 'flag-for-seychelles', emoji: '🇸🇨' },
    { name: 'flag-for-sudan', emoji: '🇸🇩' },
    { name: 'flag-for-sweden', emoji: '🇸🇪' },
    { name: 'flag-for-singapore', emoji: '🇸🇬' },
    { name: 'flag-for-st-helena', emoji: '🇸🇭' },
    { name: 'flag-for-slovenia', emoji: '🇸🇮' },
    { name: 'flag-for-svalbard-jan-mayen', emoji: '🇸🇯' },
    { name: 'flag-for-slovakia', emoji: '🇸🇰' },
    { name: 'flag-for-sierra-leone', emoji: '🇸🇱' },
    { name: 'flag-for-san-marino', emoji: '🇸🇲' },
    { name: 'flag-for-senegal', emoji: '🇸🇳' },
    { name: 'flag-for-somalia', emoji: '🇸🇴' },
    { name: 'flag-for-suriname', emoji: '🇸🇷' },
    { name: 'flag-for-south-sudan', emoji: '🇸🇸' },
    { name: 'flag-for-sao-tome-principe', emoji: '🇸🇹' },
    { name: 'flag-for-el-salvador', emoji: '🇸🇻' },
    { name: 'flag-for-sint-maarten', emoji: '🇸🇽' },
    { name: 'flag-for-syria', emoji: '🇸🇾' },
    { name: 'flag-for-swaziland', emoji: '🇸🇿' },
    { name: 'flag-for-tristan-da-cunha', emoji: '🇹🇦' },
    { name: 'flag-for-turks-caicos-islands', emoji: '🇹🇨' },
    { name: 'flag-for-chad', emoji: '🇹🇩' },
    { name: 'flag-for-french-southern-territories', emoji: '🇹🇫' },
    { name: 'flag-for-togo', emoji: '🇹🇬' },
    { name: 'flag-for-thailand', emoji: '🇹🇭' },
    { name: 'flag-for-tajikistan', emoji: '🇹🇯' },
    { name: 'flag-for-tokelau', emoji: '🇹🇰' },
    { name: 'flag-for-timor-leste', emoji: '🇹🇱' },
    { name: 'flag-for-turkmenistan', emoji: '🇹🇲' },
    { name: 'flag-for-tunisia', emoji: '🇹🇳' },
    { name: 'flag-for-tonga', emoji: '🇹🇴' },
    { name: 'flag-for-turkey', emoji: '🇹🇷' },
    { name: 'flag-for-trinidad-tobago', emoji: '🇹🇹' },
    { name: 'flag-for-tuvalu', emoji: '🇹🇻' },
    { name: 'flag-for-taiwan', emoji: '🇹🇼' },
    { name: 'flag-for-tanzania', emoji: '🇹🇿' },
    { name: 'flag-for-ukraine', emoji: '🇺🇦' },
    { name: 'flag-for-uganda', emoji: '🇺🇬' },
    { name: 'flag-for-us-outlying-islands', emoji: '🇺🇲' },
    { name: 'flag-for-united-nations', emoji: '🇺🇳' },
    { name: 'flag-for-united-states', emoji: '🇺🇸' },
    { name: 'flag-for-uruguay', emoji: '🇺🇾' },
    { name: 'flag-for-uzbekistan', emoji: '🇺🇿' },
    { name: 'flag-for-vatican-city', emoji: '🇻🇦' },
    { name: 'flag-for-st-vincent-grenadines', emoji: '🇻🇨' },
    { name: 'flag-for-venezuela', emoji: '🇻🇪' },
    { name: 'flag-for-british-virgin-islands', emoji: '🇻🇬' },
    { name: 'flag-for-us-virgin-islands', emoji: '🇻🇮' },
    { name: 'flag-for-vietnam', emoji: '🇻🇳' },
    { name: 'flag-for-vanuatu', emoji: '🇻🇺' },
    { name: 'flag-for-wallis-futuna', emoji: '🇼🇫' },
    { name: 'flag-for-samoa', emoji: '🇼🇸' },
    { name: 'flag-for-kosovo', emoji: '🇽🇰' },
    { name: 'flag-for-yemen', emoji: '🇾🇪' },
    { name: 'flag-for-mayotte', emoji: '🇾🇹' },
    { name: 'flag-for-south-africa', emoji: '🇿🇦' },
    { name: 'flag-for-zambia', emoji: '🇿🇲' },
    { name: 'flag-for-zimbabwe', emoji: '🇿🇼' },
    { name: 'flag-for-england', emoji: '🏴󠁧󠁢󠁥󠁮󠁧󠁿' },
    { name: 'flag-for-scotland', emoji: '🏴󠁧󠁢󠁳󠁣󠁴󠁿' },
    { name: 'flag-for-wales', emoji: '🏴󠁧󠁢󠁷󠁬󠁳󠁿' },
    { name: 'flag-for-texas-ustx', emoji: '🏴󠁵󠁳󠁴󠁸󠁿' }
  ]
}

export default emojis
