import { fromJS } from 'immutable'
import R from 'utils/ramda'
import {
  FETCH_SEARCH_REQUEST,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_FAILURE,
  FETCH_SEARCH_RESET,
  ADD_RECENT_SEQUENCE,
  FETCH_CHECKLIST_REQUEST,
  FETCH_CHECKLIST_SUCCESS,
  FETCH_CHECKLIST_FAILURE
} from './constants'

const initialObject = {
  data: null,
  loading: true,
  error: null
}

// The initial state of the Navigation state
const initialState = fromJS({
  results: {
    contacts: [],
    campaigns: [],
    totalContacts: 0,
    totalCampaigns: 0
  },
  query: null,
  loading: false,
  recentSequences: [],
  checklist: initialObject
})

const RECENT_SEQUENCES_STORED = 3

function navigationReducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_SEARCH_REQUEST:
      return state.set('loading', true)

    case FETCH_SEARCH_SUCCESS: {
      const {
        contacts,
        campaigns,
        totalContacts,
        totalCampaigns
      } = action.payload
      return state
        .set('results', fromJS({
          contacts,
          campaigns,
          totalContacts,
          totalCampaigns
        }))
        .set('loading', false)
    }
    case FETCH_SEARCH_FAILURE:
      return state.set('loading', false)

    case FETCH_SEARCH_RESET:
      return state
        .set('query', null)
        .set('results', fromJS({
          contacts: [],
          campaigns: [],
          totalContacts: 0,
          totalCampaigns: 0
        }))
        .set('loading', false)

    case ADD_RECENT_SEQUENCE: {
      const sequence = action.sequence
      let recent = state.get('recentSequences')
      if (sequence.get('_id')) {
        recent.unshift(sequence.toJS())
        // Remove duplicates and only take the ${RECENT_SEQUENCES_STORED} most recent
        recent = R.slice(
          0, RECENT_SEQUENCES_STORED,
          R.uniqBy(s => s.id, recent)
        )
      }
      return state.set('recentSequences', recent)
    }

    case FETCH_CHECKLIST_REQUEST:
      return state.set('checklist', state.get('checklist').merge({ loading: true }))
    case FETCH_CHECKLIST_SUCCESS:
      return state.set('checklist', fromJS({
        data: action.payload.data,
        loading: false,
        error: null
      }))
    case FETCH_CHECKLIST_FAILURE:
      return state.set('checklist', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    default:
      return state
  }
}

export default navigationReducer
