import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme, keyframes } from 'styled-components'

const circleAnimation = keyframes`
  0% { stroke-dasharray: 0 100; }
`

const Wrapper = styled.div`
  transform: scale(.8);

  & > svg {
    width: ${props => props.size}px;
    height: ${props => props.size}px;
    stroke-width: 3.5;
    stroke-linecap: round;

    & > path {
      animation: ${props => props.animate
        ? `${circleAnimation} .300s ease-out forwards`
        : 'none'
      };
    }

    & > text {
      font-size: ${props => props.fontSize};
    }
  }
`

class PercentageCircle extends React.Component {
  wheelColor = percent => {
    // use fill color if fill color exists
    const { fillColor } = this.props
    if (fillColor) {
      return fillColor
    }

    // use green, yellow, and red otherwise
    const { theme, minGreen = 50, minYellow = 30 } = this.props
    if (percent > minGreen) {
      return theme.colors.green
    } else if (percent > minYellow) {
      return theme.colors.yellow
    } else {
      return theme.colors.red
    }
  }

  render () {
    const {
      emptyColor,
      percent,
      animate = false,
      showPercentage = true,
      percentageLabel = `${percent}%`,
      size = 60,
      fontSize = '.6rem'
    } = this.props

    const stroke = this.wheelColor(percent)

    return (
      <Wrapper size={size} animate={animate}>
        <svg viewBox='0 0 36 36'>
          {!!emptyColor &&
            <path
              d='M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831'
              stroke={emptyColor}
              fill='none'
              strokeWidth='4'
            />}

          {!emptyColor &&
            <path
              d='M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831'
              stroke={stroke}
              strokeOpacity='0.1'
              fill='none'
              strokeWidth='4'
            />}

          {!!percent && percent > 0 &&
            <path
              d='M18 2.0845
                a 15.9155 15.9155 0 0 1 0 31.831
                a 15.9155 15.9155 0 0 1 0 -31.831'
              fill='none'
              stroke={stroke}
              strokeDasharray={`${percent} 100`}
            />}
          {showPercentage &&
            <text
              x='50%' y='50%'
              dominantBaseline='middle'
              textAnchor='middle'
              fontSize={fontSize}
              dy='0.05rem'
            >
              {percentageLabel}
            </text>}
        </svg>
      </Wrapper>
    )
  }
}

PercentageCircle.propTypes = {
  // general styling props
  size: PropTypes.number,
  theme: PropTypes.object,
  animate: PropTypes.bool,
  fontSize: PropTypes.number,

  // `emptyColor` & `fillColor` can be used when you want the percent wheel to
  // have only 2 colors. `emptyColor` will become the background circle,
  // `fillColor` will be the wheel to display the percent
  emptyColor: PropTypes.any,
  fillColor: PropTypes.any,

  // `minGreen` & `minYellow` can be used to show a percent with a status color
  // these are the min values that will change the color of the percent wheel
  minGreen: PropTypes.number,
  minYellow: PropTypes.number,

  // percent props
  percent: PropTypes.number,
  percentageLabel: PropTypes.string,
  showPercentage: PropTypes.bool
}

export default withTheme(PercentageCircle)
