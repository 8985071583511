import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import Checkbox from 'elements/Checkbox'
import { fadeInRight } from 'utils/animations'
import IconButton from 'components/IconButton'
import SaveActionsMenu from 'components/SlateEditor/components/Toolbar/SaveActionsMenu'
import Label from 'elements/Label'

const Wrapper = styled.div`
  color: ${props => props.theme.titleColor};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  background: ${props => props.theme.colors.gray10};
  width: ${props => props.width || '100%'};
  padding: ${props => `${props.theme.padding} 1.5rem`};
`

const SelectionTitle = styled.h3`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.header};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0;
`

const CheckboxContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 0.75rem;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`

const ButtonContainer = styled.div`
  animation: ${fadeInRight} .2s linear 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const SaveActionsButton = styled.div`
  margin-left: 0.5rem;
`

class TaskToolbar extends Component {
  constructor (props) {
    super(props)

    this.state = {
      showTaskMenu: false
    }
  }

  handleCheck = () => {
    const {
      onChecked,
      listRef
    } = this.props
    onChecked()
    if (listRef.current) {
      listRef.current.forceUpdateGrid()
    }
  }

  toggleTaskMenu = () => {
    this.setState(prevState => ({
      showTaskMenu: !prevState.showTaskMenu
    }))
  }

  render () {
    const {
      title,
      selectedLabel,
      checked,
      onChecked,
      showButtons,
      onPause,
      onSkip
    } = this.props
    const {
      showTaskMenu
    } = this.state

    return (
      <Wrapper>
        <TitleContainer>
          {onChecked &&
            <CheckboxContainer>
              <Checkbox
                selected={checked}
                handleClick={this.handleCheck}
              />
            </CheckboxContainer>}
          <SelectionTitle>{title}</SelectionTitle>
        </TitleContainer>
        {showButtons &&
          <ButtonContainer>
            <Label fontWeight='600' color='darkBlue' mr='0.5rem'>{selectedLabel}</Label>
            <SaveActionsButton onClick={this.toggleTaskMenu}>
              <IconButton
                type={['fal', 'angle-down']}
                iconSize='lg'
                translateY='1'
              />
              {showTaskMenu &&
                <SaveActionsMenu saveActions={[
                  {
                    title: 'Pause Contacts',
                    fn: () => onPause()
                  },
                  {
                    title: 'Skip Tasks',
                    fn: () => onSkip()
                  }
                ]}
                />}
            </SaveActionsButton>
          </ButtonContainer>}
      </Wrapper>
    )
  }
}

TaskToolbar.propTypes = {
  title: PropTypes.string,
  selectedLabel: PropTypes.string,
  checked: PropTypes.bool,
  onChecked: PropTypes.func,
  showButtons: PropTypes.bool,
  listRef: PropTypes.object,
  onPause: PropTypes.func,
  onSkip: PropTypes.func
}

export default withTheme(TaskToolbar)
