/* eslint-disable react/no-find-dom-node */

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { findDOMNode } from 'react-dom'
import styled from 'styled-components'
import resizeDetector from 'element-resize-detector'
import {
  Manager,
  Reference,
  Popper as Positioner
} from 'react-popper'
import { debounce } from 'utils/browser'
import Portal from '../Portal'

import PositionerWrapper from './PositionerWrapper'

const ReferenceWrapper = styled.div`
  -webkit-user-select: none;
  -moz-user-select: none;
  -khtml-user-select: none;
  -ms-user-select: none;
`

const PopoverWrapper = styled.div``

class Popper extends PureComponent {
  constructor (props) {
    super(props)

    this._handleResize = debounce(this._handleResize, 50, false)

    this.state = {
      isOpen: !!props.defaultOpen,
      width: null
    }
  }

  componentDidMount () {
    this._setOutsideTap()
  }

  UNSAFE_componentDidUpdate (lastProps, lastState) {
    if (!lastState.isOpen && this.state.isOpen) {
      setTimeout(() => this._setOutsideTap())
      setTimeout(() => this._setResizeDetector())
    }
  }

  UNSAFE_componentWillUnmount () {
    document.removeEventListener('click', this._handleOutsideTap, true)
    if (this.detector) {
      this.detector.uninstall()
    }
  }

  _setOutsideTap = () => {
    document.addEventListener('click', this._handleOutsideTap, true)
  }

  _setResizeDetector = () => {
    this.detector = resizeDetector()

    if (this.target) {
      this.detector.listenTo(this.target, this._handleResize)
    }
  }

  _handleResize = el => {
    this.mostRecentWidth = this.state.width
    this.setState({
      width: el.offsetWidth
    })
  }

  _handleOutsideTap = e => {
    const clickedPopper = e && this.popper && this.popper.contains(e.target)
    const clickedTarget = e && this.target && this.target.contains(e.target)

    if (!e || (!clickedPopper && !clickedTarget)) {
      this.close()
    }
  }

  close = () => {
    this.setState({ isOpen: false }, this.props.onClose)
  }

  _handleTargetClick = () => {
    if (this.state.isOpen) {
      this.close()
    } else {
      this.setState({ isOpen: true }, this.props.onOpen)
    }
  }

  _getPopperWidth () {
    if (this.target && this.props.fullWidth) {
      if (!this.state.width) {
        return this.target.getBoundingClientRect().width - 2
      } else {
        return this.state.width - 2
      }
    }

    if (this.props.width) {
      return this.props.width
    }
  }

  renderPositioner () {
    const {
      position,
      children,
      contentStyle
    } = this.props

    const popperStyle = {
      ...contentStyle,
      zIndex: 99,
      width: this._getPopperWidth(),
      display: this.state.isOpen ? 'block' : 'none'
    }

    const popperModifiers = [{
      name: 'preventOverflow',
      enabled: true,
      options: {
        escapeWithReference: true,
        boundariesElement: 'scrollParent'
      }
    }]

    return (
      <Positioner
        placement={position}
        modifiers={popperModifiers}
        innerRef={c => {
          this.popper = findDOMNode(c)
        }}
      >
        {({ ref, style, placement, update }) => {
          return (
            <div ref={ref} style={{ ...style, ...popperStyle }} data-placement={placement}>
              <PositionerWrapper
                {...this.props}
                update={update}
                isOpen={this.state.isOpen}
              >
                {typeof children === 'function'
                  ? children(this.close, this.state.isOpen)
                  : children}
              </PositionerWrapper>
            </div>
          )
        }}
      </Positioner>
    )
  }

  render () {
    const { trigger, portal, ...rest } = this.props

    return (
      <PopoverWrapper {...rest} isOpen={this.state.isOpen}>
        <Manager>
          <Reference>
            {({ ref }) => (
              <div ref={ref}>
                <ReferenceWrapper
                  innerRef={c => (this.target = findDOMNode(c))}
                  isOpen={this.state.isOpen}
                  onClick={() => {
                    this._handleTargetClick()
                  }}
                >
                  {trigger}
                </ReferenceWrapper>
              </div>
            )}
          </Reference>

          {portal
            ? <Portal>{this.renderPositioner()}</Portal>
            : this.renderPositioner()}
        </Manager>
      </PopoverWrapper>
    )
  }
}

Popper.propTypes = {
  arrow: PropTypes.bool,
  animation: PropTypes.oneOf(['fade', 'scale', 'slide']),
  duration: PropTypes.number,
  offset: PropTypes.number,
  onOpen: PropTypes.func,
  onClose: PropTypes.func,
  fullWidth: PropTypes.bool,
  width: PropTypes.number,
  defaultOpen: PropTypes.bool,
  portal: PropTypes.bool,
  contentStyle: PropTypes.object,
  trigger: PropTypes.oneOfType([PropTypes.func, PropTypes.element]).isRequired,
  on: PropTypes.oneOfType([
    PropTypes.oneOf(['hover', 'click', 'focus']),
    PropTypes.arrayOf(PropTypes.oneOf(['hover', 'click', 'focus']))
  ]),
  children: PropTypes.oneOfType([
    PropTypes.func,
    PropTypes.element,
    PropTypes.string
  ]).isRequired,
  position: PropTypes.oneOf([
    'top',
    'top-start',
    'top-end',
    'bottom',
    'bottom-start',
    'bottom-end',
    'right',
    'right-start',
    'right-end',
    'left',
    'left-start',
    'left-end',
    'auto',
    'auto-start',
    'auto-end'
  ])
}

Popper.defaultProps = {
  onOpen: () => {},
  onClose: () => {},
  closeOnDocumentClick: true,
  defaultOpen: false,
  on: ['click'],
  portal: false,
  arrow: true,
  animation: 'slide',
  easing: 'easeOutQuint',
  duration: 250,
  position: 'bottom-start',
  fullWidth: false,
  contentStyle: {}
}

export default Popper
