import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  flex: 1 1 auto;
  padding: 2rem 0;
  height: 100%;
  width: 100%;
`

export default Wrapper
