import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import TextFieldForm from 'components/TextFieldForm'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Subtitle from 'elements/Subtitle'
import InfoLabel from 'elements/InfoLabel'
import BrandedDomain from './BrandedDomain'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
  padding: ${props => props.theme.padding};
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray30};
  border-style: solid;
`

const CalendarSelectContainer = styled.div`
  padding-top: 1.5rem;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  grid-gap: 0.5rem;
`

const CalendarSelect = styled.div`
  border: 1px solid ${props => props.theme.colors.gray30};
  padding: 1rem;
  padding-left: 0.75rem;
  overflow: hidden;
`

class Tracking extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showInfoLabel: {
        link_tracking: false,
        unsubscribe: false
      }
    }
  }

  componentDidMount () {
    const accountType = this.props.session.get('account_type')
    if (accountType === 'google') {
      this.props.actions.fetchCalendars()
    }
  }

  updateCalendar (calendarId, value) {
    const {
      calendars,
      actions
    } = this.props

    const calendarIds = calendars
      .get('data')
      .map((calendar) => {
        if (calendar.get('id') === calendarId) {
          return calendar.set('selected', value)
        }

        return calendar
      })
      .filter(c => c.get('selected'))
      .map(c => c.get('id'))

    actions.updateCalendars(calendarIds)
  }

  renderCalendarFields () {
    const {
      calendars
    } = this.props

    return calendars
      .get('data')
      .sortBy((calendar) => calendar.get('id') !== 'primary')
      .map((calendar) => {
        return (
          <CalendarSelect key={calendar.get('id')}>
            <SwitchFieldForm
              small='true'
              condensed='true'
              label={calendar.get('name')}
              inputId='syncCalendar'
              value={calendar.get('selected')}
              disabled={calendar.get('id') === 'primary'}
              onSave={(value) => {
                this.updateCalendar(calendar.get('id'), value)
              }}
            />
          </CalendarSelect>
        )
      })
  }

  handleOnChange = (e) => {
    const { showInfoLabel } = { ...this.state }
    const currentState = showInfoLabel

    if (e.target.checked) {
      currentState[e.target.id] = true
    } else {
      currentState[e.target.id] = false
    }

    this.setState({ showInfoLabel: currentState })
  }

  renderInfoLabel = (message) => {
    return (
      <InfoLabel mt={18} mb={0} display='flex'>
        <FontAwesomeIcon icon={['fas', 'exclamation-triangle']} />
        <p>{message}</p>
      </InfoLabel>
    )
  }

  render () {
    const {
      session,
      state,
      teamDomain,
      onSettingUpdate,
      calendars
    } = this.props
    const {
      showInfoLabel
    } = this.state

    const hasCalendars = calendars.get('data') && calendars.get('data').count() > 1
    const openTracking = session.get('open_tracking')
    const linkTracking = session.get('link_tracking')
    const unsubcribeLink = session.get('unsubscribe')
    const unsubscribeText = session.get('unsubscribe_text')
    const syncCalendar = session.get('sync_calendar')
    const linkedinReplies = session.get('linkedin_replies')
    const autoSentiment = session.get('auto_sentiment')

    return (
      <Wrapper>
        <Subtitle>Tracking</Subtitle>
        <FieldContainer>
          <SwitchFieldForm
            label='Open Tracking'
            description='Track when any of your contacts opens one of your emails'
            inputId='open_tracking'
            value={openTracking}
            onSave={(value) => {
              onSettingUpdate({ open_tracking: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Link Tracking'
            description='Track whenever any of your contacts click a link in one of your messages'
            inputId='link_tracking'
            value={linkTracking}
            onSave={(value) => {
              onSettingUpdate({ link_tracking: value })
            }}
            onChange={this.handleOnChange}
          />
          {showInfoLabel.link_tracking
            ? this.renderInfoLabel('Link tracking applies only to messages sent going forward, it does not apply retroactively.')
            : null}
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Unsubscribe Link'
            description="Add an 'opt-out' link to the bottom of your messages"
            inputId='unsubscribe'
            value={unsubcribeLink}
            onSave={(value) => {
              onSettingUpdate({ unsubscribe: value })
            }}
            onChange={this.handleOnChange}
          />
          {unsubcribeLink &&
            <TextFieldForm
              mt='1rem'
              label='Opt-out Text'
            // description="Set the address you'd like to copy all emails to"
              value={unsubscribeText}
              inputId='unsubscribeText'
              onSave={(value) => {
                onSettingUpdate({ unsubscribe_text: value })
              }}
            />}
          {showInfoLabel.unsubscribe
            ? this.renderInfoLabel('Unsubscribe links are only added to messages sent going forward, it cannot add unsubscribe links retroactively.')
            : null}
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Reply Tracking'
            description='Track replies from contacts and mark contacts as finished'
            inputId='reply'
            value='true'
            disabled='true'
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Automatic Sentiment Tracking (beta)'
            description='Contacts will automatically get categorized (interested, not interested, etc.) utilizing AI on their responses. Not all responses can be categorized automatically.'
            inputId='auto_sentiment'
            value={autoSentiment}
            onSave={(value) => {
              onSettingUpdate({ auto_sentiment: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='LinkedIn Tracking (beta)'
            description='Track email notifications from LinkedIn about new InMail messages and messages from connections. You will need to make sure you are receiving individual LinkedIn notifications to your email account linked to Interseller. Not all LinkedIn emails trackable.'
            helpUrl='https://help.interseller.io/article/32-linkedin-tracking'
            inputId='linkedin_replies'
            value={linkedinReplies}
            onSave={(value) => {
              onSettingUpdate({ linkedin_replies: value })
            }}
          />
        </FieldContainer>

        <FieldContainer>
          <SwitchFieldForm
            label='Calendar Tracking'
            description='Track events in your calendar when a contact is an invitee and mark contacts as finished'
            inputId='syncCalendar'
            value={syncCalendar}
            onSave={(value) => {
              onSettingUpdate({ sync_calendar: value })
            }}
          />
          {syncCalendar && hasCalendars &&
            <CalendarSelectContainer>
              {this.renderCalendarFields()}
            </CalendarSelectContainer>}
        </FieldContainer>
        <FieldContainer>
          <BrandedDomain
            state={state}
            teamDomain={teamDomain}
            session={session}
          />
        </FieldContainer>
      </Wrapper>
    )
  }
}

Tracking.propTypes = {
  session: PropTypes.object,
  state: PropTypes.object,
  teamDomain: PropTypes.object,
  onSettingUpdate: PropTypes.func,
  calendars: PropTypes.object,
  actions: PropTypes.object
}

export default Tracking
