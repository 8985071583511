import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'

import { Search } from 'svg'

import Loading from 'components/Loading'
import IconButton from 'components/IconButton'

import SearchSuggestions from './SearchSuggestions'

import { debounce } from 'utils/browser'

const Wrapper = styled.div`
  flex: 1 0 auto;
  height: 100%;
  color: white;
  align-items: start;
  justify-content: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 1rem;
  position: relative;
`

const InputBox = styled.input`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.normal};
  height: 100%;
  width: 100%;
  padding-left: 0.75rem;
  color: black;
  border: none;
  outline: none;
  margin-top: -1px;
`

const defaultState = {
  focused: false,
  search: '',
  active: false
}

class SearchBox extends PureComponent {
  constructor (props) {
    super(props)

    // Default state
    this.state = defaultState
  }

  handleKeyDown = (e) => {
    // If 'escape' is pressed
    if (e.keyCode === 27) {
      e.preventDefault()
      this.input.blur()
      this.props.actions.fetchSearchReset()
      return this.setState(defaultState)
    } else if (e.keyCode === 40) {
      e.preventDefault()
      return this.suggestions.onKeyDown()
    } else if (e.keyCode === 38) {
      e.preventDefault()
      return this.suggestions.onKeyUp()
    } else if (e.keyCode === 13) {
      e.preventDefault()
      return this.suggestions.onKeyEnter()
    }

    return false
  }

  search = debounce(() => {
    this.props.onChange(this.state.search)
    this.setState({
      changing: false
    })
  }, 500)

  render () {
    const {
      focused,
      search,
      active,
      changing
    } = this.state

    const {
      results,
      loading,
      router,
      theme,
      // onChange,
      recentSequences
    } = this.props

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
        onMouseEnter={() => {
          if (focused) {
            this.setState({
              active: true
            })
          }
        }}
        onMouseLeave={() => {
          this.setState({
            active: false
          })
        }}
      >
        {/* <Loading size={20} padding={0} /> */}
        {(loading || changing) && <Loading size={14} padding={0} />}
        {!loading && !changing && <Search />}
        <InputBox
          innerRef={(comp) => { this.input = comp }}
          placeholder='Search for a contact or sequence'
          value={search}
          onFocus={() => {
            this.setState({
              focused: true,
              active: true
            })
          }}
          onBlur={() => {
            this.setState({
              focused: false
            })
          }}
          onChange={(e) => {
            const text = e.target.value

            this.setState({
              changing: true,
              search: text
            })

            this.search()
          }}
        />
        {search.length > 0 &&
          <IconButton
            type={['far', 'times']}
            size={25}
            iconColor='#687186'
            borderColor='#BABFCB'
            onClick={() => {
              this.setState({ search: '' })
            }}
          />}
        <SearchSuggestions
          ref={(suggestions) => { this.suggestions = suggestions }}
          open={!loading && !changing && (focused || active) && !!search.length}
          searchText={this.state.search}
          results={results}
          loading={loading}
          router={router}
          theme={theme}
          recentSequences={recentSequences}
          onResultSelected={(selectedOption) => {
            if (selectedOption && selectedOption.link) {
              router.push(selectedOption.link)
              this.input.blur()
              this.props.actions.fetchSearchReset()
              this.setState(defaultState)
            }
          }}
        />
      </Wrapper>
    )
  }
}

SearchBox.propTypes = {
  router: PropTypes.object,
  theme: PropTypes.object,
  results: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  onChange: PropTypes.func,
  actions: PropTypes.object,
  recentSequences: PropTypes.instanceOf(Immutable.List)
}

export default SearchBox
