import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Title from 'elements/Title'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
`

const TitleContainer = styled.div`
  padding: 1.5rem;
  padding-bottom: 0;
  display: grid;
  justify-content: center;
  align-items: center;
`

const DescriptionContainer = styled.div`
  padding: 1.5rem;
  padding-top: 1rem;
  text-align: center;
  white-space: pre-wrap;
  color: ${props => props.theme.colors.darkGray};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const OnboardingTutorialModal = ({
  isOpen,
  handleOnModalClose,
  modalTitle,
  modalDescription,
  modalWistiaVideoId,
  showCloseButton
}) => {
  const renderWistiaVideo = () => {
    return (
      <>
        <div
          className='wistia_responsive_padding'
          style={{
            padding: '70% 0 0 0',
            position: 'relative'
          }}
        >
          <div
            className='wistia_responsive_wrapper'
            style={{
              position: 'absolute',
              top: '0',
              left: '0',
              height: '100%',
              width: '100%'
            }}
          >
            <iframe
              src={`https://fast.wistia.net/embed/iframe/${modalWistiaVideoId}?seo=false&videoFoam=true`}
              title={modalTitle}
              className='wistia_embed'
              name='wistia_embed'
              width='100%'
              height='100%'
              frameBorder='0'
              scrolling='no'
              allow='autoplay; fullscreen'
              allowFullScreen
            />
          </div>
        </div>
      </>
    )
  }

  const InnerModal = ({ closeModal }) => {
    return (
      <Wrapper>
        <TitleContainer>
          <Title>{modalTitle}</Title>
        </TitleContainer>
        <DescriptionContainer>
          {modalDescription}
        </DescriptionContainer>
        {renderWistiaVideo()}
        {showCloseButton &&
          <ButtonContainer>
            <Button
              primary
              full
              label='Dismiss'
              onClick={() => {
                closeModal()
              }}
            />
          </ButtonContainer>}
      </Wrapper>
    )
  }

  InnerModal.propTypes = {
    closeModal: PropTypes.func
  }

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={handleOnModalClose}
      height='auto'
      width='40vw'
    >
      <InnerModal />
    </Modal>
  )
}

OnboardingTutorialModal.propTypes = {
  isOpen: PropTypes.bool,
  handleOnModalClose: PropTypes.func,
  modalTitle: PropTypes.string,
  modalDescription: PropTypes.string,
  modalWistiaVideoId: PropTypes.string,
  showCloseButton: PropTypes.bool
}

export default OnboardingTutorialModal
