import React from 'react'

/* eslint-disable */
const Diamond = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg
    onClick={onClick}
    className={className}
    style={{ ...style, transform: `rotate(${degrees}deg)` }}
    width={width || 448}
    height={height || 512}
    aria-hidden="true"
    focusable="false"
    data-prefix="fas"
    data-icon="diamond"
    role="img"
    xmlns="http://www.w3.org/2000/svg"
    viewBox="0 0 448 512"
  >
    <path
      fill={color || "currentColor"}
      d="M242.2 8.3c-9.6-11.1-26.8-11.1-36.4 0l-200 232c-7.8 9-7.8 22.3 0 31.3l200 232c9.6 11.1 26.8 11.1 36.4 0l200-232c7.8-9 7.8-22.3 0-31.3l-200-232z">
    </path>
  </svg>
);

export default Diamond;