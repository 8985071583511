export const FETCH_TEAM_MEMBERS_REQUEST = 'Team/FETCH_TEAM_MEMBERS_REQUEST'
export const FETCH_TEAM_MEMBERS_SUCCESS = 'Team/FETCH_TEAM_MEMBERS_SUCCESS'
export const FETCH_TEAM_MEMBERS_FAILURE = 'Team/FETCH_TEAM_MEMBERS_FAILURE'

export const DELETE_TEAM_MEMBER_REQUEST = 'Team/DELETE_TEAM_MEMBER_REQUEST'
export const DELETE_TEAM_MEMBER_SUCCESS = 'Team/DELETE_TEAM_MEMBER_SUCCESS'
export const DELETE_TEAM_MEMBER_FAILURE = 'Team/DELETE_TEAM_MEMBER_FAILURE'

export const UPDATE_TEAM_MEMBER_REQUEST = 'Team/UPDATE_TEAM_MEMBER_REQUEST'
export const UPDATE_TEAM_MEMBER_SUCCESS = 'Team/UPDATE_TEAM_MEMBER_SUCCESS'
export const UPDATE_TEAM_MEMBER_FAILURE = 'Team/UPDATE_TEAM_MEMBER_FAILURE'

export const FETCH_TEAM_INVITES_REQUEST = 'Team/FETCH_TEAM_INVITES_REQUEST'
export const FETCH_TEAM_INVITES_SUCCESS = 'Team/FETCH_TEAM_INVITES_SUCCESS'
export const FETCH_TEAM_INVITES_FAILURE = 'Team/FETCH_TEAM_INVITES_FAILURE'

export const DELETE_TEAM_INVITES_REQUEST = 'Team/DELETE_TEAM_INVITES_REQUEST'
export const DELETE_TEAM_INVITES_SUCCESS = 'Team/DELETE_TEAM_INVITES_SUCCESS'
export const DELETE_TEAM_INVITES_FAILURE = 'Team/DELETE_TEAM_INVITES_FAILURE'

export const SEND_TEAM_INVITE_REQUEST = 'Team/SEND_TEAM_INVITE_REQUEST'
export const SEND_TEAM_INVITE_SUCCESS = 'Team/SEND_TEAM_INVITE_SUCCESS'
export const SEND_TEAM_INVITE_FAILURE = 'Team/SEND_TEAM_INVITE_FAILURE'

export const RESEND_TEAM_INVITES_REQUEST = 'Team/RESEND_TEAM_INVITES_REQUEST'
export const RESEND_TEAM_INVITES_SUCCESS = 'Team/RESEND_TEAM_INVITES_SUCCESS'
export const RESEND_TEAM_INVITES_FAILURE = 'Team/RESEND_TEAM_INVITES_FAILURE'

export const FETCH_TEAM_COUNTS_REQUEST = 'Team/TEAM_COUNTS_REQUEST'
export const FETCH_TEAM_COUNTS_SUCCESS = 'Team/TEAM_COUNTS_SUCCESS'
export const FETCH_TEAM_COUNTS_FAILURE = 'Team/TEAM_COUNTS_FAILURE'

export const FETCH_OWNER_CRM_REQUEST = 'Team/OWNER_CRM_REQUEST'
export const FETCH_OWNER_CRM_SUCCESS = 'Team/OWNER_CRM_SUCCESS'
export const FETCH_OWNER_CRM_FAILURE = 'Team/OWNER_CRM_FAILURE'

export const REFRESH_OWNER_CRM_REQUEST = 'Team/REFRESH_OWNER_CRM_REQUEST'

export const ASSIGN_GROUP_MEMBER_REQUEST = 'Team/ASSIGN_GROUP_MEMBER_REQUEST'
export const ASSIGN_GROUP_MEMBER_FAILURE = 'Team/ASSIGN_GROUP_MEMBER_FAILURE'
