import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'

import { space, width, height } from 'styled-system'
import styled from 'styled-components'

import Button from 'components/Button'
import DropDown from 'components/DropDown'
import Input from 'components/Input'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const Label = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.label};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

const Link = styled.a`

`

const InputWrapper = styled.div`
  display: flex;
`

function DropDownSelectInput ({
  label,
  customCreateLabel = '',
  description,
  inputId,
  onSave,
  onOptionChange,
  options = Immutable.List([]),
  helpUrl,
  searchable = true,
  clearable = false,
  controlled = false,
  disabled = false,
  buttonText = 'Save',
  value,
  height = '56px',
  ...props
}) {
  // if first option is not 'None', set custom opt as first option
  const customOptionIndex = options &&
    options.first &&
    options.first() &&
    options.first().value
    ? 0
    : 1

  let initialOptions = options

  if (options && options.splice) {
    initialOptions = options.splice(customOptionIndex, 0, {
      custom: true,
      value: '',
      label: customCreateLabel || 'Fill with Custom Text...'
    })
  }

  const [inputValue, setInputValue] = useState(value)
  const [showCustomTextInput, setShowInputTextInput] = useState(false)
  const [allOptions, setAllOptions] = useState(initialOptions)

  const handleSaveCustomText = () => {
    onOptionChange({
      value: inputValue,
      label: inputValue
    })
    setCustomFieldValue(inputValue)
    setShowInputTextInput(false)
  }

  const handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13 && valid() && onSave) {
      e.preventDefault()
      return onSave(inputValue)
    }

    if (e.keyCode === 13 && showCustomTextInput) {
      handleSaveCustomText()
    }

    return false
  }

  const valid = () => value !== inputValue

  const setCustomFieldValue = (val) => {
    if (val) {
      const newOptions = allOptions.splice(customOptionIndex + 1, 0, {
        value: val,
        label: val
      })
      setAllOptions(newOptions)
    }
  }

  return (
    <Wrapper
      onKeyDown={handleKeyDown}
      {...props}
    >

      {label &&
        <Label htmlFor={inputId}>{label}</Label>}
      {description &&
        <Description htmlFor={inputId}>
          {description} {helpUrl && <Link href={helpUrl} target='_new'>Learn more</Link>}
        </Description>}

      {!showCustomTextInput &&
        <DropDown
          options={allOptions}
          clearable={clearable}
          searchable={searchable}
          disabled={disabled}
          controlled={controlled}
          onOptionChange={(option) => {
            const newValue = option ? option.value : null

            if (!option) {
              setAllOptions(initialOptions)
            } else if (option && option.custom) {
              setShowInputTextInput(true)
              setAllOptions(initialOptions)
            }

            onOptionChange(option)
            setInputValue(newValue)
          }}
          value={controlled ? value : inputValue}
        />}

      {showCustomTextInput &&
        <InputWrapper>
          <Input
            autoFocus
            onValueChange={(value) => {
              setInputValue(value)
            }}
          />
          <Button
            primary
            label='create'
            placeholder='Custom text...'
            onClick={() => handleSaveCustomText()}
            ml='0.5rem'
          />
        </InputWrapper>}
      {(!disabled && onSave) &&
        <Button
          full
          primary
          label={buttonText}
          handleClick={() => {
            onSave(inputValue)
          }}
          mt='1rem'
        />}
    </Wrapper>
  )
}

DropDownSelectInput.propTypes = {
  label: PropTypes.string,
  description: PropTypes.any,
  value: PropTypes.any,
  options: PropTypes.instanceOf(Immutable.List),
  inputId: PropTypes.string,
  onSave: PropTypes.func,
  onOptionChange: PropTypes.func,
  searchable: PropTypes.bool,
  clearable: PropTypes.bool,
  controlled: PropTypes.bool,
  disabled: PropTypes.bool,
  helpUrl: PropTypes.string,
  buttonText: PropTypes.string,
  customCreateLabel: PropTypes.string,
  height: PropTypes.string
}

export default DropDownSelectInput
