import { fromJS } from 'immutable'
import { put, takeLatest, call } from 'redux-saga/effects'

import api, { messageFromError } from 'utils/api'

import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchSessionAddressesSuccess,
  fetchSessionAddressesFailure,
  fetchTeamSettingsFailure,
  fetchTeamSettingsSuccess,
  fetchTeamDomainFailure,
  fetchTeamDomainSuccess,
  fetchBlocklistSuccess,
  fetchBlocklistFailure,
  fetchTeamBlocklistSuccess,
  fetchTeamBlocklistFailure,
  updateBlocklistSuccess,
  updateBlocklistFailure,
  updateTeamBlocklistSuccess,
  updateTeamBlocklistFailure,
  fetchConfigSuccess,
  fetchConfigFailure,
  fetchCalendarsSuccess,
  fetchCalendarsFailure,
  updateCalendarsSuccess,
  updateCalendarsFailure,
  fetchTeamLogsSuccess,
  fetchTeamLogsFailure,
  fetchPurgeEmailCountSuccess,
  fetchPurgeEmailCountFailure,
  showPurgeModal,
  hidePurgeModal,
  deletePurgeEmailSuccess,
  deletePurgeEmailFailure
} from './actions'

import {
  FETCH_SESSION_ADDRESSES_REQUEST,
  FETCH_TEAM_SETTINGS_REQUEST,
  UPDATE_TEAM_SETTINGS_REQUEST,
  FETCH_TEAM_DOMAIN_REQUEST,
  FETCH_BLOCKLIST_REQUEST,
  FETCH_TEAM_BLOCKLIST_REQUEST,
  UPDATE_BLOCKLIST_REQUEST,
  UPDATE_TEAM_BLOCKLIST_REQUEST,
  FETCH_CONFIG_REQUEST,
  RESUBSCRIBE_EMAIL_REQUEST,
  FETCH_CONTACT_CSV_REQUEST,
  FETCH_CALENDARS_REQUEST,
  UPDATE_CALENDARS_REQUEST,
  FETCH_TEAM_LOGS_REQUEST,
  FETCH_CAMPAIGN_STEP_COPY_REQUEST,
  FETCH_PURGE_EMAIL_COUNT_REQUEST,
  DELETE_PURGE_EMAIL_REQUEST
} from './constants'

const get = (url, query) => api.get(url, {
  params: query
})
const post = (url, params) => api.post(url, params)
const update = (url, params) => api.put(url, params)

export function * fetchSessionAddressses () {
  try {
    const response = yield call(get, '/me/addresses')
    const addresses = fromJS(response.data)

    yield put(fetchSessionAddressesSuccess({
      addresses
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(fetchSessionAddressesFailure(err))
  }
}

export function * fetchTeamSettings () {
  try {
    const response = yield call(get, '/team/settings')
    const settings = fromJS(response.data)

    yield put(fetchTeamSettingsSuccess({
      settings
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(fetchTeamSettingsFailure(err))
  }
}

export function * fetchBlocklist () {
  try {
    const response = yield call(get, '/me/blocklist')
    const blocklist = fromJS(response.data)

    yield put(fetchBlocklistSuccess({
      blocklist
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(fetchBlocklistFailure(err))
  }
}

export function * fetchTeamBlocklist () {
  try {
    const response = yield call(get, '/team/blocklist')
    const blocklist = fromJS(response.data)

    yield put(fetchTeamBlocklistSuccess({
      blocklist
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(fetchTeamBlocklistFailure(err))
  }
}

export function * updateBlocklist (action) {
  const {
    blocklist
  } = action
  try {
    const response = yield call(update, '/me/blocklist', { blocklist })
    const bl = fromJS(response.data)

    yield put(updateBlocklistSuccess({
      blocklist: bl
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Updated blocklist! 🚀'
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(updateBlocklistFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * updateTeamBlocklist (action) {
  const {
    blocklist
  } = action
  try {
    const response = yield call(update, '/team/blocklist', { blocklist })
    const bl = fromJS(response.data)

    yield put(updateTeamBlocklistSuccess({
      blocklist: bl
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Updated team blocklist! 🚀'
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(updateTeamBlocklistFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchTeamDomain () {
  try {
    const response = yield call(get, '/team/domain')
    const domain = fromJS(response.data)

    yield put(fetchTeamDomainSuccess({
      domain
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(fetchTeamDomainFailure(err))
  }
}

export function * updateTeamSettings (action) {
  const params = action.params
  try {
    const response = yield call(update, '/team/settings', params)
    const settings = fromJS(response.data)

    yield put(fetchTeamSettingsSuccess({
      settings
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Settings updated! 🚀'
    }))
  } catch (err) {
    yield put(fetchTeamSettingsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchConfig (action) {
  try {
    const response = yield call(get, '/config', {})
    const config = fromJS(response.data)

    yield put(fetchConfigSuccess({
      config
    }))
  } catch (err) {
    yield put(fetchConfigFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * resubscribeEmail (action) {
  const payload = action.payload

  try {
    yield call(post, '/contacts/resubscribe', payload)
    // const data = fromJS(response.data)

    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `${payload.email} successfully resubscribed!`
    }))
  } catch (err) {
    yield put(fetchConfigFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchContactCSV (action) {
  try {
    const exportMessage = 'Exporting your ' + action.payload.value + ' contacts...'
    yield put(setNotification({
      type: NOTIFICATION_TYPES.LOADING,
      message: exportMessage
    }))

    const params = {
      filter: action.payload.value,
      timeout: 60000
    }

    const path = '/contacts/csv'

    const response = yield call(post, path, params)

    const type = fromJS(response.data.type)

    let successMessage
    if (type === 'email') {
      successMessage = 'Check your email in a few minutes for your CSV export'
    } else {
      successMessage = 'Exported CSV of contacts'
    }

    if (response.data.url) {
      window.location.href = response.data.url
    }

    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: successMessage
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchCalendars (action) {
  try {
    const response = yield call(get, '/me/calendars', {})
    const calendars = fromJS(response.data)

    yield put(fetchCalendarsSuccess({
      calendars
    }))
  } catch (err) {
    yield put(fetchCalendarsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * updateCalendars (action) {
  try {
    const calendarIds = action.calendarIds
    const response = yield call(update, '/me/calendars', { selected_calendar_ids: calendarIds })
    const calendars = fromJS(response.data)

    yield put(updateCalendarsSuccess({
      calendars
    }))
  } catch (err) {
    yield put(updateCalendarsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchTeamLogs (action) {
  try {
    const { createdAt } = action
    const response = yield call(get, `/team/logs?created_at=${createdAt}`)
    const teamLogs = fromJS(response.data)

    yield put(fetchTeamLogsSuccess({
      teamLogs
    }))
  } catch (err) {
    yield put(fetchTeamLogsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchCampaignStepCopy () {
  try {
    const exportMessage = 'Exporting your campaign steps...'
    yield put(setNotification({
      type: NOTIFICATION_TYPES.LOADING,
      message: exportMessage
    }))
    const path = '/campaigns/steps/csv'

    const response = yield call(get, path)

    window.location.href = response.data.url

    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Export CSV of campaign steps'
    }))
  } catch (err) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchPurgeEmailCount (action) {
  try {
    const { email } = action
    const response = yield call(get, `/team/purge?email=${email}`)
    const purgeEmailCount = fromJS(response.data)

    if (purgeEmailCount === 0) {
      yield put(setNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: 'No contacts found for email'
      }))
    }
    yield put(fetchPurgeEmailCountSuccess({
      purgeEmailCount
    }))
    yield put(showPurgeModal())
  } catch (err) {
    yield put(fetchPurgeEmailCountFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * deletePurgeEmail (action) {
  try {
    const payload = { email: action.email }
    yield call(post, '/team/purge', payload)
    yield put(deletePurgeEmailSuccess())
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `${action.email} has been removed`
    }))
    yield put(hidePurgeModal())
  } catch (err) {
    yield put(deletePurgeEmailFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

function * fetchSessionAddressesRequest () {
  yield takeLatest(FETCH_SESSION_ADDRESSES_REQUEST, fetchSessionAddressses)
}

function * fetchTeamSettingsRequest () {
  yield takeLatest(FETCH_TEAM_SETTINGS_REQUEST, fetchTeamSettings)
}

function * updateTeamSettingsRequest () {
  yield takeLatest(UPDATE_TEAM_SETTINGS_REQUEST, updateTeamSettings)
}

function * fetchTeamDomainRequest () {
  yield takeLatest(FETCH_TEAM_DOMAIN_REQUEST, fetchTeamDomain)
}

function * fetchBlocklistRequest () {
  yield takeLatest(FETCH_BLOCKLIST_REQUEST, fetchBlocklist)
}

function * fetchTeamBlocklistRequest () {
  yield takeLatest(FETCH_TEAM_BLOCKLIST_REQUEST, fetchTeamBlocklist)
}

function * updateBlocklistRequest () {
  yield takeLatest(UPDATE_BLOCKLIST_REQUEST, updateBlocklist)
}

function * updateTeamBlocklistRequest () {
  yield takeLatest(UPDATE_TEAM_BLOCKLIST_REQUEST, updateTeamBlocklist)
}

function * fetchConfigRequest () {
  yield takeLatest(FETCH_CONFIG_REQUEST, fetchConfig)
}

function * fetchContactCSVRequest () {
  yield takeLatest(FETCH_CONTACT_CSV_REQUEST, fetchContactCSV)
}

function * resubscribeEmailRequest () {
  yield takeLatest(RESUBSCRIBE_EMAIL_REQUEST, resubscribeEmail)
}

function * fetchCalendarsRequest () {
  yield takeLatest(FETCH_CALENDARS_REQUEST, fetchCalendars)
}

function * updateCalendarsRequest () {
  yield takeLatest(UPDATE_CALENDARS_REQUEST, updateCalendars)
}

function * fetchTeamLogsRequest () {
  yield takeLatest(FETCH_TEAM_LOGS_REQUEST, fetchTeamLogs)
}

function * fetchCampaignStepCopyRequest () {
  yield takeLatest(FETCH_CAMPAIGN_STEP_COPY_REQUEST, fetchCampaignStepCopy)
}

function * fetchPurgeEmailCountRequest () {
  yield takeLatest(FETCH_PURGE_EMAIL_COUNT_REQUEST, fetchPurgeEmailCount)
}

function * deletePurgeEmailRequest () {
  yield takeLatest(DELETE_PURGE_EMAIL_REQUEST, deletePurgeEmail)
}

export default [
  fetchSessionAddressesRequest,
  fetchTeamSettingsRequest,
  updateTeamSettingsRequest,
  fetchTeamDomainRequest,
  fetchBlocklistRequest,
  fetchTeamBlocklistRequest,
  updateBlocklistRequest,
  updateTeamBlocklistRequest,
  fetchConfigRequest,
  resubscribeEmailRequest,
  fetchContactCSVRequest,
  fetchCalendarsRequest,
  updateCalendarsRequest,
  fetchTeamLogsRequest,
  fetchCampaignStepCopyRequest,
  fetchPurgeEmailCountRequest,
  deletePurgeEmailRequest
]
