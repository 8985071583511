import { createSelector } from 'reselect'

const selectTemplatesDomain = () => state => state.get('templates')

const selectTemplates = () => createSelector(
  selectTemplatesDomain(),
  templatesState => templatesState.get('templates')
)

export {
  selectTemplates
}
