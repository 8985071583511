import Immutable from 'immutable'
import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Loading from 'components/Loading'
import Integration from '../../../../Sequence/components/Settings/Integration'

const Wrapper = styled.div`
  padding: 0 1rem 1rem 1rem;
`

class CrmSettings extends Component {
  render () {
    const {
      session,
      loading,
      ownerCrm,
      setCrmSettings,
      crmSettings,
      refreshOwnerCrm
    } = this.props

    if (loading) {
      return <Loading />
    }

    if (ownerCrm.get('loading')) {
      return <Loading />
    }

    return (
      <Wrapper>
        <Integration
          sequence={crmSettings}
          crm={ownerCrm}
          onSettingUpdate={(params) => {
            setCrmSettings(params)
          }}
          onCRMRefresh={(params) => {
            refreshOwnerCrm(session?.get('id'), params)
          }}
        />
      </Wrapper>
    )
  }
}

CrmSettings.propTypes = {
  session: PropTypes.object,
  loading: PropTypes.bool,
  ownerCrm: PropTypes.instanceOf(Immutable.Map),
  setCrmSettings: PropTypes.func,
  crmSettings: PropTypes.instanceOf(Immutable.Map),
  refreshOwnerCrm: PropTypes.func
}

CrmSettings.defaultProps = {
  loading: false
}

export default CrmSettings
