import { fromJS } from 'immutable'
import R from 'utils/ramda'
import { put, takeLatest, call, takeLeading } from 'redux-saga/effects'
import api, { messageFromError } from 'utils/api'
import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'
import { pluralize } from 'utils/strings'
import {
  fetchSequenceState,
  refreshSequenceState
} from 'containers/Sequence/actions'
import {
  fetchTasksSuccess,
  fetchTasksFailure,
  fetchContactMessagesSuccess,
  fetchContactMessagesFailure,
  updateContactMessageSuccess,
  updateContactMessageFailure,
  fetchRepliedContactSuccess,
  fetchRepliedContactFailure,
  fetchContactErrorsSuccess,
  fetchContactErrorsFailure,
  fetchContactErrorsStatsSuccess,
  fetchContactErrorsStatsFailure,
  removeContactErrorsSuccess,
  fetchContactActionsSuccess,
  fetchContactActionsFailure,
  updateContactSuccess,
  updateContactFailure,
  removeContactSuccess,
  removeContactFailure,
  fetchSentimentScoreSuccess,
  fetchSentimentScoreFailure,
  updateContactSentimentFailure,
  fetchManualTasksSuccess,
  fetchManualTasksFailure,
  fetchContactTaskSuccess,
  fetchContactTaskFailure,
  updateContactTaskSuccess,
  updateContactTaskFailure,
  clearPauseContactTask,
  skipManualTaskSuccess,
  skipManualTaskFailure,
  skipManualTasksSuccess,
  skipManualTasksFailure
} from './actions'

import {
  updateContactSuccess as updateContactSuccessSequence
} from '../Sequence/actions'

import {
  FETCH_TASKS_REQUEST,
  FETCH_MESSAGES_REQUEST,
  FETCH_CONTACT_ERRORS_REQUEST,
  UPDATE_CONTACT_MESSAGE_REQUEST,
  FETCH_CONTACT_ERRORS_STATS_REQUEST,
  REMOVE_CONTACT_ERRORS_REQUEST,
  UPDATE_CONTACT_REQUEST,
  REMOVE_CONTACT_REQUEST,
  FETCH_CONTACT_ACTIONS_REQUEST,
  UPDATE_CONTACT_ERRORS_REQUEST,
  FETCH_REPLIED_CONTACT_REQUEST,
  FETCH_SENTIMENT_SCORE_REQUEST,
  UPDATE_CONTACT_SENTIMENT_REQUEST,
  FETCH_MANUAL_TASKS_REQUEST,
  FETCH_CONTACT_TASK_REQUEST,
  UPDATE_CONTACT_TASK_REQUEST,
  SKIP_MANUAL_TASK_REQUEST,
  SKIP_MANUAL_TASKS_REQUEST
} from './constants'

const get = (url, query) => api.get(url, {
  params: query
})
const post = (url, params) => api.post(url, params)
const update = (url, params) => api.put(url, params)
const remove = url => api.delete(url)

export function * fetchTasks (action) {
  const {
    userId,
    sequenceId,
    step
  } = action

  try {
    const response = yield call(get, '/messages/queue', {
      user_id: userId,
      campaign_id: sequenceId,
      step
    })
    const tasks = fromJS(response.data)

    yield put(fetchTasksSuccess({
      tasks
    }))
  } catch (err) {
    yield put(fetchTasksFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchContactErrors (action) {
  const {
    sort,
    skip = 0,
    limit = 50,
    error
  } = action

  const paginating = skip > 0

  const params = {
    filter: 'all_errors',
    sort,
    skip,
    limit,
    error
  }
  try {
    const response = yield call(get, '/contacts', params)
    const contacts = fromJS(response.data)
    const hasMore = contacts.count() === limit
    yield put(fetchContactErrorsSuccess({
      contacts,
      paginating,
      hasMore
    }))
  } catch (err) {
    yield put(fetchContactErrorsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchContactErrorsStats () {
  try {
    const response = yield call(get, '/contacts/errors')
    const stats = fromJS(response.data)

    yield put(fetchContactErrorsStatsSuccess({
      stats
    }))
  } catch (err) {
    yield put(fetchContactErrorsStatsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * removeContactErrors (action) {
  const {
    contactIds,
    error,
    all
  } = action
  try {
    let ids = fromJS(contactIds)
    if (all) {
      const path = error
        ? `/contacts?fields=['_id']&filter=all_errors&error=${error}`
        : '/contacts?fields=[\'_id\']&filter=all_errors'
      const response = yield call(get, path)
      ids = fromJS(response.data).map(c => c.get('_id')).filter(id => !ids.includes(id))
    }
    const params = { ids }
    yield call(post, '/contacts/remove', params)
    yield put(removeContactErrorsSuccess({ contactIds: ids }))
    // Update Stats
    yield call(fetchContactErrorsStats)
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Removed ${ids.count()} ${pluralize('contact', 'contacts', ids.count())}! 🚀`
    }))
  } catch (err) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * updateContactErrors (action) {
  const {
    contactIds,
    error,
    all,
    data
  } = action
  try {
    let ids = fromJS(contactIds)
    if (all) {
      const path = error
        ? `/contacts?fields=['_id']&filter=all_errors&error=${error}`
        : '/contacts?fields=[\'_id\']&filter=all_errors'
      const response = yield call(get, path)
      ids = fromJS(response.data).map(c => c.get('_id'))
    }

    if (ids.count() > 100) {
      yield put(setNotification({
        type: NOTIFICATION_TYPES.LOADING,
        message: 'Updating contacts...'
      }))
    }

    if (error) {
      yield call(post, '/contacts/errors/clear', { ids, errors: [error] })
    } else {
      yield call(post, '/contacts/update', { ids, data })
    }

    yield put(removeContactErrorsSuccess({ contactIds: ids }))
    // Update Stats
    yield call(fetchContactErrorsStats)
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Errors cleared on ${ids.count()} ${pluralize('contact', 'contacts', ids.count())}! 🚀`
    }))
  } catch (err) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchContactActions (action) {
  let query = {}

  // Build query
  if (!R.isEmpty(action.query)) {
    query = action.query
  }

  const contactId = action.contactId
  const path = `/contacts/${contactId}/actions?aggregate=true&clean=true`
  try {
    const response = yield call(get, path, query)
    const actions = fromJS(response.data)

    yield put(fetchContactActionsSuccess({
      actions
    }))
  } catch (error) {
    yield put(fetchContactActionsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContactMessages (action) {
  let query = {}

  // Build query
  if (!R.isEmpty(action.query)) {
    query = action.query
  }

  const contactId = action.contactId
  const path = `/messages/contact/${contactId}`
  try {
    const response = yield call(get, path, query)
    const messages = fromJS(response.data)

    yield put(fetchContactMessagesSuccess({
      messages
    }))
  } catch (error) {
    yield put(fetchContactMessagesFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * updateContactMessage (action) {
  const {
    contactId,
    step,
    params
  } = action
  try {
    const response = yield call(post, `/messages/contact/${contactId}/step/${step}`, params)
    const message = fromJS(response.data)
    yield put(updateContactMessageSuccess({
      message,
      contactId,
      step
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Personalized message for contact! 🚀'
    }))
  } catch (error) {
    yield put(updateContactMessageFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * updateContact (action) {
  const {
    contactId,
    sequenceId,
    data
  } = action
  try {
    const response = yield call(update, `/contacts/${contactId}`, data)
    const contact = fromJS(response.data)
    yield put(updateContactSuccess({ // tasks action: removes from list
      contact
    }))
    yield put(updateContactSuccessSequence({ // sequence action: updates status in the sequence list
      contact
    }))
    yield put(refreshSequenceState(sequenceId)) // sequence action: refreshes task counter chip
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Contact saved! 🚀'
    }))
    yield put(clearPauseContactTask())
    yield call(fetchContactErrorsStats)
  } catch (error) {
    yield put(updateContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * removeContact (action) {
  const {
    contactId
  } = action
  try {
    yield call(remove, `/contacts/${contactId}`)
    yield put(removeContactSuccess({
      contactId
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Removed contact! 🚀'
    }))
    yield call(fetchContactErrorsStats)
  } catch (error) {
    yield put(removeContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchRepliedContact (action) {
  let contacts = []

  const params = (action && action.userId !== 'undefined')
    ? { user: action.userId }
    : {}
  const path = '/contacts/sentiment/queue'

  try {
    const response = yield call(get, path, params)
    contacts = fromJS(response.data)
    yield put(fetchRepliedContactSuccess({
      contacts
    }))
  } catch (error) {
    yield put(fetchRepliedContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchSentimentScore (action) {
  let sentimentScore = {}

  const { contactId } = action
  const path = `/contacts/${contactId}/actions/sentiment/guess`

  try {
    const response = yield call(get, path)
    sentimentScore = fromJS(response.data)
    yield put(fetchSentimentScoreSuccess({
      sentimentScore
    }))
  } catch (error) {
    yield put(fetchSentimentScoreFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

function * updateContactSentiment (action) {
  const { sentimentAction, contactId, userId } = action
  const params = { sentiment: sentimentAction }
  const path = `/contacts/${contactId}/sentiment`
  try {
    yield call(update, path, params)
    yield fetchRepliedContact({
      userId
    })
  } catch (error) {
    yield put(updateContactSentimentFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchManualTasks (action) {
  const {
    userId,
    sequenceId,
    step
  } = action

  try {
    const response = yield call(get, '/tasks/queue', {
      user_id: userId,
      campaign_id: sequenceId,
      step
    })
    const manualTasks = fromJS(response.data)

    yield put(fetchManualTasksSuccess({
      manualTasks
    }))
  } catch (err) {
    yield put(fetchManualTasksFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchContactTask (action) {
  const {
    contactId,
    stepIndex
  } = action

  const path = `/tasks/contact/${contactId}/step/${stepIndex}`
  try {
    const response = yield call(get, path)
    const contactTask = fromJS(response.data)

    yield put(fetchContactTaskSuccess({
      contactTask
    }))
  } catch (error) {
    yield put(fetchContactTaskFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * updateContactTask (action) {
  const {
    contactId,
    stepIndex,
    markdown,
    completed = false,
    replied = false,
    sequenceId
  } = action

  const params = {
    markdown,
    completed,
    replied
  }

  const path = `/tasks/contact/${contactId}/step/${stepIndex}`
  try {
    const response = yield call(post, path, params)
    const contactTask = fromJS(response.data)

    yield put(updateContactTaskSuccess({
      contactTask,
      contactId
    }))

    if (contactTask.get('completed_at')) {
      yield put(setNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: 'Task completed ✅'
      }))
    }

    if (sequenceId) {
      yield put(fetchSequenceState(sequenceId))
    }
  } catch (error) {
    yield put(updateContactTaskFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * skipManualTask (action) {
  const {
    contactId,
    stepIndex,
    sequenceId
  } = action
  try {
    const path = `/tasks/contact/${contactId}/step/${stepIndex}/skip`
    yield call(post, path)
    yield put(skipManualTaskSuccess({
      contactId
    }))
    yield put(fetchSequenceState(sequenceId))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Task skipped ✅'
    }))
  } catch (error) {
    yield put(skipManualTaskFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * skipManualTasks (action) {
  const {
    contactIds
  } = action
  try {
    yield call(post, '/tasks/skip', { contactIds })
    yield put(skipManualTasksSuccess({
      contactIds
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `${contactIds.length} ${pluralize('task', 'tasks', contactIds.length)} skipped ✅`
    }))
  } catch (error) {
    yield put(skipManualTasksFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

function * fetchTasksRequest () {
  yield takeLatest(FETCH_TASKS_REQUEST, fetchTasks)
}

function * fetchContactMessagesRequest () {
  yield takeLatest(FETCH_MESSAGES_REQUEST, fetchContactMessages)
}

function * fetchContactErrorsRequest () {
  yield takeLatest(FETCH_CONTACT_ERRORS_REQUEST, fetchContactErrors)
}

function * fetchContactErrorsStatsRequest () {
  yield takeLatest(FETCH_CONTACT_ERRORS_STATS_REQUEST, fetchContactErrorsStats)
}

function * fetchContactActionsRequest () {
  yield takeLatest(FETCH_CONTACT_ACTIONS_REQUEST, fetchContactActions)
}

function * removeContactErrorsRequest () {
  yield takeLatest(REMOVE_CONTACT_ERRORS_REQUEST, removeContactErrors)
}

function * updateContactErrorsRequest () {
  yield takeLatest(UPDATE_CONTACT_ERRORS_REQUEST, updateContactErrors)
}

function * updateContactMessagesRequest () {
  yield takeLatest(UPDATE_CONTACT_MESSAGE_REQUEST, updateContactMessage)
}

function * updateContactRequest () {
  yield takeLatest(UPDATE_CONTACT_REQUEST, updateContact)
}

function * removeContactRequest () {
  yield takeLatest(REMOVE_CONTACT_REQUEST, removeContact)
}

function * fetchRepliedContactRequest () {
  yield takeLatest(FETCH_REPLIED_CONTACT_REQUEST, fetchRepliedContact)
}

function * fetchSentimentScoreRequest () {
  yield takeLatest(FETCH_SENTIMENT_SCORE_REQUEST, fetchSentimentScore)
}

function * updateContactSentimentRequest () {
  yield takeLatest(UPDATE_CONTACT_SENTIMENT_REQUEST, updateContactSentiment)
}

function * fetchManualTasksRequest () {
  yield takeLatest(FETCH_MANUAL_TASKS_REQUEST, fetchManualTasks)
}

function * fetchContactTaskRequest () {
  yield takeLatest(FETCH_CONTACT_TASK_REQUEST, fetchContactTask)
}

function * updateContactTaskRequest () {
  yield takeLeading(UPDATE_CONTACT_TASK_REQUEST, updateContactTask)
}

function * skipManualTaskRequest () {
  yield takeLatest(SKIP_MANUAL_TASK_REQUEST, skipManualTask)
}

function * skipManualTasksRequest () {
  yield takeLatest(SKIP_MANUAL_TASKS_REQUEST, skipManualTasks)
}

export default [
  fetchTasksRequest,
  fetchRepliedContactRequest,
  fetchContactMessagesRequest,
  fetchContactErrorsStatsRequest,
  fetchContactActionsRequest,
  fetchSentimentScoreRequest,
  updateContactMessagesRequest,
  fetchContactErrorsRequest,
  removeContactErrorsRequest,
  updateContactRequest,
  removeContactRequest,
  updateContactErrorsRequest,
  updateContactSentimentRequest,
  fetchManualTasksRequest,
  fetchContactTaskRequest,
  updateContactTaskRequest,
  skipManualTaskRequest,
  skipManualTasksRequest
]
