import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import DropDown from 'components/DropDown'
import Input from 'components/Input'
import Loading from 'components/Loading'
import SwitchFieldForm from 'components/SwitchFieldForm'
import styled from 'styled-components'

const Container = styled.div`
  padding: 0 3rem 2rem 3rem;
`

class CreateSequence extends Component {
  constructor (props) {
    super(props)
    this.state = {
      shared: 'rw'
    }
  }

  render () {
    const {
      userOptions,
      isManager,
      session,
      loading,
      shared,
      title,
      userId,
      setCreateInfo,
      fetchOwnerCrm
    } = this.props

    if (loading) {
      return <Loading />
    }

    return (
      <Container>
        <Input
          autoFocus
          innerRef={(e) => { this.name = e }}
          label='Sequence Name'
          description='Choose a name for your sequence that best describes it. We recommend having very specific sequences with three identifiable characteristics (e.g. Engineers, NYC, Interseller).'
          inputId='title'
          onValueChange={(value) => {
            setCreateInfo({ title: value })
          }}
          value={title}
          mt='1.5rem'
        />
        {userOptions.count() > 0 && isManager &&
          <DropDown
            label='Owner/Sender'
            description='The owner of the sequence is also the sender. You cannot change this after the sequence has been created.'
            inputId='user_id'
            onOptionChange={(option) => {
              if (option && option.value !== session.get('id')) {
                setCreateInfo({ user_id: option.value })
                setCreateInfo({ shared: 'rw' })
                fetchOwnerCrm(option.value)
              } else {
                setCreateInfo({ user_id: session.get('id') })
                fetchOwnerCrm(session.get('id'))
              }
            }}
            options={userOptions}
            clearable={false}
            value={userId}
            mt='1.5rem'
          />}
        <SwitchFieldForm
          label='Share with team'
          value={(shared === 'rw')}
          description='Team will be able to add contacts and modify steps'
          inputId='shared'
          onSave={(value) => {
            if (value) {
              setCreateInfo({ shared: 'rw' })
            } else {
              setCreateInfo({ shared: null })
            }
          }}
          mt='2rem'
          mb='1rem'
        />
      </Container>
    )
  }
}

CreateSequence.propTypes = {
  userOptions: PropTypes.object,
  isManager: PropTypes.bool,
  session: PropTypes.instanceOf(Immutable.Map),
  loading: PropTypes.bool,
  shared: PropTypes.string,
  title: PropTypes.string,
  userId: PropTypes.string,
  setCreateInfo: PropTypes.func,
  fetchOwnerCrm: PropTypes.func
}

CreateSequence.defaultProps = {
  loading: false
}

export default CreateSequence
