import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import HeatMapWidget from '../../HeatMapWidget'

const Wrapper = styled.div`
  width: 100%;
  max-width: 100%;
  height: calc(100% - 1rem);
  padding-left: 1rem;
`

class ActivityReport extends React.Component {
  parseOpenHoursReports = openHoursReports => {
    const openReportsData = openHoursReports.get('data')
    // could be 'me' or '[campaignId]'
    const openReportsMemberKeys = Object.keys(openReportsData.toObject())

    let openTotal = 0
    const openData = openReportsMemberKeys.reduce((acc, key) => {
      const data = openReportsData.getIn([key, 'opens', 'hourofday'])
      openTotal += openReportsData.getIn([key, 'opens', 'total'])
      if (data) {
        Object.keys(data.toObject()).forEach((d) => {
          acc[d] = (acc[d] || 0) + data.get(d)
        })
      }
      return acc
    }, {})

    return { openTotal, openData }
  }

  parseReplyHoursReports = replyHoursReports => {
    const replyReportsData = replyHoursReports.get('data')
    const replyReportsMemberKeys = Object.keys(replyReportsData.toObject())

    let replyTotal = 0
    const replyData = replyReportsMemberKeys.reduce((acc, key) => {
      const data = replyReportsData.getIn([key, 'replies', 'hourofday'])
      replyTotal += replyReportsData.getIn([key, 'replies', 'total'])
      if (data) {
        Object.keys(data.toObject()).forEach((d) => {
          acc[d] = (acc[d] || 0) + data.get(d)
        })
      }
      return acc
    }, {})

    return { replyTotal, replyData }
  }

  render () {
    const {
      theme,
      openHoursReports,
      replyHoursReports
    } = this.props

    // load in open hours report data if report had finished downloading
    let openTotal = null
    let openData = null
    const openHoursLoading = (!openHoursReports || !!openHoursReports) && openHoursReports.get('loading')
    if (!openHoursLoading) {
      ({ openData, openTotal } = this.parseOpenHoursReports(openHoursReports))
    }

    // load in reply hours report data if report had finished downloading
    let replyTotal = null
    let replyData = null
    const replyHoursLoading = (!replyHoursReports || !!replyHoursReports) && replyHoursReports.get('loading')
    if (!replyHoursLoading) {
      ({ replyData, replyTotal } = this.parseReplyHoursReports(replyHoursReports))
    }

    return (
      <Wrapper>
        <HeatMapWidget
          theme={theme}
          replyData={replyData}
          replyTotal={replyTotal}
          openData={openData}
          openTotal={openTotal}
          isOpenHoursReportsLoading={openHoursLoading}
          isReplyHoursReportsLoading={replyHoursLoading}
        />
      </Wrapper>
    )
  }
}

ActivityReport.propTypes = {
  theme: PropTypes.object,
  router: PropTypes.object,
  openHoursReports: PropTypes.object,
  replyHoursReports: PropTypes.object
}

export default ActivityReport
