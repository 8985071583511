import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import Immutable from 'immutable'
import StatusToggle from 'components/StatusToggle'
import { findBrokenRules } from './utils/rules-engine'
import { debounce } from 'utils/browser'
import {
  BodyEditor,
  SubjectEditor,
  SignaturePreview,
  Toolbar
} from 'components/SlateEditor/components'
import {
  toMarkdown,
  toHtml
} from 'components/SlateEditor/utils/showdown'
import {
  slateConstants,
  slateParsingRules
} from 'components/SlateEditor/utils/slate'
import {
  FIELD_BLOCKLIST,
  TOP_LEVEL_FIELD_VALUES
} from 'containers/Sequence/constants'

const Wrapper = styled.div`
  width: 100%;
`

const EditorWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  width: 100%;
  text-align: left;
  font: 300 15px soleil, sans-serif;
  color: ${props => props.theme.colors.darkBlue};
`

const SubjectLine = styled.div`
  position: relative;
  display: flex;
  flex-direction: row;
  width: 100%;
  /* subject line top & bottom padding + line height + border padding */
  min-height: calc(1rem + 1rem + 1.5em + 1px);
  background-color: ${props => props.theme.colors.white};
  overflow: auto;
  padding: 0;
  margin: 0;
`

const StatusBar = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid ${props => props.theme.colors.gray20};
  background-color: ${props => !props.threadLabel || props.threadLabel === 'new'
    ? props.theme.colors.white
    : props.theme.colors.gray10
  };
  margin: 0;
  padding-left: 0.5rem;
  padding-right: 1.25rem;
`

const EditorCanvasWrapper = styled.div``

const subjectSerializer = slateParsingRules.subjectRules()
const bodySerializer = slateParsingRules.bodyRules()

class SlateEditor extends React.Component {
  constructor (props) {
    super(props)

    const subjectHtml = toHtml(slateConstants.SLATE_EDITORS.SUBJECT, props.subject)
    const bodyHtml = toHtml(slateConstants.SLATE_EDITORS.BODY, props.body)
    this.subjectEditorRef = React.createRef()
    this.bodyEditorRef = React.createRef()

    this.state = {
      subjectText: subjectSerializer.deserialize(subjectHtml) || '',
      bodyText: bodySerializer.deserialize(bodyHtml) || '',
      activeEditorName: slateConstants.SLATE_EDITORS.SUBJECT,
      activeEditorRef: this.subjectEditorRef,
      activeEditorSelection: null,
      menuVisibility: {
        showEmojiMenu: false,
        showCustomFieldMenu: false,
        showEllipsesMenu: false,
        showSendTestEmailMenu: false,
        showAttachmentsMenu: false,
        showUnsubscribeMenu: false,
        showVideoLinkMenu: false,
        showLinkMenu: slateConstants.LINK_MENUS.NONE,
        showImageMenu: slateConstants.IMAGE_MENUS.NONE,
        showSaveActionsMenu: false
      },
      buttonsActive: {
        isUnderlineButtonActive: false,
        isBoldButtonActive: false,
        isItalicButtonActive: false,
        isEmojiButtonActive: false,
        isLinkButtonActive: false,
        isOrderedListButtonActive: false,
        isUnorderedListButtonActive: false,
        isCustomFieldButtonActive: false
      },
      brokenRules: []
    }
  }

  componentDidMount () {
    // run rules engine when component first mounts
    this.checkRules()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const prevProps = this.props

    const prevSubject = prevProps.subject
    const nextSubject = nextProps.subject
    if (nextSubject !== prevSubject) {
      const subjectHtml = toHtml(slateConstants.SLATE_EDITORS.SUBJECT, nextSubject)
      this.setState({
        subjectText: subjectSerializer.deserialize(subjectHtml)
      })
    }

    const prevBody = prevProps.body
    const nextBody = nextProps.body
    if (nextBody !== prevBody) {
      const bodyHtml = toHtml(slateConstants.SLATE_EDITORS.BODY, nextBody)
      this.setState({
        bodyText: bodySerializer.deserialize(bodyHtml)
      })
    }
  }

  getMarkdown = (slateEditorType) => {
    const { subjectDisabled, hideSubjectLine } = this.props
    const { subjectText, bodyText } = this.state

    if (slateEditorType === slateConstants.SLATE_EDITORS.SUBJECT && (subjectDisabled || hideSubjectLine)) {
      return
    }
    let html = ''
    if (slateEditorType === slateConstants.SLATE_EDITORS.SUBJECT) {
      html = subjectSerializer.serialize(subjectText)
    } else if (slateEditorType === slateConstants.SLATE_EDITORS.BODY) {
      html = bodySerializer.serialize(bodyText)
    } else {
      return
    }
    return toMarkdown(slateEditorType, html)
  }

  getSubjectEditorColor = () => {
    const { readOnly, hideSubjectLine, theme } = this.props
    const { activeEditorName } = this.state
    if (!hideSubjectLine && activeEditorName === slateConstants.SLATE_EDITORS.SUBJECT) {
      return theme.colors.blueGray
    }
    if (readOnly) {
      return theme.colors.blueGray
    }
    return theme.colors.darkBlue
  }

  setEditorText = (text, slateEditorType) => {
    if (slateEditorType === slateConstants.SLATE_EDITORS.SUBJECT) {
      this.setState({ subjectText: text })
    } else if (slateEditorType === slateConstants.SLATE_EDITORS.BODY) {
      this.setState({ bodyText: text })
    }
  }

  setActiveEditor = (slateEditorType) => {
    if (slateEditorType === slateConstants.SLATE_EDITORS.SUBJECT) {
      this.setState({
        activeEditorName: slateEditorType,
        activeEditorRef: this.subjectEditorRef,
        buttonsActive: {
          isUnderlineButtonActive: false,
          isBoldButtonActive: false,
          isItalicButtonActive: false,
          isEmojiButtonActive: false,
          isOrderedListButtonActive: false,
          isUnorderedListButtonActive: false,
          isCustomFieldButtonActive: false
        }
      })
    } else if (slateEditorType === slateConstants.SLATE_EDITORS.BODY) {
      this.setState({
        activeEditorName: slateEditorType,
        activeEditorRef: this.bodyEditorRef,
        buttonsActive: {
          isUnderlineButtonActive: false,
          isBoldButtonActive: false,
          isItalicButtonActive: false,
          isEmojiButtonActive: false,
          isOrderedListButtonActive: false,
          isUnorderedListButtonActive: false,
          isCustomFieldButtonActive: false
        }
      })
    }
  }

  setToolbarButtonActivity = (activity) => {
    if (activity) {
      this.setState(prevState => ({
        buttonsActive: {
          ...prevState.buttonsActive,
          ...activity
        }
      }))
    }
  }

  setToolbarMenuVisibility = (modal, visibility) => {
    if (modal && visibility !== null) {
      // new object force set state to update due to `menuVisibility` nesting
      this.setState(prevState => ({
        menuVisibility: {
          ...prevState.menuVisibility,
          [modal]: visibility
        }
      }))
    }
  }

  toggleToolbarMenuVisibility = (toolbarModal) => {
    this.setState(prevState => ({
      menuVisibility: {
        ...prevState.menuVisibility,
        [toolbarModal]: !prevState.menuVisibility[toolbarModal]
      }
    }))
  }

  checkRules = () => {
    const { hideEditorCoach } = this.props
    if (hideEditorCoach) {
      return
    }

    const markDown = this.getMarkdown(slateConstants.SLATE_EDITORS.BODY)
    const html = bodySerializer.serialize(this.state.bodyText)
    const subjectString = this.subjectEditorRef?.current?.getTextAsFlatString()
    const bodyString = this.bodyEditorRef?.current?.getTextAsFlatString()
    const steps = this.props.steps
    const selectedStepIndex = this.props.selectedStepIndex

    Promise.resolve(findBrokenRules(
      markDown,
      html,
      subjectString,
      bodyString,
      steps,
      selectedStepIndex
    )).then(brokenRules => {
      this.setState({ brokenRules })
    })
  }

  debouncedCheckRules = debounce(() => this.checkRules(), 500, false)

  copyToClipboard = () => {
    if (!this.bodyEditorRef || !this.bodyEditorRef.current) {
      return
    }

    const bodyText = this.bodyEditorRef?.current?.getTextAsFlatString()
    return navigator.clipboard.writeText(bodyText)
  }

  render () {
    const {
      selectedStep,
      signature,
      isSignatureDisabled,
      showSignature,
      onToggleSignature
    } = this.props

    const fields = []
    const sequenceFields = Immutable.List([])
      .concat(TOP_LEVEL_FIELD_VALUES)
      .concat(this.props.customFields)

    sequenceFields
      .filter(f => !FIELD_BLOCKLIST.includes(f) && f)
      .filter(f => !TOP_LEVEL_FIELD_VALUES.includes(f) && f)
      .forEach(field => {
        fields.push({
          searchName: field,
          variableName: `{{ ${field} }}`
        })
      })

    return (
      <Wrapper>
        <EditorWrapper>
          {!this.props.hideToolbar &&
            <Toolbar
              actions={this.props.actions}
              theme={this.props.theme}
              onSave={this.props.onSave}
              onSaveLabel={this.props.onSaveLabel}
              activeEditorName={this.state.activeEditorName}
              activeEditorRef={this.state.activeEditorRef}
              getBodyMarkdown={() => this.getMarkdown(slateConstants.SLATE_EDITORS.BODY)}
              getSubjectMarkdown={() => this.getMarkdown(slateConstants.SLATE_EDITORS.SUBJECT)}
              onSaveTemplate={this.props.onSaveTemplate}
              onShowTemplates={this.props.onShowTemplates}
              renderSendTestEmail={this.props.renderSendTestEmail || null}
              session={this.props.session}
              onSendTestEmail={this.props.onSendTestEmail}
              isBrandedDomain={this.props.isBrandedDomain}
              onRemove={this.props.onRemove}
              hideVariables={this.props.hideVariables}
              hideSubjectLine={this.props.hideSubjectLine}
              hideEditorCoach={this.props.hideEditorCoach}
              setLinkMenu={this.setLinkMenu}
              readOnly={this.props.readOnly}
              customFields={fields}
              warningMsg={this.props.warningMsg}
              hideTemplates={this.props.hideTemplates}
              saveActions={this.props.saveActions}
              toggleToolbarMenuVisibility={this.toggleToolbarMenuVisibility}
              setToolbarMenuVisibility={this.setToolbarMenuVisibility}
              menuVisibility={this.state.menuVisibility}
              buttonsActive={this.state.buttonsActive}
              getSubjectEditorColor={this.getSubjectEditorColor}
              brokenRules={this.state.brokenRules}
              showSignature={showSignature}
              onToggleSignature={onToggleSignature}
              isSignatureDisabled={isSignatureDisabled}
              needsSaving={this.props.needsSaving}
            />}

          <EditorCanvasWrapper>
            {!this.props.hideSubjectLine &&
              <SubjectLine>
                <SubjectEditor
                  theme={this.props.theme}
                  hasEnteredKeyStroke={this.props.hasEnteredKeyStroke}
                  readOnly={this.props.readOnly || this.props.subjectDisabled}
                  grayedOut={!this.props.readOnly && this.props.subjectDisabled}
                  subject={this.state.subjectText}
                  setActiveEditor={this.setActiveEditor}
                  setEditorText={this.setEditorText}
                  subjectEditorRef={this.subjectEditorRef}
                  debouncedCheckRules={this.debouncedCheckRules}
                  placeholder={this.props.subjectPlaceholder}
                />
                {this.props.threadLabel &&
                  !this.props.readOnly &&
                    <StatusBar threadLabel={this.props.threadLabel}>
                      <StatusToggle
                        leftLabel='New Thread'
                        rightLabel='Reply'
                        leftValue='new'
                        rightValue='reply'
                        value={this.props.threadLabel}
                        handleOnToggle={this.props.toggleThread}
                      />
                    </StatusBar>}
              </SubjectLine>}
            <BodyEditor
              bodyEditorRef={this.bodyEditorRef}
              body={this.state.bodyText}
              placeholder={this.props.bodyPlaceholder}
              setEditorText={this.setEditorText}
              setActiveEditor={this.setActiveEditor}
              setLinkMenu={this.setLinkMenu}
              setToolbarButtonActivity={this.setToolbarButtonActivity}
              setToolbarMenuVisibility={this.setToolbarMenuVisibility}
              menuVisibility={this.state.menuVisibility}
              hasEnteredKeyStroke={this.props.hasEnteredKeyStroke}
              ogp={Immutable.Map(this.props.ogp)}
              actions={this.props.actions}
              backgroundColor={this.props.backgroundColor}
              readOnly={this.props.readOnly}
              customFields={this.props.customFields}
              copyToClipboard={this.copyToClipboard}
              renderOverlayMenu={this.props.renderOverlayMenu}
              linkedInProfileUrl={this.props.linkedInProfileUrl}
              debouncedCheckRules={this.debouncedCheckRules}
              showSignature={this.props.showSignature}
            />
          </EditorCanvasWrapper>
          {showSignature && !isSignatureDisabled &&
            <SignaturePreview
              signature={signature}
              selectedStep={selectedStep}
            />}
        </EditorWrapper>
      </Wrapper>
    )
  }
}

SlateEditor.propTypes = {
  // standard
  theme: PropTypes.object,
  session: PropTypes.object,

  // toolbar send test email props
  onSendTestEmail: PropTypes.func,

  // template props
  onShowTemplates: PropTypes.func,
  onSaveTemplate: PropTypes.func,

  // toolbar state props
  onSave: PropTypes.func,
  onSaveLabel: PropTypes.string,
  onRemove: PropTypes.func,
  hasEnteredKeyStroke: PropTypes.func,
  isBrandedDomain: PropTypes.bool,

  // overlay menu props
  renderOverlayMenu: PropTypes.bool,
  linkedInProfileUrl: PropTypes.string,

  // thread state
  toggleThread: PropTypes.func,
  threadLabel: PropTypes.string,

  // email tasks
  readOnly: PropTypes.bool,
  hideToolbar: PropTypes.bool,
  hideSubjectLine: PropTypes.bool,
  hideVariables: PropTypes.bool,
  hideEditorCoach: PropTypes.bool,
  bodyPlaceholder: PropTypes.string,
  subjectPlaceholder: PropTypes.string,

  // Templates List
  hideTemplates: PropTypes.bool,

  // add
  subject: PropTypes.string,
  body: PropTypes.string,
  subjectDisabled: PropTypes.bool,
  warningMsg: PropTypes.string,
  ogp: PropTypes.instanceOf(Immutable.Map),
  actions: PropTypes.any,
  backgroundColor: PropTypes.string,
  renderSendTestEmail: PropTypes.func,
  saveActions: PropTypes.func,
  customFields: PropTypes.object,

  // for editor coach
  steps: PropTypes.instanceOf(Immutable.List),
  selectedStepIndex: PropTypes.number,

  showSignature: PropTypes.bool,
  signature: PropTypes.string,
  selectedStep: PropTypes.object,
  onToggleSignature: PropTypes.func,
  isSignatureDisabled: PropTypes.bool,

  // for editing
  needsSaving: PropTypes.func
}

SlateEditor.defaultProps = {
  hideToolbar: false,
  subjectDisabled: false,
  hideSubjectLine: false
}

export default withTheme(SlateEditor)
