import React, { useEffect, useRef } from 'react'
import PropTypes from 'prop-types'

const DropArea = ({ children, accept, onChange, onDrop, onDragOver, onDragAway }) => {
  // reference to file selector
  const inputFileRef = useRef(null)

  // reference to drop area
  const dropAreaRef = useRef(null)

  useEffect(() => {
    mountEventListeners()
  })

  const mountEventListeners = () => {
    const dropArea = dropAreaRef.current

    if (dropArea) {
      // prevent default behavior for all event listeners
      ['dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, preventDefaults, false)
      });

      // drag over event listeners
      ['dragenter', 'dragover'].forEach(eventName => {
        dropArea.addEventListener(eventName, handleOnDragOver, false)
      });

      // drag away event listeners
      ['dragleave', 'drop'].forEach(eventName => {
        dropArea.addEventListener(eventName, handleOnDragAway, false)
      })

      // drop event listener
      dropArea.addEventListener('drop', handleOnDrop, false)

      // manually triggering the onchange event in an input tag
      dropArea.addEventListener('click', manuallyTriggerOnChange, false)
    }
  }

  const preventDefaults = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  const manuallyTriggerOnChange = () => {
    const inputTag = inputFileRef.current
    const onChangeEvent = new window.MouseEvent('click')
    inputTag.addEventListener('change', handleOnChange, false)
    inputTag.dispatchEvent(onChangeEvent)
  }

  const handleOnChange = event => {
    if (onChange) {
      onChange(event)
    }
  }

  const handleOnDrop = event => {
    if (onDrop) {
      onDrop(event)
    }
  }

  const handleOnDragOver = event => {
    if (onDragOver) {
      onDragOver(event)
    }
  }

  const handleOnDragAway = event => {
    if (onDragAway) {
      onDragAway(event)
    }
  }

  return (
    <div ref={dropAreaRef} key='drop-area'>
      <input
        ref={inputFileRef}
        style={{ display: 'none' }}
        id='file-uploader'
        accept={accept}
        type='file'
      />
      {children}
    </div>
  )
}

DropArea.propTypes = {
  children: PropTypes.any,
  accept: PropTypes.string,
  onChange: PropTypes.func,
  onDrop: PropTypes.func,
  onDragOver: PropTypes.func,
  onDragAway: PropTypes.func
}

export default DropArea
