import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { withRouter } from 'react-router'
import styled, { withTheme } from 'styled-components'
import Sticky from 'react-stickynode'
import Button from 'components/Button'
import Loading from 'components/Loading'
import SidePanel from 'components/SidePanel'
import Modal from 'components/Modal'
import ConfirmModal from 'components/ConfirmModal'
import SequencePickerModal from 'components/SequencePickerModal'
import VirtualTable from 'components/VirtualTable'
import MessagePreview from 'components/MessagePreview'
import { pluralize } from 'utils/strings'
import { apiBaseURI } from 'utils/api'
import ContactToolbar from './ContactToolbar'
import HeaderRowRenderer from './HeaderRowRenderer'
import ContactRowRenderer from './ContactRowRenderer'
import EmptyState from './EmptyState'
import Stats from './Stats'
import Wrapper from './Wrapper'
import FixEmailModal from './FixEmailModal'
import BlockContactModal from './BlockContactModal'
import AssignSentiment from './AssignSentiment'
import ExportPdfModal from './ExportPdfModal'
import PauseModal from './PauseModal'
import { ERROR_TYPE } from 'containers/Sequence/constants'
import {
  SingleContactErrorModal,
  GroupContactErrorModal
} from './ErrorModal'
import ReplyTypeModal from './ReplyTypeModal'
import ContactInaccuracyModal from './ContactInaccuracyModal'

const TableWrapper = styled.div`
  background: ${props => props.theme.colors.white};
  border-left: 0;
  border-right: 0;
  color: #FFF;
  transition: background-color 0.3s ease, color .3s ease;
  position: relative;
  height: 100%;
  width: 100%;
  margin-top: 2rem;
  flex: 1;
`

const ContactTableWrapper = styled.div`
  background-color: ${props => props.theme.background};
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1;
  overflow-x: auto;
`

const LoadingContainer = styled.div`
  background-color: ${props => props.theme.colors.white};
  height: 100%;
  width: 100%;
`

const Danger = styled.span`
  color: ${props => props.theme.colors.red};
`

class Contacts extends Component {
  constructor (props) {
    super(props)
    this.lastSelectedIndex = null
    this.state = {
      selectedContacts: [],
      singleViewedContact: null,
      allSelected: false,
      deselectedContacts: [],
      showDeleteConfirmModal: false,
      showMoveModal: false,
      showCopyModal: false,
      showSingleContactErrorModal: false,
      showGroupContactErrorModal: false,
      showSendModal: false,
      showCloneModal: false,
      showPauseContactsModal: false,
      showResumeContactsModal: false,
      unsubscribeContact: null,
      blockContact: null,
      pauseContact: null,
      previewReplyActivity: null,
      actionContact: null,
      assignContactSentiment: null,
      toolbarStyle: {},
      showExportPdfModal: false,
      showPhoneNumberLookupModal: false,
      showContactInaccuracyModal: false
    }
  }

  UNSAFE_componentWillMount () {
    const {
      contact,
      customize
    } = this.props.location.query
    if (contact && customize) {
      this.props.router.push(`/sequence/${this.props.sequence.get('_id')}/preview?contactId=${contact}&customize=true`)
    }
  }

  componentDidUpdate (prevProps) {
    const { contactInaccuracyLoading } = this.props

    if (
      prevProps.contactInaccuracyLoading !== contactInaccuracyLoading &&
      !contactInaccuracyLoading
    ) {
      this.setState({
        showContactInaccuracyModal: false
      })
    }
  }

  onPauseContact = (contact) => {
    this.setState({
      pauseContact: contact
    })
  }

  onCloneContact = (contact) => {
    this.setState({
      showCloneModal: true,
      actionContact: contact
    })
  }

  onCopyContact = (contact) => {
    this.props.actions.fetchSequences()
    this.setState({
      showCopyModal: true,
      actionContact: contact
    })
  }

  onUnsubscribeContact = (contact) => {
    this.setState({
      unsubscribeContact: contact
    })
  }

  onBlockContact = (contact) => {
    this.setState({
      blockContact: contact
    })
  }

  onAssignSentiment = (contact) => {
    this.props.actions.fetchContactReply(contact.get('id'))
    this.setState({
      assignContactSentiment: contact
    })
  }

  onRepliedContactUpdate = (contact, { remove, booked }) => {
    const featureFlags = this.props.session.get('feature_flags') || this.props.session.get('whitelist')
    if (remove || booked || !featureFlags.includes('reply_type')) {
      return this.props.actions.repliedContact(contact.get('id'), { remove, booked })
    }

    this.setState({
      showReplyTypeModal: contact
    })
  }

  onOpenInGmail = (contactId) => {
    window.open(`${apiBaseURI}/contacts/${contactId}/open_response`)
  }

  onReportContactInaccuracy = (contact) => {
    this.setState({
      showContactInaccuracyModal: contact
    })
  }

  onClearError = (contactObj = null) => {
    const { allSelected, selectedContacts } = this.state
    const { filter, search, actions, sequence, contacts, router } = this.props
    const sequenceId = sequence.get('_id')

    // conditions when the user clicks on the contact status in a contact row
    if (contactObj) {
      const error = contactObj.get('error')
      const errorCount = this.getContactErrorCount(contactObj)
      const contactId = contactObj.get('_id')
      if ((error && errorCount === 0) || errorCount === 1) {
        if (error === ERROR_TYPE.MISSING_VARIABLE) {
          router.push({
            pathname: `/sequence/${sequenceId}/contacts/${contactId}/detail`,
            state: { editing: true }
          })
        } else {
          actions.updateContact(contactId, { error: null }, { sequenceId })
          this.setState({
            showSingleContactErrorModal: false,
            showGroupContactErrorModal: false
          })
        }
      } else if (errorCount > 1) {
        this.setState({
          selectedContacts: [contactId],
          singleViewedContact: contactObj,
          showSingleContactErrorModal: true,
          showGroupContactErrorModal: false
        })
      }
    // conditions when the selects a contact and tries to clear the errors
    } else if (allSelected || selectedContacts.length > 1) {
      actions.fetchContactErrors(sequenceId, selectedContacts, allSelected, filter, search)
      this.setState({
        showSingleContactErrorModal: false,
        showGroupContactErrorModal: true
      })
    } else if (selectedContacts.length === 1) {
      const contactId = selectedContacts[0]
      const contact = contacts
        .get('data')
        .filter(contact => contact.get('_id') === contactId)
        .get(0)

      const errorCount = this.getContactErrorCount(contact)
      if (errorCount === 1) {
        const error = contact.get('error')
        if (error === ERROR_TYPE.MISSING_VARIABLE) {
          router.push({
            pathname: `/sequence/${sequenceId}/contacts/${contactId}/detail`,
            state: { editing: true }
          })
        } else {
          actions.updateContact(contact.get('_id'), { error: null })
          this.setState({
            showSingleContactErrorModal: false,
            showGroupContactErrorModal: false
          })
        }
      } else {
        this.setState({
          singleViewedContact: contact,
          showSingleContactErrorModal: true,
          showGroupContactErrorModal: false
        })
      }
    }
  }

  onCustomizeMessages = (contact) => {
    this.props.router.push(`/sequence/${contact.get('_campaign')}/preview?contactId=${contact.get('_id')}&customize=true`)
  }

  onJumpToTask = (contact) => {
    this.props.router.push(`/sequence/${contact.get('_campaign')}/tasks?contactId=${contact.get('_id')}`)
  }

  onUpdateContact = (contactId, sequenceId, params) => {
    this.props.actions.updateContact(contactId, params, { sequenceId })
  }

  onResyncCrm = (contactId) => {
    const {
      sequence,
      filter,
      search,
      actions
    } = this.props

    const {
      allSelected,
      selectedContacts,
      deselectedContacts
    } = this.state

    const sequenceId = sequence.get('_id')

    if (contactId) {
      actions.resyncToCrm(sequenceId, [contactId])
      return
    }

    actions.resyncToCrm(sequenceId, allSelected ? deselectedContacts : selectedContacts, allSelected, filter, search)
  }

  assignSentiment = (contactId, sentiment) => {
    this.props.actions.repliedContact(contactId, { sentiment })
  }

  getContactErrorCount = (contact) => {
    if (!contact) {
      return 0
    }

    const contactErrors = contact
      .get('all_errors')
      .entrySeq()
      .map(item => item[1].get('error'))

    const errorCount = new Set(contactErrors).size
    return errorCount
  }

  getHeaderRowRenderer = (rowProps) => {
    const {
      sequence,
      sortContacts,
      sortKey,
      filter,
      search
    } = this.props
    const {
      allSelected,
      selectedContacts,
      deselectedContacts
    } = this.state

    let currentKey
    if (sortKey) {
      currentKey = sortKey.replace(':asc', '+').replace(':desc', '-')
    }

    const sequenceId = sequence.get('_id')

    return (
      <HeaderRowRenderer
        selected={allSelected && !deselectedContacts.length}
        onSelected={() => {
          this.lastSelectedIndex = null

          if (allSelected && deselectedContacts.length) {
            this.setState({
              deselectedContacts: []
            })
          } else {
            this.setState({
              allSelected: !allSelected,
              selectedContacts: [],
              deselectedContacts: []
            })

            this.props.actions.fetchSelectedContactCount(sequenceId, selectedContacts, !allSelected, filter, search)
          }
        }}
        sortFunction={(value) => {
          sortContacts(value)
        }}
        currentKey={currentKey}
        {...rowProps}
      />
    )
  }

  getRowRenderer = (rowProps) => {
    const {
      index
    } = rowProps
    const {
      session,
      contacts,
      router,
      sequence,
      actions,
      theme,
      crm,
      isArchived
    } = this.props
    const {
      selectedContacts,
      deselectedContacts,
      allSelected
    } = this.state

    const contact = contacts.getIn(['data', index])
    const contactId = contact.get('_id')
    const sequenceId = sequence.get('_id')

    const steps = sequence.get('steps') || []
    const totalSteps = steps.size

    return (
      <ContactRowRenderer
        session={session}
        sequence={sequence}
        totalSteps={totalSteps}
        router={router}
        theme={theme}
        crm={crm}
        isArchived={isArchived}
        selected={(allSelected && deselectedContacts.indexOf(contactId) < 0) || selectedContacts.indexOf(contactId) >= 0}
        onClick={() => {
          router.push(`/sequence/${sequenceId}/contacts/${contactId}/detail`)
        }}
        onCompanyClick={() => {
          router.push(`/sequence/${sequenceId}/contacts/${contactId}/company`)
        }}
        onStepClick={() => {
          router.push(`/sequence/${sequenceId}/contacts/${contactId}/activity`)
        }}
        onResyncCrm={this.onResyncCrm}
        onClearError={() => {
          this.onClearError(contact)
        }}
        onUpdateContact={params => {
          this.onUpdateContact(contactId, sequenceId, params)
        }}
        onRepliedContactUpdate={(remove, booked = false) => {
          this.onRepliedContactUpdate(contact, { remove, booked })
        }}
        onOpenInGmail={this.onOpenInGmail}
        onReportContactInaccuracy={this.onReportContactInaccuracy}
        onPauseContact={() => {
          this.onPauseContact(contact)
        }}
        onCloneContact={() => {
          this.onCloneContact(contact)
        }}
        onUnsubscribeContact={() => {
          this.onUnsubscribeContact(contact)
        }}
        onBlockContact={() => {
          this.onBlockContact(contact)
        }}
        onAssignSentiment={() => {
          this.onAssignSentiment(contact)
        }}
        onCustomizeMessages={() => {
          this.onCustomizeMessages(contact)
        }}
        onJumpToTask={() => {
          this.onJumpToTask(contact)
        }}
        onDelete={() => {
          this.setState({
            showDeleteConfirmModal: true,
            actionContact: contact
          })
        }}
        onMove={() => {
          actions.fetchSequences()
          this.setState({
            showMoveModal: true,
            actionContact: contact
          })
        }}
        onCopy={() => {
          this.onCopyContact(contact)
        }}
        onSendNow={() => {
          this.setState({
            showSendModal: true,
            actionContact: contact
          })
        }}
        onSelected={(e) => {
          document.getSelection().removeAllRanges()
          const nativeE = (e || {}).nativeEvent || {}
          const shiftKey = this.lastSelectedIndex !== null && nativeE.shiftKey

          const { singleViewedContact } = this.state
          const selected = allSelected ? deselectedContacts : selectedContacts
          const selectedIdx = selected.indexOf(contactId)

          if (selectedIdx >= 0) {
            selected.splice(selectedIdx, 1)
          } else {
            selected.push(contactId)
          }

          if (shiftKey) {
            let direction = 1
            if (this.lastSelectedIndex < index) {
              direction = -1
            }

            let i = index
            while (i !== this.lastSelectedIndex + direction) {
              const shiftContactId = contacts.getIn(['data', i, 'id'])
              if (!shiftContactId) {
                break
              }

              const shiftContactIdx = selected.indexOf(shiftContactId)
              if (selectedIdx >= 0) {
                if (shiftContactIdx >= 0) {
                  selected.splice(shiftContactIdx, 1)
                }
              } else {
                if (shiftContactIdx < 0) {
                  selected.push(shiftContactId)
                }
              }

              i = i + direction
            }
          }

          this.lastSelectedIndex = index

          if (allSelected) {
            this.setState({
              deselectedContacts: selected
            })
          } else if (
            !allSelected &&
            singleViewedContact === null &&
            selectedContacts.length === 1
          ) {
            this.setState({
              selectedContacts: selected,
              singleViewedContact: contact
            })
          } else {
            this.setState({
              selectedContacts: selected,
              singleViewedContact: null
            })
          }
        }}
        {...rowProps}
      />
    )
  }

  render () {
    const {
      sequence,
      contacts,
      stats,
      loading,
      filterContacts,
      searchContacts,
      onExportCsv,
      onAddContact,
      actions,
      contactActivity,
      contactMessages,
      contactSteps,
      contactIntegration,
      contactInaccuracyLoading,
      contactCompany,
      similarContacts,
      loadMore,
      hasMore,
      sequences,
      router,
      selectedContact,
      contactPhone,
      filter,
      search,
      state,
      crm,
      contactErrors,
      selectedContactCount,
      session,
      activeCRM,
      contactReply,
      isArchived,
      statsLoading,
      onPhoneNumberLookups
    } = this.props

    const {
      selectedContacts,
      singleViewedContact,
      allSelected,
      deselectedContacts,
      showDeleteConfirmModal,
      showMoveModal,
      showCopyModal,
      showCloneModal,
      showSingleContactErrorModal,
      showGroupContactErrorModal,
      showPauseContactsModal,
      showResumeContactsModal,
      showExportPdfModal,
      pauseContact,
      unsubscribeContact,
      blockContact,
      assignContactSentiment,
      previewReplyActivity,
      actionContact,
      showSendModal,
      toolbarStyle,
      filterAdded,
      showReplyTypeModal,
      showPhoneNumberLookupModal,
      showContactInaccuracyModal
    } = this.state

    let total = 0
    if (stats) {
      total = stats.get('total') || 0
    }

    const sequenceId = sequence.get('_id')

    if (!loading && !statsLoading && total === 0) {
      return (
        <EmptyState
          title='Start Finding New Contacts'
          description={<span>Add your first contact, import contacts from a CSV file, or<br />add contacts using the <a href='https://chrome.google.com/webstore/detail/interseller/coggaiakecmfpkamlplbpacajahgeofh' target='_new'>Chrome extension</a>.</span>}
          mt='2rem'
        >
          {!isArchived &&
            <Button
              primary
              label='New Contact'
              handleClick={onAddContact}
              mr='1rem'
            />}
          {!isArchived &&
            <Button
              label='Import CSV'
              handleClick={() => {
                router.push(`/sequence/${sequenceId}/import`)
              }}
            />}
          {activeCRM && activeCRM.getIn(['data', 'import_contacts_supported']) &&
            <Button
              style={{ marginLeft: '1rem' }}
              label={`Import from ${activeCRM.getIn(['data', 'name'])}`}
              handleClick={() => {
                router.push(`/sequence/${sequenceId}/import-crm`)
              }}
            />}
        </EmptyState>
      )
    }

    let count = selectedContacts.length
    let word = pluralize('contact', 'contacts', selectedContacts.length)

    if (allSelected) {
      if (selectedContactCount.get('loading')) {
        if (deselectedContacts.length) {
          count = 'selected'
        } else {
          count = 'all'
        }

        word = 'contacts'
      } else {
        count = selectedContactCount.get('data') - deselectedContacts.length
        word = pluralize('contact', 'contacts', count)
      }
    }

    return (
      <Wrapper>
        <Stats
          stats={stats}
          statsLoading={statsLoading}
          filter={filter}
          filterContacts={(type) => {
            this.lastSelectedIndex = null
            this.setState({
              selectedContacts: [],
              deselectedContacts: [],
              allSelected: false,
              filterAdded: null
            })
            filterContacts(type)
          }}
        />
        <TableWrapper>
          {(!loading || !statsLoading) &&
            <Sticky innerZ={3}>
              <ContactToolbar
                session={session}
                actions={actions}
                style={toolbarStyle}
                filter={filter}
                search={search}
                crm={crm}
                contacts={contacts}
                selectedContactCount={selectedContactCount}
                contactErrors={contactErrors}
                filterAdded={filterAdded}
                onExportPdf={() => {
                  this.setState({
                    showExportPdfModal: true
                  })
                }}
                onExportCsv={() => {
                  onExportCsv(selectedContacts)
                }}
                onImportCsv={() => {
                  router.push(`/sequence/${sequenceId}/import`)
                }}
                onImportCrm={() => {
                  router.push(`/sequence/${sequenceId}/import-crm`)
                }}
                onAddContact={onAddContact}
                selectedContacts={selectedContacts}
                deselectedContacts={deselectedContacts}
                allSelected={allSelected}
                onDelete={() => {
                  this.setState({
                    showDeleteConfirmModal: true
                  })
                }}
                onMove={() => {
                  actions.fetchSequences()
                  this.setState({
                    showMoveModal: true
                  })
                }}
                onCopy={() => {
                  actions.fetchSequences()
                  this.setState({
                    showCopyModal: true
                  })
                }}
                onClearError={() => {
                  this.onClearError()
                }}
                onPauseContacts={() => {
                  this.setState({
                    showPauseContactsModal: true
                  })
                }}
                onResumeEmails={() => {
                  this.setState({
                    showResumeContactsModal: true
                  })
                }}
                onClear={() => {
                  this.lastSelectedIndex = null
                  this.setState({
                    allSelected: false,
                    selectedContacts: [],
                    deselectedContacts: []
                  })
                }}
                onFilterContacts={(type, filterAdded) => {
                  this.lastSelectedIndex = null
                  this.setState({
                    selectedContacts: [],
                    deselectedContacts: [],
                    allSelected: false,
                    filterAdded
                  })
                  filterContacts(type)
                }}
                onSearchContacts={(search) => {
                  this.lastSelectedIndex = null
                  this.setState({
                    selectedContacts: [],
                    deselectedContacts: [],
                    allSelected: false
                  })
                  searchContacts(search)
                }}
                activeCRM={activeCRM}
                isArchived={isArchived}
                onResyncCrm={() => {
                  this.onResyncCrm()
                }}
                onPhoneNumberLookups={onPhoneNumberLookups}
                onOpenPhoneLookupModal={() => {
                  this.setState({
                    showPhoneNumberLookupModal: true
                  })
                }}
                onJumpToTask={(contact) => {
                  this.onJumpToTask(contact)
                }}
              />
            </Sticky>}
          <ContactTableWrapper>
            {loading && contacts.get('data').count() === 0 &&
              <LoadingContainer>
                <Loading />
              </LoadingContainer>}
            {!loading && contacts.get('data').count() === 0 &&
              <EmptyState
                title='No Contacts Found'
                description='Try removing a filter or adding more contacts.'
              >
                <Button
                  label='Remove filters'
                  handleClick={() => {
                    this.props.clearFilterAndSearch()
                  }}
                />
              </EmptyState>}
            {contacts.get('data').count() > 0 &&
              <VirtualTable
                data={contacts.get('data')}
                rowHeight={73}
                hasMore={hasMore}
                rowCount={contacts.get('data').count()}
                headerRowRenderer={this.getHeaderRowRenderer}
                rowRenderer={this.getRowRenderer}
                onScroll={({ clientHeight, scrollHeight, scrollTop }) => {
                  const LOADING_DELTA = 1200
                  if (hasMore && !contacts.get('loading') && scrollTop > (scrollHeight - clientHeight - LOADING_DELTA)) {
                    loadMore({
                      startIndex: contacts.get('data').count(),
                      stopIndex: contacts.get('data').count() + 50
                    })
                  }
                }}
              />}
            {hasMore && contacts.get('data').count() > 0 &&
              <LoadingContainer>
                <Loading padding='2rem' />
              </LoadingContainer>}
          </ContactTableWrapper>
        </TableWrapper>
        <Modal
          width='fit-content'
          maxWidth='40rem'
          isOpen={previewReplyActivity !== null}
          onModalClose={() => {
            this.setState({
              previewReplyActivity: null
            })
          }}
        >
          {previewReplyActivity &&
            <MessagePreview
              subject={
                previewReplyActivity.getIn(['data', 'subject']) ||
                previewReplyActivity.getIn(['_message', 'subject'])
              }
              body={
                previewReplyActivity.getIn(['data', 'reply'])
              }
              markdown={
                previewReplyActivity.getIn(['_message', 'markdown'])
              }
            />}
        </Modal>
        <Modal
          width='fit-content'
          maxWidth='40rem'
          isOpen={assignContactSentiment !== null}
          onModalClose={() => {
            this.setState({
              assignContactSentiment: null
            })
          }}
        >
          <AssignSentiment
            contact={assignContactSentiment}
            contactReply={contactReply}
            onCancel={() => {
              this.setState({
                assignContactSentiment: null
              })
            }}
            onConfirm={(contactId, sentiment) => {
              this.assignSentiment(contactId, sentiment)
              this.setState({
                assignContactSentiment: null
              })
            }}
          />
        </Modal>
        <ConfirmModal
          isOpen={showDeleteConfirmModal}
          onCancel={() => {
            this.setState({
              showDeleteConfirmModal: false,
              actionContact: null
            })
          }}
          onConfirm={() => {
            if (actionContact) {
              actions.deleteContacts(sequenceId, [actionContact.get('_id')], false)
            } else {
              actions.deleteContacts(sequenceId, allSelected ? deselectedContacts : selectedContacts, allSelected, filter, search)
            }

            this.lastSelectedIndex = null
            this.setState({
              showDeleteConfirmModal: false,
              actionContact: null,
              allSelected: false,
              selectedContacts: [],
              deselectedContacts: []
            })
          }}
          title='Delete Confirmation'
          description={
            actionContact
              ? `Are you sure you want to delete ${actionContact.get('name')}?`
              : `Are you sure you want to delete ${count} ${word}?`
          }
        />
        <ConfirmModal
          isOpen={showSendModal}
          onCancel={() => {
            this.setState({
              showSendModal: false,
              actionContact: null
            })
          }}
          onConfirm={() => {
            if (actionContact) {
              actions.forceSendStep({
                sequenceId: sequence.get('_id'),
                contactId: actionContact.get('_id'),
                nextStepIndex: actionContact.get('next_step')
              })
            }
            this.setState({
              showSendModal: false,
              actionContact: null
            })
          }}
          title='Send Now'
          description={`Send the next step in the sequence for ${actionContact ? actionContact.get('name') : 'contact'}?`}
        />

        <SingleContactErrorModal
          isOpen={showSingleContactErrorModal}
          sequenceId={sequence.get('_id')}
          actions={actions}
          selectedContact={singleViewedContact}
          contactErrors={contactErrors}
          filter={filter}
          search={search}
          crm={crm}
          onClose={() => {
            this.setState({ showSingleContactErrorModal: false })
          }}
        />

        <GroupContactErrorModal
          description={`Choose the errors you'd like to clear from the ${count} contacts you've selected`}
          isOpen={showGroupContactErrorModal}
          sequenceId={sequence.get('_id')}
          actions={actions}
          contactErrors={contactErrors}
          contactIds={[...selectedContacts]}
          allContactsSelected={allSelected}
          filter={filter}
          search={search}
          crm={crm}
          onClose={() => {
            this.setState({ showGroupContactErrorModal: false })
          }}
        />

        <ConfirmModal
          isOpen={unsubscribeContact !== null}
          onCancel={() => {
            this.setState({
              unsubscribeContact: null
            })
          }}
          onConfirm={() => {
            actions.unsubscribeContact(unsubscribeContact.get('id'))
            this.setState({
              unsubscribeContact: null
            })
          }}
          title='Unsubscribe Contact'
          description={`Are you sure you want to permanently unsubscribe ${unsubscribeContact ? unsubscribeContact.get('email') : 'contact'} from all sequences?`}
        />
        <PauseModal
          isOpen={pauseContact !== null}
          onCancel={() => {
            this.setState({
              pauseContact: null
            })
          }}
          onConfirm={(date) => {
            actions.updateContact(
              pauseContact.get('id'),
              { delay_until: date },
              { sequenceId: pauseContact.get('_campaign') }
            )
            this.setState({
              pauseContact: null
            })
          }}
          title='Pause Messages'
          description={`Pause messages for ${pauseContact ? pauseContact.get('email') : 'contact'} indefinitely or until a specified date`}
        />
        <PauseModal
          isOpen={showPauseContactsModal}
          onCancel={() => {
            this.setState({
              showPauseContactsModal: false
            })
          }}
          onConfirm={(date) => {
            // TODO: Add date to notification
            actions.updateContacts(sequenceId, allSelected ? deselectedContacts : selectedContacts, { delay_until: date, delayed: true, delayed_indefinitely: date === true }, 'Paused messages for', allSelected, filter, search)
            this.lastSelectedIndex = null
            this.setState({
              showPauseContactsModal: false,
              allSelected: false,
              selectedContacts: [],
              deselectedContacts: []
            })
          }}
          title='Pause Messages'
          description={`Pause messages for ${count} ${word} indefinitely or until specified date`}
        />
        <ConfirmModal
          isOpen={showResumeContactsModal}
          onCancel={() => {
            this.setState({
              showResumeContactsModal: false
            })
          }}
          onConfirm={() => {
            // TODO: Add date to notification
            actions.updateContacts(sequenceId, allSelected ? deselectedContacts : selectedContacts, { delay_until: null, delayed: false, delayed_indefinitely: false }, 'Resuming messages for', allSelected, filter, search)
            this.lastSelectedIndex = null
            this.setState({
              showResumeContactsModal: false,
              allSelected: false,
              selectedContacts: [],
              deselectedContacts: []
            })
          }}
          title='Resume Messages'
          description={`Are you sure you want to resume messages for ${count} ${word}?`}
        />
        <FixEmailModal
          isOpen={showCloneModal}
          onCancel={() => {
            this.setState({
              showCloneModal: false,
              actionContact: null
            })
          }}
          onConfirm={(email) => {
            if (actionContact) {
              actions.cloneContact(sequenceId, actionContact.get('_id'), email)
            }
            this.setState({
              showCloneModal: false,
              actionContact: null
            })
          }}
          title='Fix Email'
          description={
            `Enter the correct email address for ${actionContact ? actionContact.get('name') : 'contact'} to create a copy of the contact with the correct email`
          }
          actions={actions}
        />
        <BlockContactModal
          isOpen={blockContact !== null}
          onCancel={() => {
            this.setState({
              blockContact: null
            })
          }}
          onConfirm={(addToTeam) => {
            actions.blockContact(blockContact.get('_id'), addToTeam)
            this.setState({
              blockContact: null
            })
          }}
          title='Block Contact'
          description={
            `This will add ${blockContact ? blockContact.get('email_domain') : 'this contact\'s domain'} to your blocklist and prevent sending any messages to ${blockContact ? blockContact.get('email_domain') : 'this domain'} going forward.`
          }
          canBlockForTeam={state.get('is_manager')}
          actions={actions}
        />
        <SequencePickerModal
          isOpen={showMoveModal}
          onCancel={() => {
            this.setState({
              showMoveModal: false,
              actionContact: null
            })
          }}
          onConfirm={(s) => {
            if (actionContact) {
              actions.moveContacts(sequenceId, [actionContact.get('_id')], s, false)
            } else {
              actions.moveContacts(sequenceId, allSelected ? deselectedContacts : selectedContacts, s, allSelected, filter, search)
            }
            this.lastSelectedIndex = null
            this.setState({
              showMoveModal: false,
              allSelected: false,
              selectedContacts: [],
              deselectedContacts: [],
              actionContact: null
            })
          }}
          title='Move Confirmation'
          description={
            actionContact
              ? `Choose the sequence to move ${actionContact.get('name')} to:`
              : `Choose the sequence to move ${count} ${word} to:`
          }
          sequences={sequences}
          actions={actions}
        />
        <SequencePickerModal
          isOpen={showCopyModal}
          onCancel={() => {
            this.setState({
              showCopyModal: false,
              actionContact: null
            })
          }}
          onConfirm={(s) => {
            if (actionContact) {
              actions.copyContacts(sequenceId, [actionContact.get('_id')], s, false)
            } else {
              actions.copyContacts(sequenceId, allSelected ? deselectedContacts : selectedContacts, s, allSelected, filter, search)
            }
            this.lastSelectedIndex = null
            this.setState({
              showCopyModal: false,
              allSelected: false,
              selectedContacts: [],
              deselectedContacts: [],
              actionContact: null
            })
          }}
          title='Copy Confirmation'
          description={
            actionContact
              ? `Choose the sequence to copy ${actionContact.get('name')} to:`
              : `Choose the sequence to copy ${count} ${word} to:`
          }
          sequences={sequences}
          actions={actions}
        />
        <ExportPdfModal
          description={`This will export ${count} ${word} to a presentable PDF that you can distribute`}
          isOpen={showExportPdfModal}
          onCancel={() => {
            this.setState({
              showExportPdfModal: false
            })
          }}
          onConfirm={(showContactInfo) => {
            actions.fetchExportPdf(sequenceId, allSelected ? deselectedContacts : selectedContacts, showContactInfo, allSelected, filter, search)
            this.setState({
              showExportPdfModal: false
            })
          }}
        />
        <ExportPdfModal
          description={`This will export ${count} ${word} to a presentable PDF that you can distribute`}
          isOpen={showExportPdfModal}
          onCancel={() => {
            this.setState({
              showExportPdfModal: false
            })
          }}
          onConfirm={(showContactInfo) => {
            actions.fetchExportPdf(sequenceId, allSelected ? deselectedContacts : selectedContacts, showContactInfo, allSelected, filter, search)
            this.setState({
              showExportPdfModal: false
            })
          }}
        />
        <ReplyTypeModal
          isOpen={showReplyTypeModal}
          contact={showReplyTypeModal}
          onCancel={() => {
            this.setState({
              showReplyTypeModal: false
            })
          }}
          onConfirm={(contactId, replyType) => {
            actions.repliedContact(contactId, { replyType })
            this.setState({
              showReplyTypeModal: false
            })
          }}
        />
        <ConfirmModal
          isOpen={showPhoneNumberLookupModal}
          onCancel={() => this.setState({
            showPhoneNumberLookupModal: false
          })}
          onConfirm={() => {
            this.props.actions.phoneNumberLookups(sequenceId, allSelected ? deselectedContacts : selectedContacts, allSelected, filter, search)
            setTimeout(() => { this.props.actions.fetchLookupStatus(sequenceId) }, 1000)
            this.setState({ showPhoneNumberLookupModal: false })
          }}
          confirmLabel='Continue'
          title='Lookup Phone Numbers'
          description={
            <p>
              {`This will lookup mobile phone numbers for ${count} ${word}. `}
              <Danger>Contacts must be sourced from LinkedIn. Looking up phone numbers will utilize lookup credits if a phone number is found.</Danger>
            </p>
          }
        />
        <ContactInaccuracyModal
          isOpen={showContactInaccuracyModal}
          contact={showContactInaccuracyModal}
          contactInaccuracyLoading={contactInaccuracyLoading}
          onCancel={() => this.setState({
            showContactInaccuracyModal: false
          })}
          onConfirm={(contact) => {
            actions.reportContactInaccuracy(contact)
          }}
        />
        {this.props.children &&
          <SidePanel
            isOpen
            onSidePanelClose={() => {
              router.push(`/sequence/${sequenceId}/contacts`)
            }}
          >
            {React.cloneElement(this.props.children, {
              router,
              session,
              selectedContact,
              contactPhone,
              actions,
              sequence,
              crm,
              sequences,
              contactActivity,
              contactMessages,
              contactSteps,
              contactIntegration,
              similarContacts,
              contactCompany,
              isArchived,
              onReportContactInaccuracy: this.onReportContactInaccuracy,
              onRepliedContactUpdate: this.onRepliedContactUpdate,
              onUnsubscribeContact: this.onUnsubscribeContact,
              onBlockContact: this.onBlockContact,
              onAssignSentiment: this.onAssignSentiment,
              onCustomizeMessages: this.onCustomizeMessages,
              onPauseContact: this.onPauseContact,
              onCopyContact: this.onCopyContact,
              onCloneContact: this.onCloneContact,
              onUpdateContact: this.onUpdateContact,
              onClearError: this.onClearError,
              onSendNow: () => {
                this.setState({
                  showSendModal: true,
                  actionContact: selectedContact.get('data')
                })
              },
              onPreviewReply: (activity) => {
                this.setState({
                  previewReplyActivity: activity
                })
              },
              onJumpToTask: this.onJumpToTask,
              onResyncCrm: this.onResyncCrm
            })}
          </SidePanel>}
      </Wrapper>
    )
  }
}

Contacts.propTypes = {
  children: PropTypes.object,
  router: PropTypes.object,
  location: PropTypes.object,
  contacts: PropTypes.object,
  loading: PropTypes.bool,
  sequence: PropTypes.instanceOf(Immutable.Map),
  stats: PropTypes.object,
  filterContacts: PropTypes.func,
  searchContacts: PropTypes.func,
  clearFilterAndSearch: PropTypes.func,
  onExportCsv: PropTypes.func,
  onPhoneNumberLookups: PropTypes.func,
  onAddContact: PropTypes.func,
  actions: PropTypes.object,
  contactActivity: PropTypes.instanceOf(Immutable.List),
  contactMessages: PropTypes.instanceOf(Immutable.List),
  contactSteps: PropTypes.object,
  contactIntegration: PropTypes.instanceOf(Immutable.Map),
  contactInaccuracyLoading: PropTypes.bool,
  contactCompany: PropTypes.instanceOf(Immutable.Map),
  contactErrors: PropTypes.instanceOf(Immutable.Map),
  similarContacts: PropTypes.instanceOf(Immutable.Map),
  loadMore: PropTypes.func,
  hasMore: PropTypes.bool,
  sequences: PropTypes.instanceOf(Immutable.List),
  selectedContact: PropTypes.object,
  contactPhone: PropTypes.object,
  sortKey: PropTypes.string,
  sortContacts: PropTypes.func,
  filter: PropTypes.array,
  search: PropTypes.string,
  theme: PropTypes.object,
  state: PropTypes.object,
  crm: PropTypes.object,
  selectedContactCount: PropTypes.object,
  session: PropTypes.object,
  activeCRM: PropTypes.instanceOf(Immutable.Map),
  isArchived: PropTypes.bool,
  contactReply: PropTypes.object,
  statsLoading: PropTypes.bool
}

export default withRouter(withTheme(Contacts))
