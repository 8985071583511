import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'

import { fadeIn } from 'utils/animations'
import WorkflowItem from './WorflowItem'

const Wrapper = styled.ul`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  list-style-type: none;
  line-height: 1.4em;
  padding: 0;
  padding-top: 1rem;
  padding-bottom: 2rem;
  margin: 0;

  animation: ${fadeIn} .2s linear 1;
  box-shadow: ${props => (props.shadow ? props.theme.shadow : '')};

  ${space}
`

class Workflow extends PureComponent {
  render () {
    const {
      steps,
      currentStep,
      ...rest
    } = this.props

    const items = steps.map((step, i) => (
      <WorkflowItem
        key={`step_${step.title}`}
        step={step}
        stepIndex={i}
        currentStep={currentStep}
        totalSteps={steps.length}
      />))
    return (
      <Wrapper {...rest}>
        {items}
      </Wrapper>
    )
  }
}

Workflow.propTypes = {
  steps: PropTypes.array,
  currentStep: PropTypes.number
}

Workflow.defaultProps = {
  steps: [],
  currentStep: 0
}

export default Workflow
