import { UNSUBSCRIBE_URL_VAR } from './constants'

//
// NOTE: the word `active` in this file refers to when the cursor is inside
//       a specific tag/slate object
//
// Example: `isEmojiActive` is a function that returns true or false
//           and checks to see if the cursor is inside a span tag that
//           contains an emoji
//

function isHighlightingActive (editor) {
  const { anchor, focus } = editor.value.selection
  const isHighlightingText = anchor.offset !== focus.offset || anchor.key !== focus.key

  const { value } = editor
  const isHighlightingImage = value.blocks.some(block => block.type === 'image')

  return isHighlightingImage || isHighlightingText
}

function isBoldActive (editor) {
  const { value } = editor
  return value.marks.some(mark => mark.type === 'bold')
}

function isUnderlineActive (editor) {
  const { value } = editor
  return value.activeMarks.some(mark => mark.type === 'underline')
}

function isItalicActive (editor) {
  const { value } = editor
  return value.activeMarks.some(mark => mark.type === 'italic')
}

function isOrderedListActive (editor) {
  return editor.value.blocks.some(block => {
    const ancestors = editor.value.document.getAncestors(block.key)
    return ancestors.some(node => node.type === 'ordered_list')
  })
}

function isUnorderedListActive (editor) {
  return editor.value.blocks.some(block => {
    const ancestors = editor.value.document.getAncestors(block.key)
    return ancestors.some(node => node.type === 'unordered_list')
  })
}

function isEmojiActive (editor) {
  const { value } = editor
  return value.inlines.some(inline => inline.type === 'emoji')
}

function isLinkActive (editor) {
  const { value } = editor
  const isTextLink = value.inlines.some(inline => inline.type === 'link')
  const isImageLink = value.blocks.some(block => {
    return block.type === 'image' && block.getIn(['data', 'href'])
  })

  return isTextLink || isImageLink
}

function isUnsubscribeLinkActive (editor) {
  const { value } = editor
  return value.inlines.some(inline => {
    return inline.type === 'link' &&
      inline.getIn(['data', 'href']) === UNSUBSCRIBE_URL_VAR
  })
}

function getCursorCoordinates () {
  try {
    const native = window.getSelection()
    if (native) {
      // `getRangeAt(0)` get's the position of the first character of a selection
      const range = native.getRangeAt(0)
      if (range) {
        return range.getBoundingClientRect()
      }
    }
  } catch (err) {
    // if editor blurs, this throws error
    return null
  }
}

function getActiveLinks (editor) {
  const { value } = editor
  const textLinks = value
    .inlines
    .filter(inline => inline.type === 'link')
    .map(link => link.getIn(['data', 'href']))
    .toJS()

  const imageLinks = value
    .blocks
    .filter(block => block.type === 'image')
    .map(image => image.getIn(['data', 'href']))
    .toJS()

  return [...textLinks, ...imageLinks]
}

function getTextAsFlatString (editor) {
  const linesOfText = editor
    .value
    .document
    .getTextsAsArray()

  return linesOfText
    .map(textObj => textObj.get('text') + '\n')
    .map(text => text === '' ? '\n' : text)
    .join('')
    .trim()
}

function getCurrentSelection (editor) {
  return editor.value.selection
}

const subjectQueries = {
  isEmojiActive,
  getTextAsFlatString
}

const bodyQueries = {
  isBoldActive,
  isLinkActive,
  isEmojiActive,
  isItalicActive,
  isUnderlineActive,
  isOrderedListActive,
  isHighlightingActive,
  isUnorderedListActive,
  isUnsubscribeLinkActive,
  getActiveLinks,
  getTextAsFlatString,
  getCurrentSelection,
  getCursorCoordinates
}

export {
  subjectQueries,
  bodyQueries
}
