const PLATFORMS = {
  DRIVE: 'GOOGLE_DRIVE',
  GMAIL: 'GOOGLE_MAIL',
  OUTLOOK: 'MICROSOFT_OUTLOOK',
  WORD: 'MICROSOFT_WORD',
  NONE: null
}

const MSFT_LIST_NODES = {
  SINGLETON: 'msolistparagraph', // list with no siblings
  FIRST: 'msolistparagraphcxspfirst', // first elem of a list with siblings
  MIDDLE: 'msolistparagraphcxspmiddle', // middle elem of list with siblings - can be more than 1
  LAST: 'msolistparagraphcxsplast' // last elem of list with siblings
}

const NODES = {
  BODY: 'body',
  DIVIDER: 'div',
  PARAGRAPH: 'p',
  ORDERED_LIST: 'ol',
  UNORDERED_LIST: 'ul',
  LIST_ITEM: 'li',
  HEADER_1: 'h1',
  HEADER_2: 'h2',
  HEADER_3: 'h3',
  HEADER_4: 'h4',
  HEADER_5: 'h5',
  HEADER_6: 'h6',
  BREAK: 'br',
  SPAN: 'span',
  TIME: 'time',
  ANCHOR: 'a',
  STRONG: 'strong',
  BOLD: 'b',
  EMPHASIS: 'em',
  ITALICS: 'i',
  UNDERLINE: 'u'
}

export {
  PLATFORMS,
  MSFT_LIST_NODES,
  NODES
}
