import React from 'react'

/* eslint-disable */
const Time = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 15} height={height || 15}  viewBox="0 0 15 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Campaign-Contacts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
      <g id="Contact-Sidebar---Activities" transform="translate(-1089.000000, -263.000000)" stroke={color}>
          <g id="Group-5" transform="translate(1084.000000, 258.000000)">
              <g id="Group-12">
                  <g id="task-timeout" transform="translate(6.000000, 6.000000)">
                      <path d="M6.5,0 C10.0901667,0 13,2.90983333 13,6.5 C13,10.0901667 10.0901667,13 6.5,13 C2.90983333,13 0,10.0901667 0,6.5 C0,2.90983333 2.90983333,0 6.5,0 Z" id="Stroke-1"></path>
                      <polyline id="Stroke-3" strokeLinecap="round" points="9 7 6 7 6 3"></polyline>
                  </g>
              </g>
          </g>
      </g>
    </g>
  </svg>
);

export default Time;
