import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import AddField from 'components/AddField'
import Workflow from 'components/Workflow'
import ImportPicker from './ImportPicker'
import ImportMapper from './ImportMapper'
import ImportFinished from './ImportFinished'
import ImportLookupPicker from './ImportLookupPicker'
import Modal from 'components/Modal'
import Title from 'elements/Title'
import Label from 'elements/Label'
import Divider from 'elements/Divider'
import Button from 'components/Button'

const Wrapper = styled.div`
  height: 100%;
  padding: 2rem;
`

const ContentContainer = styled.div`
  background-color: ${props => props.theme.containerBackground};
  box-shadow: ${props => props.theme.shadow};
  padding: ${props => props.theme.padding};
  margin-bottom: 2rem;
  margin-left: auto;
  margin-right: auto;
  min-width: 32rem;
  ${props => props.currentStep === 0 && css`
    max-width: 52rem;
  `};
  ${props => props.currentStep === 1 && css`
    width: 100%;
  `};
  ${props => props.currentStep === 2 && css`
    max-width: 45rem;
  `};
  transition: width 0.3s ease-in, height 0.3s ease-in;
`

const ModalWrapper = styled.div`
  background: ${props => props.theme.colors.white};
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding: 3rem 4rem 2rem 4rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const steps = [
  {
    title: 'Upload File'
  },
  {
    title: 'Map Fields'
  },
  {
    title: 'Lookup Emails'
  },
  {
    title: 'Finished'
  }
]

class Import extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      step: 0,
      showAddModal: false,
      mapping: {}
    }
  }

  goBack () {
    let step = this.state.step - 1
    if (step < 0) {
      step = 0
    }

    this.setState({
      step
    })
  }

  render () {
    const {
      sequence,
      csv,
      importingCsv,
      actions,
      refetchSequence,
      showWarningModal,
      createCsv
    } = this.props

    const {
      step,
      mapping,
      showAddModal
    } = this.state

    let content

    switch (step) {
      case 3:
        content = (
          <ImportFinished
            sequence={sequence}
            csv={csv}
            importingCsv={importingCsv}
            refetchSequence={refetchSequence}
          />
        )
        break
      case 2:
        content = (
          <ImportLookupPicker
            sequence={sequence}
            importCsv={csv}
            mapping={mapping}
            onSubmit={(csvId, lookup) => {
              const sequenceId = sequence.get('_id')
              actions.updateCsv(sequenceId, csvId, { mapping, lookup }, lookup)
              setTimeout(() => { actions.fetchLookupStatus(sequenceId) }, 1000)
              this.setState({
                step: 3
              })
            }}
            goBack={() => {
              this.goBack()
            }}
          />
        )
        break
      case 1:
        content = (
          <ImportMapper
            sequence={sequence}
            importCsv={csv}
            onAddField={() => {
              this.setState({
                showAddModal: true
              })
            }}
            onMappingSelect={(csvId, params) => {
              this.setState({
                step: 2,
                mapping: params
              })
            }}
          />
        )
        break
      case 0:
      default:
        content = (
          <ImportPicker
            onFileSelect={(file) => {
              actions.createCsv(file)
              this.setState({
                step: 1
              })
            }}
          />
        )
        break
    }

    return (
      <Wrapper>
        <Workflow steps={steps} currentStep={this.state.step} />
        <ContentContainer currentStep={this.state.step}>
          {content && content}
        </ContentContainer>
        <AddField
          isOpen={showAddModal}
          onModalClose={() => {
            this.setState({
              showAddModal: false
            })
          }}
          onAddField={(field) => {
            actions.addCustomField(sequence.get('_id'), [field])
            this.setState({
              showAddModal: false
            })
          }}
        />
        <Modal
          isOpen={showWarningModal}
          onModalClose={actions.hideWarningModal}
        >
          <ModalWrapper>
            <InputContainer>
              <Title align='center'>Too Many CSV Rows</Title>
              <Label mt='0.5rem' mb='0.5rem' align='center'>
                The CSV file you uploaded has {createCsv.getIn(['data', 'count'])} contacts, which is over the recommended 2,000 per sequence &mdash; having too many contacts in a single sequence can have adverse effects on <a href='https://help.interseller.io/article/154-improving-your-email-deliverability' target='_new'>email deliverability</a>.
              </Label>
            </InputContainer>
            <Divider />
            <ButtonContainer>
              <Button
                label='ok'
                onClick={() => {
                  actions.hideWarningModal()
                }}
                mr='0.5rem'
              />
            </ButtonContainer>
          </ModalWrapper>
        </Modal>
      </Wrapper>
    )
  }
}

Import.propTypes = {
  actions: PropTypes.object,
  csv: PropTypes.object,
  importingCsv: PropTypes.object,
  sequence: PropTypes.instanceOf(Immutable.Map),
  refetchSequence: PropTypes.func,
  showWarningModal: PropTypes.bool,
  createCsv: PropTypes.object
}

export default Import
