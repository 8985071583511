import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { format, addDays, startOfToday, isSameDay } from 'date-fns'
import NumberFormat from 'react-number-format'
import { color, borderColor } from 'styled-system'

import Title from 'elements/Title'
import Divider from 'elements/Divider'
import Label from 'elements/Label'
import Button from 'components/Button'
import Loading from 'components/Loading'

const Wrapper = styled.div`
  width: 640px;
  background-color: ${props => props.theme.containerBackground};
`

const Container = styled.div`
  padding: 2rem 3rem;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

const DateGrid = styled.div`
  display: flex;
  width: 100%;
  margin-top: 2rem;
`

const DateGridItem = styled.div`
  flex: 1;
  padding-right: .5rem;

  &:last-child {
    padding-right: 0;
  }
`

const DateGridValue = styled.div`
  font: ${props => props.theme.fonts.number};
  color: ${props => props.theme.titleColor};

  border: 1px solid ${props => props.theme.colors.gray40};

  padding: 1rem 0;
  margin-bottom: 0.5rem;

  ${color}
  ${borderColor}
`

const DateGridValueAlt = styled(DateGridValue)`
  background-color: ${props => props.theme.colors.gray20};
  border-color: ${props => props.theme.colors.gray20};
`

const DateGridDate = styled(Label)`
  text-align: center;
  font-size: 12px;
  text-transform: uppercase;
  white-space: pre;

  ${color}
`

const DateGridDateAlt = styled(DateGridDate)`
  font-weight: 600;
`

class MessageSchedule extends PureComponent {
  render () {
    const {
      messageCounts,
      schedule,
      theme
    } = this.props

    if (messageCounts.get('loading') || schedule.get('loading')) {
      return (
        <Wrapper>
          <Loading padding='5rem' size={30} />
        </Wrapper>
      )
    }

    const today = startOfToday()
    const dates = [
      // today
    ]
    for (let i = 0; i < 7; i++) {
      dates.push(addDays(today, i))
    }

    const scheduledCounts = schedule.get('data')

    // get total messages
    let totalCount = 0
    scheduledCounts.map(count => {
      totalCount = totalCount + count
      return totalCount
    })

    let remainingCount = totalCount

    const dateGridItems = dates.map((date, idx) => {
      const count = scheduledCounts.get(format(date, 'YYYYMMDD')) || 0
      remainingCount = remainingCount - count

      const color = isSameDay(date, today) && theme.colors.blue

      return (
        <DateGridItem key={idx}>
          <DateGridValue borderColor={color} color={color}>
            <NumberFormat value={count} displayType='text' thousandSeparator=',' />
          </DateGridValue>
          {!isSameDay(date, today) && <DateGridDate>{format(date, 'ddd[\n]MMM D')}</DateGridDate>}
          {isSameDay(date, today) && <DateGridDate color={color}>Rest of<br />Today</DateGridDate>}
        </DateGridItem>
      )
    })

    return (
      <Wrapper>
        <Container>
          <Title align='center'>Message Schedule</Title>
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            A brief summary of upcoming messages being sent throughout the week
          </Label>
          <DateGrid>
            {dateGridItems}
            <DateGridItem>
              <DateGridValueAlt>
                <NumberFormat value={remainingCount} displayType='text' thousandSeparator=',' />
              </DateGridValueAlt>
              <DateGridDateAlt>BEYOND<br />7 DAYS</DateGridDateAlt>
            </DateGridItem>
          </DateGrid>
          <Label mt='1.75rem' mb='0.5rem' fontSize='13px' align='justify'>
            This is an estimation of emails being sent per day and only includes messages that are ready to be sent (and not waiting to be personalized). The actual number of messages sent depends on your <Link href='/settings/schedule'>email schedule</Link> and other email deliverability factors.
          </Label>
        </Container>
        <Divider />
        <ButtonContainer>
          <Button
            label='close'
            onClick={this.props.closeModal}
            mr='0.5rem'
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

MessageSchedule.propTypes = {
  messageCounts: PropTypes.object,
  schedule: PropTypes.object,
  closeModal: PropTypes.func,
  theme: PropTypes.object
}

export default withTheme(MessageSchedule)
