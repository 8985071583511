import {
  // Fetch Team Members
  FETCH_TEAM_MEMBERS_REQUEST,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,

  UPDATE_TEAM_MEMBER_REQUEST,
  UPDATE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_FAILURE,

  DELETE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_SUCCESS,
  DELETE_TEAM_MEMBER_FAILURE,

  FETCH_TEAM_INVITES_REQUEST,
  FETCH_TEAM_INVITES_SUCCESS,
  FETCH_TEAM_INVITES_FAILURE,

  DELETE_TEAM_INVITES_REQUEST,
  DELETE_TEAM_INVITES_SUCCESS,
  DELETE_TEAM_INVITES_FAILURE,

  RESEND_TEAM_INVITES_REQUEST,
  RESEND_TEAM_INVITES_SUCCESS,
  RESEND_TEAM_INVITES_FAILURE,

  SEND_TEAM_INVITE_REQUEST,
  SEND_TEAM_INVITE_SUCCESS,
  SEND_TEAM_INVITE_FAILURE,

  FETCH_TEAM_COUNTS_REQUEST,
  FETCH_TEAM_COUNTS_SUCCESS,
  FETCH_TEAM_COUNTS_FAILURE,

  FETCH_OWNER_CRM_REQUEST,
  FETCH_OWNER_CRM_SUCCESS,
  FETCH_OWNER_CRM_FAILURE,

  REFRESH_OWNER_CRM_REQUEST,

  ASSIGN_GROUP_MEMBER_REQUEST,
  ASSIGN_GROUP_MEMBER_FAILURE
} from './constants'

export function fetchTeamMembers () {
  return {
    type: FETCH_TEAM_MEMBERS_REQUEST
  }
}

export function fetchTeamMembersSuccess ({ members }) {
  return {
    type: FETCH_TEAM_MEMBERS_SUCCESS,
    payload: {
      members
    }
  }
}

export function fetchTeamMembersFailure (err) {
  return {
    type: FETCH_TEAM_MEMBERS_FAILURE,
    err
  }
}

export function fetchTeamCounts () {
  return {
    type: FETCH_TEAM_COUNTS_REQUEST
  }
}

export function fetchTeamCountsSuccess ({ counts }) {
  return {
    type: FETCH_TEAM_COUNTS_SUCCESS,
    payload: {
      counts
    }
  }
}

export function fetchTeamCountsFailure (err) {
  return {
    type: FETCH_TEAM_COUNTS_FAILURE,
    err
  }
}

export function fetchOwnerCrm (memberId) {
  return {
    type: FETCH_OWNER_CRM_REQUEST,
    memberId
  }
}

export function fetchOwnerCrmSuccess ({ crm }) {
  return {
    type: FETCH_OWNER_CRM_SUCCESS,
    crm
  }
}

export function fetchOwnerCrmFailure (err) {
  return {
    type: FETCH_OWNER_CRM_FAILURE,
    err
  }
}

export function refreshOwnerCrm (memberId, params = {}) {
  return {
    type: REFRESH_OWNER_CRM_REQUEST,
    memberId,
    params: {
      ...params,
      refresh: true
    }
  }
}

export function updateTeamMember (member, params) {
  return {
    type: UPDATE_TEAM_MEMBER_REQUEST,
    member,
    params
  }
}

export function updateTeamMemberSuccess ({ member }) {
  return {
    type: UPDATE_TEAM_MEMBER_SUCCESS,
    member
  }
}

export function updateTeamMemberFailure (err) {
  return {
    type: UPDATE_TEAM_MEMBER_FAILURE,
    err
  }
}

export function deleteTeamMember (member) {
  return {
    type: DELETE_TEAM_MEMBER_REQUEST,
    member
  }
}

export function deleteTeamMemberSuccess ({ member }) {
  return {
    type: DELETE_TEAM_MEMBER_SUCCESS,
    member
  }
}

export function deleteTeamMemberFailure (err) {
  return {
    type: DELETE_TEAM_MEMBER_FAILURE,
    err
  }
}

export function fetchTeamInvites () {
  return {
    type: FETCH_TEAM_INVITES_REQUEST
  }
}

export function fetchTeamInvitesSuccess ({ invites }) {
  return {
    type: FETCH_TEAM_INVITES_SUCCESS,
    payload: {
      invites
    }
  }
}

export function fetchTeamInvitesFailure (err) {
  return {
    type: FETCH_TEAM_INVITES_FAILURE,
    err
  }
}

export function deleteTeamInvite (invite) {
  return {
    type: DELETE_TEAM_INVITES_REQUEST,
    invite
  }
}

export function deleteTeamInviteSuccess ({ invite }) {
  return {
    type: DELETE_TEAM_INVITES_SUCCESS,
    invite
  }
}

export function deleteTeamInviteFailure (err) {
  return {
    type: DELETE_TEAM_INVITES_FAILURE,
    err
  }
}

export function resendTeamInvite (invite) {
  return {
    type: RESEND_TEAM_INVITES_REQUEST,
    invite
  }
}

export function resendTeamInviteSuccess ({ invite }) {
  return {
    type: RESEND_TEAM_INVITES_SUCCESS,
    invite
  }
}

export function resendTeamInviteFailure (err) {
  return {
    type: RESEND_TEAM_INVITES_FAILURE,
    err
  }
}

export function sendTeamInvite (params) {
  return {
    type: SEND_TEAM_INVITE_REQUEST,
    params
  }
}

export function sendTeamInviteSuccess ({ invite }) {
  return {
    type: SEND_TEAM_INVITE_SUCCESS,
    invite
  }
}

export function sendTeamInviteFailure (err) {
  return {
    type: SEND_TEAM_INVITE_FAILURE,
    err
  }
}

export function assignGroupMember (params) {
  return {
    type: ASSIGN_GROUP_MEMBER_REQUEST,
    params
  }
}

export function assignGroupMemberFailure (err) {
  return {
    type: ASSIGN_GROUP_MEMBER_FAILURE,
    err
  }
}
