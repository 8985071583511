import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  height: 35px;
  overflow: hidden;
`

const NotesWrapper = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  flex-wrap: nowrap;
  min-width: 0;
`

const Notes = styled.div`
  padding: 0.1rem 0.3rem;
  margin-right: 4px;

  border-radius: 3px;
  border: 1px solid ${props => props.theme.colors.gray30};
  background-color: ${props => props.theme.colors.gray20};
  font: ${props => props.theme.fonts.label};
  color: ${props => props.theme.colors.darkBlue};

  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

function TagsCellRenderer ({
  value,
  ...props
}) {
  useEffect(() => {
    if (props?.cellProperties?.className) {
      props.TD.className = props.cellProperties.className
    }
  })

  const renderNotes = () => value?.split(',')?.map((n, i) => (n
    ? <Notes key={i}>{n}</Notes>
    : null))

  return (
    <Wrapper>
      <NotesWrapper>
        {renderNotes()}
      </NotesWrapper>
    </Wrapper>
  )
}

TagsCellRenderer.propTypes = {
  cellProperties: PropTypes.object,
  value: PropTypes.string,
  TD: PropTypes.object
}

export default withTheme(TagsCellRenderer)
