import styled from 'styled-components'

const Wrapper = styled.div`
  transition: background-color 0.3s ease, color .3s ease;
  position: relative;
  overflow: visible;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-top: 2rem;
  padding: 0 2rem;
`

export default Wrapper
