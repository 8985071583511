import React from 'react'
import {
  Route,
  IndexRoute,
  IndexRedirect,
  Redirect
} from 'react-router'

// App Pages
import App from './containers/App'
import EnsureLoggedIn from './containers/EnsureLoggedIn'

// Dashboard Pages
import DashboardPage from './containers/Dashboard'

// Sequence Pages
import SequencePage from './containers/Sequence'
import StepsPage from './containers/Sequence/components/Steps'
import PreviewPage from './containers/Sequence/components/Preview'
import ImportPage from './containers/Sequence/components/Import'
import ImportCrmPage from './containers/Sequence/components/ImportCrm'
import SequenceSettings from './containers/Sequence/components/Settings'
import SequenceSchedule from './containers/Sequence/components/Settings/Schedule'

// Contact Sidebar
import ContactPage from './containers/Sequence/components/Contacts'
import ContactSidebar from './containers/Sequence/components/Contacts/ContactDetail'
import ContactMain from './containers/Sequence/components/Contacts/ContactDetail/ContactMain'
import ContactActivity from './containers/Sequence/components/Contacts/ContactDetail/ContactActivity'
import ContactCompany from './containers/Sequence/components/Contacts/ContactDetail/ContactCompany'
import ContactWorkHistory from './containers/Sequence/components/Contacts/ContactDetail/ContactWorkHistory'

// Tasks
import TaskPage from './containers/Tasks'
import EmailTasks from './containers/Tasks/components/EmailTasks'
import ContactTasks from './containers/Tasks/components/ContactTasks'
import ReplyTasks from './containers/Tasks/components/ReplyTasks'
import SequenceTasks from './containers/Tasks/components/SequenceTasks'
import ManualTasks from './containers/Tasks/components/ManualTasks'

// Onboarding Pages
import Onboarding from './containers/Onboarding'
import EmailPrompt from './containers/Onboarding/components/EmailPrompt'
import GoogleLogin from './containers/Onboarding/components/Google'
import MicrosoftLogin from './containers/Onboarding/components/Microsoft'
import NylasLogin from './containers/Onboarding/components/Nylas'
import MagicLink from './containers/Onboarding/components/MagicLink'
import Login from './containers/Onboarding/components/Login'

// Non-active page
import Paywall from './containers/Paywall'

// Billing Pages
import Billing from './containers/Billing'

// Settings Pages
import SettingsPage from './containers/Settings'
import GeneralSettingsPage from './containers/Settings/components/General'
import TrackingSettingsPage from './containers/Settings/components/Tracking'
import ScheduleSettingsPage from './containers/Settings/components/Schedule'
import SafetySettingsPage from './containers/Settings/components/Safety'
import NotificationSettingsPage from './containers/Settings/components/Notifications'
import InboxSettingsPage from './containers/Settings/components/Inbox'
import TeamSafetyPage from './containers/Settings/components/TeamSafety'
import PrivacyPage from './containers/Settings/components/Privacy'
import ExportsPage from './containers/Settings/components/Exports'
import LogsSettingsPage from './containers/Settings/components/Logs'

// Team Page
import TeamPage from './containers/Team'

// Integrations Pages
import IntegrationsPage from './containers/Integrations'
import CrmPage from './containers/Integrations/components/Crm'
import ZapierPage from './containers/Integrations/components/Zapier'
import ConnectCRM from './containers/Integrations/components/Crm/ConnectCRM'
import ApiPage from './containers/Integrations/components/Api'
import ChromeExtensionPage from './containers/Integrations/components/ChromeExtension'

import Templates from './containers/Templates'

// Reports
import Reports from './containers/Reports'
import BookedReport from './containers/Reports/components/Reports/BookedReport'
import RepliedReport from './containers/Reports/components/Reports/RepliedReport'
import SequenceReport from './containers/Reports/components/Reports/SequenceReport'
import ContactsCreatedReport from './containers/Reports/components/Reports/ContactsCreatedReport'
import MessagedReport from './containers/Reports/components/Reports/MessagedReport'
import LeaderboardReport from './containers/Reports/components/Reports/LeaderboardReport'
import BounceRateReport from './containers/Reports/components/Reports/BounceRateReport'
import ActivityReport from './containers/Reports/components/Reports/ActivityReport'
import ContactTaskReport from './containers/Reports/components/Reports/ContactTaskReport'
import MessagePerformanceReport from './containers/Reports/components/Reports/MessagePerformanceReport'
import ContactPerformanceReport from './containers/Reports/components/Reports/ContactPerformanceReport'
import StepPerformanceReport from './containers/Reports/components/Reports/StepPerformanceReport'
import SequencesReports from './containers/Reports/components/Reports/SequencesReport'

// Talent Pool
import TalentPool from './containers/TalentPool'

export default (
  <Route path='/' component={App}>
    <Route path='login' component={Onboarding} signup={false}>
      <IndexRoute component={EmailPrompt} />
      <Route path='google' component={GoogleLogin} />
      <Route path='microsoft' component={MicrosoftLogin} />
      <Route path='nylas' component={NylasLogin} />
      <Route path='magic' component={MagicLink} />
      <Route path='unknown' component={Login} />
    </Route>
    <Route path='signup' component={Onboarding} signup>
      <IndexRoute component={EmailPrompt} />
      <Route path='google' component={GoogleLogin} />
      <Route path='microsoft' component={MicrosoftLogin} />
      <Route path='unknown' component={Login} />
    </Route>
    <Route path='paywall' component={Paywall}>
      <IndexRedirect to='billing' />
      <Route path='billing' component={Billing} />
      <Route path='team' component={TeamPage} />
    </Route>
    <Route component={EnsureLoggedIn}>
      <Route path='tasks' component={TaskPage}>
        <IndexRedirect to='emails' />
        <Route name='emails' path='emails' component={EmailTasks} />
        <Route name='calls' path='calls' component={EmailTasks} />
        <Route name='contacts' path='contacts' component={ContactTasks} />
        <Route name='replies' path='replies' component={ReplyTasks} />
        <Route name='manual' path='manual' component={ManualTasks} />
      </Route>
      <IndexRoute component={DashboardPage} />
      <Route path='sequence'>
        <Route path=':id' component={SequencePage}>
          <IndexRedirect to='contacts' />
          <Route name='contacts' path='contacts' component={ContactPage}>
            <Route path=':contactId' component={ContactSidebar}>
              <IndexRedirect to='detail' />
              <Redirect from='details' to='detail' />
              <Route name='detail' path='detail' component={ContactMain} />
              <Route path='activity' component={ContactActivity} />
              <Route path='company' component={ContactCompany} />
              <Route path='workhistory' component={ContactWorkHistory} />
            </Route>
          </Route>
          <Route path='import' component={ImportPage} />
          <Route path='import-crm' component={ImportCrmPage} />
          <Route path='steps' component={StepsPage} />
          <Route path='preview' component={PreviewPage} />
          <Route path='tasks' component={TaskPage}>
            <IndexRoute component={SequenceTasks} />
          </Route>
          <Route path='settings' component={SequenceSettings}>
            <IndexRedirect to='schedule' />
            <Route name='schedule' path='schedule' component={SequenceSchedule} />
          </Route>
        </Route>
      </Route>
      <Route path='settings' component={SettingsPage}>
        <IndexRedirect to='general' />
        <Route name='general' path='general' component={GeneralSettingsPage} />
        <Route path='tracking' component={TrackingSettingsPage} />
        <Route path='schedule' component={ScheduleSettingsPage} />
        <Route path='safety' component={SafetySettingsPage} />
        <Route path='inbox' component={InboxSettingsPage} />
        <Route path='notifications' component={NotificationSettingsPage} />
        <Route path='teamsafety' component={TeamSafetyPage} />
        <Route path='privacy' component={PrivacyPage} />
        <Route path='exports' component={ExportsPage} />
        <Route path='logs' component={LogsSettingsPage} />
      </Route>
      <Route path='team' component={TeamPage} />
      <Route path='billing' component={Billing} />
      <Route path='integrations' component={IntegrationsPage}>
        <IndexRedirect to='crm' />
        <Route name='crm' path='crm' component={CrmPage}>
          <Route path=':type' component={ConnectCRM} />
        </Route>
        <Route path='zapier' component={ZapierPage} />
        <Route path='api' component={ApiPage} />
        <Route path='chrome' component={ChromeExtensionPage} />
      </Route>
      <Route path='reports' component={Reports}>
        <IndexRedirect to='leaderboard' />
        <Route path='booked' component={BookedReport} />
        <Route path='replied' component={RepliedReport} />
        <Route path='messaged' component={MessagedReport} />
        <Route path='created' component={ContactsCreatedReport} />
        <Route path='sequence' component={SequenceReport} />
        <Route path='leaderboard' component={LeaderboardReport} />
        <Route path='bounced' component={BounceRateReport} />
        <Route path='activity' component={ActivityReport} />
        <Route path='message_performance' component={MessagePerformanceReport} />
        <Route path='contact_performance' component={ContactPerformanceReport} />
        <Route path='step_performance' component={StepPerformanceReport} />
        <Route path='sequence_activity' component={SequencesReports} />
        <Route path='tasks' component={ContactTaskReport} />
      </Route>

      <Route path='talent_pool' component={TalentPool} />

      {/* here so old links are compatible with the new routing */}
      <Route path='reports-v3' component={Reports}>
        <IndexRedirect to='leaderboard' />
        <Route path='booked' component={BookedReport} />
        <Route path='replied' component={RepliedReport} />
        <Route path='messaged' component={MessagedReport} />
        <Route path='created' component={ContactsCreatedReport} />
        <Route path='sequence' component={SequenceReport} />
        <Route path='leaderboard' component={LeaderboardReport} />
        <Route path='bounced' component={BounceRateReport} />
        <Route path='activity' component={ActivityReport} />
      </Route>
      <Route path='templates'>
        <IndexRoute component={Templates} />
      </Route>
    </Route>

    {/* Redirect routes from old app  */}
    <Redirect from='campaigns' to='/' />
    <Redirect from='campaigns/list' to='/' />
    <Redirect from='campaigns/:id' to='/sequence/:id' />
    <Redirect from='campaigns/:id/steps' to='/sequence/:id/steps' />
    <Redirect from='campaigns/:id/contacts' to='/sequence/:id/contacts' />
    <Redirect from='/settings/email' to='/settings/general' />

  </Route>
)
