import React from 'react'
import SequenceHeaders from '../SequenceHeaders'

export default function sequenceHeaderRowRenderer ({
  className,
  columns,
  style,
  selected,
  currentSortKey,
  onSort,
  onSelectAll
}) {
  return (
    <div className={className} role='row' style={style}>
      <SequenceHeaders
        selected={selected}
        currentKey={currentSortKey}
        onSelected={onSelectAll}
        sortFunction={(sortKey) => {
          onSort(sortKey)
        }}
      />
    </div>
  )
}
