import React from 'react'

/* eslint-disable */
const Calendar = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
  viewBox,
}) => (
    <svg 
        onClick={onClick} 
        className={className} 
        width={width || "19px"} 
        height={height || "19px"} 
        viewBox={viewBox ||"0 0 19 19"} 
        version="1.1" xmlns="http://www.w3.org/2000/svg">
        <g id="Campaign-Sequence" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
            <g id="Campaign-Sequence---A/B" transform="translate(-1270.000000, -308.000000)" stroke="#7A859F">
                <g id="Group" transform="translate(1229.000000, 300.000000)">
                    <g stroke={color} id="filter-picture" transform="translate(42.000000, 9.000000)">
                        <polygon stroke={color} id="Stroke-1" strokeLinecap="round" points="0.404761905 16.5952381 16.5952381 16.5952381 16.5952381 0.404761905 0.404761905 0.404761905"></polygon>
                        <polyline stroke={color} id="Stroke-3" points="14.5714286 13.3571429 12.1428571 6.88095238 8.9047619 11.7380952 6.88095238 9.30952381 4.45238095 13.3571429"></polyline>
                        <g id="Group-10" transform="translate(0.000000, 3.238095)">
                            <path fill={color} d="M5.66666667,0.404761905 C6.33695238,0.404761905 6.88095238,0.948761905 6.88095238,1.61904762 C6.88095238,2.28933333 6.33695238,2.83333333 5.66666667,2.83333333 C4.99638095,2.83333333 4.45238095,2.28933333 4.45238095,1.61904762 C4.45238095,0.948761905 4.99638095,0.404761905 5.66666667,0.404761905 Z" id="Stroke-4"></path>
                            <path fill={color} d="M16.5952381,10.1190476 L0.404761905,10.1190476" id="Stroke-6"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </svg>
);

export default Calendar;
