import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { DATE_RANGE } from 'containers/Reports/utils/date_helpers'

const GraphControls = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  width: 100%;

  padding: 0.5rem;
  padding-bottom: 0;
  padding-top: 0;

  cursor: pointer;
  user-select: none;
`

const DateRangeSubMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const DateRange = styled.div`
  margin-right: 0.5rem;
  padding: .5rem .25rem;
  text-transform: capitalize;
  color: ${props => props.theme.colors.darkBlue};
  font: ${props => props.theme.fonts.normal};
  font-size: 13px;
  cursor: pointer;
  border-top: ${props => (props.selected)
    ? `solid 2px ${props.theme.colors.blue}`
    : 'solid 2px transparent'
  };

  &:hover {
    opacity: .6;
  }

  &:last-child {
    margin-right: 1rem;
  }
`

class DateSelector extends React.Component {
  render () {
    const {
      selectedDateRange,
      dayDateRangeUpdate,
      weekDateRangeUpdate
    } = this.props

    return (
      <GraphControls>
        <DateRangeSubMenu>
          <DateRange
            selected={selectedDateRange === DATE_RANGE.DAY}
            onClick={_ => dayDateRangeUpdate()}
          >
            Day
          </DateRange>
          <DateRange
            selected={selectedDateRange === DATE_RANGE.WEEK}
            onClick={_ => weekDateRangeUpdate()}
          >
            Week
          </DateRange>
        </DateRangeSubMenu>
      </GraphControls>
    )
  }
}

DateSelector.propTypes = {
  dayDateRangeUpdate: PropTypes.func,
  weekDateRangeUpdate: PropTypes.func,
  selectedDateRange: PropTypes.string
}

export default DateSelector
