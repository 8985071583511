import { createSelector } from 'reselect'

const selectReportsDomain = () => state => state.get('reports')

const selectReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('reports')
)

const selectContrastPeriodReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('contrastPeriodReports')
)

const selectUserReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('userReports')
)

const selectSequenceReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('sequenceReports')
)

const selectMyReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('myReports')
)

const selectBookedReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('bookedReports')
)

const selectRepliedReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('repliedReports')
)

const selectContactsCreatedReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('contactsCreatedReports')
)

const selectMessagedReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('messagedReports')
)

const selectExportedReport = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('exportedReport')
)

const selectBounceRateReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('bounceRateReports')
)

const selectReplyHoursReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('replyHoursReports')
)

const selectOpenHoursReports = () => createSelector(
  selectReportsDomain(),
  reportsState => reportsState.get('openHoursReports')
)

const selectContactTaskReports = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('contactTaskReports')
)

const selectLeaderboard = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('leaderboard')
)

const selectLeaderboardContrast = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('leaderboardContrast')
)

const selectLeaderboardGroups = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('leaderboardGroups')
)

const selectLeaderboardGroupsContrast = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('leaderboardGroupsContrast')
)

const selectPersonalizedMessagesReports = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('personalizedMessagesReports')
)

const selectStepPerformance = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('stepPerformanceReport')
)

const selectSequencesReports = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('sequencesReports')
)

const selectBounceReasonReports = () => createSelector(
  selectReportsDomain(),
  reportState => reportState.get('bounceReasonReports')
)

export {
  selectReports,
  selectContrastPeriodReports,
  selectUserReports,
  selectSequenceReports,
  selectMyReports,
  selectBookedReports,
  selectRepliedReports,
  selectContactsCreatedReports,
  selectMessagedReports,
  selectExportedReport,
  selectBounceRateReports,
  selectReplyHoursReports,
  selectOpenHoursReports,
  selectContactTaskReports,
  selectLeaderboard,
  selectLeaderboardContrast,
  selectLeaderboardGroups,
  selectLeaderboardGroupsContrast,
  selectPersonalizedMessagesReports,
  selectStepPerformance,
  selectSequencesReports,
  selectBounceReasonReports
}
