import React from 'react'
import PropTypes from 'prop-types'
import DropDown from 'components/DropDown'
import Immutable from 'immutable'

class ReportDropdown extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const { router, fetchReports, state } = this.props

    const routes = router.routes
    const currentRoute = routes[routes.length - 1].path

    // reports all users have access to
    let reports = [
      { label: 'Contacts Added', value: 'created' },
      { label: 'Messages Sent', value: 'messaged' },
      { label: 'Tasks Completed', value: 'tasks' },
      { label: 'Messages Bounced', value: 'bounced' },
      { label: 'Contacts Replied', value: 'replied' },
      { label: 'Meetings Booked', value: 'booked' },
      { label: 'Contact Activity', value: 'activity' }
    ]

    // reports for anyone with admin/manager permissions
    if (state.get('is_manager')) {
      reports.push({ label: 'Leaderboard', value: 'leaderboard' })
      reports.push({ label: 'Sequence Activity', value: 'sequence_activity' })
      reports.push({ label: 'Message Performance', value: 'message_performance' })
      reports.push({ label: 'Contact Performance', value: 'contact_performance' })
      reports.push({ label: 'Step Performance', value: 'step_performance' })
    }

    // The `DropDown` component expects the array to be an `Immutable.List` object
    reports = Immutable.List(reports)

    return (
      <>
        <DropDown
          options={reports}
          value={currentRoute}
          clearable={false}
          height='3rem'
          onOptionChange={option => {
            const { value } = option
            if (value) {
              const route = `/reports/${value}/`
              router.push(route)

              fetchReports()
            }
          }}
        />
      </>
    )
  }
}

ReportDropdown.propTypes = {
  router: PropTypes.object,
  fetchReports: PropTypes.func,
  state: PropTypes.object
}

export default ReportDropdown
