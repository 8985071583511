import React from 'react'

/* eslint-disable */
const Satellite = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (

<svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 73} height={height || 73}  viewBox="0 0 73 73" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="-40.5930765%" y1="138.278072%" x2="122.714702%" y2="-20.8762692%" id="linearGradient-1">
            <stop stopColor="#6E98F0" offset="42.46%"></stop>
            <stop stopColor="#4B4784" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-22.00569%" y1="139.539709%" x2="124.800084%" y2="-0.316679656%" id="linearGradient-2">
            <stop stopColor="#6E98F0" offset="42.46%"></stop>
            <stop stopColor="#4B4784" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="Campaign-List" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Campaign-List---Onboarding" transform="translate(-647.000000, -227.000000)">
            <g id="satellite" transform="translate(648.000000, 228.000000)">
                <path d="M36,35 L20,19" id="Shape" stroke="#FFFEFD" strokeWidth="2" strokeLinecap="round"></path>
                <rect id="Rectangle-path" fill="#A6B1E8" fillRule="nonzero" opacity="0.9" transform="translate(16.000000, 55.500000) rotate(-45.000000) translate(-16.000000, -55.500000) " x="1" y="48" width="30" height="15"></rect>
                <path d="M45.6206897,44.670418 L45.6206897,44.670418 C42.5220529,47.7765273 37.4314354,47.7765273 34.2590217,44.670418 L27.3239775,37.7186495 C24.2253408,34.6125402 24.2253408,29.5096463 27.3239775,26.329582 L27.3239775,26.329582 C30.4226143,23.2234727 35.5132318,23.2234727 38.6856455,26.329582 L45.6206897,33.2813505 C48.7931034,36.4614148 48.7931034,41.5643087 45.6206897,44.670418 Z" id="Shape" fill="url(#linearGradient-1)" fillRule="nonzero"></path>
                <path d="M22.3513514,10 L11,21.3513514 L12.3267813,22.6781327 C15.4226044,25.7739558 20.5085995,25.7739558 23.6781327,22.6781327 L23.6781327,22.6781327 C26.7739558,19.5823096 26.7739558,14.4963145 23.6781327,11.3267813 L22.3513514,10 Z" id="Shape" fill="url(#linearGradient-2)" fillRule="nonzero"></path>
                <rect id="Rectangle-path" fill="#A6B1E8" fillRule="nonzero" opacity="0.9" transform="translate(55.000000, 16.500000) rotate(-45.000000) translate(-55.000000, -16.500000) " x="40" y="9" width="30" height="15"></rect>
                <g id="Group" transform="translate(39.000000, 0.000000)" stroke="#FFFEFD" strokeWidth="2" strokeLinecap="round">
                    <path d="M10.7636364,31.4909091 L0.509090909,21.2363636" id="Shape"></path>
                    <path d="M21.7454545,0 L32,10.2545455" id="Shape"></path>
                    <path d="M14.6909091,7.05454545 L24.9454545,17.3818182" id="Shape"></path>
                    <path d="M7.56363636,14.1818182 L17.8909091,24.4363636" id="Shape"></path>
                </g>
                <g id="Group" transform="translate(0.000000, 39.000000)" stroke="#FFFEFD" strokeWidth="2" strokeLinecap="round">
                    <path d="M10.2545455,32 L0,21.7454545" id="Shape"></path>
                    <path d="M21.2363636,0.509090909 L31.4909091,10.7636364" id="Shape"></path>
                    <path d="M14.1818182,7.56363636 L24.4363636,17.8909091" id="Shape"></path>
                    <path d="M7.05454545,14.6909091 L17.3818182,24.9454545" id="Shape"></path>
                </g>
                <circle id="Oval" fill="#FFFEFD" fillRule="nonzero" transform="translate(12.000000, 11.000000) rotate(-45.000000) translate(-12.000000, -11.000000) " cx="12" cy="11" r="2"></circle>
                <rect id="Rectangle-path" fill="#A6B1E8" fillRule="nonzero" transform="translate(34.000000, 36.500000) rotate(-45.000000) translate(-34.000000, -36.500000) " x="32" y="31" width="4" height="11"></rect>
            </g>
        </g>
    </g>
</svg>
);

export default Satellite;
