import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { SEARCH_TABS } from '../../../../constants'

const Wrapper = styled.div`
  display: flex;
  justify-content: space-around;
`

const Tab = styled.button`
  padding: 1rem 0;
  flex: auto;
  font: ${props => props.theme.fonts.label};
  color: #687186;
  text-transform: uppercase;
  border: 0;
  border-bottom: 1px solid #D8D8D8;
  cursor: pointer;

  ${props => props.active && css`
    color: ${props.theme.colors.blue};
    border-bottom: 1px solid ${props.theme.colors.blue};
  `};
`

const Total = styled.span`
  min-width: 22px;
  width: max-content;
  height: 22px;
  line-height: 1.5;
  font: ${props => props.theme.fonts.button};
  color: ${props => props.theme.colors.gray70};
  border: 1px solid #E1E5EC;
  border-radius: 12.5px;
  margin-left: 0.25rem;
  padding: 0 2px;
  display: inline-block;
  background-color: ${props => props.theme.colors.gray20};
`

function SearchTabs ({
  activeTab,
  setActiveTab,
  totalResults,
  totalContacts,
  totalCampaigns
}) {
  const TABS = [
    {
      label: SEARCH_TABS.ALL,
      total: totalResults
    },
    {
      label: SEARCH_TABS.SEQUENCES,
      total: totalCampaigns
    },
    {
      label: SEARCH_TABS.CONTACTS,
      total: totalContacts
    }
  ]

  return (
    <Wrapper>
      {TABS.map((tab) => (
        <Tab
          key={tab.label}
          active={activeTab === tab.label}
          onClick={() => setActiveTab(tab.label)}
        >
          {tab.label}
          <Total>{tab.total}</Total>
        </Tab>
      ))}
    </Wrapper>
  )
}

SearchTabs.propTypes = {
  activeTab: PropTypes.string,
  setActiveTab: PropTypes.func,
  totalResults: PropTypes.number,
  totalContacts: PropTypes.number,
  totalCampaigns: PropTypes.number
}

export default SearchTabs
