import React, { Component } from 'react'
import { Tooltip } from 'react-tippy'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import IconButton from 'components/IconButton'

const ButtonContainer = styled.div`
  margin-right: 0.5rem;

  &:last-child {
    margin-right: 0;
  }
`

class ToolbarButton extends Component {
  render () {
    const {
      tooltip,
      icon,
      size,
      onClick,
      disabled = false
    } = this.props

    return (
      <ButtonContainer>
        <Tooltip
          style={{ display: 'block' }}
          title={tooltip}
          position='top'
          trigger='mouseenter'
          arrow
        >
          <IconButton
            type={icon}
            size={size}
            onClick={onClick}
            disabled={disabled}
          />
        </Tooltip>
      </ButtonContainer>
    )
  }
}

ToolbarButton.propTypes = {
  tooltip: PropTypes.string,
  icon: PropTypes.array,
  size: PropTypes.number,
  onClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default ToolbarButton
