import React, { PureComponent } from 'react'
import Immutabale from 'immutable'
import PropTypes from 'prop-types'
import { browserHistory } from 'react-router'
import styled, { withTheme } from 'styled-components'
import { Tooltip } from 'react-tippy'
import {
  FIELD_BLOCKLIST,
  TOP_LEVEL_FIELD_VALUES
} from 'containers/Sequence/constants'
import Button from 'components/Button'
import StatusText from 'components/StatusText'
import Divider from 'elements/Divider'
import Label from 'elements/Label'
import FormLabel from 'elements/FormLabel'
import EmailValidityIcon from 'elements/EmailValidityIcon'
import IconButton from 'components/IconButton'
import DetailRow from 'components/DetailRow'
import Loading from 'components/Loading'
import { fadeIn } from 'utils/animations'
import { titlecase } from 'utils/strings'
import { Steps } from 'svg'
import ContactEdit from './ContactEdit'
import IntegrationLink from './IntegrationLink'
import SequenceRow from './SequenceRow'
import ContactOptions from '../../ContactOptions'

const Wrapper = styled.div`
  display: block;
  position: relative;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  padding-bottom: 8rem;
`

const ContentContainer = styled.div`
  flex: 1 0 auto;
  width: 100%;
  height: fit-content;
  padding: 1rem;
  padding-bottom: 5rem;
`

const ContactTitle = styled.div`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

const ContactLocation = styled(Label)`
  text-align: center;
  margin-left: auto;
  margin-right: auto;
`

const Pronoun = styled.div`
  font-size: 15px;
  font-weight: 300;
  color: ${props => `${props.theme.colors.gray70}`};
`

const SocialContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  text-align: center;
  margin-left: auto;
  margin-right: auto;
  padding-top: ${props => props.tp};
`

const MailDetailContainer = styled.div`
  padding-top: 1rem;
  padding-bottom: 2rem;
`

const FormContainer = styled.div`
  width: 100%;

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`

const Toolbar = styled.div`
  background-color: ${props => props.theme.containerBackground};
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: ${props => props.theme.padding};
  height: 60px;
  border-bottom: ${props => `1px solid ${props.theme.colors.gray20}`};
`

const GroupedButtons = styled.div`
  display: flex;
  animation: ${fadeIn} .2s linear 1;

  >* {
    margin-right: 0.5rem;
  }

  >*:last-child {
    margin-right: 0;
  }
`

const FormValue = styled(Label)`
  display: inline;
  float: right;

  margin-right: 0.25rem;
  line-height: 22px;
`

const CustomEmailGroup = styled.div`
  display: inline;
  float: right;
`

const EmailValidityContainer = styled.div`
  display: inline-block;
  top: -7px;
  position: relative;
  margin-left: 6px;
`

const EmailFormValue = styled.div`
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  max-width: 244px;
`

const DetailLabel = styled(Label)`
  display: inline;
  float: right;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 0.25rem;
  line-height: 22px;
  word-break: break-word;
`

const DetailAction = styled(DetailLabel)`
  color: ${props => props.theme.colors.blue};
  border-bottom: 1px dotted ${props => props.theme.colors.blue};
  cursor: pointer;
`

const DetailError = styled(DetailLabel)`
  color: ${props => props.theme.colors.red};
`

const LoadingIndicator = styled(Loading)`
  float: right;
`

class ContactMain extends PureComponent {
  constructor (props) {
    super(props)

    const isEditing = (props.location.state && props.location.state.editing)
      ? props.location.state.editing
      : false

    this.state = {
      editing: isEditing
    }
  }

  UNSAFE_componentWillMount () {
    // this helps ensure that we're consistently listening for state changes
    // on the same route to trigger a state update
    this.browserHistoryUnlisten = browserHistory.listen(location => {
      if (location.state?.editing != null) {
        this.setState({
          editing: location.state.editing
        })
      }
    })
  }

  componentWillUnmount () {
    this.browserHistoryUnlisten()
  }

  render () {
    const {
      contact,
      contactPhone,
      contactIntegration,
      sequence,
      similarContacts,
      onMoveContact,
      onDeleteContact,
      onSaveContact,
      onRepliedContactUpdate,
      onUnsubscribeContact,
      onBlockContact,
      onAssignSentiment,
      onCustomizeMessages,
      onPauseContact,
      onSendNow,
      onCopyContact,
      onCloneContact,
      onUpdateContact,
      onClearError,
      onSearchPhone,
      onReportContactInaccuracy,
      router,
      crm,
      session,
      isArchived,
      onResyncCrm,
      onJumpToTask
    } = this.props

    const {
      editing
    } = this.state

    const name = contact.get('name')
    const email = contact.get('email')
    const title = contact.get('title')
    const location = contact.get('location')
    const pronoun = contact.get('pronoun')
    const company = contact.get('company')
    const phone = contact.get('phone_number')
    const twitterLink = contact.getIn(['social_urls', 'twitter'])
    const linkedInLink = contact.getIn(['social_urls', 'linkedin'])
    const githubLink = contact.getIn(['social_urls', 'github'])
    const hasSocialLink = twitterLink || linkedInLink || githubLink
    const currentStep = contact.get('step') === undefined ? -1 : contact.get('step')
    const steps = sequence.get('steps') || []
    const totalSteps = steps.size
    const hasCrm = !!crm.getIn(['data', 'type'])

    const validity = contact.get('validity')

    const rawFields = contact.get('raw') || Immutabale.Map({})
    const contactFinished = contact.get('finished')
    const hasStepsToSend = (currentStep + 1) < totalSteps
    const anonymized = contact.get('anonymized')

    const visibleFields = (sequence.get('fields') || [])
      .filter(f => !FIELD_BLOCKLIST.includes(f) &&
        !TOP_LEVEL_FIELD_VALUES.includes(f) && rawFields.get(f))
    const customFields = visibleFields.map((key) => {
      const value = rawFields.get(key)
      const label = titlecase(key.replace(/_/g, ' '))
      return (
        <FormContainer key={`customfield_${key}`}>
          <Divider />
          <DetailRow label={label} value={value} />
        </FormContainer>
      )
    })

    const topSocialPadding = title || location ? '1rem' : 0

    const lastAddedContact = similarContacts.getIn(['data', 'last_added'])
    const lastMessagedContact = similarContacts.getIn(['data', 'last_messaged'])
    const lastRepliedContact = similarContacts.getIn(['data', 'last_replied'])
    const featureFlags = session.get('feature_flags') || session.get('whitelist')

    return (
      <Wrapper>
        {isArchived &&
          <Toolbar>
            <GroupedButtons />
            <GroupedButtons>
              <Tooltip
                style={{ display: 'block' }}
                key='clear'
                title='Copy contact to another sequence'
                position='top'
                trigger='mouseenter'
                arrow
              >
                <IconButton
                  onClick={() => {
                    onCopyContact(contact)
                  }}
                  type={['fal', 'copy']}
                />
              </Tooltip>
              <Tooltip
                style={{ display: 'block' }}
                key='clear'
                title='Download PDF'
                position='top'
                trigger='mouseenter'
                arrow
              >
                <IconButton
                  onClick={() => {
                    window.location = `https://interseller.io/api/contacts/${contact.get('_id')}/pdf`
                  }}
                  type={['far', 'file-export']}
                />
              </Tooltip>
            </GroupedButtons>
          </Toolbar>}
        {editing &&
          <Toolbar>
            <GroupedButtons>
              <Button
                label='Cancel'
                small
                handleClick={() => {
                  this.setState({
                    editing: false
                  })
                }}
              />
            </GroupedButtons>
            <GroupedButtons>
              <Button
                label='Save'
                small
                primary
                handleClick={() => {
                  this.setState({
                    editing: false
                  })
                  onSaveContact(this.editForm.state.params)
                }}
              />
            </GroupedButtons>
          </Toolbar>}
        {!editing && !isArchived &&
          <Toolbar>
            <GroupedButtons>
              <Button
                label='Edit'
                small
                handleClick={() => {
                  this.setState({
                    editing: true
                  })
                }}
              />
            </GroupedButtons>
            <GroupedButtons>
              <Tooltip
                title={`Remove ${name} from sequence`}
                position='top'
                trigger='mouseenter'
                arrow
              >
                <IconButton
                  onClick={onDeleteContact}
                  type={['fal', 'trash-alt']}
                  translateY={-1}
                />
              </Tooltip>
              <ContactOptions
                trigger={
                  <IconButton
                    type={['far', 'ellipsis-v']}
                  />
                }
                router={router}
                sequence={sequence}
                contact={contact}
                crm={crm}
                contactFinished={contactFinished}
                hasStepsToSend={hasStepsToSend}
                hasCrm={hasCrm}
                isArchived={isArchived}
                onSendNow={onSendNow}
                onMoveContact={onMoveContact}
                onCopyContact={onCopyContact}
                onDeleteContact={onDeleteContact}
                onResyncCrm={onResyncCrm}
                onUnsubscribeContact={onUnsubscribeContact}
                onBlockContact={onBlockContact}
                onReportContactInaccuracy={onReportContactInaccuracy}
              />
            </GroupedButtons>
          </Toolbar>}
        {editing &&
          <ContentContainer>
            <ContactEdit
              ref={(element) => { this.editForm = element }}
              contact={contact}
              sequence={sequence}
              onCloneContact={() => {
                onCloneContact(contact)
              }}
            />
          </ContentContainer>}
        {!editing &&
          <ContentContainer>
            {(title || location || hasSocialLink) &&
              <MailDetailContainer>
                {title && (<ContactTitle>{title}</ContactTitle>)}
                {location && <ContactLocation>{location}</ContactLocation>}
                {pronoun && <Pronoun>{pronoun}</Pronoun>}
                {hasSocialLink && !anonymized &&
                  <SocialContainer tp={topSocialPadding}>
                    {linkedInLink &&
                      <IconButton
                        onClick={() => {
                          window.open(linkedInLink)
                        }}
                        type={['fab', 'linkedin-in']}
                        marginLeft='0.25rem'
                        marginRight='0.25rem'
                        size={25}
                        buttonColor={this.props.theme.colors.linkedin}
                        borderColor={this.props.theme.colors.linkedin}
                        iconColor='#FFF'
                      />}
                    {githubLink &&
                      <IconButton
                        onClick={() => {
                          window.open(githubLink)
                        }}
                        type={['fab', 'github']}
                        marginLeft='0.25rem'
                        marginRight='0.25rem'
                        size={25}
                        buttonColor={this.props.theme.colors.github}
                        borderColor={this.props.theme.colors.github}
                        iconColor='#FFF'
                      />}
                    {twitterLink &&
                      <IconButton
                        onClick={() => {
                          window.open(twitterLink)
                        }}
                        type={['fab', 'twitter']}
                        marginLeft='0.25rem'
                        marginRight='0.25rem'
                        size={25}
                        buttonColor={this.props.theme.colors.twitter}
                        borderColor={this.props.theme.colors.twitter}
                        iconColor='#FFF'
                      />}
                  </SocialContainer>}
              </MailDetailContainer>}
            {(title || location || hasSocialLink) && <Divider />}
            {contactIntegration && contactIntegration.get('url') && !anonymized &&
              <IntegrationLink crm={contactIntegration} />}
            {contactIntegration && contactIntegration.get('url') && <Divider />}
            <FormLabel label='Status'>
              <StatusText
                position='right'
                align='right'
                contact={contact}
                crm={crm}
                onReportContactInaccuracy={() => {
                  onReportContactInaccuracy(contact)
                }}
                onUpdateContact={(params) => {
                  onUpdateContact(contact.get('_id'), contact.get('_campaign'), params)
                }}
                onRepliedContactUpdate={onRepliedContactUpdate}
                onCustomizeMessages={() => {
                  onCustomizeMessages(contact)
                }}
                onPauseContact={() => {
                  onPauseContact(contact)
                }}
                onUnsubscribeContact={() => {
                  onUnsubscribeContact(contact)
                }}
                onCloneContact={() => {
                  onCloneContact(contact)
                }}
                onBlockContact={() => {
                  onBlockContact(contact)
                }}
                onAssignSentiment={() => {
                  onAssignSentiment(contact)
                }}
                onClearError={() => {
                  onClearError(contact)
                }}
                p={0}
                readOnly={isArchived}
                onJumpToTask={() => {
                  onJumpToTask(contact)
                }}
              />
            </FormLabel>
            <Divider />
            <FormLabel label='Step'>
              <Steps
                contact={contact}
                current={currentStep + 1}
                total={totalSteps}
                mr='-0.6rem'
              />
            </FormLabel>
            {email && !anonymized &&
              <FormContainer>
                <Divider />
                <DetailRow label='Email'>
                  <CustomEmailGroup>
                    <FormValue>
                      <EmailFormValue>{email}</EmailFormValue>
                      <EmailValidityContainer>
                        <EmailValidityIcon contactValidity={validity} />
                      </EmailValidityContainer>
                    </FormValue>
                  </CustomEmailGroup>
                </DetailRow>
              </FormContainer>}
            {company &&
              <FormContainer>
                <Divider />
                <DetailRow label='Company' value={company} />
              </FormContainer>}
            {phone &&
              <FormContainer>
                <Divider />
                <DetailRow label='Phone Number' value={phone} />
              </FormContainer>}
            {!phone && onSearchPhone && featureFlags.includes('phone_query') && featureFlags.includes('email_query') &&
              <FormContainer>
                <Divider />
                <DetailRow label='Phone Number'>
                  {!isArchived && !contactPhone.get('loading') && !contactPhone.get('error') && <DetailAction onClick={onSearchPhone}>Search?</DetailAction>}
                  {contactPhone.get('error') && <DetailError>{contactPhone.get('error')}</DetailError>}
                  {contactPhone.get('loading') && <LoadingIndicator size={14} padding='0.25rem' align='right' />}
                </DetailRow>
              </FormContainer>}
            {customFields && customFields}
            {lastAddedContact &&
              <FormContainer>
                <Divider />
                <FormLabel label='Recently Added' full>
                  <SequenceRow
                    contact={lastAddedContact}
                    dateField='created_at'
                    onClick={() => {
                      router.push(`/sequence/${lastAddedContact.getIn(['_campaign', '_id'])}/contacts/${lastAddedContact.get('_id')}`)
                    }}
                  />
                </FormLabel>
              </FormContainer>}
            {lastMessagedContact &&
              <FormContainer>
                <Divider />
                <FormLabel label='Last Messaged' full>
                  <SequenceRow
                    contact={lastMessagedContact}
                    dateField='last_messaged_at'
                    onClick={() => {
                      router.push(`/sequence/${lastMessagedContact.getIn(['_campaign', '_id'])}/contacts/${lastMessagedContact.get('_id')}`)
                    }}
                  />
                </FormLabel>
              </FormContainer>}
            {lastRepliedContact &&
              <FormContainer>
                <Divider />
                <FormLabel label='Last Replied' full>
                  <SequenceRow
                    contact={lastRepliedContact}
                    dateField='replied_at'
                    onClick={() => {
                      router.push(`/sequence/${lastRepliedContact.getIn(['_campaign', '_id'])}/contacts/${lastRepliedContact.get('_id')}`)
                    }}
                  />
                </FormLabel>
              </FormContainer>}
          </ContentContainer>}
      </Wrapper>
    )
  }
}

ContactMain.propTypes = {
  session: PropTypes.object,
  sequence: PropTypes.object,
  contact: PropTypes.object,
  contactPhone: PropTypes.object,
  crm: PropTypes.object,
  contactIntegration: PropTypes.object,
  similarContacts: PropTypes.object,
  theme: PropTypes.object,
  onMoveContact: PropTypes.func,
  onCopyContact: PropTypes.func,
  onSendNow: PropTypes.func,
  onDeleteContact: PropTypes.func,
  onSaveContact: PropTypes.func,
  onRepliedContactUpdate: PropTypes.func,
  onUnsubscribeContact: PropTypes.func,
  onPauseContact: PropTypes.func,
  onCloneContact: PropTypes.func,
  onBlockContact: PropTypes.func,
  onAssignSentiment: PropTypes.func,
  onCustomizeMessages: PropTypes.func,
  onUpdateContact: PropTypes.func,
  onClearError: PropTypes.func,
  onSearchPhone: PropTypes.func,
  onReportContactInaccuracy: PropTypes.func,
  router: PropTypes.object,
  location: PropTypes.object,
  isArchived: PropTypes.bool,
  onResyncCrm: PropTypes.func,
  onJumpToTask: PropTypes.func
}

export default withTheme(ContactMain)
