import { fromJS } from 'immutable'
import { combineReducers } from 'redux-immutable'
import { LOCATION_CHANGE } from 'react-router-redux'

import app from './containers/App/reducer'
import onboarding from './containers/Onboarding/reducer'
import billing from './containers/Billing/reducer'
import navigation from './containers/Navigation/reducer'
import dashboard from './containers/Dashboard/reducer'
import sequence from './containers/Sequence/reducer'
import settings from './containers/Settings/reducer'
import integrations from './containers/Integrations/reducer'
import team from './containers/Team/reducer'
import tasks from './containers/Tasks/reducer'
import templates from './containers/Templates/reducer'
import reports from './containers/Reports/reducer'
import talentPool from './containers/TalentPool/reducer'

// Initial routing state
const routeInitialState = fromJS({
  locationBeforeTransitions: null
})

/**
 * Merge route into the global application state
 */
function routeReducer (state = routeInitialState, action) {
  switch (action.type) {
    case LOCATION_CHANGE:
      return state.merge({
        locationBeforeTransitions: action.payload
      })
    default:
      return state
  }
}

const rootReducer = combineReducers({
  route: routeReducer,
  global: app,
  billing,
  navigation,
  dashboard,
  sequence,
  settings,
  integrations,
  team,
  onboarding,
  tasks,
  templates,
  reports,
  talentPool
})

export default rootReducer
