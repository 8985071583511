import {
  call,
  put,
  takeLatest
} from 'redux-saga/effects'
import R from 'utils/ramda'

import api from 'utils/api'
import { pluralize } from 'utils/strings'

import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchTalentPoolSuccess,
  fetchTalentPoolFailure,
  fetchMoreTalentsSuccess,
  fetchMoreTalentsFailure,
  updateTalentPoolSuccess,
  updateTalentPoolFailure,
  createTalentSuccess,
  createTalentFailure,
  addTalentToSequenceSuccess,
  addTalentToSequenceFailure,
  deleteCandidateSuccess,
  deleteCandidateFailure
} from './actions'
import {
  ADD_TALENT_TO_SEQUENCE_REQUEST,
  CREATE_TALENT_REQUEST,
  FETCH_TALENT_POOL_REQUEST,
  UPDATE_TALENT_POOL_REQUEST,
  DELETE_CANDIDATE_REQUEST,
  FETCH_MORE_TALENTS_REQUEST
} from './constants'

const get = (url, params) => api.get(url, { params })
const post = (url, params) => api.post(url, params)
const remove = (url, params, body) => api.delete(url, { params, data: body })
const route = '/people'

function * fetchTalentPool (action) {
  let query

  if (!R.isEmpty(action.query)) {
    query = action.query
  } else {
    query = {}
  }

  try {
    const response = yield call(get, route, query)
    yield put(fetchTalentPoolSuccess(response))
  } catch (err) {
    yield put(fetchTalentPoolFailure(err))
  }
}

function * fetchMoreTalents (action) {
  let params

  if (!R.isEmpty(action.params)) {
    params = action.params
  } else {
    params = {}
  }

  try {
    const response = yield call(get, route, params)
    if (response?.data?.people?.length) {
      yield put(fetchMoreTalentsSuccess(response))
    }
  } catch (err) {
    yield put(fetchMoreTalentsFailure(err))
  }
}

function * updateTalentPool (action) {
  try {
    const params = action.records
    const response = yield call(post, route, params)
    const message = 'Talent Pool updated! 🚀'
    yield put(updateTalentPoolSuccess(response))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (error) {
    yield put(updateTalentPoolFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error?.response?.data?.message
    }))
  }
}

function * createTalent (action) {
  try {
    // to be filled in when Create is ready
    const response = 'hello'
    yield put(createTalentSuccess(response))
  } catch (err) {
    yield put(createTalentFailure(err))
  }
}

function * addTalentToSequence (action) {
  try {
    const url = `/people/campaigns/${action.params.campaignId}`
    const response = yield call(post, url, { peopleIds: action.params.peopleIds })
    const message = `Moved ${response.data} ${pluralize('contact', 'contacts', response.data)} to ${action.params.campaignName}! 🚀`
    yield put(addTalentToSequenceSuccess(response))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (error) {
    yield put(addTalentToSequenceFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
  }
}

function * deleteCandidate (action) {
  try {
    const { peopleIds, message = 'Deleted! 🚀' } = action
    const response = yield call(remove, route, null, { peopleIds })
    yield put(deleteCandidateSuccess({ ...response, peopleIds }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (err) {
    yield put(deleteCandidateFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: err?.response?.data?.message
    }))
  }
}

function * fetchTalentPoolRequest () {
  yield takeLatest(FETCH_TALENT_POOL_REQUEST, fetchTalentPool)
}

function * fetchMoreTalentsRequest () {
  yield takeLatest(FETCH_MORE_TALENTS_REQUEST, fetchMoreTalents)
}

function * updateTalentPoolRequest () {
  yield takeLatest(UPDATE_TALENT_POOL_REQUEST, updateTalentPool)
}

function * createTalentRequest () {
  yield takeLatest(CREATE_TALENT_REQUEST, createTalent)
}

function * addTalentToSequenceRequest () {
  yield takeLatest(ADD_TALENT_TO_SEQUENCE_REQUEST, addTalentToSequence)
}

function * deleteCandidateRequest () {
  yield takeLatest(DELETE_CANDIDATE_REQUEST, deleteCandidate)
}

export default [
  fetchTalentPoolRequest,
  fetchMoreTalentsRequest,
  updateTalentPoolRequest,
  createTalentRequest,
  addTalentToSequenceRequest,
  deleteCandidateRequest
]
