import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Input from 'components/Input'
import Button from 'components/Button'
import Title from 'elements/Title'
import Divider from 'elements/Divider'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  width: 42rem;
  height: fit-content;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const FormWrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
  padding-bottom: 2.5rem;
`

const InputContainer = styled.div`
  width: 100%;
  padding: 0 2rem;
`

const HeaderContainer = styled.div`
  padding: 2.5rem 0 1rem 0;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

function CreateTalent ({
  isOpen = false,
  onCancel
}) {
  const [contactFields, setContactFields] = useState({
    name: '',
    company: '',
    email: '',
    phone_number: '',
    url: '',
    role: '',
    location: ''
  })

  const handleSave = () => console.log('save')

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={onCancel}
    >
      <Wrapper>
        <HeaderContainer>
          <Title align='center'>Add Talent</Title>
        </HeaderContainer>
        <FormWrapper>
          <InputContainer>
            <Input
              label='Name'
              inputId='name'
              mt='1.5rem'
              onValueChange={(value) => {
                setContactFields({
                  ...contactFields,
                  name: value
                })
              }}
            />
            <Input
              label='Company'
              inputId='company'
              type='text'
              mt='1.5rem'
              onValueChange={(value) => {
                setContactFields({
                  ...contactFields,
                  company: value
                })
              }}
            />
            <Input
              label='Email'
              inputId='email'
              mt='1.5rem'
              onValueChange={(value) => {
                setContactFields({
                  ...contactFields,
                  email: value
                })
              }}
            />
            <Input
              label='Location'
              inputId='location'
              type='text'
              mt='1.5rem'
              onValueChange={(value) => {
                setContactFields({
                  ...contactFields,
                  location: value
                })
              }}
            />
            <Input
              label='Phone'
              inputId='phone_number'
              type='text'
              mt='1.5rem'
              onValueChange={(value) => {
                setContactFields({
                  ...contactFields,
                  phone_number: value
                })
              }}
            />
          </InputContainer>
        </FormWrapper>
        <Divider />
        <ButtonContainer>
          <Button
            label='cancel'
            onClick={onCancel}
            mr='0.5rem'
            ml='0.5rem'
          />
          <Button
            primary
            label='add talent'
            onClick={handleSave}
            mr='0.5rem'
          />
        </ButtonContainer>
      </Wrapper>
    </Modal>
  )
}

CreateTalent.propTypes = {
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func
}

export default CreateTalent
