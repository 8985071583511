import React from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import { format } from 'date-fns'
import { ReferenceLine, Line } from 'recharts'

import Loading from 'components/Loading'
import EmptyState from 'components/EmptyState'

import { DATE_RANGE } from 'containers/Reports/utils/date_helpers'

import SpreadSheet from 'containers/Reports/components/SpreadSheet'
import Graph from 'containers/Reports/components/Graph'
import UserHUD from 'containers/Reports/components/Graph/components/UserHUD'
import DateSelector from 'containers/Reports/components/Graph/components/DateSelector'

import { ReportContainer, EmptyContainer, GraphContainer } from 'containers/Reports/styles'

import { Satellite } from 'svg'

class BounceRateReport extends React.Component {
  percent = (count, total) => {
    return total ? Math.round(count / total * 1000) / 10 : 0
  }

  /**
   * - The BounceRateReport format is designed in an adhoc way.
   * - The goal of this function is to give a temporary
   *   solution - hopefully the response can get reformatted.
   *
   * TODO: remove this function and replace it with a call to report_helpers.formatBarGraphData
   * TODO: abstract out report_helper.formatBarGraphData to just support both line and bar graphs
   */
  formatUserData = () => {
    const { bounceRateReports } = this.props
    const users = bounceRateReports.getIn(['data', 'populate', 'users'])

    const totalTotal = bounceRateReports.getIn(['data', 'reports', 'totals', 'messages_bounced', 'total', 'total'])
    const totalCount = bounceRateReports.getIn(['data', 'reports', 'totals', 'messages_bounced', 'total', 'count'])

    const userData = users.map(user => {
      const userID = user.get('_id')

      const userTotal = bounceRateReports.getIn(['data', 'reports', 'users', userID, 'messages_bounced', 'total', 'total'])
      const userCount = bounceRateReports.getIn(['data', 'reports', 'users', userID, 'messages_bounced', 'total', 'count'])

      return {
        total: this.percent(userCount, userTotal),
        photo: user.get('photo_url'),
        name: user.get('display_name')
      }
    })

    return {
      userData: userData.toJS(),
      totalUnits: this.percent(totalCount, totalTotal)
    }
  }

  /**
   * The BounceRateReport format is designed in an adhoc way.
   * The goal of this function is to give a temporary solution
   */
  formatGraphData = (period = DATE_RANGE.DAY) => {
    const { bounceRateReports } = this.props
    const bounceTotals = bounceRateReports.getIn(['data', 'reports', 'totals', 'messages_bounced', period])

    const tableData = []
    bounceTotals
      .entrySeq()
      .sortBy(dateRecord => dateRecord[0])
      .forEach(dateRecord => {
        const date = dateRecord[0]
        const count = dateRecord[1].get('count')
        const total = dateRecord[1].get('total')

        const formattedDate = format(date, 'MMMM D, YYYY')
        let rate = 0
        if (total !== 0) {
          rate = this.percent(count, total)
        }

        tableData.push({
          value: rate,
          date: formattedDate
        })
      })

    return tableData
  }

  formatTableData = () => {
    const { bounceReasonReports } = this.props
    const contacts = bounceReasonReports.getIn(['data', 'records']) || Immutable.List()
    const users = bounceReasonReports.getIn(['data', 'populate', 'users']) || Immutable.Map()
    const sequences = bounceReasonReports.getIn(['data', 'populate', 'campaigns']) || Immutable.Map()

    const tableColumns = ['contact', 'bounced reason', 'owner', 'sequence']
    const tableRows = []
    const columnWidths = ['flex', 200, 275, 400]

    contacts
      .forEach(record => {
        const sequenceId = record?.get('_campaign')
        const userId = record?.get('_user')
        const sequence = sequences.getIn([sequenceId, 'title'])
        const owner = users.getIn([userId, 'full_name'])

        tableRows.push({
          contact: record?.get('email'),
          'bounced reason': record?.get('bounced_reason') || '-',
          owner,
          sequence,
          sequenceID: sequenceId
        })
      })

    return { tableColumns, tableRows, columnWidths }
  }

  render () {
    const {
      theme,
      router,
      selectedDateRange,
      setSelectedDateType,
      bounceRateReports,
      bounceReasonReports
    } = this.props

    if (!!bounceRateReports && bounceRateReports.get('loading')) {
      return <Loading width='100%' />
    }

    let graphData = null
    let userData = null
    let totalUnits = 0

    if (bounceRateReports &&
      !bounceRateReports.get('loading') &&
      bounceRateReports.get('data') &&
      bounceRateReports.get('data').size > 0) {
      ({ totalUnits, userData } = this.formatUserData())

      graphData = {
        day: this.formatGraphData(DATE_RANGE.DAY),
        week: this.formatGraphData(DATE_RANGE.WEEK)
      }
    }

    const hasBounceReasonReports = bounceReasonReports.get('data') &&
      bounceReasonReports.get('data').size > 0
    let tableData = null

    if (bounceReasonReports &&
      !bounceReasonReports.get('loading') &&
      hasBounceReasonReports) {
      tableData = this.formatTableData()
    }

    if (!graphData || graphData.length < 0) {
      return (
        <ReportContainer>
          <EmptyContainer>
            <EmptyState
              height='100%'
              mt='0'
              p='3rem'
              icon={<Satellite />}
              title='Nothing To Report'
              description="Looks like we don't have any data for the timeframe and filter you have selected"
            />
          </EmptyContainer>
        </ReportContainer>
      )
    }

    return (
      <ReportContainer>
        <GraphContainer>
          <DateSelector
            dayDateRangeUpdate={() => setSelectedDateType(DATE_RANGE.DAY)}
            weekDateRangeUpdate={() => setSelectedDateType(DATE_RANGE.WEEK)}
            selectedDateRange={selectedDateRange}
          />

          <Graph
            selectedDateRange={selectedDateRange}
            graphData={graphData}
            theme={theme}
            unitLabel='% Message Bounced'
            unitLabelPlural='% Messages Bounced'
            yAxisDomainType='number'
            yAxisDomain={[0, dataMax => dataMax < 5 ? 10 : dataMax]}
            defaultTheme
          >
            <ReferenceLine
              y={5.0}
              stroke={theme.colors.red}
              strokeDasharray='1 0'
              strokeWidth={1.5}
            />

            <Line
              dot={false}
              dataKey='value'
              type='monotone'
              stroke={theme.colors.blue}
              strokeWidth={2.5}
              connectNulls
            />
          </Graph>

          <UserHUD
            unitLabel='Messages Bounced'
            unitLabelPlural='Messages Bounced'
            userData={userData}
            totalUnits={totalUnits}
            unitSuffix='%'
          />
        </GraphContainer>

        {hasBounceReasonReports &&
          <SpreadSheet
            theme={theme}
            router={router}
            tableData={tableData}
          />}
      </ReportContainer>
    )
  }
}

BounceRateReport.propTypes = {
  theme: PropTypes.object,
  router: PropTypes.object,
  bounceRateReports: PropTypes.object,
  bounceReasonReports: PropTypes.object,
  selectedDateRange: PropTypes.string,
  setSelectedDateType: PropTypes.func
}

export default BounceRateReport
