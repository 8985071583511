import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { space } from 'styled-system'
import styled, { css, withTheme } from 'styled-components'
import { fadeIn } from 'utils/animations'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  background-color: ${props => (props.buttonColor ? props.buttonColor : 'transparent')};

  border-style: solid;
  border-radius: 9999px;
  border-width: 1px;
  border-color: ${props => (props.borderColor ? props.borderColor : props.theme.gray40)};

  min-width: ${props => (props.size ? `${props.size}px` : '36px')};
  width: ${props => (props.size ? `${props.size}px` : '36px')};
  height: ${props => (props.size ? `${props.size}px` : '36px')};
  margin-right: ${props => (props.marginRight ? `${props.marginRight}` : '0')};
  margin-left: ${props => (props.marginLeft ? `${props.marginLeft}` : '0')};

  opacity: 1;
  transition: opacity .15s ease-in;
  animation: ${fadeIn} .2s linear 1;

  padding-top: 2px;
  font-size: 14px;

  ${props => !props.disabled && !props.fixed && css`
    cursor: pointer;

    &:hover,
    &:focus {
      opacity: .5;
      transition: opacity .15s ease-in;
    }

    &:active {
      opacity: .8;
      transition: opacity .15s ease-out;
    }
  `};

  ${props => props.disabled && css`
    cursor: default;
    opacity: .5;
  `};

  ${space}
`

class IconButton extends Component {
  render () {
    const {
      theme,
      type,
      onClick,
      iconColor,
      iconSize,
      translateX = 0,
      translateY = 0,
      ...rest
    } = this.props

    return (
      <Wrapper onClick={onClick} {...rest}>
        <FontAwesomeIcon
          icon={type}
          color={iconColor || theme.colors.darkGray}
          size={iconSize}
          transform={{
            x: translateX,
            y: translateY
          }}
        />
      </Wrapper>
    )
  }
}

IconButton.propTypes = {
  theme: PropTypes.object,
  type: PropTypes.array,
  onClick: PropTypes.func,
  iconColor: PropTypes.string,
  iconSize: PropTypes.string,
  buttonColor: PropTypes.string,
  borderColor: PropTypes.string,
  size: PropTypes.number,
  translateX: PropTypes.number,
  translateY: PropTypes.number,
  marginRight: PropTypes.string,
  marginLeft: PropTypes.string
}

export default withTheme(IconButton)
