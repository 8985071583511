import React from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

import { ACCOUNT_NAMES } from 'containers/App/constants'

import Button from 'components/Button'
import Modal from 'components/Modal'
import Divider from 'elements/Divider'

import Label from 'elements/Label'
import Title from 'elements/Title'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 1rem 0;
`

const ModalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
`

const DangerButton = styled(Button)`
  background-color: ${props => props.theme.colors.red};
  border-color: ${props => props.theme.colors.red};
`

function RevokeAccountModal ({
  isOpen,
  onModalClose,
  actions,
  session,
  ...props
}) {
  const accountEmailType = ACCOUNT_NAMES[session?.get('account_type')] || 'Email'
  return (
    <Modal
      isOpen={isOpen}
      onModalClose={onModalClose}
    >
      <Wrapper>
        <Title align='left'>Disconnect {accountEmailType} Account</Title>
        <Label
          align='center'
          mt='0.5rem'
          mb='2rem'
        >
          Are you sure you want to disconnect your {accountEmailType} account?
        </Label>
        <Divider />
        <ModalButtonContainer>
          <Button
            label='Cancel'
            handleClick={() => {
              onModalClose()
            }}
            mr='0.5rem'
          />
          <DangerButton
            primary
            label='disconnect'
            handleClick={() => {
              actions.deleteAuthToken()
              onModalClose()
            }}
          />
        </ModalButtonContainer>
      </Wrapper>
    </Modal>
  )
}

RevokeAccountModal.propTypes = {
  isOpen: PropTypes.bool,
  onModalClose: PropTypes.func,
  actions: PropTypes.any,
  theme: PropTypes.any,
  session: PropTypes.object
}

export default withTheme(RevokeAccountModal)
