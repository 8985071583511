import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width } from 'styled-system'

import Title from 'elements/Title'
import Label from 'elements/Label'
import Button from 'components/Button'
import { apiBaseURI } from 'utils/api'

import { Server, Wordmark } from 'svg'
import { fadeIn } from 'utils/animations'

const LogoContainer = styled.div`
  padding-bottom: 5rem;
`

const Wrapper = styled.div`
  max-width: 342px;
  padding-bottom: 5rem;

  animation: ${fadeIn} .15s linear 1;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  margin-bottom: 2rem;
`

const Terms = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  color: ${props => props.theme.colors.gray60};
  font: ${props => props.theme.fonts.small};
  text-align: left;

  ${space}
  ${width}
`

const OrLabel = styled(Label)`
  color: ${props => props.theme.colors.gray60};
  font: ${props => props.theme.fonts.small};
  padding-top: 1rem;
`

class NylasLogin extends Component {
  UNSAFE_componentWillMount () {
    const {
      signup
    } = this.props
    document.title = signup ? 'Interseller | Signup - Server' : 'Interseller | Login - Server'
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      magic
    } = newProps
    const {
      router
    } = this.props

    const email = magic.getIn(['data', 'email'])

    if (email) {
      router.push('/login/magic')
    }
  }

  render () {
    const {
      discovery,
      signup,
      theme,
      actions
    } = this.props

    const email = discovery.getIn(['data', 'email'])
    const showMagicLink = email && !signup

    const verb = signup ? 'up' : 'in'
    const redirect = `${window.location.protocol}//${window.location.hostname + (window.location.port ? `:${window.location.port}` : '')}`
    const label = `Sign ${verb} using your own email server.`

    const name = discovery.getIn(['data', 'identity', 'name', 'first'])
    let welcome
    if (name) {
      welcome = `${signup ? 'Welcome' : 'Welcome back'} ${name}!`
    }

    return (
      <Wrapper>
        <LogoContainer>
          <Wordmark />
        </LogoContainer>
        {welcome && <Title mb='0.5rem'>{welcome}</Title>}
        {!welcome && <Title>{`Sign ${verb} with your email account`}</Title>}
        <Label mt='1rem'>{label}</Label>
        <ButtonContainer>
          <Button
            innerRef={(button) => { this.nylasButton = button }}
            primary
            full
            mt='1rem'
            bg={theme.primaryColor}
            label={`Sign ${verb} with Mail Server`}
            icon={<Server />}
            handleClick={() => {
              const url = discovery.getIn(['data', 'url']) || `${apiBaseURI}/auth/nylas`

              if (url.indexOf('?') > -1) {
                window.location = `${url}&redirect=${redirect}`
              } else {
                window.location = `${url}?redirect=${redirect}`
              }
            }}
          />
          {showMagicLink &&
            <OrLabel align='center'>- or -</OrLabel>}
          {showMagicLink &&
            <Button
              innerRef={(button) => { this.nylasButton = button }}
              primary
              full
              mt='1rem'
              bg={theme.containerBackground}
              color={theme.primaryColor}
              label='Send Magic Link via Email'
              handleClick={() => {
                actions.fetchEmailLink({ email })
              }}
            />}
        </ButtonContainer>
        {signup &&
          <Terms pt='1rem'>In order to use Interseller with your mail server you need to know details of your mail server and your account. Learn more <a href='https://help.interseller.io/article/42-logging-into-interseller-with-exchange-and-an-external-mail-server' target='_blank' rel='noopener noreferrer'>here</a>.</Terms>}
        {showMagicLink &&
          <Terms pt='1rem'>Magic links let you sign in to your account by just clicking a link in an email sent to your inbox.</Terms>}
      </Wrapper>
    )
  }
}

NylasLogin.propTypes = {
  discovery: PropTypes.object,
  signup: PropTypes.bool,
  theme: PropTypes.object,
  actions: PropTypes.object,
  router: PropTypes.object
}

export default NylasLogin
