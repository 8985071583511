import Immutable from 'immutable'

import {
  FETCH_SEQUENCES_REQUEST,
  FETCH_SEQUENCES_SUCCESS,
  FETCH_SEQUENCES_FAILURE,
  FETCH_ARCHIVED_SEQUENCES_REQUEST,
  FETCH_ARCHIVED_SEQUENCES_SUCCESS,
  FETCH_ARCHIVED_SEQUENCES_FAILURE,
  UPDATE_SEQUENCES_REQUEST,
  UPDATE_SEQUENCES_SUCCESS,
  UPDATE_SEQUENCES_FAILURE,
  DELETE_SEQUENCES_REQUEST,
  DELETE_SEQUENCES_SUCCESS,
  DELETE_SEQUENCES_FAILURE,
  RESTORE_SEQUENCES_REQUEST,
  RESTORE_SEQUENCES_SUCCESS,
  RESTORE_SEQUENCES_FAILURE,
  FETCH_SEQUENCES_STATS_REQUEST,
  FETCH_SEQUENCES_STATS_SUCCESS,
  FETCH_SEQUENCES_STATS_FAILURE,
  FETCH_SEQUENCE_REPORTS_REQUEST,
  FETCH_SEQUENCE_REPORTS_SUCCESS,
  FETCH_SEQUENCE_REPORTS_FAILURE,
  FETCH_MESSAGE_SCHEDULE_REQUEST,
  FETCH_MESSAGE_SCHEDULE_SUCCESS,
  FETCH_MESSAGE_SCHEDULE_FAILURE,
  CREATE_SEQUENCE_REQUEST,
  CREATE_SEQUENCE_SUCCESS,
  CREATE_SEQUENCE_FAILURE,
  CREATE_SEQUENCE_RESET,
  FETCH_SEQUENCES_STATUS_REQUEST,
  FETCH_SEQUENCES_STATUS_SUCCESS,
  FETCH_SEQUENCES_STATUS_FAILURE,
  FETCH_MESSAGE_COUNTS_REQUEST,
  FETCH_MESSAGE_COUNTS_SUCCESS,
  FETCH_MESSAGE_COUNTS_FAILURE,
  FETCH_SEQUENCES_EXPORT_CSV_REQUEST,
  FETCH_SEQUENCES_EXPORT_CSV_SUCCESS,
  FETCH_SEQUENCES_EXPORT_CSV_FAILURE
} from './constants'

export function fetchSequences (query) {
  return {
    type: FETCH_SEQUENCES_REQUEST,
    query
  }
}

export function fetchSequencesSuccess ({ sequences, total }) {
  return {
    type: FETCH_SEQUENCES_SUCCESS,
    payload: {
      sequences,
      total
    }
  }
}

export function fetchSequencesFailure (err) {
  return {
    type: FETCH_SEQUENCES_FAILURE,
    err
  }
}

export function fetchArchivedSequences (query) {
  return {
    type: FETCH_ARCHIVED_SEQUENCES_REQUEST,
    query
  }
}

export function fetchArchivedSequencesSuccess ({ sequences, total }) {
  return {
    type: FETCH_ARCHIVED_SEQUENCES_SUCCESS,
    payload: {
      sequences,
      total
    }
  }
}

export function fetchArchivedSequencesFailure (err) {
  return {
    type: FETCH_ARCHIVED_SEQUENCES_FAILURE,
    err
  }
}

export function updateSequences (sequenceIds, params, message) {
  return {
    type: UPDATE_SEQUENCES_REQUEST,
    sequenceIds,
    params,
    message
  }
}

export function updateSequencesSuccess ({ sequenceIds, params, total }) {
  return {
    type: UPDATE_SEQUENCES_SUCCESS,
    sequenceIds,
    params,
    total
  }
}

export function updateSequencesFailure (err) {
  return {
    type: UPDATE_SEQUENCES_FAILURE,
    err
  }
}

export function deleteSequences (sequenceIds) {
  return {
    type: DELETE_SEQUENCES_REQUEST,
    sequenceIds
  }
}

export function deleteSequencesSuccess ({ sequenceIds }) {
  return {
    type: DELETE_SEQUENCES_SUCCESS,
    sequenceIds
  }
}

export function deleteSequencesFailure (err) {
  return {
    type: DELETE_SEQUENCES_FAILURE,
    err
  }
}

export function restoreSequences (sequenceIds) {
  return {
    type: RESTORE_SEQUENCES_REQUEST,
    sequenceIds
  }
}

export function restoreSequencesSuccess ({ sequenceIds }) {
  return {
    type: RESTORE_SEQUENCES_SUCCESS,
    sequenceIds
  }
}

export function restoreSequencesFailure (err) {
  return {
    type: RESTORE_SEQUENCES_FAILURE,
    err
  }
}

let fetchedSequenceStatsIds = Immutable.Set()

export function fetchSequencesStats ({ archived, all, sequenceIds } = {}) {
  if (sequenceIds) {
    const missingSequenceIds = Immutable.Set(sequenceIds).subtract(fetchedSequenceStatsIds)
    fetchedSequenceStatsIds = fetchedSequenceStatsIds.concat(missingSequenceIds)
    sequenceIds = missingSequenceIds.toArray()

    if (!sequenceIds.length) {
      return {
        type: FETCH_SEQUENCES_STATS_FAILURE
      }
    }
  }

  return {
    type: FETCH_SEQUENCES_STATS_REQUEST,
    archived,
    all,
    sequenceIds
  }
}

export function fetchSequencesStatsSuccess ({ sequencesStats }) {
  return {
    type: FETCH_SEQUENCES_STATS_SUCCESS,
    payload: {
      sequencesStats
    }
  }
}

export function fetchSequencesStatsFailure (err) {
  return {
    type: FETCH_SEQUENCES_STATS_FAILURE,
    err
  }
}

export function createSequence (params) {
  return {
    type: CREATE_SEQUENCE_REQUEST,
    params
  }
}

export function createSequenceSuccess ({ sequence }) {
  return {
    type: CREATE_SEQUENCE_SUCCESS,
    payload: {
      sequence
    }
  }
}

export function createSequenceFailure (err) {
  return {
    type: CREATE_SEQUENCE_FAILURE,
    err
  }
}

export function createSequenceReset () {
  return {
    type: CREATE_SEQUENCE_RESET
  }
}

export function fetchSequenceReports (tileConfig) {
  return {
    type: FETCH_SEQUENCE_REPORTS_REQUEST,
    tileConfig
  }
}

export function fetchSequenceReportsSuccess ({ reports }) {
  return {
    type: FETCH_SEQUENCE_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchSequenceReportsFailure (err) {
  return {
    type: FETCH_SEQUENCE_REPORTS_FAILURE,
    err
  }
}

export function fetchMessageSchedule (params) {
  return {
    type: FETCH_MESSAGE_SCHEDULE_REQUEST,
    params
  }
}

export function fetchMessageScheduleSuccess ({ schedule }) {
  return {
    type: FETCH_MESSAGE_SCHEDULE_SUCCESS,
    payload: {
      schedule
    }
  }
}

export function fetchMessageScheduleFailure (err) {
  return {
    type: FETCH_MESSAGE_SCHEDULE_FAILURE,
    err
  }
}

let fetchedSequenceStatusIds = Immutable.Set()

export function fetchSequencesStatus ({ sequenceIds }) {
  if (sequenceIds) {
    const missingSequenceIds = Immutable.Set(sequenceIds).subtract(fetchedSequenceStatusIds)
    fetchedSequenceStatusIds = fetchedSequenceStatusIds.concat(missingSequenceIds)
    sequenceIds = missingSequenceIds.toArray()

    if (!sequenceIds.length) {
      // nothing happens here because the reducer doesn't pick it up
      return {
        type: FETCH_SEQUENCES_STATUS_FAILURE
      }
    }
  }

  return {
    type: FETCH_SEQUENCES_STATUS_REQUEST,
    sequenceIds
  }
}

export function fetchSequencesStatusSuccess ({ sequencesStatus }) {
  return {
    type: FETCH_SEQUENCES_STATUS_SUCCESS,
    payload: {
      sequencesStatus
    }
  }
}

export function fetchSequencesStatusFailure (err) {
  return {
    type: FETCH_SEQUENCES_STATUS_FAILURE,
    err
  }
}

export function fetchMessageCounts () {
  return {
    type: FETCH_MESSAGE_COUNTS_REQUEST
  }
}

export function fetchMessageCountsSuccess ({ counts }) {
  return {
    type: FETCH_MESSAGE_COUNTS_SUCCESS,
    payload: {
      counts
    }
  }
}

export function fetchMessageCountsFailure (err) {
  return {
    type: FETCH_MESSAGE_COUNTS_FAILURE,
    err
  }
}

export function fetchSequencesExportCsv (sequenceIds) {
  return {
    type: FETCH_SEQUENCES_EXPORT_CSV_REQUEST,
    sequenceIds
  }
}

export function fetchSequencesExportCsvSuccess ({ downloadLink }) {
  return {
    type: FETCH_SEQUENCES_EXPORT_CSV_SUCCESS,
    payload: {
      downloadLink
    }
  }
}

export function fetchSequencesExportCsvFailure (err) {
  return {
    type: FETCH_SEQUENCES_EXPORT_CSV_FAILURE,
    err
  }
}
