import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'

import Button from 'components/Button'

import Subtitle from 'elements/Subtitle'
import Label from 'elements/Label'
import DropDownFieldForm from '../../../../components/DropDownFieldForm'
import { CSV_DOWNLOAD_OPTIONS } from '../../constants'

const Wrapper = styled.div`
  padding: ${props => props.theme.padding};
`

const FieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray30};
  border-style: solid;
`

const ExportLabel = styled.label`
  width: 100%;
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

function Exports ({ actions, state }) {
  const downloadOptions = Immutable.List(CSV_DOWNLOAD_OPTIONS)

  return (
    <Wrapper>
      <Subtitle>Exports</Subtitle>
      <Label mt='0.25rem'>Export data from Interseller and download it to your computer.</Label>

      <FieldContainer>
        <DropDownFieldForm
          label='Export Contacts'
          description='Download a list of your contacts via CSV'
          options={downloadOptions}
          value='bounced'
          clearable={false}
          inputId='bounced_unsubscribed'
          buttonText='Download'
          onSave={(option) => {
            actions.fetchContactCSV(option)
          }}
        />
      </FieldContainer>
      <FieldContainer>
        <ExportLabel>Export Sequence Steps</ExportLabel>
        <Description>Download a CSV that includes the steps and templates of all of your sequences</Description>
        <Button
          primary
          label='Download'
          full
          onClick={() => {
            actions.fetchCampaignStepCopyRequest()
          }}
        />
      </FieldContainer>
    </Wrapper>
  )
}

Exports.propTypes = {
  state: PropTypes.object,
  session: PropTypes.object,
  actions: PropTypes.object
}

export default Exports
