import Immutable, { fromJS } from 'immutable'
import {
  FETCH_TALENT_POOL_REQUEST,
  FETCH_TALENT_POOL_SUCCESS,
  FETCH_MORE_TALENTS_REQUEST,
  FETCH_MORE_TALENTS_SUCCESS,
  FETCH_TALENT_POOL_FAILURE,
  DELETE_CANDIDATE_REQUEST,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_FAILURE
} from './constants'

const initialObject = {
  loading: true,
  data: {
    people: [],
    scrollId: null,
    total: 0
  },
  error: null
}

const initialState = fromJS({
  list: initialObject,
  loading: false
})

function talentPoolReducer (state = initialState, action) {
  switch (action.type) {
    case FETCH_TALENT_POOL_REQUEST:
      return state.set('list', state.get('list').merge({ loading: true }))
    case FETCH_TALENT_POOL_SUCCESS: {
      const { data } = action.payload
      const scrollId = data.people.length ? data.scrollId : null
      return state
        .set('list', fromJS({
          data: {
            ...data,
            scrollId
          },
          loading: false,
          error: null
        }))
    }
    case FETCH_TALENT_POOL_FAILURE:
      return state.set('list', fromJS({
        data: null,
        loading: false,
        talent_pool_currently_populating: action.err?.response?.data?.talent_pool_currently_populating,
        error: action.err
      }))
    case FETCH_MORE_TALENTS_REQUEST:
      return state.set('list', state.get('list').merge({ loading: true }))
    case FETCH_MORE_TALENTS_SUCCESS: {
      const { data } = action.payload
      const people = state.getIn(['list', 'data', 'people']).concat(fromJS(data.people)) || Immutable.List([])
      const scrollId = data.people.length ? data.scrollId : null
      return state
        .set('list', fromJS({
          data: {
            ...action.payload.data,
            people,
            scrollId
          },
          loading: false,
          error: null
        }))
    }
    case DELETE_CANDIDATE_REQUEST:
      return state.set('list', state.get('list').merge({ loading: true }))
    case DELETE_CANDIDATE_SUCCESS: {
      const { peopleIds } = action.payload
      const people = state
        .getIn(['list', 'data', 'people'])
        .filter((p) => {
          const id = p.get('id')
          return !peopleIds.includes(id)
        })

      return state
        .setIn(['list'], state
          .getIn(['list'])
          .merge({
            data: {
              people,
              error: null,
              total: people.size
            },
            loading: false,
            error: null
          }))
    }
    case DELETE_CANDIDATE_FAILURE:
      return state.set('list', state.get('list').merge({ loading: false }))
    default:
      return state
  }
}

export default talentPoolReducer
