import HTMLParser from './parser'
import { PLATFORMS } from './constants'
import * as defaultHelpers from './utils/default-helpers'

function parseHTML (html) {
  let currentPlatform = PLATFORMS.NONE
  let isGoogleDrive = null
  let isMsftWord = null

  const driveFlag = 'docs-internal-guid'
  isGoogleDrive = html.includes(driveFlag)

  const msftFlag = 'schemas-microsoft-com:office'
  isMsftWord = html.includes(msftFlag)

  if (isGoogleDrive) {
    currentPlatform = PLATFORMS.DRIVE
  } else if (isMsftWord) {
    currentPlatform = PLATFORMS.WORD
  }

  try {
    // before parsing HTML cleanup
    const preParsedHTML = defaultHelpers.preSanitizeHTML(html, currentPlatform)

    // parse html
    const htmlParser = new HTMLParser(preParsedHTML, currentPlatform)
    const parsedHtml = htmlParser.parse()

    // after parsing HTML cleanup
    const postParsedHtml = defaultHelpers.postSanitizeHTML(parsedHtml, currentPlatform)
    return postParsedHtml
  } catch (err) {
    console.error('Error - ', err)
    return null
  }
}

export {
  parseHTML
}
