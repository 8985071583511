export const FETCH_TALENT_POOL_REQUEST = 'TalentPool/FETCH_TALENT_POOL_REQUEST'
export const FETCH_TALENT_POOL_SUCCESS = 'TalentPool/FETCH_TALENT_POOL_SUCCESS'
export const FETCH_TALENT_POOL_FAILURE = 'TalentPool/FETCH_TALENT_POOL_FAILURE'

export const FETCH_MORE_TALENTS_REQUEST = 'TalentPool/FETCH_MORE_TALENTS_REQUEST'
export const FETCH_MORE_TALENTS_SUCCESS = 'TalentPool/FETCH_MORE_TALENTS_SUCCESS'
export const FETCH_MORE_TALENTS_FAILURE = 'TalentPool/FETCH_MORE_TALENTS_REQUEST'

export const UPDATE_TALENT_POOL_REQUEST = 'TalentPool/UPDATE_TALENT_POOL_REQUEST'
export const UPDATE_TALENT_POOL_SUCCESS = 'TalentPool/UPDATE_TALENT_POOL_SUCCESS'
export const UPDATE_TALENT_POOL_FAILURE = 'TalentPool/UPDATE_TALENT_POOL_FAILURE'

export const ADD_TALENT_REQUEST = 'TalentPool/ADD_TALENT_REQUEST'
export const ADD_TALENT_SUCCESS = 'TalentPool/ADD_TALENT_SUCCESS'
export const ADD_TALENT_FAILURE = 'TalentPool/ADD_TALENT_FAILURE'

export const DELETE_CANDIDATE_REQUEST = 'TalentPool/DELETE_CANDIDATE_REQUEST'
export const DELETE_CANDIDATE_SUCCESS = 'TalentPool/DELETE_CANDIDATE_SUCCESS'
export const DELETE_CANDIDATE_FAILURE = 'TalentPool/DELETE_CANDIDATE_FAILURE'

export const CREATE_TALENT_REQUEST = 'TalentPool/CREATE_TALENT_REQUEST'
export const CREATE_TALENT_SUCCESS = 'TalentPool/CREATE_TALENT_SUCCESS'
export const CREATE_TALENT_FAILURE = 'TalentPool/CREATE_TALENT_FAILURE'

export const ADD_TALENT_TO_SEQUENCE_REQUEST = 'TalentPool/ADD_TALENT_TO_SEQUENCE_REQUEST'
export const ADD_TALENT_TO_SEQUENCE_SUCCESS = 'TalentPool/ADD_TALENT_TO_SEQUENCE_SUCCESS'
export const ADD_TALENT_TO_SEQUENCE_FAILURE = 'TalentPool/ADD_TALENT_TO_SEQUENCE_FAILURE'
