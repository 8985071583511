import { fromJS } from 'immutable'
import { put, takeLatest, call } from 'redux-saga/effects'
import api, { messageFromError } from 'utils/api'
import { titlecase } from 'utils/strings'
import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchTeamMembersSuccess,
  fetchTeamMembersFailure,
  updateTeamMemberSuccess,
  updateTeamMemberFailure,
  deleteTeamMemberSuccess,
  deleteTeamMemberFailure,
  fetchTeamInvitesSuccess,
  fetchTeamInvitesFailure,
  deleteTeamInviteSuccess,
  deleteTeamInviteFailure,
  resendTeamInviteSuccess,
  resendTeamInviteFailure,
  sendTeamInviteSuccess,
  sendTeamInviteFailure,
  fetchTeamCountsSuccess,
  fetchTeamCountsFailure,
  fetchOwnerCrmSuccess,
  fetchOwnerCrmFailure,
  assignGroupMemberFailure
} from './actions'
import {
  FETCH_TEAM_MEMBERS_REQUEST,
  UPDATE_TEAM_MEMBER_REQUEST,
  DELETE_TEAM_MEMBER_REQUEST,
  FETCH_TEAM_INVITES_REQUEST,
  DELETE_TEAM_INVITES_REQUEST,
  RESEND_TEAM_INVITES_REQUEST,
  SEND_TEAM_INVITE_REQUEST,
  FETCH_TEAM_COUNTS_REQUEST,
  FETCH_OWNER_CRM_REQUEST,
  REFRESH_OWNER_CRM_REQUEST,
  ASSIGN_GROUP_MEMBER_REQUEST
} from './constants'

const get = (url, query) => api.get(url, {
  params: query
})
const post = (url, params) => api.post(url, params)
const update = (url, params) => api.put(url, params)
const remove = url => api.delete(url)

export function * fetchTeamMembers () {
  try {
    const response = yield call(get, '/team/users')
    const members = fromJS(response.data)

    yield put(fetchTeamMembersSuccess({
      members
    }))
  } catch (err) {
    yield put(fetchTeamMembersFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchTeamCounts () {
  try {
    const response = yield call(get, '/team/counts')
    const counts = fromJS(response.data)

    yield put(fetchTeamCountsSuccess({
      counts
    }))
  } catch (err) {
    yield put(fetchTeamCountsFailure(err))
  }
}

export function * fetchOwnerCrm (action) {
  const memberId = action.memberId
  const params = action.params
  try {
    const response = yield call(api.get, `team/users/${memberId}/crm`, { params })
    const crm = fromJS(response.data)
    yield put(fetchOwnerCrmSuccess({
      crm
    }))
  } catch (err) {
    yield put(fetchOwnerCrmFailure(err))
  }
}

export function * updateTeamMember (action) {
  const params = action.params
  const memberId = action.member.get('id')
  const paramKeys = Object.keys(params)

  try {
    if (paramKeys.includes('role')) {
      const response = yield call(update, `/team/users/${memberId}`, params)
      const member = fromJS(response.data)
      const name = member.get('full_name')
      yield put(updateTeamMemberSuccess({
        member
      }))
      yield put(setNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: `Updated ${name}!`
      }))
    }

    if (paramKeys.includes('active')) {
      const type = params.active ? 'reactivate' : 'deactivate'
      const response = yield call(update, `/team/users/${memberId}/${type}`, params)
      const member = fromJS(response.data)
      const name = member.get('full_name')
      yield put(updateTeamMemberSuccess({
        member
      }))
      yield put(setNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: `${titlecase(type)}d ${name}!`
      }))
    }
  } catch (err) {
    yield put(updateTeamMemberFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }

  yield fetchTeamCounts()
}

export function * deleteTeamMember (action) {
  const member = action.member
  const name = member.get('full_name')
  const memberId = member.get('id')
  try {
    yield call(remove, `/team/users/${memberId}`)
    yield put(deleteTeamMemberSuccess({
      member
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Removed ${name} from the team. 😭`
    }))
  } catch (err) {
    yield put(deleteTeamMemberFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }

  yield fetchTeamCounts()
}

export function * fetchTeamInvites () {
  try {
    const response = yield call(get, '/team/invites')
    const invites = fromJS(response.data)

    yield put(fetchTeamInvitesSuccess({
      invites
    }))
  } catch (err) {
    yield put(fetchTeamInvitesFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * resendTeamInvite (action) {
  const inviteId = action.invite.get('_id')
  try {
    const response = yield call(get, `/team/invites/${inviteId}/resend`)
    const invite = fromJS(response.data)
    const email = invite.get('email')

    yield put(resendTeamInviteSuccess({
      invite
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Resent invite to ${email}! 🚀`
    }))
  } catch (err) {
    yield put(resendTeamInviteFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * deleteTeamInvite (action) {
  const invite = action.invite
  const email = invite.get('email')
  const inviteId = action.invite.get('_id')
  try {
    yield call(remove, `/team/invites/${inviteId}`)
    yield put(deleteTeamInviteSuccess({
      invite
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Removed invite for ${email}.`
    }))
  } catch (err) {
    yield put(deleteTeamInviteFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }

  yield fetchTeamCounts()
}

export function * sendTeamInvite (action) {
  const params = action.params
  try {
    const response = yield call(post, '/team/invites/', params)
    const invite = fromJS(response.data)
    const email = invite.get('email')
    yield put(sendTeamInviteSuccess({
      invite
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Invite sent to ${email}! 🚀`
    }))
  } catch (err) {
    yield put(sendTeamInviteFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }

  yield fetchTeamCounts()
}

export function * assignGroupMember (action) {
  const params = action.params
  try {
    if (params.targetUserId) {
      const response = yield call(post, `/team/users/${params.targetUserId}/group`, params)
      const member = fromJS(response.data)
      yield put(updateTeamMemberSuccess({
        member
      }))
      yield put(setNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: 'Team member assigned to group 🚀'
      }))
    }
  } catch (err) {
    yield put(assignGroupMemberFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

function * fetchTeamMembersRequest () {
  yield takeLatest(FETCH_TEAM_MEMBERS_REQUEST, fetchTeamMembers)
}

function * deleteTeamMemberRequest () {
  yield takeLatest(DELETE_TEAM_MEMBER_REQUEST, deleteTeamMember)
}

function * fetchTeamInvitesRequest () {
  yield takeLatest(FETCH_TEAM_INVITES_REQUEST, fetchTeamInvites)
}

function * resendTeamInviteRequest () {
  yield takeLatest(RESEND_TEAM_INVITES_REQUEST, resendTeamInvite)
}

function * deleteTeamInviteRequest () {
  yield takeLatest(DELETE_TEAM_INVITES_REQUEST, deleteTeamInvite)
}

function * sendTeamInviteRequest () {
  yield takeLatest(SEND_TEAM_INVITE_REQUEST, sendTeamInvite)
}

function * updateTeamMemberRequest () {
  yield takeLatest(UPDATE_TEAM_MEMBER_REQUEST, updateTeamMember)
}

function * fetchTeamCountsRequest () {
  yield takeLatest(FETCH_TEAM_COUNTS_REQUEST, fetchTeamCounts)
}

function * fetchOwnerCrmRequest () {
  yield takeLatest(FETCH_OWNER_CRM_REQUEST, fetchOwnerCrm)
  yield takeLatest(REFRESH_OWNER_CRM_REQUEST, fetchOwnerCrm)
}

function * assignGroupMemberRequest () {
  yield takeLatest(ASSIGN_GROUP_MEMBER_REQUEST, assignGroupMember)
}

export default [
  fetchTeamMembersRequest,
  deleteTeamMemberRequest,
  fetchTeamInvitesRequest,
  resendTeamInviteRequest,
  deleteTeamInviteRequest,
  sendTeamInviteRequest,
  updateTeamMemberRequest,
  fetchTeamCountsRequest,
  fetchOwnerCrmRequest,
  assignGroupMemberRequest
]
