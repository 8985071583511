import React, { PureComponent } from 'react'

// Wraps components and adds session to component
export default function withErrorHandler (ErrorComponent, WrappedComponent) {
  class WrappedErrorComponent extends PureComponent {
    constructor (props) {
      super(props)
      this.state = {
        hasError: false
      }
    }

    componentDidCatch () {
      this.setState({
        hasError: true
      })
    }

    render () {
      const {
        hasError
      } = this.state
      if (hasError) {
        return ErrorComponent
      }
      return <WrappedComponent {...this.props} />
    }
  }

  return WrappedErrorComponent
}
