import { fromJS } from 'immutable'
import R from 'utils/ramda'
import { put, takeLatest, call } from 'redux-saga/effects'

import api from 'utils/api'

import {
  fetchSearchSuccess,
  fetchSearchFailure,
  fetchChecklistSuccess,
  fetchChecklistFailure
} from './actions'
import {
  FETCH_SEARCH_REQUEST,
  FETCH_CHECKLIST_REQUEST
} from './constants'

const get = (url, query) => api.get(url, {
  params: query
})

export function * fetchSearch (action) {
  let query

  // Build query
  if (!R.isEmpty(action.query)) {
    query = { query: action.query, limit: 10 }
  } else {
    query = { }
  }

  try {
    const campaignsResponse = yield call(get, '/campaigns/list', query)
    const campaigns = fromJS(campaignsResponse.data)
    const totalCampaigns = campaigns.size

    const contactsResponse = yield call(get, '/contacts', query)
    const contacts = fromJS(contactsResponse.data)
    const totalContacts = contacts.size

    yield put(fetchSearchSuccess({
      contacts,
      campaigns,
      totalContacts,
      totalCampaigns
    }))
  } catch (err) {
    yield put(fetchSearchFailure(err))
  }
}

export function * fetchChecklist (action) {
  try {
    const response = yield call(get, '/me/onboarding')
    const data = fromJS(response.data)

    yield put(fetchChecklistSuccess({
      data
    }))
  } catch (err) {
    yield put(fetchChecklistFailure(err))
  }
}

function * fetchSearchRequest () {
  yield takeLatest(FETCH_SEARCH_REQUEST, fetchSearch)
}

function * fetchChecklistRequest () {
  yield takeLatest(FETCH_CHECKLIST_REQUEST, fetchChecklist)
}

export default [
  fetchSearchRequest,
  fetchChecklistRequest
]
