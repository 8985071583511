import styled from 'styled-components'

import { space, width, textAlign } from 'styled-system'

export default styled.div`
  width: 100%;
  height: 1px;
  background-color: ${props => props.theme.colors.gray20};


  ${space}
  ${width}
  ${textAlign}
`
