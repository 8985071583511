import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Button from 'components/Button'
import Loading from 'components/Loading'
import Label from 'elements/Label'
import Subtitle from 'elements/Subtitle'
import { Check } from 'svg'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 5rem 1rem;
`

const CheckContainer = styled.div`
  padding-top: 0 3rem 3rem 3rem;
`

class ImportFinished extends PureComponent {
  render () {
    const {
      sequence,
      csv,
      importingCsv,
      router,
      refetchSequence
    } = this.props

    const loading = csv.get('loading')
    const importLoading = importingCsv.get('loading')

    if (loading || importLoading) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    const name = sequence.get('name')

    return (
      <Wrapper>
        <CheckContainer>
          <Check
            width={80}
            height={60}
            color='#1FC866'
          />
        </CheckContainer>
        <Subtitle mt='1rem'>Import started!</Subtitle>
        <Label align='center' mb='2rem'>
          Contacts are being added to the {name} sequence.
        </Label>
        <Button
          label='Back to Sequence'
          handleClick={() => {
            refetchSequence()
            const sequenceId = sequence.get('_id')
            router.push(`/sequence/${sequenceId}/contacts`)
          }}
          mb='2rem'
        />
      </Wrapper>
    )
  }
}

ImportFinished.propTypes = {
  sequence: PropTypes.instanceOf(Immutable.Map),
  csv: PropTypes.instanceOf(Immutable.Map),
  importingCsv: PropTypes.instanceOf(Immutable.Map),
  router: PropTypes.object,
  refetchSequence: PropTypes.func
}

export default withRouter(ImportFinished)
