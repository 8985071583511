import styled from 'styled-components'

export const Wrapper = styled.div`
  transition: background-color 0.3s ease, color .3s ease;
  position: relative;
  overflow: visible;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 2rem;
`

export const DropBar = styled.div`
  position: relative;

  width: calc(100% - 2px);
  margin-left: 1px;
  margin-right: 1px;
  margin-bottom: -2px;

  border-bottom: 2px solid ${props => props.hasSent
    ? props.theme.colors.red
    : props.theme.colors.blue
  };
  opacity: ${props => props.visible ? '1' : '0'};

  top: calc(-0.5rem - 1px);
  height: 0;
`
