import { format, endOfWeek } from 'date-fns'

import * as dateHelpers from './date_helpers'

export function getUserTotals (reports) {
  const users = reports.getIn(['data', 'populate', 'users'])

  const userTotals = []
  reports
    .getIn(['data', 'totals', 'users'])
    .forEach((total, userId) => {
      userTotals.push({
        total,
        photo: users.getIn([userId, 'photo_url']),
        name: users.getIn([userId, 'full_name']) || users.getIn([userId, 'email'])
      })
    })

  return userTotals
}

export function getGraphValues (reports, period = dateHelpers.DATE_RANGE.DAY) {
  const report = reports
    .getIn(['data', 'report', period])
    .sort()
    .toJS()

  const graphValues = []
  Object
    .entries(report)
    .forEach(([date, count]) => {
      const formattedStartDate = format(date, 'MMMM D, YYYY')

      let endDate = null
      if (period === 'week') {
        endDate = endOfWeek(date)
      }

      let formattedDate = formattedStartDate

      if (endDate) {
        const formattedEndDate = format(endDate, 'MMMM D, YYYY')
        formattedDate = `${formattedDate} – ${formattedEndDate}`
      }

      const average = reports.getIn(['data', 'report', 'moving_average', date])
      graphValues.push({ date: formattedStartDate, value: count, average, label: formattedDate })
    })

  return graphValues
}

export function formatBarGraphData (reports) {
  // get the replied total
  const totalUnits = reports.getIn(['data', 'totals', 'total'])

  // get user totals
  const userTotals = getUserTotals(reports)

  // get graph values
  const graphValuesDay = getGraphValues(reports, 'day')
  const graphValuesWeek = getGraphValues(reports, 'week')

  const graphValues = {
    day: graphValuesDay,
    week: graphValuesWeek
  }

  return { graphValues, totalUnits, userTotals }
}
