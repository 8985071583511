import React from 'react'

/* eslint-disable */
const Search = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 14} height={height || 15}  viewBox="0 0 14 15" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Campaign-List" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Campaign-List---Search" transform="translate(-95.000000, -24.000000)" strokeWidth="1.5" stroke={color || "#CCD1DC"}>
            <g id="background-example">
                <g id="header">
                    <g id="search" transform="translate(79.000000, 0.000000)">
                        <g id="1---Search-File-(Outline)" transform="translate(17.000000, 25.000000)">
                            <path d="M10.5050505,5.21604938 C10.5050505,8.09687346 8.15349495,10.4320988 5.25252525,10.4320988 C2.35155556,10.4320988 0,8.09687346 0,5.21604938 C0,2.33522531 2.35155556,0 5.25252525,0 C8.15349495,0 10.5050505,2.33522531 10.5050505,5.21604938 L10.5050505,5.21604938 Z" id="Path"></path>
                            <path d="M8.4040404,9.38888889 L11.8675778,12.8283739" id="Stroke-361" strokeLinecap="round"></path>
                        </g>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

export default Search;
