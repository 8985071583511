import React, { useState, useRef, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Cross, Search } from 'svg'
import { fadeIn } from 'utils/animations'

const Wrapper = styled.div``

const InputBoxContainer = styled.div``

const InputContainer = styled.div`
  display: flex;
  border: 1.5px solid ${props => props.theme.colors.gray60};
  justify-content: center;
  align-items: center;
  padding-left: 0.5rem;
  margin: 0 1rem;

  animation: ${fadeIn} .2s linear 1;
`

const SearchBox = styled.input`
  outline: none;
  border: none;

  color: ${props => props.theme.colors.darkBlue};
  background-color: ${props => props.theme.colors.white};

  font: ${props => props.theme.fonts.normal};

  padding: .75rem 0.5rem;
  height: 30px;
  width: 100%;

  ::placeholder {
    color: #aaa;
  }

  &:focus {
    /* border: 1px solid ${props => props.theme.colors.blue};
    box-shadow: 0 0 4px ${props => props.theme.colors.lightBlue}; */
  }
`

const SelectedContainer = styled.div`
  display: flex;
  justify-content: flex-start;
  align-items: flex-start;
  flex-wrap: wrap;
  padding: 0 1rem;

  width: 100%;
`

const SelectedItem = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: left;
  align-items: center;

  width: 100%;
  padding: .4rem 1rem;
  margin-bottom: .5rem;

  border-radius: 50px;
  border: 1px solid #588BE2;
  background-color: #E9F1FF;
  font: ${props => props.theme.fonts.label};
  color: ${props => props.theme.colors.darkBlue};
`

const SelectedItemCloseContainer = styled.div`
  cursor: pointer;
  padding-left: .55rem;

  svg {
    position: relative;
    top: -1px;
    width: 7px;
    height: 7px;
    stroke: ${props => props.theme.colors.blue};
    fill: ${props => props.theme.colors.blue};
    margin: auto;
  }
`

const SelectedItemText = styled.div`
  flex: 1;
`

function FilterTextField ({
  onSelected,
  selectedFilters,
  placeholder = 'Search',
  showPanel = false
}) {
  const searchBoxRef = useRef()

  const [value, setValue] = useState('')

  useEffect(() => {
    if (searchBoxRef?.current) {
      searchBoxRef.current.focus()
    }
  }, [showPanel])

  const handleChange = e => setValue(e.target.value)

  const onRemoveFilter = removeVal => {
    const newSelectedFilters = selectedFilters.filterNot(x => x === removeVal)

    onSelected(newSelectedFilters)
  }

  const renderSelectedFilters = () => {
    return selectedFilters.map(m => {
      return (
        <SelectedItem key={m}>
          <SelectedItemText>{m}</SelectedItemText>
          <SelectedItemCloseContainer onClick={(_) => onRemoveFilter(m)}>
            <Cross />
          </SelectedItemCloseContainer>
        </SelectedItem>
      )
    })
  }

  const handleKeyDown = e => {
    if (e.keyCode === 13) {
      const newVal = selectedFilters.add(e.target.value)
      onSelected(newVal)
      setValue('')
    }
  }

  return (
    <Wrapper>
      <InputBoxContainer>
        <SelectedContainer>
          {renderSelectedFilters()}
        </SelectedContainer>
        {showPanel &&
          <InputContainer>
            <Search />
            <SearchBox
              onChange={handleChange}
              value={value}
              innerRef={searchBoxRef}
              onKeyDown={handleKeyDown}
              placeholder={placeholder}
            />
          </InputContainer>}
      </InputBoxContainer>
    </Wrapper>
  )
}

FilterTextField.propTypes = {
  onSelected: PropTypes.func,
  selectedFilters: PropTypes.object,
  placeholder: PropTypes.string,
  showPanel: PropTypes.bool
}

export default FilterTextField
