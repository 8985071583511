import styled from 'styled-components'
import { apiBaseURI } from 'utils/api'
import { space, width, height } from 'styled-system'

export default styled.div`
  background-image: ${props => (props.type ? `url(${apiBaseURI}/images/${props.type.toLowerCase().replace(' ', '_')}.png)` : '')};
  background-size: contain;
  background-position: center;
  width: 3rem;
  height: 2rem;
  display: inline-block;

  ${space}
  ${width}
  ${height}
`
