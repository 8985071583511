import React from 'react'
import PropTypes from 'prop-types'

import Verified from 'elements/Verified'
import Warning from 'elements/Warning'
import Error from 'elements/Error'
import Unknown from 'elements/Unknown'
import { Tooltip } from 'react-tippy'

class EmailValidityIcon extends React.Component {
  createVerifiedIcon = () => {
    const { contactValidity } = this.props
    let validMessage
    let validElement

    if (contactValidity === 'GOOD') {
      validMessage = 'Email verified and deliverable'
      validElement = <Verified />
    } else if (contactValidity === 'RISKY') {
      validMessage = 'Email is deliverable but not verified'
      validElement = <Warning />
    } else if (contactValidity === 'INVALID' || contactValidity === 'REJECTED') {
      validMessage = 'Email is invalid and will probably bounce'
      validElement = <Error />
    } else if (contactValidity === 'UNKNOWN') {
      validMessage = 'Email could not be verified'
      validElement = <Unknown />
    }

    return (
      <>
        {validElement &&
          <Tooltip
            title={validMessage}
            position='top'
            trigger='mouseenter'
            style={{ marginTop: '2px' }}
            arrow
          >
            {validElement}
          </Tooltip>}
      </>
    )
  }

  render () {
    return (
      <>
        {this.createVerifiedIcon()}
      </>
    )
  }
}

EmailValidityIcon.propTypes = {
  contactValidity: PropTypes.string
}

export default EmailValidityIcon
