import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Input from 'components/Input'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class NewTemplateModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      name: '',
      shared: false
    }
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13 && this.state.name && this.state.name.length > 0) {
      e.preventDefault()
      return this.props.onSaveTemplate(this.state)
    }
    return false
  }

  render () {
    const {
      title,
      description,
      onCancel,
      onSaveTemplate,
      isOpen
    } = this.props

    const {
      name,
      shared
    } = this.state

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <InputContainer>
            <Title align='center'>{title}</Title>
            <Label mt='0.5rem' mb='1rem' align='center'>
              {description}
            </Label>
            <Input
              label='Name of the template'
              inputId='name'
              value={name}
              disabledDays={{ before: new Date() }}
              onValueChange={(value) => {
                this.setState({
                  name: value
                })
              }}
              mt='1.5rem'
            />
            <SwitchFieldForm
              label='Share with team'
              description='Share this template with your entire team and allow them to use it in their own sequences'
              inputId='shared'
              value={shared}
              onSave={(value) => {
                this.setState({
                  shared: value
                })
              }}
              mt='1.5rem'
            />
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={() => {
                this.setState({
                  name: '',
                  shared: false
                })
                onCancel()
              }}
              mr='0.5rem'
            />
            <Button
              primary
              disabled={name === null}
              label='create'
              onClick={() => {
                onSaveTemplate(this.state)
                this.setState({
                  name: '',
                  shared: false
                })
              }}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

NewTemplateModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  onSaveTemplate: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool
}

NewTemplateModal.defaultProps = {
  isOpen: false
}

export default NewTemplateModal
