import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'

import SwitchFieldForm from 'components/SwitchFieldForm'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class ExportPdfModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showContactInfo: true
    }
  }

  render () {
    const {
      onCancel,
      onConfirm,
      isOpen,
      description
    } = this.props

    const {
      showContactInfo
    } = this.state

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper>
          <InputContainer>
            <Title align='center'>Export to PDF</Title>
            <Label mt='0.5rem' mb='2rem' align='center'>
              {description}
            </Label>
            <SwitchFieldForm
              label='Show Contact Info'
              value={showContactInfo}
              description="Choose to show if the export will include a contact's email address and phone number"
              inputId='showContactInfo'
              onSave={(value) => {
                this.setState({
                  showContactInfo: value
                })
              }}
            />
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              label='Export'
              onClick={() => {
                onConfirm(showContactInfo)
              }}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

ExportPdfModal.propTypes = {
  description: PropTypes.string,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool
}

ExportPdfModal.defaultProps = {
  loading: false,
  isOpen: false
}

export default ExportPdfModal
