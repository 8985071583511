import React, { useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 100%;
  overflow: hidden;
`

const NameWrapper = styled.p`
  font-size: 13px;
  white-space: nowrap;
  margin: 0;
`

const CheckboxWrapper = styled.div`
  padding: 0 0.5rem;
`

const CheckboxInnerWrapper = styled.div`
  flex: 0;
  width: fit-content;

  &:hover,
  &:focus {
    opacity: ${props => !props.disabled ? 0.5 : 1};
    transition: ${props => !props.disabled ? 'opacity .15s ease-in' : 'none'};
  }

  &:active {
    opacity: ${props => !props.disabled ? 0.8 : 1};
    transition: ${props => !props.disabled ? 'opacity .15s ease-in' : 'none'};
  }
`

function NameCellRenderer ({
  cellProperties,
  disabled = false,
  value,
  setRowAsSelected,
  selectedRowIds,
  ...props
}) {
  const [isSelected, setIsSelected] = useState(() => {
    const { instance, row } = cellProperties
    let selected = false

    if (instance) {
      const rowData = instance.getSourceDataAtRow(row)
      const id = rowData?.id || null
      selected = selectedRowIds?.includes(id) || false
    }

    return selected
  })

  useEffect(() => {
    if (cellProperties?.className) {
      props.TD.className = cellProperties.className
    }
  })

  const handleClick = e => {
    const { row, instance } = cellProperties
    const { id, name } = instance.getSourceDataAtRow(row)
    const params = {
      id,
      name,
      val: !isSelected
    }

    setRowAsSelected(params)
    setIsSelected(!isSelected)
  }

  return (
    <Wrapper>
      <CheckboxWrapper>
        <CheckboxInnerWrapper
          onClick={(e) => {
            e.stopPropagation()
            e.preventDefault()
            handleClick(e)
          }}
        >
          <input type='checkbox' checked={isSelected} readOnly />
        </CheckboxInnerWrapper>
      </CheckboxWrapper>
      <NameWrapper>
        {value}
      </NameWrapper>
    </Wrapper>
  )
}

NameCellRenderer.propTypes = {
  value: PropTypes.string,
  disabled: PropTypes.bool,
  cellProperties: PropTypes.object,
  setRowAsSelected: PropTypes.func,
  selectedRowIds: PropTypes.object,
  TD: PropTypes.object
}

export default withTheme(NameCellRenderer)
