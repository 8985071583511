import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { height, space, width } from 'styled-system'

import Label from 'elements/Label'
import IconButton from 'components/IconButton'

import { timeAgo } from 'utils/dates'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const Title = styled.label`
  width: 100%;
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  letter-spacing: 0.05em;
  font-size: 15px;
  text-transform: uppercase;
`

const Description = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.5rem;
  letter-spacing: 0.05em;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
`

const CronsContainer = styled.div``

const FlexWrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  margin: 1rem 0;
  padding 1rem;
  border-width: 1px;
  border-color: #E7E9ED;
  border-style: solid;
`

class CrmImportCrons extends Component {
  render () {
    const {
      importCrons,
      label,
      description,
      theme,
      onDeleteCron,
      ...rest
    } = this.props

    return (
      <Wrapper
        {...rest}
      >
        <TitleContainer>
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {label &&
              <Title>{label}</Title>}
            {description &&
              <Description>{description}</Description>}
          </div>
        </TitleContainer>
        <CronsContainer>
          {importCrons &&
            importCrons.map(cron =>
              <FlexWrapper key={cron.get('_id')}>
                <Label key={cron.get('_id')}>
                  <strong>{`${cron.get('optionName')} → `}{cron.getIn(['_campaign', 'title'])}</strong>
                  {cron.get('last_ran_at') &&
                    <div>Last ran {timeAgo(cron.get('last_ran_at'))}</div>}
                </Label>
                <IconButton
                  onClick={() => {
                    onDeleteCron({
                      id: cron.get('_id')
                    })
                  }}
                  type={['fal', 'trash-alt']}
                  marginRight='0.5rem'
                  translateY={-1}
                />
              </FlexWrapper>
            )}
        </CronsContainer>
      </Wrapper>
    )
  }
}

CrmImportCrons.propTypes = {
  importCrons: PropTypes.Array,
  onDeleteCron: PropTypes.func,
  label: PropTypes.string,
  description: PropTypes.any,
  theme: PropTypes.object
}

export default withTheme(CrmImportCrons)
