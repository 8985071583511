import { ERROR_TYPE } from 'containers/Sequence/constants'
import { pluralize } from 'utils/strings'
import { contactErrorToString } from 'utils/keyMapping'

const getSingleContactErrorData = (error, errorMessage, crm) => {
  if (!error) {
    return null
  }

  const crmName = (crm && crm.getIn(['data', 'name']))
    ? crm.getIn(['data', 'name'])
    : null

  const contactErrorData = {
    title: null,
    description: null,
    clearingDisabled: false
  }

  contactErrorData.title = contactErrorToString(error, crmName)
  contactErrorData.description = errorMessage || 'Has this error'

  switch (error) {
    case ERROR_TYPE.INVALID:
      contactErrorData.description = (errorMessage) || 'Invalid email address'
      break
    case ERROR_TYPE.MISSING_VARIABLE:
      contactErrorData.description = (errorMessage) || 'Missing data from steps'
      break
    case ERROR_TYPE.BLOCKED_CONTACT:
    case ERROR_TYPE.BLACKLISTED:
      // contactErrorData.clearingDisabled = true;
      contactErrorData.description = (errorMessage) || 'Blocklist'
      break
    case ERROR_TYPE.TEAM_BLOCKED_CONTACT:
    case ERROR_TYPE.TEAM_BLACKLISTED:
      // contactErrorData.clearingDisabled = true;
      contactErrorData.description = (errorMessage) || 'Team blocklist'
      break
    case ERROR_TYPE.DUPLICATE_CRM:
      contactErrorData.description = (errorMessage) || `Found in ${(crmName) || 'your integration'}`
      break
    case ERROR_TYPE.CRM_VALIDATION:
      contactErrorData.description = (errorMessage) || `Failed to sync to ${crmName || 'your integration'}`
      break
    case ERROR_TYPE.DUPLICATE_CAMPAIGN:
      contactErrorData.description = (errorMessage) || 'Duplicate contact found in your sequence'
      break
    case ERROR_TYPE.DUPLICATE_TEAM:
      contactErrorData.description = (errorMessage) || 'Duplicate contact found in your teammates\' sequence'
      break
    case ERROR_TYPE.REPLIED_INDIRECTLY:
      contactErrorData.description = (errorMessage) || 'Replied outside of Interseller'
      break
    case ERROR_TYPE.REPLIED_CAMPAIGN:
      contactErrorData.description = (errorMessage) || 'Replied to another sequence'
      break
    case ERROR_TYPE.REPLIED_DOMAIN:
      contactErrorData.description = (errorMessage) || 'Colleague responded'
      break
    case ERROR_TYPE.REPLIED_TEAM:
      contactErrorData.description = (errorMessage) || 'Replied to another teammate'
      break
    case ERROR_TYPE.UNSUBSCRIBED:
      // contactErrorData.clearingDisabled = true;
      contactErrorData.description = (errorMessage) || 'Has unsubscribed'
      break
    case ERROR_TYPE.BOUNCED:
      contactErrorData.description = (errorMessage) || 'Has bounced email'
      break
    case ERROR_TYPE.GDPR_WARNING:
      contactErrorData.description = (errorMessage) || 'In GDPR country'
      break
    default:
      break
  }

  return contactErrorData
}

const getGroupContactErrorData = (error, errorCount, crm) => {
  if (!error || !errorCount) {
    return null
  }

  const crmName = (crm && crm.getIn(['data', 'name']))
    ? crm.getIn(['data', 'name'])
    : null

  const contactErrorData = {
    title: null,
    description: null,
    clearingDisabled: false
  }

  contactErrorData.title = contactErrorToString(error, crmName)
  contactErrorData.description = `${pluralize('contact has', 'contacts have', errorCount)} this error`

  switch (error) {
    case ERROR_TYPE.INVALID:
      contactErrorData.description = `invalid email ${pluralize('address', 'addresses', errorCount)}`
      break
    case ERROR_TYPE.MISSING_VARIABLE:
      contactErrorData.description = `${pluralize('contact', 'contacts', errorCount)} missing data for steps`
      break
    case ERROR_TYPE.BLOCKED_CONTACT:
    case ERROR_TYPE.BLOCKLISTED:
      // contactErrorData.clearingDisabled = true;
      contactErrorData.description = `Blocked ${pluralize('contact', 'contacts', errorCount)}`
      break
    case ERROR_TYPE.TEAM_BLOCKED_CONTACT:
    case ERROR_TYPE.TEAM_BLOCKLISTED:
      // contactErrorData.clearingDisabled = true;
      contactErrorData.description = `Team blocked ${pluralize('contact', 'contacts', errorCount)}`
      break
    case ERROR_TYPE.DUPLICATE_CRM:
      contactErrorData.description = `${pluralize('contact', 'contacts', errorCount)} found in ${(crmName) || 'your integration'}`
      break
    case ERROR_TYPE.CRM_VALIDATION:
      contactErrorData.description = `${pluralize('contact', 'contacts', errorCount)} failed to sync to ${(crmName) || 'your integration'}`
      break
    case ERROR_TYPE.DUPLICATE_CAMPAIGN:
      contactErrorData.description = `duplicate ${pluralize('contact', 'contacts', errorCount)} found in your sequences`
      break
    case ERROR_TYPE.DUPLICATE_TEAM:
      contactErrorData.description = `duplicate ${pluralize('contact', 'contacts', errorCount)} found in your teammates' sequences`
      break
    case ERROR_TYPE.REPLIED_INDIRECTLY:
      contactErrorData.description = `${pluralize('contact', 'contacts', errorCount)} replied outside of Interseller`
      break
    case ERROR_TYPE.REPLIED_CAMPAIGN:
      contactErrorData.description = `${pluralize('contact', 'contacts', errorCount)} to another sequence`
      break
    case ERROR_TYPE.REPLIED_DOMAIN:
      contactErrorData.description = `${pluralize('contact has', 'contacts have', errorCount)} had a colleague respond`
      break
    case ERROR_TYPE.REPLIED_TEAM:
      contactErrorData.description = `${pluralize('contact has', 'contacts have', errorCount)} replied to another teammate`
      break
    case ERROR_TYPE.UNSUBSCRIBED:
      // contactErrorData.clearingDisabled = true;
      contactErrorData.description = `${pluralize('contact has', 'contacts have', errorCount)} unsubscribed`
      break
    case ERROR_TYPE.BOUNCED:
      contactErrorData.description = `${pluralize('contact has', 'contacts have', errorCount)} bounced emails`
      break
    case ERROR_TYPE.DUPLICATE_DOMAIN:
      contactErrorData.description = `${pluralize('contact has', 'contacts have', errorCount)} this error`
      break
    case ERROR_TYPE.GDPR_WARNING:
      contactErrorData.description = `${pluralize('contact is', 'contacts are', errorCount)} located in a GDPR country`
      break
    default:
      break
  }

  return contactErrorData
}

export {
  getSingleContactErrorData,
  getGroupContactErrorData
}
