import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { subDays } from 'date-fns'
import DateField from 'components/DateField'
import Loading from 'components/Loading'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding: 2rem 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding: 2rem;
`

class LaunchSequence extends Component {
  constructor (props) {
    super(props)
    this.state = {
      paused_until: null
    }
  }

  handleKeyDown = (e) => {
    // If 'enter' is pressed
    if (e.keyCode === 13) {
      e.preventDefault()
      return this.props.handleLaunchSequence(this.state)
    }
    return false
  }

  render () {
    const {
      loading,
      closeModal,
      handleLaunchSequence
    } = this.props

    if (loading) {
      return <Loading />
    }

    const timezone = Intl.DateTimeFormat().resolvedOptions().timeZone

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <InputContainer>
          <Title align='center'>Launch Sequence</Title>
          <Label mt='0.5rem' mb='0.5rem' align='center'>
            Launch your sequence now or schedule a specific time for the sequence to launch
          </Label>
          <DateField
            label='Start Sending On'
            placeholder='Select a date (optional)'
            subtitle={`Based on your browser's timezone (${timezone})`}
            inputId='paused_until_date'
            isValidDate={current => (current.isAfter(subDays(new Date(), 1)))}
            onValueChange={(value) => {
              this.setState({
                paused_until: value
              })
            }}
            mt='1.5rem'
            mb='0.25rem'
          />
        </InputContainer>
        <Divider />
        <ButtonContainer>
          <Button
            label='cancel'
            onClick={() => {
              this.setState({})
              closeModal()
            }}
            mr='0.5rem'
          />
          <Button
            primary
            label={this.state.paused_until ? 'Schedule' : 'Launch Now'}
            onClick={() => {
              handleLaunchSequence(this.state)
              this.setState({})
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

LaunchSequence.propTypes = {
  loading: PropTypes.bool,
  handleLaunchSequence: PropTypes.func,
  closeModal: PropTypes.func
}

LaunchSequence.defaultProps = {
  loading: false
}

export default LaunchSequence
