export const FETCH_TASKS_REQUEST = 'Tasks/FETCH_TASKS_REQUEST'
export const FETCH_TASKS_SUCCESS = 'Tasks/FETCH_TASKS_SUCCESS'
export const FETCH_TASKS_FAILURE = 'Tasks/FETCH_TASKS_FAILURE'

export const FETCH_MESSAGES_REQUEST = 'Tasks/FETCH_MESSAGES_REQUEST'
export const FETCH_MESSAGES_SUCCESS = 'Tasks/FETCH_MESSAGES_SUCCESS'
export const FETCH_MESSAGES_FAILURE = 'Tasks/FETCH_MESSAGES_FAILURE'
export const FETCH_MESSAGES_RESET = 'Tasks/FETCH_MESSAGES_RESET'

export const UPDATE_CONTACT_MESSAGE_REQUEST = 'Tasks/UPDATE_CONTACT_MESSAGE_REQUEST'
export const UPDATE_CONTACT_MESSAGE_SUCCESS = 'Tasks/UPDATE_CONTACT_MESSAGE_SUCCESS'
export const UPDATE_CONTACT_MESSAGE_FAILURE = 'Tasks/UPDATE_CONTACT_MESSAGE_FAILURE'

export const FETCH_CONTACT_ERRORS_REQUEST = 'Tasks/FETCH_CONTACT_ERRORS_REQUEST'
export const FETCH_CONTACT_ERRORS_SUCCESS = 'Tasks/FETCH_CONTACT_ERRORS_SUCCESS'
export const FETCH_CONTACT_ERRORS_FAILURE = 'Tasks/FETCH_CONTACT_ERRORS_FAILURE'

export const FETCH_CONTACT_ERRORS_STATS_REQUEST = 'Tasks/FETCH_CONTACT_ERRORS_STATS_REQUEST'
export const FETCH_CONTACT_ERRORS_STATS_SUCCESS = 'Tasks/FETCH_CONTACT_ERRORS_STATS_SUCCESS'
export const FETCH_CONTACT_ERRORS_STATS_FAILURE = 'Tasks/FETCH_CONTACT_ERRORS_STATS_FAILURE'

export const REMOVE_CONTACT_ERRORS_REQUEST = 'Tasks/REMOVE_CONTACT_ERRORS_REQUEST'
export const REMOVE_CONTACT_ERRORS_SUCCESS = 'Tasks/REMOVE_CONTACT_ERRORS_SUCCESS'
export const REMOVE_CONTACT_ERRORS_FAILURE = 'Tasks/REMOVE_CONTACT_ERRORS_FAILURE'

export const UPDATE_CONTACT_ERRORS_REQUEST = 'Tasks/UPDATE_CONTACT_ERRORS_REQUEST'
export const UPDATE_CONTACT_ERRORS_SUCCESS = 'Tasks/UPDATE_CONTACT_ERRORS_SUCCESS'
export const UPDATE_CONTACT_ERRORS_FAILURE = 'Tasks/UPDATE_CONTACT_ERRORS_FAILURE'

export const REMOVE_CONTACT_REQUEST = 'Tasks/REMOVE_CONTACT_REQUEST'
export const REMOVE_CONTACT_SUCCESS = 'Tasks/REMOVE_CONTACT_SUCCESS'
export const REMOVE_CONTACT_FAILURE = 'Tasks/REMOVE_CONTACT_FAILURE'

export const UPDATE_CONTACT_REQUEST = 'Tasks/UPDATE_CONTACT_REQUEST'
export const UPDATE_CONTACT_SUCCESS = 'Tasks/UPDATE_CONTACT_SUCCESS'
export const UPDATE_CONTACT_FAILURE = 'Tasks/UPDATE_CONTACT_FAILURE'

export const FETCH_CONTACT_ACTIONS_REQUEST = 'Tasks/FETCH_CONTACT_ACTIONS_REQUEST'
export const FETCH_CONTACT_ACTIONS_SUCCESS = 'Tasks/FETCH_CONTACT_ACTIONS_SUCCESS'
export const FETCH_CONTACT_ACTIONS_FAILURE = 'Tasks/FETCH_CONTACT_ACTIONS_FAILURE'
export const FETCH_CONTACT_ACTIONS_RESET = 'Tasks/FETCH_CONTACT_ACTIONS_RESET'

export const FETCH_REPLIED_CONTACT_REQUEST = 'Tasks/FETCH_REPLIED_CONTACT_REQUEST'
export const FETCH_REPLIED_CONTACT_SUCCESS = 'Tasks/FETCH_REPLIED_CONTACT_SUCCESS'
export const FETCH_REPLIED_CONTACT_FAILURE = 'Tasks/FETCH_REPLIED_CONTACT_FAILURE'

export const FETCH_SENTIMENT_SCORE_REQUEST = 'Tasks/FETCH_SENTIMENT_SCORE_REQUEST'
export const FETCH_SENTIMENT_SCORE_SUCCESS = 'Tasks/FETCH_SENTIMENT_SCORE_SUCCESS'
export const FETCH_SENTIMENT_SCORE_FAILURE = 'Tasks/FETCH_SENTIMENT_SCORE_FAILURE'

export const UPDATE_CONTACT_SENTIMENT_REQUEST = 'Tasks/UPDATE_CONTACT_SENTIMENT_REQUEST'
export const UPDATE_CONTACT_SENTIMENT_SUCCESS = 'Tasks/UPDATE_CONTACT_SENTIMENT_SUCCESS'
export const UPDATE_CONTACT_SENTIMENT_FAILURE = 'Tasks/UPDATE_CONTACT_SENTIMENT_FAILURE'

export const FETCH_MANUAL_TASKS_REQUEST = 'Tasks/FETCH_MANUAL_TASKS_REQUEST'
export const FETCH_MANUAL_TASKS_SUCCESS = 'Tasks/FETCH_MANUAL_TASKS_SUCCESS'
export const FETCH_MANUAL_TASKS_FAILURE = 'Tasks/FETCH_MANUAL_TASKS_FAILURE'

export const FETCH_CONTACT_TASK_REQUEST = 'Tasks/FETCH_CONTACT_TASK_REQUEST'
export const FETCH_CONTACT_TASK_SUCCESS = 'Tasks/FETCH_CONTACT_TASK_SUCCESS'
export const FETCH_CONTACT_TASK_FAILURE = 'Tasks/FETCH_CONTACT_TASK_FAILURE'
export const FETCH_CONTACT_TASK_RESET = 'Tasks/FETCH_CONTACT_TASK_RESET'

export const UPDATE_CONTACT_TASK_REQUEST = 'Tasks/UPDATE_CONTACT_TASK_REQUEST'
export const UPDATE_CONTACT_TASK_SUCCESS = 'Tasks/UPDATE_CONTACT_TASK_SUCCESS'
export const UPDATE_CONTACT_TASK_FAILURE = 'Tasks/UPDATE_CONTACT_TASK_FAILURE'

export const SET_PAUSE_CONTACT_TASK = 'Tasks/SET_PAUSE_CONTACT_TASK'
export const CLEAR_PAUSE_CONTACT_TASK = 'Tasks/CLEAR_PAUSE_CONTACT_TASK'

export const SKIP_MANUAL_TASK_REQUEST = 'Tasks/SKIP_MANUAL_TASK_REQUEST'
export const SKIP_MANUAL_TASK_SUCCESS = 'Tasks/SKIP_MANUAL_TASK_SUCCESS'
export const SKIP_MANUAL_TASK_FAILURE = 'Tasks/SKIP_MANUAL_TASK_FAILURE'

export const SKIP_MANUAL_TASKS_REQUEST = 'Tasks/SKIP_MANUAL_TASKS_REQUEST'
export const SKIP_MANUAL_TASKS_SUCCESS = 'Tasks/SKIP_MANUAL_TASKS_SUCCESS'
export const SKIP_MANUAL_TASKS_FAILURE = 'Tasks/SKIP_MANUAL_TASKS_FAILURE'
