import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import Avatar from 'react-avatar'
import NumberFormat from 'react-number-format'
import {
  Column,
  AutoSizer,
  Table,
  SortIndicator
} from 'react-virtualized'

import Loading from 'components/Loading'
import { FullContainer } from 'containers/Reports/styles'

import {
  MESSAGE_REPORTS,
  PERSONALIZED_MESSAGE_REPORTS
} from 'containers/Reports/constants'

const TableContainer = styled.div`
  /* background-color: white; */

  .ReactVirtualized__Table { }

  .ReactVirtualized__Table__headerColumn:first-of-type, .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 1rem;
    text-align: left;
  }

  .ReactVirtualized__Table__headerColumn:last-of-type, .ReactVirtualized__Table__rowColumn:last-of-type {
    margin-right: 1rem;
  }

  .ReactVirtualized__Table__headerRow {
    border: 1px solid ${props => props.theme.colors.gray30};
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.darkBlue};
    font-size: 15px;
    text-transform: capitalize;
    text-align: left;

    cursor: pointer;
    user-select: none;
  }

  .ReactVirtualized__Table__row {
    background-color: white;
    padding: 0;
    color: ${props => props.theme.colors.darkBlue};

    border: 1px solid ${props => props.theme.colors.gray30};
    border-bottom: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid ${props => props.theme.colors.gray30};
    }
  }
`

const ColumnGroup = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;

  color: ${props => props.theme.colors.darkGray};
  font-size: 18px;
  font: ${props => props.theme.fonts.normal};
`

const RowGroup = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
`

const UserCell = styled.div`
  display: flex;
  color: ${props => props.theme.colors.darkGray};
`

const UserDetails = styled.div`
  flex: 1;
  width: 0;
  font: ${props => props.theme.fonts.normal};
  margin-left: 0.75rem;

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;

  > div {
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
  }
`

const UserDetailName = styled.div`
  font: ${props => props.theme.fonts.formtitle};
  color: ${props => props.theme.colors.darkBlue};
`

const Badge = styled.span`
  background-color: ${props => props.theme.background};
  border-radius: 3px;
  font-size: .8rem;
  margin-left: 5px;
  padding: 0.5px 4px;
`

class MessagePerformanceReport extends React.Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  formatTableData = () => {
    const {
      users,
      reports,
      showPersonalizedMessagePerformance,
      personalizedMessagesReports
    } = this.props

    let { sortBy, sortDirection } = this.state
    const tableRows = []

    if (showPersonalizedMessagePerformance) {
      const columnNames = PERSONALIZED_MESSAGE_REPORTS

      users
        .get('data')
        .filter(user => {
          return personalizedMessagesReports.hasIn(['data', 'users', user.get('id')])
        })
        .forEach(user => {
          const report = personalizedMessagesReports.getIn(['data', 'users', user.get('id')]) || Immutable.Map()

          const newRow = { user, userName: user.get('full_name') }
          columnNames.forEach(columnName => {
            const total = report.get(columnName) || Immutable.Map({})
            newRow[`report_${columnName}`] = total.hasIn(['total', 'count']) ? total.getIn(['total', 'count']) : total.get('total')
          })

          tableRows.push(newRow)
        })
    } else {
      const columnNames = MESSAGE_REPORTS
      users
        .get('data')
        .filter(user => {
          return reports.hasIn(['data', user.get('id')])
        })
        .forEach(user => {
          const report = reports.getIn(['data', user.get('id')]) || Immutable.Map()

          const newRow = { user, userName: user.get('full_name') }
          columnNames.forEach(columnName => {
            const total = report.get(columnName) || Immutable.Map({})
            newRow[`report_${columnName}`] = total.hasIn(['total', 'count']) ? total.getIn(['total', 'count']) : total.get('total')
          })

          tableRows.push(newRow)
        })
    }

    if (sortBy) {
      if (sortBy === 'user') {
        sortBy = 'userName'
      }

      let sort = Immutable.List(tableRows)
      sort = sort.sortBy(row => row[sortBy])

      if (sortDirection === 'DESC') {
        sort = sort.reverse()
      }

      return sort.toArray()
    }
    return tableRows
  }

  _numberCellRenderer = ({ dataKey, rowData }) => {
    const { showPersonalizedMessagePerformance } = this.props

    const total = showPersonalizedMessagePerformance ? rowData.report_messages_manual : rowData.report_messages
    const reportData = rowData[dataKey] || 0
    const showPct = dataKey !== 'report_messages' && dataKey !== 'report_messages_manual' && total > 0

    return (
      <ColumnGroup>
        <RowGroup>
          <NumberFormat
            value={reportData}
            displayType='text'
            thousandSeparator=','
            style={{ fontWeight: '300', fontSize: '18px' }}
          />
          {showPct &&
            <Badge>
              <NumberFormat
                value={Math.abs(reportData / total * 100)}
                suffix='%'
                displayType='text'
                thousandSeparator=','
                decimalScale='1'
              />
            </Badge>}
        </RowGroup>
      </ColumnGroup>
    )
  }

  _userCellRenderer = ({ cellData }) => {
    return (
      <UserCell>
        <Avatar
          key={`user_${cellData.get('id')}`}
          src={cellData.get('photo_url' || '/bear_avatar.png')}
          size={44}
          round
        />
        <UserDetails>
          <UserDetailName>{cellData.get('full_name')}</UserDetailName>
          <div>{cellData.get('email')}</div>
        </UserDetails>
      </UserCell>
    )
  }

  _headerRenderer ({ label, dataKey, sortBy, sortDirection }) {
    return (
      <div>
        {label}

        {// indicator displaying which way a column is sorted
          sortBy === dataKey &&
            <SortIndicator sortDirection={sortDirection} />
}
      </div>
    )
  }

  sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortBy,
      sortDirection
    })
  }

  getTableColumns = () => {

  }

  render () {
    const { users, reports, showPersonalizedMessagePerformance } = this.props
    const { sortBy, sortDirection } = this.state

    if (!users || !reports || users.get('loading') || reports.get('loading')) {
      return <Loading width='100%' />
    }

    const tableRows = this.formatTableData()
    const tableColumnWidth = 140

    return (
      <FullContainer>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              const firstColumnWidth = Math.max(tableColumnWidth, width - (tableColumnWidth * 3.5))

              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={70}
                  rowCount={tableRows.length}
                  rowGetter={({ index }) => tableRows[index]}
                  sort={this.sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  <Column
                    key='user'
                    dataKey='user'
                    label='User'
                    width={firstColumnWidth}
                    cellRenderer={this._userCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Messages'
                    dataKey={showPersonalizedMessagePerformance
                      ? 'report_messages_manual'
                      : 'report_messages'}
                    label='Sent'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Opened'
                    dataKey={showPersonalizedMessagePerformance
                      ? 'report_messages_manual_opened'
                      : 'report_messages_opened'}
                    label='Opened'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Clicked'
                    dataKey={showPersonalizedMessagePerformance
                      ? 'report_messages_manual_clicked'
                      : 'report_messages_clicked'}
                    label='Clicked'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Replied'
                    dataKey={showPersonalizedMessagePerformance
                      ? 'report_messages_manual_replied'
                      : 'report_messages_replied'}
                    label='Replied'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Bounced'
                    dataKey={showPersonalizedMessagePerformance
                      ? 'report_messages_manual_bounced'
                      : 'report_messages_bounced'}
                    label='bounced'
                    width={tableColumnWidth}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />
                </Table>
              )
            }}
          </AutoSizer>
        </TableContainer>
      </FullContainer>
    )
  }
}

MessagePerformanceReport.propTypes = {
  actions: PropTypes.object,
  theme: PropTypes.object,
  reports: PropTypes.object,
  users: PropTypes.object,
  showPersonalizedMessagePerformance: PropTypes.bool,
  personalizedMessagesReports: PropTypes.object
}

export default MessagePerformanceReport
