import { createSelector } from 'reselect'

const selectSettingsDomain = () => state => state.get('settings')

const selectAddresses = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.getIn(['addresses', 'data'])
)

const selectTeamSettings = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('teamSettings')
)

const selectUpdateSessionError = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get(['updateSession', 'error'])
)

const selectTeamDomain = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('teamDomain')
)

const selectBlocklist = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('blocklist')
)

const selectTeamBlocklist = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('teamBlocklist')
)

const selectConfig = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('config')
)

const selectCalendars = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('calendars')
)

const selectTeamLogs = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('teamLogs')
)

// Pull global session
const selectGlobal = () => state => state.get('global')

const selectSession = () => createSelector(
  selectGlobal(),
  globalState => globalState.get('session')
)

const selectPurgeEmailCount = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('purgeEmailCount')
)

const selectShowPurgeModal = () => createSelector(
  selectSettingsDomain(),
  settingsState => settingsState.get('showPurgeModal')
)

export {
  selectAddresses,
  selectSession,
  selectTeamSettings,
  selectUpdateSessionError,
  selectTeamDomain,
  selectBlocklist,
  selectTeamBlocklist,
  selectConfig,
  selectCalendars,
  selectTeamLogs,
  selectPurgeEmailCount,
  selectShowPurgeModal
}
