import Immutable, { fromJS } from 'immutable'

import {
  DELETE_SEQUENCE_SUCCESS,
  FETCH_SEQUENCE_STATS_SUCCESS,
  UPDATE_SEQUENCE_SUCCESS
} from 'containers/Sequence/constants'

import {
  FETCH_SEQUENCES_REQUEST,
  FETCH_SEQUENCES_SUCCESS,
  FETCH_SEQUENCES_FAILURE,

  FETCH_ARCHIVED_SEQUENCES_REQUEST,
  FETCH_ARCHIVED_SEQUENCES_SUCCESS,
  FETCH_ARCHIVED_SEQUENCES_FAILURE,

  DELETE_SEQUENCES_SUCCESS,
  UPDATE_SEQUENCES_SUCCESS,

  FETCH_SEQUENCES_STATS_REQUEST,
  FETCH_SEQUENCES_STATS_SUCCESS,
  FETCH_SEQUENCES_STATS_FAILURE,

  FETCH_SEQUENCES_STATUS_REQUEST,
  FETCH_SEQUENCES_STATUS_SUCCESS,

  CREATE_SEQUENCE_REQUEST,
  CREATE_SEQUENCE_SUCCESS,
  CREATE_SEQUENCE_FAILURE,
  CREATE_SEQUENCE_RESET,

  FETCH_SEQUENCE_REPORTS_REQUEST,
  FETCH_SEQUENCE_REPORTS_SUCCESS,
  FETCH_SEQUENCE_REPORTS_FAILURE,

  FETCH_MESSAGE_SCHEDULE_REQUEST,
  FETCH_MESSAGE_SCHEDULE_SUCCESS,
  FETCH_MESSAGE_SCHEDULE_FAILURE,

  FETCH_MESSAGE_COUNTS_REQUEST,
  FETCH_MESSAGE_COUNTS_SUCCESS,
  FETCH_MESSAGE_COUNTS_FAILURE,

  FETCH_SEQUENCES_EXPORT_CSV_REQUEST,
  FETCH_SEQUENCES_EXPORT_CSV_SUCCESS,
  FETCH_SEQUENCES_EXPORT_CSV_FAILURE
} from './constants'

const initialNewSequence = {
  sequence: null,
  loading: false,
  error: null
}

const initialObject = {
  data: null,
  loading: false,
  error: null
}

// The initial state of the App
const initialState = fromJS({
  sequences: [],
  archivedSequences: [],
  archivedTotal: 0,
  sequencesStats: initialObject,
  sequencesStatus: initialObject,
  total: 0,
  loading: false,
  newSequence: initialNewSequence,
  reports: initialObject,
  schedule: initialObject,
  messageCounts: initialObject,
  sequencesCsvExport: initialObject
})

function dashboardReducer (state = initialState, action) {
  switch (action.type) {
    // Fetch Sequences Reducers
    case FETCH_SEQUENCES_REQUEST:
    case FETCH_ARCHIVED_SEQUENCES_REQUEST:
      return state.set('loading', true)
    case FETCH_SEQUENCES_SUCCESS:
      return state.set('sequences', action.payload.sequences)
        .set('total', fromJS(action.payload.sequences.length))
        .set('loading', false)
    case FETCH_SEQUENCES_FAILURE:
    case FETCH_ARCHIVED_SEQUENCES_FAILURE:
      return state.set('loading', false)

    // Fetch Sequences Reducers
    case FETCH_ARCHIVED_SEQUENCES_SUCCESS:
      return state.set('loading', false)
        .set('archivedSequences', action.payload.sequences)
        .set('archivedTotal', fromJS(action.payload.sequences.length))

    // Update Sequences
    case UPDATE_SEQUENCES_SUCCESS: {
      const sequenceIds = action.sequenceIds
      const params = action.params
      const data = state.get('sequences').map((s) => {
        const sequenceId = s.get('_id')
        let sequence = s
        if (sequenceIds.includes(sequenceId)) {
          sequence = s.merge(fromJS(params))
        }
        return sequence
      })
      return state.set('sequences', data)
    }
    case UPDATE_SEQUENCE_SUCCESS: {
      const sequenceId = action.payload.sequence.get('_id')
      const data = state.get('sequences').map((s) => {
        const sId = s.get('_id')
        let sequence = s
        if (sId === sequenceId) {
          sequence = s.merge(action.payload.sequence.delete('_user'))
        }
        return sequence
      })
      return state.set('sequences', data)
    }

    // Delete Sequences
    case DELETE_SEQUENCES_SUCCESS: {
      const sequenceIds = action.sequenceIds
      const sequences = state.get('sequences').filter((s) => {
        const sId = s.get('_id')
        return !sequenceIds.includes(sId)
      })
      return state.set('sequences', sequences)
    }

    case DELETE_SEQUENCE_SUCCESS: {
      const sequenceId = action.payload.sequenceId
      const sequences = state.get('sequences').filter((s) => {
        return sequenceId !== s.get('_id')
      })
      return state.set('sequences', sequences)
    }

    // Fetch Sequence Stats Reducers
    case FETCH_SEQUENCES_STATS_REQUEST:
      return state.set('sequencesStats', state.get('sequencesStats').merge({ loading: true, init: true }))
    case FETCH_SEQUENCES_STATS_SUCCESS: {
      const sequencesStats = state.getIn(['sequencesStats', 'data']) || Immutable.Map()
      return state.set('sequencesStats', state.get('sequencesStats').merge({
        data: sequencesStats.merge(action.payload.sequencesStats),
        loading: false
      }))
    }
    case FETCH_SEQUENCES_STATS_FAILURE:
      return state.set('sequencesStats', state.get('sequencesStats').merge({ loading: false }))

    case FETCH_SEQUENCE_STATS_SUCCESS: {
      const currentSequencesList = state.getIn(['sequencesStats', 'data'])
      // refreshing page was causing app to crash
      if (!currentSequencesList) {
        return state
      }
      const newSequenceStats = action.payload.stats
      const sequenceId = action.payload.sequenceId
      const newStats = Immutable.Map({
        _id: sequenceId,
        messaged_contacts_count: newSequenceStats.getIn(['state', 'messaged']),
        replied_contacts_count: newSequenceStats.getIn(['state', 'replied']),
        viewed_contacts_count: newSequenceStats.getIn(['state', 'viewed']),
        errored_contacts_count: newSequenceStats.getIn(['state', 'error']),
        contacts_count: newSequenceStats.getIn(['state', 'total'])
      })

      const mergedStats = currentSequencesList.get(sequenceId, Immutable.Map({})).merge(newStats)
      const newSequencesList = currentSequencesList.set(sequenceId, mergedStats)

      return state.set('sequencesStats',
        state.get('sequencesStats').merge({
          data: newSequencesList,
          loading: false
        })
      )
    }

    // Fetch Sequence Status Reducers
    case FETCH_SEQUENCES_STATUS_REQUEST: {
      return state.set('sequencesStatus', state.get('sequencesStatus').merge({
        loading: true
      }))
    }
    case FETCH_SEQUENCES_STATUS_SUCCESS: {
      const sequencesStatus = state.getIn(['sequencesStatus', 'data']) || Immutable.Map()
      return state.set('sequencesStatus', state.get('sequencesStatus').merge({
        data: sequencesStatus.merge(action.payload.sequencesStatus),
        loading: false
      }))
    }

    // Create Sequence Reducers
    case CREATE_SEQUENCE_REQUEST:
      return state.set('newSequence', { ...state.newSequence, loading: true })
    case CREATE_SEQUENCE_SUCCESS:
      return state
        .set('newSequence', {
          sequence: action.payload.sequence,
          loading: false,
          error: null
        })
        .set('sequences', state.get('sequences').push(action.payload.sequence))
    case CREATE_SEQUENCE_FAILURE:
      return state.set('newSequence', {
        sequence: null,
        loading: false,
        error: action.err
      })
    case CREATE_SEQUENCE_RESET:
      return state.set('newSequence', initialNewSequence)

    // Create Sequence Reducers
    case FETCH_SEQUENCE_REPORTS_REQUEST:
      return state.set('reports', state.get('reports').merge({ loading: true }))
    case FETCH_SEQUENCE_REPORTS_SUCCESS:
      return state.set('reports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_SEQUENCE_REPORTS_FAILURE:
      return state.set('reports', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Create Sequence Reducers
    case FETCH_MESSAGE_SCHEDULE_REQUEST:
      return state.set('schedule', state.get('schedule').merge({ loading: true }))
    case FETCH_MESSAGE_SCHEDULE_SUCCESS:
      return state.set('schedule', fromJS({
        data: action.payload.schedule,
        loading: false,
        error: null
      }))
    case FETCH_MESSAGE_SCHEDULE_FAILURE:
      return state.set('schedule', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case FETCH_MESSAGE_COUNTS_REQUEST:
      return state.set('messageCounts', state.get('messageCounts').merge({ loading: true }))
    case FETCH_MESSAGE_COUNTS_SUCCESS:
      return state.set('messageCounts', fromJS({
        data: action.payload.counts,
        loading: false,
        error: null
      }))
    case FETCH_MESSAGE_COUNTS_FAILURE:
      return state.set('messageCounts', fromJS({
        data: {},
        loading: false,
        error: action.err
      }))

    // Fetch Export CSV
    case FETCH_SEQUENCES_EXPORT_CSV_REQUEST:
      return state.set('sequencesCsvExport', state.get('sequencesCsvExport').merge({ loading: true }))
    case FETCH_SEQUENCES_EXPORT_CSV_SUCCESS:
      return state.set('sequencesCsvExport', fromJS({
        data: action.payload,
        loading: false,
        error: null
      }))
    case FETCH_SEQUENCES_EXPORT_CSV_FAILURE:
      return state.set('sequencesCsvExport', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    default:
      return state
  }
}

export default dashboardReducer
