import { fromJS } from 'immutable'
import {
  FETCH_TEAM_REPORTS_REQUEST,
  FETCH_TEAM_REPORTS_SUCCESS,
  FETCH_TEAM_REPORTS_FAILURE,

  FETCH_TEAM_REPORTS_CONTRAST_PERIOD_REQUEST,
  FETCH_TEAM_REPORTS_CONTRAST_PERIOD_SUCCESS,
  FETCH_TEAM_REPORTS_CONTRAST_PERIOD_FAILURE,

  FETCH_USER_REPORTS_REQUEST,
  FETCH_USER_REPORTS_SUCCESS,
  FETCH_USER_REPORTS_FAILURE,

  FETCH_SEQUENCE_REPORTS_REQUEST,
  FETCH_SEQUENCE_REPORTS_SUCCESS,
  FETCH_SEQUENCE_REPORTS_FAILURE,

  FETCH_MY_REPORTS_REQUEST,
  FETCH_MY_REPORTS_SUCCESS,
  FETCH_MY_REPORTS_FAILURE,

  FETCH_BOOKED_REPORTS_REQUEST,
  FETCH_BOOKED_REPORTS_SUCCESS,
  FETCH_BOOKED_REPORTS_FAILURE,

  FETCH_REPLIED_REPORTS_REQUEST,
  FETCH_REPLIED_REPORTS_SUCCESS,
  FETCH_REPLIED_REPORTS_FAILURE,

  FETCH_CONTACTS_REPORTS_REQUEST,
  FETCH_CONTACTS_REPORTS_SUCCESS,
  FETCH_CONTACTS_REPORTS_FAILURE,

  FETCH_MESSAGED_REPORTS_REQUEST,
  FETCH_MESSAGED_REPORTS_SUCCESS,
  FETCH_MESSAGED_REPORTS_FAILURE,

  EXPORT_REPORT_CSV_REQUEST,
  EXPORT_REPORT_CSV_SUCCESS,
  EXPORT_REPORT_CSV_FAILURE,

  FETCH_BOUNCE_RATE_REPORT_REQUEST,
  FETCH_BOUNCE_RATE_REPORT_SUCCESS,
  FETCH_BOUNCE_RATE_REPORT_FAILURE,

  FETCH_OPEN_HOURS_REQUEST,
  FETCH_OPEN_HOURS_SUCCESS,
  FETCH_OPEN_HOURS_FAILURE,

  FETCH_REPLY_HOURS_REQUEST,
  FETCH_REPLY_HOURS_SUCCESS,
  FETCH_REPLY_HOURS_FAILURE,

  FETCH_CONTACT_TASK_REPORTS_REQUEST,
  FETCH_CONTACT_TASK_REPORTS_SUCCESS,
  FETCH_CONTACT_TASK_REPORTS_FAILURE,

  FETCH_LEADERBOARD_REQUEST,
  FETCH_LEADERBOARD_SUCCESS,
  FETCH_LEADERBOARD_FAILURE,

  FETCH_LEADERBOARD_CONTRAST_REQUEST,
  FETCH_LEADERBOARD_CONTRAST_SUCCESS,
  FETCH_LEADERBOARD_CONTRAST_FAILURE,

  FETCH_LEADERBOARD_GROUPS_REQUEST,
  FETCH_LEADERBOARD_GROUPS_SUCCESS,
  FETCH_LEADERBOARD_GROUPS_FAILURE,

  FETCH_LEADERBOARD_GROUPS_CONTRAST_REQUEST,
  FETCH_LEADERBOARD_GROUPS_CONTRAST_SUCCESS,
  FETCH_LEADERBOARD_GROUPS_CONTRAST_FAILURE,

  FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST,
  FETCH_PERSONALIZED_MESSAGES_REPORTS_SUCCESS,
  FETCH_PERSONALIZED_MESSAGES_REPORTS_FAILURE,

  FETCH_STEP_PERFORMANCE_REQUEST,
  FETCH_STEP_PERFORMANCE_SUCCESS,
  FETCH_STEP_PERFORMANCE_FAILURE,

  FETCH_SEQUENCES_REPORTS_REQUEST,
  FETCH_SEQUENCES_REPORTS_SUCCESS,
  FETCH_SEQUENCES_REPORTS_FAILURE,

  FETCH_BOUNCE_REASON_REQUEST,
  FETCH_BOUNCE_REASON_SUCCESS,
  FETCH_BOUNCE_REASON_FAILURE
} from './constants'

const initialArray = {
  loading: false,
  data: [],
  error: null
}

const initialObject = {
  loading: false,
  data: null,
  error: null
}

const initialState = fromJS({
  reports: initialArray,
  contrastPeriodReports: initialArray,
  userReports: initialArray,
  sequenceReports: initialArray,
  myReports: initialArray,
  bookedReports: initialArray,
  repliedReports: initialArray,
  contactsCreatedReports: initialArray,
  messagedReports: initialArray,
  bounceRateReports: initialArray,
  exportedReport: initialArray,
  replyHoursReports: initialArray,
  openHoursReports: initialArray,
  contactTaskReports: initialArray,
  leaderboard: initialArray,
  leaderboardContrast: initialArray,
  leaderboardGroups: initialArray,
  leaderboardGroupsContrast: initialArray,
  personalizedMessagesReports: initialArray,
  stepPerformanceReport: initialObject,
  sequencesReports: initialObject,
  bounceReasonReports: initialObject
})

function reportsReducer (state = initialState, action) {
  switch (action.type) {
    // reports
    case FETCH_TEAM_REPORTS_REQUEST:
      return state.set('reports', state.get('reports').merge({ loading: true }))
    case FETCH_TEAM_REPORTS_SUCCESS:
      return state.set('reports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_TEAM_REPORTS_FAILURE:
      return state.set('reports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST:
      return state.set('personalizedMessagesReports', state.get('personalizedMessagesReports').merge({ loading: true }))
    case FETCH_PERSONALIZED_MESSAGES_REPORTS_SUCCESS:
      return state.set('personalizedMessagesReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_PERSONALIZED_MESSAGES_REPORTS_FAILURE:
      return state.set('personalizedMessagesReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_LEADERBOARD_REQUEST:
      return state.set('leaderboard', state.get('leaderboard').merge({ loading: true }))
    case FETCH_LEADERBOARD_SUCCESS:
      return state.set('leaderboard', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_LEADERBOARD_FAILURE:
      return state.set('leaderboard', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_LEADERBOARD_CONTRAST_REQUEST:
      return state.set('leaderboardContrast', state.get('leaderboardContrast').merge({ loading: true }))
    case FETCH_LEADERBOARD_CONTRAST_SUCCESS:
      return state.set('leaderboardContrast', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_LEADERBOARD_CONTRAST_FAILURE:
      return state.set('leaderboardContrast', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_LEADERBOARD_GROUPS_REQUEST:
      return state.set('leaderboardGroups', state.get('leaderboardGroups').merge({ loading: true }))
    case FETCH_LEADERBOARD_GROUPS_SUCCESS:
      return state.set('leaderboardGroups', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_LEADERBOARD_GROUPS_FAILURE:
      return state.set('leaderboardGroups', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_LEADERBOARD_GROUPS_CONTRAST_REQUEST:
      return state.set('leaderboardGroupsContrast', state.get('leaderboardContrast').merge({ loading: true }))
    case FETCH_LEADERBOARD_GROUPS_CONTRAST_SUCCESS:
      return state.set('leaderboardGroupsContrast', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_LEADERBOARD_GROUPS_CONTRAST_FAILURE:
      return state.set('leaderboardGroupsContrast', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_TEAM_REPORTS_CONTRAST_PERIOD_REQUEST:
      return state.set('contrastPeriodReports', state.get('contrastPeriodReports').merge({ loading: true }))
    case FETCH_TEAM_REPORTS_CONTRAST_PERIOD_SUCCESS:
      return state.set('contrastPeriodReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_TEAM_REPORTS_CONTRAST_PERIOD_FAILURE:
      return state.set('contrastPeriodReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_USER_REPORTS_REQUEST:
      return state.set('userReports', state.get('userReports').merge({ loading: true }))
    case FETCH_USER_REPORTS_SUCCESS:
      return state.set('userReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_USER_REPORTS_FAILURE:
      return state.set('userReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_SEQUENCE_REPORTS_REQUEST:
      return state.set('sequenceReports', state.get('sequenceReports').merge({ loading: true }))
    case FETCH_SEQUENCE_REPORTS_SUCCESS:
      return state.set('sequenceReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_SEQUENCE_REPORTS_FAILURE:
      return state.set('sequenceReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_MY_REPORTS_REQUEST:
      return state.set('myReports', state.get('myReports').merge({ loading: true }))
    case FETCH_MY_REPORTS_SUCCESS:
      return state.set('myReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_MY_REPORTS_FAILURE:
      return state.set('myReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_BOOKED_REPORTS_REQUEST:
      return state.set('bookedReports', state.get('bookedReports').merge({ loading: true }))
    case FETCH_BOOKED_REPORTS_SUCCESS:
      return state.set('bookedReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_BOOKED_REPORTS_FAILURE:
      return state.set('bookedReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_REPLIED_REPORTS_REQUEST:
      return state.set('repliedReports', state.get('repliedReports').merge({ loading: true }))
    case FETCH_REPLIED_REPORTS_SUCCESS:
      return state.set('repliedReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_REPLIED_REPORTS_FAILURE:
      return state.set('repliedReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_CONTACTS_REPORTS_REQUEST:
      return state.set('contactsCreatedReports', state.get('contactsCreatedReports').merge({ loading: true }))
    case FETCH_CONTACTS_REPORTS_SUCCESS:
      return state.set('contactsCreatedReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_CONTACTS_REPORTS_FAILURE:
      return state.set('contactsCreatedReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_MESSAGED_REPORTS_REQUEST:
      return state.set('messagedReports', state.get('messagedReports').merge({ loading: true }))
    case FETCH_MESSAGED_REPORTS_SUCCESS:
      return state.set('messagedReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_MESSAGED_REPORTS_FAILURE:
      return state.set('messagedReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case EXPORT_REPORT_CSV_REQUEST:
      return state.set('exportedReport', state.get('exportedReport').merge({ loading: true }))
    case EXPORT_REPORT_CSV_SUCCESS:
      return state.set('exportedReport', fromJS({
        data: action.payload,
        loading: false,
        error: null
      }))
    case EXPORT_REPORT_CSV_FAILURE:
      return state.set('exportedReport', fromJS({
        loading: false,
        error: action.err
      }))

    case FETCH_BOUNCE_RATE_REPORT_REQUEST:
      return state.set('bounceRateReports', state.get('bounceRateReports').merge({ loading: true }))
    case FETCH_BOUNCE_RATE_REPORT_SUCCESS:
      return state.set('bounceRateReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_BOUNCE_RATE_REPORT_FAILURE:
      return state.set('bounceRateReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_REPLY_HOURS_REQUEST:
      return state.set('replyHoursReports', state.get('replyHoursReports').merge({ loading: true }))
    case FETCH_REPLY_HOURS_SUCCESS:
      return state.set('replyHoursReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_REPLY_HOURS_FAILURE:
      return state.set('replyHoursReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_OPEN_HOURS_REQUEST:
      return state.set('openHoursReports', state.get('openHoursReports').merge({ loading: true }))
    case FETCH_OPEN_HOURS_SUCCESS:
      return state.set('openHoursReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_OPEN_HOURS_FAILURE:
      return state.set('openHoursReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_CONTACT_TASK_REPORTS_REQUEST:
      return state.set('contactTaskReports', state.get('contactTaskReports').merge({ loading: true }))
    case FETCH_CONTACT_TASK_REPORTS_SUCCESS:
      return state.set('contactTaskReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_TASK_REPORTS_FAILURE:
      return state.set('contactTaskReports', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_STEP_PERFORMANCE_REQUEST:
      return state.set('stepPerformanceReport', state.get('stepPerformanceReport').merge({ loading: true }))
    case FETCH_STEP_PERFORMANCE_SUCCESS:
      return state.set('stepPerformanceReport', fromJS({
        data: action.payload.report,
        loading: false,
        error: null
      }))
    case FETCH_STEP_PERFORMANCE_FAILURE:
      return state.set('stepPerformanceReport', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case FETCH_SEQUENCES_REPORTS_REQUEST:
      return state.set('sequencesReports', state.get('sequencesReports').merge({ loading: true }))
    case FETCH_SEQUENCES_REPORTS_SUCCESS:
      return state.set('sequencesReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_SEQUENCES_REPORTS_FAILURE:
      return state.set('sequencesReports', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case FETCH_BOUNCE_REASON_REQUEST:
      return state.set('bounceReasonReports', state.get('bounceReasonReports').merge({ loading: true }))
    case FETCH_BOUNCE_REASON_SUCCESS:
      return state.set('bounceReasonReports', fromJS({
        data: action.payload.reports,
        loading: false,
        error: null
      }))
    case FETCH_BOUNCE_REASON_FAILURE:
      return state.set('bounceReasonReports', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    default:
      return state
  }
}
export default reportsReducer
