import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'
import EmptyState from 'components/EmptyState'
import { Satellite } from 'svg'
import ActivityItem from './ActivityItem'
import MessageItem from './MessageItem'

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  width: 100%;
  background-color: ${props => props.theme.colors.white};
  padding-bottom: 8rem;
`

const ActivityList = styled.ul`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  list-style-type: none;
  line-height: 1.4em;
  padding: 1rem 0 2rem 0;
  margin: 0;

  >:last-child div {
    border-left: none!important;
  }
`

class ContactActivity extends PureComponent {
  render () {
    const {
      sequence,
      contact,
      contactActivity,
      contactSteps,
      router,
      onPreviewReply,
      isArchived
    } = this.props

    if (
      !contactSteps.get('loading') &&
      !contactSteps.get('data').count &&
      (!contactActivity || contactActivity.count() < 1)
    ) {
      return (
        <EmptyState
          icon={<Satellite />}
          title='No Activity Yet'
          description='Add steps or launch your sequence to see past and future activities'
          p='3rem'
        />
      )
    }

    const messageActivityItems = contactSteps
      .get('data')
      .filter(step => !step.get('fulfilled'))
      .filter(step => !isArchived && step.get('scheduled_for'))
      .reverse()
      .map((step, i) => (
        <MessageItem
          contact={contact}
          sequence={sequence}
          step={step}
          key={`step_${step.get(['_step']) || i}`}
          data-index={i}
          onClick={() => {
            router.push(`/sequence/${sequence.get('_id')}/preview?contactId=${contact.get('_id')}&step=${step.get('step')}&customize=true`)
          }}
        />
      ))

    const activityItems = contactActivity.map((activity, i) => {
      const aggregate = activity.get('aggregate')
      const key = aggregate ? `activity_${activity.get('start_at')}` : `activity_${activity.get('_id') || i}`

      return (
        <ActivityItem
          activity={activity}
          router={router}
          key={key}
          data-index={i}
          onClick={() => {
            const verb = activity.get('verb')
            const step = Immutable
              .List([
                activity.getIn(['_message', 'step']),
                activity.getIn(['_task', 'step'])
              ])
              .filter(step => {
                return step !== undefined && step !== null
              })
              .first()

            if (verb === 'replied' || (step !== undefined && step !== null)) {
              onPreviewReply(activity)
            }
          }}
        />
      )
    })

    return (
      <Wrapper>
        <ActivityList>
          {sequence.get('active') && contact.get('state') !== 'error' && messageActivityItems}
          {activityItems}
        </ActivityList>
      </Wrapper>
    )
  }
}

ContactActivity.propTypes = {
  sequence: PropTypes.object,
  contact: PropTypes.object,
  contactActivity: PropTypes.instanceOf(Immutable.List),
  router: PropTypes.object,
  onPreviewReply: PropTypes.func,
  isArchived: PropTypes.bool,
  contactSteps: PropTypes.object
}

ContactActivity.defaultProps = {
  contactActivity: Immutable.List([])
}

export default withRouter(ContactActivity)
