import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Html from 'slate-html-serializer'
import { Tooltip } from 'react-tippy'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { fadeIn } from 'utils/animations'

import Label from 'elements/Label'
import Input from 'components/Input'
import { Curtain } from '../ToolbarModal'

import {
  slateConstants,
  slateParsingRules,
  slateHelpers
} from 'components/SlateEditor/utils/slate/'

const EditWrapper = styled.div`
  padding: 1rem 1rem;
  background-color: ${props => props.theme.colors.white};

  position: absolute;
  top: ${props => props.yPos ? `calc(${props.yPos}px + 35px)` : '-10000px'};
  left: ${props => props.xPos ? `${props.xPos}px` : '-10000px'};

  ${props => (props.xDir === 'right')
    ? 'transform: translateX(-382px)'
    : 'auto'
  };

  ${props => (props.yDir === 'bottom')
    ? 'transform: translateY(calc(-90px - 45px))'
    : 'auto'
  };

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  box-shadow: ${props => props.theme.shadow};
  animation: ${fadeIn} .2s linear 1;
  z-index: 10;
  border-radius: 3px;
`

const PreviewWrapper = styled.div`
  animation: ${fadeIn} .2s linear 1;
  position: absolute;

  left: ${props => (props.xPos) ? `${props.xPos}px` : '-10000px'};
  top: ${props => (props.yPos)
      ? (props.isImageSelected)
        ? `${props.yPos}px`
        : `calc(${props.yPos}px + 30px)`
      : '-10000px'};

  ${props => (props.xDir === 'right')
    ? 'transform: translateX(-321px)'
    : 'auto'
  };

  ${props => (props.yDir === 'bottom')
    ? 'transform: translateY(calc(-57.88px - 35px))'
    : 'auto'
  };

  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.colors.gray30};
  border-radius: 3px;

  box-shadow: ${props => props.theme.shadow};
  z-index: 10;

  padding: 0.5rem 0.5rem 0.55rem 0.75rem;
  width: 321px;
`
const InputContainer = styled.div`
  width: 250px;
`

const InputRowBottom = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const InvalidLabel = styled(Label)`
  color: ${props => props.theme.colors.red};
  font: ${props => props.theme.fonts.normal};
  text-align: center;
  padding-top: 0;
`

const ApplyButton = styled.div`
  padding: 0 .65rem;
  border-radius: 100px;
  background-color: ${props => props.theme.colors.blue};
  color: ${props => props.theme.colors.white};
  text-transform: uppercase;
  text-align: center;
  letter-spacing: 0.05em;
  position: relative;
  cursor: pointer;
  font: ${props => props.theme.fonts.button};

  height: 46px;
  width: 84px;
  line-height: 44px;

  margin-top: 17.27px;
  margin-left: 1rem;

  &:hover {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: .8;
    transition: opacity .15s ease-out;
  }
`

const LeftSubMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-left: 0.25rem;
  width: calc(100% - 66px);
  flex: 1;
`

const RightSubMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const PreviewMenu = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
  flex: 1;
`

const MenuItem = styled.div`
  padding: 0.2rem .5rem .3rem .5rem;
  position: relative;
  display: inline-block;
  border-radius: 9px;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.gray20};
  }
`

const MenuIcon = styled.div`
  padding: 0;
  margin: 0;

  font-size: 18px;
`

const Href = styled.a`
  color: ${props => props.theme.colors.blue};

  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;

  margin-right: 0.5rem;
`

const HrefVariable = styled.p`
  color: ${props => props.theme.colors.blue};
  background-color: ${props => props.theme.colors.gray20};
  padding: 0px 2px;
  margin: 0;

  display: block;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
`

const LinkMenuPadding = styled.div`
  padding-left: .75rem;
`

class LinkMenu extends React.Component {
  constructor (props) {
    super(props)

    this.state = {
      isLinkValid: true,
      linkUrl: '',

      // the edit menu needs its coordinates stored in state
      // because the edit menu would otherwise move as the user types
      editMenuXPos: -10000,
      editMenuYPos: -10000
    }

    this.handleOnEnterKey = this.handleOnEnterKey.bind(this)
    this.handleOnEscapeKey = this.handleOnEscapeKey.bind(this)

    this.linkEdit = React.createRef()
    this.linkPreview = React.createRef()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.linkMenuType === slateConstants.LINK_MENUS.EDIT) {
      const { editorRef } = nextProps

      const coordinates = editorRef.current.getCursorCoordinates()
      if (!coordinates) {
        return
      }

      const { x, y } = coordinates

      if (x === this.state.editMenuXPos && y === this.state.editMenuYPos) {
        return
      }

      this.setState({
        editMenuXPos: x,
        editMenuYPos: y
      })
    }
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleOnEscapeKey, false)
    window.addEventListener('keydown', this.handleOnEnterKey, false)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleOnEscapeKey, false)
    window.removeEventListener('keydown', this.handleOnEnterKey, false)
  }

  _calculateDirection = (x, y) => {
    const pos = {
      xDir: 'right',
      yDir: 'top'
    }

    const doc = document
    const docElem = doc.documentElement
    const body = doc.getElementsByTagName('body')[0]

    const LINK_MENU_WIDTH = 382
    const LINK_MENU_HEIGHT = 177.56
    const SCREEN_WIDTH = window.innerWidth || docElem.clientWidth || body.clientWidth
    const SCREEN_HEIGHT = window.innerHeight || docElem.clientHeight || body.clientHeight
    const SCROLL_LEFT_OFFSET = window.pageXOffset
    const SCROLL_TOP_OFFSET = window.pageYOffset

    if ((LINK_MENU_WIDTH + x - SCROLL_LEFT_OFFSET) > SCREEN_WIDTH) {
      pos.xDir = 'right'
    } else {
      pos.xDir = 'left'
    }

    if ((LINK_MENU_HEIGHT + y - SCROLL_TOP_OFFSET) > SCREEN_HEIGHT) {
      pos.yDir = 'bottom'
    } else {
      pos.yDir = 'top'
    }

    return pos
  }

  handleOnEscapeKey = event => {
    const ESCAPE_KEY = 27
    if (event.keyCode === ESCAPE_KEY) {
      const { setToolbarMenuVisibility } = this.props
      setToolbarMenuVisibility('showLinkMenu', slateConstants.LINK_MENUS.NONE)
    }
  }

  handleClickOutside = event => {
    event.preventDefault()
    const { setToolbarMenuVisibility } = this.props
    setToolbarMenuVisibility('showLinkMenu', slateConstants.LINK_MENUS.NONE)
  }

  handleOnEnterKey = event => {
    const ENTER_KEY = 13
    if (event.keyCode === ENTER_KEY) {
      this.handleApplyLink()
    }
  }

  handleApplyLink = () => {
    const { editorRef, setToolbarMenuVisibility } = this.props
    let { linkUrl } = this.state
    linkUrl = linkUrl.trim()

    const applyLink = (url) => {
      Promise
        .resolve()
        .then(() => {
          if (editorRef.current.isHighlightingActive()) {
            editorRef.current.wrapLink(url)
          } else {
            const linkHtml = `<a href=${url}>${url}</a>`
            const serializer = new Html({ rules: slateParsingRules.bodyRules() })
            const { document } = serializer.deserialize(linkHtml)
            editorRef.current.insertFragment(document)
          }
        })
        .then(() => this.setState({ isLinkValid: true }))
        .then(() => setToolbarMenuVisibility('showLinkMenu', slateConstants.LINK_MENUS.PREVIEW))
    }

    const repairedUrl = `https://${linkUrl}`
    if (slateHelpers.isValidUrl(linkUrl)) {
      applyLink(linkUrl)
    } else if (slateHelpers.isValidUrl(repairedUrl)) {
      applyLink(repairedUrl)
    } else {
      this.setState({ isLinkValid: false })
    }
  }

  renderPreviewMenu = () => {
    const {
      editorRef,
      setToolbarMenuVisibility
    } = this.props

    let xPos = -10000
    let yPos = -10000
    const coordinates = editorRef.current.getCursorCoordinates()
    if (coordinates) {
      xPos = coordinates.x
      yPos = coordinates.y
    }

    const { xDir, yDir } = this._calculateDirection(xPos, yPos)
    const activeUrls = editorRef.current.getActiveLinks()

    if (activeUrls.length > 1) {
      return null
    }

    return (
      <PreviewWrapper
        xPos={xPos}
        yPos={yPos}
        xDir={xDir}
        yDir={yDir}
      >
        <PreviewMenu>
          <LeftSubMenu>
            <MenuIcon>
              <FontAwesomeIcon
                icon={['fas', 'globe-americas']}
                color='#243E6D'
              />
            </MenuIcon>
            <LinkMenuPadding />
            {editorRef.current.isUnsubscribeLinkActive && editorRef.current.isUnsubscribeLinkActive()
              ? <HrefVariable href={activeUrls} tag={activeUrls} target='_blank'>{activeUrls}</HrefVariable>
              : <Href href={activeUrls} tag={activeUrls} target='_blank'>{activeUrls}</Href>}
          </LeftSubMenu>

          <RightSubMenu>
            {editorRef.current.isUnsubscribeLinkActive &&
            !editorRef.current.isUnsubscribeLinkActive() &&
              <Tooltip
                title='Edit Link'
                position='top'
                trigger='mouseenter'
                arrow
              >
                <MenuItem
                  onClick={() => {
                    Promise
                      .resolve()
                      .then(() => editorRef.current.unwrapLink())
                      .then(() => setToolbarMenuVisibility('showLinkMenu', slateConstants.LINK_MENUS.EDIT))
                  }}
                >
                  <FontAwesomeIcon
                    icon={['fal', 'pen']}
                    color='#243E6D'
                  />
                </MenuItem>
              </Tooltip>}

            <Tooltip
              title='Remove Link'
              position='top'
              trigger='mouseenter'
              arrow
            >
              <MenuItem
                onClick={() => {
                  Promise
                    .resolve()
                    .then(() => editorRef.current.unwrapLink())
                    .then(() => setToolbarMenuVisibility('showLinkMenu', slateConstants.LINK_MENUS.NONE))
                }}
              >
                <FontAwesomeIcon
                  icon={['fal', 'unlink']}
                  color='#243E6D'
                />
              </MenuItem>
            </Tooltip>
          </RightSubMenu>
        </PreviewMenu>
      </PreviewWrapper>
    )
  }

  renderEditMenu = () => {
    const {
      isLinkValid,
      linkUrl,
      editMenuXPos,
      editMenuYPos
    } = this.state

    const { xDir, yDir } = this._calculateDirection()
    return (
      <EditWrapper
        xPos={editMenuXPos}
        yPos={editMenuYPos}
        xDir={xDir}
        yDir={yDir}
        ref={this.linkMenu}
      >
        {!isLinkValid &&
          <InvalidLabel mt='0.5rem' mb='0.5rem'>
            The URL your provided is invalid
          </InvalidLabel>}

        <InputRowBottom>
          <InputContainer>
            <Input
              id='add_link_url'
              label='Url'
              type='text'
              small
              value={linkUrl}
              onValueChange={(value) => {
                this.setState({ linkUrl: value })
              }}
              mr='1rem'
            />
          </InputContainer>
          <ApplyButton onClick={this.handleApplyLink}>
            Apply
          </ApplyButton>
        </InputRowBottom>
      </EditWrapper>
    )
  }

  render () {
    const {
      linkMenuType,
      editorRef
    } = this.props

    return (
      <>
        <Curtain
          onClick={this.handleClickOutside}
          visible={false}
        />

        {linkMenuType === slateConstants.LINK_MENUS.EDIT && this.renderEditMenu()}
        {linkMenuType === slateConstants.LINK_MENUS.PREVIEW &&
          // NOTE: this check is needed because `isLinkActive` doesn't
          // exist until our slate plugin is binded to `editorRef`
          editorRef.current.isLinkActive &&
          editorRef.current.isLinkActive() &&
          this.renderPreviewMenu()}
      </>
    )
  }
}

LinkMenu.propTypes = {
  linkMenuType: PropTypes.string,
  setToolbarMenuVisibility: PropTypes.func,
  editorRef: PropTypes.object,
  activeEditorName: PropTypes.string
}

export default LinkMenu
