import Immutable, { fromJS } from 'immutable'
import R from 'utils/ramda'
import { put, takeLatest, call, delay } from 'redux-saga/effects'
import api, { fileApi, messageFromError } from 'utils/api'
import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'
import { ordinal, pluralize } from 'utils/strings'

import {
  fetchSequenceSuccess,
  fetchSequenceFailure,
  deleteSequenceSuccess,
  deleteSequenceFailure,
  fetchContactsSuccess,
  fetchContactsFailure,
  updateContactSuccess,
  updateContactFailure,
  fetchSequenceStatsSuccess,
  fetchSequenceStatsFailure,
  fetchStepStatsSuccess,
  fetchStepStatsFailure,
  fetchExportCsvSuccess,
  fetchExportCsvFailure,
  fetchExportPdfSuccess,
  fetchExportPdfFailure,
  fetchContactSuccess,
  fetchContactFailure,
  repliedContactSuccess,
  repliedContactFailure,
  unsubscribeContactSuccess,
  unsubscribeContactFailure,
  blockContactSuccess,
  blockContactFailure,
  fetchContactActionsSuccess,
  fetchContactActionsFailure,
  fetchContactIntegrationSuccess,
  fetchContactIntegrationFailure,
  fetchContactMessagesSuccess,
  fetchContactMessagesFailure,
  reportContactInaccuracySuccess,
  reportContactInaccuracyFailure,
  createContactSuccess,
  createContactFailure,
  fetchSequencePreviewSuccess,
  fetchSequencePreviewFailure,
  updateSequenceSuccess,
  updateSequenceFailure,
  restoreSequenceSuccess,
  restoreSequenceFailure,
  removeSequenceContacts,
  updateContactsSuccess,
  createCsvSuccess,
  createCsvFailure,
  updateCsvSuccess,
  updateCsvFailure,
  importCsvSuccess,
  importCsvFailure,
  fetchSequenceStateSuccess,
  fetchSequenceStateFailure,
  addCustomFieldSuccess,
  addCustomFieldFailure,
  updateContactStepSuccess,
  updateContactStepFailure,
  removeContactStepSuccess,
  removeContactStepFailure,
  fetchTemplatesSuccess,
  fetchTemplatesFailure,
  createTemplateSuccess,
  createTemplateFailure,
  deleteTemplateSuccess,
  deleteTemplateFailure,
  fetchContactCompanySuccess,
  fetchContactCompanyFailure,
  fetchLookupStatusSuccess,
  fetchLookupStatusFailure,
  fetchPreviewContactsSuccess,
  fetchPreviewContactsFailure,
  createFileSuccess,
  createFileFailure,
  cloneContactSuccess,
  cloneContactFailure,
  fetchSimilarContactsSuccess,
  fetchSimilarContactsFailure,
  fetchCrmSuccess,
  fetchCrmFailure,
  fetchContactReplySuccess,
  fetchContactReplyFailure,
  transferSequenceSuccess,
  transferSequenceFailure,
  fetchSentMessagesSuccess,
  fetchSentMessagesFailure,
  fetchContactErrorsSuccess,
  fetchContactErrorsFailure,
  updateContactErrorsSuccess,
  updateContactErrorsFailure,
  fetchSelectedContactCountSuccess,
  fetchSelectedContactCountFailure,
  fetchOgpSuccess,
  fetchOgpFailure,
  fetchAddressesSuccess,
  fetchAddressesFailure,
  fetchContactStepsSuccess,
  fetchContactStepsFailure,
  fetchContactPhoneSuccess,
  fetchContactPhoneFailure,
  importCrmContactsSuccess,
  importCrmContactsFailure,
  showWarningModal,
  resyncToCrmSuccess,
  resyncToCrmFailure,
  phoneNumberLookupsSuccess,
  phoneNumberLookupsFailure,
  refreshSequenceState,
  fetchSequenceSignatureSuccess,
  fetchSequenceSignatureFailure
} from './actions'
import {
  FETCH_SEQUENCE_REQUEST,
  DELETE_SEQUENCE_REQUEST,
  FETCH_SEQUENCE_STATE_REQUEST,
  FETCH_CONTACTS_REQUEST,
  FETCH_SEQUENCE_STATS_REQUEST,
  FETCH_STEP_STATS_REQUEST,
  FETCH_EXPORT_CSV_REQUEST,
  FETCH_EXPORT_PDF_REQUEST,
  FETCH_CONTACT_REQUEST,
  UPDATE_CONTACT_REQUEST,
  REPLIED_CONTACT_REQUEST,
  FETCH_CONTACT_ACTIONS_REQUEST,
  FETCH_CONTACT_INTEGRATION_REQUEST,
  FETCH_CONTACT_MESSAGES_REQUEST,
  REPORT_CONTACT_INACCURACY_REQUEST,
  CREATE_CONTACT_REQUEST,
  FETCH_SEQUENCE_PREVIEW_REQUEST,
  UPDATE_SEQUENCE_REQUEST,
  RESTORE_SEQUENCE_REQUEST,
  SEND_TEST_EMAIL_REQUEST,
  DELETE_CONTACTS_REQUEST,
  MOVE_CONTACTS_REQUEST,
  UPDATE_CONTACTS_REQUEST,
  TOP_LEVEL_FIELD_VALUES,
  CREATE_CSV_REQUEST,
  UPDATE_CSV_REQUEST,
  IMPORT_CSV_REQUEST,
  UPDATE_CUSTOM_FIELDS_REQUEST,
  UPDATE_CONTACT_STEP_REQUEST,
  REMOVE_CONTACT_STEP_REQUEST,
  CREATE_TEMPLATE_REQUEST,
  FETCH_TEMPLATES_REQUEST,
  DELETE_TEMPLATE_REQUEST,
  FETCH_CONTACT_COMPANY_REQUEST,
  UNSUBSCRIBE_CONTACT_REQUEST,
  BLOCK_CONTACT_REQUEST,
  FETCH_LOOKUP_STATUS_REQUEST,
  FETCH_PREVIEW_CONTACTS_REQUEST,
  FORCE_SEND_STEP_REQUEST,
  CREATE_FILE_REQUEST,
  COPY_CONTACTS_REQUEST,
  CLONE_CONTACT_REQUEST,
  FETCH_SIMILAR_CONTACTS_REQUEST,
  FETCH_CRM_REQUEST,
  REFRESH_CRM_REQUEST,
  FETCH_CONTACT_REPLY_REQUEST,
  TRANSFER_SEQUENCE_REQUEST,
  FETCH_SENT_MESSAGES_REQUEST,
  FETCH_CONTACT_ERRORS_REQUEST,
  UPDATE_CONTACT_ERRORS_REQUEST,
  FETCH_SELECTED_CONTACT_COUNT_REQUEST,
  FETCH_OGP_REQUEST,
  FETCH_ADDRESSES_REQUEST,
  FETCH_CONTACT_STEPS_REQUEST,
  FETCH_CONTACT_PHONE_REQUEST,
  IMPORT_CRM_CONTACTS_REQUEST,
  RESYNC_TO_CRM_REQUEST,
  PHONE_NUMBER_LOOKUPS_REQUEST,
  FETCH_SEQUENCE_SIGNATURE_REQUEST
} from './constants'
import { fetchManualTasks } from '../Tasks/actions'

const get = (url, query) => api.get(url, {
  params: query
})

const post = (url, params) => api.post(url, params)
const postFile = (url, params) => fileApi.post(url, params)

const update = (url, params) => api.put(url, params)
const remove = url => api.delete(url)

const BATCH_SIZE = 2000

export function * fetchSequence (action) {
  let sequence = {}

  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}`
  const params = {
    populate: ['user']
  }
  try {
    const response = yield call(get, path, params)
    sequence = fromJS(response.data)

    yield put(fetchSequenceSuccess({
      sequence
    }))
  } catch (error) {
    yield put(fetchSequenceFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContacts (action) {
  let contacts = []
  let query = {}

  // Build query
  if (!R.isEmpty(action.query)) {
    query = action.query

    // backend calls it query, FE calls it search
    if (query.search) {
      query.query = query.search
      delete query.search
    }
  } else {
    query = { limit: 50 }
  }
  const paginating = query.skip > 0
  const limit = query.limit || 50
  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}/contacts`
  try {
    const response = yield call(get, path, query)
    contacts = fromJS(response.data)
    const hasMore = contacts.count() === limit
    yield put(fetchContactsSuccess({
      contacts,
      paginating,
      hasMore
    }))
  } catch (error) {
    yield put(fetchContactsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * deleteSequence (action) {
  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}`
  try {
    const response = yield call(remove, path)
    const sequence = fromJS(response.data)
    yield put(deleteSequenceSuccess(sequence))
  } catch (error) {
    yield put(deleteSequenceFailure(error))
  }
}

export function * fetchSequenceStats (action) {
  let stats = {}

  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}/stats`
  try {
    const response = yield call(get, path)
    stats = fromJS(response.data)

    yield put(fetchSequenceStatsSuccess({ stats, sequenceId }))
  } catch (error) {
    console.log(error)
    yield put(fetchSequenceStatsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchSequenceState (action) {
  let state = {}

  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}/state`
  try {
    const response = yield call(get, path)
    state = fromJS(response.data)

    yield put(fetchSequenceStateSuccess({
      sequenceId,
      state
    }))
  } catch (error) {
    yield put(fetchSequenceStateFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchStepStats (action) {
  let stepStats = {}

  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}/stats/steps`
  try {
    const response = yield call(get, path)
    stepStats = fromJS(response.data)

    yield put(fetchStepStatsSuccess({
      stepStats
    }))
  } catch (error) {
    yield put(fetchStepStatsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchExportCsvLink (action) {
  let query = {}

  // Build query
  if (!R.isEmpty(action.query)) {
    query = action.query
  }

  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}/contacts/csv`
  try {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.LOADING,
      message: 'Exporting your contacts...'
    }))

    const response = yield call(api.post, path, query, { timeout: 120000 })
    const type = fromJS(response.data.type)
    const csvDownloadLink = fromJS(response.data.url)

    yield put(fetchExportCsvSuccess({
      csvDownloadLink
    }))
    let message
    if (type === 'email') {
      message = 'Check your email in a few minutes for a link to download the export'
    } else {
      message = 'Exported CSV of contacts'
    }
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (error) {
    yield put(fetchExportCsvFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchExportPdf (action) {
  const {
    sequenceId,
    contactIds,
    all,
    filter,
    search,
    showContactInfo
  } = action

  try {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.LOADING,
      message: 'Exporting your contacts...'
    }))

    const ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)

    const query = {
      contactIds: ids,
      showContactInfo
    }

    const path = '/contacts/pdf'
    const response = yield call(api.post, path, query, { timeout: 120000 })
    const type = fromJS(response.data.type)
    const downloadLink = fromJS(response.data.url)

    yield put(fetchExportPdfSuccess({
      type,
      downloadLink
    }))

    let message
    if (type === 'email') {
      message = 'Check your email in a few minutes for your PDF export'
    } else {
      message = 'Exported PDF of contacts'
    }
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (error) {
    yield put(fetchExportPdfFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContact (action) {
  const {
    query
  } = action
  let q = {}

  // Build query
  if (!R.isEmpty(query)) {
    q = query
  }

  const sequenceId = action.sequenceId
  const contactId = action.contactId
  const path = `/campaigns/${sequenceId}/contacts/${contactId}`
  try {
    const response = yield call(get, path, q)
    const contact = fromJS(response.data)

    yield put(fetchContactSuccess({
      contact
    }))
  } catch (error) {
    yield put(fetchContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * updateContact (action) {
  const contactId = action.contactId
  const sequenceId = action.sequenceId
  const params = action.params
  const path = `/contacts/${contactId}`
  try {
    const raw = Object.keys(params)
      .filter(key => !TOP_LEVEL_FIELD_VALUES.includes(key))
      .reduce((acc, key) => {
        const cFields = acc
        cFields[key] = params[key]
        return cFields
      }, {})
    params.raw = raw
    const response = yield call(update, path, params)
    const contact = fromJS(response.data)
    yield put(updateContactSuccess({
      contact
    }))
    if (sequenceId) {
      yield put(refreshSequenceState(sequenceId)) // this refreshes the task counter chip
    }
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Contact updated! 🚀'
    }))
  } catch (error) {
    yield put(updateContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * cloneContact (action) {
  const {
    sequenceId,
    contactId,
    email
  } = action
  const params = { email }
  const path = `/campaigns/${sequenceId}/contacts/${contactId}/clone`
  try {
    const response = yield call(post, path, params)
    const contact = fromJS(response.data)

    yield put(cloneContactSuccess({
      contact
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Contact email updated and status reset! 🚀'
    }))
  } catch (error) {
    yield put(cloneContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * repliedContact (action) {
  const contactId = action.contactId
  const r = action.remove || false
  const booked = action.booked || false

  const path = `/contacts/${contactId}/replied`
  try {
    let response
    if (r) {
      response = yield call(remove, path)
      yield put(setNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: booked ? 'Removed booked meeting, messages will continue! 🚀' : 'Contact reply removed, messages will continue! 🚀'
      }))
    } else {
      const payload = {}
      if (action.booked) {
        payload.booked = true
      }
      if (action.sentiment !== undefined) {
        payload.sentiment = action.sentiment
      }
      if (action.replyType !== undefined) {
        payload.type = action.replyType
      }

      response = yield call(update, path, payload)
      yield put(setNotification({
        type: NOTIFICATION_TYPES.SUCCESS,
        message: booked ? 'Contact marked as booked! 🚀' : 'Contact marked as replied! 🚀'
      }))
    }
    const contact = fromJS(response.data)
    yield put(repliedContactSuccess({
      contact
    }))
  } catch (error) {
    yield put(repliedContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * unsubscribeContact (action) {
  const contactId = action.contactId
  const path = `/contacts/${contactId}/unsubscribe`
  try {
    const response = yield call(update, path)
    const contact = fromJS(response.data)
    yield put(unsubscribeContactSuccess({
      contact
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Contact marked as unsubscribed!'
    }))
  } catch (error) {
    yield put(unsubscribeContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * blockContact (action) {
  const {
    contactId,
    addToTeam
  } = action
  const path = `/contacts/${contactId}/block`
  try {
    const response = yield call(update, path, {
      team: addToTeam
    })
    const contact = fromJS(response.data)
    yield put(blockContactSuccess({
      contact
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Blocked ${contact.get('email_domain')}`
    }))
  } catch (error) {
    yield put(blockContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContactActions (action) {
  let query = {}

  // Build query
  if (!R.isEmpty(action.query)) {
    query = action.query
  }

  const contactId = action.contactId
  const path = `/contacts/${contactId}/actions?aggregate=true&clean=true`
  try {
    const response = yield call(get, path, query)
    const actions = fromJS(response.data)

    yield put(fetchContactActionsSuccess({
      actions
    }))
  } catch (error) {
    yield put(fetchContactActionsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContactCompany (action) {
  const domain = action.domain
  try {
    const response = yield call(get, '/websites/details', { url: domain })
    const company = fromJS(response.data)

    yield put(fetchContactCompanySuccess({
      company
    }))
  } catch (error) {
    yield put(fetchContactCompanyFailure(error))
  }
}

export function * fetchContactIntegration (action) {
  let query = {}

  // Build query
  if (!R.isEmpty(action.query)) {
    query = action.query
  }

  const contactId = action.contactId
  const path = `/contacts/${contactId}/integration`
  try {
    const response = yield call(get, path, query)
    const integration = fromJS(response.data)

    yield put(fetchContactIntegrationSuccess({
      integration
    }))
  } catch (error) {
    yield put(fetchContactIntegrationFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContactMessages (action) {
  let query = {}

  // Build query
  if (!R.isEmpty(action.query)) {
    query = action.query
  }

  const contactId = action.contactId
  const path = `/messages/contact/${contactId}`
  try {
    const response = yield call(get, path, query)
    const messages = fromJS(response.data)

    yield put(fetchContactMessagesSuccess({
      messages
    }))
  } catch (error) {
    yield put(fetchContactMessagesFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * reportContactInaccuracy (action) {
  const { contactId, inaccuracy } = action

  try {
    yield call(update, `/contacts/${contactId}/inaccuracy`, {
      inaccuracy
    })
    yield put(reportContactInaccuracySuccess())
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Report successfully submitted. Thank you for letting us know! 🚀'
    }))
  } catch (error) {
    yield put(reportContactInaccuracyFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * createContact (action) {
  const params = action.params

  try {
    const sequenceId = action.sequenceId
    const response = yield call(post, `/campaigns/${sequenceId}/contacts`, params)
    const contacts = fromJS(response.data)

    yield put(createContactSuccess({
      contacts
    }))
    yield call(fetchSequenceStats, { sequenceId })
  } catch (error) {
    yield put(createContactFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchSequencePreview (action) {
  const contactId = action.contactId
  const sequenceId = action.sequenceId
  const params = {}
  if (contactId) {
    params.contactId = contactId
  }
  try {
    const response = yield call(get, `/campaigns/${sequenceId}/steps/preview`, params)
    const previewSteps = fromJS(response.data)

    yield put(fetchSequencePreviewSuccess({
      previewSteps
    }))
  } catch (error) {
    yield put(fetchSequencePreviewFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * updateSequence (action) {
  const sequenceId = action.sequenceId
  const params = action.params
  const path = `/campaigns/${sequenceId}`
  const message = action.message || 'Sequence updated! 🚀'
  const cb = action.callback || null
  try {
    const response = yield call(update, path, params)
    const sequence = fromJS(response.data)

    if (params.active || sequence.get('active')) {
      yield call(fetchSequenceState, { sequenceId })
    }

    yield put(updateSequenceSuccess({
      sequence
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))

    if (cb) {
      cb()
    }
  } catch (error) {
    yield put(updateSequenceFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * restoreSequence (action) {
  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}/restore`
  const message = 'Sequence restored! 🚀'
  try {
    const response = yield call(update, path, {})
    const sequence = fromJS(response.data)

    yield put(restoreSequenceSuccess({
      sequence
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (error) {
    yield put(restoreSequenceFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * addCustomField (action) {
  const params = {
    fields: action.fields
  }
  const sequenceId = action.sequenceId
  const path = `/campaigns/${sequenceId}/fields`
  try {
    const response = yield call(post, path, params)
    const fields = fromJS(response.data)
    yield put(addCustomFieldSuccess({
      fields
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Updated custom fields 🚀'
    }))
  } catch (error) {
    yield put(addCustomFieldFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * sendTestEmail (action) {
  const {
    params,
    sequenceId,
    contactId,
    stepIndex,
    stepId,
    showPersonalized
  } = action

  const email = params.email
  let path = `/campaigns/${sequenceId}/preview/${stepId}`

  if (contactId) {
    if (showPersonalized) {
      path = `/messages/contact/${contactId}/step/${stepIndex}/preview`
    } else {
      path = `/campaigns/${sequenceId}/preview/${stepId}?contactId=${contactId}`
    }
  }

  try {
    yield call(post, path, params)
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Test email sent to ${email} 🚀`
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContactIds (sequenceId, contactIds, all, filter, search) {
  try {
    let ids = fromJS(contactIds)

    // if select all is chosen
    if (all) {
      const path = `/campaigns/${sequenceId}/contacts`

      const params = {
        fields: ['_id']
      }
      if (filter) {
        params.filter = filter
      }

      // backend calls it query, FE calls it search
      if (search) {
        params.query = search
      }

      const response = yield call(get, path, params)
      ids = fromJS(response.data).map(c => c.get('_id')).filter(id => !ids.includes(id))
    }

    return yield ids
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }

  return yield []
}

export function * deleteContacts (action) {
  const { sequenceId, contactIds, all, filter, search } = action
  try {
    const ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)
    const params = { ids }
    yield call(post, '/contacts/remove', params)
    yield put(removeSequenceContacts(ids))
    yield call(fetchSequenceStats, { sequenceId })
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Removed ${ids.count()} ${pluralize('contact', 'contacts', ids.count())}! 🚀`
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * moveContacts (action) {
  const {
    sequenceId,
    contactIds,
    all,
    filter,
    search,
    sequence
  } = action

  const ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)

  if (ids.count() > 100) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.LOADING,
      message: 'Moving contacts...'
    }))
  }

  try {
    for (let i = 0; i < ids.count(); i = i + BATCH_SIZE) {
      const params = {
        ids: ids.slice(i, i + BATCH_SIZE),
        campaign_id: sequence.get('_id')
      }
      yield call(api.post, '/contacts/move', params, { timeout: 120000 })
      yield put(removeSequenceContacts(ids))
    }

    yield call(fetchSequenceStats, { sequenceId })

    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Moved ${ids.count()} ${pluralize('contact', 'contacts', ids.count())} to ${sequence.get('title')}! 🚀`
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * copyContacts (action) {
  const {
    sequenceId,
    contactIds,
    all,
    filter,
    search,
    sequence
  } = action

  try {
    const ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)

    if (ids.count() > 100) {
      yield put(setNotification({
        type: NOTIFICATION_TYPES.LOADING,
        message: 'Copying contacts...'
      }))
    }

    for (let i = 0; i < ids.count(); i = i + BATCH_SIZE) {
      const params = {
        ids: ids.slice(i, i + BATCH_SIZE),
        campaign_id: sequence.get('_id')
      }
      yield call(api.post, '/contacts/copy', params, { timeout: 120000 })
    }

    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Copied ${ids.count()} ${pluralize('contact', 'contacts', ids.count())} to ${sequence.get('title')}! 🚀`
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * updateContacts (action) {
  const {
    sequenceId,
    contactIds,
    data,
    message,
    all,
    filter,
    search
  } = action
  try {
    let ids = []
    let count = 0
    if (sequenceId) {
      ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)
      count = ids.count()
    } else {
      ids = contactIds
      count = ids.length
    }

    if (count > 100) {
      yield put(setNotification({
        type: NOTIFICATION_TYPES.LOADING,
        message: 'Updating contacts...'
      }))
    }

    for (let i = 0; i < count; i = i + BATCH_SIZE) {
      const params = {
        ids: ids.slice(i, i + BATCH_SIZE),
        data
      }
      yield call(post, '/contacts/update', params)
    }

    yield put(updateContactsSuccess(ids, data))
    if (sequenceId) {
      yield put(refreshSequenceState(sequenceId)) // this refreshes the task counter chip
      yield call(fetchSequenceStats, { sequenceId })
    } else {
      yield put(fetchManualTasks()) // todo get this to refresh tasklist
    }
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `${message} ${count} ${pluralize('contact', 'contacts', count)}! 🚀`
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * createCsv (action) {
  const file = action.file
  // eslint-disable-next-line no-undef
  const data = new FormData()
  data.append('file', file)
  try {
    const response = yield call(postFile, '/csv', data)
    const csv = fromJS(response.data)
    if (csv.get('count') > 2000) {
      yield put(showWarningModal())
    }
    yield put(createCsvSuccess({
      csv
    }))
  } catch (error) {
    yield put(createCsvFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * importCsv (action) {
  const {
    sequenceId,
    csvId,
    lookup = false
  } = action
  try {
    const response = yield call(post, `/campaigns/${sequenceId}/csv/${csvId}`, { lookup })
    const csvSequence = fromJS(response.data)
    yield put(importCsvSuccess({ csvSequence }))
  } catch (error) {
    yield put(importCsvFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * updateCsv (action) {
  const {
    sequenceId,
    csvId,
    params,
    lookup
  } = action
  try {
    const response = yield call(post, `/csv/${csvId}`, params)
    const csv = fromJS(response.data)
    yield put(updateCsvSuccess({
      csv
    }))
    // Dirty hack to just call importCsv when done
    yield call(importCsv, { sequenceId, csvId, lookup })
  } catch (error) {
    yield put(updateCsvFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

const getContactStepUrl = (contactId, stepType, stepIndex) => {
  if (stepType === 'task') {
    return `/tasks/contact/${contactId}/step/${stepIndex}`
  }
  return `/messages/contact/${contactId}/step/${stepIndex}`
}

export function * updateContactStep (action) {
  const {
    contactId,
    stepType,
    stepIndex,
    params
  } = action
  try {
    const url = getContactStepUrl(contactId, stepType, stepIndex)
    const response = yield call(post, url, params)
    const object = fromJS(response.data)
    yield put(updateContactStepSuccess({
      object,
      contactId,
      stepIndex
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Personalized ${ordinal(stepIndex + 1)} step for contact! 🚀`
    }))
  } catch (error) {
    yield put(updateContactStepFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * removeContactStep (action) {
  const {
    contactId,
    stepType,
    stepIndex
  } = action
  try {
    const url = getContactStepUrl(contactId, stepType, stepIndex)
    const response = yield call(remove, url)
    const object = fromJS(response.data)
    yield put(removeContactStepSuccess({
      object,
      contactId,
      stepIndex
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Removed personalized step for contact!'
    }))
  } catch (error) {
    yield put(removeContactStepFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * createTemplate (action) {
  const {
    params
  } = action
  try {
    const response = yield call(post, '/templates', params)
    const template = fromJS(response.data)
    yield put(createTemplateSuccess({
      template
    }))
    const name = params.name
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Saved template ${name}! 🚀`
    }))
  } catch (error) {
    yield put(createTemplateFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * deleteTemplate (action) {
  const {
    template
  } = action
  try {
    yield call(remove, `/templates/${template.get('id')}`)
    yield put(deleteTemplateSuccess({
      template
    }))
    const name = template.get('name')
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Deleted template ${name}! 🚀`
    }))
  } catch (error) {
    yield put(deleteTemplateFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchTemplates () {
  try {
    const response = yield call(get, '/templates?filter=me')
    const templates = fromJS(response.data)
    yield put(fetchTemplatesSuccess({
      templates
    }))
  } catch (error) {
    yield put(fetchTemplatesFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchLookupStatus (action) {
  const { sequenceId } = action
  try {
    const response = yield call(get, `/campaigns/${sequenceId}/lookups`)
    const lookups = fromJS(response.data)

    yield put(fetchLookupStatusSuccess({
      lookups
    }))
  } catch (error) {
    yield put(fetchLookupStatusFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchPreviewContacts (action) {
  const { sequenceId } = action
  try {
    const path = `/campaigns/${sequenceId}/contacts`
    const params = {
      fields: '_id,name',
      filter: ['!replied', '!unsubscribed']
    }
    const response = yield call(get, path, params)
    const contacts = fromJS(response.data)

    yield put(fetchPreviewContactsSuccess({
      contacts
    }))
  } catch (error) {
    yield put(fetchPreviewContactsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * forceSendStep (action) {
  const {
    sequenceId,
    contactId,
    nextStepIndex
  } = action

  try {
    yield call(post, `/messages/contact/${contactId}/step/${nextStepIndex}/force`)
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Sent ${ordinal(nextStepIndex + 1)} step for contact! 🚀`
    }))
    yield delay(5000)
    const path = `/campaigns/${sequenceId}/contacts/${contactId}`
    const response = yield call(get, path)
    const contact = fromJS(response.data)
    yield put(updateContactSuccess({
      contact
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * createFile (action) {
  const f = action.file
  // eslint-disable-next-line no-undef
  const data = new FormData()
  data.append('file', f)
  try {
    const response = yield call(postFile, '/files', data)
    const file = fromJS(response.data)
    yield put(createFileSuccess({
      file
    }))
  } catch (error) {
    yield put(createFileFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchSimilarContacts (action) {
  const { contactId } = action
  try {
    const path = `/contacts/${contactId}/similar`
    const response = yield call(get, path)
    const similar = fromJS(response.data)

    yield put(fetchSimilarContactsSuccess({
      similar
    }))
  } catch (error) {
    yield put(fetchSimilarContactsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchCrm (action) {
  const { sequenceId, params } = action
  try {
    const path = `/campaigns/${sequenceId}/crm`

    if (!params?.refresh) {
      const response = yield call(get, path, {
        fields: [
          'type',
          'name'
        ]
      })
      const crm = fromJS(response.data)
      yield put(fetchCrmSuccess({
        crm
      }, true))
    }

    const responseFull = yield call(api.get, path, { params, timeout: 60000 })
    const crmFull = fromJS(responseFull.data)
    yield put(fetchCrmSuccess({
      crm: crmFull
    }))
  } catch (error) {
    yield put(fetchCrmFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContactReply (action) {
  const { contactId } = action
  try {
    const path = `/contacts/${contactId}/actions?verb=replied`
    const response = yield call(get, path)
    const data = fromJS(response.data)
    const contactReplyAction = data.find(action => action.get('verb') === 'replied')

    let contactReply
    if (contactReplyAction) {
      contactReply = contactReplyAction.get('data')
    }

    yield put(fetchContactReplySuccess({
      contactReply
    }))
  } catch (error) {
    yield put(fetchContactReplyFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * transferSequence (action) {
  const {
    sequenceId,
    params
  } = action

  try {
    const path = `/campaigns/${sequenceId}/transfer`
    const response = yield call(post, path, params)
    const sequence = fromJS(response.data)

    yield put(transferSequenceSuccess({
      sequence
    }))
  } catch (error) {
    yield put(transferSequenceFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchSentMessages (action) {
  const {
    contactId
  } = action

  try {
    const path = `/messages/contact/${contactId}/sent`
    const response = yield call(get, path)
    const messages = fromJS(response.data)

    yield put(fetchSentMessagesSuccess({
      messages
    }))
  } catch (error) {
    yield put(fetchSentMessagesFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchContactErrors (action) {
  const { sequenceId, contactIds, all, search } = action

  let filter = action.filter || []
  if (!Array.isArray(filter)) {
    filter = [filter]
  }
  if (!filter.includes('error')) {
    filter.push('error')
  }

  try {
    const ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)
    const path = '/contacts/errors'
    const response = yield call(post, path, { ids })
    const contactErrors = fromJS(response.data)
    yield put(fetchContactErrorsSuccess({ contactErrors }))
  } catch (error) {
    yield put(fetchContactErrorsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * updateContactErrors (action) {
  const { sequenceId, contactIds, errorTypes, all, search } = action

  let filter = action.filter || []
  if (!Array.isArray(filter)) {
    filter = [filter]
  }
  if (!filter.includes('error')) {
    filter.push('error')
  }

  try {
    const ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)

    if (ids.count() > 100) {
      yield put(setNotification({
        type: NOTIFICATION_TYPES.LOADING,
        message: 'Clearing contact errors...'
      }))
    }

    let contacts = Immutable.List([])
    for (let i = 0; i < ids.count(); i = i + BATCH_SIZE) {
      const params = {
        ids: ids.slice(i, i + BATCH_SIZE),
        errors: errorTypes
      }
      const response = yield call(api.post, '/contacts/errors/clear?full=true', params, { timeout: 120000 })
      contacts = contacts.concat(fromJS(response.data))
    }

    yield put(updateContactErrorsSuccess({ contacts }))
    yield call(fetchSequenceStats, { sequenceId })
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Successfully cleared contact errors'
    }))
  } catch (error) {
    yield put(updateContactErrorsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * fetchSelectedContactCount (action) {
  const { sequenceId, selectedContacts, all, filter, search } = action

  if (!all) {
    yield put(fetchSelectedContactCountSuccess({
      selectedCount: selectedContacts.length
    }))
  } else {
    const path = `/campaigns/${sequenceId}/contacts`

    const params = {
      count: true
    }

    if (filter) {
      params.filter = filter
    }

    // backend calls it query, FE calls it search
    if (search) {
      params.query = search
    }

    try {
      const response = yield call(get, path, params)
      const data = fromJS(response.data)
      yield put(fetchSelectedContactCountSuccess({
        selectedCount: data.get('count')
      }))
    } catch (error) {
      yield put(fetchSelectedContactCountFailure(error))
      yield put(setNotification({
        type: NOTIFICATION_TYPES.ERROR,
        message: messageFromError(error)
      }))
    }
  }
}

export function * fetchOgp (action) {
  const { url } = action
  try {
    const path = `/websites/metatags?url=${url}`
    const response = yield call(get, path)
    const ogpData = fromJS(response.data)
    yield put(fetchOgpSuccess({ ogpData }))
  } catch (err) {
    yield put(fetchOgpFailure(err))
  }
}

export function * fetchAddresses (action) {
  const { sequenceId } = action
  try {
    const path = `/campaigns/${sequenceId}/addresses`
    const response = yield call(get, path)
    const addresses = fromJS(response.data)
    yield put(fetchAddressesSuccess({ addresses }))
  } catch (err) {
    yield put(fetchAddressesFailure(err))
  }
}

export function * fetchContactSteps (action) {
  const { sequenceId, contactId } = action
  try {
    const path = `/campaigns/${sequenceId}/contacts/${contactId}/steps`
    const response = yield call(get, path)
    const contactSteps = fromJS(response.data)
    yield put(fetchContactStepsSuccess(contactSteps))
  } catch (err) {
    yield put(fetchContactStepsFailure(err))
  }
}

export function * fetchContactPhone (action) {
  const { contactId } = action
  try {
    const path = `/phones/contact/${contactId}/query`
    const response = yield call(post, path)
    const contact = fromJS(response.data)
    yield put(fetchContactPhoneSuccess(contactId, contact))
  } catch (err) {
    yield put(fetchContactPhoneFailure(messageFromError(err)))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * importCrmContacts (action) {
  const { sequenceId, importOptionId, createCron } = action
  try {
    const path = `campaigns/${sequenceId}/crm_import`
    yield call(post, path, { importOptionId, createCron })
    yield put(importCrmContactsSuccess())
  } catch (err) {
    yield put(importCrmContactsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * resyncToCrm (action) {
  const {
    sequenceId,
    contactIds,
    all,
    filter,
    search
  } = action

  try {
    const ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)

    const path = '/contacts/resync'
    const response = yield call(post, path, { ids })

    const numberOfContactsModified = response.data.modified

    yield put(resyncToCrmSuccess())
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `${numberOfContactsModified} ${pluralize('contact', 'contacts', numberOfContactsModified)} resynced! 🚀`
    }))
  } catch (err) {
    yield put(resyncToCrmFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * phoneNumberLookups (action) {
  const { sequenceId, contactIds, all, filter, search } = action
  try {
    const ids = yield fetchContactIds(sequenceId, contactIds, all, filter, search)
    const path = '/phones/contacts/lookup'
    const params = {
      ids,
      campaign: sequenceId
    }
    const response = yield call(post, path, params)
    const lookup = response.data

    yield put(phoneNumberLookupsSuccess(lookup))
  } catch (err) {
    yield put(phoneNumberLookupsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchSequenceSignature (action) {
  const { sequenceId } = action

  try {
    const path = `/campaigns/${sequenceId}/signature`
    const response = yield call(get, path)
    const data = fromJS(response.data)

    yield put(fetchSequenceSignatureSuccess(data))
  } catch (err) {
    yield put(fetchSequenceSignatureFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

function * fetchExportCsvLinkRequest () {
  yield takeLatest(FETCH_EXPORT_CSV_REQUEST, fetchExportCsvLink)
}

function * fetchExportPdfRequest () {
  yield takeLatest(FETCH_EXPORT_PDF_REQUEST, fetchExportPdf)
}

function * fetchSequenceRequest () {
  yield takeLatest(FETCH_SEQUENCE_REQUEST, fetchSequence)
}

function * deleteSequenceRequest () {
  yield takeLatest(DELETE_SEQUENCE_REQUEST, deleteSequence)
}

function * fetchSequenceStateRequest () {
  yield takeLatest(FETCH_SEQUENCE_STATE_REQUEST, fetchSequenceState)
}

function * fetchContactsRequest () {
  yield takeLatest(FETCH_CONTACTS_REQUEST, fetchContacts)
}

function * fetchStatsRequest () {
  yield takeLatest(FETCH_SEQUENCE_STATS_REQUEST, fetchSequenceStats)
}

function * fetchStepStatsRequest () {
  yield takeLatest(FETCH_STEP_STATS_REQUEST, fetchStepStats)
}

function * fetchContactRequest () {
  yield takeLatest(FETCH_CONTACT_REQUEST, fetchContact)
}

function * updateContactRequest () {
  yield takeLatest(UPDATE_CONTACT_REQUEST, updateContact)
}

function * cloneContactRequest () {
  yield takeLatest(CLONE_CONTACT_REQUEST, cloneContact)
}

function * fetchContactActionsRequest () {
  yield takeLatest(FETCH_CONTACT_ACTIONS_REQUEST, fetchContactActions)
}

function * fetchContactIntegrationRequest () {
  yield takeLatest(FETCH_CONTACT_INTEGRATION_REQUEST, fetchContactIntegration)
}

function * fetchContactMessagesRequest () {
  yield takeLatest(FETCH_CONTACT_MESSAGES_REQUEST, fetchContactMessages)
}

function * reportContactInaccuracyRequest () {
  yield takeLatest(REPORT_CONTACT_INACCURACY_REQUEST, reportContactInaccuracy)
}

function * createContactRequest () {
  yield takeLatest(CREATE_CONTACT_REQUEST, createContact)
}

function * repliedContactRequest () {
  yield takeLatest(REPLIED_CONTACT_REQUEST, repliedContact)
}

function * unsubscribeContactRequest () {
  yield takeLatest(UNSUBSCRIBE_CONTACT_REQUEST, unsubscribeContact)
}

function * blockContactRequest () {
  yield takeLatest(BLOCK_CONTACT_REQUEST, blockContact)
}

function * fetchSequencePreviewRequest () {
  yield takeLatest(FETCH_SEQUENCE_PREVIEW_REQUEST, fetchSequencePreview)
}

function * updateSequenceRequest () {
  yield takeLatest(UPDATE_SEQUENCE_REQUEST, updateSequence)
}

function * restoreSequenceRequest () {
  yield takeLatest(RESTORE_SEQUENCE_REQUEST, restoreSequence)
}

function * sendTestEmailRequest () {
  yield takeLatest(SEND_TEST_EMAIL_REQUEST, sendTestEmail)
}

function * deleteContactsRequest () {
  yield takeLatest(DELETE_CONTACTS_REQUEST, deleteContacts)
}

function * moveContactsRequest () {
  yield takeLatest(MOVE_CONTACTS_REQUEST, moveContacts)
}

function * copyContactsRequest () {
  yield takeLatest(COPY_CONTACTS_REQUEST, copyContacts)
}

function * updateContactsRequest () {
  yield takeLatest(UPDATE_CONTACTS_REQUEST, updateContacts)
}

function * createCsvRequest () {
  yield takeLatest(CREATE_CSV_REQUEST, createCsv)
}

function * updateCsvRequest () {
  yield takeLatest(UPDATE_CSV_REQUEST, updateCsv)
}

function * importCsvRequest () {
  yield takeLatest(IMPORT_CSV_REQUEST, importCsv)
}

function * addCustomFieldRequest () {
  yield takeLatest(UPDATE_CUSTOM_FIELDS_REQUEST, addCustomField)
}

function * updateContactStepRequest () {
  yield takeLatest(UPDATE_CONTACT_STEP_REQUEST, updateContactStep)
}

function * removeContactStepRequest () {
  yield takeLatest(REMOVE_CONTACT_STEP_REQUEST, removeContactStep)
}

function * createTemplateRequest () {
  yield takeLatest(CREATE_TEMPLATE_REQUEST, createTemplate)
}

function * fetchTemplatesRequest () {
  yield takeLatest(FETCH_TEMPLATES_REQUEST, fetchTemplates)
}

function * fetchContactCompanyRequest () {
  yield takeLatest(FETCH_CONTACT_COMPANY_REQUEST, fetchContactCompany)
}

function * fetchLookupStatusRequest () {
  yield takeLatest(FETCH_LOOKUP_STATUS_REQUEST, fetchLookupStatus)
}

function * fetchPreviewContactsRequest () {
  yield takeLatest(FETCH_PREVIEW_CONTACTS_REQUEST, fetchPreviewContacts)
}

function * deleteTemplateRequest () {
  yield takeLatest(DELETE_TEMPLATE_REQUEST, deleteTemplate)
}

function * forceSendStepRequest () {
  yield takeLatest(FORCE_SEND_STEP_REQUEST, forceSendStep)
}

function * createFileRequest () {
  yield takeLatest(CREATE_FILE_REQUEST, createFile)
}

function * fetchSimilarContactsRequest () {
  yield takeLatest(FETCH_SIMILAR_CONTACTS_REQUEST, fetchSimilarContacts)
}

function * fetchCrmRequest () {
  yield takeLatest(FETCH_CRM_REQUEST, fetchCrm)
  yield takeLatest(REFRESH_CRM_REQUEST, fetchCrm)
}

function * fetchContactReplyRequest () {
  yield takeLatest(FETCH_CONTACT_REPLY_REQUEST, fetchContactReply)
}

function * transferSequenceRequest () {
  yield takeLatest(TRANSFER_SEQUENCE_REQUEST, transferSequence)
}

function * fetchSentMessagesRequest () {
  yield takeLatest(FETCH_SENT_MESSAGES_REQUEST, fetchSentMessages)
}

function * fetchContactErrorsRequest () {
  yield takeLatest(FETCH_CONTACT_ERRORS_REQUEST, fetchContactErrors)
}

function * clearContactErrorsRequest () {
  yield takeLatest(UPDATE_CONTACT_ERRORS_REQUEST, updateContactErrors)
}

function * fetchSelectedContactCountRequest () {
  yield takeLatest(FETCH_SELECTED_CONTACT_COUNT_REQUEST, fetchSelectedContactCount)
}

function * fetchOgpRequest () {
  yield takeLatest(FETCH_OGP_REQUEST, fetchOgp)
}

function * fetchAddressesRequest () {
  yield takeLatest(FETCH_ADDRESSES_REQUEST, fetchAddresses)
}

function * fetchContactStepsRequest () {
  yield takeLatest(FETCH_CONTACT_STEPS_REQUEST, fetchContactSteps)
}

function * fetchContactPhoneRequest () {
  yield takeLatest(FETCH_CONTACT_PHONE_REQUEST, fetchContactPhone)
}

function * importCrmContactsRequest () {
  yield takeLatest(IMPORT_CRM_CONTACTS_REQUEST, importCrmContacts)
}

function * resyncToCrmRequest () {
  yield takeLatest(RESYNC_TO_CRM_REQUEST, resyncToCrm)
}

function * phoneNumberLookupsRequest () {
  yield takeLatest(PHONE_NUMBER_LOOKUPS_REQUEST, phoneNumberLookups)
}

function * fetchSequenceSignatureRequest () {
  yield takeLatest(FETCH_SEQUENCE_SIGNATURE_REQUEST, fetchSequenceSignature)
}

export default [
  fetchSequenceRequest,
  deleteSequenceRequest,
  fetchSequenceStateRequest,
  fetchContactsRequest,
  fetchStatsRequest,
  fetchStepStatsRequest,
  fetchExportCsvLinkRequest,
  fetchExportPdfRequest,
  fetchContactRequest,
  fetchContactActionsRequest,
  fetchContactIntegrationRequest,
  fetchContactMessagesRequest,
  reportContactInaccuracyRequest,
  createContactRequest,
  fetchSequencePreviewRequest,
  updateSequenceRequest,
  restoreSequenceRequest,
  sendTestEmailRequest,
  deleteContactsRequest,
  moveContactsRequest,
  updateContactsRequest,
  updateContactRequest,
  repliedContactRequest,
  unsubscribeContactRequest,
  blockContactRequest,
  createCsvRequest,
  updateCsvRequest,
  importCsvRequest,
  addCustomFieldRequest,
  updateContactStepRequest,
  removeContactStepRequest,
  createTemplateRequest,
  fetchTemplatesRequest,
  fetchContactCompanyRequest,
  fetchLookupStatusRequest,
  fetchPreviewContactsRequest,
  deleteTemplateRequest,
  forceSendStepRequest,
  createFileRequest,
  copyContactsRequest,
  cloneContactRequest,
  fetchSimilarContactsRequest,
  fetchCrmRequest,
  fetchContactReplyRequest,
  transferSequenceRequest,
  fetchSentMessagesRequest,
  fetchContactErrorsRequest,
  clearContactErrorsRequest,
  fetchSelectedContactCountRequest,
  fetchOgpRequest,
  fetchAddressesRequest,
  fetchContactStepsRequest,
  fetchContactPhoneRequest,
  importCrmContactsRequest,
  resyncToCrmRequest,
  phoneNumberLookupsRequest,
  fetchSequenceSignatureRequest
]
