import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import Immutable from 'immutable'
import DropDown from 'components/DropDown'

const DropDownContainer = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  text-align: right;
`

class ContactSequenceDropdown extends Component {
  render () {
    const {
      session,
      members,
      sequences,
      selectedMemberId,
      selectedSequenceId,
      onOptionChange
    } = this.props

    let options = []
    options.push({
      label: 'Team Members',
      type: 'header'
    })
    members.get('data')
      .filter(member => !session.get('group') || session.get('group') === member.get('group'))
      .sortBy(member => member.get('full_name') || member.get('email'))
      .forEach((member, i) => {
        options.push({
          label: member.get('full_name') || member.get('email'),
          value: JSON.stringify({
            userId: member.get('id')
          }),
          index: i
        })
      })
    // sequences
    options.push({
      label: 'Sequences',
      type: 'header'
    })
    sequences
      .sortBy(sequence => sequence.get('title'))
      .forEach((sequence, i) => {
        options.push({
          label: sequence.get('title'),
          value: JSON.stringify({
            userId: sequence.getIn(['_user', '_id']),
            sequenceId: sequence.get('_id')
          }),
          index: members.get('data').count() + i
        })
      })
    options = Immutable.List(options)

    let selectedOption
    if (selectedMemberId || selectedSequenceId) {
      selectedOption = {}
      if (selectedMemberId) {
        selectedOption.userId = selectedMemberId
      }
      if (selectedSequenceId) {
        selectedOption.sequenceId = selectedSequenceId
      }
      selectedOption = JSON.stringify(selectedOption)
    }

    return (
      <DropDownContainer>
        <DropDown
          options={options}
          clearable={false}
          controlled
          searchable
          height='3rem'
          placeholder='Filter by team member or sequence…'
          value={selectedOption}
          onOptionChange={onOptionChange}
          borderColor={this.props.theme.borderColor}
          style={{
            zIndex: 100
          }}
        />
      </DropDownContainer>
    )
  }
}

ContactSequenceDropdown.propTypes = {
  session: PropTypes.object,
  members: PropTypes.object,
  sequences: PropTypes.object,
  actions: PropTypes.object,
  selectedMemberId: PropTypes.string,
  selectedSequenceId: PropTypes.string,
  onOptionChange: PropTypes.func,
  theme: PropTypes.any
}

export default withTheme(ContactSequenceDropdown)
