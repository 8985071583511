import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
  // display: grid;
  display: flex;
  height: 100%;
  min-width: 185px;
  align-items: center;
  // grid-template-columns: 15% 75%;
`

const NameWrapper = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  vertical-align: middle;
  display: inline-block;
`

const IconWrapper = styled.a`
  flex: 0 0 20px;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  background-color: ${props => props.theme.colors.linkedin};

  border-style: solid;
  border-radius: 9999px;
  border-width: 0;
  border-color: ${props => props.theme.colors.linkedin};

  height: 20px;
  width: 20px;

  margin: auto 0;

  opacity: 1;

  font-size: 14px;
  ${space}
`

function SocialLinksCellRenderer ({
  value
}) {
  const getUsername = linkedinUrl => {
    if (linkedinUrl) {
      return linkedinUrl.replace(/\/$/, '').split('/').pop()
    }

    return null
  }

  const linkedinUsername = getUsername(value)

  return (
    <Wrapper>
      {value && (
        <>
          <IconWrapper
            mr='6px'
            target='_new'
            href={`https://www.linkedin.com/in/${linkedinUsername}`}
          >
            <FontAwesomeIcon
              icon={['fab', 'linkedin-in']}
              color='#FFF'
              size='xs'
              transform={{
                x: 0,
                y: 1
              }}
            />
          </IconWrapper>
          <NameWrapper>{linkedinUsername}</NameWrapper>
        </>
      )}
    </Wrapper>
  )
}

SocialLinksCellRenderer.propTypes = {
  value: PropTypes.string
}

export default SocialLinksCellRenderer
