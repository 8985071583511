import React from 'react'
import styled from 'styled-components'

import ArchiveRow from '../ArchiveRow'
import FolderRow from '../FolderRow'
import SequenceRow from '../SequenceRow'

const Wrapper = styled.div`
  cursor: pointer;
  font: ${props => props.theme.fonts.normal};
  background-color: ${props => (props.selected ? props.theme.highlightBackground : props.theme.containerBackground)};
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-top: ${props => `solid 1px ${props.theme.borderColor}`};
  letter-spacing: .025em;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: row;
  width: 900px;

  &:hover {
    background-color: ${props => props.theme.highlightBackground};
  }
`

/**
 * Custom render for contacts in sequence
 */
export default function sequenceRowRenderer ({
  className,
  // columns,
  index,
  parent,
  // onRowClick,
  // onRowDoubleClick,
  // onRowMouseOut,
  // onRowMouseOver,
  // onRowRightClick,
  rowData,
  onClick,
  style,
  theme,
  router,
  cache,
  isScrolling,
  onSequenceSelected,
  selectedSequences,
  allSelected,
  onSequenceClick,
  onSequenceStatusToggle,
  onFolderClick,
  onArchiveClick,
  showArchived,
  onFolderSelected
}) {
  const type = rowData.get('type')
  const data = rowData.get('data')
  let key = `row_${index}`

  switch (type) {
    case 'archive': {
      key = 'archive_row'

      return (
        <Wrapper
          className={className}
          key={key}
          role='row'
          style={style}
        >
          <ArchiveRow
            archiveCount={data.get('count')}
            onClick={() => {
              onArchiveClick()
            }}
          />
        </Wrapper>
      )
    }
    case 'folder': {
      const folderName = data.get('name')
      key = `folder_row_${folderName}`

      return (
        <Wrapper
          className={className}
          key={key}
          role='row'
          style={style}
        >
          <FolderRow
            key={folderName}
            folderName={folderName}
            sequences={data.get('sequences')}
            stats={data.get('stats')}
            onClick={() => {
              onFolderClick(folderName)
            }}
            selected={allSelected || selectedSequences.indexOf(data.getIn(['sequences', '0', 'id'])) >= 0}
            onSelected={(e) => {
              onFolderSelected(folderName, e)
            }}
          />
        </Wrapper>
      )
    }
    case 'sequence': {
      const sequence = data.get('sequence')
      const sequenceId = sequence.get('id')
      key = `sequence_row_${sequenceId}`

      return (
        <Wrapper
          className={className}
          key={key}
          role='row'
          style={style}
        >
          <SequenceRow
            sequence={sequence}
            stats={data.get('stats')}
            status={data.get('status')}
            selected={allSelected || selectedSequences.indexOf(sequenceId) >= 0}
            archived={showArchived}
            onSelected={(e) => {
              onSequenceSelected(sequenceId, e)
            }}
            onClick={(e) => {
              onSequenceClick(sequenceId, e)
            }}
            onToggleStatus={(value) => {
              onSequenceStatusToggle(sequence, value)
            }}
          />
        </Wrapper>
      )
    }
    default:
      return (
        <Wrapper
          className={className}
          role='row'
          style={style}
        />
      )
  }
}
