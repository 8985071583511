/**
*
* WorkflowItem
*
*/

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'

const ITEM_TYPE = {
  PREVIOUS: 0,
  CURRENT: 1,
  FUTURE: 2
}

const Wrapper = styled.li`
  align-items: center;
  justify-content: center;
  display: flex;
  flex-direction: column;
  width: 10rem;
  height: 2rem;
  font: ${props => props.theme.fonts.normal};
  padding: ${props => props.theme.padding};
  padding-bottom: 0;
  padding-left: 1.5rem;
  padding-right: 1.5rem;
  margin-bottom: 0;
  cursor: normal;
  position: relative;
`

const IconContainer = styled.div`
  position: absolute;
  background-color: ${props => props.color};
  left: 0;
  right: 0;
  top: -12.4px;
  height: 27px;
  border-radius: 9999px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const BorderLine = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  border-width: 1px;
  border-style: solid;
  border-color: ${props => props.theme.colors.gray60};
  background-color: ${props => props.theme.colors.gray60};
  ${props => props.right && css`
    left: 50%;
  `};
  ${props => props.left && css`
    right: 50%;
  `};
  ${props => props.type === ITEM_TYPE.PREVIOUS && css`
    border-color: ${props.theme.primaryColor};
    background-color: ${props.theme.primaryColor};
  `};
  ${props => props.left && props.type === ITEM_TYPE.CURRENT && css`
    border-color: ${props.theme.primaryColor};
    background-color: ${props.theme.primaryColor};
  `};

  transition: border-color .5s ease-in .2s;
`

const Circle = styled.div`
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 9999px;
  border-style: solid;
  border-width: 2px;
  background-color: ${props => props.theme.background};

  ${props => props.type !== ITEM_TYPE.FUTURE && css`
    border-color: ${props.theme.primaryColor};
  `};

  ${props => props.type === ITEM_TYPE.PREVIOUS && css`
    background-color: ${props.theme.primaryColor};
  `};

  ${props => props.type === ITEM_TYPE.FUTURE && css`
    border-color: ${props.theme.colors.gray60};
  `};

  transition: background-color .5s ease-in .2s, border-color .5s ease-in .2s;
`

const TitleContainer = styled.div`
  font: ${props => props.theme.fonts.button};
  letter-spacing: .05em;
  color: ${props => props.theme.colors.gray60};
  text-align: left;
  text-transform: uppercase;
  margin: 0;

  ${props => props.type === ITEM_TYPE.CURRENT && css`
    color: ${props.theme.primaryColor};
  `};

  transition: color .5s ease-in .5s;
`

class WorkflowItem extends PureComponent {
  render () {
    const {
      step,
      stepIndex,
      currentStep,
      totalSteps
    } = this.props

    let itemType
    if (stepIndex < currentStep) {
      itemType = ITEM_TYPE.PREVIOUS
    } else if (stepIndex === currentStep) {
      itemType = ITEM_TYPE.CURRENT
    } else {
      itemType = ITEM_TYPE.FUTURE
    }

    const left = stepIndex !== 0
    const right = stepIndex + 1 !== totalSteps
    return (
      <Wrapper type={itemType}>
        {left &&
          <BorderLine
            type={itemType}
            left
          />}
        {right &&
          <BorderLine
            type={itemType}
            right
          />}
        <IconContainer>
          <Circle type={itemType} />
        </IconContainer>
        <TitleContainer type={itemType}>
          {step.title}
        </TitleContainer>
      </Wrapper>
    )
  }
}

WorkflowItem.propTypes = {
  step: PropTypes.object,
  stepIndex: PropTypes.number,
  currentStep: PropTypes.number,
  totalSteps: PropTypes.number
}

export default WorkflowItem
