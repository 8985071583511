/* eslint-disable no-return-assign */
import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import R from 'utils/ramda'

import Modal from 'components/Modal'
import Button from 'components/Button'
import Loading from 'components/Loading'
import EmptyState from 'components/EmptyState'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import Checkbox from 'elements/Checkbox'
import { Satellite } from 'svg'

import * as ErrorStyles from '../components/ErrorStyles'
import * as ModalWrappers from '../components/ModalWrappers'
import { getGroupContactErrorData } from '../utils/ModalUtils'

class GroupContactErrorModal extends React.PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedContactIds: [],
      selectedContactErrors: [],
      activeErrorCount: 0
    }

    this.handleOnCancel = this.handleOnCancel.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleSelectRow = this.handleSelectRow.bind(this)
    this.handleOnConfirm = this.handleOnConfirm.bind(this)
    this.mapPropsToState = this.mapPropsToState.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const prevProps = this.props
    if (nextProps.contactErrors && !R.equals(nextProps.contactErrors, prevProps.contactErrors)) {
      this.mapPropsToState(nextProps)
    }
  }

  mapPropsToState = props => {
    const newSelectedContactErrors = []
    props.contactErrors
      .get('data')
      .entrySeq()
      .forEach(([error, errorCount]) => {
        const {
          title,
          description,
          clearingDisabled
        } = getGroupContactErrorData(error, errorCount, props.crm)

        const selectedContactErrorRow = {
          title,
          error,
          errorCount,
          description,
          selected: false,
          clearingDisabled
        }

        newSelectedContactErrors.push(selectedContactErrorRow)
      })

    this.setState({ selectedContactErrors: newSelectedContactErrors })
  }

  handleKeyDown = event => {
    const ENTER = 13
    if (event.keyCode === ENTER) {
      this.handleOnConfirm()
    }

    return false
  }

  handleOnConfirm = () => {
    const { selectedContactErrors } = this.state

    const {
      actions,
      sequenceId,
      contactIds,
      allContactsSelected,
      filter,
      search,
      onClose
    } = this.props

    // gather all selected error types
    const errorTypes = []
    selectedContactErrors.forEach(err => {
      if (err.selected) {
        errorTypes.push(err.error)
      }
    })

    // deselect all errors
    const deselectedContactErrors = [...selectedContactErrors]
    deselectedContactErrors.forEach(err => err.selected = false)

    // clear errors, reset selected state and close
    Promise
      .resolve()
      .then(() => {
        if (errorTypes && errorTypes.length > 0) {
          actions.updateContactErrors(
            sequenceId,
            contactIds,
            errorTypes,
            allContactsSelected,
            filter,
            search
          )

          return true
        }

        return false
      })
      .then(result => {
        if (result) {
          this.setState({
            selectedContactIds: [],
            selectedContactErrors: deselectedContactErrors,
            activeErrorCount: 0
          }, onClose)
        }
      })
  }

  handleOnCancel = () => {
    const { onClose } = this.props
    const { selectedContactErrors } = this.state

    if (selectedContactErrors && selectedContactErrors.length > 0) {
      const deselectedContactErrors = [...selectedContactErrors]
      deselectedContactErrors.forEach(err => err.selected = false)
      this.setState({
        selectedContactErrors: deselectedContactErrors,
        activeErrorCount: 0
      }, onClose)
    } else {
      this.setState({
        activeErrorCount: 0
      }, onClose)
    }
  }

  handleSelectRow = (idx, clearingDisabled) => {
    if (clearingDisabled) {
      return
    }

    // get current state
    const { selectedContactErrors, activeErrorCount } = this.state

    // copy and invert `selected` value
    const newSelectedContactErrors = [...selectedContactErrors]
    newSelectedContactErrors[idx].selected = !newSelectedContactErrors[idx].selected

    // if `selected` is `true`, incrementt `activeErrorCount`, otherwise decrement `activeErrorCount`
    if (newSelectedContactErrors[idx].selected) {
      this.setState({
        selectedContactErrors: newSelectedContactErrors,
        activeErrorCount: activeErrorCount - 1
      })
    } else {
      this.setState({
        selectedContactErrors: newSelectedContactErrors,
        activeErrorCount: activeErrorCount + 1
      })
    }
  }

  renderErrors = () => {
    const { contactErrors, isOpen } = this.props
    const { selectedContactErrors } = this.state

    const LOADING = contactErrors &&
      contactErrors.get('loading')
    const NO_ERRORS = !contactErrors ||
      (contactErrors &&
      !contactErrors.get('loading') &&
      contactErrors.get('data').size === 0)

    if (LOADING) {
      return (<Loading />)
    } else if (NO_ERRORS) {
      return (
        <EmptyState
          icon={<Satellite />}
          title='No Errors Found'
          description="We couldn't find any errors on the contacts you have selected, try selecting different contacts"
        />
      )
    } else if (isOpen) {
      return selectedContactErrors.map((errObj, idx) => {
        const { title, errorCount, selected, description, clearingDisabled } = errObj

        return (
          <ErrorStyles.ErrorRow key={`er_${idx}`}>
            <ErrorStyles.ErrorRowDivider />
            <ErrorStyles.ErrorRowCell>
              <ErrorStyles.ErrorRowStatus>
                <Checkbox
                  disabled={clearingDisabled}
                  handleClick={() => this.handleSelectRow(idx, clearingDisabled)}
                  selected={selected}
                />
                <ErrorStyles.ErrorLabel
                  disabled={clearingDisabled}
                  onClick={() => this.handleSelectRow(idx)}
                >
                  {title}
                </ErrorStyles.ErrorLabel>
              </ErrorStyles.ErrorRowStatus>
            </ErrorStyles.ErrorRowCell>
            <ErrorStyles.ErrorRowCell>
              <Label align='left'>
                <b> {errorCount} </b>
                {description}
              </Label>
            </ErrorStyles.ErrorRowCell>
            <ErrorStyles.ErrorRowDivider />
          </ErrorStyles.ErrorRow>
        )
      })
    }
  }

  render () {
    const { activeErrorCount } = this.state
    const { isOpen, description } = this.props
    return (
      <Modal
        width='fit-content'
        maxWidth='40rem'
        isOpen={isOpen}
        onModalClose={this.handleOnCancel}
      >
        <ModalWrappers.Wrapper
          onKeyDown={this.handleOnCancel}
        >
          <ModalWrappers.InputContainer>
            <Title align='center'>Clear Contact Errors</Title>
            <Label mt='0.5rem' mb='0.5rem' align='center'>
              {description}
            </Label>
          </ModalWrappers.InputContainer>
          <Divider />
          <ErrorStyles.ErrorContainer>
            {this.renderErrors()}
          </ErrorStyles.ErrorContainer>
          <Divider />
          <ModalWrappers.ButtonContainer>
            <Button
              mr='0.5rem'
              label='cancel'
              handleClick={this.handleOnCancel}
            />
            <Button
              primary
              label='clear errors'
              handleClick={this.handleOnConfirm}
              disabled={activeErrorCount === 0}
            />
          </ModalWrappers.ButtonContainer>
        </ModalWrappers.Wrapper>
      </Modal>
    )
  }
}

GroupContactErrorModal.propTypes = {
  // Contact bar & general modal props
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfim: PropTypes.func,

  // error modal specific props
  actions: PropTypes.object,
  filter: PropTypes.object,
  search: PropTypes.string,
  sequenceId: PropTypes.string,
  allContactsSelected: PropTypes.bool,
  contactIds: PropTypes.array,
  contactErrors: PropTypes.instanceOf(Immutable.Map),
  crm: PropTypes.object,
  description: PropTypes.string
}

export default GroupContactErrorModal
