import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Avatar from 'react-avatar'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'
import { space, width } from 'styled-system'
import Checkbox from 'elements/Checkbox'
import Verified from 'elements/Verified'
import Warning from 'elements/Warning'
import Error from 'elements/Error'
import Label from 'elements/Label'
import Unknown from 'elements/Unknown'
import ToolbarButton from 'components/ToolbarButton'
import StatusActions from './StatusActions'

const ActionsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
  position: absolute;
  right: 0;
  padding: 1rem;
  background-color: ${props => (props.selected ? props.theme.highlightBackground : props.theme.containerBackground)};
  transition: background-color 0.2s ease;
`

const Wrapper = styled.div`
  font: ${props => props.theme.fonts.normal};
  background-color: ${props => (props.selected ? props.theme.highlightBackground : props.theme.containerBackground)};
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-top: ${props => `solid 1px ${props.theme.borderColor}`};
  letter-spacing: .025em;
  transition: background-color 0.2s ease;
  display: flex;
  flex-direction: row;
  cursor: pointer;

  ${ActionsContainer} {
    opacity: 0;
  }

  &:hover {
    background-color: ${props => props.theme.highlightBackground};
  }

  &:hover ${ActionsContainer} {
    opacity: 1;
    background-color: ${props => props.theme.highlightBackground};
  }
`

const ContactName = styled.div`
  height: 100%;
  color: ${props => props.theme.titleColor};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  max-width: ${props => props.maxWidth};
  padding-left: ${props => props.theme.padding};
  padding-right: ${props => props.theme.padding};
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  display: flex;
  flex-direction: row;
  align-items: center;
  overflow: hidden;

  ${space}
  ${width}
`

const SequenceTitle = styled(Label)`
  color: ${props => props.theme.titleColor};
  font-weight: 600;
  text-overflow: ellipsis;
  word-wrap: break-word;
  overflow: hidden;
  max-height: 3rem;
  line-height: 1.5rem;

  &:hover {
    text-decoration: underline;
  }
`

const AlignCenter = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  max-width: 100%;
`

const CheckboxContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 1.5rem;

  ${space}
  ${width}
`

const TitleContainer = styled(Label)`
  overflow: hidden;
  text-overflow: ellipsis;
  color: ${props => props.theme.titleColor};
`

const ContactDetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  margin-left: 1rem;
  width: calc(100% - 48px);
`

const RowDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
`

class ContactRowRenderer extends Component {
  render () {
    const {
      className,
      rowData,
      onClick,
      style,
      selected,
      onSelected,
      onRemove,
      onClear,
      theme,
      errorFilter,
      onClickSequence
    } = this.props

    const contact = rowData
    const a11yProps = {}

    const name = contact.get('name')
    const validity = contact.get('validity')
    const photoUrl = contact.get('image_url')

    let validMessage
    let validElement

    if (validity === 'GOOD') {
      validMessage = 'Email verified and deliverable'
      validElement = <Verified />
    } else if (validity === 'RISKY') {
      validMessage = 'Email is deliverable but not verified'
      validElement = <Warning />
    } else if (validity === 'INVALID' || validity === 'REJECTED') {
      validMessage = 'Email is invalid and will probably bounce'
      validElement = <Error />
    } else if (validity === 'UNKNOWN') {
      validMessage = 'Email could not be verified'
      validElement = <Unknown />
    }

    const sequenceTitle = contact.getIn(['_campaign', 'title'])

    return (
      <Wrapper
        selected={selected}
        {...a11yProps}
        className={className}
        key={`contact_row_${contact.get('_id')}`}
        role='row'
        style={style}
      >
        <CheckboxContainer onClick={onSelected}>
          <Checkbox
            selected={selected}
            handleClick={onSelected}
          />
        </CheckboxContainer>
        <ContactName onClick={onClick} width='30%'>
          <AlignCenter>
            <Avatar
              email={photoUrl ? null : contact.get('email')}
              maxInitials={2}
              src={photoUrl}
              name={name}
              size={38}
              textSizeRatio={2}
              round
              colors={theme.avatarColors}
            />
            <ContactDetailContainer>
              <RowDetail>
                <TitleContainer
                  color={theme.titleColor}
                  style={{ fontWeight: 700 }}
                >
                  {name}
                </TitleContainer>
              </RowDetail>
              <RowDetail>
                <TitleContainer style={{
                  marginRight: '5px',
                  overflow: 'hidden',
                  textOverflow: 'ellipsis'
                }}
                >
                  {contact.get('email')}
                </TitleContainer>
                {validElement && (
                  <Tooltip
                    // options
                    title={validMessage}
                    position='top'
                    trigger='mouseenter'
                    style={{ marginTop: '2px' }}
                    arrow
                  >
                    {validElement}
                  </Tooltip>)}
              </RowDetail>
            </ContactDetailContainer>
          </AlignCenter>
        </ContactName>
        <ContactName width='20%' onClick={onClickSequence}>
          <SequenceTitle>
            {sequenceTitle}
          </SequenceTitle>
        </ContactName>
        <ContactName width='50%' onClick={onClick}>
          <AlignCenter>
            <StatusActions
              contact={contact}
              errorFilter={errorFilter}
            />
          </AlignCenter>
          <ActionsContainer
            className
            selected={selected}
          >
            <ToolbarButton
              tooltip='Ignore Error'
              icon={['fal', 'eye-slash']}
              onClick={(e) => {
                e.persist()
                e.nativeEvent.stopImmediatePropagation()
                e.stopPropagation()
                e.preventDefault()
                onClear()
              }}
            />
            <ToolbarButton
              tooltip='Remove Contact'
              icon={['fal', 'trash-alt']}
              onClick={(e) => {
                e.persist()
                e.nativeEvent.stopImmediatePropagation()
                e.stopPropagation()
                e.preventDefault()
                onRemove()
              }}
            />
          </ActionsContainer>
        </ContactName>
      </Wrapper>
    )
  }
}

ContactRowRenderer.propTypes = {
  className: PropTypes.string,
  rowData: PropTypes.object,
  onClick: PropTypes.function,
  onClickSequence: PropTypes.function,
  style: PropTypes.object,
  selected: PropTypes.bool,
  onSelected: PropTypes.function,
  onRemove: PropTypes.function,
  theme: PropTypes.object,
  onClear: PropTypes.function,
  errorFilter: PropTypes.string
}

export default ContactRowRenderer
