import { fromJS } from 'immutable'
import {
  FETCH_CRM_LIST_REQUEST,
  FETCH_CRM_LIST_SUCCESS,
  FETCH_CRM_LIST_FAILURE,
  FETCH_CRM_REQUEST,
  FETCH_CRM_SUCCESS,
  FETCH_CRM_FAILURE,
  REFRESH_CRM_REQUEST,
  DELETE_CRM_SUCCESS,
  CREATE_CRM_SUCCESS,
  CREATE_CRM_REQUEST,
  CREATE_CRM_FAILURE,
  UPDATE_CRM_SUCCESS,
  FETCH_SEQUENCE_FIELDS_REQUEST,
  FETCH_SEQUENCE_FIELDS_SUCCESS,
  FETCH_SEQUENCE_FIELDS_FAILURE,

  // Fetch Configured Zaps
  FETCH_ZAPS_REQUEST,
  FETCH_ZAPS_SUCCESS,
  FETCH_ZAPS_FAILURE,

  // Fetch Suggested Zaps
  FETCH_ZAP_TEMPLATES_REQUEST,
  FETCH_ZAP_TEMPLATES_SUCCESS,
  FETCH_ZAP_TEMPLATES_FAILURE,

  DELETE_CRON_SUCCESS,

  FETCH_API_KEY_REQUEST,
  FETCH_API_KEY_SUCCESS,
  FETCH_API_KEY_FAILURE,

  // Clone Settings
  CLONE_SETTINGS_REQUEST,
  CLONE_SETTINGS_SUCCESS,
  CLONE_SETTINGS_FAILURE
} from './constants'

const initialArray = {
  loading: false,
  data: [],
  error: null
}

const initialObject = {
  loading: false,
  data: {},
  error: null
}

const initialState = fromJS({
  crm: {
    loading: false,
    refreshing: false,
    data: {},
    error: null
  },
  crmList: initialArray,
  sequenceFields: initialArray,
  zaps: initialArray,
  zapTemplates: initialArray,
  apiKey: initialObject,
  modifiedSettings: initialObject
})

function integrationReducer (state = initialState, action) {
  switch (action.type) {
    // CRM List
    case FETCH_CRM_LIST_REQUEST:
      return state.set('crmList', state.get('crmList').merge({ loading: true }))
    case FETCH_CRM_LIST_SUCCESS:
      return state.set('crmList', fromJS({
        data: action.payload.crms,
        loading: false,
        error: null
      }))
    case FETCH_CRM_LIST_FAILURE:
      return state.set('crmList', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_CRM_REQUEST:
    case CREATE_CRM_REQUEST:
      return state.set('crm', state.get('crm').merge({ loading: true }))
    case FETCH_CRM_SUCCESS:
    case CREATE_CRM_SUCCESS:
    case UPDATE_CRM_SUCCESS:
      return state.set('crm', fromJS({
        data: action.payload.crm,
        loading: false,
        refreshing: false,
        error: null
      }))
    case FETCH_CRM_FAILURE:
      return state.set('crm', fromJS({
        data: null,
        loading: false,
        refreshing: false,
        error: action.err
      }))

    case REFRESH_CRM_REQUEST:
      return state.set('crm', state.get('crm').merge({ refreshing: true }))

    case CREATE_CRM_FAILURE:
      return state.set('crm', fromJS({
        data: null,
        loading: false,
        refreshing: false,
        error: null
      }))

    case DELETE_CRON_SUCCESS: {
      const id = action.id
      const filteredCrons = state.getIn(['crm', 'data', 'import_crons']).filter(s => (id !== s.get('_id')))
      return state.setIn(['crm', 'data', 'import_crons'], filteredCrons)
    }

    case FETCH_SEQUENCE_FIELDS_REQUEST:
      return state.set('sequenceFields', state.get('sequenceFields').merge({ loading: true }))
    case FETCH_SEQUENCE_FIELDS_SUCCESS:
      return state.set('sequenceFields', fromJS({
        data: action.payload.fields,
        loading: false,
        error: null
      }))
    case FETCH_SEQUENCE_FIELDS_FAILURE:
      return state.set('sequenceFields', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_ZAPS_REQUEST:
      return state.set('zaps', state.get('zaps').merge({ loading: true }))
    case FETCH_ZAPS_SUCCESS:
      return state.set('zaps', fromJS({
        data: action.payload.zaps,
        loading: false,
        error: null
      }))
    case FETCH_ZAPS_FAILURE:
      return state.set('zaps', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_ZAP_TEMPLATES_REQUEST:
      return state.set('zapTemplates', state.get('zapTemplates').merge({ loading: true }))
    case FETCH_ZAP_TEMPLATES_SUCCESS:
      return state.set('zapTemplates', fromJS({
        data: action.payload.zapTemplates,
        loading: false,
        error: null
      }))
    case FETCH_ZAP_TEMPLATES_FAILURE:
      return state.set('zapTemplates', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case DELETE_CRM_SUCCESS:
      return state.set('crm', fromJS({
        data: null,
        loading: false,
        refreshing: false,
        error: null
      }))

    case FETCH_API_KEY_REQUEST:
      return state.set('apiKey', state.get('apiKey').merge({ loading: true }))
    case FETCH_API_KEY_SUCCESS:
      return state.set('apiKey', fromJS({
        data: action.apiKey,
        loading: false,
        error: null
      }))
    case FETCH_API_KEY_FAILURE:
      return state.set('apiKey', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case CLONE_SETTINGS_REQUEST:
      return state.set('modifiedSettings', state.get('modifiedSettings').merge({ loading: true }))
    case CLONE_SETTINGS_SUCCESS:
      return state.set('modifiedSettings', fromJS({
        data: action.payload.modified,
        loading: false,
        error: null
      }))
    case CLONE_SETTINGS_FAILURE:
      return state.set('modifiedSettings', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    default:
      return state
  }
}

export default integrationReducer
