import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import * as Sentry from '@sentry/react'
import Title from 'elements/Title'
import Label from 'elements/Label'
import Button from 'components/Button'
import HanBear from 'svg/HanBear.svg'

const Wrapper = styled.div`
  background-color: ${props => props.theme.colors.darkBlue};
  text-align: center;
  display: flex;
  flex-direction: column;
  padding: .25rem;
  align-items: center;
  justify-content: center;
  min-height: 500px;
  min-width: 500px;
  height: 100vh;
  width: 100vw;
  position: relative;
`

const Floor = styled.div`
  background-color: ${props => props.theme.colors.darkestBlue};
  position: absolute;
  height: 15%;
  bottom: 0;
  left: 0;
  right: 0;
`

const QuoteWrapper = styled.div`
  position: absolute;
  left: 60%;
  display: flex;
  flex-direction: column;
  width: 30%;
  max-width: 350px;
  min-width: 300px;
`

const ComponentError = ({
  message = 'We\'re sorry — something\'s gone wrong. Our team has been notified!',
  ...rest
}) => (
  <Wrapper {...rest}>
    <img
      src={HanBear}
      alt='Error Bear'
      style={{
        height: '149%',
        position: 'absolute',
        top: 0,
        right: '5vw'
      }}
    />
    <Floor />
    <QuoteWrapper>
      <Title align='center' color='#FFF'>
        WHOOPS, 500!
      </Title>
      <Label mb='1rem' align='center' color='#FFF'>
        {message}
      </Label>
      <Button
        label='Submit Report'
        primary
        width='60%'
        ml='auto'
        mr='auto'
        mb='1rem'
        onClick={() => {
          Sentry.showReportDialog({})
        }}
      />
      <Button
        label='Go Back'
        width='45%'
        ml='auto'
        mr='auto'
        bg='white'
        onClick={() => {
          // window.location = window.location.href;
          window.history.go(-1)
        }}
      />
    </QuoteWrapper>
  </Wrapper>
)

ComponentError.propTypes = {
  message: PropTypes.string
}

export default ComponentError
