import React from 'react'
import PropTypes from 'prop-types'

import NumberFormat from 'react-number-format'

import {
  TooltipLabel,
  TooltipValue,
  TooltipContainer,
  TooltipUnitLabel
} from 'containers/Reports/styles'

class GraphTooltip extends React.Component {
  render () {
    const { active } = this.props

    if (active) {
      const { payload, unitLabel, unitLabelPlural } = this.props
      let label = this.props.label
      const value = payload[0].value
      const displayUnitLabel = value === 1 ? unitLabel : unitLabelPlural
      /* eslint-disable-next-line */
      const trendValue = (!!payload[1]) ? payload[1].value : null

      if (payload[0] && payload[0].payload && payload[0].payload.label) {
        label = payload[0].payload.label
      }

      return (
        <TooltipContainer>
          <TooltipValue>
            <NumberFormat
              value={value}
              displayType='text'
              thousandSeparator=','
            /> <TooltipUnitLabel>{displayUnitLabel}</TooltipUnitLabel>
          </TooltipValue>
          <TooltipLabel>{label}</TooltipLabel>
          {!!trendValue && <TooltipLabel>&mdash;<br />7 day average: {trendValue}</TooltipLabel>}
        </TooltipContainer>
      )
    }

    return null
  }
}

GraphTooltip.propTypes = {
  type: PropTypes.string,
  label: PropTypes.string,
  payload: PropTypes.array,
  active: PropTypes.bool,
  unitLabelPlural: PropTypes.string,
  unitLabel: PropTypes.string
}

export default GraphTooltip
