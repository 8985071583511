import React, { PureComponent } from 'react'
import R from 'utils/ramda'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled, { withTheme } from 'styled-components'
import SlateEditor from 'components/SlateEditor'
import EmptyState from 'components/EmptyState'
import ConfirmModal from 'components/ConfirmModal'
import Loading from 'components/Loading'
import DropDown from 'components/DropDown'
import Label from 'elements/Label'
import { Satellite } from 'svg'
import api from 'utils/api'
import StepList from './StepList'
import EditorStatusBar from './EditorStatusBar'
import { SLATE_EDITORS } from 'components/SlateEditor/utils/slate/constants'

const Wrapper = styled.div`
  transition: background-color 0.3s ease, color .3s ease;
  padding: 0 2rem;
  padding-bottom: 3rem;
  display: grid;
  grid-auto-flow: column;
  grid-template-columns: 45% 55%;
  grid-template-rows: 100%;
  align-items: start;
`

const StepsContainer = styled.div`
  height: 100%;
  margin-right: .5rem;
`

const EditorContainer = styled.div`
  margin-top: 1rem;
  border: 1px solid ${props => props.theme.colors.gray30};
`

const LoadingContainer = styled.div`
  height: 100%;
`

const PreviewEditorContainer = styled.div``

const EmptyWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  min-height: 50vh;
  box-shadow: ${props => (props.shadow ? props.theme.shadow : 'none')};
  padding: 2rem;
  margin: 2rem 2rem;
`

const ContactWrapper = styled.div`
  padding: 1.5rem;
  margin-top: 2rem;
  background-color: ${props => props.theme.colors.gray10};
  border: 1px solid ${props => props.theme.colors.gray30};
`

const ContactDetail = styled.div`
  margin-top: 1rem;
`

class Preview extends PureComponent {
  constructor (props) {
    super(props)

    this.state = {
      selectedStepIndex: 0,
      selectedContactId: null,
      testSelected: false,
      showPersonalized: true
    }
  }

  draftPending = false

  togglePersonalized = () => {
    const { showPersonalized } = this.state
    this.setState({ showPersonalized: !showPersonalized })

    const query = this.props.location.query
    query.customize = !showPersonalized

    if (!query.customize) {
      delete query.customize
    }

    this.props.router.push({
      ...this.props.location,
      query
    })
  }

  UNSAFE_componentWillMount () {
    this.props.router.setRouteLeaveHook(this.props.route, (nextLocation) => {
      if (this.checkPendingChange()) {
        this.setState({
          nextLocation
        })
        return false
      }
      return true
    })

    const query = this.props.location.query || {}
    if (query.step) {
      this.setState({
        selectedStepIndex: Number.parseInt(query.step, 10)
      })
    }
    const sequenceId = this.props.params.id
    this.props.actions.fetchPreviewContacts(sequenceId)
    this.fetchPreviewForContact(query.contactId)
  }

  componentWillUnmount () {
    this.props.actions.fetchContactMessagesReset()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.selectedContact) {
      const domain = nextProps.selectedContact.getIn(['data', 'domain'])
      const oldDomain = this.props.selectedContact ? this.props.selectedContact.getIn(['data', 'domain']) : null
      if (domain !== oldDomain) {
        this.props.actions.fetchContactCompany(domain)
      }
    }
  }

  updateQueryParams = (contactId, step) => {
    const query = {
      contactId: this.props.location.query.contactId,
      step: this.props.location.query.step,
      customize: this.props.location.query.customize
    }

    if (contactId) {
      query.contactId = contactId
    } else {
      delete query.contactId
    }

    if (step !== null && step !== undefined) {
      query.step = step
    }

    if (!R.equals(query, this.props.location.query)) {
      this.props.router.push({
        ...this.props.location,
        query
      })
    }
  }

  checkPendingChange = () => {
    const {
      showSaveChangesModal
    } = this.state

    if (this.editor && !showSaveChangesModal && this.draftPending) {
      this.setState({
        showSaveChangesModal: true
      })
      return true
    }

    return false
  }

  fetchPreviewForContact = (contactId) => {
    const {
      actions
    } = this.props

    this.updateQueryParams(contactId)

    this.setState({
      selectedContactId: contactId
    })

    const sequenceId = this.props.params.id
    if (contactId) {
      actions.fetchSequencePreview(sequenceId, contactId)
      actions.fetchContact(sequenceId, contactId)
      actions.fetchContactSteps(sequenceId, contactId)
      try { api.get(`/contacts/${contactId}/touch`) } catch (e) {}
    } else {
      actions.fetchSequencePreview(sequenceId)
      actions.fetchContactStepsReset()
    }
  }

  updateSelectedStep = (step, testSelected) => {
    this.updateQueryParams(this.state.selectedContactId, step)
    this.setState({
      selectedStepIndex: step,
      testSelected
    })
  }

  sendTestEmail = (email) => {
    const {
      actions,
      sequence
    } = this.props

    const {
      selectedContactId,
      selectedStepIndex,
      testSelected,
      showPersonalized
    } = this.state

    const sequenceId = sequence.get('_id')
    const steps = sequence.get('steps')
    let selectedStep = steps.get(selectedStepIndex)

    // choose the test step instead if we have it selected
    if (testSelected) {
      selectedStep = sequence
        .get('other_steps')
        .find(s => s.get('_step') === selectedStep.get('_id'))
    }

    const stepId = selectedStep.get('_id')

    const params = {
      contactId: selectedContactId,
      step: selectedStepIndex,
      email,
      stepId
    }
    actions.sendTestEmail(sequenceId, selectedContactId, selectedStepIndex, stepId, showPersonalized, params)

    this.setState({
      showSendEmailModal: false
    })
  }

  getStepsByIndex = (steps, selectedStepIndex) => {
    const byStep = R.groupBy(option => (option.get('step')))
    const stepsMap = byStep(steps)
    return stepsMap.get(selectedStepIndex)
  }

  getSelectedStep = (steps, selectedStepIndex, testSelected) => {
    const selectedStepGroup = this.getStepsByIndex(steps, selectedStepIndex)

    if (!selectedStepGroup) {
      return null
    }

    if (testSelected) {
      return selectedStepGroup.last()
    }

    return selectedStepGroup.first()
  }

  saveContactStep = (selectedContactId, selectedStep, subject, markdown) => {
    const selectedStepIndex = selectedStep.get('step')
    const selectedStepType = selectedStep.get('type')

    this.props.actions.updateContactStep(selectedContactId, selectedStepType, selectedStepIndex, subject, markdown)
    this.draftPending = false
  }

  clearContactStep = (selectedContactId, selectedStep) => {
    const selectedStepIndex = selectedStep.get('step')
    const selectedStepType = selectedStep.get('type')

    this.props.actions.removeContactStep(selectedContactId, selectedStepType, selectedStepIndex)
  }

  onContactSelected = (contactId) => {
    if (this.checkPendingChange()) {
      this.setState({
        pendingCall: () => { this.fetchPreviewForContact(contactId) }
      })
    } else {
      this.fetchPreviewForContact(contactId)
    }
  }

  onOptionChange = (option) => {
    if (option) {
      this.setState({
        selectedContactId: option.value,
        selectedContactIndex: option.index
      })
      this.onContactSelected(option.value)
    } else {
      this.setState({
        selectedContactId: null,
        selectedContactIndex: null
      })
      this.onContactSelected(null)
    }
  }

  render () {
    const {
      session,
      sequence,
      stepStats,
      previewContacts,
      selectedContact,
      contactSteps,
      previewSteps,
      theme
    } = this.props
    const {
      selectedContactId,
      selectedStepIndex,
      showSaveChangesModal,
      testSelected,
      showRemoveMessageModal,
      showPersonalized
    } = this.state

    const steps = sequence.get('steps')
    if (!steps || steps.count() < 1) {
      return (
        <EmptyWrapper>
          <EmptyState
            icon={<Satellite />}
            title='Nothing to preview'
            description='Add steps and contacts to your sequence to preview and customize the message'
          />
        </EmptyWrapper>
      )
    }

    // get the contact's name
    let contactName
    if (selectedContact) {
      contactName = selectedContact.getIn(['data', 'parsed_name', 'full']) || selectedContact.getIn(['data', 'email'])
    }

    const stepsLoading = previewSteps.get('loading') || contactSteps.get('loading')

    const previewStep = this.getSelectedStep(previewSteps.get('data'), selectedStepIndex, testSelected)
    let contactStep = this.getSelectedStep(contactSteps.get('data'), selectedStepIndex)

    // choose what to display to the editor
    let subject
    let body
    let readOnly = false
    let hideToolbar = false
    let newThread = false
    let hideSubjectLine = false
    let backgroundColor = null
    let hideSendTestEmail = false
    let showClearMessage = false

    // don't show contactSteps if we're not viewing personalized emails
    // or emails that have already been sent
    if (!showPersonalized) {
      contactStep = null
    }

    // contact steps are emails that are directly related to a contact
    // and is either a sent email, completed task, an upcoming email,
    // or an upcoming personalized email
    //
    // previewSteps are directly rendered emails from the sequence itself
    // and does not care for emails that have been sent
    if (contactStep) {
      if (contactStep.get('fulfilled')) {
        readOnly = true
        hideToolbar = true
      }

      if (contactStep.get('type') === 'message') {
        const message = contactStep.get('object')
        newThread = message.has('subject') && !message.get('sent')
      }
    } else if (previewStep) {
      readOnly = true
      // tasks do not have the option to send a test email
      if (previewStep.get('type') === 'task') {
        hideToolbar = true
      }
    }

    const selectedStep = contactStep || previewStep
    if (selectedStep) {
      switch (selectedStep.get('type')) {
        // FIX eslint error
        // https://eslint.org/docs/rules/no-case-declarations
        case 'message': {
          const message = selectedStep.get('object')
          subject = message.get('subject') || message.get('previous_subject')
          body = message.get('markdown')
          showClearMessage = message.get('type') === 'manual' && !message.get('sent_at')
          break
        }
        case 'task': {
          const task = selectedStep.get('object')
          body = task.get('markdown')

          hideSubjectLine = true
          hideSendTestEmail = true
          readOnly = true
          hideToolbar = true
          backgroundColor = theme.colors.lightYellow
          break
        }
      }
    }
    const displayRemoveMessageModal = () => {
      this.setState({
        showRemoveMessageModal: true
      })
    }

    const signature = selectedStep?.getIn(['object', 'signature'])
      ? selectedStep.getIn(['object', 'signature'])
      : null

    const isAlias = selectedStep?.get('gmail_send_as')
      ? !!selectedStep.get('gmail_send_as')
      : false

    const isManualTask = selectedStep?.get('type') === 'task'

    const showSignaturePreview = signature && !isAlias && !isManualTask

    const contactOptions = Immutable.List([
      {
        label: null,
        value: null,
        index: 0
      }
    ]).concat(previewContacts?.get('data').map((contact, i) => (
      {
        label: contact.get('name'),
        value: contact.get('_id'),
        index: i + 1
      }
    )))

    return (
      <Wrapper>
        <StepsContainer>
          <StepList
            contacts={previewContacts.get('data')}
            sequence={sequence}
            stepStats={stepStats}
            contactSteps={contactSteps}
            previewSteps={previewSteps}
            selectedContactId={selectedContactId}
            selectedStepIndex={selectedStepIndex}
            testSelected={testSelected}
            togglePersonalized={this.togglePersonalized}
            showPersonalized={showPersonalized}
            onStepSelected={(index, testStepSelected) => {
              if (this.checkPendingChange()) {
                this.setState({
                  pendingCall: () => { this.updateSelectedStep(index, testStepSelected) }
                })
              } else {
                this.updateSelectedStep(index, testStepSelected)
              }
            }}
          />
        </StepsContainer>

        {stepsLoading && <LoadingContainer><Loading /></LoadingContainer>}

        <PreviewEditorContainer>
          {!stepsLoading &&
            <ContactWrapper>
              <DropDown
                label='Preview message as contact'
                inputId='contact'
                options={contactOptions}
                clearable
                searchable
                controlled
                placeholder='Select a contact to preview this message as...'
                value={selectedContactId}
                onOptionChange={this.onOptionChange}
              />

              {!selectedContactId &&
                <ContactDetail>
                  <Label>You are currently previewing the message with variables filled in as yourself. Not all variables may be filled out below. Switch to a contact above to preview message as a contact.</Label>
                </ContactDetail>}
            </ContactWrapper>}

          {!stepsLoading &&
            <EditorContainer>
              <EditorStatusBar
                selectedContact={selectedContactId && selectedContact}
                selectedStep={selectedStep}
                showPersonalized={showPersonalized}
              />

              <SlateEditor
                innerRef={element => (this.editor = element)}
                session={session}
                steps={sequence.get('steps')}
                subject={subject}
                body={body}
                readOnly={readOnly}
                subjectDisabled={!newThread}
                hideSubjectLine={hideSubjectLine}
                hideToolbar={hideToolbar}
                hideVariables
                hideEditorCoach
                backgroundColor={backgroundColor}
                isBrandedDomain={session.get('branded_domain_enabled')}
                onSendTestEmail={!hideSendTestEmail && this.sendTestEmail}
                hasEnteredKeyStroke={() => {
                  this.draftPending = true
                }}
                onSave={() => {
                  const subject = newThread ? this.editor.getMarkdown(SLATE_EDITORS.SUBJECT) : null
                  const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)
                  return this.saveContactStep(selectedContactId, selectedStep, subject, markdown)
                }}
                onTestEmail={() => {
                  this.setState({
                    showSendEmailModal: true
                  })
                }}
                onRemove={showClearMessage && displayRemoveMessageModal}
                showSignature={showSignaturePreview}
                signature={signature}
                selectedStep={selectedStep}
              />
            </EditorContainer>}
        </PreviewEditorContainer>

        <ConfirmModal
          isOpen={showRemoveMessageModal}
          onCancel={() => {
            this.setState({
              showRemoveMessageModal: false
            })
          }}
          onConfirm={() => {
            this.clearContactStep(selectedContactId, contactStep)
            this.setState({
              showRemoveMessageModal: false
            })
          }}
          cancelLabel='Cancel'
          confirmLabel='Clear Personalized Message'
          title='Clear Personalized Message'
          description={`Are you sure you want to clear this personalized message for ${contactName}? This will reset the message to the current sequence step's template.`}
        />
        <ConfirmModal
          isOpen={showSaveChangesModal}
          onCancel={() => {
            const {
              nextLocation,
              pendingCall
            } = this.state
            if (nextLocation) {
              this.props.router.push(this.state.nextLocation)
            } else if (pendingCall) {
              pendingCall()
            }
            this.setState({
              nextLocation: null,
              pendingCall: null,
              showSaveChangesModal: false
            })
            this.draftPending = false
          }}
          onConfirm={() => {
            const subject = newThread ? this.editor.getMarkdown(SLATE_EDITORS.SUBJECT) : null
            const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)
            this.saveContactStep(selectedContactId, selectedStep, subject, markdown)
            setTimeout(() => {
              const {
                nextLocation,
                pendingCall
              } = this.state
              if (nextLocation) {
                this.props.router.push(this.state.nextLocation)
              } else if (pendingCall) {
                pendingCall()
              }
              this.setState({
                nextLocation: null,
                pendingCall: null,
                showSaveChangesModal: false
              })
              this.draftPending = false
            }, 1000)
          }}
          cancelLabel='Discard Changes'
          confirmLabel='Save Changes'
          title='Unsaved changes'
          description='Do you want to save your customizations to the message?'
        />
      </Wrapper>
    )
  }
}

Preview.propTypes = {
  sequence: PropTypes.object,
  stepStats: PropTypes.object,
  actions: PropTypes.object,
  preview: PropTypes.object,
  location: PropTypes.object,
  router: PropTypes.object,
  route: PropTypes.any,
  params: PropTypes.object,
  previewContacts: PropTypes.object,
  selectedContact: PropTypes.object,
  contactSteps: PropTypes.object,
  session: PropTypes.object,
  theme: PropTypes.object,
  previewSteps: PropTypes.object
}

export default withTheme(Preview)
