import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { MenuHeader, MenuItem } from 'elements/Menu'
import Curtain from '../ToolbarModal/Curtain'
import { IMAGE_MENUS } from 'components/SlateEditor/utils/slate/constants'

const Wrapper = styled.div``

const DropDownMenu = styled.div`
  z-index: 20;
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  overflow-y: auto;
  width: 200px;
  max-height: 380px;
  text-align: left;
  margin-top: 1rem;
  padding: 0.5rem;
  box-shadow: ${props => props.theme.shadow};
`

const ListHeader = styled(MenuHeader)``

const ListItem = styled(MenuItem)`
  white-space: pre-wrap;
  line-height: 20px;
  padding: 0.3rem 0.5rem;
  z-index: 20;
  user-select: none;
`

const HorizontailDivider = styled.div`
  padding: 0.5rem 0rem;
`

class EllipsesMenu extends React.Component {
  constructor (props) {
    super(props)

    const {
      onShowTemplates,
      onSaveTemplate,
      toggleToolbarMenuVisibility,
      onToggleSignature,
      isSignatureDisabled,
      setToolbarMenuVisibility
    } = this.props

    this.state = {
      templateFields: [
        {
          title: 'Use Template',
          handleOnClick: () => {
            toggleToolbarMenuVisibility('showEllipsesMenu')
            onShowTemplates()
          }
        },
        {
          title: 'Save Template',
          handleOnClick: () => {
            toggleToolbarMenuVisibility('showEllipsesMenu')
            onSaveTemplate()
          }
        }
      ],
      imageAndAttachmentFields: [
        {
          title: 'Add Attachment',
          handleOnClick: () => {
            toggleToolbarMenuVisibility('showEllipsesMenu')
            toggleToolbarMenuVisibility('showAttachmentsMenu')
          }
        },
        {
          title: 'Add Image URL',
          handleOnClick: () => {
            toggleToolbarMenuVisibility('showEllipsesMenu')
            setToolbarMenuVisibility('showImageMenu', IMAGE_MENUS.URL)
          }
        },
        {
          title: 'Upload Image',
          handleOnClick: () => {
            toggleToolbarMenuVisibility('showEllipsesMenu')
            setToolbarMenuVisibility('showImageMenu', IMAGE_MENUS.DRAG_N_DROP)
          }
        },
        {
          title: 'Add Video',
          handleOnClick: () => {
            toggleToolbarMenuVisibility('showEllipsesMenu')
            toggleToolbarMenuVisibility('showVideoLinkMenu')
          }
        }
      ],
      extrasFields: [
        {
          title: 'Add Unsubscribe Link',
          handleOnClick: () => {
            toggleToolbarMenuVisibility('showEllipsesMenu')
            toggleToolbarMenuVisibility('showUnsubscribeMenu')
          }
        },
        {
          title: `${isSignatureDisabled ? 'Enable' : 'Disable'} Signature`,
          handleOnClick: () => {
            toggleToolbarMenuVisibility('showEllipsesMenu')
            onToggleSignature()
          }
        }
      ]
    }

    this.ellipsesMenuRef = React.createRef()
    this.handleEscapeKey = this.handleEscapeKey.bind(this)
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEscapeKey, false)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEscapeKey, false)
  }

  handleEscapeKey = event => {
    const ESCAPE_KEY = 27
    const { toggleToolbarMenuVisibility } = this.props
    if (event.keyCode === ESCAPE_KEY) {
      toggleToolbarMenuVisibility('showEllipsesMenu')
    }
  }

  renderTemplateFields = () => {
    const { templateFields } = this.state

    return templateFields.map(field => {
      return (
        <ListItem
          key={field.title}
          onClick={field.handleOnClick}
        >
          {field.title}
        </ListItem>
      )
    })
  }

  renderImagesAndAttachmentsFields = () => {
    const { imageAndAttachmentFields } = this.state
    return imageAndAttachmentFields.map(field => {
      return (
        <ListItem
          key={field.title}
          onClick={field.handleOnClick}
        >
          {field.title}
        </ListItem>
      )
    })
  }

  renderExtrasFields = () => {
    const { extrasFields } = this.state
    const { showSignature } = this.props

    let fields = extrasFields

    if (!showSignature) {
      fields = extrasFields.filter(field => !field.title.toLowerCase().includes('signature'))
    }

    return fields.map(field => {
      return (
        <ListItem
          key={field.title}
          onClick={field.handleOnClick}
        >
          {field.title}
        </ListItem>
      )
    })
  }

  render () {
    const {
      onShowTemplates,
      onSaveTemplate,
      hideVariables,
      hideTemplates,
      toggleToolbarMenuVisibility
    } = this.props

    return (
      <Wrapper ref={this.ellipsesMenuRef}>
        <Curtain
          onClick={() => toggleToolbarMenuVisibility('showEllipsesMenu')}
        />
        <DropDownMenu>
          {!hideTemplates && onShowTemplates && onSaveTemplate &&
            <>
              <ListHeader>Templates</ListHeader>
              {this.renderTemplateFields()}
              <HorizontailDivider />
            </>}

          <ListHeader>Images & Attachments</ListHeader>
          {this.renderImagesAndAttachmentsFields()}

          {!hideVariables &&
            <>
              <HorizontailDivider />
              <ListHeader>Extras</ListHeader>
              {this.renderExtrasFields()}
            </>}
        </DropDownMenu>
      </Wrapper>
    )
  }
}

EllipsesMenu.propTypes = {
  // existed in old editor and are imported different from all other buttons
  onShowTemplates: PropTypes.func,
  onSaveTemplate: PropTypes.func,

  hideVariables: PropTypes.bool,
  hideTemplates: PropTypes.bool,
  getActiveEdtior: PropTypes.func,
  toggleToolbarMenuVisibility: PropTypes.func,

  // Signature
  showSignature: PropTypes.bool,
  onToggleSignature: PropTypes.func,
  isSignatureDisabled: PropTypes.bool,
  setToolbarMenuVisibility: PropTypes.func
}

export default EllipsesMenu
