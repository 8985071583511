import React from 'react'

/* eslint-disable */
const RocketCrash = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 143} height={height || 117} viewBox="0 0 143 117">
    <defs>
        <linearGradient x1="0.0381927711%" y1="49.9573494%" x2="99.9825301%" y2="49.9573494%" id="linearGradient-1">
            <stop stopColor="#EAF0FA" offset="0%"></stop>
            <stop stopColor="#D1DEF5" offset="78.2%"></stop>
            <stop stopColor="#C9D8F4" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="0.0219066937%" y1="49.9502024%" x2="100.08641%" y2="49.9502024%" id="linearGradient-2">
            <stop stopColor="#EAF0FA" offset="0%"></stop>
            <stop stopColor="#D1DEF5" offset="78.2%"></stop>
            <stop stopColor="#C9D8F4" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="0.0251521298%" y1="49.9502024%" x2="100.089655%" y2="49.9502024%" id="linearGradient-3">
            <stop stopColor="#EAF0FA" offset="0%"></stop>
            <stop stopColor="#D1DEF5" offset="78.2%"></stop>
            <stop stopColor="#C9D8F4" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-0.061440678%" y1="49.8050847%" x2="99.8317797%" y2="49.8050847%" id="linearGradient-4">
            <stop stopColor="#EAF0FA" offset="0%"></stop>
            <stop stopColor="#D1DEF5" offset="78.2%"></stop>
            <stop stopColor="#C9D8F4" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="0.105932203%" y1="49.8050847%" x2="99.9991525%" y2="49.8050847%" id="linearGradient-5">
            <stop stopColor="#EAF0FA" offset="0%"></stop>
            <stop stopColor="#D1DEF5" offset="78.2%"></stop>
            <stop stopColor="#C9D8F4" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-1.5778125%" y1="49.6465753%" x2="101.948438%" y2="49.6465753%" id="linearGradient-6">
            <stop stopColor="#F1F5FC" offset="0%"></stop>
            <stop stopColor="#D8E3F7" offset="65.78%"></stop>
            <stop stopColor="#C9D8F4" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-1.26582278%" y1="50.100813%" x2="101.429325%" y2="50.100813%" id="linearGradient-7">
            <stop stopColor="#F1F5FC" offset="0%"></stop>
            <stop stopColor="#D8E3F7" offset="65.78%"></stop>
            <stop stopColor="#C9D8F4" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="49.9820097%" y1="100.313825%" x2="49.9820097%" y2="0%" id="linearGradient-8">
            <stop stopColor="#EAF0FA" offset="0%"></stop>
            <stop stopColor="#D1DEF5" offset="78.2%"></stop>
            <stop stopColor="#C9D8F4" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="Playground" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="small-error-final">
            <g id="Group" opacity="0.38" transform="translate(1.000000, 69.000000)" fillRule="nonzero">
                <polygon id="Shape" fill="url(#linearGradient-1)" points="76.3 0.5 117.8 42 34.8 42"></polygon>
                <polygon id="Shape" fill="url(#linearGradient-2)" points="109.3 17.3 133.9 42 84.6 42"></polygon>
                <polygon id="Shape" fill="url(#linearGradient-3)" points="37.1 17.3 61.7 42 12.4 42"></polygon>
                <polygon id="Shape" fill="url(#linearGradient-4)" points="129.6 30.2 141.4 42 117.8 42"></polygon>
                <polygon id="Shape" fill="url(#linearGradient-5)" points="12.4 30.2 24.2 42 0.6 42"></polygon>
            </g>
            <path d="M132.5,57.9 C132.2,55.9 130.4,54.4 128.3,54.4 C127,54.4 125.9,55 125.1,55.9 C124,52.8 121,50.6 117.5,50.6 C114.8,50.6 112.4,51.9 111,53.9 C109.9,52.9 108.4,52.3 106.8,52.3 C103.5,52.3 100.8,54.8 100.5,57.9 L132.5,57.9 Z" id="Shape" stroke="url(#linearGradient-6)"></path>
            <path d="M48,71.6 C46.4,67.3 42.3,64.3 37.4,64.3 C34.7,64.3 32.2,65.3 30.2,66.9 C27.3,62.4 22.2,59.3 16.4,59.3 C8.8,59.3 2.4,64.5 0.6,71.6 L48,71.6 Z" id="Shape" stroke="url(#linearGradient-7)"></path>
            <rect id="Rectangle-path" fill="#DDCCA2" fillRule="nonzero" transform="translate(100.580220, 71.569600) rotate(-45.000000) translate(-100.580220, -71.569600) " x="98.5802392" y="69.5696192" width="3.99996164" height="3.99996164"></rect>
            <rect id="Rectangle-path" fill="#DDCCA2" fillRule="nonzero" transform="translate(9.084440, 35.330420) rotate(-45.000000) translate(-9.084440, -35.330420) " x="7.08445918" y="33.3304392" width="3.99996164" height="3.99996164"></rect>
            <g id="Group" transform="translate(114.000000, 13.000000)" stroke="#DDCCA2" strokeLinecap="round" strokeWidth="2">
                <path d="M3,0.4 L3,6.1" id="Shape"></path>
                <path d="M5.8,3.3 L0.2,3.3" id="Shape"></path>
            </g>
            <path d="M97.5,28.7 C97.5,26.8 96.6,25 95.1,23.9 C95.8,22.4 96.2,20.8 96.2,19 C96.2,12.7 91.1,7.7 84.9,7.7 C83.7,7.7 82.5,7.9 81.4,8.2 C81.1,3.6 77.3,0 72.6,0 C69,0 65.9,2.2 64.5,5.3 C63.7,4.9 62.7,4.6 61.7,4.6 C58.7,4.6 56.3,6.8 55.8,9.6 C54.2,8.7 52.3,8.2 50.4,8.2 C44.1,8.2 39.1,13.3 39.1,19.5 C39.1,21 39.4,22.4 39.9,23.7 C37.4,25.3 35.8,28.1 35.8,31.3 C35.8,36.3 39.9,40.4 44.9,40.4 C45.9,40.4 46.9,40.2 47.8,39.9 C47.4,40.7 47.2,41.6 47.2,42.5 C47.2,45.8 49.9,48.5 53.2,48.5 C54.1,48.5 55,48.3 55.8,47.9 C56.2,49 57.2,49.7 58.4,49.7 C58.7,49.7 59,49.7 59.2,49.6 C58.7,50.3 58.4,51.1 58.4,52.1 C58.4,54.4 60.3,56.3 62.6,56.3 C62.6,56.3 62.6,56.3 62.6,56.3 L63.6,65.1 L69.3,65.1 L70.3,58.3 C70.3,58.3 70.4,58.3 70.4,58.3 C72.5,58.3 74.3,56.6 74.3,54.4 C74.3,53.5 74,52.7 73.5,52 C73.9,52.3 74.5,52.5 75.1,52.5 C76.6,52.5 77.8,51.3 77.8,49.8 C77.8,49.6 77.8,49.3 77.7,49.1 C79.2,49 80.4,48 80.7,46.6 C82,47.4 83.4,47.8 85,47.8 C89.6,47.8 93.3,44.1 93.3,39.5 C93.3,37.6 92.6,35.8 91.6,34.4 C91.6,34.4 91.7,34.4 91.7,34.4 C94.8,34.7 97.5,32 97.5,28.7 Z" id="Shape" fill="url(#linearGradient-8)" fillRule="nonzero"></path>
            <g id="Group" opacity="0.67" transform="translate(50.000000, 28.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M8.3,20.1 C8.2,19.2 8.1,18.3 8,17.4 L7.8,16 C7.7,15.6 7.7,15.1 7.6,14.7 C7.4,13.9 7.1,13.3 6.5,12.8 C6.2,12.5 6,12.3 5.6,12.1 L4.4,11.4 C4,11.2 3.6,10.9 3.2,10.7 L2.9,10.5 L2.5,10.3 C2.3,10.1 2,10 1.8,9.8 C0.9,9 0.3,7.8 0.2,6.6 C0.2,6.3 0.2,6 0.2,5.7 C0.2,5.4 0.3,5.2 0.3,5 C0.4,4.5 0.5,4.1 0.6,3.6 C0.8,2.7 1.1,1.8 1.5,0.9 L2,0.9 C2.4,1.8 2.7,2.7 2.9,3.6 C3,4.1 3.1,4.5 3.2,5 C3.2,5.2 3.3,5.5 3.3,5.6 C3.3,5.8 3.4,5.9 3.4,6.1 C3.6,6.7 3.9,7.2 4.2,7.7 C4.3,7.8 4.4,8 4.5,8.1 L4.7,8.3 L5,8.5 C5.3,8.8 5.7,9.1 6,9.4 L7.1,10.3 C7.5,10.6 7.9,11 8.2,11.5 C8.8,12.4 9.2,13.5 9.2,14.5 C9.2,15.1 9.1,15.5 9.1,15.9 L9,17.3 C8.9,18.2 8.8,19.1 8.7,20 L8.3,20 L8.3,20.1 Z" id="Shape"></path>
            </g>
            <g id="Group" opacity="0.67" transform="translate(65.000000, 6.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M1.1,48.2 L0.8,44.9 L0.7,43.3 C0.7,42.8 0.6,42.1 0.7,41.5 C0.8,40.3 1.2,39.1 1.8,38 C2.1,37.5 2.5,36.9 2.9,36.5 C3.2,36.1 3.5,35.8 3.7,35.3 C4.6,33.7 4.7,31.7 4.1,30.1 C4,29.7 3.8,29.3 3.6,28.9 C3.3,28.4 3,27.8 2.8,27.3 C2.3,26.2 1.9,25.1 1.6,23.9 C1,21.6 0.7,19.1 0.8,16.6 C0.9,14.1 1.7,11.7 2.9,9.5 C3.5,8.4 4.3,7.4 5.1,6.5 C5.8,5.7 6.6,4.9 7.4,4.1 C9,2.8 10.6,1.3 12.5,0 L12.9,0.3 C12.2,2.5 11.3,4.5 10.2,6.5 C9.7,7.5 9.2,8.5 8.6,9.4 C8.1,10.3 7.7,11.1 7.4,12 C6.7,13.8 6.1,15.6 5.7,17.4 C5.3,19.3 5.2,21.2 5.5,23.2 C5.6,24.2 5.9,25.1 6.2,26.1 C6.3,26.6 6.6,27 6.7,27.5 C7,28.1 7.2,28.7 7.3,29.4 C7.9,32 7.2,34.7 5.7,36.8 C5.3,37.3 4.9,37.8 4.4,38.2 C4,38.5 3.7,38.8 3.4,39.2 C2.8,40 2.4,40.8 2.1,41.8 C1.9,42.7 1.9,43.8 1.8,44.9 L1.5,48.2 L1.1,48.2 Z" id="Shape"></path>
            </g>
            <g id="Group" opacity="0.67" transform="translate(81.000000, 17.000000)" fill="#FFFFFF" fillRule="nonzero">
                <path d="M0.7,11.9 C0.4,10.8 0.6,9.4 1.3,8.3 C1.6,7.7 2.1,7.2 2.7,6.8 C3.4,6.4 3.6,6.2 3.9,5.9 C4.2,5.6 4.4,5.2 4.5,4.8 C4.6,4.4 4.7,4.1 4.9,3.7 C5,3.5 5.1,3.3 5.1,3.1 C5.2,2.8 5.3,2.5 5.4,2.3 C5.6,1.7 5.9,1.2 6.3,0.6 L6.6,0.6 C7,1.2 7.3,1.7 7.5,2.3 C7.6,2.6 7.7,2.9 7.8,3.2 C7.9,3.6 7.9,3.9 7.9,4.3 C7.9,5.1 7.6,5.8 7.1,6.4 C6.6,7 6,7.5 5.4,7.8 C4.8,8.2 4,8.4 3.7,8.6 C3.3,8.8 2.9,9 2.5,9.3 C1.7,9.9 1.2,10.9 1,12 L0.7,12 L0.7,11.9 Z" id="Shape"></path>
            </g>
            <g id="Group" transform="translate(41.000000, 66.000000)">
                <g transform="translate(10.000000, 0.000000)" fillRule="nonzero">
                    <polygon id="Shape" fill="#0F3567" points="18.2 34.4 13.1 34.4 12.4 30.2 18.9 30.2"></polygon>
                    <g transform="translate(0.000000, 22.000000)" fill="#0F3567" id="Shape">
                        <path d="M12.3,4.2 L2.5,6.7 L3.7,5.1 L3.3,19.1 L0.8,19.1 L0.4,5.1 C0.4,4.4 0.8,3.7 1.5,3.5 L1.6,3.5 L11.2,0.4 L12.3,4.2 Z"></path>
                        <path d="M20.1,0.4 L29.7,3.5 L29.8,3.5 C30.5,3.7 30.9,4.4 30.9,5.1 L30.5,19.1 L28,19.1 L27.6,5.1 L28.8,6.7 L19,4.2 L20.1,0.4 Z"></path>
                    </g>
                    <rect id="Rectangle-path" fill="#EAEEF6" x="10" y="3.3" width="11.3" height="27"></rect>
                    <circle id="Oval" fill="#6E98F0" cx="15.6" cy="9.4" r="1.8"></circle>
                    <circle id="Oval" fill="#6E98F0" cx="15.6" cy="17.1" r="1.8"></circle>
                    <rect id="Rectangle-path" fill="#325187" transform="translate(15.617800, 27.310000) rotate(-90.000000) translate(-15.617800, -27.310000) " x="11.5678" y="26.01" width="8.1" height="2.6"></rect>
                    <polygon id="Shape" fill="#EAEEF6" points="10 3.3 21.3 3.3 21.3 0.8 19 3.1 17.1 1.3 15.6 2.7 14.2 1.3 12.3 3.1 10 0.8"></polygon>
                </g>
                <g transform="translate(40.000000, 27.000000)">
                    <rect id="Rectangle-path" fill="#0F3567" fillRule="nonzero" transform="translate(11.610740, 9.466380) rotate(114.571042) translate(-11.610740, -9.466380) " x="5.96102816" y="2.11675486" width="11.2994237" height="14.6992503"></rect>
                    <path d="M37.9,20.7 L35.4,18.3 L33.8,21.7 L37.2,22 C38,22.2 38.5,21.3 37.9,20.7 Z" id="Shape" fill="#6E98F0" fillRule="nonzero"></path>
                    <rect id="Rectangle-path" fill="#325187" fillRule="nonzero" transform="translate(33.135740, 19.392080) rotate(-65.428958) translate(-33.135740, -19.392080) " x="31.2358369" y="17.6921667" width="3.7998062" height="3.3998266"></rect>
                    <polygon id="Shape" fill="#EAEEF6" fillRule="nonzero" points="30.8 20.4 32.4 16.9 20.7 7.5 16 17.7"></polygon>
                    <circle id="Oval" fill="#6E98F0" fillRule="nonzero" cx="11.6" cy="9.5" r="2.4"></circle>
                    <path d="M25.1,15.7 L21.3,14" id="Shape" stroke="#325185" strokeLinejoin="round"></path>
                    <path d="M23.8,18.7 L20,16.9" id="Shape" stroke="#325185" strokeLinejoin="round"></path>
                    <path d="M26.5,12.8 L22.7,11" id="Shape" stroke="#325185" strokeLinejoin="round"></path>
                    <polygon id="Shape" fill="#0F3567" fillRule="nonzero" points="2.6 11.6 7.3 1.3 5 0.3 6.1 3.3 3.7 4.3 4.4 6.2 2.4 6.9 3.4 9.4 0.3 10.6"></polygon>
                </g>
                <g transform="translate(0.000000, 40.000000)">
                    <path d="M41.4,0.8 L9.9,0.8 C4.5,0.8 0.1,5.2 0.1,10.6 L51.3,10.6 C51.2,5.2 46.8,0.8 41.4,0.8 Z" id="Shape" fill="#325185" fillRule="nonzero"></path>
                    <circle id="Oval" fill="#6E98F0" fillRule="nonzero" cx="8.4" cy="6.4" r="1.4"></circle>
                    <circle id="Oval" fill="#6E98F0" fillRule="nonzero" cx="14.1" cy="6.4" r="1.4"></circle>
                    <path d="M34.6,5.7 L39.4,5.7" id="Shape" stroke="#FFFFFF" strokeLinecap="round"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
);

export default RocketCrash;
