/**
*
* Subscribe Components
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, textAlign } from 'styled-system'
import { format, differenceInDays } from 'date-fns'

import Button from 'components/Button'
import Input from 'components/Input'
import Modal from 'components/Modal'

import DangerLabel from 'elements/DangerLabel'

import ChargePreview from './ChargePreview'

const Wrapper = styled.div`
  padding: 2rem;
  width: 100%;
  max-width: 600px;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
`

const Title = styled.h3`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.header};
  text-align: left;
  margin-top: auto;
  margin-bottom: 0.25rem;
`

const Subtitle = styled.h3`
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.normal};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0;
`

const Label = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.label};
  text-align: left;
  padding-top: 0;
  text-transform: uppercase;
  letter-spacing: 0.05em;

  ${space}
  ${width}
  ${textAlign}
`

const FieldContainer = styled.div`
  width: 100%;
  margin-top: 1rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray30};
  border-style: solid;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: space-between;
`

const ChargesContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  width: 50%;
`

class AddLookups extends Component {
  constructor (props) {
    super(props)
    this.state = {
      lookups: ''
    }
  }

  UNSAFE_componentWillReceiveProps () {
    this.setState({
      lookups: 250
    })
  }

  render () {
    const {
      subscription,
      isOpen,
      onSave,
      onModalClose,
      requests
    } = this.props

    const {
      lookups
    } = this.state

    const lookupResetRaw = requests.getIn(['data', 'resets_on'])
    const lookupReset = format(new Date(lookupResetRaw), 'MMMM Do')
    const lookupResetDays = differenceInDays(lookupResetRaw, Date.now())

    let lookupResetDaysString = `in ${lookupResetDays} days`
    if (lookupResetDays === 0) {
      lookupResetDaysString = 'today'
    } else if (lookupResetDays === 1) {
      lookupResetDaysString = 'tomorrow'
    }

    const unitAmount = subscription.getIn(['data', 'plan', 'metadata', 'lookups_unit_amount'])
    const amount = unitAmount * lookups

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={() => {
          this.setState({
            lookups: null
          })
          onModalClose()
        }}
      >
        <Wrapper>
          <Title>Add Additional Lookups</Title>
          <Subtitle>
            Purchase blocks of 250 lookups for use with the Chrome extension or through CSV lookups.
          </Subtitle>
          <FieldContainer>
            <Input
              label='Additional Lookups'
              inputId='lookups'
              type='number'
              width='50%'
              mr='2rem'
              small
              value={lookups}
              step={250}
              min={250}
              onValueChange={(value) => {
                this.setState({
                  lookups: value
                })
              }}
            />
            <ChargesContainer>
              <Label>
                Total Cost
              </Label>
              <ChargePreview
                amount={amount}
              />
            </ChargesContainer>
          </FieldContainer>
          <DangerLabel>
            Lookups do not carry over to your next billing cycle. They will reset {lookupResetDaysString} on {lookupReset}. Please make sure you purchase only what you need!
          </DangerLabel>
          <Button
            primary
            label='Purchase'
            full
            mt='1rem'
            handleClick={() => {
              onSave(parseInt(this.state.lookups, 10))
              this.setState({})
            }}
          />
        </Wrapper>
      </Modal>
    )
  }
}

AddLookups.propTypes = {
  subscription: PropTypes.object,
  requests: PropTypes.object,
  isOpen: PropTypes.bool,
  onSave: PropTypes.func,
  onModalClose: PropTypes.func
}

export default AddLookups
