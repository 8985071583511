import Immutable, { fromJS } from 'immutable'
import {
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,
  FETCH_SEQUENCE_REQUEST,
  FETCH_SEQUENCE_SUCCESS,
  FETCH_SEQUENCE_FAILURE,
  DELETE_SEQUENCE_REQUEST,
  DELETE_SEQUENCE_SUCCESS,
  FETCH_SEQUENCE_STATE_REQUEST,
  FETCH_SEQUENCE_STATE_SUCCESS,
  FETCH_SEQUENCE_STATE_FAILURE,
  FETCH_SEQUENCE_STATS_REQUEST,
  FETCH_SEQUENCE_STATS_SUCCESS,
  FETCH_SEQUENCE_STATS_FAILURE,
  FETCH_STEP_STATS_REQUEST,
  FETCH_STEP_STATS_SUCCESS,
  FETCH_STEP_STATS_FAILURE,
  FETCH_EXPORT_CSV_REQUEST,
  FETCH_EXPORT_CSV_SUCCESS,
  FETCH_EXPORT_CSV_FAILURE,
  FETCH_EXPORT_PDF_REQUEST,
  FETCH_EXPORT_PDF_SUCCESS,
  FETCH_EXPORT_PDF_FAILURE,
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE,
  UPDATE_CONTACT_SUCCESS,
  CLONE_CONTACT_SUCCESS,
  REPLIED_CONTACT_SUCCESS,
  FETCH_CONTACT_ACTIONS_REQUEST,
  FETCH_CONTACT_ACTIONS_SUCCESS,
  FETCH_CONTACT_ACTIONS_FAILURE,
  FETCH_CONTACT_INTEGRATION_REQUEST,
  FETCH_CONTACT_INTEGRATION_SUCCESS,
  FETCH_CONTACT_INTEGRATION_FAILURE,
  FETCH_CONTACT_MESSAGES_REQUEST,
  FETCH_CONTACT_MESSAGES_SUCCESS,
  FETCH_CONTACT_MESSAGES_FAILURE,
  FETCH_CONTACT_MESSAGES_RESET,
  REPORT_CONTACT_INACCURACY_REQUEST,
  REPORT_CONTACT_INACCURACY_SUCCESS,
  REPORT_CONTACT_INACCURACY_FAILURE,
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  CREATE_CONTACT_RESET,
  FETCH_SEQUENCE_PREVIEW_REQUEST,
  FETCH_SEQUENCE_PREVIEW_SUCCESS,
  FETCH_SEQUENCE_PREVIEW_FAILURE,
  UPDATE_SEQUENCE_SUCCESS,
  RESTORE_SEQUENCE_SUCCESS,
  RESTORE_SEQUENCE_FAILURE,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  REMOVE_SEQUENCE_CONTACTS,
  UPDATE_CONTACTS_SUCCESS,
  CREATE_CSV_REQUEST,
  CREATE_CSV_SUCCESS,
  CREATE_CSV_FAILURE,
  CREATE_CSV_RESET,
  UPDATE_CSV_REQUEST,
  UPDATE_CSV_SUCCESS,
  UPDATE_CSV_FAILURE,
  IMPORT_CSV_REQUEST,
  IMPORT_CSV_SUCCESS,
  IMPORT_CSV_FAILURE,
  UPDATE_CONTACT_STEP_SUCCESS,
  REMOVE_CONTACT_STEP_SUCCESS,

  // Fetch templates
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,

  // Fetch contact company
  FETCH_CONTACT_COMPANY_REQUEST,
  FETCH_CONTACT_COMPANY_SUCCESS,
  FETCH_CONTACT_COMPANY_FAILURE,
  FETCH_CONTACT_ACTIONS_RESET,

  // Unsubscribe contact
  UNSUBSCRIBE_CONTACT_SUCCESS,

  // Block contact
  BLOCK_CONTACT_SUCCESS,

  // Fetch lookup status
  FETCH_LOOKUP_STATUS_REQUEST,
  FETCH_LOOKUP_STATUS_SUCCESS,
  FETCH_LOOKUP_STATUS_FAILURE,

  // Fetch preview contacts
  FETCH_PREVIEW_CONTACTS_REQUEST,
  FETCH_PREVIEW_CONTACTS_SUCCESS,
  FETCH_PREVIEW_CONTACTS_FAILURE,

  // Delete Templates
  DELETE_TEMPLATE_SUCCESS,

  // Create File Request
  CREATE_FILE_REQUEST,
  CREATE_FILE_SUCCESS,
  CREATE_FILE_FAILURE,
  CREATE_FILE_RESET,

  // Check for similar contacts
  FETCH_SIMILAR_CONTACTS_REQUEST,
  FETCH_SIMILAR_CONTACTS_SUCCESS,
  FETCH_SIMILAR_CONTACTS_FAILURE,

  // Fetch CRM
  FETCH_CRM_REQUEST,
  FETCH_CRM_SUCCESS,
  FETCH_CRM_FAILURE,

  // Refresh CRM
  REFRESH_CRM_REQUEST,

  // Fetch contact reply
  FETCH_CONTACT_REPLY_REQUEST,
  FETCH_CONTACT_REPLY_SUCCESS,
  FETCH_CONTACT_REPLY_FAILURE,

  // Transfer sequence
  TRANSFER_SEQUENCE_REQUEST,
  TRANSFER_SEQUENCE_SUCCESS,
  TRANSFER_SEQUENCE_FAILURE,
  NEW_SEQUENCE_RESET,

  // fetch sent messages
  FETCH_SENT_MESSAGES_REQUEST,
  FETCH_SENT_MESSAGES_SUCCESS,
  FETCH_SENT_MESSAGES_FAILURE,
  FETCH_SENT_MESSAGES_RESET,

  // Fetch errors on a group of contacts
  FETCH_CONTACT_ERRORS_REQUEST,
  FETCH_CONTACT_ERRORS_SUCCESS,
  FETCH_CONTACT_ERRORS_FAILURE,
  FETCH_CONTACT_ERRORS_RESET,

  // update errors on a group of contacts
  UPDATE_CONTACT_ERRORS_REQUEST,
  UPDATE_CONTACT_ERRORS_SUCCESS,
  UPDATE_CONTACT_ERRORS_FAILURE,

  FETCH_SELECTED_CONTACT_COUNT_REQUEST,
  FETCH_SELECTED_CONTACT_COUNT_SUCCESS,
  FETCH_SELECTED_CONTACT_COUNT_FAILURE,

  FETCH_OGP_REQUEST,
  FETCH_OGP_SUCCESS,
  FETCH_OGP_FAILURE,

  // fetch addresses / aliases
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_FAILURE,

  FETCH_CONTACT_STEPS_REQUEST,
  FETCH_CONTACT_STEPS_SUCCESS,
  FETCH_CONTACT_STEPS_FAILURE,
  FETCH_CONTACT_STEPS_RESET,

  FETCH_CONTACT_PHONE_REQUEST,
  FETCH_CONTACT_PHONE_SUCCESS,
  FETCH_CONTACT_PHONE_FAILURE,

  IMPORT_CRM_CONTACTS_SUCCESS,
  IMPORT_CRM_CONTACTS_RESET,
  IMPORT_CRM_OPTION_SELECTED,

  SHOW_WARNING_MODAL,
  HIDE_WARNING_MODAL,

  FETCH_SEQUENCE_SIGNATURE_REQUEST,
  FETCH_SEQUENCE_SIGNATURE_SUCCESS,
  FETCH_SEQUENCE_SIGNATURE_FAILURE
} from './constants'

const initialArray = {
  loading: false,
  data: [],
  error: null
}

const initialObject = {
  loading: true,
  data: null,
  error: null
}

const initialPostObject = {
  loading: false,
  data: null,
  error: null
}

const initialContacts = {
  loading: false,
  data: [],
  error: null,
  hasMore: false,
  filter: null
}

// The initial state of the App
const initialState = fromJS({
  sequence: initialObject,
  sequenceState: initialObject,
  contacts: initialContacts,
  selectedContact: {
    loading: false,
    data: null,
    error: null
  },
  stats: initialObject,
  stepStats: initialObject,
  csvDownloadLink: initialObject,
  contactActivity: initialArray,
  contactMessages: initialArray,
  contactCompany: initialPostObject,
  contactIntegration: initialObject,
  contactInaccuracy: initialPostObject,
  newContacts: initialPostObject,
  updateSequence: initialPostObject,
  createCsv: initialObject,
  pdfExport: initialObject,
  createFile: initialObject,
  importCsv: initialObject,
  previewSteps: initialArray,
  templates: initialArray,
  lookups: initialArray,
  previewContacts: initialArray,
  similarContacts: initialArray,
  crm: {
    loading: true,
    refreshing: false,
    data: null,
    error: null
  },
  contactReply: initialObject,
  newSequence: {
    loading: false,
    data: null,
    error: null
  },
  sentMessages: initialArray,
  selectedContactCount: initialObject,
  addresses: initialArray,
  contactSteps: initialArray,
  contactPhone: initialPostObject,
  importCrmStep: {
    data: { step: 0 },
    loading: false,
    error: null
  },
  showWarningModal: false,
  sequenceSignature: initialObject
})

function sequenceReducer (state = initialState, action) {
  switch (action.type) {
    // Fetch Contacts
    case FETCH_CONTACTS_REQUEST: {
      const loading = true
      let filter
      if (action.query && action.query.skip > 0) {
        filter = action.query.filter
      }
      let data = state.getIn(['contacts', 'data'])
      if (action.query && action.query.skip === 0) {
        data = fromJS([])
      }
      return state.set('contacts', state.get('contacts').merge({
        loading,
        filter,
        data
      }))
    }
    case FETCH_CONTACTS_SUCCESS: {
      let contacts = action.payload.contacts
      const existingContacts = state.getIn(['contacts', 'data'])
      if (action.payload.paginating && existingContacts) {
        contacts = existingContacts.concat(contacts)
      }
      return state.set('contacts', fromJS({
        data: contacts,
        loading: false,
        error: null,
        hasMore: action.payload.hasMore
      }))
    }
    case FETCH_CONTACTS_FAILURE:
      return state.set('contacts', state.get('contacts').merge({ loading: false, error: action.err }))

    // Fetch Sequence
    case FETCH_SEQUENCE_REQUEST:
      return state.set('sequence', state.get('sequence').merge({ data: null, loading: true, deleted: false }))
    case FETCH_SEQUENCE_SUCCESS:
      return state.set('sequence', fromJS({
        data: action.payload.sequence,
        loading: false,
        error: null
      }))
    case RESTORE_SEQUENCE_SUCCESS:
    case UPDATE_SEQUENCE_SUCCESS: {
      const sequenceId = state.getIn(['sequence', 'data', '_id'])
      const updatedId = action.payload.sequence ? action.payload.sequence.get('_id') : null
      const data = sequenceId === updatedId ? state.getIn(['sequence', 'data']).merge(action.payload.sequence.delete('_user')) : state.getIn(['sequence', 'data'])
      return state.set('sequence', fromJS({
        data,
        loading: false,
        error: null
      }))
    }
    case RESTORE_SEQUENCE_FAILURE:
    case FETCH_SEQUENCE_FAILURE:
      return state.set('sequence', fromJS({
        data: null,
        loading: false,
        error: action.err.response.status === 404 ? 'NOT_FOUND' : action.err
      }))

    case UPDATE_CUSTOM_FIELDS_SUCCESS:
      return state.setIn(['sequence', 'data', 'fields'], action.payload.fields)

    // Delete Sequence
    case DELETE_SEQUENCE_REQUEST:
      return state.set('sequence', state.get('sequence').merge({ loading: true }))
    case DELETE_SEQUENCE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      const data = action.payload.sequence
      return state.set('sequence', fromJS({
        data,
        loading: true,
        error: null,
        deleted: true
      }))

    // Fetch Sequence
    case FETCH_SEQUENCE_STATE_REQUEST:
      // eslint-disable-next-line no-case-declarations
      const sameSequence = state.getIn(['sequenceState', 'sequenceId']) === action.sequenceId
      return state.set('sequenceState', state.get('sequenceState').merge({
        data: sameSequence ? state.getIn(['sequenceState', 'data']) : {},
        loading: state.getIn(['sequenceState', 'loading']) || !sameSequence
      }))
    case FETCH_SEQUENCE_STATE_SUCCESS:
      return state.set('sequenceState', fromJS({
        sequenceId: action.payload.sequenceId,
        data: action.payload.state,
        loading: false,
        error: null
      }))
    case FETCH_SEQUENCE_STATE_FAILURE:
      return state.set('sequenceState', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch Stats
    case FETCH_SEQUENCE_STATS_REQUEST:
      return state.set('stats', state.get('stats').merge({ loading: true, data: null }))
    case FETCH_SEQUENCE_STATS_SUCCESS:
      return state.set('stats', fromJS({
        data: action.payload.stats,
        loading: false,
        error: null
      }))
    case FETCH_SEQUENCE_STATS_FAILURE:
      return state.set('stats', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch Step Stats
    case FETCH_STEP_STATS_REQUEST:
      return state.set('stepStats', state.get('stepStats').merge({ loading: true, data: null }))
    case FETCH_STEP_STATS_SUCCESS:
      return state.set('stepStats', fromJS({
        data: action.payload.stepStats,
        loading: false,
        error: null
      }))
    case FETCH_STEP_STATS_FAILURE:
      return state.set('stepStats', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch Export CSV
    case FETCH_EXPORT_CSV_REQUEST:
      return state.set('csvDownloadLink', state.get('csvDownloadLink').merge({ loading: true }))
    case FETCH_EXPORT_CSV_SUCCESS:
      return state.set('csvDownloadLink', fromJS({
        data: action.payload.csvDownloadLink,
        loading: false,
        error: null
      }))
    case FETCH_EXPORT_CSV_FAILURE:
      return state.set('csvDownloadLink', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch Export PDF
    case FETCH_EXPORT_PDF_REQUEST:
      return state.set('pdfExport', state.get('pdfExport').merge({ loading: true }))
    case FETCH_EXPORT_PDF_SUCCESS:
      return state.set('pdfExport', fromJS({
        data: action.payload,
        loading: false,
        error: null
      }))
    case FETCH_EXPORT_PDF_FAILURE:
      return state.set('pdfExport', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Selected Contact
    case FETCH_CONTACT_REQUEST:
      return state
        .set('selectedContact', state.get('selectedContact').merge({
          loading: true
        }))
        .set('contactPhone', state.get('contactPhone').merge({
          data: {},
          loading: false,
          error: null
        }))
    case FETCH_CONTACT_SUCCESS: {
      const contact = action.payload.contact
      const contactId = contact.get('_id')
      const data = state.get('contacts').get('data').map((c) => {
        const cId = c.get('_id')
        let newContact = c
        if (contactId === cId) {
          newContact = contact
        }
        return newContact
      })
      return state
        .set('selectedContact', fromJS({
          data: action.payload.contact,
          loading: false,
          error: null
        }))
        .set('contacts', state.get('contacts').merge({ data }))
    }
    case FETCH_CONTACT_FAILURE:
      return state.set('selectedContact', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case REPLIED_CONTACT_SUCCESS:
    case UNSUBSCRIBE_CONTACT_SUCCESS:
    case BLOCK_CONTACT_SUCCESS:
    case UPDATE_CONTACT_SUCCESS: {
      let contact = action.payload.contact
      const contactId = contact.get('_id')
      const data = state
        .getIn(['contacts', 'data'])
        .map((c) => {
          if (contactId !== c.get('_id')) {
            return c
          }

          contact = contact.mergeWith((n, o) => {
            if (n === undefined) {
              return o
            }

            return n
          }, c)

          return contact
        })

      const selectedContactId = state.get('selectedContact').get('data')
        ? state.get('selectedContact').get('data').get('_id')
        : null

      let contactData = state.get('selectedContact').get('data')
      if (selectedContactId === contactId) {
        contactData = contactData.merge(contact)
      }
      return state
        .set('contacts', state.get('contacts').merge({ data }))
        .set('selectedContact', state.get('selectedContact').merge({ data: contactData }))
    }

    // Contact Activity
    case FETCH_CONTACT_ACTIONS_REQUEST:
      return state.set('contactActivity', state.get('contactActivity').merge({
        data: [],
        loading: true
      }))
    case FETCH_CONTACT_ACTIONS_SUCCESS:
      return state.set('contactActivity', fromJS({
        data: action.payload.actions,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_ACTIONS_FAILURE:
      return state.set('contactActivity', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))
    case FETCH_CONTACT_ACTIONS_RESET:
      return state.set('contactActivity', fromJS({
        data: [],
        loading: false,
        error: null
      }))

    // Contact Integration
    case FETCH_CONTACT_INTEGRATION_REQUEST:
      return state.set('contactIntegration', state.get('contactIntegration').merge({
        data: null,
        loading: true
      }))
    case FETCH_CONTACT_INTEGRATION_SUCCESS:
      return state.set('contactIntegration', fromJS({
        data: action.payload.integration,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_INTEGRATION_FAILURE:
      return state.set('contactIntegration', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Contact Messages
    case FETCH_CONTACT_MESSAGES_REQUEST:
      return state.set('contactMessages', state.get('contactMessages').merge({
        data: [],
        loading: true
      }))
    case FETCH_CONTACT_MESSAGES_SUCCESS:
      return state.set('contactMessages', fromJS({
        data: action.payload.messages,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_MESSAGES_FAILURE:
      return state.set('contactMessages', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))
    case FETCH_CONTACT_MESSAGES_RESET:
      return state.set('contactMessages', fromJS({
        data: [],
        loading: false,
        error: null
      }))

    // Inaccurate Contact
    case REPORT_CONTACT_INACCURACY_REQUEST:
      return state.set('contactInaccuracy', state.get('contactInaccuracy').merge({
        loading: true
      }))
    case REPORT_CONTACT_INACCURACY_SUCCESS:
      return state.set('contactInaccuracy', fromJS({
        loading: false,
        error: null
      }))
    case REPORT_CONTACT_INACCURACY_FAILURE:
      return state.set('contactInaccuracy', fromJS({
        loading: false,
        error: action.err
      }))

    // Contact Company
    case FETCH_CONTACT_COMPANY_REQUEST:
      return state.set('contactCompany', state.get('contactCompany').merge({
        data: null,
        loading: true
      }))
    case FETCH_CONTACT_COMPANY_SUCCESS:
      return state.set('contactCompany', fromJS({
        data: action.payload.company,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_COMPANY_FAILURE:
      return state.set('contactCompany', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Update contact message success
    case REMOVE_CONTACT_STEP_SUCCESS:
    case UPDATE_CONTACT_STEP_SUCCESS: {
      const {
        object,
        stepIndex
      } = action.payload
      const data = state.getIn(['contactSteps', 'data']).map((contactStep) => {
        const contactStepIndex = contactStep.get('step')
        if (contactStepIndex === stepIndex) {
          contactStep = contactStep.set('object', object)
        }
        return contactStep
      })
      return state.set('contactSteps', state.get('contactSteps').merge({ data }))
    }

    // Create Contact
    case CREATE_CONTACT_REQUEST:
      return state.set('newContacts', state.get('newContacts').merge({ loading: true }))
    case CREATE_CONTACT_SUCCESS:
      return state.set('newContacts', fromJS({
        data: action.payload.contacts,
        loading: false,
        error: null
      }))
    case CREATE_CONTACT_FAILURE:
      return state.set('newContacts', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))
    case CREATE_CONTACT_RESET:
      return state.set('newContacts', fromJS(initialPostObject))

    // Create Csv
    case CREATE_CSV_REQUEST:
      return state.set('createCsv', state.get('createCsv').merge({ loading: true }))
    case CREATE_CSV_SUCCESS:
      return state.set('createCsv', fromJS({
        data: action.payload.csv,
        loading: false,
        error: null
      }))
    case CREATE_CSV_FAILURE:
      return state.set('createCsv', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))
    case CREATE_CSV_RESET:
      return state.set('createCsv', fromJS(initialPostObject))

    // Create Csv
    case CREATE_FILE_REQUEST:
      return state.set('createFile', state.get('createFile').merge({ loading: true }))
    case CREATE_FILE_SUCCESS:
      return state.set('createFile', fromJS({
        data: action.payload.file,
        loading: false,
        error: null
      }))
    case CREATE_FILE_FAILURE:
      return state.set('createFile', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))
    case CREATE_FILE_RESET:
      return state.set('createFile', fromJS(initialPostObject))

    case UPDATE_CSV_REQUEST:
      return state.set('createCsv', state.get('createCsv').merge({ loading: true }))
    case UPDATE_CSV_SUCCESS:
      return state.set('createCsv', fromJS({
        data: action.payload.csv,
        loading: false,
        error: null
      }))
    case UPDATE_CSV_FAILURE:
      return state.set('createCsv', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case IMPORT_CSV_REQUEST:
      return state.set('importCsv', state.get('importCsv').merge({ loading: true }))
    case IMPORT_CSV_SUCCESS:
      return state.set('importCsv', fromJS({
        data: action.payload.csvSequence,
        loading: false,
        error: null
      }))
    case IMPORT_CSV_FAILURE:
      return state.set('importCsv', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Preview Contact
    case FETCH_SEQUENCE_PREVIEW_REQUEST:
      return state.set('previewSteps', state.get('previewSteps').merge({ loading: true }))
    case FETCH_SEQUENCE_PREVIEW_SUCCESS:
      return state.set('previewSteps', fromJS({
        data: action.payload.previewSteps,
        loading: false,
        error: null
      }))
    case FETCH_SEQUENCE_PREVIEW_FAILURE:
      return state.set('previewSteps', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch templates
    case FETCH_TEMPLATES_REQUEST:
      return state.set('templates', state.get('templates').merge({ loading: true }))
    case FETCH_TEMPLATES_SUCCESS:
      return state.set('templates', fromJS({
        data: action.payload.templates,
        loading: false,
        error: null
      }))
    case FETCH_TEMPLATES_FAILURE:
      return state.set('templates', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case DELETE_TEMPLATE_SUCCESS: {
      const {
        template
      } = action
      const data = state.getIn(['templates', 'data']).filter(t => (t.get('_id') !== template.get('_id')))
      return state.set('templates', fromJS({
        data,
        loading: false,
        error: null
      }))
    }

    // Fetch lookups
    case FETCH_LOOKUP_STATUS_REQUEST:
      return state.set('lookups', state.get('lookups').merge({ loading: true }))
    case FETCH_LOOKUP_STATUS_SUCCESS:
      return state.set('lookups', fromJS({
        data: action.payload.lookups,
        loading: false,
        error: null
      }))
    case FETCH_LOOKUP_STATUS_FAILURE:
      return state.set('lookups', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_PREVIEW_CONTACTS_REQUEST:
      return state.set('previewContacts', state.get('previewContacts').merge({ loading: true }))
    case FETCH_PREVIEW_CONTACTS_SUCCESS:
      return state.set('previewContacts', fromJS({
        data: action.payload.contacts,
        loading: false,
        error: null
      }))
    case FETCH_PREVIEW_CONTACTS_FAILURE:
      return state.set('previewContacts', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_SIMILAR_CONTACTS_REQUEST:
      return state.set('similarContacts', state.get('similarContacts').merge({
        data: {},
        loading: true
      }))
    case FETCH_SIMILAR_CONTACTS_SUCCESS:
      return state.set('similarContacts', fromJS({
        data: action.payload.similar,
        loading: false,
        error: null
      }))
    case FETCH_SIMILAR_CONTACTS_FAILURE:
      return state.set('similarContacts', fromJS({
        data: {},
        loading: false,
        error: action.err
      }))

    case REMOVE_SEQUENCE_CONTACTS: {
      const contactIds = action.contactIds
      const data = state.get('contacts').get('data').filter((contact) => {
        const contactId = contact.get('_id')
        return !contactIds.includes(contactId)
      })
      return state.set('contacts', state.get('contacts').merge({ data }))
    }

    case UPDATE_CONTACTS_SUCCESS: {
      const contactIds = action.contactIds
      const params = action.params
      const data = state.get('contacts').get('data').map((c) => {
        const contactId = c.get('_id')
        let contact = c
        if (contactIds.includes(contactId)) {
          contact = c.merge(fromJS(params))
        }
        return contact
      })
      return state.set('contacts', state.get('contacts').merge({ data }))
    }

    case CLONE_CONTACT_SUCCESS: {
      const contact = action.payload.contact
      const data = state.get('contacts').get('data').unshift(contact)
      // set selected contact to cloned contact and put cloned contact to top of contacts
      return state.set('contacts', state.get('contacts').merge({ data }))
    }

    case FETCH_CRM_REQUEST:
      return state.set('crm', state.get('crm').merge({
        data: {},
        loading: true
      }))
    case FETCH_CRM_SUCCESS:
      return state.set('crm', fromJS({
        data: action.payload.crm,
        loading: action.loading,
        refreshing: false,
        error: null
      }))
    case FETCH_CRM_FAILURE:
      return state.set('crm', state.get('crm').merge({
        loading: false,
        refreshing: false,
        error: action.err
      }))

    case REFRESH_CRM_REQUEST:
      return state.set('crm', state.get('crm').merge({ refreshing: true }))

    case FETCH_CONTACT_REPLY_REQUEST:
      return state.set('contactReply', state.get('contactReply').merge({
        data: {},
        loading: true
      }))
    case FETCH_CONTACT_REPLY_SUCCESS:
      return state.set('contactReply', fromJS({
        data: action.payload.contactReply,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_REPLY_FAILURE:
      return state.set('contactReply', fromJS({
        data: {},
        loading: false,
        error: action.err
      }))

    case TRANSFER_SEQUENCE_REQUEST:
      return state.set('newSequence', state.get('newSequence').merge({
        data: null,
        loading: true
      }))
    case TRANSFER_SEQUENCE_SUCCESS:
      return state.set('newSequence', fromJS({
        data: action.payload.sequence,
        loading: false,
        error: null
      }))
    case TRANSFER_SEQUENCE_FAILURE:
      return state.set('newSequence', fromJS({
        data: {},
        loading: false,
        error: action.err
      }))
    case NEW_SEQUENCE_RESET:
      return state.set('newSequence', fromJS({
        data: null,
        loading: false,
        error: null
      }))

    case FETCH_SENT_MESSAGES_REQUEST:
      return state.set('sentMessages', state.get('sentMessages').merge({
        data: [],
        loading: true
      }))
    case FETCH_SENT_MESSAGES_SUCCESS:
      return state.set('sentMessages', fromJS({
        data: action.payload.messages,
        loading: false,
        error: null
      }))
    case FETCH_SENT_MESSAGES_FAILURE:
      return state.set('sentMessages', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))
    case FETCH_SENT_MESSAGES_RESET:
      return state.set('sentMessages', fromJS({
        data: [],
        loading: false,
        error: null
      }))

    case FETCH_CONTACT_ERRORS_REQUEST:
      return state.set('contactErrors', fromJS({
        data: [],
        loading: true
      }))
    case FETCH_CONTACT_ERRORS_SUCCESS:
      return state.set('contactErrors', fromJS({
        data: action.payload.contactErrors,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_ERRORS_FAILURE:
      return state.set('contactErrors', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))
    case FETCH_CONTACT_ERRORS_RESET:
      return state.set('contactErrors', fromJS({
        data: [],
        loading: false,
        err: null
      }))

    case UPDATE_CONTACT_ERRORS_REQUEST:
      return state.set('noClearedContactErrors', fromJS({
        data: [],
        loading: true
      }))
    case UPDATE_CONTACT_ERRORS_SUCCESS: {
      // map everything into a hash map for easy access
      let contactDataMap = Immutable.Map({})
      // eslint-disable-next-line array-callback-return
      action.payload.contacts.map((c) => {
        contactDataMap = contactDataMap.set(c.get('_id'), c)
      })

      // iterate through all the contacts that are currently
      // loaded into the state, and merge the update into them
      const data = state.get('contacts').get('data').map((c) => {
        const contactId = c.get('_id')
        let updatedContact = c

        if (contactDataMap.has(contactId)) {
          updatedContact = updatedContact.merge(contactDataMap.get(contactId))
        }

        return updatedContact
      })

      const selectedContact = state.get('selectedContact').get('data')
      const selectedContactId = (selectedContact) ? selectedContact.get('_id') : null
      let newSelectedContact = selectedContact
      if (contactDataMap.get(selectedContactId)) {
        newSelectedContact = selectedContact.merge(contactDataMap.get(selectedContactId))
      }

      return state
        .set('contacts', state.get('contacts').merge({ data }))
        .set('selectedContact', state.get('selectedContact').merge({ data: newSelectedContact }))
    }
    case UPDATE_CONTACT_ERRORS_FAILURE:
      return state.set('noClearedContactErrors', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_SELECTED_CONTACT_COUNT_REQUEST:
      return state.set('selectedContactCount', fromJS({
        data: null,
        loading: true
      }))
    case FETCH_SELECTED_CONTACT_COUNT_SUCCESS:
      return state.set('selectedContactCount', fromJS({
        data: action.selectedCount,
        loading: false
      }))
    case FETCH_SELECTED_CONTACT_COUNT_FAILURE:
      return state.set('selectedContactCount', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case FETCH_OGP_REQUEST:
      return state.set('ogp', fromJS({
        data: null,
        loading: true
      }))
    case FETCH_OGP_SUCCESS:
      return state.set('ogp', fromJS({
        data: action.ogpData,
        loading: false
      }))
    case FETCH_OGP_FAILURE:
      return state.set('ogp', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case FETCH_ADDRESSES_REQUEST:
      return state.set('addresses', state.get('addresses').merge({
        data: [],
        loading: true
      }))
    case FETCH_ADDRESSES_SUCCESS:
      return state.set('addresses', fromJS({
        data: action.payload.addresses,
        loading: false,
        error: null
      }))
    case FETCH_ADDRESSES_FAILURE:
      return state.set('addresses', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_CONTACT_STEPS_REQUEST:
      return state.set('contactSteps', state.get('contactSteps').merge({
        data: [],
        loading: true
      }))
    case FETCH_CONTACT_STEPS_SUCCESS:
      return state.set('contactSteps', fromJS({
        data: action.payload.contactSteps,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_STEPS_FAILURE:
      return state.set('contactSteps', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))
    case FETCH_CONTACT_STEPS_RESET:
      return state.set('contactSteps', fromJS({
        data: [],
        loading: false,
        error: null
      }))

    case FETCH_CONTACT_PHONE_REQUEST:
      return state.set('contactPhone', state.get('contactPhone').merge({
        data: {},
        loading: true,
        error: null
      }))
    case FETCH_CONTACT_PHONE_SUCCESS:
      // eslint-disable-next-line no-case-declarations
      let selectedContact = state.getIn(['selectedContact', 'data'])
      if (selectedContact.get('id') === action.payload.contactId) {
        selectedContact = selectedContact.merge(action.payload.contact)
      }
      // eslint-disable-next-line no-case-declarations
      const err = selectedContact.has('phone_number') ? null : 'Not Found'

      return state
        .set('contactPhone', state.get('contactPhone').merge({
          data: action.payload.contact,
          loading: false,
          error: err
        }))
        .set('selectedContact', state.get('selectedContact').merge({
          data: selectedContact
        }))
    case FETCH_CONTACT_PHONE_FAILURE:
      return state.set('contactPhone', fromJS({
        data: {},
        loading: false,
        error: action.err
      }))
    case IMPORT_CRM_OPTION_SELECTED:
      return state.set('importCrmStep', fromJS({
        data: { step: 1 },
        loading: false,
        error: null
      }))
    case IMPORT_CRM_CONTACTS_RESET:
      return state.set('importCrmStep', fromJS({
        data: { step: 0 },
        loading: false,
        error: null
      }))
    case IMPORT_CRM_CONTACTS_SUCCESS:
      return state.set('importCrmStep', fromJS({
        data: { step: 2 },
        loading: false,
        error: null
      }))
    case SHOW_WARNING_MODAL:
      return state.set('showWarningModal', true)
    case HIDE_WARNING_MODAL:
      return state.set('showWarningModal', false)

    case FETCH_SEQUENCE_SIGNATURE_REQUEST:
      return state.set('sequenceSignature', state.get('sequenceSignature').merge({
        data: {},
        loading: true,
        error: null
      }))
    case FETCH_SEQUENCE_SIGNATURE_SUCCESS:
      return state.set('sequenceSignature', state.get('sequenceSignature').merge({
        data: action.signature,
        loading: true,
        error: null
      }))
    case FETCH_SEQUENCE_SIGNATURE_FAILURE:
      return state.set('sequenceSignature', state.get('sequenceSignature').merge({
        data: {},
        loading: false,
        error: action.err
      }))
    default:
      return state
  }
}

export default sequenceReducer
