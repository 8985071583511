import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import Modal from 'components/Modal'
import Button from 'components/Button'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import DangerLabel from 'elements/DangerLabel'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding: 2rem 4rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class RemoveTeammate extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      deleteMember,
      isOpen,
      onCancel,
      onConfirm
    } = this.props

    const name = deleteMember ? deleteMember.get('full_name') : null

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper>
          <InputContainer>
            <Title align='center'>Remove Teammate</Title>
            <Label mt='1.5rem' mb='1rem' align='center'>
              <DangerLabel>Removing {name} also removes shared sequences that {name} owns from your team. If you&apos;re looking to free up a seat, use &quot;unassign seat&quot; instead.</DangerLabel> Are you sure you want to remove {name} from your team?
            </Label>
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              label='confirm'
              onClick={onConfirm}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

RemoveTeammate.propTypes = {
  deleteMember: PropTypes.string,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onConfirm: PropTypes.func
}

export default withTheme(RemoveTeammate)
