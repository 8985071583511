import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled, { css } from 'styled-components'
import { space, width, color, borderColor } from 'styled-system'

const Wrapper = styled.button`
  position: relative;
  color: ${props => (props.primary ? props.theme.colors.white : props.theme.labelColor)};
  background-color: ${props => (props.primary ? props.theme.colors.blue : 'transparent')};
  text-align: center;
  padding-top: ${props => (props.small ? '0.5rem' : '0.75rem')};
  padding-bottom: ${props => (props.small ? '0.5rem' : '0.75rem')};
  padding-left: ${props => (props.small ? '1.5rem' : '2rem')};
  padding-right: ${props => (props.small ? '1.5rem' : '2rem')};
  width: ${props => (props.full ? '100%' : 'auto')};
  text-transform: uppercase;
  font: ${props => props.theme.fonts.button};
  letter-spacing: .05em;
  border-style: solid;
  border-radius: 9999px;
  border-width: ${props => (props.primary ? '1px' : '1px')};
  border-color: ${props => (props.primary ? props.theme.colors.blue : props.theme.gray40)};
  white-space: nowrap;

  opacity: 1;
  transition: opacity .15s ease-in;
  transition: transform 0.25s ease-out;

  ${props => props.destructive && css`
    color: ${props.theme.colors.white};
    background-color: ${props.theme.colors.red};
    border-color: ${props.theme.colors.red};
  `};

  ${props => !props.disabled && css`
    cursor: pointer;

    &:hover {
      opacity: .5;
      transition: opacity .15s ease-in;
    }

    &:active {
      opacity: .8;
      transition: opacity .15s ease-out;
    }
  `};

  ${props => props.disabled && css`
    cursor: default;
    opacity: .5;
  `};

  ${space}
  ${width}
  ${color}
  ${borderColor}
`

const IconContainer = styled.div`
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  bottom: 0;
  margin-top: auto;
  margin-bottom: auto;
  padding-right: ${props => props.isRenderingLabelAndIcon};
`

class Button extends Component {
  render () {
    const {
      handleClick,
      primary = false,
      destructive = false,
      small = false,
      full = false,
      icon,
      iconPadding = '1.5rem',
      disabled = false,
      label,
      ...rest
    } = this.props

    return (
      <Wrapper
        onClick={handleClick}
        primary={primary}
        destructive={destructive}
        small={small}
        full={full}
        disabled={disabled}
        {...rest}
      >
        {icon && <IconContainer>{icon}</IconContainer>}
        {icon && label && <span style={{ paddingRight: iconPadding }} />}
        {label}
      </Wrapper>
    )
  }
}

Button.propTypes = {
  icon: PropTypes.object,
  iconPadding: PropTypes.string,
  label: PropTypes.string,
  primary: PropTypes.bool,
  destructive: PropTypes.bool,
  small: PropTypes.bool,
  full: PropTypes.bool,
  handleClick: PropTypes.func,
  disabled: PropTypes.bool
}

export default Button
