import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Checkbox from 'elements/Checkbox'
import Label from 'elements/Label'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`

const CheckboxContainer = styled.div`
  background: ${props => props.theme.background};
  display: flex;
  flex-direction: row;
  align-items: center;
  padding: 0.25rem;
  padding-right: 0.5rem;
  border: ${props => `solid 1px ${props.theme.borderColor}`};
`

const days = [
  {
    label: 'S',
    index: 0
  },
  {
    label: 'M',
    index: 1
  },
  {
    label: 'T',
    index: 2
  },
  {
    label: 'W',
    index: 3
  },
  {
    label: 'T',
    index: 4
  },
  {
    label: 'F',
    index: 5
  },
  {
    label: 'S',
    index: 6
  }
]

class DaySelector extends Component {
  render () {
    const {
      selectedDays = [],
      onChange
    } = this.props

    const dayOptions = days.map((day) => {
      const selected = selectedDays.includes(day.index)
      return (
        <CheckboxContainer key={day.index}>
          <Checkbox
            selected={selected}
            handleClick={() => {
              let updatedDays
              if (selected) {
                updatedDays = selectedDays.filter(d => d !== day.index)
              } else {
                updatedDays = selectedDays.concat([day.index])
              }
              onChange(updatedDays)
            }}
          />
          <Label>{day.label}</Label>
        </CheckboxContainer>
      )
    })
    return (
      <Wrapper>
        {dayOptions}
      </Wrapper>
    )
  }
}

DaySelector.propTypes = {
  selectedDays: PropTypes.array,
  onChange: PropTypes.func
}

export default DaySelector
