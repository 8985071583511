import forEach from 'ramda/src/forEach'
import last from 'ramda/src/last'
import equals from 'ramda/src/equals'
import pick from 'ramda/src/pick'
import uniqBy from 'ramda/src/uniqBy'
import slice from 'ramda/src/slice'
import isEmpty from 'ramda/src/isEmpty'
import mergeRight from 'ramda/src/mergeRight'
import groupBy from 'ramda/src/groupBy'
import indexBy from 'ramda/src/indexBy'
import range from 'ramda/src/range'
import isNil from 'ramda/src/isNil'

const R = {
  forEach,
  last,
  equals,
  pick,
  uniqBy,
  slice,
  isEmpty,
  merge: mergeRight,
  groupBy,
  indexBy,
  range,
  isNil
}

export default R
