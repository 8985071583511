import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { toHtml } from '../SlateEditor/utils/showdown'
import { slateConstants } from 'components/SlateEditor/utils/slate/'
import Label from 'elements/Label'

const Wrapper = styled.div`
  display: flex;
  align-items: start;
  justify-content: start;
  flex-direction: column;
  background-color: ${props => props.bg ? props.bg : props.theme.colors.white};
  box-shadow: ${props => (props.shadow ? props.theme.shadow : 'none')};
  width: 36rem;
`

const Subject = styled.h3`
  font: ${props => props.theme.fonts.formtitle};
  color: ${props => props.theme.titleColor};
  text-align: left;
  margin: 0;
  padding: 1rem;
  width: 100%;
  border-bottom: 1px solid ${props => props.theme.colors.gray20};
`

const HtmlBody = styled(Label)`
  padding: 1rem;
  min-height: 320px;
  max-width: 100%;

  >div>p {
    margin: 0;
    display: block;
  }

  >div>p:after {
    white-space: pre;
    content: ' ';
  }

  >div code {
    word-break: break-all;
  }
`

const PlainTextBody = styled(Label)`
  padding: 1rem;
  min-height: 320px;
  white-space: pre-wrap;
  word-break: break-word;
`

class MessagePreview extends PureComponent {
  render () {
    const {
      subject,
      body,
      markdown,
      bg
    } = this.props

    return (
      <Wrapper bg={bg}>
        {subject &&
          <Subject>
            {subject}
          </Subject>}
        {markdown &&
          <HtmlBody>
            <div dangerouslySetInnerHTML={{ __html: toHtml(slateConstants.SLATE_EDITORS.BODY, markdown) }} />
          </HtmlBody>}
        {body &&
          <PlainTextBody>
            {body}
          </PlainTextBody>}
      </Wrapper>
    )
  }
}

MessagePreview.propTypes = {
  subject: PropTypes.string,
  body: PropTypes.string,
  bg: PropTypes.string,
  markdown: PropTypes.any
}

export default MessagePreview
