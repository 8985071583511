import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { format } from 'date-fns'

import MessagePreview from 'components/MessagePreview'
import Loading from 'components/Loading'
import Button from 'components/Button'
import DropDownFieldForm from 'components/DropDownFieldForm'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'

import { sentimentTypesToOptions } from 'containers/Sequence/constants'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const Message = styled.div`
  margin-top: 2rem;
`

const MessagePreviewWrapper = styled.div`
  border-radius: 2px;
  overflow-y: auto;
  max-height: 24rem;
`

const MessagePreviewLabel = styled.div`
  margin-bottom: 0.5rem;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.normal};
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class AssignSentiment extends Component {
  constructor (props) {
    super(props)
    this.state = {
      sentiment: null
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    if (!this.state.sentiment) {
      if (newProps.contact && newProps.contact.get('sentiment')) {
        this.setState({
          sentiment: newProps.contact.get('sentiment')
        })
      }
    }
  }

  render () {
    const {
      contact,
      contactReply,
      onCancel,
      onConfirm,
      theme
    } = this.props

    const {
      sentiment
    } = this.state

    if (!contactReply || contactReply.get('loading')) {
      return <Loading />
    }

    const hasReply = contactReply.get('data')
    const date = format(new Date(contactReply.getIn(['data', 'timestamp'])), 'dddd, MMMM D, YYYY [at] h:mm A')

    const contactId = contact.get('id')
    const name = contactReply.getIn(['data', 'from', 'name']) || (contact.get('name') || contact.get('email'))

    const listOptions = sentimentTypesToOptions()

    return (
      <Wrapper>
        <InputContainer>
          <Title align='center'>Assign Sentiment</Title>
          <Label mt='0.5rem' mb='1rem' align='center'>
            Assign a category to a contact that best fits the
            sentiment for {name}
          </Label>
          <DropDownFieldForm
            options={listOptions}
            value={sentiment}
            clearable
            inputId='list'
            onOptionChange={(option) => {
              this.setState({
                sentiment: option || null
              })
            }}
          />
          {hasReply &&
            <Message>
              <MessagePreviewLabel>Reply from {name} on {date}:</MessagePreviewLabel>
              <MessagePreviewWrapper>
                <MessagePreview
                  bg={theme.colors.gray20}
                  subject={contactReply.getIn(['data', 'subject'])}
                  body={contactReply.getIn(['data', 'reply'])}
                />
              </MessagePreviewWrapper>
            </Message>}
        </InputContainer>
        <Divider />
        <ButtonContainer>
          <Button
            label='cancel'
            onClick={onCancel}
            mr='0.5rem'
          />
          <Button
            primary
            label='confirm'
            onClick={() => {
              onConfirm(contactId, sentiment)
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

AssignSentiment.propTypes = {
  contact: PropTypes.object,
  theme: PropTypes.object,
  contactReply: PropTypes.object,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func
}

export default withTheme(AssignSentiment)
