// https://journeys.autopilotapp.com/blog/email-spam-trigger-words/
import {
  CUSTOM_FIELD_REGEX,
  FALLBACK_CUSTOM_FIELD_REGEX
} from '../slate/constants'

import { EMAIL_STATUS } from './index'

const _naughtyWords = new Set([
  'act now',
  'action',
  'apply now',
  'apply online',
  'buy',
  'buy direct',
  'call now',
  'click here',
  'clearance',
  'click here',
  'do it today',
  'don’t delete',
  'drastically reduced',
  'exclusive deal',
  'expire',
  'get it now',
  'get started now',
  'important information regarding',
  'instant',
  'limited time',
  'new customers only',
  'now only',
  'offer expires',
  'once in a lifetime',
  'order now',
  'please read',
  'special promotion',
  'take action',
  'this won’t last',
  'urgent',
  'while stocks last',
  '100%',
  'all-new',
  'bargain',
  'best price',
  'bonus',
  'email marketing',
  'free',
  'for instant access',
  'free gift',
  'free trial',
  'have you been turned down?',
  'great offer',
  'join millions of americans',
  'incredible deal',
  'prize',
  'satisfaction guaranteed',
  'will not believe your eyes',
  'as seen on',
  'click here',
  'click below',
  'deal',
  'direct email',
  'direct marketing',
  'do it today',
  'order now',
  'order today',
  'unlimited',
  'what are you waiting for?',
  'visit our website',
  'acceptance',
  'access',
  'avoid bankruptcy',
  'boss',
  'cancel',
  'card accepted',
  'certified',
  'cheap',
  'compare rates',
  'congratulations',
  'credit card offers',
  'cures',
  'dear [personalization variable]',
  'dear friend',
  'drastically reduced',
  'easy terms',
  'free grant money',
  'free hosting',
  'free info',
  'free membership',
  'get out of debt',
  'giving away',
  'guarantee',
  'guaranteed',
  'have you been turned down?',
  'information you requested',
  'join millions',
  'no age restrictions',
  'no catch',
  'no experience',
  'no obligation',
  'no purchase necessary',
  'no questions asked',
  'no strings attached',
  'offer',
  'save big',
  'winner,',
  'winning',
  'won',
  'you are a winner!',
  'you’ve been selected!',
  'additional income',
  'all-natural',
  'amazing',
  'be your own boss',
  'big bucks',
  'billion',
  'billion dollars',
  'cash',
  'cash bonus',
  'consolidate debt and credit',
  'consolidate your debt',
  'double your income',
  'earn',
  'earn cash',
  'earn extra cash',
  'eliminate bad credit',
  'eliminate debt',
  'extra',
  'fantastic deal',
  'financial freedom',
  'financially independent',
  'free investment',
  'free money',
  'get paid',
  'home-based',
  'income',
  'increase sales',
  'increase traffic',
  'lose',
  'lose weight',
  'money back',
  'no catch',
  'no fees',
  'no hidden costs',
  'no strings attached',
  'potential earnings',
  'pure profit',
  'removes wrinkles',
  'reverses aging',
  'risk-free',
  'serious cash',
  'stop snoring',
  'vacation',
  'vacation offers',
  'weekend getaway',
  'weight loss',
  'while you sleep',
  'addresses',
  'beneficiary',
  'billing',
  'casino',
  'celebrity',
  'collect child support',
  'copy dvds',
  'fast viagra delivery',
  'hidden',
  'human growth hormone',
  'in accordance with laws',
  'investment',
  'junk',
  'legal',
  'life insurance',
  'loan',
  'lottery',
  'luxury car',
  'medicine',
  'meet singles',
  'message contains',
  'miracle',
  'money',
  'multi-level marketing',
  'nigerian',
  'offshore',
  'online degree',
  'online pharmacy',
  'passwords',
  'refinance',
  'request',
  'rolex',
  'score',
  'social security number',
  'spam',
  'this isn’t spam',
  'undisclosed recipient',
  'university diplomas',
  'unsecured credit',
  'unsolicited',
  'us dollars',
  'valium',
  'viagra',
  'vicodin',
  'warranty',
  'xanax'
])

function _findNaughtyWords (string) {
  if (!string) {
    return []
  }

  const emailWords = string.split(/\s+/)
  const intersection = emailWords.filter(word => _naughtyWords.has(word))
  return intersection
}

export async function emailTooLong (markDown) {
  // ideal email size fits on phone screen (signature could be off the bottom)
  let result = {
    rule: 'email_too_long',
    rule_violation: 'Research suggests the ideal length of an email is between 50 and 125 words',
    details: null,
    color: EMAIL_STATUS.GREEN
  }

  const wordCount = markDown.split(' ').length
  if (wordCount && wordCount > 125) {
    const details = (num) => `Email over ${num} words`
    result = (wordCount <= 150)
      ? { ...result, details: details(125), color: EMAIL_STATUS.AMBER }
      : { ...result, details: details(150), color: EMAIL_STATUS.RED }
  }

  return result
}

export async function tooManyLinks (html) {
  // never more than 1 link outside of signature
  let result = {
    rule: 'too_many_links',
    rule_violation: "You shouldn't have more than one link in your email body",
    details: null,
    color: EMAIL_STATUS.GREEN
  }

  const linkCount = (html.match(/<a /g) || []).length
  if (linkCount && linkCount > 1) {
    const details = `${linkCount} links in email body`
    result = (linkCount < 3)
      ? { ...result, details, color: EMAIL_STATUS.AMBER }
      : { ...result, details, color: EMAIL_STATUS.RED }
  }

  return result
}

export async function tooManyImages (html) {
  let result = {
    rule: 'too_many_images',
    rule_violation: "You shouldn't have more than one image in your email body",
    details: null,
    color: EMAIL_STATUS.GREEN
  }

  const imgCount = (html.match(/<img /g) || []).length
  if (imgCount && imgCount > 1) {
    const details = `${imgCount} images in email`
    result = (imgCount < 3)
      ? { ...result, details, color: EMAIL_STATUS.AMBER }
      : { ...result, details, color: EMAIL_STATUS.RED }
  }

  return result
}

export async function tooManyAttachments (html) {
  // no more than 1 attachment
  let result = {
    rule: 'too_many_attachments',
    rule_violation: "You shouldn't have more than one attachment in your email",
    details: null,
    color: EMAIL_STATUS.GREEN
  }

  const attachmentCount = (html.match(/<a href="https:\/\/interseller.intersellerusercontent.com/g) || []).length
  if (attachmentCount && attachmentCount > 1) {
    const details = `${attachmentCount} attachments in email`
    result = (attachmentCount < 3)
      ? { ...result, details, color: EMAIL_STATUS.AMBER }
      : { ...result, details, color: EMAIL_STATUS.RED }
  }

  return result
}

export async function longSubjectLine (subjectString) {
  // subject-line limit should be at most 78 characters.
  let result = {
    rule: 'long_subject_line',
    rule_violation: "If it's too long it can get cut short on small screens",
    details: null,
    color: EMAIL_STATUS.GREEN
  }

  if (subjectString && subjectString.length > 60) {
    const details = (num) => `Subject line is over ${num} characters long`
    result = (subjectString.length > 78)
      ? { ...result, details: details(78), color: EMAIL_STATUS.RED }
      : { ...result, details: details(60), color: EMAIL_STATUS.AMBER }
  }

  return result
}

export async function spamFilterPhrases (subjectString, bodyString) {
  // detect any spam words or phrases from this list - https://journeys.autopilotapp.com/blog/email-spam-trigger-words/
  let result = {
    rule: 'spam_filter_phrases',
    rule_violation: "To avoid triggering spam detectors don't use the following phrases in subject",
    details: null,
    color: EMAIL_STATUS.GREEN
  }

  const naughtyBodyWords = _findNaughtyWords(bodyString.toLowerCase())
  const naughtySubjectWords = _findNaughtyWords(subjectString.toLowerCase())
  const uniqueNaughtyWords = new Set([...naughtySubjectWords, ...naughtyBodyWords])
  const allNaughtyWords = Array.from(uniqueNaughtyWords).join(', ')

  if (allNaughtyWords) {
    result = {
      ...result,
      details: `Your email contains the following phrases: ${allNaughtyWords}`,
      color: EMAIL_STATUS.RED
    }
  }

  return result
}

const customFieldRegex = CUSTOM_FIELD_REGEX()
const fallbackCustomFieldRegex = FALLBACK_CUSTOM_FIELD_REGEX()
export async function noCustomFields (subjectString, bodyString) {
  // warn the user if they aren't using personalization tags - Encourage user to at least use {{ first_name }}
  let result = {
    rule: 'no_custom_fields',
    rule_violation: 'We recommend at least using the {{ first_name }} contact field',
    color: EMAIL_STATUS.GREEN,
    details: null
  }

  let foundCustomField
  if (subjectString) {
    // if the subject line isn't empty, the email is considered a new thread
    // and both the `body` and `subject` need to be checked.
    foundCustomField = subjectString.match(customFieldRegex) ||
      subjectString.match(fallbackCustomFieldRegex) ||
      bodyString.match(customFieldRegex) ||
      bodyString.match(fallbackCustomFieldRegex)
  } else {
    // if there is no subject line, the email is considered apart of an existing
    // thread and only the `body` needs to be checked.
    foundCustomField = bodyString.match(customFieldRegex) || bodyString.match(fallbackCustomFieldRegex)
  }

  if (!foundCustomField) {
    result = {
      ...result,
      details: 'No custom fields used. We recommend at least using the {{ first_name }} contact field',
      color: EMAIL_STATUS.RED
    }
  }

  return result
}

export async function shortFollowUp (markDown, selectedStepIndex) {
  // follow up email is only 1/2 sentences - Encourage user to look in templates for a good follow up email
  let result = {
    rule: 'short_follow_up',
    rule_violation: 'Look in templates for some good follow-up examples',
    color: EMAIL_STATUS.GREEN,
    details: null
  }

  const wordCount = markDown.split(' ').length
  if (selectedStepIndex > 0 && wordCount < 80) {
    result = (wordCount < 20)
      ? { ...result, details: 'Remember to present a new idea outside of just asking to read your last email', color: EMAIL_STATUS.RED }
      : { ...result, details: 'Follow up email is under 80 words in length', color: EMAIL_STATUS.AMBER }
  }

  return result
}

export async function tooManyEmails (steps) {
  // never more than 5 emails per sequence
  let result = {
    rule: 'too_many_emails',
    rule_violation: 'Avoid having more than 5 emails per Campaign',
    details: null,
    color: EMAIL_STATUS.GREEN
  }

  const emailSteps = steps.filter(step => step.get('type') === 'message')
  if (emailSteps?.size > 5) {
    const details = `${emailSteps.size} steps in sequence`
    result = (emailSteps.size >= 7)
      ? { ...result, details, color: EMAIL_STATUS.RED }
      : { ...result, details, color: EMAIL_STATUS.AMBER }
  }

  return result
}

export async function noScheduleLinks (html) {
  let result = {
    rule: 'avoid_using_scheduling_links',
    rule_violation: 'Remove the Caldenly link from your email',
    color: EMAIL_STATUS.GREEN,
    details: null
  }

  const calendlyLinkRegex = /https:\/\/calendly.com/i
  if (calendlyLinkRegex.test(html)) {
    result = {
      ...result,
      details: 'Adding scheduling links can make emails feel automated and less genuine',
      color: EMAIL_STATUS.RED
    }
  }

  return result
}
