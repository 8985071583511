/**
 * The Billing state selectors
 */

import { createSelector } from 'reselect'

const selectBillingDomain = () => state => state.get('billing')

const selectSubscription = () => createSelector(
  selectBillingDomain(),
  billingState => billingState.get('subscription')
)

const selectCustomer = () => createSelector(
  selectBillingDomain(),
  billingState => billingState.get('customer')
)

const selectPreview = () => createSelector(
  selectBillingDomain(),
  billingState => billingState.get('preview')
)

const selectUpdatePreview = () => createSelector(
  selectBillingDomain(),
  billingState => billingState.get('updatePreview')
)

const selectCharges = () => createSelector(
  selectBillingDomain(),
  billingState => billingState.get('charges')
)

const selectRequests = () => createSelector(
  selectBillingDomain(),
  billingState => billingState.get('requests')
)

const selectBillingEmails = () => createSelector(
  selectBillingDomain(),
  billingState => billingState.get('billingEmails')
)

const selectPricing = () => createSelector(
  selectBillingDomain(),
  billingState => billingState.get('pricing')
)

export {
  selectSubscription,
  selectCustomer,
  selectPreview,
  selectUpdatePreview,
  selectCharges,
  selectRequests,
  selectBillingEmails,
  selectPricing
}
