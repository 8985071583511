import R from 'utils/ramda'
import { limit, substring, length, indexOf } from 'stringz'
import { differenceInSeconds, distanceInWordsToNow, format, setHours, isSameYear } from 'date-fns'

export function pluralize (singular, plural, count) {
  return (count === 1) ? singular : plural
}

export function capitalize (str) {
  return str.charAt(0).toUpperCase() + str.substring(1)
}

export function ordinal (n) {
  const s = ['th', 'st', 'nd', 'rd']
  const v = n % 100
  return n + (s[(v - 20) % 10] || s[v] || s[0])
}

export function stripHtml (html = '') {
  return html.replace(/<\/?[^>]+(>|$)/g, '')
}

export function truncate (string, count) {
  if (length(string) > count) {
    return `${string.substring(0, count)}…`
  }
  return string
}

export function uniLength (string) {
  return length(string)
}

export function uniIndexOf (string, search, start = 0) {
  return indexOf(string, search, start)
}

export function replaceRange (s, start, end, substitute) {
  // Using substring from stingz to support unicode
  return limit(s, start) + substitute + substring(s, end)
}

export function titlecase (string) {
  if (string) {
    return string.replace(/\w\S*/g, txt => (txt.charAt(0).toUpperCase() + txt.substr(1).toLowerCase()))
  }
  return string
}

export function validateEmail (email) {
  const re = /^(([^<>()\[\]\\.,;:\s@"]+(\.[^<>()\[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/ // eslint-disable-line
  return re.test(email)
}

export function formatHourRange (startHour, endHour, style = 'short', html = false) {
  const start = setHours(new Date(), startHour)
  const end = setHours(new Date(), endHour)
  const endDisplay = format(end, 'h:59 a')

  if (html) {
    return `<span style="color: #243e6d">${format(start, 'h a')}</span> to <span style="color: #243e6d">${endDisplay}</span>`
  }

  return `${format(start, 'h a')}-${endDisplay}`
}

export function formatHours (schedule, day, style = 'long', html = true) {
  // Get only hours for this specific day
  const hours = schedule.filter(hour => (
    hour >= day * 24 && hour < (day + 1) * 24
  )).sort()

  const consecutive = []
  let current = []
  R.forEach((hour) => {
    if (!current.length) {
      return current.push(hour)
    }

    if (R.last(current) + 1 === hour) {
      return current.push(hour)
    }

    consecutive.push(current)
    current = [hour]
    return current
  }, hours)

  if (current.length) {
    consecutive.push(current)
  }

  return consecutive.map(row => (
    formatHourRange(row[0], row[row.length - 1], style, html)
  )).join(' | ')
}

export function timeFromNow (date) {
  const diff = differenceInSeconds(new Date(), date)
  if (diff < 30) {
    return 'a moment ago'
  }
  const options = {
    addSuffix: true,
    includeSeconds: false
  }
  const str = distanceInWordsToNow(date, options)
  return str.replace('about', '')
}

export function timeInFuture (date) {
  const diff = differenceInSeconds(new Date(), date)
  if (diff < (60 * 60 * 24 * 7)) {
    // If this week send day of week and time
    return format(date, 'ddd[,] MMM D [at] ha')
  }
  return format(date, 'ddd[,] MMM do [at] ha')
}

export function timeInFutureShort (date) {
  return timeInFuture(date).replace(' at ', ', ')
}

export function timeInFutureDay (date) {
  if (isSameYear(date)) {
    return format(date, 'dddd[,] MMMM D')
  }

  return format(date, 'dddd[,] MMMM D, YYYY')
}

export function isValidEmail (str) {
  if (!str) {
    return false
  }

  str = str.trim()

  // this regex is used as the default email pattern validation for html input tag
  // https://developer.mozilla.org/en-US/docs/Web/HTML/Element/input/email#basic_validation
  /* eslint-disable */
  const emailRegex = new RegExp("^[a-zA-Z0-9.!#$%&'*+\/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$", 'ig')
  /* eslint-enable */

  return emailRegex.test(str)
}
