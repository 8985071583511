import {
  FETCH_SESSION_REQUEST,
  FETCH_SESSION_SUCCESS,
  FETCH_SESSION_FAILURE,

  FETCH_SESSION_STATE_REQUEST,
  FETCH_SESSION_STATE_SUCCESS,
  FETCH_SESSION_STATE_FAILURE,

  FETCH_SESSION_SCOPES_REQUEST,
  FETCH_SESSION_SCOPES_SUCCESS,
  FETCH_SESSION_SCOPES_FAILURE,

  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE,
  UPDATE_TIMEZONE_REQUEST,

  SET_NOTIFICATION,
  RESET_NOTIFICATION,

  DELETE_AUTH_TOKEN_REQUEST
} from './constants'

export function fetchSession () {
  return {
    type: FETCH_SESSION_REQUEST
  }
}

export function fetchSessionSuccess ({ session }) {
  return {
    type: FETCH_SESSION_SUCCESS,
    payload: {
      session
    }
  }
}

export function fetchSessionFailure (err) {
  return {
    type: FETCH_SESSION_FAILURE,
    err
  }
}

export function fetchSessionState () {
  return {
    type: FETCH_SESSION_STATE_REQUEST
  }
}

export function fetchSessionStateSuccess ({ state }) {
  return {
    type: FETCH_SESSION_STATE_SUCCESS,
    payload: {
      state
    }
  }
}

export function fetchSessionStateFailure (err) {
  return {
    type: FETCH_SESSION_STATE_FAILURE,
    err
  }
}

export function fetchSessionScopes () {
  return {
    type: FETCH_SESSION_SCOPES_REQUEST
  }
}

export function fetchSessionScopesSuccess ({ scopes }) {
  return {
    type: FETCH_SESSION_SCOPES_SUCCESS,
    payload: {
      scopes
    }
  }
}

export function fetchSessionScopesFailure (err) {
  return {
    type: FETCH_SESSION_SCOPES_FAILURE,
    err
  }
}

export function updateSessionSuccess ({ session }) {
  return {
    type: UPDATE_SESSION_SUCCESS,
    payload: {
      session
    }
  }
}

export function updateSessionFailure (err) {
  return {
    type: UPDATE_SESSION_FAILURE,
    err
  }
}

export function updateTimezone (timezone) {
  return {
    type: UPDATE_TIMEZONE_REQUEST,
    timezone
  }
}

export function setNotification (notification) {
  return {
    type: SET_NOTIFICATION,
    notification
  }
}

export function resetNotification () {
  return {
    type: RESET_NOTIFICATION
  }
}

export function deleteAuthToken () {
  return {
    type: DELETE_AUTH_TOKEN_REQUEST
  }
}
