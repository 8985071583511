import styled from 'styled-components'

const Wrapper = styled.div`
  text-align: center;
  flex: 1 1 auto;
  padding: 2rem;
  overflow: auto;
`

export default Wrapper
