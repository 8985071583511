import {
  FETCH_SUBSCRIPTION_REQUEST,
  FETCH_SUBSCRIPTION_SUCCESS,
  FETCH_SUBSCRIPTION_FAILURE,

  CREATE_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_SUCCESS,
  CREATE_SUBSCRIPTION_FAILURE,

  UPDATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_SUCCESS,
  UPDATE_SUBSCRIPTION_FAILURE,

  FETCH_CUSTOMER_REQUEST,
  FETCH_CUSTOMER_SUCCESS,
  FETCH_CUSTOMER_FAILURE,

  FETCH_PREVIEW_REQUEST,
  FETCH_PREVIEW_SUCCESS,
  FETCH_PREVIEW_FAILURE,

  FETCH_UPDATE_PREVIEW_REQUEST,
  FETCH_UPDATE_PREVIEW_SUCCESS,
  FETCH_UPDATE_PREVIEW_FAILURE,
  FETCH_UPDATE_PREVIEW_RESET,

  FETCH_CHARGES_REQUEST,
  FETCH_CHARGES_SUCCESS,
  FETCH_CHARGES_FAILURE,

  FETCH_REQUESTS_REQUEST,
  FETCH_REQUESTS_SUCCESS,
  FETCH_REQUESTS_FAILURE,

  FETCH_BILLING_EMAIL_REQUEST,
  FETCH_BILLING_EMAIL_SUCCESS,
  FETCH_BILLING_EMAIL_FAILURE,

  FETCH_PRICING_REQUEST,
  FETCH_PRICING_SUCCESS,
  FETCH_PRICING_FAILURE,

  ADD_LOOKUPS_REQUEST,
  ADD_LOOKUPS_SUCCESS,
  ADD_LOOKUPS_FAILURE
} from './constants'

export function fetchSubscription () {
  return {
    type: FETCH_SUBSCRIPTION_REQUEST
  }
}

export function fetchSubscriptionSuccess ({ subscription }) {
  return {
    type: FETCH_SUBSCRIPTION_SUCCESS,
    payload: {
      subscription
    }
  }
}

export function fetchSubscriptionFailure (err) {
  return {
    type: FETCH_SUBSCRIPTION_FAILURE,
    err
  }
}

export function createSubscription ({
  coupon,
  seats,
  /* eslint-disable camelcase */
  stripe_token_id,
  yearly,
  recruiter
}) {
  return {
    type: CREATE_SUBSCRIPTION_REQUEST,
    params: {
      coupon,
      seats,
      stripe_token_id,
      yearly,
      recruiter
    }
  }
}

export function createSubscriptionSuccess ({ subscription }) {
  return {
    type: CREATE_SUBSCRIPTION_SUCCESS,
    payload: {
      subscription
    }
  }
}

export function createSubscriptionFailure (err) {
  return {
    type: CREATE_SUBSCRIPTION_FAILURE,
    err
  }
}

export function updateSubscription ({
  coupon,
  seats,
  /* eslint-disable camelcase */
  stripe_token_id,
  yearly,
  /* eslint-disable camelcase */
  proration_date
}) {
  return {
    type: UPDATE_SUBSCRIPTION_REQUEST,
    params: {
      coupon,
      seats,
      stripe_token_id,
      yearly,
      proration_date
    }
  }
}

export function updateSubscriptionSuccess ({ subscription }) {
  return {
    type: UPDATE_SUBSCRIPTION_SUCCESS,
    payload: {
      subscription
    }
  }
}

export function updateSubscriptionFailure (err) {
  return {
    type: UPDATE_SUBSCRIPTION_FAILURE,
    err
  }
}

export function fetchCustomer () {
  return {
    type: FETCH_CUSTOMER_REQUEST

  }
}

export function fetchCustomerSuccess ({ customer }) {
  return {
    type: FETCH_CUSTOMER_SUCCESS,
    payload: {
      customer
    }
  }
}

export function fetchCustomerFailure (err) {
  return {
    type: FETCH_CUSTOMER_FAILURE,
    err
  }
}

export function fetchPreview () {
  return {
    type: FETCH_PREVIEW_REQUEST
  }
}

export function fetchPreviewSuccess ({ preview }) {
  return {
    type: FETCH_PREVIEW_SUCCESS,
    payload: {
      preview
    }
  }
}

export function fetchPreviewFailure (err) {
  return {
    type: FETCH_PREVIEW_FAILURE,
    err
  }
}

export function fetchUpdatePreview (query) {
  return {
    type: FETCH_UPDATE_PREVIEW_REQUEST,
    query
  }
}

export function fetchUpdatePreviewSuccess ({ preview }) {
  return {
    type: FETCH_UPDATE_PREVIEW_SUCCESS,
    payload: {
      preview
    }
  }
}

export function fetchUpdatePreviewFailure (err) {
  return {
    type: FETCH_UPDATE_PREVIEW_FAILURE,
    err
  }
}

export function resetUpdatePreview () {
  return {
    type: FETCH_UPDATE_PREVIEW_RESET
  }
}

export function fetchCharges () {
  return {
    type: FETCH_CHARGES_REQUEST
  }
}

export function fetchChargesSuccess ({ charges }) {
  return {
    type: FETCH_CHARGES_SUCCESS,
    payload: {
      charges
    }
  }
}

export function fetchChargesFailure (err) {
  return {
    type: FETCH_CHARGES_FAILURE,
    err
  }
}

export function fetchRequests (cyclesAgo = 0) {
  return {
    type: FETCH_REQUESTS_REQUEST,
    payload: {
      cyclesAgo
    }
  }
}

export function fetchRequestsSuccess ({ requests }) {
  return {
    type: FETCH_REQUESTS_SUCCESS,
    payload: {
      requests
    }
  }
}

export function fetchRequestsFailure (err) {
  return {
    type: FETCH_REQUESTS_FAILURE,
    err
  }
}

export function fetchBillingEmail () {
  return {
    type: FETCH_BILLING_EMAIL_REQUEST
  }
}

export function fetchBillingEmailSuccess ({ billingEmails }) {
  return {
    type: FETCH_BILLING_EMAIL_SUCCESS,
    payload: {
      billingEmails
    }
  }
}

export function fetchBillingEmailFailure (err) {
  return {
    type: FETCH_BILLING_EMAIL_FAILURE,
    err
  }
}

export function fetchPricing (query) {
  return {
    type: FETCH_PRICING_REQUEST,
    query
  }
}

export function fetchPricingSuccess ({ pricing }) {
  return {
    type: FETCH_PRICING_SUCCESS,
    payload: {
      pricing
    }
  }
}

export function fetchPricingFailure (err) {
  return {
    type: FETCH_PRICING_FAILURE,
    err
  }
}

export function addLookups (query) {
  return {
    type: ADD_LOOKUPS_REQUEST,
    query
  }
}

export function addLookupsSuccess () {
  return {
    type: ADD_LOOKUPS_SUCCESS,
    payload: {}
  }
}

export function addLookupsFailure (err) {
  return {
    type: ADD_LOOKUPS_FAILURE,
    err
  }
}
