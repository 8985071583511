/**
*
* CompanyPreview Component
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { space } from 'styled-system'

import Loading from 'components/Loading'
import Label from 'elements/Label'
import Divider from 'elements/Divider'

const Wrapper = styled.div`
  width: 100%;
  padding-top: 0.5rem;

  ${space}
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${space}
`

const ContactName = styled(Label)`
  text-overflow: ellipsis;
  overflow: hidden;
  color: ${props => props.theme.titleColor};
`

const FormDetailContainer = styled.div`
  width: 100%;
  text-align: left;

  ${space}
`

const RowDetail = styled.div`
  >div {
    display: inline;
  }
`

const FormTitle = styled(Label)`
  margin-right: 0.5rem;
  margin-top: 0;
  color: ${props => props.theme.titleColor};
`

const FormValue = styled(Label)`
  text-overflow: ellipsis;
  overflow: hidden;
`

class CompanyPreview extends Component {
  render () {
    const {
      contactCompany,
      hideDivider,
      ...rest
    } = this.props

    if (contactCompany.get('loading')) {
      return (
        <Wrapper>
          <Loading padding='1rem' size={32} align='center' />
        </Wrapper>
      )
    }

    if (!contactCompany.get('data')) {
      return null
    }

    const companyData = contactCompany.get('data')

    const companyName = companyData.get('name')
    const description = companyData.get('description')
    const industry = companyData.get('industry')
    const employeeRange = companyData.get('employee_range')
    const location = companyData.get('location')
    const url = companyData.get('url')

    return (
      <Wrapper {...rest}>
        {!hideDivider && <Divider mb='1rem' />}

        <NameContainer>
          <ContactName style={{ fontWeight: 700 }}>
            {companyName}
          </ContactName>
        </NameContainer>

        {(description || url) &&
          <FormValue align='left' mr='0.25rem'>
            {url && <Label mr='0.5rem'><a href={url} target='_new'>{url}</a></Label>}
            {description && <Label mt='0.5rem'>{description}</Label>}
          </FormValue>}

        {(industry || employeeRange || location) &&
          <FormDetailContainer pt='0.5rem'>

            {industry &&
              <RowDetail>
                <FormTitle>
                  Industry
                </FormTitle>
                <FormValue>
                  {industry}
                </FormValue>
              </RowDetail>}

            {location &&
              <RowDetail>
                <FormTitle>
                  Location
                </FormTitle>
                <FormValue>
                  {location}
                </FormValue>
              </RowDetail>}

            {employeeRange &&
              <RowDetail>
                <FormTitle>
                  Employees
                </FormTitle>
                <FormValue>
                  {employeeRange}
                </FormValue>
              </RowDetail>}

          </FormDetailContainer>}
      </Wrapper>
    )
  }
}

CompanyPreview.propTypes = {
  contactCompany: PropTypes.object,
  hideDivider: PropTypes.boolean
}

export default CompanyPreview
