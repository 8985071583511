import { UNSUBSCRIBE_URL_VAR } from './constants'

function wrapLink (editor, url) {
  const { value } = editor
  const selectedImages = value.blocks.filter(block => block.type === 'image')
  // wrap all text with link
  editor.wrapInline({
    type: 'link',
    data: { href: url }
  })

  // iterate over each image and wrap with link
  selectedImages.forEach(img => {
    const linkData = { href: url }
    editor.setNodeByKey(img.key, {
      data: img.data.merge(linkData)
    })
  })
}

function wrapUnsubscribeLink (editor, text) {
  if (!text) {
    return
  }

  editor
    .insertText(text)
    .moveFocusBackward(text.length)
    .wrapInline({
      type: 'link',
      data: { href: UNSUBSCRIBE_URL_VAR }
    })
}

function unwrapLink (editor) {
  const { value } = editor

  editor
    .focus()
    .moveToStartOfInline()
    .moveAnchorToEndOfInline()
    .select()
    .unwrapInline('link')

  const selectedImages = value.blocks.filter(block => block.type === 'image')
  selectedImages.forEach(img => {
    editor.setNodeByKey(img.key, { data: img.get('data').remove('href') })
  })
}

function insertImage (editor, src, target) {
  if (target) {
    editor.select(target)
  }

  editor.insertBlock({
    type: 'image',
    data: { src }
  })
}

function insertVideoLink (editor, imgLink, hrefLink) {
  editor.insertBlock({
    type: 'image',
    data: {
      src: imgLink,
      href: hrefLink
    }
  })
}

function insertEmoji (editor, emojiCode) {
  editor
    .insertInline({ type: 'emoji', data: { code: emojiCode } })
    .moveToStartOfNextText()
}

function insertCustomField (editor, customField) {
  editor.insertText(customField)
}

function toggleOrderedList (editor) {
  if (!editor.isUnorderedListActive()) {
    if (editor.isOrderedListActive()) {
      editor
        .setBlocks('paragraph')
        .unwrapBlock('ordered_list')
    } else {
      editor
        .setBlocks('list_item')
        .wrapBlock('ordered_list')
    }
  }
}

function toggleUnorderedList (editor) {
  if (!editor.isOrderedListActive()) {
    if (editor.isUnorderedListActive()) {
      editor
        .setBlocks('paragraph')
        .unwrapBlock('unordered_list')
    } else {
      editor
        .setBlocks('list_item')
        .wrapBlock('unordered_list')
    }
  }
}

const subjectCommands = {
  insertEmoji,
  insertCustomField
}

const bodyCommands = {
  wrapLink,
  unwrapLink,
  insertImage,
  insertEmoji,
  insertVideoLink,
  insertCustomField,
  toggleOrderedList,
  toggleUnorderedList,
  wrapUnsubscribeLink
}

export {
  subjectCommands,
  bodyCommands
}
