export const FETCH_SESSION_REQUEST = 'App/FETCH_SESSION_REQUEST'
export const FETCH_SESSION_SUCCESS = 'App/FETCH_SESSION_SUCCESS'
export const FETCH_SESSION_FAILURE = 'App/FETCH_SESSION_FAILURE'

export const FETCH_SESSION_STATE_REQUEST = 'App/FETCH_SESSION_STATE_REQUEST'
export const FETCH_SESSION_STATE_SUCCESS = 'App/FETCH_SESSION_STATE_SUCCESS'
export const FETCH_SESSION_STATE_FAILURE = 'App/FETCH_SESSION_STATE_FAILURE'

export const FETCH_SESSION_SCOPES_REQUEST = 'App/FETCH_SESSION_SCOPES_REQUEST'
export const FETCH_SESSION_SCOPES_SUCCESS = 'App/FETCH_SESSION_SCOPES_SUCCESS'
export const FETCH_SESSION_SCOPES_FAILURE = 'App/FETCH_SESSION_SCOPES_FAILURE'

export const UPDATE_SESSION_REQUEST = 'App/UPDATE_SESSION_REQUEST'
export const UPDATE_SESSION_SUCCESS = 'App/UPDATE_SESSION_SUCCESS'
export const UPDATE_SESSION_FAILURE = 'App/UPDATE_SESSION_FAILURE'

export const SET_NOTIFICATION = 'App/SET_NOTIFICATION'
export const RESET_NOTIFICATION = 'App/RESET_NOTIFICATION'

export const UPDATE_TIMEZONE_REQUEST = 'App/UPDATE_TIMEZONE_REQUEST'

export const DELETE_AUTH_TOKEN_REQUEST = 'Settings/DELETE_AUTH_TOKEN_REQUEST'

export const NOTIFICATION_TYPES = {
  SUCCESS: 'NOTIFICATION_SUCCESS',
  ERROR: 'NOTIFICATION_ERROR',
  CHROME: 'NOTIFICATION_CHROME',
  LOADING: 'NOTIFICATION_LOADING'
}

export const ACCOUNT_NAMES = {
  google: 'Gmail',
  microsoft: 'Outlook'
}
