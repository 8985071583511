import {
  FETCH_CRM_LIST_REQUEST,
  FETCH_CRM_LIST_SUCCESS,
  FETCH_CRM_LIST_FAILURE,

  // Fetch Current CRM
  FETCH_CRM_REQUEST,
  FETCH_CRM_SUCCESS,
  FETCH_CRM_FAILURE,

  // Refresh Current CRM
  REFRESH_CRM_REQUEST,

  // Set Current CRM
  CREATE_CRM_REQUEST,
  CREATE_CRM_SUCCESS,
  CREATE_CRM_FAILURE,

  // Update Current CRM
  UPDATE_CRM_REQUEST,
  UPDATE_CRM_SUCCESS,
  UPDATE_CRM_FAILURE,

  // Delete Current CRM
  DELETE_CRM_REQUEST,
  DELETE_CRM_SUCCESS,
  DELETE_CRM_FAILURE,

  // Find Sequence Fields
  FETCH_SEQUENCE_FIELDS_REQUEST,
  FETCH_SEQUENCE_FIELDS_SUCCESS,
  FETCH_SEQUENCE_FIELDS_FAILURE,

  // Fetch Configured Zaps
  FETCH_ZAPS_REQUEST,
  FETCH_ZAPS_SUCCESS,
  FETCH_ZAPS_FAILURE,

  // Fetch Suggested Zaps
  FETCH_ZAP_TEMPLATES_REQUEST,
  FETCH_ZAP_TEMPLATES_SUCCESS,
  FETCH_ZAP_TEMPLATES_FAILURE,

  DELETE_CRON_REQUEST,
  DELETE_CRON_SUCCESS,
  DELETE_CRON_FAILURE,

  FETCH_API_KEY_REQUEST,
  FETCH_API_KEY_SUCCESS,
  FETCH_API_KEY_FAILURE,

  // Clone Settings
  CLONE_SETTINGS_REQUEST,
  CLONE_SETTINGS_SUCCESS,
  CLONE_SETTINGS_FAILURE
} from './constants'

export function fetchCrmList () {
  return {
    type: FETCH_CRM_LIST_REQUEST
  }
}

export function fetchCrmListSuccess ({ crms }) {
  return {
    type: FETCH_CRM_LIST_SUCCESS,
    payload: {
      crms
    }
  }
}

export function fetchCrmListFailure (err) {
  return {
    type: FETCH_CRM_LIST_FAILURE,
    err
  }
}

export function fetchActiveCrm (params) {
  return {
    type: FETCH_CRM_REQUEST,
    params
  }
}

export function fetchCrmSuccess ({ crm }) {
  return {
    type: FETCH_CRM_SUCCESS,
    payload: {
      crm
    }
  }
}

export function fetchCrmFailure (err) {
  return {
    type: FETCH_CRM_FAILURE,
    err
  }
}

export function refreshActiveCrm (params = {}) {
  return {
    type: REFRESH_CRM_REQUEST,
    params: {
      ...params,
      refresh: true
    }
  }
}

export function createCrm (crmType, auth) {
  return {
    type: CREATE_CRM_REQUEST,
    crmType,
    auth
  }
}

export function createCrmSuccess ({ crm }) {
  return {
    type: CREATE_CRM_SUCCESS,
    payload: {
      crm
    }
  }
}

export function createCrmFailure (err) {
  return {
    type: CREATE_CRM_FAILURE,
    err
  }
}

export function updateCrm (params) {
  return {
    type: UPDATE_CRM_REQUEST,
    params
  }
}

export function updateCrmSuccess ({ crm }) {
  return {
    type: UPDATE_CRM_SUCCESS,
    payload: {
      crm
    }
  }
}

export function updateCrmFailure (err) {
  return {
    type: UPDATE_CRM_FAILURE,
    err
  }
}

export function deleteCrm () {
  return {
    type: DELETE_CRM_REQUEST
  }
}

export function deleteCrmSuccess () {
  return {
    type: DELETE_CRM_SUCCESS
  }
}

export function deleteCrmFailure (err) {
  return {
    type: DELETE_CRM_FAILURE,
    err
  }
}

export function fetchSequenceFields () {
  return {
    type: FETCH_SEQUENCE_FIELDS_REQUEST
  }
}

export function fetchSequenceFieldsSuccess ({ fields }) {
  return {
    type: FETCH_SEQUENCE_FIELDS_SUCCESS,
    payload: {
      fields
    }
  }
}

export function fetchSequenceFieldsFailure (err) {
  return {
    type: FETCH_SEQUENCE_FIELDS_FAILURE,
    err
  }
}

export function fetchZaps () {
  return {
    type: FETCH_ZAPS_REQUEST
  }
}

export function fetchZapsSuccess ({ zaps }) {
  return {
    type: FETCH_ZAPS_SUCCESS,
    payload: {
      zaps
    }
  }
}

export function fetchZapsFailure (err) {
  return {
    type: FETCH_ZAPS_FAILURE,
    err
  }
}

export function fetchZapTemplates () {
  return {
    type: FETCH_ZAP_TEMPLATES_REQUEST
  }
}

export function fetchZapTemplatesSuccess ({ zapTemplates }) {
  return {
    type: FETCH_ZAP_TEMPLATES_SUCCESS,
    payload: {
      zapTemplates
    }
  }
}

export function fetchZapTemplatesFailure (err) {
  return {
    type: FETCH_ZAP_TEMPLATES_FAILURE,
    err
  }
}

export function deleteCron (params) {
  return {
    type: DELETE_CRON_REQUEST,
    params
  }
}

export function deleteCronSuccess (id) {
  return {
    type: DELETE_CRON_SUCCESS,
    id
  }
}

export function deleteCronFailure (err) {
  return {
    type: DELETE_CRON_FAILURE,
    err
  }
}

export function fetchApiKey ({ regenerate = false } = {}) {
  return {
    type: FETCH_API_KEY_REQUEST,
    regenerate
  }
}

export function fetchApiKeySuccess (apiKey) {
  return {
    type: FETCH_API_KEY_SUCCESS,
    apiKey
  }
}

export function fetchApiKeyFailure (err) {
  return {
    type: FETCH_API_KEY_FAILURE,
    err
  }
}

export function cloneSettings () {
  return {
    type: CLONE_SETTINGS_REQUEST
  }
}

export function cloneSettingsSuccess ({ modified }) {
  return {
    type: CLONE_SETTINGS_SUCCESS,
    payload: {
      modified
    }
  }
}

export function cloneSettingsFailure (err) {
  return {
    type: CLONE_SETTINGS_FAILURE,
    err
  }
}
