import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import Immutable from 'immutable'
import R from 'utils/ramda'
import Button from 'components/Button'
import Modal from 'components/Modal'
import NavigationLink from 'components/NavigationLink'
import ActiveState from 'elements/ActiveState'
import Title from 'elements/Title'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { addRecentSequence } from 'containers/Navigation/actions'
import {
  selectSession,
  selectState
} from 'containers/App/selectors'
import { selectSequences } from 'containers/Dashboard/selectors'
import { fetchSequences } from 'containers/Dashboard/actions'
import SequenceStatus from 'elements/SequenceStatus'
import Loading from 'components/Loading'
import { findLookups, clearLookupToasts } from 'utils/toast'
import { fetchTeamMembers } from 'containers/Team/actions'
import { selectMembers } from 'containers/Team/selectors'
import LogoImage from 'elements/LogoImage'
import CreateContact from './components/CreateContact'
import LaunchSequence from './components/LaunchSequence'
import Settings from './components/Settings'
import ComponentError from 'elements/ComponentError'
import Wrapper from './Wrapper'
import { fetchActiveCrm, refreshActiveCrm } from 'containers/Integrations/actions'
import { selectActiveCrm } from 'containers/Integrations/selectors'

import {
  selectLoading,
  selectSequence,
  selectSequenceState,
  selectContacts,
  selectStats,
  selectStepStats,
  selectCsvDownloadLink,
  selectPdfExport,
  selectContactActivity,
  selectContactMessages,
  selectContactIntegration,
  selectContactInaccuracyLoading,
  selectContactCompany,
  selectNewContacts,
  selectSequencePreview,
  selectContactsHasMore,
  selectContact,
  selectCreateCsv,
  selectImportCsv,
  selectTemplates,
  selectLookups,
  selectPreviewContacts,
  selectCreateFile,
  selectSimilarContacts,
  selectCrm,
  selectContactReply,
  selectNewSequence,
  selectContactMessagesLoading,
  selectSentMessages,
  selectContactErrors,
  selectClearedContactErrors,
  selectSelectedContactCount,
  selectOgp,
  selectAddresses,
  selectContactSteps,
  selectContactPhone,
  selectImportCrmStep,
  selectShowWarningModal,
  selectSequenceSignature
} from './selectors'

import {
  fetchSequence,
  deleteSequence,
  fetchSequenceState,
  refreshSequenceState,
  fetchSequenceStats,
  fetchContact,
  updateContact,
  repliedContact,
  fetchContactSuccess,
  fetchContacts,
  fetchStepStats,
  fetchExportCsv,
  fetchExportPdf,
  fetchContactActions,
  fetchContactActionsReset,
  fetchContactMessages,
  fetchContactMessagesReset,
  fetchContactIntegration,
  fetchContactCompany,
  reportContactInaccuracy,
  createContact,
  createContactReset,
  fetchSequencePreview,
  updateSequence,
  restoreSequence,
  sendTestEmail,
  deleteContacts,
  moveContacts,
  updateContacts,
  createCsv,
  updateCsv,
  importCsv,
  addCustomField,
  updateContactStep,
  removeContactStep,
  fetchTemplates,
  createTemplate,
  unsubscribeContact,
  blockContact,
  fetchLookupStatus,
  fetchPreviewContacts,
  deleteTemplate,
  forceSendStep,
  createFile,
  cloneContact,
  copyContacts,
  fetchSimilarContacts,
  fetchCrm,
  refreshCrm,
  fetchContactReply,
  transferSequence,
  newSequenceReset,
  fetchSentMessages,
  fetchContactErrorsReset,
  fetchSentMessagesReset,
  fetchContactErrors,
  updateContactErrors,
  fetchSelectedContactCount,
  fetchOgp,
  fetchAddresses,
  fetchContactSteps,
  fetchContactStepsReset,
  fetchContactPhone,
  importCrmContacts,
  importCrmOptionSelected,
  importCrmContactsReset,
  hideWarningModal,
  resyncToCrm,
  phoneNumberLookups,
  fetchSequenceSignature
} from './actions'

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  padding: 0 2rem;
`

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`

const StatusContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`

const NavContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: end;
  align-items: center;
`

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
`

const OwnerContainer = styled.div`
  color: ${props => props.theme.titleColor};
  margin-top: 0.5rem;
  text-align: left;
`

const UserBadge = styled.div`
  background-color: ${props => props.theme.colors.gray10};
  border-radius: 9999px;
  border-style: solid;
  border-width: 1px;
  border-color: ${props => props.theme.borderColor};
  display: inline-flex;
  align-items: center;
  padding: 0.3rem 1rem;
  margin-right: 0.5rem;
  margin-bottom: 0.5rem;

  >svg {
    height: 20px;
  }
`

const ClickableUserBadge = styled(UserBadge)`
  cursor: pointer;
`

const BadgeText = styled.span`
  margin-left: 0.5rem;
  margin-top: -1px;
`

const Avatar = styled.div`
  background-size: cover;
  background-position: center;
  background-color: #FFF;
  border-radius: 9999px;
  width: 20px;
  height: 20px;
  background-image: url(${props => props.photoUrl || '/bear_avatar.png'});
`

const ArchivedBanner = styled.div`
  color: ${props => props.theme.colors.darkYellow};
  background-color: ${props => props.theme.colors.lightYellow};
  text-align: center;
  padding: 0.75rem 1rem;
  margin: 1rem 2rem;
  margin-bottom: 0;

  border: 1px solid ${props => props.theme.colors.darkYellow};
  border-radius: 3px;
`

const ErrorContainer = styled.div`
  padding: 2rem;
  max-width: 480px;
  margin: 0 auto;
`

class Sequence extends PureComponent {
  constructor (props) {
    super(props)

    // Default state
    this.state = {
      downloadingCSV: false,
      showNewContactModal: false,
      showLaunchSequence: false,
      showSettingsModal: false,
      lookupLabel: '',
      filter: null,
      search: null,
      settingsTab: 0
    }
  }

  refreshStateTimer = null

  clearRefreshTimer = () => {
    if (this.refreshStateTimer) {
      clearInterval(this.refreshStateTimer)
    }
  }

  startRefreshTimer = () => {
    const actions = this.props.actions
    const sequenceId = this.props.params.id

    this.refreshStateTimer = setInterval(function () {
      actions.refreshSequenceState(sequenceId)
    }, 60000)
  }

  onFocus = () => {
    this.startRefreshTimer()
  }

  onBlur = () => {
    this.clearRefreshTimer()
  }

  UNSAFE_componentWillMount () {
    const sequenceId = this.props.params.id
    this.fetchSequence(sequenceId)
    this.props.actions.fetchActiveCrm({ lightweight: true })
    this.startRefreshTimer()
    window.addEventListener('focus', this.onFocus)
    window.addEventListener('blur', this.onBlur)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (nextProps.sequenceData && nextProps.sequenceData.get('deleted')) {
      this.props.router.push('/')
      return
    }

    if (nextProps.newSequence && nextProps.newSequence.getIn(['data', '_id'])) {
      const sequenceId = nextProps.newSequence.getIn(['data', '_id'])
      this.setState({
        showSettingsModal: false
      })
      this.props.router.push(`/sequence/${sequenceId}/contacts`)
      this.props.actions.newSequenceReset()
      return
    }

    if (this.props.params.id !== nextProps.params.id) {
      const sequenceId = nextProps.params.id
      this.fetchSequence(sequenceId)
    }

    // If getting sequence add to recent sequences
    if (nextProps.sequenceData && nextProps.sequenceData.get('data') && nextProps.sequenceData.get('data') !== this.props.sequenceData.get('data')) {
      this.props.actions.addRecentSequence(nextProps.sequenceData.get('data'))
    }

    if (nextProps.sequenceData && nextProps.sequenceData.getIn(['data', 'title'])) {
      document.title = `Interseller | ${nextProps.sequenceData.getIn(['data', 'title'])}`
    }

    // Only update from props if the plugins have been loaded
    if (nextProps.csvDownload && this.state.downloadingCSV) {
      this.setState({ downloadingCSV: false })
      // If csv becomes very large we can show a modal to download as the server prepares
      // Just directly downloading for now
      window.location.href = nextProps.csvDownload
    }

    if (nextProps.pdfExport.getIn(['data', 'downloadLink']) && this.props.pdfExport.get('loading')) {
      window.location.assign(nextProps.pdfExport.getIn(['data', 'downloadLink']))
    }

    // Update logic for new contact added
    if (nextProps.newContacts && nextProps.newContacts.get('data') && this.state.showNewContactModal) {
      // Remove filter in order to show new contact and close modal
      this.setState({
        filter: null,
        search: null,
        showNewContactModal: false
      })
      const sequenceId = this.props.params.id
      this.fetchContacts(sequenceId, {})
      this.props.actions.createContactReset()
    }

    if (nextProps.lookups) {
      const lookupStatus = nextProps.lookups.get('data')
      if (lookupStatus && lookupStatus.count() > 0 && !lookupStatus.equals(this.props.lookups.get('data'))) {
        if (findLookups(this.props.params.id, lookupStatus)) {
          setTimeout(() => { this.props.actions.fetchLookupStatus(this.props.params.id) }, 2500)
        }
      }
    }
  }

  componentWillUnmount () {
    // Remove lookup notifications if any
    clearLookupToasts()

    // clear any state refresh timers
    this.clearRefreshTimer()
    window.removeEventListener('focus', this.onFocus)
    window.removeEventListener('blur', this.onBlur)
  }

  fetchSequence = (sequenceId) => {
    const {
      location,
      actions
    } = this.props
    const query = location.query || {}

    // Fetch sequence details
    actions.fetchSequence(sequenceId)

    this.fetchContacts(sequenceId, query)

    // If filter is set, update state for selected filter
    if (query.filter) {
      const filter = Array.isArray(query.filter) ? query.filter : [query.filter]
      this.setState({ filter })
    }

    if (query.search) {
      this.setState({ search: query.search })
    }

    if (query.sort) {
      this.setState({ sortKey: query.sort })
    }

    // Fetch sequence stats
    actions.fetchSequenceStats(sequenceId)
    actions.fetchSequenceState(sequenceId)
    actions.fetchStepStats(sequenceId)
    actions.fetchLookupStatus(sequenceId)
    actions.fetchCrm(sequenceId)
    actions.fetchAddresses(sequenceId)
  }

  fetchContacts = (sequenceId, query = {}, skip = 0, limit = 50) => {
    // Fetch contacts with filter and sorting
    if (!R.equals(query, this.props.location.query)) {
      if (!query.search) {
        delete query.search
      }

      if (!query.filter) {
        delete query.filter
      }

      this.props.router.push({
        ...this.props.location,
        query
      })
    }

    this.props.actions.fetchContacts(
      sequenceId,
      R.merge(query, { skip, limit })
    )
  }

  downloadCSV = (sequenceId, selectedContactIds = []) => {
    const filter = (selectedContactIds.length > 0 ? selectedContactIds : this.state.filter)
    const query = this.state.query
    this.props.actions.fetchExportCsv(sequenceId, { filter, query })
    this.setState({ downloadingCSV: true })
  }

  titleForFilter = (filter) => {
    switch (filter.toLowerCase()) {
      case 'ongoing': return 'Actively Messaging'
      case 'opened': return 'Opened'
      case 'visited': return 'Clicked'
      case 'replied': return 'Replied'
      case 'booked': return 'Booked Meeting'
      case 'error': return 'Need Attention'
      case 'bounced': return 'Bounced'
      case '!opened': return 'No Opens'
      case '!visited': return 'No Clicks'
      case '!replied': return 'No Reply'
      case '!booked': return 'No Booked Meeting'
      case '!error': return 'No Error'
      case '!bounced': return 'No Bounce'
      default: return 'All Contacts'
    }
  }

  createContact = (params) => {
    const sequenceId = this.props.params.id
    this.props.actions.createContact(sequenceId, params)
  }

  launchSequence = (params) => {
    const sequenceId = this.props.params.id
    this.props.actions.updateSequence(sequenceId, { ...params, active: true }, 'Sequence Launched! 🚀')
    this.setState({
      showLaunchSequence: false
    })
  }

  openSettings = (tabId) => {
    this.props.actions.fetchTeamMembers()

    const newState = {
      showSettingsModal: true
    }

    if (tabId !== undefined) {
      newState.settingsTab = tabId
    }

    this.setState(newState)
  }

  render () {
    const {
      actions,
      contactActivity,
      contactMessages,
      contactIntegration,
      contactInaccuracyLoading,
      contactCompany,
      similarContacts,
      contacts,
      loading,
      location,
      router,
      sequenceData,
      sequenceStateData,
      statsData,
      stepStats,
      hasMore,
      sequences,
      selectedContact,
      csv,
      importingCsv,
      pdfExport,
      session,
      templates,
      newContacts,
      previewContacts,
      crm,
      uploadedFile,
      state,
      members,
      newSequence,
      sentMessages,
      contactErrors,
      selectedContactCount,
      ogp,
      addresses,
      contactSteps,
      previewSteps,
      contactPhone,
      activeCRM,
      importCrmStep,
      contactReply,
      showWarningModal,
      createCsv,
      sequenceSignature,
      params
    } = this.props

    const {
      sortKey,
      settingsTab,
      filter,
      search
    } = this.state

    const sequenceLoading = sequenceData.get('loading')
    if (sequenceLoading) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    const sequenceNotFound = sequenceData.get('error') === 'NOT_FOUND'
    if (sequenceNotFound) {
      if (state.get('trusted') || state.get('impersonating')) {
        if (this.props.params.contactId) {
          window.location = `https://interseller.io/admin/contacts/${this.props.params.contactId}`
        } else {
          window.location = `https://interseller.io/admin/campaigns/${this.props.params.id}`
        }

        return (<Wrapper />)
      }

      return (
        <Wrapper>
          <ErrorContainer>
            <ComponentError message='404 NOT_FOUND — The sequence could not be found or you are trying to access a sequence that you do not have permission to. Please check the URL or the account you are logged in as and try again.' />
            <Button
              label='Back to Sequences'
              mt='2rem'
              handleClick={() => {
                router.push('/')
              }}
            />
          </ErrorContainer>
        </Wrapper>
      )
    }

    const sequenceId = params.id
    const sequence = sequenceData.get('data')
    const sequenceState = sequenceStateData.get('data')
    const stats = statsData.getIn(['data', 'state'])
    const statsLoading = statsData.get('loading')
    const active = sequence.get('active')
    const group = sequence.get('group')

    const photoUrl = sequence.getIn(['_user', 'photo_url'])
    const ownerId = sequence.getIn(['_user', '_id'])
    const ownerName = sequence.getIn(['_user', 'full_name'])
    const ownerEmail = sequence.getIn(['_user', 'email'])
    const isSequenceOwner = session.get('_id') === ownerId
    const isManager = state.get('is_manager')

    const schedule = sequence.get('schedule')
    let customSchedule = false
    if (schedule && schedule.count() > 0) {
      customSchedule = true
    }

    const customCrm = crm.getIn(['data', 'name']) && sequence.get('crm_customized')
    const crmDisabled = crm.getIn(['data', 'name']) && sequence.get('crm_disabled')

    const crmLists = crm.getIn(['data', 'lists'])
    let crmList = null
    if (crmLists) {
      crmList = crmLists.find((list) => {
        return list.get('id') === sequence.get('crm_list')
      })
    }

    const crmStages = crm.getIn(['data', 'stages'])
    let crmStage = null
    if (crmStages) {
      crmStage = crmStages.find((stage) => {
        return stage.get('id') === sequence.get('crm_stage')
      })
    }

    const crmModel = sequence.get('crm_model')

    const query = location.query || {}
    const deletedAt = sequence.get('deleted_at')
    const isArchived = !!deletedAt
    const canRestore = isManager || isSequenceOwner

    let ownerContent

    if (ownerName) {
      ownerContent = (
        <OwnerContainer>
          <ClickableUserBadge onClick={() => { router.push(`/?userId=${ownerId}`) }} title={ownerEmail}>
            <Avatar photoUrl={photoUrl} />
            <BadgeText>{ownerName}</BadgeText>
          </ClickableUserBadge>
          {group &&
            <ClickableUserBadge onClick={() => { router.push(`/?folder=${encodeURIComponent(group)}&userId=all`) }}>
              <FontAwesomeIcon
                icon={['fas', 'folder']}
                transform={{

                }}
              />
              <BadgeText>{group}</BadgeText>
            </ClickableUserBadge>}
          {customSchedule && !isArchived &&
            <ClickableUserBadge onClick={() => { this.openSettings(1) }}>
              <FontAwesomeIcon
                icon={['fas', 'calendar']}
                transform={{
                }}
              />
              <BadgeText>Custom Schedule</BadgeText>
            </ClickableUserBadge>}
          {customCrm && !crmDisabled && crmModel && !isArchived &&
            <ClickableUserBadge onClick={() => { this.openSettings(2) }}>
              <LogoImage type={crm.getIn(['data', 'type'])} width='20px' height='20px' />
              <BadgeText>{crmModel}</BadgeText>
            </ClickableUserBadge>}
          {customCrm && !crmDisabled && crmList && !isArchived &&
            <ClickableUserBadge onClick={() => { this.openSettings(2) }}>
              <LogoImage type={crm.getIn(['data', 'type'])} width='20px' height='20px' />
              <BadgeText>{crmList.get('title')}</BadgeText>
            </ClickableUserBadge>}
          {customCrm && !crmDisabled && crmStage && !isArchived &&
            <ClickableUserBadge onClick={() => { this.openSettings(2) }}>
              <LogoImage type={crm.getIn(['data', 'type'])} width='20px' height='20px' />
              <BadgeText>{crmStage.get('title')}</BadgeText>
            </ClickableUserBadge>}
          {customCrm && crmDisabled && !isArchived &&
            <ClickableUserBadge onClick={() => { this.openSettings(2) }}>
              <LogoImage type={crm.getIn(['data', 'type'])} width='20px' height='20px' />
              <BadgeText>Sync Disabled</BadgeText>
            </ClickableUserBadge>}
        </OwnerContainer>
      )
    }

    return (
      <>
        <Wrapper>
          <HeaderContainer>
            <TitleContainer>
              <StatusContainer>
                {!isArchived && <ActiveState active={active} mr='0.5rem' mt='2px' />}
                <Title>{sequence.get('title')}</Title>
              </StatusContainer>
              <SequenceStatus sequence={sequence} state={sequenceState} loading={sequenceStateData.get('loading')} />
              {ownerContent}
            </TitleContainer>
            <NavContainer>
              <NavigationLink
                label='Contacts'
                to={`/sequence/${params.id}/contacts`}
              />
              <NavigationLink
                label='Steps'
                to={
                  query.step
                    ? `/sequence/${params.id}/steps?step=${query.step}`
                    : `/sequence/${params.id}/steps`
                }
              />
              {!isArchived &&
                <NavigationLink
                  label='Preview'
                  to={
                    query.step
                      ? `/sequence/${params.id}/preview?step=${query.step}`
                      : `/sequence/${params.id}/preview`
                  }
                />}
              {!isArchived &&
                <NavigationLink
                  label='Tasks'
                  badge={active && sequenceState ? sequenceState.get('manual') : null}
                  to={
                    query.step
                      ? `/sequence/${params.id}/tasks?step=${query.step}`
                      : `/sequence/${params.id}/tasks`
                  }
                />}
              {!isArchived &&
                <Button
                  label='Settings'
                  ml='2rem'
                  handleClick={() => {
                    this.openSettings()
                  }}
                />}
              {!active && !isArchived &&
                <Button
                  primary
                  label='Launch'
                  ml='1rem'
                  handleClick={() => {
                    this.setState({
                      showLaunchSequence: true
                    })
                  }}
                />}
              {active && !isArchived &&
                <Button
                  primary
                  label='Pause'
                  ml='1rem'
                  handleClick={() => {
                    actions.updateSequence(sequenceId, { active: false }, 'Sequence Paused!')
                  }}
                />}
              {isArchived &&
                <Button
                  primary
                  label='Restore'
                  ml='1rem'
                  disabled={!canRestore}
                  handleClick={() => {
                    actions.restoreSequence(sequenceId)
                  }}
                />}
            </NavContainer>
          </HeaderContainer>
          {isArchived &&
            <ArchivedBanner>
              You are viewing a sequence that is archived and is read-only, restore the sequence to make changes
            </ArchivedBanner>}
          <ResultContainer>
            {React.cloneElement(this.props.children, {
              state,
              session,
              actions,
              contacts,
              loading,
              sequence,
              crm,
              stepStats,
              contactActivity,
              sentMessages,
              contactMessages,
              contactIntegration,
              contactInaccuracyLoading,
              contactCompany,
              contactErrors,
              similarContacts,
              location,
              router,
              hasMore,
              sequences,
              selectedContact,
              contactPhone,
              csv,
              ogp,
              importingCsv,
              pdfExport,
              templates,
              stats,
              filter,
              search,
              sortKey,
              previewContacts,
              uploadedFile,
              selectedContactCount,
              addresses,
              contactSteps,
              previewSteps,
              activeCRM,
              importCrmStep,
              contactReply,
              showWarningModal,
              createCsv,
              isArchived,
              isManager,
              statsLoading,
              sequenceSignature,
              sortContacts: (value) => {
                const sort = value ? value.replace('+', ':asc').replace('-', ':desc') : null
                this.setState({
                  sortKey: sort
                })
                const q = {}
                q.sort = sort
                if (filter) {
                  q.filter = filter
                }
                if (search) {
                  q.search = search
                }
                this.fetchContacts(params.id, q)
              },
              filterContacts: (filter) => {
                this.fetchContacts(params.id, { filter, sort: sortKey, search: this.state.search })
                this.setState({ filter })
              },
              searchContacts: (search) => {
                this.fetchContacts(params.id, { filter: this.state.filter, sort: sortKey, search })
                this.setState({ search })
              },
              clearFilterAndSearch: () => {
                this.fetchContacts(params.id, { sort: sortKey })
                this.setState({ search: null, filter: null })
              },
              onExportCsv: (selectedContactIds) => {
                this.downloadCSV(params.id, selectedContactIds)
              },
              onAddContact: () => {
                this.setState({
                  showNewContactModal: true
                })
              },
              loadMore: ({ startIndex, stopIndex }) => {
                const skip = startIndex - 1
                const q = {}
                if (filter) {
                  q.filter = filter
                }
                if (search) {
                  q.search = search
                }
                if (sortKey) {
                  q.sort = sortKey
                }
                this.fetchContacts(
                  params.id,
                  q,
                  startIndex,
                  stopIndex - skip
                )
              },
              refetchSequence: () => {
                this.fetchSequence(sequenceId)
              },
              onActiveCRMRefresh: (params) => {
                actions.refreshActiveCrm(params)
              }
            })}
          </ResultContainer>
          <CreateContact
            isOpen={this.state.showNewContactModal}
            customFields={sequence.get('fields')}
            defaultValues={sequence.get('fields_default_values')}
            onCancel={() => {
              this.setState({
                showNewContactModal: false
              })
            }}
            handleSave={this.createContact}
            newContacts={newContacts}
          />
          <Modal
            isOpen={this.state.showLaunchSequence}
            onModalClose={() => {
              this.setState({
                showLaunchSequence: false
              })
            }}
          >
            <LaunchSequence
              handleLaunchSequence={this.launchSequence}
            />
          </Modal>
          <Settings
            actions={actions}
            sequence={sequence}
            session={session}
            isManager={isManager}
            crm={crm}
            addresses={addresses}
            members={members}
            newSequence={newSequence}
            isOpen={this.state.showSettingsModal}
            settingsTab={settingsTab}
            onCancel={() => {
              this.setState({
                showSettingsModal: false,
                settingsTab: 0
              })
            }}
            onSave={(params) => {
              this.setState({
                showSettingsModal: false,
                settingsTab: 0
              })
              actions.updateSequence(sequenceId, params)
            }}
            onArchive={() => {
              this.setState({
                showSettingsModal: false,
                settingsTab: 0
              })
              actions.deleteSequence(sequenceId)
            }}
            onTransfer={(userId, params) => {
              actions.transferSequence(sequenceId, userId, params)
            }}
            onCRMRefresh={(params) => {
              actions.refreshCrm(sequenceId, params)
            }}
          />
        </Wrapper>
      </>
    )
  }
}

Sequence.propTypes = {
  children: PropTypes.object.isRequired,
  sequenceData: PropTypes.object,
  sequenceStateData: PropTypes.object,
  statsData: PropTypes.object,
  contacts: PropTypes.instanceOf(Immutable.Map),
  stepStats: PropTypes.object,
  actions: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object,
  router: PropTypes.object,
  params: PropTypes.object,
  csvDownload: PropTypes.string,
  pdfExport: PropTypes.object,
  contactActivity: PropTypes.instanceOf(Immutable.List),
  contactMessages: PropTypes.instanceOf(Immutable.List),
  contactIntegration: PropTypes.instanceOf(Immutable.Map),
  contactInaccuracyLoading: PropTypes.bool,
  contactCompany: PropTypes.object,
  newContacts: PropTypes.object,
  previewSteps: PropTypes.object,
  hasMore: PropTypes.bool,
  sequences: PropTypes.instanceOf(Immutable.List),
  selectedContact: PropTypes.object,
  csv: PropTypes.instanceOf(Immutable.Map),
  importingCsv: PropTypes.instanceOf(Immutable.Map),
  session: PropTypes.instanceOf(Immutable.Map),
  templates: PropTypes.object,
  lookups: PropTypes.object,
  previewContacts: PropTypes.object,
  crm: PropTypes.object,
  uploadedFile: PropTypes.object,
  similarContacts: PropTypes.object,
  state: PropTypes.object,
  members: PropTypes.object,
  newSequence: PropTypes.object,
  sentMessages: PropTypes.object,
  contactErrors: PropTypes.instanceOf(Immutable.Map),
  noClearedContactErrors: PropTypes.object,
  selectedContactCount: PropTypes.object,
  ogp: PropTypes.instanceOf(Immutable.Map),
  addresses: PropTypes.object,
  contactSteps: PropTypes.object,
  contactPhone: PropTypes.object,
  activeCRM: PropTypes.instanceOf(Immutable.Map),
  importCrmStep: PropTypes.instanceOf(Immutable.Map),
  contactReply: PropTypes.object,
  showWarningModal: PropTypes.bool,
  createCsv: PropTypes.object,
  sequenceSignature: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  sequenceData: selectSequence(),
  sequenceStateData: selectSequenceState(),
  statsData: selectStats(),
  stepStats: selectStepStats(),
  contacts: selectContacts(),
  loading: selectLoading(),
  csvDownload: selectCsvDownloadLink(),
  pdfExport: selectPdfExport(),
  contactActivity: selectContactActivity(),
  contactMessages: selectContactMessages(),
  contactMessagesLoading: selectContactMessagesLoading(),
  contactIntegration: selectContactIntegration(),
  contactInaccuracyLoading: selectContactInaccuracyLoading(),
  contactCompany: selectContactCompany(),
  newContacts: selectNewContacts(),
  hasMore: selectContactsHasMore(),
  sequences: selectSequences(),
  selectedContact: selectContact(),
  csv: selectCreateCsv(),
  importingCsv: selectImportCsv(),
  session: selectSession(),
  templates: selectTemplates(),
  lookups: selectLookups(),
  previewContacts: selectPreviewContacts(),
  crm: selectCrm(),
  uploadedFile: selectCreateFile(),
  similarContacts: selectSimilarContacts(),
  state: selectState(),
  contactReply: selectContactReply(),
  members: selectMembers(),
  newSequence: selectNewSequence(),
  sentMessages: selectSentMessages(),
  contactErrors: selectContactErrors(),
  noClearedContactErrors: selectClearedContactErrors(),
  selectedContactCount: selectSelectedContactCount(),
  ogp: selectOgp(),
  addresses: selectAddresses(),
  contactSteps: selectContactSteps(),
  previewSteps: selectSequencePreview(),
  contactPhone: selectContactPhone(),
  activeCRM: selectActiveCrm(),
  importCrmStep: selectImportCrmStep(),
  showWarningModal: selectShowWarningModal(),
  createCsv: selectCreateCsv(),
  sequenceSignature: selectSequenceSignature()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSequence,
      deleteSequence,
      fetchSequenceState,
      refreshSequenceState,
      fetchSequenceStats,
      fetchStepStats,
      fetchContacts,
      fetchExportCsv,
      fetchExportPdf,
      fetchContactActions,
      fetchContactActionsReset,
      fetchContactMessages,
      fetchContactMessagesReset,
      fetchContactIntegration,
      fetchContactCompany,
      reportContactInaccuracy,
      createContact,
      createContactReset,
      fetchSequencePreview,
      updateSequence,
      restoreSequence,
      sendTestEmail,
      addRecentSequence,
      fetchSequences,
      deleteContacts,
      moveContacts,
      updateContacts,
      fetchContact,
      updateContact,
      fetchContactSuccess,
      createCsv,
      updateCsv,
      importCsv,
      repliedContact,
      unsubscribeContact,
      blockContact,
      addCustomField,
      updateContactStep,
      removeContactStep,
      fetchTemplates,
      createTemplate,
      deleteTemplate,
      fetchLookupStatus,
      fetchPreviewContacts,
      fetchCrm,
      refreshCrm,
      forceSendStep,
      createFile,
      cloneContact,
      copyContacts,
      fetchSimilarContacts,
      fetchContactReply,
      fetchTeamMembers,
      transferSequence,
      newSequenceReset,
      fetchSentMessages,
      fetchSentMessagesReset,
      fetchContactErrors,
      fetchContactErrorsReset,
      updateContactErrors,
      fetchSelectedContactCount,
      fetchOgp,
      fetchAddresses,
      fetchContactSteps,
      fetchContactStepsReset,
      fetchContactPhone,
      fetchActiveCrm,
      refreshActiveCrm,
      importCrmContacts,
      importCrmOptionSelected,
      importCrmContactsReset,
      hideWarningModal,
      resyncToCrm,
      phoneNumberLookups,
      fetchSequenceSignature
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Sequence)
