import React from 'react'

/* eslint-disable */
const Send = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 14} height={height || 13}  viewBox="0 0 14 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Campaign-Contacts" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd" strokeLinejoin="round">
        <g id="Contact-Sidebar---Activities" transform="translate(-1089.000000, -697.000000)" stroke={color || "#FFFFFF"}>
            <g id="Group-5" transform="translate(1084.000000, 691.000000)">
                <g id="Group-10">
                    <g id="email-send-1" transform="translate(5.000000, 7.000000)">
                        <polygon id="Stroke-1" points="12.7291667 0.270833333 0.270833333 5.41666667 5.14583333 7.3125"></polygon>
                        <polygon id="Stroke-3" points="12.7291667 0.270833333 10.5625 10.2916667 5.14583333 7.3125"></polygon>
                        <polyline id="Stroke-5" points="5.14583333 7.3125 5.14583333 11.6458333 7.04166667 8.39583333"></polyline>
                    </g>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

export default Send;
