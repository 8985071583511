import React from 'react'

/* eslint-disable */
const FileUpload = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 33.293} height={height || 33.293}  viewBox="0 0 33.293 33.293" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
	<g stroke={color || "#7A859F"}>
        <path d="M16.646,24.786L16.646,24.786c-0.276,0-0.5-0.224-0.5-0.5l0.001-11.279c0-0.276,0.224-0.5,0.5-0.5l0,0
            c0.276,0,0.5,0.224,0.5,0.5l-0.001,11.279C17.146,24.562,16.922,24.786,16.646,24.786z"/>
        <path d="M20.959,17.819c-0.128,0-0.256-0.049-0.354-0.146l-3.959-3.959l-3.959,3.959c-0.195,0.195-0.512,0.195-0.707,0
            s-0.195-0.512,0-0.707l4.313-4.313c0.195-0.195,0.512-0.195,0.707,0l4.313,4.313c0.195,0.195,0.195,0.512,0,0.707
            C21.215,17.77,21.087,17.819,20.959,17.819z"/>
    </g>
    <g stroke={color || "#7A859F"}>
        <path d="M27.578,33.293H5.715c-1.378,0-2.5-1.121-2.5-2.5V2.5c0-1.379,1.122-2.5,2.5-2.5h15.877c0.276,0,0.5,0.224,0.5,0.5 S21.868,1,21.592,1H5.715c-0.827,0-1.5,0.673-1.5,1.5v28.293c0,0.827,0.673,1.5,1.5,1.5h21.863c0.827,0,1.5-0.673,1.5-1.5V8.704
            c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v22.09C30.078,32.172,28.957,33.293,27.578,33.293z"/>
        <path d="M29.578,9.204h-7.986c-0.276,0-0.5-0.224-0.5-0.5V0.717c0-0.276,0.224-0.5,0.5-0.5s0.5,0.224,0.5,0.5v7.486h7.486
            c0.276,0,0.5,0.224,0.5,0.5S29.855,9.204,29.578,9.204z"/>
        <path d="M29.578,9.204c-0.13,0-0.26-0.051-0.358-0.151l-7.986-8.203c-0.192-0.197-0.188-0.515,0.01-0.707
            c0.197-0.193,0.514-0.188,0.707,0.01l7.986,8.203c0.192,0.197,0.188,0.515-0.01,0.707C29.83,9.157,29.704,9.204,29.578,9.204z"/>
    </g>
  </svg>
);

export default FileUpload;
