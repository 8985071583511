import * as rules from './rules'

const EMAIL_STATUS = {
  GREEN: 0,
  AMBER: 1,
  RED: 2
}

function findBrokenRules (markDown, html, subjectString, bodyString, steps, selectedStepIndex) {
  // check if any arguments are null or undefined
  // (can't just use falsy check because some values could equal 0)
  for (const arg of arguments) {
    if (typeof arg === 'undefined' || arg === null) {
      return []
    }
  }

  // don't want to run rules engine on manual tasks
  const isEmail = steps?.getIn([selectedStepIndex, 'type']) === 'message'
  if (!isEmail) {
    return []
  }

  return Promise.all([
    rules.emailTooLong(markDown),
    rules.noScheduleLinks(html),
    rules.tooManyLinks(html),
    rules.tooManyImages(html),
    rules.tooManyAttachments(html),
    rules.longSubjectLine(subjectString),
    rules.spamFilterPhrases(subjectString, bodyString),
    rules.noCustomFields(subjectString, bodyString),
    rules.shortFollowUp(markDown, selectedStepIndex),
    rules.tooManyEmails(steps)
  ])
}

export {
  EMAIL_STATUS,
  findBrokenRules
}
