/**
*
* SequenceStatus Element
*
*/

import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import {
  format,
  isToday,
  isTomorrow,
  differenceInMinutes
} from 'date-fns'

import { space, width, textAlign } from 'styled-system'

import TextLabel from 'elements/Label'
import { Tooltip } from 'react-tippy'

import { pluralize, capitalize } from 'utils/strings'

import withSession from 'hocs/session'

import { CRM_NAMES } from 'containers/Integrations/constants'

import Loading from 'components/Loading'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.titleColor};
  text-align: left;

  ${space}
  ${width}
  ${textAlign}
`

function Label (props) {
  if (props.tooltip) {
    return (
      <Tooltip
        title={props.tooltip}
        position='bottom-right'
        trigger='mouseenter'
        arrow
      >
        <TextLabel>
          {props.children}
          &nbsp;
          <FontAwesomeIcon
            icon={['fas', 'info-circle']}
            transform={{
              size: 14,
              y: 1
            }}
          />
        </TextLabel>
      </Tooltip>
    )
  }

  return (
    <TextLabel>{props.children}</TextLabel>
  )
}

Label.propTypes = {
  tooltip: PropTypes.string,
  children: PropTypes.any
}

class SequenceStatus extends PureComponent {
  render () {
    const {
      sequence,
      session,
      state = Immutable.Map({}),
      loading = false
    } = this.props

    let content

    if (sequence && !sequence.get('active')) {
      return (<Wrapper><Label>Launch this sequence to start sending emails</Label></Wrapper>)
    }

    if (loading) {
      return (<Wrapper><Loading size={13} padding='0' pt='5px' pb='4px' /></Wrapper>)
      // return (<Wrapper><Label>&mdash;</Label></Wrapper>)
    }

    if (sequence && state) {
      // const sequenceId = sequence.get('_id')
      const active = sequence.get('active')
      // const minimum = sequence.get('minimum')
      const user = sequence.get('_user')
      // const customSchedule = sequence.get('schedule')
      const finished = state.get('ongoing') === 0
      const total = state.get('total')

      const schedule = state.get('schedule')
      const isThrottled = schedule === 'SYSTEM_RATE_LIMITED' || schedule === 'USER_SETTING_RATE_LIMITED'

      let schedulePaused = state.get('paused_until')
      if (schedulePaused && isThrottled && differenceInMinutes(schedulePaused, Date.now()) < 10) {
        schedulePaused = null
      }

      let schedulePausedMinutes = null
      if (schedulePaused) {
        schedulePausedMinutes = differenceInMinutes(schedulePaused, Date.now())
        if (schedulePausedMinutes <= 0) {
          schedulePausedMinutes = 1
        }

        schedulePausedMinutes = `${schedulePausedMinutes} ${pluralize('minute', 'minutes', schedulePausedMinutes)}`
      }

      const sent = state.get('sent')
      const queued = state.get('queued')

      const hasSent = sent > 0
      const hasQueued = queued > 0

      const isOwner = user.get('_id') === session.get('_id')
      let accountType
      let crmType
      let owner = 'your'
      let ownerPossessive = 'your'
      let crmName = 'CRM'

      if (Immutable.Map.isMap(user)) {
        accountType = user.get('account_type')
        crmType = user.get('crm_type')

        if (!isOwner) {
          owner = user.get('full_name') || user.get('email')
          ownerPossessive = `${owner}'s`
        }

        if (crmType) {
          crmName = CRM_NAMES[crmType] || capitalize(crmType)
        }
      }

      let pausedUntil = ''
      let pausedUntilPrefix = ''
      if (schedulePaused) {
        const date = Date.parse(schedulePaused)
        pausedUntilPrefix = 'on'

        let day = format(date, 'MMMM Do')
        if (isToday(date)) {
          day = 'today'
          pausedUntilPrefix = ''
        } else if (isTomorrow(date)) {
          day = 'tomorrow'
          pausedUntilPrefix = ''
        }

        const hour = format(date, 'h')
        const min = format(date, 'mma')

        pausedUntil = `${day} at ${hour}:${min}`
      }

      let accountName = 'email server'
      let emailType = `${ownerPossessive} email server's`
      if (accountType === 'google') {
        emailType = 'Gmail'
        accountName = 'Gmail account'
      } else if (accountType === 'microsoft') {
        emailType = 'Outlook'
        accountName = 'Outlook account'
      }

      if (!active) {
        return (
          <Wrapper>
            <Label>Launch this sequence to start sending emails</Label>
          </Wrapper>
        )
      }

      switch (true) {
        case finished && total && total > 0:
          content = <Label>Sequence completed, all contacts have finished</Label>
          break
        // case !active && !minimum:
        //   content = <Label>Write the first email for this sequence via <Link onClick={(e) => { e.stopPropagation(); }} to={`/sequence/${sequenceId}/steps`}>sequence steps</Link></Label>;
        //   break;
        case schedule === 'HOLIDAY_EXCLUSION':
          content = <Label>Emails are paused due to {ownerPossessive} <Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>holiday exclusion</Link></Label>
          break
        case schedule === 'CUSTOM_HOLIDAY_EXCLUSION':
          content = <Label>Emails are paused due to {ownerPossessive} <Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>custom holiday exclusion</Link></Label>
          break
        case schedule === 'OUT_OF_SCHEDULE' && !schedulePaused:
          content = <Label><Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>Set a schedule</Link> to start sending emails</Label>
          break
        case schedule === 'OUT_OF_SCHEDULE' && schedulePaused && hasQueued && hasSent:
          content = <Label><strong>{sent}</strong> {pluralize('email', 'emails', sent)} sent today, <strong>{queued}</strong> {pluralize('email', 'emails', queued)} paused until <Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>{pausedUntil}</Link></Label>
          break
        case schedule === 'OUT_OF_SCHEDULE' && schedulePaused && hasQueued && !hasSent:
          content = <Label>Emails will start sending {pausedUntilPrefix} <Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>{pausedUntil}</Link></Label>
          break
        case schedule === 'SYSTEM_RATE_LIMITED' && schedulePaused && hasQueued && hasSent:
          content = <Label><strong>{sent}</strong> {pluralize('email', 'emails', sent)} sent today, <strong>{queued}</strong> {pluralize('email', 'emails', queued)} throttled temporarily due to {emailType} sending restrictions</Label>
          break
        case schedule === 'SYSTEM_RATE_LIMITED' && schedulePaused && hasQueued && !hasSent:
          content = <Label>{queued} {pluralize('email', 'emails', queued)} throttled temporarily due to {emailType} sending restrictions</Label>
          break
        case schedule === 'USER_SETTING_RATE_LIMITED' && schedulePaused && hasQueued && hasSent:
          content = <Label><strong>{sent}</strong> {pluralize('email', 'emails', sent)} sent today, <strong>{queued}</strong> {pluralize('email', 'emails', queued)} paused until <strong>{pausedUntil}</strong> due to {ownerPossessive} <Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>volume limits</Link></Label>
          break
        case schedule === 'USER_SETTING_RATE_LIMITED' && schedulePaused && hasQueued && !hasSent:
          content = <Label>{queued} emails paused until {pausedUntil} due to {ownerPossessive} <Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>volume limits</Link></Label>
          break
        case schedule === 'NO_ACCESS_TOKEN':
          content = <Label tooltip={owner !== 'your' && `Please have ${owner} log into Interseller to reconnect their ${accountName}`}>Emails paused due to a connection issue with {ownerPossessive} {accountName}</Label>
          break
        case schedule === 'TZ_NOT_SET':
          content = <Label>Emails paused due to missing timezone, set {ownerPossessive} timezone via <Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>settings</Link></Label>
          break
        case schedule === 'CAMPAIGN_PAUSED':
          content = <Label>Sequence is set to launch <Link onClick={(e) => { e.stopPropagation() }} to='/settings/schedule'>{pausedUntil}</Link></Label>
          break
        case schedule === 'USER_DISABLED':
          content = <Label>{capitalize(ownerPossessive)} account does not have a seat assigned and cannot send emails</Label>
          break
        case schedule === 'CRM_ERROR' && crmType && hasQueued:
          content = <Label tooltip={owner !== 'your' && `Please have ${owner} log into Interseller to reconnect their ${crmName} account`}>Emails are paused because of a connection issue with {ownerPossessive} {crmName} account</Label>
          break
        case schedule === 'CAMPAIGN_RECENTLY_LAUNCHED' && hasQueued && !hasSent:
          content = <Label>Emails will start sending in {schedulePausedMinutes}</Label>
          break
        case schedule === 'CAMPAIGN_RECENTLY_LAUNCHED' && hasQueued && hasSent:
          content = <Label>{sent} {pluralize('email', 'emails', sent)} sent, {queued} remaining {pluralize('email', 'emails', queued)} will start sending in {schedulePausedMinutes}</Label>
          break
        case schedule === 'CAMPAIGN_DELAY' && hasQueued && !hasSent:
          content = <Label>{queued} {pluralize('email', 'emails', queued)} paused for {schedulePausedMinutes} from recent step updates</Label>
          break
        case schedule === 'CAMPAIGN_DELAY' && hasQueued && hasSent:
          content = <Label>{sent} {pluralize('email', 'emails', sent)} sent, {queued} remaining {pluralize('email', 'emails', queued)} paused for {schedulePausedMinutes} from recent step updates</Label>
          break
        case !hasSent && !hasQueued:
          content = <Label>There is nothing to send today</Label>
          break
        case hasSent && !hasQueued:
          content = <Label><strong>{sent}</strong> {pluralize('email', 'emails', sent)} sent today, nothing left to send today</Label>
          break
        case !hasSent && hasQueued:
          content = <Label>{queued} {pluralize('email', 'emails', queued)} queued to send today</Label>
          break
        case hasSent && hasQueued:
          content = <Label><strong>{sent}</strong> {pluralize('email', 'emails', sent)} sent today, <strong>{queued}</strong> {pluralize('email', 'emails', queued)} remaining</Label>
          break
        default:
          content = <Label>schedule {schedule}, sent {sent}, queued {queued}</Label>
      }
    }

    return (
      <Wrapper>
        {content}
      </Wrapper>
    )
  }
}

SequenceStatus.propTypes = {
  sequence: PropTypes.object,
  session: PropTypes.object,
  state: PropTypes.object,
  loading: PropTypes.bool
}

export default withSession(SequenceStatus)
