import { UPDATE_SESSION_REQUEST } from 'containers/App/constants'

import {
  FETCH_SESSION_ADDRESSES_REQUEST,
  FETCH_SESSION_ADDRESSES_SUCCESS,
  FETCH_SESSION_ADDRESSES_FAILURE,

  FETCH_TEAM_SETTINGS_REQUEST,
  FETCH_TEAM_SETTINGS_SUCCESS,
  FETCH_TEAM_SETTINGS_FAILURE,

  FETCH_BLOCKLIST_REQUEST,
  FETCH_BLOCKLIST_SUCCESS,
  FETCH_BLOCKLIST_FAILURE,

  FETCH_TEAM_BLOCKLIST_REQUEST,
  FETCH_TEAM_BLOCKLIST_SUCCESS,
  FETCH_TEAM_BLOCKLIST_FAILURE,

  UPDATE_BLOCKLIST_REQUEST,
  UPDATE_BLOCKLIST_SUCCESS,
  UPDATE_BLOCKLIST_FAILURE,

  UPDATE_TEAM_BLOCKLIST_REQUEST,
  UPDATE_TEAM_BLOCKLIST_SUCCESS,
  UPDATE_TEAM_BLOCKLIST_FAILURE,

  UPDATE_TEAM_SETTINGS_REQUEST,
  UPDATE_TEAM_SETTINGS_SUCCESS,
  UPDATE_TEAM_SETTINGS_FAILURE,

  FETCH_TEAM_DOMAIN_REQUEST,
  FETCH_TEAM_DOMAIN_SUCCESS,
  FETCH_TEAM_DOMAIN_FAILURE,

  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,

  RESUBSCRIBE_EMAIL_REQUEST,

  FETCH_CONTACT_CSV_REQUEST,

  FETCH_CALENDARS_REQUEST,
  FETCH_CALENDARS_SUCCESS,
  FETCH_CALENDARS_FAILURE,

  UPDATE_CALENDARS_REQUEST,
  UPDATE_CALENDARS_SUCCESS,
  UPDATE_CALENDARS_FAILURE,

  FETCH_TEAM_LOGS_REQUEST,
  FETCH_TEAM_LOGS_SUCCESS,
  FETCH_TEAM_LOGS_FAILURE,

  FETCH_CAMPAIGN_STEP_COPY_REQUEST,

  FETCH_PURGE_EMAIL_COUNT_REQUEST,
  FETCH_PURGE_EMAIL_COUNT_SUCCESS,
  FETCH_PURGE_EMAIL_COUNT_FAILURE,

  SHOW_PURGE_MODAL,
  HIDE_PURGE_MODAL,

  DELETE_PURGE_EMAIL_REQUEST,
  DELETE_PURGE_EMAIL_SUCCESS,
  DELETE_PURGE_EMAIL_FAILURE
} from './constants'

export function fetchSessionAddresses () {
  return {
    type: FETCH_SESSION_ADDRESSES_REQUEST
  }
}

export function fetchSessionAddressesSuccess ({ addresses }) {
  return {
    type: FETCH_SESSION_ADDRESSES_SUCCESS,
    payload: {
      addresses
    }
  }
}

export function fetchSessionAddressesFailure (err) {
  return {
    type: FETCH_SESSION_ADDRESSES_FAILURE,
    err
  }
}

export function fetchTeamSettings () {
  return {
    type: FETCH_TEAM_SETTINGS_REQUEST
  }
}

export function fetchTeamSettingsSuccess ({ settings }) {
  return {
    type: FETCH_TEAM_SETTINGS_SUCCESS,
    payload: {
      settings
    }
  }
}

export function fetchTeamSettingsFailure (err) {
  return {
    type: FETCH_TEAM_SETTINGS_FAILURE,
    err
  }
}

export function fetchBlocklist () {
  return {
    type: FETCH_BLOCKLIST_REQUEST
  }
}

export function fetchBlocklistSuccess ({ blocklist }) {
  return {
    type: FETCH_BLOCKLIST_SUCCESS,
    payload: {
      blocklist
    }
  }
}

export function fetchBlocklistFailure (err) {
  return {
    type: FETCH_BLOCKLIST_FAILURE,
    err
  }
}

export function updateBlocklist (blocklist) {
  return {
    type: UPDATE_BLOCKLIST_REQUEST,
    blocklist
  }
}

export function updateBlocklistSuccess ({ blocklist }) {
  return {
    type: UPDATE_BLOCKLIST_SUCCESS,
    payload: {
      blocklist
    }
  }
}

export function updateBlocklistFailure (err) {
  return {
    type: UPDATE_BLOCKLIST_FAILURE,
    err
  }
}

export function updateTeamBlocklist (blocklist) {
  return {
    type: UPDATE_TEAM_BLOCKLIST_REQUEST,
    blocklist
  }
}

export function updateTeamBlocklistSuccess ({ blocklist }) {
  return {
    type: UPDATE_TEAM_BLOCKLIST_SUCCESS,
    payload: {
      blocklist
    }
  }
}

export function updateTeamBlocklistFailure (err) {
  return {
    type: UPDATE_TEAM_BLOCKLIST_FAILURE,
    err
  }
}

export function fetchTeamBlocklist () {
  return {
    type: FETCH_TEAM_BLOCKLIST_REQUEST
  }
}

export function fetchTeamBlocklistSuccess ({ blocklist }) {
  return {
    type: FETCH_TEAM_BLOCKLIST_SUCCESS,
    payload: {
      blocklist
    }
  }
}

export function fetchTeamBlocklistFailure (err) {
  return {
    type: FETCH_TEAM_BLOCKLIST_FAILURE,
    err
  }
}

export function updateTeamSettings (params) {
  return {
    type: UPDATE_TEAM_SETTINGS_REQUEST,
    params
  }
}

export function updateTeamSettingsSuccess ({ settings }) {
  return {
    type: UPDATE_TEAM_SETTINGS_SUCCESS,
    payload: {
      settings
    }
  }
}

export function updateTeamSettingsFailure (err) {
  return {
    type: UPDATE_TEAM_SETTINGS_FAILURE,
    err
  }
}

export function updateSession (params, success) {
  return {
    type: UPDATE_SESSION_REQUEST,
    params,
    success
  }
}

export function fetchTeamDomain () {
  return {
    type: FETCH_TEAM_DOMAIN_REQUEST
  }
}

export function fetchTeamDomainSuccess ({ domain }) {
  return {
    type: FETCH_TEAM_DOMAIN_SUCCESS,
    payload: {
      domain
    }
  }
}

export function fetchTeamDomainFailure (err) {
  return {
    type: FETCH_TEAM_DOMAIN_FAILURE,
    err
  }
}

export function fetchConfig () {
  return {
    type: FETCH_CONFIG_REQUEST
  }
}

export function fetchConfigSuccess ({ config }) {
  return {
    type: FETCH_CONFIG_SUCCESS,
    payload: {
      config
    }
  }
}

export function fetchConfigFailure (err) {
  return {
    type: FETCH_CONFIG_FAILURE,
    err
  }
}

export function resubscribeEmail (email) {
  return {
    type: RESUBSCRIBE_EMAIL_REQUEST,
    payload: {
      email
    }
  }
}

export function fetchContactCSV (value) {
  return {
    type: FETCH_CONTACT_CSV_REQUEST,
    payload: {
      value
    }
  }
}

export function fetchCalendars () {
  return {
    type: FETCH_CALENDARS_REQUEST
  }
}

export function fetchCalendarsSuccess ({ calendars }) {
  return {
    type: FETCH_CALENDARS_SUCCESS,
    payload: {
      calendars
    }
  }
}

export function fetchCalendarsFailure (err) {
  return {
    type: FETCH_CALENDARS_FAILURE,
    err
  }
}

export function updateCalendars (calendarIds) {
  return {
    type: UPDATE_CALENDARS_REQUEST,
    calendarIds
  }
}

export function updateCalendarsSuccess ({ calendars }) {
  return {
    type: UPDATE_CALENDARS_SUCCESS,
    payload: {
      calendars
    }
  }
}

export function updateCalendarsFailure (err) {
  return {
    type: UPDATE_CALENDARS_FAILURE,
    err
  }
}

export function fetchTeamLogs (createdAt) {
  return {
    type: FETCH_TEAM_LOGS_REQUEST,
    createdAt
  }
}

export function fetchTeamLogsSuccess ({ teamLogs }) {
  return {
    type: FETCH_TEAM_LOGS_SUCCESS,
    payload: {
      teamLogs
    }
  }
}

export function fetchTeamLogsFailure (err) {
  return {
    type: FETCH_TEAM_LOGS_FAILURE,
    err
  }
}

export function fetchCampaignStepCopyRequest () {
  return {
    type: FETCH_CAMPAIGN_STEP_COPY_REQUEST
  }
}

export function fetchPurgeEmailCount (email) {
  return {
    type: FETCH_PURGE_EMAIL_COUNT_REQUEST,
    email
  }
}

export function fetchPurgeEmailCountSuccess ({ purgeEmailCount }) {
  return {
    type: FETCH_PURGE_EMAIL_COUNT_SUCCESS,
    payload: {
      purgeEmailCount
    }
  }
}

export function fetchPurgeEmailCountFailure (err) {
  return {
    type: FETCH_PURGE_EMAIL_COUNT_FAILURE,
    err
  }
}

export function showPurgeModal () {
  return {
    type: SHOW_PURGE_MODAL
  }
}

export function hidePurgeModal () {
  return {
    type: HIDE_PURGE_MODAL
  }
}

export function deletePurgeEmail (email) {
  return {
    type: DELETE_PURGE_EMAIL_REQUEST,
    email
  }
}

export function deletePurgeEmailSuccess () {
  return {
    type: DELETE_PURGE_EMAIL_SUCCESS
  }
}

export function deletePurgeEmailFailure (err) {
  return {
    type: DELETE_PURGE_EMAIL_FAILURE,
    err
  }
}
