import { fromJS } from 'immutable'
import R from 'utils/ramda'
import pickBy from 'lodash/pickBy'
import includes from 'lodash/includes'
import filter from 'lodash/filter'
import { put, takeLatest, takeEvery, takeLeading, call } from 'redux-saga/effects'
import { startOfWeek, startOfDay } from 'date-fns'

import api, { messageFromError } from 'utils/api'

import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchSequencesSuccess,
  fetchSequencesFailure,
  fetchArchivedSequencesSuccess,
  fetchArchivedSequencesFailure,
  updateSequencesSuccess,
  updateSequencesFailure,
  deleteSequencesSuccess,
  restoreSequencesSuccess,
  fetchSequencesStatsSuccess,
  fetchSequencesStatsFailure,
  createSequenceSuccess,
  createSequenceFailure,
  fetchSequenceReportsSuccess,
  fetchSequenceReportsFailure,
  fetchMessageScheduleSuccess,
  fetchMessageScheduleFailure,
  fetchSequencesStatusSuccess,
  fetchSequencesStatusFailure,
  fetchMessageCountsSuccess,
  fetchMessageCountsFailure,
  fetchSequencesExportCsvSuccess,
  fetchSequencesExportCsvFailure
} from './actions'

import {
  FETCH_SEQUENCES_REQUEST,
  FETCH_ARCHIVED_SEQUENCES_REQUEST,
  UPDATE_SEQUENCES_REQUEST,
  DELETE_SEQUENCES_REQUEST,
  RESTORE_SEQUENCES_REQUEST,
  FETCH_SEQUENCES_STATS_REQUEST,
  CREATE_SEQUENCE_REQUEST,
  FETCH_SEQUENCE_REPORTS_REQUEST,
  FETCH_MESSAGE_SCHEDULE_REQUEST,
  FETCH_SEQUENCES_STATUS_REQUEST,
  FETCH_MESSAGE_COUNTS_REQUEST,
  FETCH_SEQUENCES_EXPORT_CSV_REQUEST
} from './constants'

const get = (url, params) => api.get(url, { params })
const post = (url, params) => api.post(url, params)
// const update = (url, params) => api.put(url, params)

export function * fetchSequences (action) {
  let query
  let sequences = []
  let total = 0

  // Build query
  if (!R.isEmpty(action.query)) {
    query = action.query
  } else {
    query = { }
  }

  try {
    const response = yield call(get, '/campaigns/list', query)
    sequences = fromJS(response.data)
    total = sequences.length

    yield put(fetchSequencesSuccess({
      sequences,
      total
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
    yield put(fetchSequencesFailure(error))
  }
}

export function * fetchArchivedSequences (action) {
  let query
  let sequences = []
  let total = 0

  // Build query
  if (!R.isEmpty(action.query)) {
    query = { ...action.query, archived: 1 }
  } else {
    query = { archived: 1 }
  }

  try {
    const response = yield call(get, '/campaigns/list', query)
    sequences = fromJS(response.data)
    total = sequences.length

    yield put(fetchArchivedSequencesSuccess({
      sequences,
      total
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
    yield put(fetchArchivedSequencesFailure(error))
  }
}

export function * fetchSequencesStats (action) {
  let sequencesStats = {}
  const { sequenceIds } = action

  let query = {
    show: [action.archived ? 'archived' : 'active'],
    campaigns: sequenceIds
  }

  if (action.all) {
    query = {}
  }

  try {
    const response = yield call(post, '/campaigns/stats', query)
    sequencesStats = fromJS(response.data)

    yield put(fetchSequencesStatsSuccess({
      sequencesStats
    }))
  } catch (error) {
    yield put(fetchSequencesStatsFailure(error))
  }
}

export function * fetchSequencesStatus (action) {
  let sequencesStatus = {}

  let query
  if (action.sequenceIds) {
    query = {
      campaigns: action.sequenceIds
    }
  }

  try {
    const response = yield call(post, '/campaigns/state', query)
    sequencesStatus = fromJS(response.data)

    yield put(fetchSequencesStatusSuccess({
      sequencesStatus
    }))
  } catch (error) {
    yield put(fetchSequencesStatusFailure(error))
  }
}

export function * updateSequences (action) {
  const ids = action.sequenceIds
  const data = action.params
  const message = action.message || `Updated ${ids.length} sequences! 🚀`

  try {
    const params = {
      ids,
      data
    }
    yield call(post, '/campaigns/update', params)

    // TODO: Make restore action and update redux directly instead of refetching here
    if (data.deleted_at === null) {
      yield call(fetchSequences, {})
      yield call(fetchArchivedSequences, {})
    }

    // update sequence statuses
    if (data.active === true) {
      yield call(fetchSequencesStatus, action)
    }

    yield put(updateSequencesSuccess({
      sequenceIds: ids,
      params: data
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
    yield put(updateSequencesFailure(error))
  }
}

export function * deleteSequences (action) {
  const sequenceIds = action.sequenceIds
  const path = '/campaigns/remove'
  const params = { ids: sequenceIds }
  try {
    yield call(post, path, params)
    yield put(deleteSequencesSuccess({ sequenceIds }))
    yield call(fetchArchivedSequences, {})
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Archived ${sequenceIds.length} sequences! 🚀`
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * restoreSequences (action) {
  const sequenceIds = action.sequenceIds
  const path = '/campaigns/restore'
  const params = { ids: sequenceIds }
  try {
    yield call(post, path, params)
    yield put(restoreSequencesSuccess({ sequenceIds }))
    yield call(fetchSequences, {})
    yield call(fetchArchivedSequences, {})
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: `Restored ${sequenceIds.length} sequences! 🚀`
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * createSequence (action) {
  const params = action.params

  try {
    const response = yield call(post, '/campaigns', params)
    const sequence = fromJS(response.data)

    yield put(createSequenceSuccess({
      sequence
    }))
  } catch (error) {
    yield put(createSequenceFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

const keysContainSubstring = function (obj, string) {
  let keys = pickBy(obj, (value, key) => includes(key, string))
  keys = filter(keys, v => v) // value has to be true
  return keys.length > 0
}

const reportsNeeded = function (obj) {
  const possibleReports = [
    'messages',
    'contacts',
    'replies',
    'booked'
  ]
  return filter(possibleReports, function (possible) {
    return keysContainSubstring(obj, possible)
  })
}

export function * fetchSequenceReports (action) {
  const tileConfig = action.tileConfig
  const needWeeklyData = keysContainSubstring(tileConfig, 'Weekly')
  const start = needWeeklyData ? startOfWeek(new Date()).toISOString() : startOfDay(new Date()).toISOString()
  const params = {
    start,
    reports: reportsNeeded(tileConfig)
  }
  try {
    const response = yield call(get, '/reports/me', params)
    const reports = fromJS(response.data)

    yield put(fetchSequenceReportsSuccess({
      reports
    }))
  } catch (error) {
    yield put(fetchSequenceReportsFailure(error))
  }
}

export function * fetchMessageSchedule () {
  try {
    const response = yield call(api.get, '/messages/schedule?limit_day=user&max_days=7', { timeout: 120000 })
    const schedule = fromJS(response.data)
    yield put(fetchMessageScheduleSuccess({
      schedule
    }))
  } catch (error) {
    yield put(fetchMessageScheduleFailure(error))
  }
}

export function * fetchMessageCounts () {
  try {
    const path = '/me/counts'
    const response = yield call(get, path)
    const counts = fromJS(response.data)

    yield put(fetchMessageCountsSuccess({
      counts
    }))
  } catch (error) {
    yield put(fetchMessageCountsFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

export function * exportSequencesCsv (action) {
  const {
    sequenceIds
  } = action

  try {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.LOADING,
      message: 'Exporting your contacts...'
    }))

    const query = {
      campaigns: sequenceIds
    }

    const path = '/contacts/csv'
    const response = yield call(post, path, query, { timeout: 120000 })
    const type = fromJS(response.data.type)
    const downloadLink = fromJS(response.data.url)

    yield put(fetchSequencesExportCsvSuccess({
      type,
      downloadLink
    }))

    let message
    if (type === 'email') {
      message = 'Check your email in a few minutes for your CSV export'
    } else {
      message = 'Exported CSV of contacts'
    }
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (error) {
    yield put(fetchSequencesExportCsvFailure(error))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(error)
    }))
  }
}

function * createSequenceRequest () {
  yield takeLatest(CREATE_SEQUENCE_REQUEST, createSequence)
}

function * fetchSequencesRequest () {
  yield takeLeading(FETCH_SEQUENCES_REQUEST, fetchSequences)
}

function * updateSequencesRequest () {
  yield takeLatest(UPDATE_SEQUENCES_REQUEST, updateSequences)
}

function * fetchSequencesStatsRequest () {
  yield takeEvery(FETCH_SEQUENCES_STATS_REQUEST, fetchSequencesStats)
}

function * deleteSequencesRequest () {
  yield takeLatest(DELETE_SEQUENCES_REQUEST, deleteSequences)
}

function * restoreSequencesRequest () {
  yield takeLatest(RESTORE_SEQUENCES_REQUEST, restoreSequences)
}

function * fetchArchivedSequencesRequest () {
  yield takeLatest(FETCH_ARCHIVED_SEQUENCES_REQUEST, fetchArchivedSequences)
}

function * fetchSequenceReportsRequest () {
  yield takeLatest(FETCH_SEQUENCE_REPORTS_REQUEST, fetchSequenceReports)
}

function * fetchMessageScheduleRequest () {
  yield takeLatest(FETCH_MESSAGE_SCHEDULE_REQUEST, fetchMessageSchedule)
}

function * fetchSequencesStatusRequest () {
  yield takeEvery(FETCH_SEQUENCES_STATUS_REQUEST, fetchSequencesStatus)
}

function * fetchMessageCountsRequest () {
  yield takeLatest(FETCH_MESSAGE_COUNTS_REQUEST, fetchMessageCounts)
}

function * fetchExportSequencesCsv () {
  yield takeLatest(FETCH_SEQUENCES_EXPORT_CSV_REQUEST, exportSequencesCsv)
}

export default [
  fetchSequencesRequest,
  fetchSequencesStatsRequest,
  createSequenceRequest,
  deleteSequencesRequest,
  updateSequencesRequest,
  restoreSequencesRequest,
  fetchArchivedSequencesRequest,
  fetchSequenceReportsRequest,
  fetchMessageScheduleRequest,
  fetchSequencesStatusRequest,
  fetchMessageCountsRequest,
  fetchExportSequencesCsv
]
