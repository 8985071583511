import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Modal from 'components/Modal'
import Loading from 'components/Loading'
import Button from 'components/Button'
import EmailInput from 'components/EmailInput'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding-top: 2rem;
  padding-bottom: 2rem;
  padding-left: 5rem;
  padding-right: 5rem;
  width: 100%;
`

const ButtonContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  padding-bottom: 1.5rem;
  padding-top: 1.5rem;
`

class FixEmailModal extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: null
    }
  }

  handleKeyDown = (e) => {
    const {
      email
    } = this.state
    // If 'enter' is pressed
    if (e.keyCode === 13 && email !== null) {
      e.preventDefault()
      return this.props.onConfirm(email)
    }
    return false
  }

  render () {
    const {
      title,
      description,
      loading,
      onCancel,
      onConfirm,
      isOpen
    } = this.props

    const {
      email
    } = this.state

    if (loading) {
      return <Loading />
    }

    return (
      <Modal
        isOpen={isOpen}
        onModalClose={onCancel}
      >
        <Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <InputContainer>
            <Title align='center'>{title}</Title>
            <Label mt='0.5rem' mb='1rem' align='center'>
              {description}
            </Label>
            <EmailInput
              label='Email'
              value={email}
              inputId='email'
              onValueChange={(value) => {
                this.setState({
                  email: value
                })
              }}
            />
          </InputContainer>
          <Divider />
          <ButtonContainer>
            <Button
              label='cancel'
              onClick={onCancel}
              mr='0.5rem'
            />
            <Button
              primary
              disabled={email === null}
              label='confirm'
              onClick={() => {
                onConfirm(email)
              }}
            />
          </ButtonContainer>
        </Wrapper>
      </Modal>
    )
  }
}

FixEmailModal.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string,
  loading: PropTypes.bool,
  onConfirm: PropTypes.func,
  onCancel: PropTypes.func,
  isOpen: PropTypes.bool,
  actions: PropTypes.object
}

FixEmailModal.defaultProps = {
  loading: false,
  isOpen: false
}

export default FixEmailModal
