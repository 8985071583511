import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { space, width, textAlign } from 'styled-system'

const Wrapper = styled.h2`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.subtitle};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0;

  ${space}
  ${width}
  ${textAlign}
`

class Subtitle extends Component {
  render () {
    return (
      <Wrapper {...this.props}>
        {this.props.children}
      </Wrapper>
    )
  }
}

Subtitle.propTypes = {
  children: PropTypes.any
}

export default Subtitle
