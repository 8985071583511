import React, { Component } from 'react'
import styled from 'styled-components'
import Immutable from 'immutable'
import PropTypes from 'prop-types'

import { TOP_LEVEL_FIELD_OPTIONS } from 'containers/Integrations/constants'

import Input from 'components/Input'
import DropDown from 'components/DropDown'
import Button from 'components/Button'

const Wrapper = styled.div`
  width: 45%;
`

const InputWrapper = styled.div`
  display: flex;
`

class FieldDropdown extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showCustomTextInput: false,
      customText: ''
    }
  }

  render () {
    const {
      options,
      value,
      onOptionChange,
      clearable
    } = this.props

    const {
      showCustomTextInput,
      customText
    } = this.state

    const topLevelValues = Immutable.fromJS(TOP_LEVEL_FIELD_OPTIONS)
      .map(option => option.get('value'))

    let allOptions = options

    allOptions = allOptions.splice(2, 0, {
      custom: true,
      value: null,
      label: 'Fill with Custom Static Text'
    })

    if (value && !topLevelValues.includes(value) && !value.startsWith('$')) {
      allOptions = allOptions.splice(2, 0, {
        value,
        label: `Fill with "${value}"`
      })
    }

    return (
      <Wrapper>
        {!showCustomTextInput && <DropDown
          height='44px'
          options={allOptions}
          value={value}
          controlled
          clearable={clearable}
          onOptionChange={(option) => {
            if (option && option.custom) {
              return this.setState({
                showCustomTextInput: true
              })
            }

            onOptionChange(option)
          }}
                                 />}
        {showCustomTextInput &&
          <InputWrapper>
            <Input
              small
              onValueChange={(value) => {
                this.setState({
                  customText: value
                })
              }}
            />
            <Button
              primary
              label='save'
              placeholder='Custom static text...'
              onClick={() => {
                onOptionChange({
                  value: customText
                })
                this.setState({
                  showCustomTextInput: false
                })
              }}
              ml='0.5rem'
            />
          </InputWrapper>}
      </Wrapper>
    )
  }
}

FieldDropdown.propTypes = {
  options: PropTypes.instanceOf(Immutable.List),
  value: PropTypes.any,
  onOptionChange: PropTypes.func,
  clearable: PropTypes.bool
}

export default FieldDropdown
