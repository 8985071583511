import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import NumberFormat from 'react-number-format'
import { Tooltip } from 'react-tippy'

import { Info } from 'svg'

import { space, width, textAlign } from 'styled-system'

const Wrapper = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background: ${props => props.theme.containerBackground};
  flex-basis: 10.5rem;
  border: ${props => (props.border ? `solid 1px ${props.theme.borderColor}` : 'none')};
  padding: ${props => (props.padding ? `${props.padding}` : '.75rem')};

  ${space}
  ${width}
  ${textAlign}
`

const Label = styled.div`
  text-align: center;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
`

const ValueContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const Value = styled.div`
  font: ${props => props.theme.fonts.number};
  color: ${props => props.theme.titleColor};
  text-align: center;
`

const Limit = styled.div`
  font: ${props => props.theme.fonts.formTitle};
  color: ${props => props.theme.labelColor};
  text-align: center;
  margin-top: auto;
  margin-bottom: 1px;
  letter-spacing: .025em;
`

const Suffix = styled.div`
  font: ${props => props.theme.fonts.xsmall};
  color: ${props => props.theme.labelColor};
  text-align: center;
  margin-top: auto;
  margin-bottom: 3px;
  margin-left: 5px;
  letter-spacing: .025em;
`

class NumberBox extends Component {
  render () {
    const {
      theme,
      label,
      tooltip,
      value,
      limit,
      suffix,
      currency = false,
      border = true,
      padding = '0.75rem',
      ...rest
    } = this.props

    return (
      <Wrapper onClick={this.props.onClick} border={border} padding={padding} {...rest}>

        <Label>
          {label}
          {tooltip &&
            <Tooltip
            // options
              title={tooltip}
              position='top'
              trigger='mouseenter'
              arrow
            >
              <Info
                style={{ marginLeft: '3px', marginTop: '-3px', display: 'inline-block' }}
                color={theme.labelColor}
                width='11px'
                height='11px'
              />
            </Tooltip>}
        </Label>
        <ValueContainer>
          <Value>
            {currency &&
              <NumberFormat
                value={value / 100}
                thousandSeparator
                decimalSeparator='.'
                displayType='text'
                prefix='$'
              />}
            {!currency &&
              <NumberFormat value={value} displayType='text' thousandSeparator=',' />}
          </Value>
          {limit &&
            <Limit>
              /
              <NumberFormat value={limit} displayType='text' thousandSeparator=',' />
            </Limit>}
          {suffix &&
            <Suffix>{suffix}</Suffix>}
        </ValueContainer>
      </Wrapper>
    )
  }
}

NumberBox.propTypes = {
  label: PropTypes.string,
  tooltip: PropTypes.string,
  value: PropTypes.any,
  limit: PropTypes.any,
  suffix: PropTypes.any,
  onClick: PropTypes.func,
  currency: PropTypes.bool,
  border: PropTypes.bool,
  padding: PropTypes.string,
  theme: PropTypes.object
}

export default withTheme(NumberBox)
