import styled from 'styled-components'

export default styled.div`
  background: ${props => props.theme.background};
  -webkit-app-region: drag;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;

  /* Toast message styling */

  .toastify {
    z-index: 10000000;
  }

  .interseller-switch.react-toggle .react-toggle-track {
    background-color: ${props => props.theme.colors.gray50};
  }

  .interseller-switch.react-toggle .react-toggle-thumb {
    border-color: ${props => props.theme.colors.gray20};
  }

  .interseller-switch.react-toggle--checked .react-toggle-track {
    background-color: ${props => props.theme.colors.green};
  }

  .interseller-switch.react-toggle--checked .react-toggle-thumb {
    border-color: ${props => props.theme.colors.gray20};
  }

  .toastify-content {
    padding: 0;
    background-color: transparent;
  }

  .toastify__body {
    opacity: 0.9;
    background-color: white;
    transition: opacity .15s ease-in;
  }

  .toastify__body:hover, .toastify__body:focus {
    opacity: 1;
    background-color: white;
    transition: opacity .15s ease-out;
  }

  /* .toastify--top-right {
    top: 5rem;
  }

  .toastify--top-left {
    top: 5rem;
  } */

  .success-progress{
    background: ${props => props.theme.colors.green}
  }

  .error-progress{
    background: ${props => props.theme.colors.red}
  }

  .warning-progress{
    background: ${props => props.theme.colors.yellow}
  }

  .default-progress{
    background: ${props => props.theme.colors.blue}
  }

`
