/**
*
* SliderFieldForm Component
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import { space, width, height } from 'styled-system'
import styled from 'styled-components'

import CustomSlider from 'components/CustomSlider'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;

  .rc-slider-dot {
    display: none;
  }

  ${space}
  ${width}
  ${height}
`

const Label = styled.label`
  width: 60%;
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  letter-spacing: 0.05em;
`

const SliderContainer = styled.div`
  width: 30%;
  align-items: center;
  justify-content: center;
  display: flex;
  margin-bottom: 7px;
`

const ValueLabel = styled(Label)`
  width: 10%;
  text-transform: uppercase;
  text-align: right;
`

class SliderFieldForm extends Component {
  render () {
    const {
      label,
      description,
      inputId,
      min,
      max,
      step,
      value,
      onSliderChange,
      trackColor,
      tipFormatter,
      marks,
      ...rest
    } = this.props

    return (
      <Wrapper
        {...rest}
      >
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        <SliderContainer>
          <CustomSlider
            value={value}
            min={min}
            max={max}
            step={step}
            trackColor={trackColor}
            tipFormatter={tipFormatter}
            onSliderChange={onSliderChange}
            marks={marks}
          />
        </SliderContainer>
        <ValueLabel>{value}</ValueLabel>
      </Wrapper>
    )
  }
}

SliderFieldForm.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  value: PropTypes.any,
  inputId: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  step: PropTypes.number,
  onSliderChange: PropTypes.func,
  trackColor: PropTypes.any,
  tipFormatter: PropTypes.func,
  marks: PropTypes.object
}

export default SliderFieldForm
