import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Editor, getEventTransfer } from 'slate-react'
import {
  Emoji,
  CustomField
} from '../EditorTags'
import OverlayMenu from 'components/SlateEditor/components/Editors/OverlayMenu/'
import {
  parseHTML
} from 'components/SlateEditor/utils/copy-paste/'
import {
  slateParsingRules,
  slateSchemas,
  slateQueries,
  slateCommands,
  slateDecorations,
  slateNormalizers,
  slateConstants,
  slateHelpers
} from 'components/SlateEditor/utils/slate/'

const BoldMark = styled.b`
  font-weight: 600;
`

const OrderedList = styled.ol`
  margin-top: 22px;
  margin-bottom: 22px;
`

const UnorderedList = styled.ul`
  margin-top: 22px;
  margin-bottom: 22px;
`

const Image = styled.img`
  display: block;
  max-width: 100%;
  max-height: 20em;
`

const ImageLink = styled(Image)`
  cursor: pointer;
`

const Paragraph = styled.p`
  margin: 0;
  padding: 0;
`

const EditorWrapper = styled.div`
  min-height: ${props => props.showSignature ? '10rem' : '20rem'};
  overflow: hidden;
  cursor: auto;
`

const StyledBodyEditor = styled(Editor)`
  background-color: ${props => props.backgroundColor
    ? props.backgroundColor
    : props.theme.colors.white
  };
  cursor: auto;
  padding: ${props => props.showSignature
    ? '1rem 1.25rem 0 1.25rem'
    : '1rem 1.25rem 1.1rem 1.25rem'
  };
  min-height: ${props => props.showSignature ? '10rem' : '20rem'};
  overflow: hidden;
`

const serializer = slateParsingRules.bodyRules()

class BodyEditor extends React.Component {
  handleOnChange = ({ value }) => {
    const {
      setActiveEditor,
      setEditorText,
      setToolbarButtonActivity,
      setToolbarMenuVisibility,
      bodyEditorRef,
      menuVisibility
    } = this.props

    setActiveEditor(slateConstants.SLATE_EDITORS.BODY)
    setEditorText(value, slateConstants.SLATE_EDITORS.BODY)

    if (bodyEditorRef?.current) {
      setToolbarButtonActivity({
        isBoldButtonActive: bodyEditorRef.current.isBoldActive(),
        isItalicButtonActive: bodyEditorRef.current.isItalicActive(),
        isUnderlineButtonActive: bodyEditorRef.current.isUnderlineActive(),
        isLinkButtonActive: bodyEditorRef.current.isLinkActive(),
        isHighlightingActive: bodyEditorRef.current.isHighlightingActive(),
        isOrderedListButtonActive: bodyEditorRef.current.isOrderedListActive(),
        isUnorderedListButtonActive: bodyEditorRef.current.isUnorderedListActive()
      })
    }

    if (
      bodyEditorRef?.current?.isLinkActive() &&
      menuVisibility.showLinkMenu === false &&
      !bodyEditorRef?.current?.isHighlightingActive()
    ) {
      setToolbarMenuVisibility('showLinkMenu', slateConstants.LINK_MENUS.PREVIEW)
    }
  }

  handleOnKeyUp = (_event, editor, next) => {
    const { debouncedCheckRules } = this.props
    debouncedCheckRules()

    slateNormalizers.wrapCustomFields(editor)

    return next()
  }

  handleOnKeyDown = (event, editor, next) => {
    // keep track of it the the user needs to be prompted to save
    const { hasEnteredKeyStroke } = this.props
    if (hasEnteredKeyStroke) {
      hasEnteredKeyStroke()
    }

    // delete last list item if backspaced
    slateNormalizers.deleteListItem(event, editor)

    // exit list if `enter` on last list item that has no text
    slateNormalizers.exitList(event, editor)

    // update selected text to add/remove mark with `mod + <u>|<b>|<i>`
    let mark
    if (slateHelpers.isBoldHotkey(event)) {
      mark = 'bold'
    } else if (slateHelpers.isItalicHotkey(event)) {
      mark = 'italic'
    } else if (slateHelpers.isUnderlinedHotkey(event)) {
      mark = 'underline'
    } else {
      return next()
    }

    event.preventDefault()
    editor.toggleMark(mark)
  }

  handleOnMouseUp = (_event, editor, next) => {
    const { debouncedCheckRules } = this.props
    debouncedCheckRules()
    slateNormalizers.wrapCustomFields(editor)
    return next()
  }

  groupConsoleLog = (name, data) => {
    console.groupCollapsed(name)
    console.log(data)
    console.groupEnd(name)
  }

  onPasteFileType = (files, target) => {
    const { bodyEditorRef } = this.props
    for (const file of files) {
      const reader = new window.FileReader()
      const [mime] = file.type.split('/')

      if (mime !== 'image') {
        continue
      }

      reader.addEventListener('load', () => {
        bodyEditorRef.current.insertImage(reader.result, target)
      })

      reader.readAsDataURL()
    }
  }

  onPasteTextType = (pastedText, target) => {
    // this.groupConsoleLog('Pasted Text', pastedText)
    const { bodyEditorRef } = this.props
    if (slateHelpers.isUrl(pastedText)) {
      const url = pastedText
      if (!bodyEditorRef.current.isHighlightingActive()) {
        const fullHtml = `<a href=${url}>${url}</a>`
        const { document } = serializer.deserialize(fullHtml)
        bodyEditorRef.current.insertFragment(document)
      } else {
        bodyEditorRef.current.wrapLink(url)
      }
    } else {
      try {
        if (!slateHelpers.isUrl(pastedText) || !slateHelpers.isImage(pastedText)) {
          bodyEditorRef
            .current
            .insertText(pastedText)
        } else {
          bodyEditorRef
            .current
            .insertImage(pastedText, target)
        }
      } catch (err) {
        console.error(`ERROR: failed to insert image - ${err}`)
      }
    }
  }

  onPasteHtmlType = (pastedHtml) => {
    // try to paste the html content with our custom slate seralizer
    // If that fails, try to insert the data using Slate's document serializer
    const { bodyEditorRef } = this.props
    try {
      const paste = parseHTML(pastedHtml)
      this.groupConsoleLog('Pasted Text', pastedHtml)
      this.groupConsoleLog('After Parsing Text', paste)

      if (paste) {
        const { document } = serializer.deserialize(paste)
        bodyEditorRef
          .current
          .insertFragment(document)
      }
    } catch (err) {
      console.warn(`Was not able to parse content with interseller serializer - ${err}`)
      console.log('Defaulting to Slate serializer')

      // Use the Slate serializer to catch any potential obscure situations
      const { document } = serializer.deserialize(pastedHtml)
      bodyEditorRef
        .current
        .insertFragment(document)
    }
  }

  decorateNodes = (node, editor, next) => {
    return slateDecorations.decorateCustomFields(node, editor, next)
  }

  handleOnDropOrPaste = (event, editor, next) => {
    event.preventDefault()

    const { debouncedCheckRules } = this.props

    // if the user is performing a drop action - skip over it
    const target = editor.findEventRange(event)
    if (!target && event.type === 'drop') {
      return next()
    }

    const transfer = getEventTransfer(event)
    const TRANSFER_TYPE = {
      TEXT: 'text',
      HTML: 'html',
      FILE: 'file'
    }

    const label = `TRANSFER TYPE: ${transfer.type}`
    console.group(`%c${label}`, 'background-color: rgb(195,232,141)')
    if (transfer.type === TRANSFER_TYPE.FILE) {
      this.onPasteFileType(transfer.files, target)
      debouncedCheckRules()
      console.groupEnd(label)
    } else if (transfer.type === TRANSFER_TYPE.TEXT) {
      this.onPasteTextType(transfer.text, target)
      debouncedCheckRules()
      console.groupEnd(label)
    } else if (transfer.type === TRANSFER_TYPE.HTML) {
      this.onPasteHtmlType(transfer.html)
      debouncedCheckRules()
      console.groupEnd(label)
    } else {
      console.groupEnd(label)
      return next()
    }
  }

  renderDecoration = (props, _editor, next) => {
    const { children, decoration, attributes } = props

    switch (decoration.type) {
      case 'custom_field': {
        return (
          <CustomField {...attributes}>
            {children}
          </CustomField>
        )
      }

      default:
        return next()
    }
  }

  renderMark = (props, _editor, next) => {
    switch (props.mark.type) {
      case 'bold':
        return (
          <BoldMark
            {...props.attributes}
          >
            {props.children}
          </BoldMark>
        )

      case 'italic':
        return <em {...props.attributes}>{props.children}</em>

      case 'underline':
        return <u {...props.attributes}>{props.children}</u>

      default:
        return next()
    }
  }

  renderBlock = (props, _editor, next) => {
    const { attributes, children, node, isFocused } = props
    const { bodyEditorRef, readOnly } = this.props

    switch (node.type) {
      case 'ordered_list': {
        return (
          <OrderedList {...attributes}>
            {children}
          </OrderedList>
        )
      }

      case 'unordered_list': {
        return (
          <UnorderedList {...attributes}>
            {children}
          </UnorderedList>
        )
      }

      case 'list_item': {
        return <li {...attributes}>{children}</li>
      }

      case 'image': {
        const src = node.data.get('src')
        const href = node.data.get('href')
        if (href) {
          return (
            <ImageLink
              {...attributes}
              style={{ border: `${isFocused ? '1px solid #5469b2' : '1px solid transparent'}` }}
              src={src}
              title={href}
              data-href={href}
              onClick={() => {
                // force select the image node
                bodyEditorRef.current.moveToRangeOfNode(node)

                // img is only readOnly when in preview tab
                if (readOnly) {
                  window.open(href, '_blank')
                }
              }}
            />
          )
        } else {
          return (
            <Image
              {...attributes}
              onClick={() => {
                bodyEditorRef.current.moveToRangeOfNode(node)
              }}
              style={{ border: `${isFocused ? '1px solid #629ef7' : '1px solid transparent'}` }}
              src={src}
            />
          )
        }
      }

      case 'h1':
      case 'h2':
      case 'h3':
      case 'h4':
      case 'h5':
      case 'h6': {
        return React.createElement(node.type, { style: { marginTop: '1em', marginBottom: '1em' } }, children)
      }

      case 'paragraph': {
        return (
          <Paragraph {...attributes}>
            {props.children}
          </Paragraph>
        )
      }

      default:
        return next()
    }
  }

  renderInline = (props, _editor, next) => {
    const { attributes, children, node, isFocused } = props

    switch (node.type) {
      case 'link': {
        const { data } = node
        const href = data.get('href')
        return (
          <a
            title={href}
            target='_blank'
            rel='noreferrer'
            alt={href}
            href={href}
            {...attributes}
          >
            {children}
          </a>
        )
      }
      case 'emoji': {
        return (
          <Emoji
            emojiCode={node.data.get('code')}
            isFocused={isFocused}
          />
        )
      }

      default:
        return next()
    }
  }

  render () {
    const {
      body,
      bodyEditorRef,
      placeholder,
      readOnly,
      backgroundColor,
      copyToClipboard,
      linkedInProfileUrl,
      renderOverlayMenu,
      showSignature = false
    } = this.props

    return (
      <EditorWrapper showSignature={showSignature}>
        <StyledBodyEditor
          // DO NOT DELETE: this fixes a bug that occurs in chrome v105+
          style={{
            WebkitUserModify: 'read-write'
          }}
          innerRef={bodyEditorRef}
          value={body}
          readOnly={readOnly}
          placeholder={placeholder}
          onPaste={this.handleOnDropOrPaste}
          onChange={this.handleOnChange}
          onKeyDown={this.handleOnKeyDown}
          onKeyUp={this.handleOnKeyUp}
          decorateNode={this.decorateNodes}
          renderMark={this.renderMark}
          renderBlock={this.renderBlock}
          renderInline={this.renderInline}
          renderDecoration={this.renderDecoration}
          onMouseUp={this.handleOnMouseUp}
          backgroundColor={backgroundColor}
          showSignature={showSignature}
          schema={slateSchemas.bodySchema}
          plugins={[
            {
              queries: slateQueries.bodyQueries,
              commands: slateCommands.bodyCommands
            }
          ]}
        />
        {renderOverlayMenu &&
          <OverlayMenu
            showCopyToClipboard
            linkedInProfileUrl={linkedInProfileUrl}
            copyToClipboard={copyToClipboard}
          />}
      </EditorWrapper>
    )
  }
}

BodyEditor.propTypes = {
  body: PropTypes.object,
  readOnly: PropTypes.bool,
  setActiveEditor: PropTypes.func,
  setEditorText: PropTypes.func,
  hasEnteredKeyStroke: PropTypes.func,
  placeholder: PropTypes.string,
  backgroundColor: PropTypes.string,
  bodyEditorRef: PropTypes.object,
  menuVisibility: PropTypes.object,
  setToolbarButtonActivity: PropTypes.func,
  setToolbarMenuVisibility: PropTypes.func,
  copyToClipboard: PropTypes.func,
  linkedInProfileUrl: PropTypes.string,
  renderOverlayMenu: PropTypes.bool,
  debouncedCheckRules: PropTypes.func,
  showSignature: PropTypes.bool
}

export default BodyEditor
