import React from 'react'
import styled from 'styled-components'

import Checkbox from 'elements/Checkbox'
import HeaderLabel from 'elements/HeaderLabel'

const CheckboxContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding-left: 1.5rem;
  width: 3.75rem;
`

export default function defaultHeaderRowRenderer ({
  className,
  columns,
  style,
  selected,
  onSelected,
  currentKey,
  sortFunction
}) {
  return (
    <div className={className} role='row' style={style}>
      <CheckboxContainer>
        <Checkbox
          selected={selected}
          handleClick={onSelected}
        />
      </CheckboxContainer>
      <HeaderLabel
        sortKey='name'
        onClick={sortFunction}
        currentKey={currentKey}
        width='30%'
      >
        Name
      </HeaderLabel>
      <HeaderLabel
        sortKey='state'
        onClick={sortFunction}
        currentKey={currentKey}
        width='20%'
      >
        Status
      </HeaderLabel>
      <HeaderLabel
        sortKey='company'
        onClick={sortFunction}
        currentKey={currentKey}
        width='30%'
      >
        Company
      </HeaderLabel>
      <HeaderLabel
        sortKey='step'
        onClick={sortFunction}
        currentKey={currentKey}
        width='10%'
      >
        Step
      </HeaderLabel>
      <div style={{ width: '5%' }} />
    </div>
  )
}
