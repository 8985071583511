import React from 'react'

const ConfusedAlien = ({
  onClick,
  width,
  height
}) => (
  <svg onClick={onClick} width={width} height={height} version='1.1' viewBox='0 0 161 131' xmlns='http://www.w3.org/2000/svg'>
    <defs>
      <circle id='a' cx='63.8' cy='63.8' r='63.8' />
      <linearGradient id='i' x1='50.095%' x2='50.095%' y1='99.906%' y2='.11556%'>
        <stop stopColor='#639EF7' offset='.5346' />
        <stop stopColor='#629DF5' offset='.7392' />
        <stop stopColor='#5F98EE' offset='.8129' />
        <stop stopColor='#5A90E3' offset='.8654' />
        <stop stopColor='#5384D2' offset='.9079' />
        <stop stopColor='#4975BC' offset='.9443' />
        <stop stopColor='#3D63A1' offset='.9759' />
        <stop stopColor='#325187' offset='1' />
      </linearGradient>
      <linearGradient id='h' x1='47.48%' x2='47.48%' y1='100.05%' y2='.0735%'>
        <stop stopColor='#639EF7' offset='0' />
        <stop stopColor='#629CF5' offset='.3527' />
        <stop stopColor='#5F97ED' offset='.5238' />
        <stop stopColor='#598EE0' offset='.6559' />
        <stop stopColor='#5182CE' offset='.7679' />
        <stop stopColor='#4671B6' offset='.8673' />
        <stop stopColor='#3A5D98' offset='.9563' />
        <stop stopColor='#325187' offset='1' />
      </linearGradient>
      <linearGradient id='g' x1='49.546%' x2='49.546%' y1='101.76%' y2='-.095331%'>
        <stop stopColor='#639EF7' offset='.4206' />
        <stop stopColor='#629DF5' offset='.6753' />
        <stop stopColor='#5F98EE' offset='.7671' />
        <stop stopColor='#5A90E3' offset='.8325' />
        <stop stopColor='#5384D2' offset='.8853' />
        <stop stopColor='#4975BC' offset='.9306' />
        <stop stopColor='#3D63A1' offset='.97' />
        <stop stopColor='#325187' offset='1' />
      </linearGradient>
      <linearGradient id='f' x1='-2.4856%' x2='99.982%' y1='49.892%' y2='49.892%'>
        <stop stopColor='#325187' offset='0' />
        <stop stopColor='#3D63A1' offset='.031061' />
        <stop stopColor='#4975BC' offset='.071876' />
        <stop stopColor='#5384D2' offset='.1188' />
        <stop stopColor='#5A90E3' offset='.1736' />
        <stop stopColor='#5F98EE' offset='.2414' />
        <stop stopColor='#629DF5' offset='.3364' />
        <stop stopColor='#639EF7' offset='.6004' />
      </linearGradient>
      <linearGradient id='e' x1='.63132%' x2='100.4%' y1='49.875%' y2='49.875%'>
        <stop stopColor='#639EF7' offset='.3996' />
        <stop stopColor='#629DF5' offset='.6636' />
        <stop stopColor='#5F98EE' offset='.7586' />
        <stop stopColor='#5A90E3' offset='.8264' />
        <stop stopColor='#5384D2' offset='.8812' />
        <stop stopColor='#4975BC' offset='.9281' />
        <stop stopColor='#3D63A1' offset='.9689' />
        <stop stopColor='#325187' offset='1' />
      </linearGradient>
      <linearGradient id='d' x1='-.01326%' x2='99.434%' y1='49.719%' y2='49.719%'>
        <stop stopColor='#639EF7' offset='.3996' />
        <stop stopColor='#629DF5' offset='.6636' />
        <stop stopColor='#5F98EE' offset='.7586' />
        <stop stopColor='#5A90E3' offset='.8264' />
        <stop stopColor='#5384D2' offset='.8812' />
        <stop stopColor='#4975BC' offset='.9281' />
        <stop stopColor='#3D63A1' offset='.9689' />
        <stop stopColor='#325187' offset='1' />
      </linearGradient>
      <linearGradient id='c' x1='-.376%' x2='99.614%' y1='51.126%' y2='51.126%'>
        <stop stopColor='#325187' offset='0' />
        <stop stopColor='#3D63A1' offset='.031061' />
        <stop stopColor='#4975BC' offset='.071876' />
        <stop stopColor='#5384D2' offset='.1188' />
        <stop stopColor='#5A90E3' offset='.1736' />
        <stop stopColor='#5F98EE' offset='.2414' />
        <stop stopColor='#629DF5' offset='.3364' />
        <stop stopColor='#639EF7' offset='.6004' />
      </linearGradient>
    </defs>
    <g fill='none' fillRule='evenodd'>
      <g transform='translate(23.5 2.8)' fillRule='nonzero'>
        <mask id='b' fill='white'>
          <use xlinkHref='#a' />
        </mask>
        <use fill='#BCCADE' xlinkHref='#a' />
        <path d='m90 127.9h-31.5c-9.8 0-17.8-8-17.8-17.8v-34.6c0-13.6 11-24.7 24.7-24.7 13.6 0 24.7 11 24.7 24.7v52.4h-0.1z' fill='#639EF7' mask='url(#b)' />
      </g>
      <g transform='translate(14.3 .4)'>
        <path d='m95.2 32.3l-0.2 10.7v0.3 0.4c0 0.3 0 0.6-0.1 0.9-0.1 0.6-0.3 1.1-0.5 1.7-0.4 1.1-1.2 2-2 2.8-0.9 0.8-1.9 1.3-3 1.6-0.6 0.1-1.1 0.2-1.7 0.3h-0.7-0.5c-1.3 0.2-2.5 1-3.1 2.1-0.3 0.6-0.5 1.2-0.6 1.9v0.6 0.7 1.3l-0.2 10.7h-2.5l-0.2-10.7v-1.3-0.7-0.7c0-1.1 0.3-2.2 0.8-3.2 1-2 3-3.5 5.2-3.9 0.3 0 0.6-0.1 0.8-0.1h0.7c0.3 0 0.6-0.1 0.9-0.2 0.6-0.2 1.2-0.5 1.6-1 0.5-0.4 0.8-1 1-1.6 0.1-0.3 0.2-0.6 0.2-0.9v-0.5-0.2-0.3l-0.2-10.7h4.3z' fill='url(#i)' fillRule='nonzero' />
        <circle cx='94.8' cy='23.5' r='10.8' fill='#639EF7' fillRule='nonzero' />
        <circle cx='94.8' cy='23.5' r='7' fill='#fff' fillRule='nonzero' />
        <circle cx='59.9' cy='103.1' r='2.7' fill='#325187' fillRule='nonzero' />
        <circle cx='85.1' cy='119.6' r='6.1' fill='#325187' fillRule='nonzero' />
        <circle cx='83.4' cy='68.9' r='2.7' fill='#325187' fillRule='nonzero' />
        <circle cx='89.3' cy='74.4' r='2' fill='#325187' fillRule='nonzero' />
        <circle cx='96.6' cy='22.4' r='2.2' fill='#325187' fillRule='nonzero' />
        <path d='m69.8 57.4l-0.2-11.1v-0.7c0-0.1 0-0.3-0.1-0.4-0.1-0.3-0.2-0.5-0.3-0.8-0.3-0.5-0.8-0.8-1.3-1-0.1 0-0.3-0.1-0.4-0.1h-0.6c-0.6 0-1.3-0.2-1.9-0.4-1.2-0.5-2.2-1.5-2.7-2.7-0.3-0.6-0.4-1.2-0.5-1.9v-0.8-0.7-2.8l-0.1-5.5-0.2-11.1h4l-0.2 11.1-0.1 5.5v2.8 0.7 0.6c0 0.3 0.1 0.5 0.2 0.8 0.2 0.5 0.6 0.9 1.1 1.2 0.2 0.1 0.5 0.2 0.8 0.2 0.1 0 0.5 0 0.8 0.1 0.3 0 0.6 0.1 0.9 0.2 1.2 0.4 2.3 1.2 3 2.3 0.3 0.5 0.6 1.2 0.7 1.8 0.1 0.3 0.1 0.6 0.1 0.9v0.7l-0.2 11.1h-2.8z' fill='url(#h)' fillRule='nonzero' />
        <circle cx='63.3' cy='13.6' r='13.6' fill='#639EF7' fillRule='nonzero' />
        <circle cx='63.3' cy='13.6' r='8.8' fill='#fff' fillRule='nonzero' />
        <circle cx='66' cy='12.4' r='2.7' fill='#325187' fillRule='nonzero' />
        <path d='m42.3 40.5l-0.1 5.8v1.4 0.2 0.1 0.2c0 0.1 0 0.3 0.1 0.4 0.1 0.5 0.5 1 0.9 1.2 0.2 0.1 0.5 0.3 0.7 0.3 0.1 0 0.3 0 0.4 0.1h0.4c0.2 0 0.3 0 0.5 0.1 1.4 0.2 2.6 1 3.5 2s1.4 2.4 1.3 3.8v2.9 1.4 0.6c0 0.1 0 0.3 0.1 0.4 0.1 0.6 0.5 1.1 1 1.4 0.2 0.2 0.5 0.3 0.8 0.4s0.5 0.1 1.1 0.1h2.9l11.5 0.2v2.5l-11.5 0.2h-2.9c-0.4 0-1.1 0-1.8-0.1-0.6-0.1-1.3-0.4-1.8-0.7-1.1-0.7-2-1.8-2.3-3.1-0.1-0.3-0.2-0.6-0.2-1v-0.9-1.4-2.9c0-0.6-0.2-1.1-0.6-1.5s-0.9-0.7-1.4-0.7h-0.2-0.3c-0.3 0-0.7 0-1-0.1-0.7-0.1-1.3-0.4-1.9-0.7-1.2-0.7-2.1-1.8-2.6-3.2-0.1-0.3-0.2-0.7-0.2-1 0-0.2 0-0.3-0.1-0.5v-0.3-0.2-1.4l-0.1-5.8h3.8v-0.2z' fill='url(#g)' fillRule='nonzero' />
        <path d='m49.9 94l-7.9-0.2-2-0.1h-1l-1.3-0.1c-1.7-0.2-3.3-0.8-4.7-1.6-2.9-1.7-5-4.5-5.7-7.8-0.1-0.4-0.2-0.8-0.2-1.2-0.1-0.4-0.1-0.8-0.1-1.2v-0.9c0-0.3 0-0.5-0.1-0.7-0.1-1-0.5-2-1-2.8-1-1.8-2.8-3.1-4.9-3.5-0.5-0.1-1-0.2-1.6-0.2l-2-0.1-3.9-0.2h-1c-0.4 0-0.8-0.1-1.1-0.1-0.8-0.1-1.5-0.3-2.2-0.6-1.4-0.5-2.7-1.3-3.8-2.4-0.6-0.5-0.9-1-1.4-1.5l-1.3-1.5-2.7-2.9 1.4-1.4 2.9 2.7 1.5 1.3c0.5 0.4 1 0.9 1.4 1.3 0.9 0.7 1.9 1.2 3 1.5 0.5 0.1 1.1 0.2 1.6 0.3h0.8 1l3.9-0.2 2-0.1c0.7 0 1.6 0 2.3 0.2 3.1 0.5 6 2.4 7.8 5 0.9 1.3 1.5 2.9 1.8 4.5 0.1 0.4 0.1 0.8 0.2 1.2l0.1 1c0 0.2 0 0.5 0.1 0.7 0 0.2 0.1 0.5 0.2 0.7 0.5 1.9 1.9 3.5 3.6 4.4 0.9 0.4 1.8 0.7 2.7 0.8h0.7 1l2-0.1 7.9-0.2v6z' fill='url(#f)' fillRule='nonzero' />
        <path d='m74 79.9c-2.7 0-2.7-2-5.3-2-2.7 0-2.7 2-5.3 2-2.7 0-2.7-2-5.3-2' stroke='#315186' strokeLinecap='round' strokeWidth='2' />
        <circle cx='37.9' cy='33.7' r='10.8' fill='#639EF7' fillRule='nonzero' />
        <circle cx='37.9' cy='33.7' r='7' fill='#fff' fillRule='nonzero' />
        <circle cx='35.8' cy='31.5' r='2.2' fill='#325187' fillRule='nonzero' />
        <path d='m96.9 92.3l6.1 1c2.6 0.4 4.9 1.8 6.7 3.6s2.9 4.2 3.2 6.7c0.1 0.6 0.1 1.2 0.1 1.9v1.2c0.1 0.8 0.3 1.6 0.6 2.3 0.6 1.5 1.8 2.7 3.2 3.6 0.7 0.4 1.5 0.7 2.3 0.9 0.4 0.1 0.8 0.1 1.2 0.2l1.5 0.1 12.2 0.5v2l-12.2 0.5-1.5 0.1c-0.6 0-1.2 0-1.8-0.1-1.2-0.1-2.4-0.5-3.4-1-2.2-1-4-2.8-5.2-4.9-0.6-1.1-1-2.3-1.2-3.5-0.1-0.6-0.2-1.3-0.2-1.8 0-0.4-0.1-0.8-0.2-1.2-0.3-1.5-1.1-2.9-2.3-3.9-1.1-1-2.5-1.6-4-1.7l-6-0.5 0.9-6z' fill='url(#e)' fillRule='nonzero' />
        <path d='m96.6 80l3 0.2h0.6c0.2 0 0.3 0 0.5-0.1 0.3 0 0.6-0.2 0.9-0.3 0.6-0.3 1.1-0.8 1.5-1.4 0.2-0.3 0.3-0.6 0.4-1 0.1-0.2 0.1-0.4 0.1-0.5 0-0.3 0-0.6 0.1-0.9 0.4-2.5 2.5-4.5 4.8-5 0.6-0.1 1.2-0.1 1.7-0.1l1.5 0.1 3 0.2v1.3l-3 0.2-1.5 0.1c-0.5 0-0.8 0.1-1.2 0.2-1.5 0.5-2.6 1.9-2.7 3.4v0.5 0.9c-0.1 0.6-0.2 1.3-0.5 1.9-0.5 1.2-1.4 2.2-2.5 3-0.5 0.4-1.2 0.7-1.8 0.8-0.3 0.1-0.6 0.2-1 0.2-0.3 0-0.7 0.1-0.9 0.1l-3 0.2v-4z' fill='url(#d)' fillRule='nonzero' />
        <path d='m51.7 108.7l-3.9-0.2h-0.4-0.2c-0.2 0-0.3 0.1-0.5 0.1-0.3 0.1-0.6 0.4-0.8 0.6-0.1 0.1-0.2 0.3-0.2 0.5 0 0.1 0 0.2-0.1 0.3v0.4c-0.1 0.9-0.4 1.9-1 2.6-0.6 0.8-1.3 1.3-2.2 1.7-0.4 0.2-0.9 0.3-1.3 0.3h-0.6-0.5l-15.7-0.7v-1.3l15.7-0.7h0.5 0.3c0.2 0 0.4-0.1 0.6-0.2 0.3-0.2 0.6-0.5 0.8-0.8s0.3-0.7 0.3-1.1v-0.6c0-0.2 0-0.5 0.1-0.7 0.1-0.5 0.3-0.9 0.5-1.4 0.5-0.9 1.2-1.6 2-2.1 0.4-0.2 0.9-0.4 1.4-0.6 0.2-0.1 0.5-0.1 0.7-0.1h0.4 0.3l3.9-0.2v4.2h-0.1z' fill='url(#c)' fillRule='nonzero' />
      </g>
      <g transform='translate(149 109)' stroke='#639EF7' strokeLinecap='round' strokeWidth='3'>
        <path d='m0 5.5c0-3 2.4-5.4 5.4-5.4 1.9 0 3.6 1 4.6 2.5' />
        <path d='m0 6c0 3 2.4 5.4 5.4 5.4 1.9 0 3.6-1 4.6-2.5' />
      </g>
      <g transform='translate(128 67)' stroke='#639EF7' strokeLinecap='round' strokeWidth='3'>
        <path d='m0.9 5c0-2.6 2.1-4.7 4.7-4.7 1.7 0 3.2 0.9 4 2.2' />
        <path d='m0.9 5.4c0 2.6 2.1 4.7 4.7 4.7 1.7 0 3.2-0.9 4-2.2' />
      </g>
      <g transform='translate(29 109)' stroke='#639EF7' strokeLinecap='round' strokeWidth='3'>
        <path d='m9 5.3c0 2.6-2.1 4.7-4.7 4.7-1.7 0-3.2-0.9-4-2.2' />
        <path d='m9 4.9c0-2.6-2.1-4.7-4.7-4.7-1.7 0-3.2 0.9-4 2.2' />
      </g>
      <g transform='translate(1 51)' stroke='#639EF7' strokeLinecap='round' strokeWidth='3'>
        <path d='m13.4 13.4c-2.9 2.9-7.5 2.9-10.4 0-1.8-1.8-2.5-4.4-2-6.8' />
        <path d='m13.9 12.9c2.9-2.9 2.9-7.5 0-10.4-1.8-1.8-4.4-2.5-6.8-2' />
      </g>
    </g>
  </svg>
)

export default ConfusedAlien
