import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import Table from 'components/Table'
import Button from 'components/Button'
import Label from 'elements/Label'
import Input from 'components/Input'

const Wrapper = styled.div`
  width: 100%;
  padding: ${props => props.theme.padding};
`

const TableContainer = styled.div`
  padding: 1rem;
  max-height: calc(100vh - 194px);

  th:first-child,
  td:first-child {
    border-right: ${props => `solid 1px ${props.theme.borderColor}`};
  }

  th:last-child {
    width: 10%;
  }

  tbody tr {
    border-top: ${props => `solid 1px ${props.theme.borderColor}`};
  }
`

const RowButton = styled.button`
  width: 100%;
  padding: 0;
  border: 0;
  cursor: pointer;

  &:hover {
    opacity: .5;
    transition: opacity .15s ease-out;
    cursor: pointer;
  }
`

const Field = styled(Input)`
  position: absolute;
  inset: 0;

  * {
    border: 0;
  }

  input {
    opacity: 1;
  }
`

class CustomFields extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  normalizeField = (field) => {
    return field
      .toLowerCase()
      .replace(/[^A-Za-z0-9_\s]/ig, '')
  }

  renderRows = () => {
    const { mappedFields, onChange, onDeleteRow } = this.props
    const rows = mappedFields
      .map(({ field, value, unsaved }, idx) => ({
        renderField: (index) => (
          <Field
            inputId={'custom_field_' + idx}
            type='text'
            value={field}
            onValueChange={(field) => {
              onChange({ field: this.normalizeField(field) }, index)
            }}
            disabled={!unsaved}
          />
        ),
        renderValue: (index) => (
          <Field
            inputId={'default_value_' + idx}
            type='text'
            value={value || ''}
            onValueChange={(value) => {
              onChange({ value }, index)
            }}
          />
        ),
        onClick: () => {
          onDeleteRow(idx)
        }
      }))

    return rows
  }

  render () {
    const { onAddRow } = this.props
    const rows = this.renderRows()

    const cols = [
      {
        header: 'Custom field',
        render: (row, index) => row.renderField(index)
      },
      {
        header: 'Default value',
        render: (row, index) => row.renderValue(index)
      },
      {
        render: (row) => (
          <RowButton onClick={row.onClick}>
            <FontAwesomeIcon
              icon={['fal', 'trash-alt']}
              color='#ff6666'
            />
          </RowButton>
        )
      }
    ]

    return (
      <Wrapper>
        <TableContainer>
          <Label mb='1rem'>
            Custom fields can be used in sequences. Fields can
            only consist of letters, numbers and underscores
            (e.g. custom_field).
          </Label>
          <Table
            border
            cols={cols}
            rows={rows}
          />
          <Button
            mt='1.5rem'
            label='Add new field'
            onClick={onAddRow}
          />
        </TableContainer>
      </Wrapper>
    )
  }
}

CustomFields.propTypes = {
  actions: PropTypes.object,
  sequence: PropTypes.object,
  mappedFields: PropTypes.instanceOf(Immutable.List),
  onAddRow: PropTypes.func,
  onChange: PropTypes.func,
  onDeleteRow: PropTypes.func
}

export default CustomFields
