import metrics from './metrics'
import fonts from './fonts'
import colors from './colors'
import grid from './grid'

export default {
  metrics,
  colors,
  fonts,
  grid,
  padding: metrics.baseMargin
}
