import styled from 'styled-components'

const ExternalLink = styled.a`
  color: ${props => props.theme.colors.gray70};
  text-align: center;
  cursor: pointer;
  margin-left: 1rem;
  margin-right: 1rem;
  margin-top: 1.1rem;
  text-transform: uppercase;
  text-decoration: none;
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
  font: ${props => props.theme.fonts.button};
  letter-spacing: .05em;
  border-bottom: 2px solid transparent;

  &.${props => props.activeClassName} {
    border-bottom: ${props => `2px solid ${props.theme.colors.blue}`};
    color: ${props => props.theme.colors.blue};
    cursor: default;
    transition: none;

    &:hover,
    &:focus {
      opacity: 1;
      transition: none;
    }
  };

  opacity: 1;
  transition: opacity .15s ease-in;

  &:hover,
  &:focus {
    opacity: .5;
    transition: opacity .15s ease-in;
  }

  &:active {
    opacity: 1;
    transition: opacity .15s ease-out;
  }
`

export default ExternalLink
