import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width } from 'styled-system'

import Title from 'elements/Title'
import Label from 'elements/Label'
import { Wordmark } from 'svg'
import { fadeIn } from 'utils/animations'

const LogoContainer = styled.div`
  padding-bottom: 5rem;
`

const Wrapper = styled.div`
  max-width: 342px;
  padding-bottom: 5rem;

  animation: ${fadeIn} .15s linear 1;
`

const Terms = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  color: ${props => props.theme.colors.gray60};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 2rem;

  ${space}
  ${width}
`

class MagicLink extends Component {
  UNSAFE_componentWillMount () {
    document.title = 'Interseller | Login - MagicLink'
  }

  render () {
    const {
      magic,
      router
    } = this.props

    const email = magic.getIn(['data', 'email'])

    return (
      <Wrapper>
        <LogoContainer>
          <Wordmark />
        </LogoContainer>
        <Title>Magic Link Sent! <span role='img' aria-label='rocket'>🚀</span></Title>
        <Label mt='1rem'>Check the inbox of <strong>{email}</strong> for a link to sign in!</Label>
        <Terms pt='1rem'>If you don't receive an email from us you may not have an account. <a href='#' onClick={() => { router.push('/signup') }}>Signup here</a> to create an account.</Terms>
      </Wrapper>
    )
  }
}

MagicLink.propTypes = {
  magic: PropTypes.object,
  router: PropTypes.object
}

export default MagicLink
