import React, { PureComponent } from 'react'
import Title from 'elements/Title'
import Label from 'elements/Label'
import { space } from 'styled-system'
import Alien from 'svg/Alien.svg'
import PropTypes from 'prop-types'
import styled from 'styled-components'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  background-color: ${props => props.theme.colors.white};
  min-height: 50vh;
  border-left: 0;
  border-right: 0;
  padding-top: 2rem;
  padding-bottom: 2rem;

  ${space}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 2rem;
`

class EmptyState extends PureComponent {
  render () {
    const {
      title,
      description,
      children,
      shadow = false,
      ...rest
    } = this.props

    return (
      <Wrapper shadow={shadow} {...rest}>
        <div style={{ marginRight: '1.5rem' }}>
          <img
            src={Alien}
            alt='Error Bear'
            style={{
              height: '226',
              width: '250'
            }}
          />
        </div>
        <Title mt='1.5rem' mb='0'>{title}</Title>
        <Label align='center' mt='0.5rem' mb='0'>{description}</Label>
        <ButtonContainer>
          {children}
        </ButtonContainer>
      </Wrapper>
    )
  }
}

EmptyState.propTypes = {
  children: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.object,
  shadow: PropTypes.bool
}

export default EmptyState
