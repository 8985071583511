import moment from 'moment-timezone'

export function guessBrowserTimezone () {
  return moment.tz.guess()
}

export function getTimezoneNames () {
  return moment.tz.names()
    .map(t => ({ label: t, value: t }))
}

export function timeAgo (isoDate) {
  return moment(isoDate).fromNow()
}

export function toUnixTimestamp (value) {
  return moment(value).valueOf()
}

export function getTimeDiff (start, end, format) {
  if (!start) {
    return ''
  }

  start = moment(start, format)
  end = end ? moment(end, format) : moment()
  const months = moment(end).diff(start, 'months')
  const years = Math.floor(months / 12)
  const rMonths = months % 12

  const parts = []
  if (years > 0) {
    parts.push(`${years} ${years === 1 ? 'year' : 'years'}`)
  }
  if (rMonths > 0) {
    parts.push(`${rMonths} ${rMonths === 1 ? 'month' : 'months'}`)
  }

  return parts.join(', ')
}

export function toUTC (date, format = 'MM-DD-YYYY') {
  if (!date) {
    return null
  }

  return moment(date, format).utc()
}
