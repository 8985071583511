import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { space, height } from 'styled-system'
import Loading from 'components/Loading'
import SlateEditor from 'components/SlateEditor'
import TaskList from '../TaskList'
import ContactSequenceDropdown from '../ContactSequenceDropdown'
import EditContactSidebar from '../EditContactSidebar'
import { SLATE_EDITORS } from 'components/SlateEditor/utils/slate/constants'
import PauseModal from '../../../Sequence/components/Contacts/PauseModal'

const Wrapper = styled.div`
  height: 100%;
  width: 100%;
  padding-left: 2rem;
  padding-right: 2rem;
`

const FlexWrapper = styled.div`
  display: grid;
  grid-template-columns: 50% 50%;
  align-items: start;
`

const Container = styled.div`
  display: flex;
  flex: 1;
  height: calc(100vh - 360px);
  flex-direction: column;
  align-items: start;
  justify-content: start;

  ${space}
  ${height}
`

const EditorContainer = styled.div`
  width: 100%;
  flex: 1;
`

const EditorWrapper = styled.div`
  border: 1px solid ${props => props.theme.borderColor};
`

const EmptyWrapper = styled.div`
  height: 100%;
  width: 100%;
  padding: 5rem;

  display: flex;
  align-items: center;
  justify-content: center;
`

const EmptySection = styled.div`
  height: 100%;
  width: 100%;
  background: ${props => props.theme.containerBackground};
  border: 1px solid ${props => props.theme.borderColor};
  padding: 5rem;
`

class ManualTasks extends Component {
  constructor (props) {
    super(props)
    this.state = {
      activeTask: null,
      activeTaskIndex: 0,
      selectedMemberId: null,
      selectedSequenceId: null,
      showEditContact: false,
      checkedContacts: [],
      showPauseContactsModal: false
    }
  }

  handleChecked = (contactId) => {
    const {
      checkedContacts
    } = this.state

    const i = checkedContacts.indexOf(contactId)
    if (i >= 0) {
      checkedContacts.splice(i, 1)
    } else {
      checkedContacts.push(contactId)
    }

    this.setState({
      checkedContacts
    })
  }

  handleAllChecked = () => {
    const {
      checkedContacts
    } = this.state
    const {
      manualTasks
    } = this.props

    if (checkedContacts.length === manualTasks.get('data').count()) {
      this.setState({
        checkedContacts: []
      })
    } else {
      this.setState({
        checkedContacts: manualTasks.get('data').map(task => task.get('id')).toArray()
      })
    }
  }

  handlePauseContacts = () => {
    this.setState({
      showPauseContactsModal: true
    })
  }

  handleSkipContacts = () => {
    const {
      checkedContacts
    } = this.state
    const {
      actions
    } = this.props

    actions.skipManualTasks(checkedContacts)
    this.setState({
      checkedContacts: []
    })
  }

  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props

    actions.fetchTeamMembers()
    actions.fetchSequences()
    actions.fetchManualTasks()

    document.title = 'Interseller | Manual Tasks'
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const { manualTasks } = newProps
    const { activeTask, activeTaskIndex } = this.state

    if ((!activeTask || manualTasks !== this.props.manualTasks) && manualTasks.get('data').count() > 0) {
      if (activeTaskIndex !== null && activeTaskIndex < manualTasks.get('data').count() - 1) {
        this.onTaskSelected(manualTasks.getIn(['data', activeTaskIndex]), activeTaskIndex)
      } else {
        this.onTaskSelected(manualTasks.getIn(['data', 0]), 0)
      }
    }

    if (manualTasks.get('data').count() === 0 && activeTask) {
      this.setState({ activeTask: null })
    }

    if (!this.state.selectedMemberId && !this.state.selectedSequenceId && newProps.session) {
      this.setState({
        selectedMemberId: newProps.session.get('id')
      })
    }
  }

  onTaskSelected = (task, index) => {
    const { actions } = this.props
    const contactId = task.get('id')
    const stepIndex = task.get('next_step')
    actions.fetchContactTask(contactId, stepIndex)

    this.setState({
      activeTask: task,
      activeTaskIndex: index,
      showEditContact: false
    })
  }

  saveManualTask = ({ replied } = {}) => {
    const { actions } = this.props
    const { activeTask } = this.state

    const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)

    actions.updateContactTask(
      activeTask.get('id'),
      activeTask.get('next_step'),
      markdown,
      {
        completed: true,
        replied
      }
    )
  }

  deleteContact = (task) => {
    this.setState({
      showDeleteConfirmModal: true,
      actionTask: task
    })
  }

  editContact = (contactId) => {
    this.setState({
      showEditContact: true
    })
  }

  render () {
    const {
      actions,
      manualTasks,
      contactCompany,
      selectedContact,
      session,
      theme,
      members,
      sequences,
      contactTask
    } = this.props
    const {
      activeTask,
      selectedMemberId,
      selectedSequenceId,
      checkedContacts,
      showPauseContactsModal
    } = this.state

    const loading = contactTask.get('loading') || manualTasks.get('loading')

    const contactSequence = sequences
      .find(s => s.get('_id') === selectedContact.getIn(['data', '_campaign']))

    // if source url has /talent/ then use that, otherwise default back to profile_url
    const sourceUrl = selectedContact.getIn(['data', 'source_url'])
    const profileUrl = selectedContact.getIn(['data', 'profile_url'])
    const linkedInProfileUrl = (sourceUrl && sourceUrl.includes('linkedin.com/talent'))
      ? sourceUrl
      : profileUrl

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <FlexWrapper>
          <Container>
            <ContactSequenceDropdown
              session={session}
              members={members}
              sequences={sequences}
              actions={actions}
              selectedMemberId={selectedMemberId}
              selectedSequenceId={selectedSequenceId}
              onOptionChange={(option) => {
                const value = option ? JSON.parse(option.value) : {}

                this.setState({
                  activeTask: null,
                  selectedMemberId: value.userId,
                  selectedSequenceId: value.sequenceId
                })

                actions.fetchContactTaskReset()
                actions.fetchManualTasks(value.userId, value.sequenceId)
              }}
            />
            <TaskList
              actions={actions}
              tasks={manualTasks}
              taskType='Manual'
              handleTaskSelected={this.onTaskSelected}
              activeTask={activeTask}
              contactCompany={contactCompany}
              selectedContact={selectedContact}
              editContact={this.editContact}
              onChecked={this.handleChecked}
              onAllChecked={this.handleAllChecked}
              checkedContacts={checkedContacts}
              allChecked={manualTasks.get('data').count() === checkedContacts.length}
              onPause={this.handlePauseContacts}
              onSkip={this.handleSkipContacts}
            />
          </Container>

          {loading &&
            <Container pl='1rem' height='100%'>
              <EmptyWrapper>
                <Loading />
              </EmptyWrapper>
            </Container>}

          {!loading &&
            <Container pl='1rem' height='100%'>
              {!activeTask && <EmptySection />}

              {activeTask &&
                <EditorContainer>
                  <EditorWrapper>
                    <SlateEditor
                      key={contactTask.getIn(['data', 'id'])}
                      innerRef={(element) => { this.editor = element }}
                      renderOverlayMenu
                      linkedInProfileUrl={linkedInProfileUrl}
                      onSave={this.saveManualTask}
                      onSaveLabel='Complete Task'
                      body={contactTask.getIn(['data', 'markdown'])}
                      hideSubjectLine
                      hideVariables
                      hideTemplates
                      hideEditorCoach
                      backgroundColor={theme.colors.lightYellow}
                      saveActions={[
                        {
                          title: 'Complete Task & Mark Replied',
                          fn: () => {
                            return this.saveManualTask({ replied: true })
                          }
                        },
                        {
                          title: 'Pause Task/Contact',
                          fn: () => {
                            return actions.setPauseContactTask(activeTask)
                          }
                        },
                        {
                          title: 'Skip Task',
                          fn: () => {
                            return actions.skipManualTask(
                              activeTask.get('id'),
                              activeTask.get('next_step'),
                              activeTask.getIn(['_campaign', '_id'])
                            )
                          }
                        }
                      ]}
                      mt={0}
                    />
                  </EditorWrapper>
                </EditorContainer>}
            </Container>}
        </FlexWrapper>

        {this.state.showEditContact &&
          <EditContactSidebar
            contact={selectedContact.get('data')}
            sequence={contactSequence}
            loading={selectedContact.get('loading')}
            theme={this.props.theme}
            router={this.props.router}
            onClose={() => {
              this.setState({
                showEditContact: false
              })
            }}
            onRemove={() => {
              const contactId = selectedContact.getIn(['data', '_id'])
              this.props.actions.removeContact(contactId)
            }}
            onSave={(params) => {
              const contactId = selectedContact.getIn(['data', '_id'])
              this.props.actions.updateSequenceContact(contactId, params)
              this.setState({
                showEditContact: false
              })
            }}
          />}
        <PauseModal
          isOpen={showPauseContactsModal}
          onCancel={() => {
            this.setState({
              showPauseContactsModal: false
            })
          }}
          onConfirm={(date) => {
            actions.updateContacts(null, checkedContacts, { delay_until: date, delayed: true, delayed_indefinitely: date === true }, 'Paused messages for', null, null, null)
            this.setState({
              showPauseContactsModal: false,
              checkedContacts: []
            })
          }}
          title='Pause Messages'
          description='Pause messages indefinitely or until specified date'
        />
      </Wrapper>
    )
  }
}

ManualTasks.propTypes = {
  tasks: PropTypes.object,
  contactTask: PropTypes.object,
  contactCompany: PropTypes.object,
  actions: PropTypes.object,
  selectedContact: PropTypes.object,
  session: PropTypes.object,
  members: PropTypes.object,
  sequences: PropTypes.object,
  manualTasks: PropTypes.object,
  router: PropTypes.object,
  theme: PropTypes.object
}

export default withTheme(ManualTasks)
