import { isKeyHotkey } from 'is-hotkey'
import isUrl from 'is-url'
import imageExtensions from 'image-extensions'

const isBoldHotkey = isKeyHotkey('mod+b')
const isItalicHotkey = isKeyHotkey('mod+i')
const isUnderlinedHotkey = isKeyHotkey('mod+u')

function isValidImageFile (file) {
  if (!file) {
    return {
      error: 'No file was found - please reupload as a .jpg, .png, or .gif',
      isValid: false
    }
  }

  const ACCEPTABLE_FILETYPES = ['image/jpg', 'image/jpeg', 'image/png', 'image/gif']
  const FILESIZE_CAP = 16 * 1024 * 1024

  if (!ACCEPTABLE_FILETYPES.includes(file.type)) {
    return {
      error: 'Incorrect file type - please re-upload with a .jpg, .png, or .gif',
      isValid: false
    }
  } else if (file.size > FILESIZE_CAP) {
    return {
      error: 'File limit exceeded',
      isValid: false
    }
  }

  return {
    isValid: true,
    error: null
  }
}

function isImage (url) {
  const extension = new URL(url).pathname.split('.').pop()
  return imageExtensions.includes(extension)
}

function isValidUrl (str) {
  /* eslint-disable */
  const pattern = new RegExp(
    '(?:(?:(?:https?|ftp):)?//)' // protocol identifier
    + '(?:\\S+(?::\\S*)?@)?' // user:pass authentication
    + '(?:'
    + '(?!(?:10|127)(?:\\.\\d{1,3}){3})' // IP address exclusion private & local networks
    + '(?!(?:169\\.254|192\\.168)(?:\\.\\d{1,3}){2})'
    + '(?!172\\.(?:1[6-9]|2\\d|3[0-1])(?:\\.\\d{1,3}){2})'
    + '(?:[1-9]\\d?|1\\d\\d|2[01]\\d|22[0-3])' // IP address dotted notation octets
    + '(?:\\.(?:1?\\d{1,2}|2[0-4]\\d|25[0-5])){2}'
    + '(?:\\.(?:[1-9]\\d?|1\\d\\d|2[0-4]\\d|25[0-4]))'
    + '|'
    + '(?:(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)' // host name
    + '(?:\\.(?:[a-z\\u00a1-\\uffff0-9]-*)*[a-z\\u00a1-\\uffff0-9]+)*' // domain name
    + '(?:\\.(?:[a-z\\u00a1-\\uffff]{2,})))' // TLD identifier
    + '(?::\\d{2,5})?' // port number
    + '(?:[/?#]\\S*)?', // resource path
    'ig')
  /* eslint-enable */

  return !!pattern.test(str)
}

export {
  isBoldHotkey,
  isItalicHotkey,
  isUnderlinedHotkey,
  isImage,
  isValidImageFile,
  isUrl,
  isValidUrl
}
