import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { textAlign } from 'styled-system'
import { titlecase } from 'utils/strings'
import DropdownArrow from 'elements/DropdownArrow'
import HeaderLabel from 'elements/HeaderLabel'
import { Menu, MenuItem } from 'elements/Menu'
import LogoImage from 'elements/LogoImage'
import StyledTooltip from 'elements/StyledTooltip'
import Popover from 'components/Popover'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { getGroupLabel } from 'utils/groups'

const Wrapper = styled.div`
  width: 100%;
  padding-left: ${props => props.theme.padding};
  padding-right: ${props => props.theme.padding};
  padding-bottom: ${props => props.theme.padding};
`

const MemberTable = styled.table`
  width: 100%;
`

const ListItem = styled.tr`
  width: 100%;
  font: ${props => props.theme.fonts.normal};
  padding: ${props => props.theme.padding};
  background-color: ${props => (props.selected ? props.theme.highlightBackground : props.theme.containerBackground)};
  transition: all 0.6s cubic-bezier(0.165, 0.84, 0.44, 1);
  border-top: ${props => `solid 1px ${props.theme.borderColor}`};
  letter-spacing: .025em;
`

const TeamImage = styled.div`
  background-image: ${props => `url(${props.url || '/bear_avatar.png'})`};
  background-size: contain;
  background-position: center;
  width: 30px;
  height: 30px;
  border-radius: 9999px;
  margin-right: 1rem;
  margin-top: 5px;
`

const Cell = styled.td`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.normal};
  padding: 0.75rem 0.5rem;

  ${textAlign}
`

const Title = styled.div`
  font-weight: 600;
  max-width: 10rem;
  overflow: hidden;
  text-overflow: ellipsis;
`

const Email = styled.div`
  overflow: hidden;
  text-overflow: ellipsis;
`

const InfoWrapper = styled.span`
  color: ${props => props.theme.labelColor};
`

class MemberList extends PureComponent {
  render () {
    const {
      members,
      invites,
      isOwner,
      isManager,
      onResendInvite,
      onDeleteInvite,
      onUpdateMember,
      onDeleteMember,
      onAssignGroupMember,
      theme,
      session
    } = this.props

    const featureFlags = session.get('feature_flags') || session.get('whitelist')
    const hasAssignedGroup = session.get('group')

    const memberRows = members.map((member, i) => {
      const id = member.get('id')
      const name = member.get('full_name')
      const email = member.get('email')
      const photoUrl = member.get('photo_url')
      const type = titlecase(member.get('type'))
      const active = member.get('active')
      const canRemove = isOwner || (isManager && type === 'User')
      const canAssign = isManager
      const userIsOwner = type === 'Owner'
      const userIsManager = type === 'Manager'
      const crmType = member.get('crm_type')
      const crmName = member.get('crm_name')
      const group = member.get('group')

      return (
        <ListItem key={`member_${id}`} data-index={i}>
          <Cell>
            <table>
              <tr>
                <td>
                  <TeamImage url={photoUrl} />
                </td>
                <td>
                  <Title>{name}</Title>
                  <Email title={email}>{email}</Email>
                </td>
              </tr>
            </table>
          </Cell>
          <Cell>
            {type}
          </Cell>
          <Cell>
            {active &&
              <FontAwesomeIcon
                icon={['fas', 'check']}
                color={theme.colors.blue}
                size='sm'
              />}
          </Cell>
          {featureFlags.includes('show_group_ui') &&
            <Cell>
              {getGroupLabel(group)}
            </Cell>}
          <Cell>
            {crmType &&
              <LogoImage
                type={crmType}
                width={24}
                height={24}
                mt='5px'
                data-tip={`Connected with ${crmName || titlecase(crmType)}`}
              />}
          </Cell>
          {(isOwner || isManager) &&
            <Cell>
              {canAssign &&
                <Popover
                  ref={(element) => { this.popover = element }}
                  minWidth='7rem;'
                  marginTop='1rem;'
                  position='right'
                  content={
                    <Menu>
                      {(isOwner && !userIsManager && !userIsOwner) &&
                        <MenuItem onClick={() => onUpdateMember(member, { role: 'manager' })}>
                          Promote to Manager
                        </MenuItem>}
                      {(isOwner && userIsManager && !userIsOwner) &&
                        <MenuItem onClick={() => onUpdateMember(member, { role: 'user' })}>
                          Demote to User
                        </MenuItem>}
                      {(canAssign && active) &&
                        <MenuItem onClick={() => onUpdateMember(member, { active: false })}>
                          Unassign Seat
                        </MenuItem>}
                      {(canAssign && !active) &&
                        <MenuItem onClick={() => onUpdateMember(member, { active: true })}>
                          Assign Seat
                        </MenuItem>}
                      {
                        featureFlags.includes('show_group_ui') &&
                        !userIsOwner &&
                        !hasAssignedGroup && // group managers can't add or remove from groups
                          <MenuItem onClick={() => onAssignGroupMember(member)}>
                            Assign Group
                          </MenuItem>
                      }
                      {(canRemove && !userIsOwner) &&
                        <MenuItem color={theme.colors.red} onClick={() => onDeleteMember(member)}>
                          Remove User
                        </MenuItem>}
                    </Menu>
              }
                >
                  <DropdownArrow />
                </Popover>}
            </Cell>}
        </ListItem>
      )
    })

    const inviteRows = invites.map((invite, i) => {
      const id = invite.get('id')
      const email = invite.get('email')
      const name = invite.get('full_name') || email
      const photoUrl = invite.get('photo_url')
      const group = invite.get('group')
      const activeInvite = !invite.get('inactive')

      return (
        <ListItem key={`invite_${id}`} data-index={i}>
          <Cell>
            <table>
              <tr>
                <td>
                  <TeamImage url={photoUrl} />
                </td>
                <td>
                  <Title>{name}</Title>
                  <Email title={email}>{email}</Email>
                </td>
              </tr>
            </table>
          </Cell>
          <Cell>Invited</Cell>
          <Cell>
            {activeInvite &&
              <FontAwesomeIcon
                icon={['fas', 'check']}
                color={theme.colors.blue}
                size='sm'
              />}
          </Cell>
          {featureFlags.includes('show_group_ui') &&
            <Cell>
              {group}
            </Cell>}
          <Cell />
          <Cell>
            <Popover
              ref={(element) => { this.popover = element }}
              minWidth='7rem;'
              marginTop='1rem;'
              position='right'
              content={
                <Menu>
                  <MenuItem onClick={() => onResendInvite(invite)}>
                    Resend
                  </MenuItem>
                  <MenuItem onClick={() => onDeleteInvite(invite)}>
                    Remove
                  </MenuItem>
                </Menu>
              }
            >
              <DropdownArrow color={theme.colors.gray70} />
            </Popover>
          </Cell>
        </ListItem>
      )
    })

    return (
      <Wrapper>
        <MemberTable>
          <thead>
            <tr>
              <th>
                <HeaderLabel pl='0.5rem' pr='0.5rem'>User</HeaderLabel>
              </th>
              <th>
                <HeaderLabel pl='0.5rem' pr='0.5rem'>
                  <InfoWrapper data-tip='Managers have access to team-level permissions<br>such as creating sequences as other users, assigning<br>seats, inviting users, and viewing team reports.<br>Owners have access similar to managers but also<br>have access to billing.'>
                    Role&nbsp;
                    <a href='https://help.interseller.io/article/20-inviting-removing-users-and-understanding-team-account-roles' rel='noopener noreferrer' target='_blank'>
                      <FontAwesomeIcon
                        icon={['fas', 'info-circle']}
                        color={theme.labelColor}
                        transform={{
                          size: 14
                        }}
                      />
                    </a>
                  </InfoWrapper>
                </HeaderLabel>
              </th>
              <th>
                <HeaderLabel pl='0.5rem' pr='0.5rem'>
                  <InfoWrapper data-tip='Seats allow users to send emails with<br>sequences and use the Chrome extension'>
                    Seat&nbsp;
                    <FontAwesomeIcon
                      icon={['fas', 'info-circle']}
                      color={theme.labelColor}
                      transform={{
                        size: 14
                      }}
                    />
                  </InfoWrapper>
                </HeaderLabel>
              </th>
              {featureFlags.includes('show_group_ui') &&
                <th>
                  <HeaderLabel pl='0.5rem' pr='0.5rem'>
                    <InfoWrapper data-tip="Users assigned a group are restricted to seeing<br>only their own group's sequences and contacts.">
                      Group&nbsp;
                      <FontAwesomeIcon
                        icon={['fas', 'info-circle']}
                        color={theme.labelColor}
                        transform={{
                          size: 14
                        }}
                      />
                    </InfoWrapper>
                  </HeaderLabel>
                </th>}
              <th>
                <HeaderLabel pl='0.5rem' pr='0.5rem'>CRM</HeaderLabel>
              </th>
              {(isOwner || isManager) &&
                <th>
                  <HeaderLabel pl='0.5rem' pr='0.5rem' />
                </th>}
            </tr>
          </thead>
          <tbody>
            {memberRows}
            {inviteRows}
          </tbody>
        </MemberTable>
        <StyledTooltip effect='solid' multiline />
      </Wrapper>
    )
  }
}

MemberList.propTypes = {
  members: PropTypes.instanceOf(Immutable.List),
  invites: PropTypes.instanceOf(Immutable.List),
  isOwner: PropTypes.bool,
  isManager: PropTypes.bool,
  onResendInvite: PropTypes.func,
  onDeleteInvite: PropTypes.func,
  onUpdateMember: PropTypes.func,
  onDeleteMember: PropTypes.func,
  onAssignGroupMember: PropTypes.func,
  theme: PropTypes.object,
  session: PropTypes.object
}

export default withTheme(MemberList)
