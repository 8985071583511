import { MSFT_LIST_NODES } from '../constants'

function isWordListNode (node) {
  if (!node) {
    return false
  }

  return node.className && Object.values(MSFT_LIST_NODES).includes(node.className.toLowerCase())
}

function extractMsftListType (node) {
  if (!node) {
    return null
  }

  const nodeCssText = !!node.firstElementChild &&
    // TODO: idk why these checks prevent this condition from passing but i think its fine to ignore these
    // && !!node.firstElementChild.tagName
    // && !!node.firstElementChild.tagName.toLowerCase() === NODES.SPAN
    node.firstElementChild.style &&
    node.firstElementChild.style.cssText
    ? node.firstElementChild.style.cssText.toLowerCase()
    : null

  if (nodeCssText && nodeCssText.includes('symbol')) {
    return 'ul'
  }

  return 'ol'
}

export {
  isWordListNode,
  extractMsftListType
}
