import { PLATFORMS, NODES } from '../constants'

function extractInlineCSS (node, _tag = null) {
  if (!node || !node.style) {
    return ''
  }

  let openTags = ''
  let closeTags = ''

  const fontStyle = node.style.fontStyle
  const fontWeight = node.style.fontWeight
  const textDecoration = node.style.textDecoration
  const marginBottom = node.style.marginBottom

  if (fontWeight === 'bold' ||
  fontWeight === '600' ||
  fontWeight === '700' ||
  fontWeight === '800' ||
  fontWeight === '900') {
    openTags += '<b>'
    closeTags = '</b>' + closeTags
  }
  if (fontStyle === 'italic') {
    openTags += '<em>'
    closeTags = '</em>' + closeTags
  }
  if (textDecoration === 'underline') {
    openTags += '<u>'
    closeTags = '</u>' + closeTags
  }

  return { openTags, closeTags, marginBottom }
}

function extractNodeMetadata (node) {
  if (!node) {
    console.warn(`\`node\` is ${node} in \`extractNodeMetadata\``)
    return { }
  }

  const id = node.id ? node.id : null
  const tag = node.tagName ? node.tagName.toLowerCase() : null
  const type = node.nodeType ? node.nodeType : null
  const data = node.data ? node.data : null
  const className = node.className ? node.className.toLowerCase() : null
  const childNodes = node.childNodes ? node.childNodes : null
  const firstChild = node.firstChild ? node.firstChild : null
  const lastChild = node.lastChild ? node.lastChild : null
  const parentNode = node.parentNode ? node.parentNode : null
  const nextSibling = node.nextElementSibling ? node.nextElementSibling : null

  return {
    id,
    tag,
    type,
    data,
    className,
    childNodes,
    firstChild,
    lastChild,
    parentNode,
    nextSibling
  }
}

function isValidListNode (node) {
  if (!node) {
    console.warn(`\`node\` is ${node} in \`isValidListNode\``)
    return false
  }

  const LISTS = [NODES.UNORDERED_LIST, NODES.ORDERED_LIST]
  const { tag } = extractNodeMetadata(node)
  return LISTS.includes(tag)
}

function isValidInlineNode (node) {
  if (!node) {
    console.warn(`\`node\` is ${node} in \`isValidInlineNode\``)
    return false
  }

  // *NOTE: break tags are converted to p tags, so they are not seen as valid inlines
  const INLINES = [
    NODES.SPAN,
    NODES.ITALICS,
    NODES.EMPHASIS,
    NODES.STRONG,
    NODES.BOLD,
    NODES.UNDERLINE,
    NODES.ANCHOR
  ]

  const { tag } = extractNodeMetadata(node)
  return INLINES.includes(tag)
}

function isValidTextNode (node) {
  if (!node) {
    console.warn(`\`node\` is ${node} in \`isTextNode\``)
    return false
  }

  return !!node.data && node.nodeType === window.Node.TEXT_NODE
}

function isCommentNode (node) {
  if (!node) {
    console.warn(`\`node\` is ${node} in \`isCommentNode\``)
    return false
  }

  return !!node.nodeName && node.nodeName === '#comment'
}

function preSanitizeHTML (html, currentPlatform = PLATFORMS.NONE) {
  let parsedHtml = html

  switch (currentPlatform) {
    case PLATFORMS.WORD: {
      // remove all Microsoft Office HTML formatting
      const startRegex = /<!--StartFragment-->/ig
      const endRegex = /<!--EndFragment-->/ig
      const startIdx = html.search(startRegex)
      const endIdx = html.search(endRegex)
      parsedHtml = html.substring(startIdx, endIdx)

      parsedHtml = parsedHtml.replace(startRegex, '')
      parsedHtml = parsedHtml.replace(/\r?\n|\r/g, ' ')
      parsedHtml = parsedHtml.replace(/(?: ?)class=(?:"?)MsoNormal(?:"?)( >)/ig, '$1')
      parsedHtml = parsedHtml.replace(/<\/?o:p>/ig, '')
      parsedHtml = parsedHtml.replace(/&nbsp;/ig, '')
      parsedHtml = parsedHtml.replace(/<i>/ig, '<em>')
      /* eslint-disable */
      parsedHtml = parsedHtml.replace(/<\/i>/ig, '<\/em>')
      /* eslint-enable */
      break
    }
  }

  return parsedHtml
}

function postSanitizeHTML (html, _currentPlatform = PLATFORMS.NONE) {
  // PLATFORM SPECIFIC CHANGES
  // switch (currentPlatforms) { }

  /* GLOBAL HTML CHANGES
   * This is a specific type of list condition that's
   * custom to email. By default, browser HTML does not
   * have padding on lists whereas email HTML does.
   */
  html = html.replace(/<p><\/p>(<(?:ul|li)>)/ig, '$1')
  html = html.replace(/(<\/(?:ul|li)>)<p><\/p>/ig, '$1')

  // Remove newlines before lists
  html = html.replace(/(<p>&#65279;<\/p>){1,}(<[u|o]l>)/ig, '$2')
  html = html.replace(/(<p><\/p>){1,}(<[u|o]l>)/ig, '$2')

  /*
   * Take excessive new lines (> 2) and replace them with
   * one. The markdown compiler will do this automatically
   */
  html = html.replace(/(\s?<p><\/p>\s?){2,}/ig, '<p></p>')

  return html
}

export {
  extractInlineCSS,
  extractNodeMetadata,
  isValidListNode,
  isValidInlineNode,
  isValidTextNode,
  isCommentNode,
  preSanitizeHTML,
  postSanitizeHTML
}
