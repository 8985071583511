/**
*
* SequenceStats Components
*
*/

import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import MessageBox from './MessageBox'
import NumberBox from './NumberBox'
import { Tooltip } from 'react-tippy'
import IconButton from 'components/IconButton'

const ConfigButtonContainer = styled.div`
  float: right;
  margin-bottom: 0.5rem;
`

const Wrapper = styled.div`
  display: inline-flex;
  width: 100%;
`

const ContainerBox = styled.div`
  flex: 1;
  margin-right: 0.5rem;
  &:last-child {
    margin-right: 0;
  }
  display: flex;
  align-items: center;
  padding: 1rem;
  height: 120px;
  background: ${props => props.theme.containerBackground};
  border: ${props => '1px solid'};
  border-color: ${props => props.highlight ? props.theme.colors.blue : props.theme.borderColor};
  transition: all .2s ease;
  cursor: pointer;
  &:hover,
  &:focus {
    box-shadow: 1px 1px 5px ${props => props.theme.colors.gray60};
  }
`

class SequenceStats extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      reports,
      messageCounts,
      router,
      onShowSchedule,
      theme,
      onShowConfigModal,
      config,
      numberTiles
    } = this.props

    return (
      <>
        <ConfigButtonContainer>
          <Tooltip
            style={{ display: 'block' }}
            title='Configure Tiles'
            position='left'
            trigger='mouseenter'
            arrow
          >
            <IconButton
              type={['fal', 'cog']}
              buttonColor='transparent'
              borderColor={theme.colors.gray60}
              iconColor={theme.colors.gray70}
              size={30}
              translateY={-1}
              onClick={onShowConfigModal}
            />
          </Tooltip>
        </ConfigButtonContainer>
        <Wrapper>
          {config.messageQueue &&
            <ContainerBox>
              <MessageBox
                reports={reports}
                messageCounts={messageCounts}
                onClick={() => {
                  onShowSchedule()
                }}
              />
            </ContainerBox>}
          {
            numberTiles.map((tile) => {
              if (tile.hidden || !config[tile.name]) {
                return null
              }

              return (
                <ContainerBox key={tile.label} highlight={tile.highlight}>
                  <NumberBox
                    title={tile.label}
                    loading={tile.loading}
                    value={tile.value}
                    tooltip={tile.tooltip}
                    highlight={tile.highlight}
                    onClick={() => {
                      router.push(tile.onClickString)
                    }}
                  />
                </ContainerBox>
              )
            })
          }
        </Wrapper>
      </>
    )
  }
}

SequenceStats.propTypes = {
  router: PropTypes.object,
  reports: PropTypes.object,
  messageCounts: PropTypes.object,
  onShowSchedule: PropTypes.func,
  onShowConfigModal: PropTypes.func,
  config: PropTypes.object,
  numberTiles: PropTypes.array,
  theme: PropTypes.object
}

export default withTheme(SequenceStats)
