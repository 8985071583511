//
// Class: URLSearchParams
//
// Documentation:
//   This class is built around the same specifications found on MDN's documentation
//       https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams
//
//   This class is a proper implementation of the `URLSearchParams` object that is found
//   inside the Javascript standard library. The reason for creating this custom made
//   class has to do with subtle encoding differences when parsing arrays.
//
//   The original implementation encodes arrays with the following structure:
//       arg: arr = [1, 2, 3]
//       url: https://example.com?arr=1%2C2%2C3
//
//   This custom file encodes arrays as:
//       arg: arr = [1, 2, 3]
//       url: https://example.com?arr[]=1&arr[]=2&arr[]=3
//
//   The custom encoding used in this class is the way servers anticipate url search
//   params to be formatted.
//
//   This class also comes with 3 methods that serve as quality of life updates:
//     join(obj: Object)
//        - Append an object's key-value pairs to the url serach params.
//        - NOTE: that if a key inside the object is already a url search
//          param key, this method will not override the already added url
//          search param key-value pair
//
//     joinWithOverride(obj: Object)
//        - Append an object's key-value pairs to the url search params.
//        - NOTE: this method *will* override any key-value pairs that
//          share the same key
//
//     toJSON()
//        - Return all the url search params as a JSON object.
//

import moment from 'moment'

export default class URLSearchParams {
  constructor (init) {
    this._params = {}

    if (init) {
      if (init instanceof URL) {
        this._parseURLQueryParams(init.search)
      } else if (this._isString(init)) {
        this._parseRawStringQueryParams(init)
      } else if (this._isObject(init)) {
        this.join(false, init)
      }
    }
  }

  append (key, value) {
    value = this._normalizeValues(value)

    if (!this._isValidKey(key) || !this._isValidValue(value)) {
      return
    }

    if (!this.has(key)) {
      this.set(key, value)
    } else if (Array.isArray(this._params[key])) {
      this._params[key].push(value)
    } else {
      const oldValue = this._params[key]
      this._params[key] = [oldValue, value]
    }
  }

  delete (key) {
    if (!this.has(key)) {
      return
    }

    delete this._params[key]
  }

  * entries () {
    const result = []

    const entries = [...Object.entries(this._params)]
    for (const [key, val] of entries) {
      if (Array.isArray(val)) {
        const subvalues = Array.from(val)
        for (const subval of subvalues) {
          result.push([key, subval])
        }
      } else {
        result.push([key, val])
      }
    }

    for (const [key, val] of result) {
      yield [key, val]
    }
  }

  * forEach (callback) {
    for (const [key, value] of this.entries()) {
      // - The `forEach`` method inside the MDN spec passes the `value` and then
      //   the `key`, even though this is backwards to what you'd expect
      // - https://developer.mozilla.org/en-US/docs/Web/API/URLSearchParams/forEach
      yield callback(value, key)
    }
  }

  get (key) {
    if (!this.has(key)) {
      return null
    }

    const values = this._params[key]
    if (values instanceof Set) {
      const result = Array.from(values)
      if (result.length === 1) {
        return result[0]
      } else {
        return result
      }
    }

    return this._params[key]
  }

  getAll (key) {
    if (!this.has(key)) {
      return []
    } else if (Array.isArray(this._params[key])) {
      return this._params[key]
    } else if (this._params[key] instanceof Set) {
      return Array.from(this._params[key])
    }

    return [this._params[key]]
  }

  has (key) {
    if (!key) {
      return false
    }

    return Object.prototype.hasOwnProperty.call(this._params, key)
  }

  join (override, object) {
    if (!this._isObject(object) || Object.keys(object).length === 0) {
      return
    }

    for (let [key, value] of Object.entries(object)) {
      // remove white space from key
      key = key.replace(/\s/g, '')

      if (!this._isValidKey(key) || !this._isValidValue(value)) {
        continue
      }

      if (!override) {
        this.append(key, value)
      } else {
        this.set(key, value)
      }
    }
  }

  * keys () {
    for (const k of Object.keys(this._params)) {
      yield k
    }
  }

  set (key, value) {
    value = this._normalizeValues(value)

    if (!this._isValidKey(key) || !this._isValidValue(value)) {
      return
    }

    // remove all white space from `key` string
    key = key.replace(/\s/g, '')
    if (this._isObject(value)) {
      this.join(value)
    } else {
      this._params[key] = value
    }
  }

  sort () {
    this._params = Object
      .keys(this._params)
      .sort()
      .reduce((acc, key) => {
        acc[key] = this._params[key]
        return acc
      }, {})
  }

  toJSON () {
    return JSON.stringify(this._params)
  }

  toString () {
    const result = []
    for (const [key, value] of Object.entries(this._params)) {
      if (Array.isArray(value)) {
        this.getAll(key).forEach(v => result.push(`${key}[]=${encodeURIComponent(v)}`))
      } else {
        result.push(`${key}=${encodeURIComponent(value)}`)
      }
    }

    return result.join('&')
  }

  * values () {
    const result = []

    const values = [...Object.values(this._params)]
    for (const val of values) {
      if (Array.isArray(val)) {
        val.forEach(v => result.push(v))
      } else {
        result.push(val)
      }
    }

    for (const item of result) {
      yield item
    }
  }

  _isDate (obj) {
    return moment.isDate(obj) || moment.isMoment(obj)
  }

  _isNumber (num) {
    return (typeof num === 'number' || num instanceof Number) && !Number.isNaN(num)
  }

  _isObject (obj) {
    // need to use `Object.toString` to check if a variable is an `Object`
    // because it's the only consistent method that can validate that all
    // other Javascript primitives are not of type `Object`
    return Object.prototype.toString.call(obj) === '[object Object]'
  }

  _isString (str) {
    // need to use both `typeof` and `instanceof` operators to catch weird
    // JS edge cases. the edge case is that `object wrapped strings` are not
    // technically instances of `String`, therefore we also need to use `typeof`
    return (typeof str === 'string') || (str instanceof String)
  }

  _isFilteredDataType (item) {
    // each one of the data types checked in this function must be filtered out of
    // query paramteres entirely - whether the fields make up a key or value
    const falseyValues = new Set([null, undefined, '', 'undefined', 'null', 'NaN'])
    if (falseyValues.has(item)) {
      return true
    }

    if (Array.isArray(item) && item.length === 0) {
      return true
    }

    if (Number.isNaN(item)) {
      return true
    }

    return false
  }

  _isValidKey (key) {
    if (this._isFilteredDataType(key)) {
      return false
    }

    if (!this._isString(key) && !this._isNumber(key)) {
      return false
    }

    return true
  }

  _isValidValue (value) {
    if (this._isFilteredDataType(value)) {
      return false
    }

    if (this._isObject(value) && !this._isDate(value)) {
      return false
    }

    if (
      !this._isDate(value) &&
      !this._isNumber(value) &&
      !this._isString(value) &&
      !Array.isArray(value) &&
      value !== false &&
      value !== true
    ) {
      return false
    }

    return true
  }

  _normalizeValues (value) {
    const normalizeDate = (value) => moment(value).format('YYYY-MM-DDTHH:mm:ss.sss[Z]')

    // normalize dates
    if (this._isDate(value)) {
      return normalizeDate(value)
    }

    // normalize arrays
    if (Array.isArray(value)) {
      return value
        .map((item) => {
          if (this._isDate(item)) {
            return normalizeDate(item)
          } else if (Array.isArray(item)) {
            // recursively remove invalid sub-array values
            return this._normalizeValues(item)
          }
          return item
        })
        .filter((item) => this._isValidValue(item))
    }

    return value
  }

  _parseRawStringQueryParams (queryParamStr) {
    // using regex to strip query params from string
    // visual regex flowchart - https://www.debuggex.com/r/a0vbj5aUJ7r2T83t

    /* eslint-disable */
    const queryStringRegex = /(&)?([a-z|A-Z|0-9|\\\-_\.!~%\*'\(\)])+(\[\])?=([a-z|A-Z|0-9|\\\-_\.!%~\*'\(\)])+/ig
    /* eslint-enable */

    const params = queryParamStr.match(queryStringRegex).join('')
    this._parseURLQueryParams(params)
  }

  _parseURLQueryParams (queryParams) {
    if (queryParams.charAt(0) === '?') {
      // removes ? from beginning of string
      queryParams = queryParams.substring(1)
    }

    const params = queryParams.split('&')
    if (params.length === 0) {
      return
    }

    for (const p of params) {
      let [key, val] = p.split('=')

      if (!this._isValidKey(key) || !this._isValidValue(val)) {
        continue
      }

      // if key belongs to an array, strip brackets from key
      if (key.length >= 3 && key.substring(key.length - 2) === '[]') {
        key = key.substring(0, key.length - 2)
      }

      this.append(key, val)
    }
  }
}
