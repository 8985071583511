import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width } from 'styled-system'

import Title from 'elements/Title'
import Label from 'elements/Label'
import Button from 'components/Button'
import Input from 'components/Input'

import { Wordmark } from 'svg'
import { fadeIn } from 'utils/animations'

const LogoContainer = styled.div`
  padding-bottom: 5rem;
`

const Wrapper = styled.div`
  width: 342px;
  padding-bottom: 5rem;

  animation: ${fadeIn} .15s linear 1;
`

const ButtonContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1rem;
`

const Terms = styled.p`
  margin-top: auto;
  margin-bottom: auto;
  color: ${props => props.theme.colors.gray60};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 2rem;

  ${space}
  ${width}
`

class EmailPrompt extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  UNSAFE_componentWillMount () {
    const {
      signup
    } = this.props
    document.title = signup ? 'Interseller | Signup' : 'Interseller | Login'

    this.props.actions.fetchEmailDiscoveryReset()

    const {
      email
    } = this.props.location.query
    if (email) {
      this.setState({
        email
      })
      this.checkEmail(email)
    }
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      discovery
    } = newProps
    const {
      router,
      signup
    } = this.props
    const {
      email
    } = this.state

    const loading = discovery.get('loading')
    if (loading) {
      return
    }

    const loginType = discovery.getIn(['data', 'type'])
    const path = signup ? 'signup' : 'login'

    if (loginType) {
      router.push(`${path}/${loginType}`)
    } else if (email) {
      router.push(`${path}/unknown`)
    }
  }

  handleKeyDown = (e) => {
    if (!this.state.email) {
      return
    }

    if (e.key === 'Enter') {
      this.checkEmail(this.state.email)
    }
  }

  checkEmail = (email) => {
    this.props.actions.fetchEmailDiscovery({ email })
  }

  render () {
    const {
      signup,
      discovery,
      router
    } = this.props
    const {
      email
    } = this.state

    const title = signup
      ? 'Get Started for Free'
      : 'Login to Interseller'
    const label = signup
      ? 'Enter your email to get started!'
      : 'Enter your email to login'

    const loading = discovery.get('loading')

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <LogoContainer>
          <Wordmark />
        </LogoContainer>
        <Title>{title}</Title>
        <Label mt='1rem' mb='1rem'>{label}</Label>
        <Input
          inputId='email'
          type='email'
          placeholder='email address'
          width='100%'
          disabled={loading}
          value={email || ''}
          onValueChange={(value) => {
            this.setState({
              email: value
            })
          }}
        />
        <ButtonContainer>
          <Button
            innerRef={(button) => { this.button = button }}
            primary
            full
            bg={this.props.theme.primaryColor}
            disabled={loading}
            label='Submit'
            handleClick={() => {
              this.checkEmail(email)
            }}
          />
        </ButtonContainer>
        <Terms>
          By logging in and using our service you agree to our <a href='https://www.interseller.io/terms' target='_blank' rel='noopener noreferrer'>Terms of Service</a> and have read our <a href='https://www.interseller.io/privacy' target='_blank' rel='noopener noreferrer'>Privacy Policy</a>
        </Terms>
        {signup &&
          <Terms pt='1rem'>
            Have an account already? <a href='#' onClick={() => { router.push('/login') }}>Login here</a>
          </Terms>}
      </Wrapper>
    )
  }
}

EmailPrompt.propTypes = {
  actions: PropTypes.object,
  theme: PropTypes.object,
  discovery: PropTypes.object,
  signup: PropTypes.bool,
  router: PropTypes.object,
  location: PropTypes.object
}

export default EmailPrompt
