import { fromJS } from 'immutable'
import {
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,

  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  FETCH_MESSAGES_RESET,

  UPDATE_CONTACT_MESSAGE_SUCCESS,

  FETCH_CONTACT_ERRORS_REQUEST,
  FETCH_CONTACT_ERRORS_SUCCESS,
  FETCH_CONTACT_ERRORS_FAILURE,

  FETCH_CONTACT_ACTIONS_REQUEST,
  FETCH_CONTACT_ACTIONS_SUCCESS,
  FETCH_CONTACT_ACTIONS_FAILURE,
  FETCH_CONTACT_ACTIONS_RESET,

  FETCH_CONTACT_ERRORS_STATS_REQUEST,
  FETCH_CONTACT_ERRORS_STATS_SUCCESS,
  FETCH_CONTACT_ERRORS_STATS_FAILURE,

  FETCH_REPLIED_CONTACT_REQUEST,
  FETCH_REPLIED_CONTACT_SUCCESS,
  FETCH_REPLIED_CONTACT_FAILURE,

  FETCH_SENTIMENT_SCORE_REQUEST,
  FETCH_SENTIMENT_SCORE_SUCCESS,
  FETCH_SENTIMENT_SCORE_FAILURE,

  UPDATE_CONTACT_SENTIMENT_REQUEST,

  REMOVE_CONTACT_ERRORS_SUCCESS,

  REMOVE_CONTACT_SUCCESS,
  UPDATE_CONTACT_SUCCESS,

  FETCH_MANUAL_TASKS_REQUEST,
  FETCH_MANUAL_TASKS_SUCCESS,
  FETCH_MANUAL_TASKS_FAILURE,

  FETCH_CONTACT_TASK_REQUEST,
  FETCH_CONTACT_TASK_SUCCESS,
  FETCH_CONTACT_TASK_FAILURE,
  FETCH_CONTACT_TASK_RESET,

  UPDATE_CONTACT_TASK_SUCCESS,

  SET_PAUSE_CONTACT_TASK,
  CLEAR_PAUSE_CONTACT_TASK,

  SKIP_MANUAL_TASK_SUCCESS,

  SKIP_MANUAL_TASKS_SUCCESS
} from './constants'

const initialArray = {
  loading: false,
  data: [],
  error: null
}

const initialData = {
  loading: false,
  data: {},
  error: null
}

const initialContacts = {
  loading: false,
  data: [],
  error: null,
  hasMore: false,
  filter: null
}

const initialState = fromJS({
  tasks: initialArray,
  messages: initialArray,
  contacts: initialContacts,
  contactActivity: initialArray,
  repliedContact: initialData,
  contactErrors: initialContacts,
  contactErrorStats: initialData,
  sentimentScore: initialData,
  manualTasks: initialArray,
  contactTask: initialData,
  pauseContactTask: null
})

const removeContactFromTaskList = (state, contactId) => {
  const data = state.getIn(['contactErrors', 'data']).filter(t => (t.get('_id') !== contactId))
  const taskData = state
    .getIn(['tasks', 'data'])
    .filter(t => (t.get('id') !== contactId))
  const manualTaskData = state
    .getIn(['manualTasks', 'data'])
    .filter(t => (t.get('id') !== contactId))
  return state
    .set('contactErrors', state.get('contactErrors').merge({ data }))
    .set('tasks', state.get('tasks').merge({ data: taskData }))
    .set('manualTasks', state.get('manualTasks').merge({ data: manualTaskData }))
}

function taskReducer (state = initialState, action) {
  switch (action.type) {
    // Member List
    case FETCH_TASKS_REQUEST:
      return state.set('tasks', state.get('tasks').merge({ loading: true, data: [] }))
    case FETCH_TASKS_SUCCESS:
      return state.set('tasks', fromJS({
        data: action.payload.tasks,
        loading: false,
        error: null
      }))
    case FETCH_TASKS_FAILURE:
      return state.set('tasks', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_CONTACT_ERRORS_REQUEST: {
      const loading = true
      let filter
      if (action.query && action.query.skip > 0) {
        filter = action.query.filter
      }
      let data = state.getIn(['contactErrors', 'data'])
      if (action.query && action.query.sort && action.query.skip === 0) {
        data = fromJS([])
      }
      return state.set('contactErrors', state.get('contactErrors').merge({ loading, filter, data }))
    }
    case FETCH_CONTACT_ERRORS_SUCCESS: {
      let contacts = action.payload.contacts
      const existingContacts = state.getIn(['contactErrors', 'data'])
      if (action.payload.paginating && existingContacts) {
        contacts = existingContacts.concat(contacts)
      }
      return state.set('contactErrors', fromJS({
        data: contacts,
        loading: false,
        error: null,
        hasMore: action.payload.hasMore
      }))
    }
    case FETCH_CONTACT_ERRORS_FAILURE:
      return state.set('contactErrors', state.get('contactErrors').merge({ loading: false, error: action.err }))

    case REMOVE_CONTACT_ERRORS_SUCCESS: {
      const contactIds = action.contactIds
      const data = state.get('contactErrors').get('data').filter((contact) => {
        const contactId = contact.get('_id')
        return !contactIds.includes(contactId)
      })
      return state.set('contactErrors', state.get('contactErrors').merge({ data }))
    }

    case REMOVE_CONTACT_SUCCESS: {
      const {
        contactId
      } = action
      return removeContactFromTaskList(state, contactId)
    }

    case UPDATE_CONTACT_SUCCESS: {
      const {
        contact
      } = action.payload
      return removeContactFromTaskList(state, contact.get('_id')) // if paused from sequence need this to keep tasklist fresh
    }

    // Contact Stats
    case FETCH_CONTACT_ERRORS_STATS_REQUEST:
      return state.set('contactErrorStats', state.get('contactErrorStats').merge({ loading: true }))
    case FETCH_CONTACT_ERRORS_STATS_SUCCESS:
      return state.set('contactErrorStats', fromJS({
        data: action.payload.stats,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_ERRORS_STATS_FAILURE:
      return state.set('contactErrorStats', fromJS(initialData))

    // Contact Messages
    case FETCH_MESSAGES_REQUEST:
      return state.set('messages', state.get('messages').merge({ loading: true }))
    case FETCH_MESSAGES_SUCCESS:
      return state.set('messages', fromJS({
        data: action.payload.messages,
        loading: false,
        error: null
      }))
    case FETCH_MESSAGES_FAILURE:
      return state.set('messages', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))
    case FETCH_MESSAGES_RESET:
      return state.set('messages', fromJS({
        data: [],
        loading: false,
        error: null
      }))

    case UPDATE_CONTACT_MESSAGE_SUCCESS: {
      const {
        contactId
      } = action.payload
      // If we've updated a message then we remove the task from the list
      const data = state.getIn(['tasks', 'data']).filter(t => (t.get('id') !== contactId))
      return state.setIn(['tasks', 'data'], data)
    }

    // Contact Activity
    case FETCH_CONTACT_ACTIONS_REQUEST:
      return state.set('contactActivity', state.get('contactActivity').merge({
        data: [],
        loading: true
      }))
    case FETCH_CONTACT_ACTIONS_SUCCESS:
      return state.set('contactActivity', fromJS({
        data: action.payload.actions,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_ACTIONS_FAILURE:
      return state.set('contactActivity', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))
    case FETCH_CONTACT_ACTIONS_RESET:
      return state.set('contactActivity', fromJS({
        data: [],
        loading: false,
        error: null
      }))

    // case UPDATE_REPLIED_CONTACT_REQUEST:
    case UPDATE_CONTACT_SENTIMENT_REQUEST:
    case FETCH_REPLIED_CONTACT_REQUEST: {
      const loading = true
      const error = null
      const data = {}
      return state.set('repliedContact', state.get('repliedContact').merge({
        loading,
        error,
        data
      }))
    }

    case FETCH_REPLIED_CONTACT_SUCCESS: {
      const contacts = action.payload.contacts
      return state.set('repliedContact', fromJS({
        data: contacts,
        loading: false,
        error: null
      }))
    }
    case FETCH_REPLIED_CONTACT_FAILURE:
      return state.set('repliedContact', state.get('repliedContact').merge({
        loading: false,
        error: action.err
      }))

    case FETCH_SENTIMENT_SCORE_REQUEST: {
      return state.set('sentimentScore', state.get('sentimentScore').merge({ loading: true, data: {} }))
    }
    case FETCH_SENTIMENT_SCORE_SUCCESS: {
      const sentimentScore = action.payload.sentimentScore
      return state.set('sentimentScore', fromJS({
        data: sentimentScore,
        loading: false,
        error: null
      }))
    }
    case FETCH_SENTIMENT_SCORE_FAILURE: {
      return state.set('sentimentScore', state.get('sentimentScore').merge({
        loading: false,
        error: action.err
      }))
    }

    // Manual Tasks
    case FETCH_MANUAL_TASKS_REQUEST:
      return state.set('manualTasks', state.get('manualTasks').merge({ loading: true, data: [] }))
    case FETCH_MANUAL_TASKS_SUCCESS:
      return state.set('manualTasks', fromJS({
        data: action.payload.manualTasks,
        loading: false,
        error: null
      }))
    case FETCH_MANUAL_TASKS_FAILURE:
      return state.set('manualTasks', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_CONTACT_TASK_REQUEST:
      return state.set('contactTask', state.get('contactTask').merge({ loading: true, data: {} }))
    case FETCH_CONTACT_TASK_SUCCESS:
      return state.set('contactTask', fromJS({
        data: action.payload.contactTask,
        loading: false,
        error: null
      }))
    case FETCH_CONTACT_TASK_FAILURE:
      return state.set('contactTask', fromJS({
        data: {},
        loading: false,
        error: action.err
      }))
    case FETCH_CONTACT_TASK_RESET:
      return state.set('contactTask', state.get('contactTask').merge({ data: {} }))

    case UPDATE_CONTACT_TASK_SUCCESS: {
      const {
        contactId,
        contactTask
      } = action.payload

      if (!contactTask.get('completed_at')) {
        return state
      }

      // If we've updated a message then we remove the task from the list
      const data = state
        .getIn(['manualTasks', 'data'])
        .filter(t => (t.get('id') !== contactId))

      return state.setIn(['manualTasks', 'data'], data)
    }

    case SET_PAUSE_CONTACT_TASK: {
      const task = action.payload
      return state.set('pauseContactTask', task)
    }
    case CLEAR_PAUSE_CONTACT_TASK:
      return state.set('pauseContactTask', null)

    case SKIP_MANUAL_TASK_SUCCESS: {
      const {
        contactId
      } = action.payload
      const data = state.getIn(['manualTasks', 'data']).filter(t => (t.get('id') !== contactId))
      return state.setIn(['manualTasks', 'data'], data)
    }

    case SKIP_MANUAL_TASKS_SUCCESS: {
      const {
        contactIds
      } = action.payload
      const data = state.getIn(['manualTasks', 'data']).filter(task => (!contactIds.includes(task.get('id'))))
      return state.setIn(['manualTasks', 'data'], data)
    }

    default:
      return state
  }
}

export default taskReducer
