import {
  // Fetch Team Reports
  FETCH_TEAM_REPORTS_REQUEST,
  FETCH_TEAM_REPORTS_SUCCESS,
  FETCH_TEAM_REPORTS_FAILURE,

  FETCH_TEAM_REPORTS_CONTRAST_PERIOD_REQUEST,
  FETCH_TEAM_REPORTS_CONTRAST_PERIOD_SUCCESS,
  FETCH_TEAM_REPORTS_CONTRAST_PERIOD_FAILURE,

  // Fetch User Reports
  FETCH_USER_REPORTS_REQUEST,
  FETCH_USER_REPORTS_SUCCESS,
  FETCH_USER_REPORTS_FAILURE,

  // Fetch Sequence Reports
  FETCH_SEQUENCE_REPORTS_REQUEST,
  FETCH_SEQUENCE_REPORTS_SUCCESS,
  FETCH_SEQUENCE_REPORTS_FAILURE,

  // Fetch My Reports
  FETCH_MY_REPORTS_REQUEST,
  FETCH_MY_REPORTS_SUCCESS,
  FETCH_MY_REPORTS_FAILURE,

  // Fetch Booked Meetting Reports
  FETCH_BOOKED_REPORTS_REQUEST,
  FETCH_BOOKED_REPORTS_SUCCESS,
  FETCH_BOOKED_REPORTS_FAILURE,

  // Fetch Replied Reports
  FETCH_REPLIED_REPORTS_REQUEST,
  FETCH_REPLIED_REPORTS_SUCCESS,
  FETCH_REPLIED_REPORTS_FAILURE,

  // Fetch Contacts Added Report
  FETCH_CONTACTS_REPORTS_REQUEST,
  FETCH_CONTACTS_REPORTS_SUCCESS,
  FETCH_CONTACTS_REPORTS_FAILURE,

  // Fetch Messages Sent Report
  FETCH_MESSAGED_REPORTS_REQUEST,
  FETCH_MESSAGED_REPORTS_SUCCESS,
  FETCH_MESSAGED_REPORTS_FAILURE,

  EXPORT_REPORT_CSV_REQUEST,
  EXPORT_REPORT_CSV_SUCCESS,
  EXPORT_REPORT_CSV_FAILURE,

  FETCH_BOUNCE_RATE_REPORT_REQUEST,
  FETCH_BOUNCE_RATE_REPORT_SUCCESS,
  FETCH_BOUNCE_RATE_REPORT_FAILURE,

  FETCH_OPEN_HOURS_REQUEST,
  FETCH_OPEN_HOURS_SUCCESS,
  FETCH_OPEN_HOURS_FAILURE,

  FETCH_REPLY_HOURS_REQUEST,
  FETCH_REPLY_HOURS_SUCCESS,
  FETCH_REPLY_HOURS_FAILURE,

  FETCH_CONTACT_TASK_REPORTS_REQUEST,
  FETCH_CONTACT_TASK_REPORTS_SUCCESS,
  FETCH_CONTACT_TASK_REPORTS_FAILURE,

  MESSAGE_REPORTS,
  CONTACT_REPORTS,

  FETCH_LEADERBOARD_REQUEST,
  FETCH_LEADERBOARD_SUCCESS,
  FETCH_LEADERBOARD_FAILURE,

  FETCH_LEADERBOARD_CONTRAST_REQUEST,
  FETCH_LEADERBOARD_CONTRAST_SUCCESS,
  FETCH_LEADERBOARD_CONTRAST_FAILURE,

  FETCH_LEADERBOARD_GROUPS_REQUEST,
  FETCH_LEADERBOARD_GROUPS_SUCCESS,
  FETCH_LEADERBOARD_GROUPS_FAILURE,

  FETCH_LEADERBOARD_GROUPS_CONTRAST_REQUEST,
  FETCH_LEADERBOARD_GROUPS_CONTRAST_SUCCESS,
  FETCH_LEADERBOARD_GROUPS_CONTRAST_FAILURE,

  FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST,
  FETCH_PERSONALIZED_MESSAGES_REPORTS_SUCCESS,
  FETCH_PERSONALIZED_MESSAGES_REPORTS_FAILURE,

  PERSONALIZED_MESSAGE_REPORTS,
  PERSONALIZED_CONTACT_REPORTS,

  FETCH_STEP_PERFORMANCE_REQUEST,
  FETCH_STEP_PERFORMANCE_SUCCESS,
  FETCH_STEP_PERFORMANCE_FAILURE,

  FETCH_SEQUENCES_REPORTS_REQUEST,
  FETCH_SEQUENCES_REPORTS_SUCCESS,
  FETCH_SEQUENCES_REPORTS_FAILURE,

  FETCH_BOUNCE_REASON_REQUEST,
  FETCH_BOUNCE_REASON_SUCCESS,
  FETCH_BOUNCE_REASON_FAILURE
} from './constants'

export function fetchTeamReports (start, end) {
  return {
    type: FETCH_TEAM_REPORTS_REQUEST,
    start,
    end
  }
}

export function fetchTeamReportsSuccess (reports) {
  return {
    type: FETCH_TEAM_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchTeamReportsFailure (err) {
  return {
    type: FETCH_TEAM_REPORTS_FAILURE,
    err
  }
}

export function fetchLeaderboard (start, end) {
  return {
    type: FETCH_LEADERBOARD_REQUEST,
    start,
    end
  }
}

export function fetchLeaderboardSuccess (reports) {
  return {
    type: FETCH_LEADERBOARD_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchLeaderboardFailure (err) {
  return {
    type: FETCH_LEADERBOARD_FAILURE,
    err
  }
}

export function fetchLeaderboardContrast (start, end, version) {
  return {
    type: FETCH_LEADERBOARD_CONTRAST_REQUEST,
    start,
    end,
    version
  }
}

export function fetchLeaderboardContrastSuccess (reports) {
  return {
    type: FETCH_LEADERBOARD_CONTRAST_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchLeaderboardContrastFailure (err) {
  return {
    type: FETCH_LEADERBOARD_CONTRAST_FAILURE,
    err
  }
}

export function fetchLeaderboardGroups (start, end) {
  return {
    type: FETCH_LEADERBOARD_GROUPS_REQUEST,
    start,
    end
  }
}

export function fetchLeaderboardGroupsSuccess (reports) {
  return {
    type: FETCH_LEADERBOARD_GROUPS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchLeaderboardGroupsFailure (err) {
  return {
    type: FETCH_LEADERBOARD_GROUPS_FAILURE,
    err
  }
}

export function fetchLeaderboardGroupsContrast (start, end, version) {
  return {
    type: FETCH_LEADERBOARD_GROUPS_CONTRAST_REQUEST,
    start,
    end,
    version
  }
}

export function fetchLeaderboardGroupsContrastSuccess (reports) {
  return {
    type: FETCH_LEADERBOARD_GROUPS_CONTRAST_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchLeaderboardGroupsContrastFailure (err) {
  return {
    type: FETCH_LEADERBOARD_GROUPS_CONTRAST_FAILURE,
    err
  }
}

export function fetchMessagePerformaceReports (start, end) {
  return {
    type: FETCH_TEAM_REPORTS_REQUEST,
    reports: MESSAGE_REPORTS,
    start,
    end
  }
}

export function fetchPersonalizedMessagePerformanceReports (start, end) {
  return {
    type: FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST,
    reports: PERSONALIZED_MESSAGE_REPORTS,
    start,
    end
  }
}

export function fetchContactPerformaceReports (start, end) {
  return {
    type: FETCH_TEAM_REPORTS_REQUEST,
    reports: CONTACT_REPORTS,
    start,
    end
  }
}

export function fetchPersonalizedContactPerformanceReports (start, end) {
  return {
    type: FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST,
    reports: PERSONALIZED_CONTACT_REPORTS,
    start,
    end
  }
}

export function fetchTeamReportsContrastPeriod (start, end) {
  return {
    type: FETCH_TEAM_REPORTS_CONTRAST_PERIOD_REQUEST,
    start,
    end
  }
}

export function fetchTeamReportsContrastPeriodSuccess (reports) {
  return {
    type: FETCH_TEAM_REPORTS_CONTRAST_PERIOD_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchTeamReportsContrastPeriodFailure (err) {
  return {
    type: FETCH_TEAM_REPORTS_CONTRAST_PERIOD_FAILURE,
    err
  }
}

export function fetchUserReports (userId, start, end) {
  return {
    type: FETCH_USER_REPORTS_REQUEST,
    start,
    end,
    userId
  }
}

export function fetchUserReportsSuccess ({ reports }) {
  return {
    type: FETCH_USER_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchUserReportsFailure (err) {
  return {
    type: FETCH_USER_REPORTS_FAILURE,
    err
  }
}

export function fetchSequenceReports (start, end, sequenceIds) {
  return {
    type: FETCH_SEQUENCE_REPORTS_REQUEST,
    start,
    end,
    sequenceIds
  }
}

export function fetchSequenceReportsSuccess ({ reports }) {
  return {
    type: FETCH_SEQUENCE_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchSequenceReportsFailure (err) {
  return {
    type: FETCH_SEQUENCE_REPORTS_FAILURE,
    err
  }
}

export function fetchMyReports (start, end) {
  return {
    type: FETCH_MY_REPORTS_REQUEST,
    start,
    end
  }
}

export function fetchMyReportsSuccess ({ reports }) {
  return {
    type: FETCH_MY_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchMyReportsFailure (err) {
  return {
    type: FETCH_MY_REPORTS_FAILURE,
    err
  }
}

export function fetchBookedReports (start, end, campaigns, users, groups, all, movingAvgWindow = 7) {
  return {
    type: FETCH_BOOKED_REPORTS_REQUEST,
    start,
    end,
    campaigns,
    users,
    groups,
    all,
    movingAvgWindow
  }
}

export function fetchBookedReportsSuccess ({ reports }) {
  return {
    type: FETCH_BOOKED_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchBookedReportsFailure (err) {
  return {
    type: FETCH_BOOKED_REPORTS_FAILURE,
    err
  }
}

export function fetchRepliedReports (start, end, campaigns, users, groups, all, movingAvgWindow = 7) {
  return {
    type: FETCH_REPLIED_REPORTS_REQUEST,
    start,
    end,
    campaigns,
    users,
    groups,
    all,
    movingAvgWindow
  }
}

export function fetchRepliedReportsSuccess ({ reports }) {
  return {
    type: FETCH_REPLIED_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchRepliedReportsFailure (err) {
  return {
    type: FETCH_REPLIED_REPORTS_FAILURE,
    err
  }
}

export function fetchContactsCreatedReports (start, end, campaigns, users, groups, all, movingAvgWindow = 7) {
  return {
    type: FETCH_CONTACTS_REPORTS_REQUEST,
    start,
    end,
    campaigns,
    users,
    groups,
    all,
    movingAvgWindow
  }
}

export function fetchContactsCreatedReportsSuccess ({ reports }) {
  return {
    type: FETCH_CONTACTS_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchContactsCreatedReportsFailure (err) {
  return {
    type: FETCH_CONTACTS_REPORTS_FAILURE,
    err
  }
}

export function fetchMessagedReports (start, end, campaigns, users, groups, all, movingAvgWindow = 7) {
  return {
    type: FETCH_MESSAGED_REPORTS_REQUEST,
    start,
    end,
    campaigns,
    users,
    groups,
    all,
    movingAvgWindow
  }
}

export function fetchMessagedReportsSuccess ({ reports }) {
  return {
    type: FETCH_MESSAGED_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchMessagedReportsFailure (err) {
  return {
    type: FETCH_MESSAGED_REPORTS_FAILURE,
    err
  }
}

export function exportReportCSV (start, end, campaigns, users, groups, all, reportType) {
  return {
    type: EXPORT_REPORT_CSV_REQUEST,
    start,
    end,
    campaigns,
    users,
    groups,
    all,
    reportType
  }
}

export function exportReportCSVSuccess ({ downloadLink }) {
  return {
    type: EXPORT_REPORT_CSV_SUCCESS,
    payload: {
      downloadLink
    }
  }
}

export function exportReportCSVFailure (err) {
  return {
    type: EXPORT_REPORT_CSV_FAILURE,
    err
  }
}

export function fetchBounceRateReports (start, end, { user }) {
  return {
    type: FETCH_BOUNCE_RATE_REPORT_REQUEST,
    start,
    end,
    user
  }
}

export function fetchBounceRateReportsSuccess ({ reports }) {
  return {
    type: FETCH_BOUNCE_RATE_REPORT_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchBounceRateReportsFailure (err) {
  return {
    type: FETCH_BOUNCE_RATE_REPORT_FAILURE,
    err
  }
}

export function fetchOpenHoursReports (start, end, { sequence, user }) {
  return {
    type: FETCH_OPEN_HOURS_REQUEST,
    start,
    end,
    sequence,
    user
  }
}

export function fetchOpenHoursReportsSuccess ({ reports }) {
  return {
    type: FETCH_OPEN_HOURS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchOpenHoursReportsFailure (err) {
  return {
    type: FETCH_OPEN_HOURS_FAILURE,
    err
  }
}

export function fetchReplyHoursReports (start, end, { sequence, user }) {
  return {
    type: FETCH_REPLY_HOURS_REQUEST,
    start,
    end,
    sequence,
    user
  }
}

export function fetchReplyHoursReportsSuccess ({ reports }) {
  return {
    type: FETCH_REPLY_HOURS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchReplyHoursReportsFailure (err) {
  return {
    type: FETCH_REPLY_HOURS_FAILURE,
    err
  }
}

export function fetchContactTaskReports (start, end, campaigns, users, groups, all, movingAvgWindow = 7) {
  return {
    type: FETCH_CONTACT_TASK_REPORTS_REQUEST,
    campaigns,
    users,
    groups,
    all,
    movingAvgWindow,
    start,
    end
  }
}

export function fetchContactTaskReportsSuccess ({ reports }) {
  return {
    type: FETCH_CONTACT_TASK_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchContactTaskReportsFailure (err) {
  return {
    type: FETCH_CONTACT_TASK_REPORTS_FAILURE,
    err
  }
}

export function fetchPersonalizedMessagesReportsSuccess (reports) {
  return {
    type: FETCH_PERSONALIZED_MESSAGES_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchPersonalizedMessagesReportsFailure (err) {
  return {
    type: FETCH_PERSONALIZED_MESSAGES_REPORTS_FAILURE,
    err
  }
}

export function fetchStepPerformance (start, end, campaigns, users, timezone) {
  return {
    type: FETCH_STEP_PERFORMANCE_REQUEST,
    start,
    end,
    campaigns,
    users,
    timezone
  }
}

export function fetchStepPerformanceSuccess (report) {
  return {
    type: FETCH_STEP_PERFORMANCE_SUCCESS,
    payload: {
      report
    }
  }
}

export function fetchStepPerformanceFailure (err) {
  return {
    type: FETCH_STEP_PERFORMANCE_FAILURE,
    err
  }
}

export function fetchSequencesReports () {
  return {
    type: FETCH_SEQUENCES_REPORTS_REQUEST
  }
}

export function fetchSequencesReportsSuccess (reports) {
  return {
    type: FETCH_SEQUENCES_REPORTS_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchSequencesReportsFailure (err) {
  return {
    type: FETCH_SEQUENCES_REPORTS_FAILURE,
    err
  }
}

export function fetchBounceReason (start, end) {
  return {
    type: FETCH_BOUNCE_REASON_REQUEST,
    start,
    end
  }
}

export function fetchBounceReasonSuccess (reports) {
  return {
    type: FETCH_BOUNCE_REASON_SUCCESS,
    payload: {
      reports
    }
  }
}

export function fetchBounceReasonFailure (err) {
  return {
    type: FETCH_BOUNCE_REASON_FAILURE,
    err
  }
}
