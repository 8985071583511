import styled from 'styled-components'

export default styled.ul`
  background: ${props => props.background || props.theme.menuBackground};
  padding: .5rem;
  margin: 0;
  z-index: 201;
  cursor: default;
  border-radius: 3px;

  a.active li {
    background: ${props => props.activeLinkBackground || props.theme.menuActiveLinkBackground};
  }
`
