export const FETCH_SEARCH_REQUEST = 'Navigation/FETCH_SEARCH_REQUEST'
export const FETCH_SEARCH_SUCCESS = 'Navigation/FETCH_SEARCH_SUCCESS'
export const FETCH_SEARCH_FAILURE = 'Navigation/FETCH_SEARCH_FAILURE'
export const FETCH_SEARCH_RESET = 'Navigation/FETCH_SEARCH_RESET'

export const FETCH_RECENT_SEQUENCES = 'Navigation/RECENT_SEQUENCES'
export const ADD_RECENT_SEQUENCE = 'Navigation/ADD_RECENT_SEQUENCE'

export const FETCH_CHECKLIST_REQUEST = 'Navigation/FETCH_CHECKLIST_REQUEST'
export const FETCH_CHECKLIST_SUCCESS = 'Navigation/FETCH_CHECKLIST_SUCCESS'
export const FETCH_CHECKLIST_FAILURE = 'Navigation/FETCH_CHECKLIST_FAILURE'

export const SEARCH_TABS = {
  ALL: 'all',
  SEQUENCES: 'sequences',
  CONTACTS: 'contacts'
}
