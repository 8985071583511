import React, { PureComponent } from 'react'
import { Link } from 'react-router'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import { getTimezoneNames } from 'utils/dates'

import DropDownFieldForm from 'components/DropDownFieldForm'
import ScheduleFieldForm from 'components/ScheduleFieldForm'
import SwitchFieldForm from 'components/SwitchFieldForm'

const Wrapper = styled.div`
  width: 100%;
  padding: ${props => props.theme.padding};
`

const FieldContainer = styled.div`
  padding: 1rem;
  /* overflow: auto; */
  /* max-height: calc(100vh - 194px); */
`

const LabelLink = styled.span`
  cursor: pointer;
  color: ${props => props.theme.primaryColor};
  font: ${props => props.theme.fonts.small};
`

class Schedule extends PureComponent {
  constructor (props) {
    super(props)
    this.state = this.buildStateFromProps(props)
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const state = this.buildStateFromProps(newProps)
    this.setState(state)
  }

  buildStateFromProps = (props) => {
    const {
      sequence
    } = props

    const schedule = sequence.get('schedule')
    let customSchedule = false
    if (schedule && schedule.count() > 0) {
      customSchedule = true
    }

    const timezone = sequence.get('timezone')

    return {
      schedule,
      timezone,
      customSchedule
    }
  }

  render () {
    const {
      session,
      onSettingUpdate
    } = this.props

    const {
      schedule,
      customSchedule,
      timezone
    } = this.state

    const defaultTimezone = session.get('timezone')
    const timezones = Immutable.List(getTimezoneNames())

    const defaultSchedule = session.get('schedule')
    let description
    if (!customSchedule) {
      description = (
        <span>Sequence is currently using your <Link href='/settings/schedule' to='/settings/schedule'>default schedule</Link>.
          Customize the schedule for only this sequence below:
        </span>
      )
    } else {
      description = (
        <span>
          Sequence schedule is customized.&nbsp;
          <LabelLink
            onClick={() => {
              onSettingUpdate({ schedule: null })
              this.setState({
                schedule: defaultSchedule,
                customSchedule: false
              })
            }}
          >
            Clear customization
          </LabelLink>
          &nbsp;and send under default schedule.
        </span>
      )
    }

    return (
      <Wrapper>
        <FieldContainer>
          <SwitchFieldForm
            value={customSchedule}
            label='Custom Schedule'
            description={description}
            onSave={(value) => {
              onSettingUpdate({
                schedule: value ? defaultSchedule : Immutable.List([]),
                timezone: value ? defaultTimezone : null
              })
              this.setState({
                customSchedule: value
              })
            }}
          />
          {customSchedule && <DropDownFieldForm
            label='Timezone'
            description='Utilize this timezone for the custom schedule'
            options={timezones}
            value={timezone || defaultTimezone}
            searchable
            inputId='timezone'
            mt='2rem'
            onOptionChange={(value) => {
              onSettingUpdate({ timezone: value })
            }}
                             />}
          {customSchedule &&
            <ScheduleFieldForm
              label='SCHEDULE'
              description='Emails will send within this timeframe and ignore your user set schedule'
              schedule={schedule}
              mt='2rem'
              onChange={(hours) => {
                onSettingUpdate({ schedule: hours })
              }}
            />}
        </FieldContainer>
      </Wrapper>
    )
  }
}

Schedule.propTypes = {
  session: PropTypes.object,
  onSettingUpdate: PropTypes.func
}

export default Schedule
