import { fromJS } from 'immutable'
import { put, takeLatest, call } from 'redux-saga/effects'

import api from 'utils/api'

import {
  setNotification,
  fetchSessionState
} from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchSubscriptionSuccess,
  fetchSubscriptionFailure,
  createSubscriptionSuccess,
  createSubscriptionFailure,
  updateSubscriptionSuccess,
  updateSubscriptionFailure,
  fetchCustomerSuccess,
  fetchCustomerFailure,
  fetchPreviewSuccess,
  fetchPreviewFailure,
  fetchUpdatePreviewSuccess,
  fetchUpdatePreviewFailure,
  fetchChargesSuccess,
  fetchChargesFailure,
  fetchRequestsSuccess,
  fetchRequestsFailure,
  fetchBillingEmailSuccess,
  fetchBillingEmailFailure,
  fetchPricingSuccess,
  fetchPricingFailure,
  addLookupsSuccess,
  addLookupsFailure
} from './actions'
import {
  FETCH_SUBSCRIPTION_REQUEST,
  CREATE_SUBSCRIPTION_REQUEST,
  UPDATE_SUBSCRIPTION_REQUEST,
  FETCH_CUSTOMER_REQUEST,
  FETCH_PREVIEW_REQUEST,
  FETCH_UPDATE_PREVIEW_REQUEST,
  FETCH_CHARGES_REQUEST,
  FETCH_REQUESTS_REQUEST,
  FETCH_BILLING_EMAIL_REQUEST,
  FETCH_PRICING_REQUEST,
  ADD_LOOKUPS_REQUEST
} from './constants'

const get = (url, params) => api.get(url, {
  params
})
const post = (url, params) => api.post(url, params)
const update = (url, params) => api.put(url, params)

export function * fetchSubscription () {
  try {
    const response = yield call(get, '/subscription')
    const subscription = fromJS(response.data)

    yield put(fetchSubscriptionSuccess({
      subscription
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(fetchSubscriptionFailure(error))
  }
}

export function * createSubscription (action) {
  const params = action.params
  try {
    const response = yield call(post, '/subscription', params)
    const subscription = fromJS(response.data)
    yield put(fetchSessionState())
    yield put(createSubscriptionSuccess({
      subscription
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(createSubscriptionFailure(error))
  }
}

export function * fetchPreview () {
  try {
    const response = yield call(get, '/subscription/preview')
    const preview = fromJS(response.data)

    yield put(fetchPreviewSuccess({
      preview
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(fetchPreviewFailure(error))
  }
}

export function * updateSubscription (action) {
  const params = action.params
  try {
    const response = yield call(update, '/subscription', params)
    if (params.seats || params.yearly) {
      const previewResponse = yield call(get, '/subscription/preview')
      const preview = fromJS(previewResponse.data)
      yield put(fetchPreviewSuccess({
        preview
      }))
    }
    if (params.stripe_token_id) {
      const customerResponse = yield call(get, '/subscription/customer')
      const customer = fromJS(customerResponse.data)
      yield put(fetchCustomerSuccess({
        customer
      }))
    }

    const subscription = fromJS(response.data)
    yield put(updateSubscriptionSuccess({
      subscription
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(updateSubscriptionFailure(error))
  }
}

export function * fetchCustomer () {
  try {
    const response = yield call(get, '/subscription/customer')
    const customer = fromJS(response.data)

    yield put(fetchCustomerSuccess({
      customer
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(fetchCustomerFailure(error))
  }
}

export function * fetchUpdatePreview (action) {
  const query = action.query
  try {
    const response = yield call(get, '/subscription/preview', query)
    const preview = fromJS(response.data)

    yield put(fetchUpdatePreviewSuccess({
      preview
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(fetchUpdatePreviewFailure(error))
  }
}

export function * fetchCharges () {
  try {
    const response = yield call(get, '/subscription/charges')
    const charges = fromJS(response.data)

    yield put(fetchChargesSuccess({
      charges
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(fetchChargesFailure(error))
  }
}

export function * fetchRequests (action) {
  try {
    const query = {
      cycles_ago: action.payload.cyclesAgo
    }

    const response = yield call(get, '/subscription/requests', query)
    const requests = fromJS(response.data)

    yield put(fetchRequestsSuccess({
      requests
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(fetchRequestsFailure(error))
  }
}

export function * fetchBillingEmail () {
  try {
    const response = yield call(get, '/subscription/billing_emails')
    const billingEmails = fromJS(response.data)

    yield put(fetchBillingEmailSuccess({
      billingEmails
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(fetchBillingEmailFailure(error))
  }
}

export function * fetchPricing (action) {
  const query = action.query
  try {
    const response = yield call(get, '/subscription/pricing', query)
    const pricing = fromJS(response.data)

    yield put(fetchPricingSuccess({
      pricing
    }))
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(fetchPricingFailure(error))
  }
}

export function * addLookups (action) {
  const query = action.query
  try {
    yield call(post, '/subscription/lookups', query)
    yield put(addLookupsSuccess())
  } catch (error) {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: error.response.data.message
    }))
    yield put(addLookupsFailure(error))
  }
}

function * fetchSubscriptionRequest () {
  yield takeLatest(FETCH_SUBSCRIPTION_REQUEST, fetchSubscription)
}

function * createSubscriptionRequest () {
  yield takeLatest(CREATE_SUBSCRIPTION_REQUEST, createSubscription)
}

function * updateSubscriptionRequest () {
  yield takeLatest(UPDATE_SUBSCRIPTION_REQUEST, updateSubscription)
}

function * fetchCustomerRequest () {
  yield takeLatest(FETCH_CUSTOMER_REQUEST, fetchCustomer)
}

function * fetchPreviewRequest () {
  yield takeLatest(FETCH_PREVIEW_REQUEST, fetchPreview)
}

function * fetchUpdatePreviewRequest () {
  yield takeLatest(FETCH_UPDATE_PREVIEW_REQUEST, fetchUpdatePreview)
}

function * fetchChargesRequest () {
  yield takeLatest(FETCH_CHARGES_REQUEST, fetchCharges)
}

function * fetchRequestsRequest () {
  yield takeLatest(FETCH_REQUESTS_REQUEST, fetchRequests)
}

function * fetchBillingEmailRequest () {
  yield takeLatest(FETCH_BILLING_EMAIL_REQUEST, fetchBillingEmail)
}

function * fetchPricingRequest () {
  yield takeLatest(FETCH_PRICING_REQUEST, fetchPricing)
}

function * addLookupsRequest () {
  yield takeLatest(ADD_LOOKUPS_REQUEST, addLookups)
}

export default [
  fetchSubscriptionRequest,
  createSubscriptionRequest,
  updateSubscriptionRequest,
  fetchCustomerRequest,
  fetchPreviewRequest,
  fetchUpdatePreviewRequest,
  fetchChargesRequest,
  fetchRequestsRequest,
  fetchBillingEmailRequest,
  fetchPricingRequest,
  addLookupsRequest
]
