import React from 'react'

/* eslint-disable */
const Rocket = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 347} height={height || 134}  viewBox="0 0 347 134" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <defs>
        <linearGradient x1="49.9993767%" y1="0.0160828025%" x2="49.9993767%" y2="100.053822%" id="linearGradient-1">
            <stop stopColor="#FFFFFF" offset="0%"></stop>
            <stop stopColor="#FDFDFE" offset="86.08%"></stop>
            <stop stopColor="#FBFCFE" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="49.9571223%" y1="0.175318471%" x2="49.9571223%" y2="100.213057%" id="linearGradient-2">
            <stop stopColor="#FFFFFF" offset="0%"></stop>
            <stop stopColor="#FDFDFE" offset="86.08%"></stop>
            <stop stopColor="#FBFCFE" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-3.00786562%" y1="50.0322785%" x2="99.9723172%" y2="50.0322785%" id="linearGradient-3">
            <stop stopColor="#BBC9DE" offset="0%"></stop>
            <stop stopColor="#B9C7DD" offset="42.22%"></stop>
            <stop stopColor="#B2C1D8" offset="57.43%"></stop>
            <stop stopColor="#A6B7D1" offset="68.27%"></stop>
            <stop stopColor="#96A8C6" offset="77.04%"></stop>
            <stop stopColor="#8095B8" offset="84.54%"></stop>
            <stop stopColor="#647DA7" offset="91.19%"></stop>
            <stop stopColor="#456293" offset="97.05%"></stop>
            <stop stopColor="#325187" offset="100%"></stop>
        </linearGradient>
        <linearGradient x1="-0.661437908%" y1="50.0322785%" x2="102.672222%" y2="50.0322785%" id="linearGradient-4">
            <stop stopColor="#325187" offset="0%"></stop>
            <stop stopColor="#456293" offset="2.946562%"></stop>
            <stop stopColor="#647DA7" offset="8.81359%"></stop>
            <stop stopColor="#8095B8" offset="15.46%"></stop>
            <stop stopColor="#96A8C6" offset="22.96%"></stop>
            <stop stopColor="#A6B7D1" offset="31.73%"></stop>
            <stop stopColor="#B2C1D8" offset="42.57%"></stop>
            <stop stopColor="#B9C7DD" offset="57.78%"></stop>
            <stop stopColor="#BBC9DE" offset="100%"></stop>
        </linearGradient>
    </defs>
    <g id="Billing" stroke="none" strokeWidth="1" fill="none" fillRule="evenodd">
        <g transform="translate(-510.000000, -93.000000)" id="rocket-launch">
            <g transform="translate(510.000000, 93.000000)">
                <polygon id="Shape" fill="#FFFFFF" fillRule="nonzero" points="176.142857 28.5959654 176.142857 19.2380403 172.714286 6 169.285714 6 165.857143 19.2380403 165.857143 28.5959654 163 46.1706052 163 66.940634 163 105 179 105 179 66.940634 179 46.1706052"></polygon>
                <g id="Group" transform="translate(0.000000, 84.000000)" fillRule="nonzero">
                    <path d="M337.437459,27.403125 C335.719637,27.403125 334.116337,27.865625 332.742079,28.7328125 C329.134653,12.5453125 314.819472,0.4625 297.641254,0.4625 C288.479538,0.4625 280.119472,3.93125 273.763531,9.596875 C268.037459,5.203125 260.937129,2.54375 253.14967,2.54375 C240.953135,2.54375 230.24538,9.0765625 224.290264,18.846875 C221.484488,17.2859375 218.220627,16.41875 214.784983,16.41875 C206.310396,16.41875 199.038284,21.7375 196.060726,29.1953125 C194.514686,28.0390625 192.625083,27.403125 190.620957,27.403125 C185.524752,27.403125 181.344719,31.6234375 181.344719,36.76875 L194.686469,36.76875 L199.954455,36.76875 L219.308581,36.76875 L234.99802,36.76875 L261.681518,36.76875 L287.105281,36.76875 L328.161221,36.76875 L333.60099,36.76875 L346.713696,36.76875 C346.713696,31.6234375 342.533663,27.403125 337.437459,27.403125 Z" id="Shape" fill="url(#linearGradient-1)"></path>
                    <path d="M149.507756,27.403125 C147.961716,27.403125 146.530198,27.8078125 145.270462,28.44375 C142.121122,21.3328125 135.078053,16.41875 126.889769,16.41875 C122.709736,16.41875 118.816007,17.690625 115.552145,19.9453125 C111.143069,15.3203125 104.958911,12.371875 98.0876238,12.371875 C91.6744224,12.371875 85.8338284,14.915625 81.5392739,19.0203125 C75.3551155,7.9203125 63.6166667,0.4046875 50.160396,0.4046875 C32.810396,0.4046875 18.3234323,12.7765625 14.9450495,29.3109375 C13.3990099,28.096875 11.4521452,27.3453125 9.33349835,27.3453125 C4.23729373,27.3453125 0.0572607261,31.565625 0.0572607261,36.7109375 L14.2006601,36.7109375 L18.609736,36.7109375 L74.0381188,36.7109375 L86.1773927,36.7109375 L106.791254,36.7109375 L122.308911,36.7109375 L140.288779,36.7109375 L147.102805,36.7109375 L158.841254,36.7109375 C158.783993,31.6234375 154.60396,27.403125 149.507756,27.403125 Z" id="Shape" fill="url(#linearGradient-2)"></path>
                </g>
                <polygon id="Shape" fill="#C9D8F4" fillRule="nonzero" points="173.993789 111 167.006211 111 166 105 175 105"></polygon>
                <g id="Group" transform="translate(148.000000, 93.000000)" fillRule="nonzero">
                    <path d="M18.056962,6.6375 L4.2721519,10.0125 L6.32278481,7.3125 L5.58227848,26.83125 L1.36708861,26.83125 L0.569620253,7.3125 C0.512658228,6.075 1.31012658,5.00625 2.44936709,4.66875 L2.67721519,4.6125 L16.1772152,0.16875 L18.056962,6.6375 Z" id="Shape" fill="url(#linearGradient-3)"></path>
                    <path d="M29.0506329,0.16875 L42.5506329,4.6125 L42.778481,4.66875 C43.9746835,5.0625 44.6582278,6.13125 44.6582278,7.3125 L43.9177215,26.83125 L39.7025316,26.83125 L38.9620253,7.3125 L41.0126582,10.0125 L27.2278481,6.6375 L29.0506329,0.16875 Z" id="Shape" fill="url(#linearGradient-4)"></path>
                </g>
                <path d="M132,35 L132,121" id="Shape" stroke="#BBC9DE" strokeWidth="3"></path>
                <path d="M117,121 L117,35" id="Shape" stroke="#BBC9DE" strokeWidth="3"></path>
                <polyline id="Shape" stroke="#BBC9DE" strokeWidth="2" strokeLinejoin="round" points="132 109 117 109 132 93.3523694 117 93.3523694 132 77.6476306 117 77.6476306 132 62"></polyline>
                <polyline id="Shape" stroke="#BBC9DE" strokeWidth="2" strokeLinejoin="round" points="117 46 132.314286 46 117 62 149 62"></polyline>
                <path d="M227,68 L227,121" id="Shape" stroke="#BBC9DE" strokeWidth="3"></path>
                <path d="M242,106 L242,121" id="Shape" stroke="#BBC9DE" strokeWidth="3"></path>
                <path d="M212,121 L212,74" id="Shape" stroke="#BBC9DE" strokeWidth="3"></path>
                <polyline id="Shape" stroke="#BBC9DE" strokeWidth="2" strokeLinejoin="round" points="242 108 212 108 227.19774 92.5282332 212 92.5282332 227.19774 77 212 77"></polyline>
                <path d="M165,120.280969 C165,126.035986 167.377358,131.035294 171,134 C174.622642,131.035294 177,125.977855 177,120.280969 C177,120.222837 177,120.164706 177,120.106574 C176.943396,110.631142 165.113208,110.631142 165.056604,120.106574 C165,120.164706 165,120.222837 165,120.280969 Z" id="Shape" fill="#6E98F0" fillRule="nonzero"></path>
                <path d="M168,118.462604 C168,121.177285 169.188679,123.614958 171,125 C172.811321,123.614958 174,121.177285 174,118.462604 C174,118.462604 174,118.407202 174,118.407202 C173.943396,113.864266 168.056604,113.864266 168,118.407202 C168,118.407202 168,118.462604 168,118.462604 Z" id="Shape" fill="#FFFFFF" fillRule="nonzero"></path>
                <rect id="Rectangle-path" fill="#C9D8F4" fillRule="nonzero" x="163" y="46" width="16" height="21"></rect>
                <path d="M169.9,0.410788382 L169,3 L172,3 L171.1,0.410788382 C170.9,-0.136929461 170.1,-0.136929461 169.9,0.410788382 Z" id="Shape" fill="#6E98F0" fillRule="nonzero"></path>
                <rect id="Rectangle-path" fill="#C9D8F4" fillRule="nonzero" x="169" y="3" width="3" height="3"></rect>
                <polygon id="Shape" fill="#FFFFFF" fillRule="nonzero" points="172.666667 6 169.333333 6 166 19 176 19"></polygon>
                <rect id="Rectangle-path" fill="#C9D8F4" fillRule="nonzero" x="166" y="19" width="10" height="9"></rect>
                <polygon id="Shape" fill="#FFFFFF" fillRule="nonzero" points="176.142857 29 165.857143 29 163 47 179 47"></polygon>
                <rect id="Rectangle-path" fill="#FFFFFF" fillRule="nonzero" x="163" y="67" width="16" height="38"></rect>
                <circle id="Oval" fill="#325187" fillRule="nonzero" cx="171.5" cy="56.5" r="3.5"></circle>
                <circle id="Oval" fill="#6E98F0" fillRule="nonzero" cx="170.5" cy="75.5" r="2.5"></circle>
                <circle id="Oval" fill="#6E98F0" fillRule="nonzero" cx="170.5" cy="86.5" r="2.5"></circle>
                <rect id="Rectangle-path" fill="#325187" fillRule="nonzero" transform="translate(170.500000, 101.000000) rotate(-90.000000) translate(-170.500000, -101.000000) " x="165" y="99" width="11" height="4"></rect>
                <path d="M171,36 L171,42" id="Shape" stroke="#BBC9DE" strokeWidth="4" strokeLinejoin="round"></path>
                <path d="M176,36 L176,42" id="Shape" stroke="#C9D8F4" strokeWidth="2" strokeLinejoin="round"></path>
                <path d="M166,36 L166,42" id="Shape" stroke="#C9D8F4" strokeWidth="2" strokeLinejoin="round"></path>
            </g>
        </g>
    </g>
  </svg>
);

export default Rocket;
