import React from 'react'
import Avatar from 'react-avatar'
import styled, { withTheme } from 'styled-components'
import { color, textAlign } from 'styled-system'
import PropTypes from 'prop-types'
import NumberFormat from 'react-number-format'
import { pluralize } from 'utils/strings'

const Wrapper = styled.div`
  display: grid;
  grid-template-columns: 25% 75%;

  background-color: ${props => props.theme.colors.white};
  text-transform: capitalize;

  border-top: 1px solid ${props => props.theme.colors.gray30};
  width: 100%;
`

const ScrollWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(${props => props.columnCount}, 33.33%);
  overflow-x: scroll;
  overflow-y: hidden;
`

const Item = styled.div`
  padding: 0.65rem 1rem;
  padding-top: calc(0.65rem + 2px);

  height: 100%;

  font: ${props => props.theme.fonts.subtitle};
  text-align: center;

  border-right: ${props => props.border ? `1px solid ${props.theme.colors.gray30}` : 'none'};

  ${color}
`

const UserItem = styled(Item)``

const UserAvatar = styled.div`
  display: inline-block;
  position: relative;
  top: -14px;
  margin-right: 0.75rem;
`

const LabelWrapper = styled.div`
  display: inline-block;
  max-width: calc(100% - 28px - 0.75rem);

  ${textAlign}
`

const Count = styled.div`
  margin-bottom: -5px;
  font: ${props => props.theme.fonts.subtitle};
  font-size: 20px;
  color: ${props => props.theme.colors.blue};
`

const Label = styled.div`
  font: ${props => props.theme.fonts.xsmall};
  color: ${props => props.theme.colors.gray70};

  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

class UserHUD extends React.Component {
  renderUserItems (userData) {
    const { theme, unitSuffix } = this.props

    return userData
      .sort((a, b) => a.total < b.total ? 1 : -1)
      .map((user, index) => {
        return (
          <UserItem key={index}>
            <UserAvatar>
              <Avatar
                src={user.photo || '/bear_avatar.png'}
                name={user.name}
                maxInitials={2}
                size={28}
                textSizeRatio={2}
                round
                colors={theme.avatarColors}
              />
            </UserAvatar>
            <LabelWrapper align='left'>
              <Count>
                <NumberFormat
                  value={user.total}
                  displayType='text'
                  thousandSeparator=','
                  suffix={unitSuffix}
                />
              </Count>
              <Label>{user.name}</Label>
            </LabelWrapper>
          </UserItem>
        )
      })
  }

  render () {
    const {
      theme,
      userData,
      unitLabel,
      unitLabelPlural,
      totalUnits,
      unitSuffix
    } = this.props

    if (!userData || userData.length <= 0) {
      return (<></>)
    }

    return (
      <Wrapper>
        <Item bg={theme.colors.gray10} border>
          <LabelWrapper>
            <Count>
              <NumberFormat
                value={totalUnits}
                displayType='text'
                thousandSeparator=','
                suffix={unitSuffix}
              />
            </Count>
            <Label>{`${pluralize(unitLabel, unitLabelPlural, totalUnits)}`}</Label>
          </LabelWrapper>
        </Item>
        <ScrollWrapper columnCount={userData.length}>
          {this.renderUserItems(userData)}
        </ScrollWrapper>
      </Wrapper>
    )
  }
}

UserHUD.propTypes = {
  userData: PropTypes.object,
  unitLabel: PropTypes.string,
  unitLabelPlural: PropTypes.string,
  totalUnits: PropTypes.number,
  unitSuffix: PropTypes.unitSuffix,
  theme: PropTypes.object
}

export default withTheme(UserHUD)
