export const FETCH_CRM_LIST_REQUEST = 'Integrations/FETCH_CRM_LIST_REQUEST'
export const FETCH_CRM_LIST_SUCCESS = 'Integrations/FETCH_CRM_LIST_SUCCESS'
export const FETCH_CRM_LIST_FAILURE = 'Integrations/FETCH_CRM_LIST_FAILURE'

export const FETCH_CRM_REQUEST = 'Integrations/FETCH_CRM_REQUEST'
export const FETCH_CRM_SUCCESS = 'Integrations/FETCH_CRM_SUCCESS'
export const FETCH_CRM_FAILURE = 'Integrations/FETCH_CRM_FAILURE'

export const REFRESH_CRM_REQUEST = 'Integrations/REFRESH_CRM_REQUEST'

export const CREATE_CRM_REQUEST = 'Integrations/CREATE_CRM_REQUEST'
export const CREATE_CRM_SUCCESS = 'Integrations/CREATE_CRM_SUCCESS'
export const CREATE_CRM_FAILURE = 'Integrations/CREATE_CRM_FAILURE'

export const UPDATE_CRM_REQUEST = 'Integrations/UPDATE_CRM_REQUEST'
export const UPDATE_CRM_SUCCESS = 'Integrations/UPDATE_CRM_SUCCESS'
export const UPDATE_CRM_FAILURE = 'Integrations/UPDATE_CRM_FAILURE'

export const DELETE_CRM_REQUEST = 'Integrations/DELETE_CRM_REQUEST'
export const DELETE_CRM_SUCCESS = 'Integrations/DELETE_CRM_SUCCESS'
export const DELETE_CRM_FAILURE = 'Integrations/DELETE_CRM_FAILURE'

export const DELETE_CRON_REQUEST = 'Integrations/DELETE_CRON_REQUEST'
export const DELETE_CRON_SUCCESS = 'Integrations/DELETE_CRON_SUCCESS'
export const DELETE_CRON_FAILURE = 'Integrations/DELETE_CRON_FAILURE'

export const FETCH_SEQUENCE_FIELDS_REQUEST = 'Integrations/FETCH_SEQUENCE_FIELDS_REQUEST'
export const FETCH_SEQUENCE_FIELDS_SUCCESS = 'Integrations/FETCH_SEQUENCE_FIELDS_SUCCESS'
export const FETCH_SEQUENCE_FIELDS_FAILURE = 'Integrations/FETCH_SEQUENCE_FIELDS_FAILURE'

export const FETCH_ZAPS_REQUEST = 'Integrations/FETCH_ZAPS_REQUEST'
export const FETCH_ZAPS_SUCCESS = 'Integrations/FETCH_ZAPS_SUCCESS'
export const FETCH_ZAPS_FAILURE = 'Integrations/FETCH_ZAPS_FAILURE'

export const FETCH_ZAP_TEMPLATES_REQUEST = 'Integrations/FETCH_ZAP_TEMPLATES_REQUEST'
export const FETCH_ZAP_TEMPLATES_SUCCESS = 'Integrations/FETCH_ZAP_TEMPLATES_SUCCESS'
export const FETCH_ZAP_TEMPLATES_FAILURE = 'Integrations/FETCH_ZAP_TEMPLATES_FAILURE'

export const FETCH_API_KEY_REQUEST = 'Integrations/FETCH_API_KEY_REQUEST'
export const FETCH_API_KEY_SUCCESS = 'Integrations/FETCH_API_KEY_SUCCESS'
export const FETCH_API_KEY_FAILURE = 'Integrations/FETCH_API_KEY_FAILURE'

export const CLONE_SETTINGS_REQUEST = 'Integrations/CLONE_SETTINGS_REQUEST'
export const CLONE_SETTINGS_SUCCESS = 'Integrations/CLONE_SETTINGS_SUCCESS'
export const CLONE_SETTINGS_FAILURE = 'Integrations/CLONE_SETTINGS_FAILURE'

export const CRM_TYPES = {
  BASE: 'base',
  BULLHORN: 'bullhorn',
  CATS: 'cats',
  CLOSEIO: 'closeio',
  GREENHOUSE: 'greenhouse',
  HUBSPOT: 'hubspot',
  PIPEDRIVE: 'pipedrive',
  PROSPERWORKS: 'prosperworks',
  SALESFORCE: 'salesforce',
  SALESFORCEIQ: 'salesforceiq',
  SLACK: 'slack',
  ZAPIER: 'zapier',
  ZOHO: 'zoho',
  JAZZHR: 'jazzhr',
  INSIGHTLY: 'insightly',
  ZOHO_RECRUIT: 'zoho_recruit',
  CLOCKWORK: 'clockwork',
  RECRUITEE: 'recruitee',
  EXELARE: 'exelare',
  CRELATE: 'crelate',
  PCRECRUITER: 'pcr',
  BREEZY: 'breezy',
  INVENIAS: 'invenias',
  RECRUITERFLOW: 'recruiterflow',
  COMPAS: 'compas',
  LEVER: 'lever',
  LESSANNOYING: 'lessannoying',
  PIPELINEDEALS: 'pipelinedeals',
  VINCERE: 'vincere',
  LEO: 'leo',
  LOXO: 'loxo',
  DYNAMICS: 'dynamics',
  RECRUITLY: 'recruitly',
  AFFINITY: 'affinity',
  ERECRUIT: 'erecruit'
}

export const CRM_NAMES = {
  pcr: 'PCRecruiter',
  zoho_recruit: 'Zoho Recruit',
  breezy: 'Breezy HR',
  dynamics: 'Microsoft Dynamics CRM'
}

export const CRM_FIELDS = {
  LISTS: 'lists',
  STAGES: 'stages'
}

export const TOP_LEVEL_FIELD_OPTIONS = [
  {
    value: 'Interseller',
    label: 'Fill with "Interseller"'
  },
  {
    value: 'Lead',
    label: 'Fill with "Lead"'
  },
  {
    value: '$_campaign.title',
    label: 'Sequence Title'
  },
  {
    value: '$email',
    label: 'Email'
  },
  {
    value: '$email_domain',
    label: 'Email Domain'
  },
  {
    value: '$name',
    label: 'Name'
  },
  {
    value: '$parsed_name.first',
    label: 'First Name'
  },
  {
    value: '$parsed_name.last',
    label: 'Last Name'
  },
  {
    value: '$company',
    label: 'Company Name'
  },
  {
    value: '$title',
    label: 'Title'
  },
  {
    value: '$phone_number',
    label: 'Phone Number'
  },
  {
    value: '$domain',
    label: 'Website'
  },
  {
    value: '$source_url_social',
    label: 'Source Type'
  },
  {
    value: '$source_url',
    label: 'Source URL'
  },
  {
    value: '$profile_url',
    label: 'Profile URL'
  },
  {
    value: '$social_urls.linkedin',
    label: 'LinkedIn URL'
  },
  {
    value: '$social_urls.github',
    label: 'GitHub URL'
  },
  {
    value: '$location',
    label: 'Location'
  },
  {
    value: '$parsed_location.city',
    label: 'Location - City'
  },
  {
    value: '$parsed_location.state',
    label: 'Location - State'
  },
  {
    value: '$parsed_location.state_code',
    label: 'Location - State Abbreviation'
  },
  {
    value: '$parsed_location.country',
    label: 'Location - Country'
  },
  {
    value: '$parsed_location.country_code',
    label: 'Location - Country Code'
  },
  {
    value: '$parsed_location.full',
    label: 'Location - City, State, Country'
  },
  {
    value: '$college',
    label: 'Education - College'
  },
  {
    value: '$degree',
    label: 'Education - Degree'
  },
  {
    value: '$created_at',
    label: 'Created At'
  },
  {
    value: '$first_messaged_at',
    label: 'First Messaged At'
  },
  {
    value: '$last_messaged_at',
    label: 'Last Messaged At'
  },
  {
    value: '$replied_at',
    label: 'Responded At'
  },
  {
    value: '$bounced',
    label: 'Bounced'
  },
  {
    value: '$unsubscribed',
    label: 'Unsubscribed'
  },
  {
    value: '$sentiment',
    label: 'Sentiment'
  },
  {
    value: '$pronoun',
    label: 'Pronoun'
  }
]
