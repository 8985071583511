
/* eslint-disable react/no-find-dom-node */

import React from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { fromJS } from 'immutable'
import { fileApi } from 'utils/api'

import {
  Wrapper,
  Window,
  Curtain,
  ButtonContainer,
  Header,
  TabContainer
} from '../ToolbarModal'

import Title from 'elements/Title'
import Divider from 'elements/Divider'
import Button from 'components/Button'
import Label from 'elements/Label'
import DangerLabel from 'elements/DangerLabel'
import {
  FileUpload,
  FileValid,
  FileInvalid
} from 'svg'

const InputContainer = styled.div``

const UploadContainer = styled.div`
  color: ${props => props.theme.labelColor};
  border: 1px dashed ${props => props.theme.colors.gray60};
  border-radius: 3px;
  transition: background-color 0.2s linear;

  background-color: ${
    props => props.dragOver
    ? props.theme.colors.blueGray
    : props.theme.colors.white
  };

  &:hover {
    background-color: ${props => props.theme.colors.blueGray}
  }
`

const UploadArea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2px;
  padding: 5rem 0;

  cursor: pointer;
`

const InvalidLabel = styled(Label)`
  color: ${props => props.theme.colors.red};
  font: ${props => props.theme.fonts.normal};
  text-align: center;
`

class AttachmentModal extends React.Component {
  constructor (props) {
    super(props)

    this.uploadFileRef = React.createRef()
    this.dropAreaRef = React.createRef()

    this.preventDefaults = this.preventDefaults.bind(this)
    this.handleEscapeKey = this.handleEscapeKey.bind(this)
    this.handleEnterKey = this.handleEnterKey.bind(this)
    this.handleDropFile = this.handleDropFile.bind(this)
    this.handleDragOverDropArea = this.handleDragOverDropArea.bind(this)
    this.handleDragAwayDropArea = this.handleDragAwayDropArea.bind(this)
    this.handleFileUpload = this.handleFileUpload.bind(this)
    this.handleOnChange = this.handleOnChange.bind(this)
    this.handleAppendAttachment = this.handleAppendAttachment.bind(this)

    this.state = {
      urlUploadStatus: false, // tracks if a user has tried to upload a file
      urlFailed: false, // tracks if the file a user tried to upload failed

      // metadata about the file
      filesrc: null,
      filename: null,
      filesize: null,

      // tracks if the user is dragging a file over the upload box
      dragOver: false
    }
  }

  componentDidMount () {
    window.addEventListener('keydown', this.handleEscapeKey, false)
    window.addEventListener('keydown', this.handleEnterKey, false)
    this.manageMouseEventListeners(true)
  }

  componentWillUnmount () {
    window.removeEventListener('keydown', this.handleEscapeKey, false)
    window.removeEventListener('keydown', this.handleEnterKey, false)
    this.manageMouseEventListeners(false)
  }

  manageMouseEventListeners = (mount) => {
    if (mount) {
      const uploadFile = this.uploadFileRef.current
      if (uploadFile) {
        // disable default behavior for onChange event -- overrides when user uploads file
        uploadFile.addEventListener('change', this.handleOnChange, false)
      }

      const dropArea = ReactDOM.findDOMNode(this.dropAreaRef.current)
      if (dropArea) {
        // prevent default behavior for all event listeners
        ['keydown', 'dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
          dropArea.addEventListener(eventName, this.preventDefaults, false)
        });

        ['dragenter', 'dragover'].forEach(eventName => {
          dropArea.addEventListener(eventName, this.handleDragOverDropArea, false)
        });

        ['dragleave', 'drop'].forEach(eventName => {
          dropArea.addEventListener(eventName, this.handleDragAwayDropArea, false)
        })

        dropArea.addEventListener('drop', this.handleDropFile, false)
      }
    } else {
      const uploadFile = this.uploadFileRef.current
      if (uploadFile) {
        // disable default behavior for onChange event -- overrides when user uploads file
        uploadFile.removeEventListener('change', this.handleOnChange, false)
      }

      const dropArea = ReactDOM.findDOMNode(this.dropAreaRef.current)
      if (dropArea) {
        // prevent default behavior for all event listeners
        ['keydown', 'dragenter', 'dragover', 'dragleave', 'drop'].forEach(eventName => {
          dropArea.removeEventListener(eventName, this.preventDefaults, false)
        });

        ['dragenter', 'dragover'].forEach(eventName => {
          dropArea.removeEventListener(eventName, this.handleDragOverDropArea, false)
        });

        ['dragleave', 'drop'].forEach(eventName => {
          dropArea.removeEventListener(eventName, this.handleDragAwayDropArea, false)
        })

        dropArea.removeEventListener('drop', this.handleDropFile, false)
      }
    }
  }

  preventDefaults = event => {
    event.preventDefault()
    event.stopPropagation()
  }

  handleEscapeKey = event => {
    const ESCAPE_KEY = 27
    const { toggleToolbarMenuVisibility } = this.props
    if (event.keyCode === ESCAPE_KEY) {
      toggleToolbarMenuVisibility('showAttachmentsMenu')
    }
  }

  handleEnterKey = event => {
    const ENTER_KEY = 13
    const { toggleToolbarMenuVisibility } = this.props
    if (event.keyCode === ENTER_KEY) {
      Promise
        .resolve()
        .then(() => this.handleAddAttachment())
        .then(() => toggleToolbarMenuVisibility('showAttachmentsMenu'))
    }
  }

  handleDragOverDropArea = event => {
    this.setState({ dragOver: true })
  }

  handleDragAwayDropArea = event => {
    this.setState({ dragOver: false })
  }

  handleDropFile = event => {
    // get file from drop event
    const uploadedFile = event.dataTransfer.files[0]

    // checking if file type is among the acceptable filetypes
    const ACCEPTABLE_FILETYPES = ['application/pdf', 'image/jpeg', 'image/jpg', 'image/png', 'image/gif']
    const { type } = uploadedFile

    // check that the uploaded file isn't larger than 216 mb
    const FILESIZE_CAP = 16 * 1000 * 1000

    if (!ACCEPTABLE_FILETYPES.includes(type)) {
      this.setState({
        filesrc: null,
        urlFailed: true,
        urlUploadStatus: true,
        errorMsg: 'Incorrect file type - please re-upload with a .pdf, .jpg, .png, or .gif'
      })
    } else if (uploadedFile.size > FILESIZE_CAP) {
      this.setState({
        filesrc: null,
        urlFailed: true,
        urlUploadStatus: true,
        errorMsg: 'File limit exceeded'
      })
    } else {
      this.handleFileUpload(uploadedFile)
    }
  }

  handleOnChange = event => {
    event.preventDefault()

    // get uploaded file
    const uploadedFile = this.uploadFileRef.current.files[0]

    // check that the uploaded file isn't larger than 25mb
    const FILESIZE_CAP = 16 * 1000 * 1000
    if (uploadedFile.size > FILESIZE_CAP) {
      this.setState({
        filesrc: null,
        urlFailed: true,
        urlUploadStatus: true,
        errorMsg: 'File limit exceeded'
      })
    } else {
      this.handleFileUpload(uploadedFile)
    }
  }

  handleFileUpload = uploadedFile => {
    // continue with uploading the file
    Promise.resolve().then(() => {
      const formData = new window.FormData()
      formData.append('file', uploadedFile)
      fileApi.post('/files', formData)
        .then(response => {
          const result = fromJS(response.data)
          const type = result.get('type')

          if (type === 'link' || type === 'image') {
            const filesrc = result.get('url')
            this.setState({
              filesrc,
              filename: uploadedFile.name,
              filesize: uploadedFile.size,
              urlFailed: false,
              urlUploadStatus: true,
              errorMsg: null
            })
          }
        })
        .catch(err => {
          console.error(err)
          const errorMsg = err.response?.data?.name === 'VIRUS_FOUND' ? 'File failed antivirus scan' : 'Failed to attach file, please try again'
          this.setState({
            filesrc: null,
            urlFailed: true,
            urlUploadStatus: true,
            errorMsg
          })
        })
    })
  }

  handleAppendAttachment = _event => {
    const { filesrc } = this.state
    const { editorRef } = this.props

    editorRef
      .current
      .insertText(filesrc)
      .moveFocusBackward(filesrc.length)
      .wrapLink(filesrc)
  }

  handleAddAttachment = _event => {
    const { filesrc } = this.state
    if (filesrc !== null) {
      // append new attachment to email
      this.handleAppendAttachment()

      // clear state
      this.setState({
        filesrc: null,
        filename: null,
        filesize: null
      })
    }
  }

  renderModal = () => {
    const { urlFailed, urlUploadStatus } = this.state
    const { isBrandedDomain } = this.props

    const uploadInput = (
      <UploadContainer
        ref={this.dropAreaRef}
        dragOver={this.state.dragOver}
      >
        <UploadArea
          onClick={event => {
            event.preventDefault()
            this.uploadFileRef.current.click()
          }}
        >
          {/* User has not uploaded anything */}
          {!urlFailed && !urlUploadStatus &&
            <FileUpload
              width='36px'
              height='36px'
            />}
          {!urlFailed && !urlUploadStatus &&
            <Label mt='1rem'>Drag and drop your file here, or click to browse for a file to upload</Label>}

          {/* User successfully uploaded a file */}
          {!urlFailed && urlUploadStatus &&
            <FileValid
              width='36px'
              height='36px'
            />}

          {!urlFailed && urlUploadStatus &&
            <Label mt='1rem'> <u>{this.state.filename + ' '}</u> was successfully attached </Label>}

          {/* User unsuccessfully uploaded a file */}
          {urlFailed && urlUploadStatus &&
            <FileInvalid
              width='36px'
              height='36px'
            />}

          {urlFailed && urlUploadStatus &&
            <InvalidLabel mt='1rem'> {this.state.errorMsg} </InvalidLabel>}
        </UploadArea>
      </UploadContainer>
    )

    const uploadWarning = (
      <DangerLabel>
        Uploading files may decrease your chances of your email being delivered. We recommend setting up a <a href='https://help.interseller.io/article/12-branded-domains' target='_new'>branded domain</a>.
      </DangerLabel>
    )

    return (
      <div>
        {!isBrandedDomain && uploadWarning}
        {uploadInput}
      </div>
    )
  }

  render () {
    const { toggleToolbarMenuVisibility } = this.props
    return (
      <Wrapper>
        <Curtain
          onClick={() => toggleToolbarMenuVisibility('showAttachmentsMenu')}
          visible
        />
        <Window>
          <TabContainer>
            <input
              ref={this.uploadFileRef}
              style={{ display: 'none' }}
              // accept='.png, .gif, .jpeg, .jpg, .pdf, .doc, .docx, .7z, .csv, .xml, .ods, .xlr, .xls, .xlsx, odt, .rtf, .txt, .wps, .wks, .wpd'
              accept='.pdf, .jpeg, .jpg, .png, .gif'
              id='img-uploader'
              type='file'
            />
            <Header>
              <Title>Add Attachment</Title>
              <Label mt='0.5rem' mb='0.5rem' align='center'>Upload an attachment to your email </Label>
            </Header>

            <InputContainer>
              {this.renderModal()}
            </InputContainer>
          </TabContainer>

          <Divider />

          <ButtonContainer>
            <Button
              label='cancel'
              handleClick={() => toggleToolbarMenuVisibility('showAttachmentsMenu')}
              mr='0.5rem'
            />
            <Button
              primary
              label='Add Attachment'
              handleClick={() => {
                Promise
                  .resolve()
                  .then(() => this.handleAddAttachment())
                  .then(() => toggleToolbarMenuVisibility('showAttachmentsMenu'))
              }}
            />
          </ButtonContainer>
        </Window>
      </Wrapper>
    )
  }
}

AttachmentModal.propTypes = {
  editorRef: PropTypes.object,
  isBrandedDomain: PropTypes.bool,
  toggleToolbarMenuVisibility: PropTypes.func
}

export default AttachmentModal
