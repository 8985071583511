import Immutable, { fromJS } from 'immutable'
import { put, takeLatest, call } from 'redux-saga/effects'
import { subDays, differenceInDays } from 'date-fns'

import api, { messageFromError, apiBaseURI } from 'utils/api'
import { guessBrowserTimezone } from 'utils/dates'
import URLSearchParams from 'utils/browser/URLSearchParams'

import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchTeamReportsSuccess,
  fetchTeamReportsFailure,
  fetchMyReportsSuccess,
  fetchTeamReportsContrastPeriodSuccess,
  fetchTeamReportsContrastPeriodFailure,
  fetchMyReportsFailure,
  fetchSequenceReportsSuccess,
  fetchSequenceReportsFailure,
  fetchUserReportsSuccess,
  fetchUserReportsFailure,
  fetchBookedReportsSuccess,
  fetchBookedReportsFailure,
  fetchRepliedReportsSuccess,
  fetchRepliedReportsFailure,
  fetchContactsCreatedReportsSuccess,
  fetchContactsCreatedReportsFailure,
  fetchMessagedReportsSuccess,
  fetchMessagedReportsFailure,
  exportReportCSVFailure,
  exportReportCSVSuccess,
  fetchBounceRateReportsSuccess,
  fetchBounceRateReportsFailure,
  fetchOpenHoursReportsSuccess,
  fetchOpenHoursReportsFailure,
  fetchReplyHoursReportsSuccess,
  fetchReplyHoursReportsFailure,
  fetchContactTaskReportsSuccess,
  fetchContactTaskReportsFailure,
  fetchLeaderboardSuccess,
  fetchLeaderboardFailure,
  fetchLeaderboardContrastSuccess,
  fetchLeaderboardContrastFailure,
  fetchLeaderboardGroupsSuccess,
  fetchLeaderboardGroupsFailure,
  fetchLeaderboardGroupsContrastSuccess,
  fetchLeaderboardGroupsContrastFailure,
  fetchPersonalizedMessagesReportsSuccess,
  fetchPersonalizedMessagesReportsFailure,
  fetchStepPerformanceSuccess,
  fetchStepPerformanceFailure,
  fetchSequencesReportsSuccess,
  fetchSequencesReportsFailure,
  fetchBounceReasonSuccess,
  fetchBounceReasonFailure
} from './actions'
import {
  FETCH_TEAM_REPORTS_REQUEST,
  FETCH_USER_REPORTS_REQUEST,
  FETCH_SEQUENCE_REPORTS_REQUEST,
  FETCH_MY_REPORTS_REQUEST,
  FETCH_BOOKED_REPORTS_REQUEST,
  FETCH_REPLIED_REPORTS_REQUEST,
  FETCH_CONTACTS_REPORTS_REQUEST,
  FETCH_MESSAGED_REPORTS_REQUEST,
  EXPORT_REPORT_CSV_REQUEST,
  FETCH_TEAM_REPORTS_CONTRAST_PERIOD_REQUEST,
  FETCH_BOUNCE_RATE_REPORT_REQUEST,
  FETCH_OPEN_HOURS_REQUEST,
  FETCH_REPLY_HOURS_REQUEST,
  FETCH_CONTACT_TASK_REPORTS_REQUEST,
  TEAM_REPORTS,
  FETCH_LEADERBOARD_REQUEST,
  FETCH_LEADERBOARD_CONTRAST_REQUEST,
  FETCH_LEADERBOARD_GROUPS_REQUEST,
  FETCH_LEADERBOARD_GROUPS_CONTRAST_REQUEST,
  FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST,
  FETCH_STEP_PERFORMANCE_REQUEST,
  FETCH_SEQUENCES_REPORTS_REQUEST,
  FETCH_BOUNCE_REASON_REQUEST
} from './constants'

const get = (url, query) => api.get(url, {
  params: query,
  timeout: 60000
})

export function * fetchTeamReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    reports = TEAM_REPORTS
  } = actions

  const dateParams = { start, end, reports }

  try {
    const resp = yield call(get, '/reports/team', dateParams)
    const reports = fromJS(resp.data)

    yield put(fetchTeamReportsSuccess(reports))
  } catch (err) {
    yield put(fetchTeamReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchTeamReportsContrastPeriod (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    reports = TEAM_REPORTS
  } = actions
  const dayDiff = differenceInDays(end, start)
  const params = {
    start: subDays(start, dayDiff).toISOString(),
    end: subDays(start, 1).toISOString(),
    reports
  }
  try {
    const response = yield call(get, '/reports/team', params)
    const reports = fromJS(response.data)
    yield put(fetchTeamReportsContrastPeriodSuccess(reports))
  } catch (err) {
    yield put(fetchTeamReportsContrastPeriodFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchPersonalizedTeamReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    reports = TEAM_REPORTS
  } = actions

  const params = { start, end, reports, version: 2 }

  try {
    const resp = yield call(get, '/reports/team', params)
    const reports = fromJS(resp.data.reports)

    yield put(fetchPersonalizedMessagesReportsSuccess(reports))
  } catch (err) {
    yield put(fetchPersonalizedMessagesReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchLeaderboard (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end
  } = actions

  const params = { start, end }

  try {
    const resp = yield call(get, '/reports/leaderboard', params)
    const reports = fromJS(resp.data)

    yield put(fetchLeaderboardSuccess(reports))
  } catch (err) {
    yield put(fetchLeaderboardFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchLeaderboardContrast (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end
  } = actions
  const dayDiff = differenceInDays(end, start)
  const params = {
    start: subDays(start, dayDiff).toISOString(),
    end: subDays(start, 1).toISOString()
  }
  try {
    const response = yield call(get, '/reports/leaderboard', params)
    const reports = fromJS(response.data)
    yield put(fetchLeaderboardContrastSuccess(reports))
  } catch (err) {
    yield put(fetchLeaderboardContrastFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchLeaderboardGroups (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end
  } = actions

  const params = { start, end, version: 2 }

  try {
    const resp = yield call(get, '/reports/leaderboard', params)
    const reports = fromJS(resp.data.groups)
    yield put(fetchLeaderboardGroupsSuccess(reports))
  } catch (err) {
    yield put(fetchLeaderboardGroupsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchLeaderboardGroupsContrast (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end
  } = actions
  const dayDiff = differenceInDays(end, start)
  const params = {
    start: subDays(start, dayDiff).toISOString(),
    end: subDays(start, 1).toISOString(),
    version: 2
  }
  try {
    const response = yield call(get, '/reports/leaderboard', params)
    const reports = fromJS(response.data.groups)
    yield put(fetchLeaderboardGroupsContrastSuccess(reports))
  } catch (err) {
    yield put(fetchLeaderboardGroupsContrastFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchUserReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    userId,
    reports = TEAM_REPORTS
  } = actions
  const params = {
    start,
    end,
    reports
  }
  try {
    const response = yield call(get, `/reports/team/users/${userId}`, params)

    const reports = fromJS(response.data)

    yield put(fetchUserReportsSuccess({
      reports
    }))
  } catch (err) {
    yield put(fetchUserReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchSequenceReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    sequenceIds,
    reports = TEAM_REPORTS
  } = actions
  const params = {
    start,
    end,
    reports,
    campagins: sequenceIds
  }
  try {
    const response = yield call(get, '/reports/campaigns/', params)
    const reports = fromJS(response.data)

    yield put(fetchSequenceReportsSuccess({
      reports
    }))
  } catch (err) {
    yield put(fetchSequenceReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchMyReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    reports = TEAM_REPORTS
  } = actions
  const params = {
    start,
    end,
    reports
  }
  try {
    const response = yield call(get, '/reports/me', params)
    const reports = fromJS(response.data)

    yield put(fetchMyReportsSuccess({
      reports
    }))
  } catch (err) {
    yield put(fetchMyReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchBookedReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    campaigns,
    users,
    groups,
    movingAvgWindow,
    all,
    tz = guessBrowserTimezone()
  } = actions

  const params = (all)
    ? { start, end, campagins: [], users: [], groups: [], moving_average: movingAvgWindow, all, tz }
    : { start, end, campaigns, users, groups, moving_average: movingAvgWindow, tz }

  try {
    const response = yield call(get, '/reports/booked', params)
    const reports = fromJS(response.data)
    yield put(fetchBookedReportsSuccess({ reports }))
  } catch (err) {
    yield put(fetchBookedReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchRepliedReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    campaigns,
    users,
    groups,
    movingAvgWindow,
    all,
    tz = guessBrowserTimezone()
  } = actions

  const params = (all)
    ? { start, end, campagins: [], users: [], groups: [], moving_average: movingAvgWindow, all, tz }
    : { start, end, campaigns, users, groups, moving_average: movingAvgWindow, tz }

  try {
    const response = yield call(get, '/reports/replied', params)
    const reports = fromJS(response.data)
    yield put(fetchRepliedReportsSuccess({ reports }))
  } catch (err) {
    yield put(fetchRepliedReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchMessagedReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    campaigns,
    users,
    groups,
    movingAvgWindow,
    all,
    tz = guessBrowserTimezone()
  } = actions

  const params = (all)
    ? { start, end, campagins: [], users: [], groups: [], moving_average: movingAvgWindow, all, tz }
    : { start, end, campaigns, users, groups, moving_average: movingAvgWindow, tz }

  try {
    const response = yield call(get, '/reports/messaged', params)
    const reports = fromJS(response.data)
    yield put(fetchMessagedReportsSuccess({ reports }))
  } catch (err) {
    yield put(fetchMessagedReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchContactsCreatedReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    campaigns,
    users,
    groups,
    movingAvgWindow,
    all,
    tz = guessBrowserTimezone()
  } = actions

  const params = (all)
    ? { start, end, campagins: [], users: [], groups: [], moving_average: movingAvgWindow, all, tz }
    : { start, end, campaigns, users, groups, moving_average: movingAvgWindow, tz }

  try {
    const response = yield call(get, '/reports/created', params)
    const reports = fromJS(response.data)
    yield put(fetchContactsCreatedReportsSuccess({ reports }))
  } catch (err) {
    yield put(fetchContactsCreatedReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * exportReportCSV (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    reportType,
    end,
    campaigns,
    users,
    groups,
    all,
    tz = guessBrowserTimezone()
  } = actions

  // default `url` and `param` arguments
  let url = `/reports/${reportType}/csv`
  const params = (all)
    ? { reportType, start, end, campagins: [], users: [], groups: [], all, tz }
    : { reportType, start, end, campaigns, users, groups, tz }

  switch (reportType) {
    // call updated csv endpoint if trying to extract leaderboard report
    case 'leaderboard': {
      url = '/reports/team/csv'
      params.version = 2
      break
    }

    case 'created':
    case 'messaged': {
      params.total_type = 'campaigns'
      break
    }
  }

  const searchParams = new URLSearchParams(params)

  try {
    yield put(setNotification({
      type: NOTIFICATION_TYPES.LOADING,
      message: 'Exporting your report...'
    }))

    let message

    if (reportType === 'leaderboard') {
      const response = yield call(get, url, params)
      const type = fromJS(response.data.type)
      const downloadLink = fromJS(response.data.url)

      yield put(exportReportCSVSuccess({
        downloadLink
      }))

      if (type === 'email') {
        message = 'Check your email in a few minutes for a link to download the export'
      } else {
        message = 'Exported CSV of report'
      }
    } else {
      window.location = `${apiBaseURI}/api${url}?${searchParams.toString()}`
      message = 'Export successful'
    }

    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message
    }))
  } catch (err) {
    yield put(exportReportCSVFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchBounceRateReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    user = 'team',
    end
  } = actions

  const params = { start, end, reports: ['messages_bounced'], version: '2' }

  try {
    const resp = yield call(get, `reports/${user}`, params)
    const reports = fromJS(resp.data)
    yield put(fetchBounceRateReportsSuccess({ reports }))
  } catch (err) {
    yield put(fetchBounceRateReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

function * getLegacyReportRequest (actions, reports) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    user,
    sequence
  } = actions

  let endpoint = '/reports/team'

  const params = {
    start,
    end,
    reports
  }

  if (user) {
    if (user === 'me') {
      endpoint = '/reports/me'
    } else {
      params.users = [user]
    }
  } else if (sequence) {
    endpoint = '/reports/campaigns'
    params.campaigns = [sequence]
  }

  const response = yield call(get, endpoint, params)
  const data = fromJS(response.data)

  if (user && user === 'me') {
    return Immutable.Map({
      me: data
    })
  } else if (sequence) {
    return Immutable.Map({
      [sequence]: data
    })
  }

  return data
}

export function * fetchOpenHoursReports (actions) {
  try {
    const reports = yield getLegacyReportRequest(actions, 'opens')
    yield put(fetchOpenHoursReportsSuccess({ reports }))
  } catch (err) {
    yield put(fetchOpenHoursReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      messages: messageFromError(err)
    }))
  }
}

export function * fetchReplyHoursReports (actions) {
  try {
    const reports = yield getLegacyReportRequest(actions, 'replies')
    yield put(fetchReplyHoursReportsSuccess({ reports }))
  } catch (err) {
    yield put(fetchReplyHoursReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      messages: messageFromError(err)
    }))
  }
}

export function * fetchContactTaskReports (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    campaigns,
    users,
    groups,
    movingAvgWindow,
    all,
    tz = guessBrowserTimezone()
  } = actions

  const params = (all)
    ? { start, end, campagins: [], users: [], groups: [], moving_average: movingAvgWindow, all, tz }
    : { start, end, campaigns, users, groups, moving_average: movingAvgWindow, tz }

  try {
    const resp = yield call(get, 'reports/tasks', params)
    const reports = fromJS(resp.data)
    yield put(fetchContactTaskReportsSuccess({ reports }))
  } catch (err) {
    yield put(fetchContactTaskReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchStepPerformance (actions) {
  const {
    start = subDays(new Date(), 30).toISOString(),
    end,
    campaigns,
    users,
    timezone = guessBrowserTimezone()
  } = actions

  const params = {
    start,
    end,
    campaigns,
    users,
    timezone
  }

  try {
    const resp = yield call(get, '/reports/steps', params)
    const report = fromJS(resp.data)

    yield put(fetchStepPerformanceSuccess(report))
  } catch (err) {
    yield put(fetchStepPerformanceFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchSequencesReports (actions) {
  const { timezone = guessBrowserTimezone() } = actions

  try {
    const resp = yield call(get, '/reports/campaigns_stats', { timezone })
    const reports = fromJS(resp.data)

    yield put(fetchSequencesReportsSuccess(reports))
  } catch (err) {
    yield put(fetchSequencesReportsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchBounceReason (actions) {
  const { start, end, timezone = guessBrowserTimezone() } = actions

  try {
    const resp = yield call(get, '/reports/bounced', {
      start,
      end,
      timezone
    })
    const reports = fromJS(resp.data)

    yield put(fetchBounceReasonSuccess(reports))
  } catch (err) {
    yield put(fetchBounceReasonFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

function * fetchTeamReportsRequest () {
  yield takeLatest(FETCH_TEAM_REPORTS_REQUEST, fetchTeamReports)
}

function * fetchTeamReportsContrastPeriodRequest () {
  yield takeLatest(FETCH_TEAM_REPORTS_CONTRAST_PERIOD_REQUEST, fetchTeamReportsContrastPeriod)
}

function * fetchPersonalizedTeamReportsRequest () {
  yield takeLatest(FETCH_PERSONALIZED_MESSAGES_REPORTS_REQUEST, fetchPersonalizedTeamReports)
}

function * fetchLeaderboardRequest () {
  yield takeLatest(FETCH_LEADERBOARD_REQUEST, fetchLeaderboard)
}

function * fetchLeaderboardContrastRequest () {
  yield takeLatest(FETCH_LEADERBOARD_CONTRAST_REQUEST, fetchLeaderboardContrast)
}

function * fetchLeaderboardGroupsRequest () {
  yield takeLatest(FETCH_LEADERBOARD_GROUPS_REQUEST, fetchLeaderboardGroups)
}

function * fetchLeaderboardGroupsContrastRequest () {
  yield takeLatest(FETCH_LEADERBOARD_GROUPS_CONTRAST_REQUEST, fetchLeaderboardGroupsContrast)
}

function * fetchUserReportsRequest () {
  yield takeLatest(FETCH_USER_REPORTS_REQUEST, fetchUserReports)
}

function * fetchSequenceReportsRequest () {
  yield takeLatest(FETCH_SEQUENCE_REPORTS_REQUEST, fetchSequenceReports)
}

function * fetchMyReportsRequest () {
  yield takeLatest(FETCH_MY_REPORTS_REQUEST, fetchMyReports)
}

function * fetchBookedReportsRequest () {
  yield takeLatest(FETCH_BOOKED_REPORTS_REQUEST, fetchBookedReports)
}

function * fetchRepliedReportsRequest () {
  yield takeLatest(FETCH_REPLIED_REPORTS_REQUEST, fetchRepliedReports)
}

function * fetchMessagedReportsRequest () {
  yield takeLatest(FETCH_MESSAGED_REPORTS_REQUEST, fetchMessagedReports)
}

function * fetchContactsCreatedReportsRequest () {
  yield takeLatest(FETCH_CONTACTS_REPORTS_REQUEST, fetchContactsCreatedReports)
}

function * exportReportCSVRequest () {
  yield takeLatest(EXPORT_REPORT_CSV_REQUEST, exportReportCSV)
}
function * fetchBounceRateReportsRequest () {
  yield takeLatest(FETCH_BOUNCE_RATE_REPORT_REQUEST, fetchBounceRateReports)
}

function * fetchOpenHoursReportsRequest () {
  yield takeLatest(FETCH_OPEN_HOURS_REQUEST, fetchOpenHoursReports)
}

function * fetchReplyHoursReportsRequest () {
  yield takeLatest(FETCH_REPLY_HOURS_REQUEST, fetchReplyHoursReports)
}

function * fetchContactTaskReportsRequest () {
  yield takeLatest(FETCH_CONTACT_TASK_REPORTS_REQUEST, fetchContactTaskReports)
}

function * fetchStepPerformanceRequest () {
  yield takeLatest(FETCH_STEP_PERFORMANCE_REQUEST, fetchStepPerformance)
}

function * fetchSequencesReportsRequest () {
  yield takeLatest(FETCH_SEQUENCES_REPORTS_REQUEST, fetchSequencesReports)
}

function * fetchBounceReasonRequest () {
  yield takeLatest(FETCH_BOUNCE_REASON_REQUEST, fetchBounceReason)
}

export default [
  fetchTeamReportsRequest,
  fetchTeamReportsContrastPeriodRequest,
  fetchPersonalizedTeamReportsRequest,
  fetchUserReportsRequest,
  fetchSequenceReportsRequest,
  fetchMyReportsRequest,
  fetchBookedReportsRequest,
  fetchRepliedReportsRequest,
  fetchContactsCreatedReportsRequest,
  fetchMessagedReportsRequest,
  exportReportCSVRequest,
  fetchBounceRateReportsRequest,
  fetchOpenHoursReportsRequest,
  fetchReplyHoursReportsRequest,
  fetchContactTaskReportsRequest,
  fetchLeaderboardRequest,
  fetchLeaderboardContrastRequest,
  fetchLeaderboardGroupsRequest,
  fetchLeaderboardGroupsContrastRequest,
  fetchStepPerformanceRequest,
  fetchSequencesReportsRequest,
  fetchBounceReasonRequest
]
