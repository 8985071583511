import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Tooltip } from 'react-tippy'
import Popper from 'components/Popper'
import ToolbarButton from 'components/ToolbarButton'
import { Menu, MenuHeader, MenuItem } from 'elements/Menu'
import DropdownArrow from 'elements/DropdownArrow'
import Label from 'elements/Label'
import { fadeInRight } from 'utils/animations'
import { contactErrorToString } from 'utils/keyMapping'

const Wrapper = styled.div`
  color: ${props => props.theme.titleColor};
  width: 100%;
  height: 68px;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.gray10};
  width: ${props => props.width};
  padding: ${props => `${props.theme.padding} 2rem`};
`

const SelectionTitle = styled.h3`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.header};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  padding-top: 0;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
`

const ButtonContainer = styled.div`
  animation: ${fadeInRight} .2s linear 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CaretContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90%;
  margin-top: auto;
  margin-bottom: auto;
  padding: 0 0.5rem;
  padding-top: 3px;
`

const LabelWrapper = styled.div`
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeInRight} .2s linear 1;
`

class Toolbar extends Component {
  render () {
    const {
      title,
      style,
      selectedContacts = [],
      allSelected,
      onClearError,
      onRemove,
      onFilterContacts,
      stats,
      filter
    } = this.props

    let selectedButtons

    if (selectedContacts.length > 0 || allSelected) {
      selectedButtons = [
        <ToolbarButton
          tooltip='Ignore contact errors'
          icon={['fal', 'eye-slash']}
          onClick={onClearError}
          key={1}
        />,
        <ToolbarButton
          tooltip='Remove contacts'
          icon={['fal', 'trash-alt']}
          onClick={onRemove}
          key={2}
        />
      ]
    }

    const menuItems = stats.keySeq().toArray().map((s) => {
      const itemTitle = `${contactErrorToString(s)} (${stats.get(s)})`
      return (
        <MenuItem key={s} onClick={() => { onFilterContacts(s) }}>
          {itemTitle}
        </MenuItem>
      )
    })

    return (
      <Wrapper style={style}>
        <Container>
          <ButtonContainer>
            <SelectionTitle>{title}</SelectionTitle>
            <Popper
              position='bottom-start'
              trigger={
                <Tooltip
                  style={{ display: 'block' }}
                  key='filter'
                  title='Filter contacts by error'
                  position='top'
                  trigger='mouseenter'
                  arrow
                >
                  <CaretContainer>
                    <DropdownArrow />
                  </CaretContainer>
                </Tooltip>
              }
            >
              {close => (
                <Menu onClick={close}>
                  <MenuHeader>
                    Filter Contacts
                  </MenuHeader>
                  {filter &&
                    <MenuItem onClick={() => { onFilterContacts(null) }}>
                      Clear Filter
                    </MenuItem>}
                  {menuItems}
                </Menu>
              )}
            </Popper>
          </ButtonContainer>
          {selectedButtons &&
            <ButtonContainer>{selectedButtons}</ButtonContainer>}
          {selectedContacts.length > 0 && !allSelected &&
            <LabelWrapper>
              <Label fontWeight='600' color='darkBlue'>{selectedContacts.length} selected</Label>
            </LabelWrapper>}
        </Container>
      </Wrapper>
    )
  }
}

Toolbar.propTypes = {
  title: PropTypes.string,
  stats: PropTypes.object,
  style: PropTypes.object,
  filter: PropTypes.string,
  selectedContacts: PropTypes.array,
  allSelected: PropTypes.bool,
  onClearError: PropTypes.func,
  onRemove: PropTypes.func,
  onFilterContacts: PropTypes.func
}

export default withTheme(Toolbar)
