/**
 * Purpose: Takes a string formatted as a year and month and generates a formatted string
 *          with the name of the month and the year.
 *
 * @param {String} yearMonthStr
 * @return {String} `{month} {year}` -- Ex:` May 2020`
 */
export function generateFormattedMonthHeader (yearMonthStr) {
  const year = parseInt(yearMonthStr.substring(0, 4))
  const month = parseInt(yearMonthStr.substring(4, 6))
  const day = 1

  const date = new Date(year, month, day)
  const monthStr = date.toLocaleDateString('default', { month: 'long' })
  return `${monthStr} ${year}`
}

export function generateFormattedWeekHeader (yearWeekMonthStr) {
  // TODO: create formatted headers for week ranges
}

export function generateFormattedDateFromUTCString (utcString) {
  const dateObj = new Date(Date.parse(utcString))

  const date = dateObj.getDate()
  const month = dateObj.toLocaleString('default', { month: 'long' })
  const year = dateObj.getFullYear()

  return `${month} ${date}, ${year}`
}

export function generateFormattedDateFromYearMonthDateString (yearMonthDayStr) {
  const year = yearMonthDayStr.substring(0, 4)
  const month = yearMonthDayStr.substring(4, 6)
  const date = yearMonthDayStr.substring(6, 8)

  const dateMonthYear = `${month}-${date}-${year}`
  const dateObj = new Date(Date.parse(dateMonthYear))
  const formattedMonth = dateObj.toLocaleString('default', { month: 'long' })

  return `${formattedMonth} ${date}, ${year}`
}

export function getFirstDayOfMonth (date) {
  const year = date.getFullYear()
  const month = date.getMonth()

  const firstDayOfMonth = new Date(year, month, 1)
  return firstDayOfMonth
}

export function getLastDayOfMonth (date) {
  const year = date.getFullYear()
  const month = date.getMonth()

  const lastDayOfMonth = new Date(year, month + 1, 0)
  return lastDayOfMonth
}

export const DATE_RANGE = {
  WEEK: 'week',
  DAY: 'day'
}
