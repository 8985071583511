import format from 'date-fns/format'

export const constructContactStatus = (contact) => {
  const dateFormat = 'MMM Do [at] h:mma'

  switch (contact.get('state')) {
    case 'unsubscribed':
      return 'Unsubscribed'
    case 'bounced':
      return 'Bounced'
    case 'booked':
      return `Booked on ${format(contact.get('booked_at'), dateFormat)}`
    case 'replied':
      return `Replied on ${format(contact.get('replied_at'), dateFormat)}`
    case 'opened':
    case 'clicked':
    case 'messaged':
      return `Messaged on ${format(contact.get('last_messaged_at'), dateFormat)}`
    case 'reached':
      return `Task done on ${format(contact.get('last_step_at'), dateFormat)}`
    default:
      return `Added on ${format(contact.get('created_at'), dateFormat)}`
  }
}
