import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import Immutable from 'immutable'
import NumberFormat from 'react-number-format'
import {
  Column,
  AutoSizer,
  Table,
  SortIndicator
} from 'react-virtualized'

import Loading from 'components/Loading'
import { FullContainer } from 'containers/Reports/styles'

import { STEP_REPORTS } from 'containers/Reports/constants'

const TableContainer = styled.div`
  max-width: 1000px;

  .ReactVirtualized__Table__headerColumn:first-of-type,
  .ReactVirtualized__Table__rowColumn:first-of-type {
    margin-left: 1.5rem;
  }

  .ReactVirtualized__Table__headerRow {
    border: 1px solid ${props => props.theme.colors.gray30};
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.darkBlue};
    font-size: 15px;
    text-transform: capitalize;

    cursor: pointer;
    user-select: none;
  }

  .ReactVirtualized__Table__row {
    background-color: white;
    padding: 0;
    color: ${props => props.theme.colors.darkBlue};

    border: 1px solid ${props => props.theme.colors.gray30};
    border-bottom: 0;

    &:first-child {
      border-top: 0;
    }

    &:last-child {
      border-bottom: 1px solid ${props => props.theme.colors.gray30};
    }
  }
`

const ColumnGroup = styled.div`
  color: ${props => props.theme.colors.darkGray};
  font: ${props => props.theme.fonts.normal};
`

const RowGroup = styled.div`
  display: flex;
  align-items: center;
`

const Cell = styled.span`
  color: ${props => props.theme.colors.darkGray};
  font-size: 18px;
  font-weight: 300;

  ${props => props.empty && css`
    color: ${props => props.theme.colors.gray60};
  `}
`

const Badge = styled.span`
  background-color: ${props => props.theme.background};
  border-radius: 3px;
  font-size: .8rem;
  margin-left: 5px;
  padding: 0.5px 4px;
`

class StepPerformanceReport extends React.Component {
  constructor (props) {
    super(props)
    this.emptyCell = '-'
    this.state = {}
  }

  formatTableData = () => {
    const { stepPerformance } = this.props

    let { sortBy, sortDirection } = this.state
    const tableRows = []
    const columnNames = STEP_REPORTS

    if (stepPerformance?.get('data')) {
      stepPerformance
        .get('data')
        .forEach((val, key) => {
          if (key !== 'totals') {
            const newRow = { step: parseInt(key) + 1 }

            columnNames.forEach((col) => {
              const count = val.getIn([col, 'total', 'count'])
              const total = val.getIn([col, 'total'])
              newRow[`report_${col}`] = count !== undefined ? count : total
            })

            tableRows.push(newRow)
            return tableRows
          }
        })

      if (!tableRows.length) {
        const newRow = { step: this.emptyCell }

        columnNames.forEach((col) => {
          newRow[`report_${col}`] = this.emptyCell
        })

        tableRows.push(newRow)
      }
    }

    if (sortBy) {
      if (sortBy === 'step') {
        sortBy = 'step'
      }

      let sort = Immutable.List(tableRows)
      sort = sort.sortBy(row => row[sortBy])

      if (sortDirection === 'DESC') {
        sort = sort.reverse()
      }

      return sort.toArray()
    }
    return tableRows
  }

  _numberCellRenderer = ({ dataKey, rowData }) => {
    const reportData = rowData[dataKey] || 0
    const total = rowData.report_messages
    const showPct = dataKey !== 'report_messages' && total > 0

    if (reportData === this.emptyCell) {
      return (
        <ColumnGroup>
          <RowGroup>
            <Cell empty={reportData === this.emptyCell}>{reportData}</Cell>
          </RowGroup>
        </ColumnGroup>
      )
    }

    return (
      <ColumnGroup>
        <RowGroup>
          <NumberFormat
            value={reportData}
            displayType='text'
            thousandSeparator=','
            style={{ fontWeight: '300', fontSize: '18px' }}
          />
          {showPct &&
            <Badge>
              <NumberFormat
                value={Math.abs(reportData / total * 100)}
                suffix='%'
                displayType='text'
                thousandSeparator=','
                decimalScale='1'
              />
            </Badge>}
        </RowGroup>
      </ColumnGroup>
    )
  }

  _stepCellRenderer = ({ cellData }) => {
    return (
      <Cell empty={cellData === this.emptyCell}>{cellData}</Cell>
    )
  }

  _headerRenderer ({ label, dataKey, sortBy, sortDirection }) {
    return (
      <div>
        {label}
        {sortBy === dataKey &&
          <SortIndicator sortDirection={sortDirection} />}
      </div>
    )
  }

  sort = ({ sortBy, sortDirection }) => {
    this.setState({
      sortBy,
      sortDirection
    })
  }

  render () {
    const { stepPerformance } = this.props
    const { sortBy, sortDirection } = this.state

    if (!stepPerformance || stepPerformance.get('loading')) {
      return <Loading width='100%' />
    }

    const columnWidth = 100
    const tableRows = this.formatTableData()

    return (
      <FullContainer>
        <TableContainer>
          <AutoSizer>
            {({ height, width }) => {
              return (
                <Table
                  width={width}
                  height={height}
                  headerHeight={50}
                  rowHeight={70}
                  rowCount={tableRows.length}
                  rowGetter={({ index }) => tableRows[index]}
                  sort={this.sort}
                  sortBy={sortBy}
                  sortDirection={sortDirection}
                >
                  <Column
                    key='step'
                    dataKey='step'
                    label='Step'
                    width={columnWidth}
                    flexGrow={5}
                    cellRenderer={this._stepCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Sent'
                    dataKey='report_messages'
                    label='Sent'
                    width={columnWidth}
                    flexGrow={1}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Opened'
                    dataKey='report_messages_opened'
                    label='Opened'
                    width={columnWidth}
                    flexGrow={1}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Replied'
                    dataKey='report_messages_replied'
                    label='Replied'
                    width={columnWidth}
                    flexGrow={1}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />

                  <Column
                    key='Bounced'
                    dataKey='report_messages_bounced'
                    label='Bounced'
                    width={columnWidth}
                    flexGrow={1}
                    cellRenderer={this._numberCellRenderer}
                    headerRenderer={this._headerRenderer}
                  />
                </Table>
              )
            }}
          </AutoSizer>
        </TableContainer>
      </FullContainer>
    )
  }
}

StepPerformanceReport.propTypes = {
  actions: PropTypes.object,
  theme: PropTypes.object,
  stepPerformance: PropTypes.object
}

export default StepPerformanceReport
