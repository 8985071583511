import React, { useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { format } from 'date-fns'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  height: 35px;
`

function DateCellRenderer ({ value, ...props }) {
  useEffect(() => {
    if (props?.cellProperties?.className) {
      props.TD.className = props.cellProperties.className
    }
  })

  return (
    <Wrapper>
      {value ? format(value, 'MMM D, YYYY') : null}
    </Wrapper>
  )
}

DateCellRenderer.propTypes = {
  value: PropTypes.string,
  cellProperties: PropTypes.object,
  TD: PropTypes.object
}

export default DateCellRenderer
