import { all, fork } from 'redux-saga/effects'
import appSagas from './containers/App/sagas'
import billingSagas from './containers/Billing/sagas'
import navigationSagas from './containers/Navigation/sagas'
import dashboardSagas from './containers/Dashboard/sagas'
import sequenceSagas from './containers/Sequence/sagas'
import settingsSagas from './containers/Settings/sagas'
import integrationSagas from './containers/Integrations/sagas'
import teamSagas from './containers/Team/sagas'
import reportsSagas from './containers/Reports/sagas'
import onboardingSagas from './containers/Onboarding/sagas'
import taskSagas from './containers/Tasks/sagas'
import templatesSagas from './containers/Templates/sagas'
import talentPoolSagas from './containers/TalentPool/sagas'

// Combine sagas
const sagas = [
  ...appSagas,
  ...billingSagas,
  ...navigationSagas,
  ...dashboardSagas,
  ...sequenceSagas,
  ...settingsSagas,
  ...integrationSagas,
  ...teamSagas,
  ...onboardingSagas,
  ...taskSagas,
  ...templatesSagas,
  ...reportsSagas,
  ...talentPoolSagas
]

// Start saga watchers
export default function * root () {
  yield all(sagas.map(saga => fork(saga)))
}
