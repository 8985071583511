import { fromJS } from 'immutable'
import {
  UPDATE_SESSION_REQUEST,
  UPDATE_SESSION_SUCCESS,
  UPDATE_SESSION_FAILURE
} from 'containers/App/constants'
import {
  FETCH_SESSION_ADDRESSES_REQUEST,
  FETCH_SESSION_ADDRESSES_SUCCESS,
  FETCH_SESSION_ADDRESSES_FAILURE,

  FETCH_TEAM_SETTINGS_REQUEST,
  FETCH_TEAM_SETTINGS_SUCCESS,
  FETCH_TEAM_SETTINGS_FAILURE,

  FETCH_BLOCKLIST_REQUEST,
  FETCH_BLOCKLIST_SUCCESS,
  FETCH_BLOCKLIST_FAILURE,

  FETCH_TEAM_BLOCKLIST_REQUEST,
  FETCH_TEAM_BLOCKLIST_SUCCESS,
  FETCH_TEAM_BLOCKLIST_FAILURE,

  UPDATE_BLOCKLIST_SUCCESS,
  UPDATE_TEAM_BLOCKLIST_SUCCESS,

  UPDATE_TEAM_SETTINGS_SUCCESS,

  FETCH_TEAM_DOMAIN_REQUEST,
  FETCH_TEAM_DOMAIN_SUCCESS,
  FETCH_TEAM_DOMAIN_FAILURE,

  FETCH_CONFIG_REQUEST,
  FETCH_CONFIG_SUCCESS,
  FETCH_CONFIG_FAILURE,

  FETCH_CALENDARS_REQUEST,
  FETCH_CALENDARS_SUCCESS,
  FETCH_CALENDARS_FAILURE,

  // UPDATE_CALENDARS_REQUEST,
  UPDATE_CALENDARS_SUCCESS,
  UPDATE_CALENDARS_FAILURE,

  FETCH_TEAM_LOGS_REQUEST,
  FETCH_TEAM_LOGS_SUCCESS,
  FETCH_TEAM_LOGS_FAILURE,

  FETCH_PURGE_EMAIL_COUNT_REQUEST,
  FETCH_PURGE_EMAIL_COUNT_SUCCESS,
  FETCH_PURGE_EMAIL_COUNT_FAILURE,

  SHOW_PURGE_MODAL,
  HIDE_PURGE_MODAL,

  DELETE_PURGE_EMAIL_REQUEST,
  DELETE_PURGE_EMAIL_SUCCESS,
  DELETE_PURGE_EMAIL_FAILURE
} from './constants'

const initialArray = {
  loading: false,
  data: [],
  error: null
}

const initialObject = {
  loading: false,
  data: null,
  error: null
}

const initialState = fromJS({
  addresses: initialArray,
  updateSession: initialObject,
  teamSettings: initialObject,
  teamDomain: initialObject,
  blocklist: initialArray,
  teamBlocklist: initialArray,
  config: initialObject,
  calendars: initialArray,
  teamLogs: initialArray,
  purgeEmailCount: initialObject,
  showPurgeModal: false
})

function appReducer (state = initialState, action) {
  switch (action.type) {
    // Session Addresses
    case FETCH_SESSION_ADDRESSES_REQUEST:
      return state.set('addresses', state.get('addresses').merge({ loading: true }))
    case FETCH_SESSION_ADDRESSES_SUCCESS:
      return state.set('addresses', fromJS({
        data: action.payload.addresses,
        loading: false,
        error: null
      }))
    case FETCH_SESSION_ADDRESSES_FAILURE:
      return state.set('addresses', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Update Settings Request
    case UPDATE_SESSION_REQUEST:
      return state.set('updateSession', state.get('updateSession').merge({ loading: true }))
    case UPDATE_SESSION_SUCCESS:
      return state.set('updateSession', fromJS({
        data: action.payload.session,
        loading: false,
        error: null
      }))
    case UPDATE_SESSION_FAILURE:
      return state.set('updateSession', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch Team Settings
    case FETCH_TEAM_SETTINGS_REQUEST:
      return state.set('teamSettings', state.get('teamSettings').merge({ loading: true }))
    case FETCH_TEAM_SETTINGS_SUCCESS:
    case UPDATE_TEAM_SETTINGS_SUCCESS:
      return state.set('teamSettings', fromJS({
        data: action.payload.settings,
        loading: false,
        error: null
      }))
    case FETCH_TEAM_SETTINGS_FAILURE:
      return state.set('teamSettings', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch Blocklist Settings
    case FETCH_BLOCKLIST_REQUEST:
      return state.set('blocklist', state.get('blocklist').merge({ loading: true }))
    case FETCH_BLOCKLIST_SUCCESS:
    case UPDATE_BLOCKLIST_SUCCESS:
      return state.set('blocklist', fromJS({
        data: action.payload.blocklist,
        loading: false,
        error: null
      }))
    case FETCH_BLOCKLIST_FAILURE:
      return state.set('blocklist', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch Team Blocklist Settings
    case FETCH_TEAM_BLOCKLIST_REQUEST:
      return state.set('teamBlocklist', state.get('teamBlocklist').merge({ loading: true }))
    case FETCH_TEAM_BLOCKLIST_SUCCESS:
    case UPDATE_TEAM_BLOCKLIST_SUCCESS:
      return state.set('teamBlocklist', fromJS({
        data: action.payload.blocklist,
        loading: false,
        error: null
      }))
    case FETCH_TEAM_BLOCKLIST_FAILURE:
      return state.set('teamBlocklist', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Fetch Team Domain
    case FETCH_TEAM_DOMAIN_REQUEST:
      return state.set('teamDomain', state.get('teamDomain').merge({ loading: true }))
    case FETCH_TEAM_DOMAIN_SUCCESS:
      return state.set('teamDomain', fromJS({
        data: action.payload.domain,
        loading: false,
        error: null
      }))
    case FETCH_TEAM_DOMAIN_FAILURE:
      return state.set('teamDomain', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case FETCH_CONFIG_REQUEST:
      return state.set('config', state.get('config').merge({ loading: true }))
    case FETCH_CONFIG_SUCCESS:
      return state.set('config', fromJS({
        data: action.payload.config,
        loading: false,
        error: null
      }))
    case FETCH_CONFIG_FAILURE:
      return state.set('config', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case FETCH_CALENDARS_REQUEST:
      return state.set('calendars', state.get('calendars').merge({ loading: true }))
    case FETCH_CALENDARS_SUCCESS:
    case UPDATE_CALENDARS_SUCCESS:
      return state.set('calendars', fromJS({
        data: action.payload.calendars,
        loading: false,
        error: null
      }))
    case FETCH_CALENDARS_FAILURE:
    case UPDATE_CALENDARS_FAILURE:
      return state.set('calendars', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    case FETCH_TEAM_LOGS_REQUEST:
      return state.set('teamLogs', state.get('teamLogs').merge({ loading: true }))
    case FETCH_TEAM_LOGS_SUCCESS:
      return state.set('teamLogs', fromJS({
        data: action.payload.teamLogs,
        loading: false,
        error: null
      }))
    case FETCH_TEAM_LOGS_FAILURE:
      return state.set('teamLogs', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case FETCH_PURGE_EMAIL_COUNT_REQUEST:
      return state.set('purgeEmailCount', state.get('purgeEmailCount').merge({ loading: true }))
    case FETCH_PURGE_EMAIL_COUNT_SUCCESS:
      return state.set('purgeEmailCount', fromJS({
        data: action.payload.purgeEmailCount,
        loading: false,
        error: null
      }))
    case FETCH_PURGE_EMAIL_COUNT_FAILURE:
      return state.set('purgeEmailCount', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    case SHOW_PURGE_MODAL:
      return state.set('showPurgeModal', true)
    case HIDE_PURGE_MODAL:
      return state.set('showPurgeModal', false)

    case DELETE_PURGE_EMAIL_REQUEST:
      return state
    case DELETE_PURGE_EMAIL_SUCCESS:
      return state.set('purgeEmailCount', fromJS({
        data: 0,
        loading: false,
        error: null
      }))
    case DELETE_PURGE_EMAIL_FAILURE:
      return state

    default:
      return state
  }
}

export default appReducer
