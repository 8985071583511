/* eslint-disable react/no-find-dom-node */
import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled, { withTheme, css } from 'styled-components'
import Avatar from 'react-avatar'
import { space, width } from 'styled-system'
import Immutable from 'immutable'
import { withRouter } from 'react-router'
import { CellMeasurer } from 'react-virtualized'
import Label from 'elements/Label'
import IconButton from 'components/IconButton'
import CompanyPreview from 'components/CompanyPreview'
import Checkbox from 'elements/Checkbox'

const Wrapper = styled.div`
  padding: 1rem 1.5rem;
  display: flex;
  flex-direction: column;
  width: 100%;
  border-left: solid 1px ${props => props.theme.colors.gray30};
  border-right: solid 1px ${props => props.theme.colors.gray30};

  ${props => !props.last && css`
    border-bottom: ${`solid 1px ${props.theme.background}`};
  `};

  ${props => props.last && css`
    border-bottom: ${`solid 1px ${props.theme.colors.gray30}`};
  `};

  ${props => !props.selected && css`
    background: ${props.theme.containerBackground};
    cursor: pointer;
  `};

  ${props => props.selected && css`
    background: ${props.theme.highlightBackground};
    cursor: auto;
  `};
`

const DetailContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 1rem;
  overflow: hidden;

  flex: ${props => props.flex};
`

const TaskTitle = styled.span`
  font: ${props => props.theme.fonts.formtitle};
  color: ${props => props.theme.titleColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  border-bottom: 1px solid transparent;
`

const TaskTitleLink = styled(TaskTitle)`
  cursor: pointer;

  &:hover {
    border-bottom: 1px solid ${props => props.theme.titleColor};
  }
`

const SequenceTitle = styled.span`
  font: ${props => props.theme.fonts.formtitle};
  color: ${props => props.theme.titleColor};
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  cursor: pointer;
  border-bottom: 1px solid transparent;
  text-align: right;

  &:hover {
    border-bottom: 1px solid ${props => props.theme.titleColor};
  }
`

const TaskSubtitle = styled(Label)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
`

const NameContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  ${space}
`

const FormDetail = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  ${width}
  ${space}
`

const IconContainer = styled.div`
  display: flex;
  margin-left: 0.25rem;
`

const StyledAvatar = styled(Avatar)`
  img {
    max-width: none!important;
  }
`

const CheckboxContainer = styled.div`
  margin-top: auto;
  margin-bottom: auto;
  padding-right: 0.75rem;
`

class TaskRow extends Component {
  constructor (props) {
    super(props)
    this.state = { hasMounted: false }
  }

  componentDidMount () {
    this.setState({ hasMounted: true })
  }

  componentDidUpdate () {
    const { selected, contactCompany, handleCellLoadingComplete } = this.props
    const { hasMounted } = this.state
    if (hasMounted && selected && !contactCompany.get('loading')) {
      const selectedCellHeight = ReactDOM.findDOMNode(this).clientHeight
      handleCellLoadingComplete(selectedCellHeight)
    } else if (hasMounted && selected && contactCompany.get('loading')) {
      const selectedCellHeight = ReactDOM.findDOMNode(this).clientHeight
      handleCellLoadingComplete(selectedCellHeight)
    }
  }

  handleCheck = () => {
    const {
      task,
      onChecked,
      listRef
    } = this.props
    onChecked(task.get('id'))
    if (listRef.current) {
      listRef.current.forceUpdateGrid()
    }
  }

  render () {
    const {
      theme,
      task,
      onClick,
      last = false,
      selected = false,
      contactCompany,
      selectedContact,
      style,
      parent,
      index,
      cache,
      rowKey,
      checkedContacts,
      onChecked
    } = this.props

    const contactId = task.get('id')
    const sequenceId = task.getIn(['_campaign', '_id'])
    const name = task.get('name')
    const title = task.getIn(['_campaign', 'title'])
    const step = task.get('next_step') + 1
    const photoUrl = task.get('image_url')

    // Company data
    const contactData = selectedContact.get('data') || Immutable.Map({})
    const isFreeEmail = contactData.get('is_free_email')

    const twitterLink = contactData.getIn(['social_urls', 'twitter'])
    const linkedInLink = contactData.getIn(['social_urls', 'linkedin'])
    const githubLink = contactData.getIn(['social_urls', 'github'])

    return (
      <CellMeasurer
        cache={cache}
        columnIndex={0}
        parent={parent}
        rowIndex={index}
        key={`cm_${rowKey}`}
      >
        {({ registerChild }) => (
          <Wrapper
            Key={`wrapper_${rowKey}`}
            ref={registerChild}
            onClick={onClick}
            last={last}
            selected={selected}
            style={style}
          >
            <FormDetail>
              {onChecked &&
                <CheckboxContainer>
                  <Checkbox
                    selected={checkedContacts.indexOf(contactId) >= 0}
                    handleClick={this.handleCheck}
                  />
                </CheckboxContainer>}
              <StyledAvatar
                maxInitials={2}
                src={photoUrl}
                name={name}
                size={38}
                textSizeRatio={2}
                round
                colors={theme.avatarColors}
              />
              <DetailContainer style={{ flex: '1 0 auto' }}>
                <NameContainer>
                  {!selected &&
                    <TaskTitle>
                      {name}
                    </TaskTitle>}
                  {selected &&
                    <TaskTitleLink
                      onClick={() => {
                        this.props.editContact(contactId)
                      }}
                    >
                      {name}
                    </TaskTitleLink>}
                  {!selectedContact.get('loading') &&
                    <IconContainer>
                      {selected && linkedInLink &&
                        <IconButton
                          onClick={() => {
                            window.open(linkedInLink)
                          }}
                          type={['fab', 'linkedin-in']}
                          marginLeft='0.25rem'
                          size={17}
                          iconSize='xs'
                          buttonColor={this.props.theme.colors.linkedin}
                          borderColor={this.props.theme.colors.linkedin}
                          iconColor='#FFF'
                        />}
                      {selected && githubLink &&
                        <IconButton
                          onClick={() => {
                            window.open(githubLink)
                          }}
                          type={['fab', 'github']}
                          marginLeft='0.25rem'
                          size={17}
                          iconSize='xs'
                          buttonColor={this.props.theme.colors.github}
                          borderColor={this.props.theme.colors.github}
                          iconColor='#FFF'
                        />}
                      {selected && twitterLink &&
                        <IconButton
                          onClick={() => {
                            window.open(twitterLink)
                          }}
                          type={['fab', 'github']}
                          marginLeft='0.25rem'
                          size={17}
                          iconSize='xs'
                          buttonColor={this.props.theme.colors.twitter}
                          borderColor={this.props.theme.colors.twitter}
                          iconColor='#FFF'
                        />}
                    </IconContainer>}
                </NameContainer>
                <TaskSubtitle>{task.get('email')}</TaskSubtitle>
              </DetailContainer>
              <DetailContainer>
                <SequenceTitle
                  onClick={() => {
                    this.props.router.push(`/sequence/${sequenceId}/contacts/${contactId}`)
                  }}
                >
                  {title}
                </SequenceTitle>
                <TaskSubtitle align='right'>{`Step ${step}`}</TaskSubtitle>
              </DetailContainer>
              {selected && this.props.deleteContact &&
                <DetailContainer flex='0 0 36px'>
                  <IconButton
                    onClick={() => {
                      this.props.deleteContact(task)
                    }}
                    type={['fal', 'trash-alt']}
                  />
                </DetailContainer>}
            </FormDetail>
            {selected && !isFreeEmail &&
              <CompanyPreview
                contactCompany={contactCompany}
                hideDivider
                mt='1rem'
              />}
          </Wrapper>
        )}
      </CellMeasurer>
    )
  }
}

TaskRow.propTypes = {
  style: PropTypes.object,
  router: PropTypes.object,
  theme: PropTypes.object,
  task: PropTypes.object,
  onClick: PropTypes.func,
  last: PropTypes.bool,
  selected: PropTypes.bool,
  contactCompany: PropTypes.object,
  selectedContact: PropTypes.object,
  parent: PropTypes.object,
  index: PropTypes.number,
  cache: PropTypes.object,
  handleCellLoadingComplete: PropTypes.func,
  rowKey: PropTypes.string,
  deleteContact: PropTypes.func,
  editContact: PropTypes.func,
  onChecked: PropTypes.func,
  checkedContacts: PropTypes.array,
  listRef: PropTypes.object
}

export default withRouter(withTheme(TaskRow))
