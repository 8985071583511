import React from 'react'
import styled from 'styled-components'
import PropTypes from 'prop-types'

import { MenuItem } from 'elements/Menu'

const Wrapper = styled.div`
  position: absolute;
  right: 3rem;
`

const DropDownMenu = styled.div`
  z-index: 20;
  position: relative;
  background-color: #FFFFFF;
  border: 1px solid #D8D8D8;
  border-radius: 3px;
  overflow-y: auto;
  max-height: 250px;
  text-align: left;
  margin-top: 0.5rem;
  padding: 0.5rem;
  box-shadow: ${props => props.theme.shadow};
`

const Backdrop = styled.div`
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;

  &:hover {
    cursor: auto;
  }
`

class SaveActionsMenu extends React.Component {
  render () {
    const menuItems = this.props.saveActions.map((action, index) => {
      return (
        <MenuItem
          key={`extram-${index}`}
          onClick={() => {
            action.fn()
          }}
        >
          {action.title}
        </MenuItem>
      )
    })

    return (
      <Wrapper>
        <Backdrop />
        <DropDownMenu>
          {menuItems}
        </DropDownMenu>
      </Wrapper>
    )
  }
}

SaveActionsMenu.propTypes = {
  saveActions: PropTypes.array
}

export default SaveActionsMenu
