const family = {
  soleil: 'soleil, sans-serif'
}

const weight = {
  bold: 600,
  semibold: 500,
  regular: 300
}

const size = {
  xxxlarge: '30px',
  xxlarge: '24px',
  xlarge: '22px',
  large: '20px',
  normal: '15px',
  small: '13px',
  xsmall: '12px',
  xxsmall: '10px'
}

const style = {
  price: `${weight.bold} ${size.xxxlarge} ${family.soleil}`,
  title: `${weight.bold} ${size.xxlarge} ${family.soleil}`,
  subtitle: `${weight.bold} ${size.xlarge} ${family.soleil}`,
  formtitle: `${weight.bold} ${size.normal} ${family.soleil}`,
  searchtitle: `${weight.semibold} ${size.small} ${family.soleil}`,
  header: `${weight.bold} ${size.large} ${family.soleil}`,
  normal: `${weight.regular} ${size.normal} ${family.soleil}`,
  small: `${weight.regular} ${size.small} ${family.soleil}`,
  xsmall: `${weight.regular} ${size.xsmall} ${family.soleil}`,
  xxsmall: `${weight.regular} ${size.xxsmall} ${family.soleil}`,
  button: `${weight.semibold} ${size.xsmall} ${family.soleil}`,
  number: `${weight.regular} ${size.xlarge} ${family.soleil}`,
  label: `${weight.bold} ${size.xsmall} ${family.soleil}`
}

export default style
