import {
  CUSTOM_FIELD_REGEX,
  FALLBACK_CUSTOM_FIELD_REGEX
} from './constants'

function decorate (node, regex, decorationType) {
  const { text, key, path } = node
  const decorations = []
  let match
  while ((match = regex.exec(text))) {
    const startIndex = match.index
    const endIndex = match.index + match[0].length

    const decoration = {
      type: decorationType,
      anchor: { key, path, offset: startIndex },
      focus: { key, path, offset: endIndex }
    }

    decorations.push(decoration)
  }

  return decorations
}

function decorateCustomFields (node, editor, next) {
  const others = next() || []
  const validNodeTypes = new Set(['paragraph', 'list_item', 'blockquote'])
  if (!validNodeTypes.has(node.type)) {
    return others
  }

  const textNodes = Array.from(node.texts())
  const decorations = []
  for (const textNode of textNodes) {
    const [startText, startPath] = textNode
    const node = {
      // the actual `text` that is stored inside the slate DOM node
      text: startText.text,

      // slate refers to each DOM node with a unique `key`. this key
      // uniquely identifies which DOM node to add the decoration to
      key: startText.key,

      // each DOM node that slate keeps track of contains a `path`.
      // this `path` refers to the sequence of nodes that slate would
      // need to recurse through in order to arrive at the node
      path: startPath
    }

    decorations.push(
      ...decorate(node, CUSTOM_FIELD_REGEX(), 'custom_field'),
      ...decorate(node, FALLBACK_CUSTOM_FIELD_REGEX(), 'custom_field')
    )
  }

  return [...others, ...decorations]
}

export {
  decorateCustomFields
}
