export const FETCH_SUBSCRIPTION_REQUEST = 'Billing/FETCH_SUBSCRIPTION_REQUEST'
export const FETCH_SUBSCRIPTION_SUCCESS = 'Billing/FETCH_SUBSCRIPTION_SUCCESS'
export const FETCH_SUBSCRIPTION_FAILURE = 'Billing/FETCH_SUBSCRIPTION_FAILURE'

export const CREATE_SUBSCRIPTION_REQUEST = 'Billing/CREATE_SUBSCRIPTION_REQUEST'
export const CREATE_SUBSCRIPTION_SUCCESS = 'Billing/CREATE_SUBSCRIPTION_SUCCESS'
export const CREATE_SUBSCRIPTION_FAILURE = 'Billing/CREATE_SUBSCRIPTION_FAILURE'

export const UPDATE_SUBSCRIPTION_REQUEST = 'Billing/UPDATE_SUBSCRIPTION_REQUEST'
export const UPDATE_SUBSCRIPTION_SUCCESS = 'Billing/UPDATE_SUBSCRIPTION_SUCCESS'
export const UPDATE_SUBSCRIPTION_FAILURE = 'Billing/UPDATE_SUBSCRIPTION_FAILURE'

export const FETCH_CUSTOMER_REQUEST = 'Billing/FETCH_CUSTOMER_REQUEST'
export const FETCH_CUSTOMER_SUCCESS = 'Billing/FETCH_CUSTOMER_SUCCESS'
export const FETCH_CUSTOMER_FAILURE = 'Billing/FETCH_CUSTOMER_FAILURE'

export const FETCH_PREVIEW_REQUEST = 'Billing/FETCH_PREVIEW_REQUEST'
export const FETCH_PREVIEW_SUCCESS = 'Billing/FETCH_PREVIEW_SUCCESS'
export const FETCH_PREVIEW_FAILURE = 'Billing/FETCH_PREVIEW_FAILURE'

export const FETCH_UPDATE_PREVIEW_REQUEST = 'Billing/FETCH_UPDATE_PREVIEW_REQUEST'
export const FETCH_UPDATE_PREVIEW_SUCCESS = 'Billing/FETCH_UPDATE_PREVIEW_SUCCESS'
export const FETCH_UPDATE_PREVIEW_FAILURE = 'Billing/FETCH_UPDATE_PREVIEW_FAILURE'
export const FETCH_UPDATE_PREVIEW_RESET = 'Billing/FETCH_UPDATE_PREVIEW_RESET'

export const FETCH_CHARGES_REQUEST = 'Billing/FETCH_CHARGES_REQUEST'
export const FETCH_CHARGES_SUCCESS = 'Billing/FETCH_CHARGES_SUCCESS'
export const FETCH_CHARGES_FAILURE = 'Billing/FETCH_CHARGES_FAILURE'

export const FETCH_REQUESTS_REQUEST = 'Billing/FETCH_REQUESTS_REQUEST'
export const FETCH_REQUESTS_SUCCESS = 'Billing/FETCH_REQUESTS_SUCCESS'
export const FETCH_REQUESTS_FAILURE = 'Billing/FETCH_REQUESTS_FAILURE'

export const FETCH_BILLING_EMAIL_REQUEST = 'Billing/FETCH_BILLING_EMAIL_REQUEST'
export const FETCH_BILLING_EMAIL_SUCCESS = 'Billing/FETCH_BILLING_EMAIL_SUCCESS'
export const FETCH_BILLING_EMAIL_FAILURE = 'Billing/FETCH_BILLING_EMAIL_FAILURE'

export const FETCH_PRICING_REQUEST = 'Billing/FETCH_PRICING_REQUEST'
export const FETCH_PRICING_SUCCESS = 'Billing/FETCH_PRICING_SUCCESS'
export const FETCH_PRICING_FAILURE = 'Billing/FETCH_PRICING_FAILURE'

export const ADD_LOOKUPS_REQUEST = 'Billing/ADD_LOOKUPS_REQUEST'
export const ADD_LOOKUPS_SUCCESS = 'Billing/ADD_LOOKUPS_SUCCESS'
export const ADD_LOOKUPS_FAILURE = 'Billing/ADD_LOOKUPS_FAILURE'
