import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import Immutable from 'immutable'
import Modal from 'components/Modal'
import {
  selectSession,
  selectState
} from 'containers/App/selectors'
import {
  fetchTeamMembers,
  fetchOwnerCrm,
  refreshOwnerCrm
} from 'containers/Team/actions'
import {
  selectMembers,
  selectOwnerCrm
} from 'containers/Team/selectors'
import CreateSequenceModal from './components/CreateSequenceModal'
import Sequences from './components/Sequences'
import SequenceStats from './components/SequenceStats'
import MessageSchedule from './components/MessageSchedule'
import Wrapper from './Wrapper'
import ConfigModal from './components/ConfigModal'
import {
  endOfToday,
  format,
  startOfToday,
  startOfWeek,
  endOfWeek
} from 'date-fns'

import {
  selectSequences,
  selectLoading,
  selectSequencesStats,
  selectNewSequence,
  selectArchivedSequences,
  selectReports,
  selectSchedule,
  selectSequencesStatus,
  selectMessageCounts,
  selectSequencesCsvExport
} from './selectors'

import {
  fetchSequences,
  updateSequences,
  deleteSequences,
  restoreSequences,
  fetchSequencesStats,
  fetchSequencesStatus,
  createSequence,
  createSequenceReset,
  fetchArchivedSequences,
  fetchSequenceReports,
  fetchMessageSchedule,
  fetchMessageCounts,
  fetchSequencesExportCsv
} from './actions'

import {
  updateSession
} from '../Settings/actions'

const ResultContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`

const defaultConfig = {
  messageQueue: true,
  personalizeMessage: true,
  pendingManual: true,
  repliesToday: true,
  bookedToday: true,
  contactsAddedToday: true,
  messagesToday: true,
  repliesWeekly: false,
  bookedWeekly: false,
  contactsAddedWeekly: false,
  messagesWeekly: false
}

class Dashboard extends Component {
  constructor (props) {
    super(props)

    // Default state
    this.state = {
      showNewSequenceModal: false,
      showScheduleModal: false,
      showConfigModal: false,
      userConfig: {} // local mem for config modal toggles
    }
  }

  UNSAFE_componentWillMount () {
    this.fetch({})
    document.title = 'Interseller | Dashboard'
    const newSequence = this.props.location.query.new
    this.setState({
      showNewSequenceModal: newSequence !== undefined
    })
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    // Take new sequence and push to
    if (nextProps.newSequence.sequence) {
      const sequenceId = nextProps.newSequence.sequence.get('_id')
      this.props.router.push(`/sequence/${sequenceId}/contacts`)
      this.props.actions.createSequenceReset()
    }

    const redirect = nextProps.location.query.redirect

    if (redirect && nextProps.sequences) {
      const firstSequenceId = nextProps.sequences.find((s) => {
        return s.getIn(['_user', 'id']) === this.props.session.get('_id')
      })

      if (firstSequenceId) {
        if (redirect === 'steps') {
          return this.props.router.push(`/sequence/${firstSequenceId.get('id')}/steps`)
        } else if (redirect === 'contacts') {
          return this.props.router.push(`/sequence/${firstSequenceId.get('id')}/contacts`)
        }
      }
    }

    if (nextProps.sequencesCsvExport.getIn(['data', 'downloadLink']) && this.props.sequencesCsvExport.get('loading')) { // eslint-disable-line
      window.location.assign(nextProps.sequencesCsvExport.getIn(['data', 'downloadLink'])) // eslint-disable-line
    }
  }

  getConfigFromState = () => {
    return this.props.session.get('dashboard_config') ? this.props.session.get('dashboard_config').toJSON() : defaultConfig
  }

  fetchSequences = (archived) => {
    const {
      actions,
      archivedSequences,
      sequences
    } = this.props

    if (archived) {
      if (!archivedSequences.count()) {
        actions.fetchArchivedSequences({})
      }
    } else {
      if (!sequences.count()) {
        actions.fetchSequences({})
      }
    }
  }

  fetch = (query) => {
    const {
      actions,
      members
    } = this.props
    const archived = this.props.location.query.archived
    this.fetchSequences(archived)

    // If filters or sort are handled by the server in the future, move query
    // param updates to here Fetch sequence stats
    actions.fetchSequenceReports(this.getConfigFromState())
    // actions.fetchSequencesStatus()
    actions.fetchMessageCounts()
    actions.fetchOwnerCrm(this.props.session.get('id'))

    // Fetch team users
    if (!members.get('data').size) {
      actions.fetchTeamMembers()
    }
  }

  createSequence = (params) => {
    // hello, I am filter folder and I need some refactoring
    const filterFolder = this.props.location.query.folder
    if (filterFolder) {
      params.group = filterFolder
    }
    this.props.actions.createSequence(params)
  }

  loadMore = () => {
    // TODO: add pagination to sequences, blocked by api
  }

  closeConfigModal = () => {
    this.setState({
      showConfigModal: false
    })
  }

  updateConfig = () => {
    this.props.actions.updateSession({
      dashboard_config: this.state.userConfig
    }, 'Dashboard updated!')
    this.closeConfigModal()
  }

  toggleTile = (id, checked) => {
    this.setState((prevState) => {
      const userConfig = Object.assign({}, prevState.userConfig)
      userConfig[id] = checked
      return { userConfig }
    })
  }

  render () {
    const {
      members,
      sequences,
      archivedSequences,
      sequencesStats,
      sequencesStatus,
      newSequence,
      reports,
      schedule,
      actions,
      router,
      location,
      loading,
      messageCounts,
      state,
      session,
      ownerCrm
    } = this.props

    const todayString = format(new Date(), 'YYYYMMDD')
    const repliesToday = reports.getIn(['data', 'replies', 'day', todayString])
    const bookedToday = reports.getIn(['data', 'booked', 'day', todayString])
    const contactsAddedToday = reports.getIn(['data', 'contacts', 'day', todayString])
    const todayStart = startOfToday().toISOString()
    const todayEnd = endOfToday().toISOString()

    const startWeek = startOfWeek(new Date())
    const endWeek = endOfWeek(new Date())
    const repliesWeek = reports.getIn(['data', 'replies', 'total'])
    const bookedWeek = reports.getIn(['data', 'booked', 'total'])
    const contactsAddedWeek = reports.getIn(['data', 'contacts', 'total'])
    const messagesWeek = reports.getIn(['data', 'messages', 'total'])

    const weekTooltip = `${format(startWeek, 'ddd, MMM D')} — ${format(endWeek, 'ddd, MMM D')}`
    const dashConfig = this.getConfigFromState()

    const numberTiles = [
      {
        name: 'personalizeMessage',
        label: 'Personalize Message Tasks',
        disabled: true,
        loading: messageCounts.get('loading'),
        value: messageCounts.getIn(['data', 'manual_message']),
        highlight: messageCounts.getIn(['data', 'manual_message']) > 0,
        onClickString: '/tasks/emails'
      },
      {
        name: 'pendingManual',
        label: 'Pending Manual Tasks',
        disabled: true,
        loading: messageCounts.get('loading'),
        value: messageCounts.getIn(['data', 'manual_task']),
        highlight: messageCounts.getIn(['data', 'manual_task']) > 0,
        onClickString: '/tasks/manual'
      },
      {
        name: 'contactsAddedToday',
        label: ' Contacts Added (Today)',
        disabled: false,
        loading: reports.get('loading'),
        value: contactsAddedToday,
        onClickString: `/reports/created?users=${session.get('id')}&start=${todayStart}&end=${todayEnd}`
      },
      {
        name: 'repliesToday',
        label: 'Replies Received (Today)',
        disabled: true,
        loading: reports.get('loading'),
        value: repliesToday,
        onClickString: '/tasks/replies'
      },
      {
        name: 'bookedToday',
        label: 'Meetings Booked (Today)',
        disabled: false,
        loading: reports.get('loading'),
        value: bookedToday,
        onClickString: `/reports/booked?users=${session.get('id')}&start=${todayStart}&end=${todayEnd}`
      },
      {
        name: 'contactsAddedWeekly',
        label: 'Contacts Added (This Week)',
        tooltip: weekTooltip,
        disabled: false,
        loading: reports.get('loading'),
        value: contactsAddedWeek,
        onClickString: `/reports/created?users=${session.get('id')}&start=${startWeek}&end=${endWeek}`
      },
      {
        name: 'messagesWeekly',
        label: 'Messages Sent (This Week)',
        tooltip: weekTooltip,
        disabled: false,
        loading: reports.get('loading'),
        value: messagesWeek,
        onClickString: `/reports/messaged?users=${session.get('id')}&start=${startWeek}&end=${endWeek}`
      },
      {
        name: 'repliesWeekly',
        label: 'Replies Received (This Week)',
        tooltip: weekTooltip,
        disabled: false,
        loading: reports.get('loading'),
        value: repliesWeek,
        onClickString: '/tasks/replies'
      },
      {
        name: 'bookedWeekly',
        label: 'Meetings Booked (This Week)',
        tooltip: weekTooltip,
        disabled: false,
        loading: reports.get('loading'),
        value: bookedWeek,
        onClickString: `/reports/booked?users=${session.get('id')}&start=${startWeek}&end=${endWeek}`
      }
    ]

    return (
      <Wrapper>
        <SequenceStats
          router={router}
          reports={reports}
          messageCounts={messageCounts}
          onShowConfigModal={() => {
            this.setState({
              showConfigModal: true,
              userConfig: this.getConfigFromState() // refreshing this on open instead of on close fixes bug
            })
          }}
          onShowSchedule={() => {
            actions.fetchMessageSchedule()
            this.setState({
              showScheduleModal: true
            })
          }}
          config={dashConfig}
          numberTiles={numberTiles}
        />
        <ResultContainer>
          <Sequences
            members={members}
            sequences={sequences}
            archivedSequences={archivedSequences}
            fetchSequences={(archived) => {
              this.fetchSequences(archived)
            }}
            loadMore={this.loadMore}
            sequencesStats={sequencesStats}
            sequencesStatus={sequencesStatus}
            state={state}
            loading={loading}
            router={router}
            location={location}
            actions={actions}
            onAddSequence={() => {
              actions.fetchOwnerCrm(this.props.session.get('id'))
              this.setState({
                showNewSequenceModal: true
              })
            }}
            onExportCsv={(sequenceIds) => {
              actions.fetchSequencesExportCsv(sequenceIds)
            }}
          />
        </ResultContainer>
        <Modal
          isOpen={this.state.showNewSequenceModal}
          onModalClose={() => {
            this.setState({
              showNewSequenceModal: false
            })
          }}
        >
          <CreateSequenceModal
            members={members}
            isManager={state.get('is_manager')}
            state={state}
            group={session.get('group')}
            session={session}
            sequences={sequences}
            handleCreateSequence={this.createSequence}
            loading={newSequence.loading}
            closeModal={() => this.setState({
              showNewSequenceModal: false
            })}
            createSequenceReset={actions.createSequenceReset}
            fetchOwnerCrm={actions.fetchOwnerCrm}
            refreshOwnerCrm={actions.refreshOwnerCrm}
            ownerCrm={ownerCrm}
            newSequence={newSequence}
          />
        </Modal>
        <Modal
          width='640px'
          isOpen={this.state.showScheduleModal}
          onModalClose={() => {
            this.setState({
              showScheduleModal: false
            })
          }}
        >
          <MessageSchedule
            messageCounts={messageCounts}
            schedule={schedule}
          />
        </Modal>
        <ConfigModal
          isOpen={this.state.showConfigModal}
          handleModalClose={this.closeConfigModal}
          config={this.state.userConfig}
          updateConfig={this.updateConfig}
          numberTiles={numberTiles}
          toggleTile={this.toggleTile}
        />
      </Wrapper>
    )
  }
}

Dashboard.propTypes = {
  sequences: PropTypes.instanceOf(Immutable.List),
  archivedSequences: PropTypes.instanceOf(Immutable.List),
  sequencesStats: PropTypes.object,
  sequencesStatus: PropTypes.object,
  newSequence: PropTypes.object,
  actions: PropTypes.object,
  loading: PropTypes.bool,
  location: PropTypes.object,
  reports: PropTypes.object,
  schedule: PropTypes.object,
  router: PropTypes.object,
  messageCounts: PropTypes.object,
  members: PropTypes.object,
  state: PropTypes.object,
  session: PropTypes.object,
  ownerCrm: PropTypes.object
}

// Specifies the default values for props:
Dashboard.defaultProps = {
  sequences: [],
  loading: false,
  newSequence: {
    loading: false
  }
}

const mapStateToProps = createStructuredSelector({
  sequences: selectSequences(),
  sequencesStats: selectSequencesStats(),
  loading: selectLoading(),
  newSequence: selectNewSequence(),
  archivedSequences: selectArchivedSequences(),
  reports: selectReports(),
  schedule: selectSchedule(),
  sequencesStatus: selectSequencesStatus(),
  messageCounts: selectMessageCounts(),
  state: selectState(),
  session: selectSession(),
  members: selectMembers(),
  sequencesCsvExport: selectSequencesCsvExport(),
  ownerCrm: selectOwnerCrm()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      createSequence,
      updateSequences,
      deleteSequences,
      restoreSequences,
      fetchSequences,
      fetchSequencesStats,
      createSequenceReset,
      fetchArchivedSequences,
      fetchSequenceReports,
      fetchMessageSchedule,
      fetchSequencesStatus,
      fetchMessageCounts,
      fetchTeamMembers,
      fetchSequencesExportCsv,
      fetchOwnerCrm,
      refreshOwnerCrm,
      updateSession
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Dashboard)
