import React, { useState } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import styled from 'styled-components'

import DropDownSelectInputField from 'components/DropDownSelectInputField'

import Modal from 'components/Modal'
import Button from 'components/Button'

import Divider from 'elements/Divider'
import Label from 'elements/Label'
import Title from 'elements/Title'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 1rem 0;
`

const FormWrapper = styled.div`
  padding: 0px 3rem 2rem;
  width: 100%;
  margin-top: 1.5rem;
`

const FormInput = styled.div`
  width: 100%;
`

const LabelWrapper = styled.div`
  padding: 0 2rem;
  width: 100%;
`

const ModalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
`

function AddToFolder ({
  sequence,
  folders,
  isOpen,
  onCancel,
  onSave,
  ...rest
}) {
  const [selectedFolder, setSelectedFolder] = useState(null)

  const options = folders
    .sort()
    .map(f => {
      return {
        value: f,
        label: f
      }
    })

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={onCancel}
    >
      <Wrapper>

        <Title align='center'>Add to Folder</Title>
        <LabelWrapper>
          <Label align='center' mt='0.5rem'>Choose a name for the folder or select an existing folder to group your sequences under</Label>

        </LabelWrapper>

        <FormWrapper>

          <FormInput>
            <DropDownSelectInputField
              options={options.toList()}
              clearable
              label='Folder name'
              customCreateLabel='Create new folder...'
              onOptionChange={(option) => {
                const newVal = option && option.value ? option.value : null
                setSelectedFolder(newVal)
              }}
              mb='1rem'
            />

          </FormInput>

        </FormWrapper>

        <Divider />

        <ModalButtonContainer>
          <Button
            label='Cancel'
            handleClick={() => {
              setSelectedFolder(null)
              onCancel()
            }}
            mr='0.5rem'
          />
          <Button
            primary
            label='Move Sequences'
            handleClick={() => {
              onSave(selectedFolder)
              setSelectedFolder(null)
            }}
          />
        </ModalButtonContainer>
      </Wrapper>
    </Modal>
  )
}

AddToFolder.propTypes = {
  folders: PropTypes.instanceOf(Immutable.Set),
  onSave: PropTypes.func,
  sequence: PropTypes.any,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func
}

export default AddToFolder
