import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Loading from 'components/Loading'
import TextFieldForm from 'components/TextFieldForm'
import DropDownFieldForm from 'components/DropDownFieldForm'
import SwitchFieldForm from 'components/SwitchFieldForm'
import Label from 'elements/Label'
import Subtitle from 'elements/Subtitle'

const Wrapper = styled.div`
  padding: ${props => props.theme.padding};
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray30};
  border-style: solid;
`

class TeamSafety extends Component {
  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props
    actions.fetchTeamBlocklist()
  }

  render () {
    const {
      teamSettings,
      onTeamSettingUpdate,
      teamBlocklist,
      actions,
      state,
      session
    } = this.props

    if (teamBlocklist.get('loading')) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    const canUpdate = state && (state.get('is_manager') || state.get('is_owner'))
    const contactSafety = teamSettings.getIn(['data', 'contact_safety']) || 'OFF'
    const contactSafetyDays = teamSettings.getIn(['data', 'contact_safety_days'])
    const groupDedupe = teamSettings.getIn(['data', 'group_dedupe'] || false)
    const blocklistString = teamBlocklist.get('data').join('\n')
    const accountOwnership = teamSettings.getIn(['data', 'contact_domain_overlap_safety']) || false
    const contactsWithoutEmails = teamSettings.getIn(['data', 'contacts_without_emails']) || false
    const featureFlags = session.get('feature_flags') || session.get('whitelist')

    const options = Immutable.List([
      {
        value: 'OFF',
        label: 'Off - Do not deduplicate'
      },
      {
        value: 'MESSAGING',
        label: 'Low - Contact is being actively messaged'
      },
      {
        value: 'MESSAGED',
        label: 'Medium - Contact is being messaged or has been messaged'
      },
      {
        value: 'CAMPAIGNS',
        label: 'High - Contact exists in another sequence'
      }
    ])

    const groupOptions = Immutable.List([
      {
        value: false,
        label: 'Team - Contacts are deduplicated against the entire team'
      },
      {
        value: true,
        label: 'Group - Contacts are only deduplicated against a teammate\'s group'
      }
    ])

    return (
      <Wrapper>
        <Subtitle>Team Safety</Subtitle>
        <Label mt='0.25rem'>Safety settings can only be updated by a manager or owner.</Label>
        <FieldContainer>
          <DropDownFieldForm
            label='Deduplication'
            description={<span>Adjust your deduplication threshold to be warned when a team member adds a contact that exists in another team members sequence. Learn more about the <a href='https://help.interseller.io/article/47-running-duplicate-checks-in-team-sequences' target='_new'>team thresholds</a>.</span>}
            options={options}
            value={contactSafety}
            inputId='contact_safety'
            onSave={(value) => {
              onTeamSettingUpdate({ contact_safety: value === 'OFF' ? null : value })
            }}
            disabled={!canUpdate}
          />
        </FieldContainer>
        <FieldContainer>
          <TextFieldForm
            label='Deduplication Period'
            description={
              <span>
                Adjust the number of days contacts should be considered for deduplication.
                Contacts that were messaged or created longer than this period will be ignored.
                Removing this setting will set this to all time.
              </span>
            }
            value={contactSafetyDays}
            textType='number'
            inputId='contact_safety_days'
            onSave={(value) => {
              onTeamSettingUpdate({ contact_safety_days: value })
            }}
            disabled={!canUpdate}
          />
        </FieldContainer>
        {featureFlags.includes('show_group_ui') &&
          <FieldContainer>
            <DropDownFieldForm
              label='Deduplication Scope'
              description={
                <span>
                  You can adjust deduplication to work across your entire team or have deduplication work only across groups. Contacts of users that are not part of a group get deduplicated across the entire team. Learn more about <a href='https://help.interseller.io/article/236-assign-groups-to-users-on-a-team' target='_new'>groups</a>.
                </span>
              }
              options={groupOptions}
              value={groupDedupe}
              inputId='group_dedupe'
              onSave={(value) => {
                onTeamSettingUpdate({ group_dedupe: value })
              }}
              disabled={!canUpdate}
            />
          </FieldContainer>}
        <FieldContainer>
          <SwitchFieldForm
            label='Account Ownership'
            description='Only owners of an account can message a contact of that account.'
            inputId='contact_domain_overlap_safety'
            value={accountOwnership}
            onSave={(value) => {
              onTeamSettingUpdate({ contact_domain_overlap_safety: value })
            }}
          />
        </FieldContainer>
        {featureFlags.includes('contact_no_email_setting') &&
          <FieldContainer>
            <SwitchFieldForm
              label='Contacts without Emails (BETA)'
              description='For each contact added without an email address, Interseller will create a non-functioning email address to ensure duplicate tracking and ATS/CRM integrations continue to function as intended.'
              inputId='contacts_without_emails'
              value={contactsWithoutEmails}
              onSave={(value) => {
                onTeamSettingUpdate({ contacts_without_emails: value })
              }}
            />
          </FieldContainer>}
        <FieldContainer>
          <TextFieldForm
            label='Blocklist'
            description='Block domains and email addresses to prevent emails being sent by your team to people you do not want to contact. Separate each by a new line.'
            value={blocklistString}
            inputId='blocklist'
            multiline
            onSave={(value) => {
              const valueArray = value.split('\n')
              actions.updateTeamBlocklist(valueArray)
            }}
            disabled={!canUpdate}
          />
        </FieldContainer>
      </Wrapper>
    )
  }
}

TeamSafety.propTypes = {
  state: PropTypes.object,
  session: PropTypes.object,
  teamSettings: PropTypes.object,
  onTeamSettingUpdate: PropTypes.func,
  actions: PropTypes.object,
  teamBlocklist: PropTypes.object
}

export default TeamSafety
