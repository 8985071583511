export function handleLinkEvent (e, path) {
  const nativeE = (e || {}).nativeEvent || {}

  if (nativeE.metaKey) {
    window.open(`${window.location.origin}${path}`, '_newtab')
    return true
  }

  if (nativeE.shiftKey) {
    window.open(`${window.location.origin}${path}`, '_new')
    return true
  }

  return false
}
