import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled, { css, withTheme } from 'styled-components'
import { space, width, textAlign } from 'styled-system'

import { Caret } from 'svg'

const Wrapper = styled.div`
  font: ${props => props.theme.fonts.button};
  letter-spacing: .05em;
  color: ${props => props.theme.labelColor};
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  text-transform: uppercase;
  width: ${props => props.width ? props.width : '100%'};
  padding: ${props => props.theme.padding};

  ${props => props.sortKey && css`
    cursor: pointer;

    &:hover {
      opacity: .5;
      transition: opacity .15s ease-in;
    }

    &:active {
      opacity: .8;
      transition: opacity .15s ease-out;
    }
  `};

  ${space}
  ${width}
  ${textAlign}
`

const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: ${props => props.justifyContent ? props.justifyContent : 'start'};
`

class HeaderLabel extends Component {
  render () {
    const {
      sortKey,
      onClick,
      currentKey,
      theme,
      justifyContent,
      ...rest
    } = this.props

    let sortElement = <div />
    let newSortKey
    if (currentKey && currentKey.indexOf(sortKey) === 0) {
      // Inverse the direction of sort if tapped again
      if (currentKey.indexOf('+') > -1) {
        sortElement = <Caret color={theme.labelColor} degrees={180} height={18} />
        newSortKey = `${sortKey}-`
      } else {
        sortElement = <Caret color={theme.labelColor} height={18} />
        // Clear out the sort key if tapping a third time
        newSortKey = null
      }
    } else {
      // default sort is ascending
      newSortKey = `${sortKey}+`
    }

    return (
      <Wrapper
        onClick={() => {
          if (onClick) {
            onClick(newSortKey)
          }
        }}
        sortKey={sortKey}
        {...rest}
      >
        <HeaderContainer justifyContent={justifyContent}>
          {this.props.children}
          {sortElement && sortElement}
        </HeaderContainer>
      </Wrapper>
    )
  }
}

HeaderLabel.propTypes = {
  children: PropTypes.any,
  sortKey: PropTypes.string,
  onClick: PropTypes.func,
  currentKey: PropTypes.string,
  theme: PropTypes.object,
  justifyContent: PropTypes.string
}

export default withTheme(HeaderLabel)
