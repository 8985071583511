import React, { PureComponent } from 'react'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'

import { selectSession } from 'containers/App/selectors'

// Wraps components and adds session to component
export default function withSession (WrappedComponent) {
  class WrappedSessionComponent extends PureComponent {
    render () {
      return <WrappedComponent {...this.props} />
    }
  }

  const mapStateToProps = createStructuredSelector({
    session: selectSession()
  })

  return connect(mapStateToProps)(WrappedSessionComponent)
}
