import React from 'react'
import styled, { css } from 'styled-components'
import PropTypes from 'prop-types'
import { Column, Table } from 'react-virtualized'
import 'react-virtualized/styles.css'

import Loading from 'components/Loading'
import { NoDataLabel, NoDataHeader } from 'containers/Reports/styles'
import * as dateHelpers from 'containers/Reports/utils/date_helpers'

const TableContainer = styled.div`
  border: solid 1px ${props => props.theme.colors.gray30};
  border-radius: 3px;
  background-color: ${props => props.theme.colors.white};

  ${props => props.graphDisabled
    ? css`
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
    `
    : css`
      display: flex;
      flex-direction: row;
      justify-content: flex-start;
    `
  };

  position: absolute;
  top: ${props => props.tableRegion.y}px;
  left: ${props => props.tableRegion.x}px;
  width: ${props => props.tableRegion.width}px;
  height: ${props => props.tableRegion.height}px;

  .ReactVirtualized__Table { }

  .ReactVirtualized__Table__headerRow {
    background-color: ${props => props.theme.colors.gray10};
    color: ${props => props.theme.colors.darkBlue};
    text-transform: capitalize;

    border-bottom: 1px solid ${props => props.theme.colors.gray30};
  }

  .ReactVirtualized__Table__row {
    padding: .35rem 0rem;
    text-align: center;
    color: ${props => props.theme.colors.darkBlue};
    font: ${props => props.theme.fonts.normal};

    border-bottom: 1px solid ${props => props.theme.colors.gray30};
  }
`

const TableHeaderRowContainer = styled.div`
  display: inline-flex;
  flex-direction: column;
  justify-content: space-evenly;
  align-items: center;

  background-color: ${props => props.theme.colors.gray10};
  width: 160px;
`

const TableRowHeader = styled.div`
  width: 100%;

  padding: .35rem 0rem;
  text-align: center;
  color: ${props => props.theme.colors.darkBlue};
  font: ${props => props.theme.fonts.normal};
  font-weight: bold;

  border-top: 1px solid ${props => props.theme.colors.gray30};
  border-right: 1px solid ${props => props.theme.colors.gray30};
  &:last-child{
    border-bottom: 1px solid ${props => props.theme.colors.gray30};
  }
`

class ReportTable extends React.Component {
  formatTableData = (range = null) => {
    // first row in the table acting as a table header
    const tableColumnHeaders = {}

    // all table rows that include report data split by week or month
    const tableRows = [
      { tableRowLabel: 'Added' },
      { tableRowLabel: 'messaged' },
      { tableRowLabel: 'opened' },
      // { tableRowLabel: 'clicked' },
      { tableRowLabel: 'replied' }
      // { tableRowLabel: 'booked' },
    ]

    // generated reports for each stat going into the table
    const { sequenceStats } = this.props
    const added = sequenceStats.getIn(['data', 'contacts'])
    const messaged = sequenceStats.getIn(['data', 'contacts_messaged'])
    const opened = sequenceStats.getIn(['data', 'opens'])
    const replied = sequenceStats.getIn(['data', 'replies'])

    const rows = [
      added,
      messaged,
      opened,
      // clicked,
      replied
      // booked
    ]

    rows.forEach((row, idx) => {
      const dataByMonth = row.get('month')
      dataByMonth.entrySeq().forEach(month => {
        // key that will be later used to refer to a speciific column
        // an example would be`202012` -- which refers to `December 2020`
        const yearMonthStr = month[0]

        // yearMonthStr to the data returned from the reports
        const value = month[1]
        tableRows[idx][yearMonthStr] = value

        // create the proper date string dynamically and add it to `tableColumnHeaders`
        // if not alrady included
        if (!Object.keys(tableColumnHeaders).includes(yearMonthStr)) {
          const formattedYearMonthStr = dateHelpers.generateFormattedMonthHeader(yearMonthStr)
          tableColumnHeaders[yearMonthStr] = formattedYearMonthStr
        }
      })
    })

    return { tableRows, tableColumnHeaders }
  }

  render () {
    const { tableRegion, sequenceStats } = this.props

    if (!sequenceStats || !sequenceStats.get('data') || sequenceStats.get('data').size === 0) {
      return (
        <TableContainer tableRegion={tableRegion} graphDisabled>
          <NoDataHeader> No Sequences Selected</NoDataHeader>
          <NoDataLabel> Select a date range and a set of users or sequences to build a report</NoDataLabel>
        </TableContainer>
      )
    } else if (sequenceStats.get('loading')) {
      return (
        <TableContainer tableRegion={tableRegion}>
          <Loading />
        </TableContainer>
      )
    } else if (sequenceStats.get('data')) {
      const { tableRows, tableColumnHeaders } = this.formatTableData()
      const headerRowLength = 160
      return (
        <TableContainer tableRegion={tableRegion}>
          <TableHeaderRowContainer>
            <TableRowHeader> &nbsp; </TableRowHeader>
            <TableRowHeader>Added</TableRowHeader>
            <TableRowHeader>Messaged</TableRowHeader>
            <TableRowHeader>Opened</TableRowHeader>
            <TableRowHeader>Clicked</TableRowHeader>
            <TableRowHeader>Replied</TableRowHeader>
            <TableRowHeader>Booked</TableRowHeader>
          </TableHeaderRowContainer>
          <Table
            width={(tableRegion.width - headerRowLength)}
            height={240}
            headerHeight={34.19}
            rowHeight={34.19}
            rowCount={tableRows.length}
            rowGetter={({ index }) => tableRows[index]}
          >
            {Object.keys(tableColumnHeaders).map(columnKey => {
              const formattedHeader = tableColumnHeaders[columnKey]
              return (
                <Column
                  label={formattedHeader}
                  dataKey={columnKey}
                  key={columnKey}
                />
              )
            })}
          </Table>
        </TableContainer>
      )
    }
  }
}

ReportTable.propTypes = {
  tableRegion: PropTypes.object,
  sequenceStats: PropTypes.object
}

export default ReportTable
