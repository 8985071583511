import React, { Component } from 'react'
import { fromJS } from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { Tooltip } from 'react-tippy'

import Loading from 'components/Loading'
import Verified from 'elements/Verified'
import Warning from 'elements/Warning'
import Error from 'elements/Error'
import Button from 'components/Button'
import CompanyInput from 'components/CompanyInput'
import Input from 'components/Input'
import Label from 'elements/Label'
import Unknown from 'elements/Unknown'

import api from 'utils/api'

const Wrapper = styled.div`
  background: ${props => props.theme.colors.white};
  -webkit-app-region: drag;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
  display: flex;
  align-items: center;
  justify-content: center;
`

const InputContainer = styled.div`
  padding: 2rem;
  width: 100%;
`

const RowContainer = styled.div`
  display: flex;
  flex-direction: row;
`

const EmailContainer = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  margin-top: 1.5rem;
`

const EmailHeaderLabel = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.label};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const EmailLabel = styled(Label)`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.normal};
  width: 100%;
  padding: 0.5rem;
  padding-left: 2rem;
  height: 38px;
`

const ValidContainer = styled.div`
  position: absolute;
  left: 0.45rem;
  bottom: 0.6rem;
`

class LookupContact extends Component {
  constructor (props) {
    super(props)
    this.state = {
      email: props.email
    }
  }

  handleKeyDown = (e) => {
    const {
      name,
      website
    } = this.props
    // If 'enter' is pressed
    if (e.keyCode === 13 && name && website) {
      e.preventDefault()
      e.stopPropagation()
      return this.lookupEmail(name, website)
    }
    return false
  }

  lookupEmail = (name, website) => {
    this.setState({ loading: true, email: '' })
    api.post('/emails/lookup', { name, query: website })
      .then((response) => {
        const data = fromJS(response.data)
        const result = data.get(0)
        const email = result.get('email')
        this.setState({
          email,
          verdict: result.get('verdict'),
          loading: false,
          error: false
        })
        this.props.onValueChange({
          email
        })
      })
      .catch(() => {
        // Assuming failure is not found
        this.setState({
          loading: false,
          error: true,
          verdict: null
        })
        this.props.onValueChange({
          email: ''
        })
      })
  }

  render () {
    const {
      onValueChange,
      name,
      website
    } = this.props

    const {
      email,
      verdict,
      loading,
      error
    } = this.state

    let validMessage
    let validElement
    if (loading) {
      validMessage = 'Verifying email…'
      validElement = <Loading size={14} padding={0} />
    } else if (verdict === 'GOOD') {
      validMessage = 'Email verified and deliverable'
      validElement = <Verified />
    } else if (verdict === 'RISKY') {
      validMessage = 'Email is deliverable but not verified'
      validElement = <Warning />
    } else if (verdict === 'INVALID' || verdict === 'REJECTED') {
      validMessage = 'Email is invalid and will probably bounce'
      validElement = <Error />
    } else if (verdict === 'UNKNOWN') {
      validMessage = 'Email could not be verified'
      validElement = <Unknown />
    }

    let emailHeader
    if (loading) {
      emailHeader = 'Searching for email address…'
    } else if (email) {
      emailHeader = 'Found email address'
    } else if (error) {
      emailHeader = 'Could not find a verified email addresses'
    } else {
      emailHeader = 'Search for an email address'
    }

    return (
      <Wrapper
        onKeyDown={this.handleKeyDown}
      >
        <InputContainer>
          <Label mb='0.5rem' align='center'>
            Lookup contact information by providing name and company website
          </Label>
          <RowContainer>
            <Input
              label='Name'
              value={name}
              inputId='name'
              onValueChange={(value) => {
                onValueChange({
                  name: value
                })
              }}
              mt='1.5rem'
              mr='0.5rem'
            />
            <CompanyInput
              label='Company Website'
              value={website}
              inputId='website'
              onValueChange={(value) => {
                onValueChange({
                  website: value
                })
              }}
              onCompanySelect={(company) => {
                onValueChange({
                  website: company.domain,
                  company: company.name
                })
              }}
              mt='1.5rem'
              ml='0.5rem'
            />
          </RowContainer>
          <Button
            primary
            full
            disabled={!name || !website || loading}
            label={loading ? 'Looking up Contact…' : 'Lookup Contact'}
            onClick={() => {
              this.lookupEmail(name, website)
            }}
            mt='1.5rem'
          />
          <EmailContainer>
            <EmailHeaderLabel>{emailHeader}</EmailHeaderLabel>
            <EmailLabel>
              {email}
            </EmailLabel>
            {validElement &&
              <ValidContainer>
                <Tooltip
                  // options
                  title={validMessage}
                  position='top'
                  trigger='mouseenter'
                  arrow
                >
                  {validElement}
                </Tooltip>
              </ValidContainer>}
          </EmailContainer>
        </InputContainer>
      </Wrapper>
    )
  }
}

LookupContact.propTypes = {
  onValueChange: PropTypes.func,
  name: PropTypes.string,
  website: PropTypes.string,
  email: PropTypes.string
}

export default LookupContact
