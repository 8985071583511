import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import withSession from 'hocs/session'
import styled, { withTheme } from 'styled-components'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { subDays, endOfDay, startOfDay } from 'date-fns'

import Loading from 'components/Loading'

import ReportMenu from './components/ReportMenu'

import { selectSession, selectState } from 'containers/App/selectors'
import { selectMembers } from 'containers/Team/selectors'

import { fetchTeamMembers } from 'containers/Team/actions'

import { selectRequests } from 'containers/Billing/selectors'
import { fetchRequests } from 'containers/Billing/actions'

import {
  selectSequencesProper,
  selectSequencesStats
} from 'containers/Dashboard/selectors'
import {
  fetchSequences,
  fetchSequencesStats
} from 'containers/Dashboard/actions'

import {
  fetchTeamReports,
  fetchTeamReportsContrastPeriod,
  fetchSequenceReports,
  fetchBookedReports,
  fetchRepliedReports,
  fetchMessagedReports,
  fetchContactsCreatedReports,
  fetchBounceRateReports,
  fetchOpenHoursReports,
  fetchReplyHoursReports,
  exportReportCSV,
  fetchMessagePerformaceReports,
  fetchContactPerformaceReports,
  fetchContactTaskReports,
  fetchLeaderboard,
  fetchLeaderboardContrast,
  fetchLeaderboardGroups,
  fetchLeaderboardGroupsContrast,
  fetchPersonalizedMessagePerformanceReports,
  fetchPersonalizedContactPerformanceReports,
  fetchStepPerformance,
  fetchSequencesReports,
  fetchBounceReason
} from './actions'
import {
  selectSequenceReports,
  selectContrastPeriodReports,
  selectBookedReports,
  selectRepliedReports,
  selectExportedReport,
  selectMessagedReports,
  selectContactsCreatedReports,
  selectBounceRateReports,
  selectReports,
  selectReplyHoursReports,
  selectOpenHoursReports,
  selectContactTaskReports,
  selectLeaderboard,
  selectLeaderboardContrast,
  selectLeaderboardGroups,
  selectLeaderboardGroupsContrast,
  selectPersonalizedMessagesReports,
  selectStepPerformance,
  selectSequencesReports,
  selectBounceReasonReports
} from './selectors'

import { DATE_RANGE } from 'containers/Reports/utils/date_helpers'

const REPORT_TYPES = {
  BOOKED: 'booked',
  REPLIED: 'replied',
  MESSAGED: 'messaged',
  CREATED: 'created',
  SEQUENCE: 'sequence',
  LEADERBOARD: 'leaderboard',
  BOUNCED: 'bounced',
  ACTIVITY: 'activity',
  MESSAGE_PERFORMANCE: 'message_performance',
  CONTACT_PERFORMANCE: 'contact_performance',
  STEP_PERFORMANCE: 'step_performance',
  TASKS: 'tasks',
  SEQUENCE_ACTIVITY: 'sequence_activity'
}

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  max-height: 100%;
  max-width: 100%;
  background-color: ${props => props.theme.colors.gray20};
  padding: 0 1.5rem;
`

const ContentWrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  padding-top: 1.5rem;
`

const Warning = styled.div`
  color: ${props => props.theme.colors.red}
`

class Reports extends React.PureComponent {
  constructor (props) {
    super(props)

    const {
      start, // start date ISO string
      end // send date ISO string
    } = props.location.query

    this.state = {
      // flags for if all users and sequcnes are selected
      allSequencesUsersSelected: false,

      // indices of selected users and sequences, and names of sequence folders
      selectedSequenceIds: Immutable.Set(),
      selectedUserIds: Immutable.Set(),
      selectedFolderNames: Immutable.Set(),
      selectedGroups: Immutable.Set(),

      // selected date range for generated report
      startDate: start ? startOfDay(new Date(start)) : startOfDay(subDays(new Date(), 30)),
      endDate: end ? endOfDay(new Date(end)) : endOfDay(new Date()),

      // toggle previous date data in leaderboard report
      showLeaderboardContrastData: false,

      // represents the selected date range inside the graph menu
      selectedDateRange: DATE_RANGE.DAY,

      loadedParamsOnStart: false,

      // toggle view by groups
      showLeaderboardGroupsData: false,

      // toggle personalized messages
      showPersonalizedMessagePerformance: false,
      showPersonalizedContactPerformance: false

    }

    this.graphMenuRef = React.createRef()
  }

  UNSAFE_componentWillMount () {
    document.title = 'Interseller | Reports'

    // load user and report data
    const { actions } = this.props
    actions.fetchRequests()
    actions.fetchSequences()
    actions.fetchTeamMembers()

    actions.fetchSequencesStats(false, true)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    let userIds = this.props.location.query.users
    if (userIds) {
      userIds = Immutable.Set(userIds.split(','))
    } else {
      userIds = Immutable.Set()
    }

    let sequenceIds = this.props.location.query.sequences
    if (sequenceIds) {
      sequenceIds = Immutable.Set(sequenceIds.split(','))
    } else {
      sequenceIds = Immutable.Set()
    }

    let folderNames = this.props.location.query.folders
    if (folderNames) {
      folderNames = Immutable.Set(folderNames.split(','))
    } else {
      folderNames = Immutable.Set()
    }

    let groups = this.props.location.query.groups
    if (groups) {
      groups = Immutable.Set(groups.split(','))
    } else {
      groups = Immutable.Set()
    }

    const { router } = this.props
    const { loadedParamsOnStart } = this.state

    // set the root state if this is the first time the user is loading
    // the reports route
    if (
      !loadedParamsOnStart &&
      !nextProps.users.get('loading') &&
      !nextProps.sequences.get('loading') &&
      nextProps.state.has('is_manager')
    ) {
      // `leaderboard report` is the default report (set in react router)
      // if the user is an admin, we want the `leaderboard report` to be the
      // default report
      const currentRoute = this.getCurrentRoute()
      if (currentRoute === 'leaderboard' && !nextProps.state.get('is_manager')) {
        return router.push('/reports/replied')
      }

      this.setState({
        allSequencesUsersSelected: userIds.size === 0 && sequenceIds.size === 0 && folderNames.size === 0 && groups.size === 0,
        selectedUserIds: userIds,
        selectedSequenceIds: sequenceIds,
        selectedFolderNames: folderNames,
        selectedGroups: groups,
        loadedParamsOnStart: true
      }, () => {
        this.fetchReports()
        this.setRouteParameters()
      })
    }

    const prevProps = this.props
    if (!nextProps.exportedReport.get('loading') &&
      prevProps.exportedReport !== nextProps.exportedReport &&
      !!nextProps.exportedReport) {
      const downloadLink = nextProps.exportedReport.getIn(['data', 'downloadLink'])

      if (downloadLink) {
        window.location = downloadLink
      }
    }
  }

  fetchReports = () => {
    // TODO: move the logic of this function into it's own component
    const {
      router,
      actions,
      state
    } = this.props
    const {
      startDate,
      endDate,
      selectedFolderNames,
      selectedGroups,
      allSequencesUsersSelected
    } = this.state

    // get current route
    const routes = router.routes
    const currentRoute = routes[routes.length - 1].path

    // create lists of selected users and sequences
    let users = this.getSelectedUserIds()
    const sequences = this.getSelectedSequenceIds()
    const folders = selectedFolderNames.toArray()
    const groups = selectedGroups.toArray()

    let filter = {
      user: users[0],
      sequence: sequences[0]
    }

    if (groups.length) {
      const filteredGroupMembers = this.getMembersFromGroups(groups)

      users = users.concat(filteredGroupMembers)

      users = [...new Set(users)]
    }

    switch (currentRoute) {
      case 'booked': {
        actions.fetchBookedReports(startDate, endDate, sequences, users, folders, allSequencesUsersSelected)
        break
      }
      case 'replied': {
        actions.fetchRepliedReports(startDate, endDate, sequences, users, folders, allSequencesUsersSelected)
        break
      }
      case 'created': {
        actions.fetchContactsCreatedReports(startDate, endDate, sequences, users, folders, allSequencesUsersSelected)
        break
      }
      case 'messaged': {
        actions.fetchMessagedReports(startDate, endDate, sequences, users, folders, allSequencesUsersSelected)
        break
      }
      case 'bounced': {
        if (!state.get('is_manager')) {
          filter = { user: 'me' } // special parameter that the sagas recognize to change endpoints
        }

        actions.fetchBounceRateReports(startDate, endDate, filter)
        actions.fetchBounceReason(startDate, endDate)
        break
      }
      case 'activity': {
        if (!state.get('is_manager')) {
          filter = { user: 'me' } // special parameter that the sagas recognize to change endpoints
        }

        actions.fetchReplyHoursReports(startDate, endDate, filter)
        actions.fetchOpenHoursReports(startDate, endDate, filter)
        break
      }
      case 'leaderboard': {
        actions.fetchLeaderboard(startDate, endDate)
        actions.fetchLeaderboardContrast(startDate, endDate)
        actions.fetchLeaderboardGroups(startDate, endDate)
        actions.fetchLeaderboardGroupsContrast(startDate, endDate)
        break
      }
      case 'message_performance': {
        actions.fetchMessagePerformaceReports(startDate, endDate)
        actions.fetchPersonalizedMessagePerformanceReports(startDate, endDate)
        break
      }
      case 'contact_performance': {
        actions.fetchContactPerformaceReports(startDate, endDate)
        actions.fetchPersonalizedContactPerformanceReports(startDate, endDate)
        break
      }
      case 'step_performance': {
        actions.fetchStepPerformance(startDate, endDate, sequences, users)
        break
      }
      case 'sequence_activity': {
        actions.fetchSequencesReports()
        break
      }
      case 'tasks': {
        actions.fetchContactTaskReports(startDate, endDate, sequences, users, folders, allSequencesUsersSelected)
        break
      }
    }
  }

  exportReport = () => {
    const { actions } = this.props

    const {
      startDate,
      endDate,
      selectedFolderNames,
      selectedGroups,
      allSequencesUsersSelected
    } = this.state

    // get current route
    const currentRoute = this.getCurrentRoute()

    // create lists of selected users and sequences
    let users = this.getSelectedUserIds()
    const sequences = this.getSelectedSequenceIds()
    const folders = selectedFolderNames.toArray()
    const groups = selectedGroups.toArray()

    if (groups.length) {
      const filteredGroupMembers = this.getMembersFromGroups(groups)

      users = users.concat(filteredGroupMembers)

      users = [...new Set(users)]
    }

    actions.exportReportCSV(
      startDate,
      endDate,
      sequences,
      users,
      folders,
      allSequencesUsersSelected,
      currentRoute
    )
  }

  setRouteParameters = () => {
    const { location, router } = this.props
    const {
      startDate,
      endDate,
      selectedFolderNames,
      selectedGroups
    } = this.state

    // start and end date params
    const start = startDate ? startDate.toISOString() : null
    const end = endDate ? endDate.toISOString() : null

    // create lists of selected users and sequences
    const users = this.getSelectedUserIds()
    const sequences = this.getSelectedSequenceIds()
    const folders = selectedFolderNames.toArray()
    const groups = selectedGroups.toArray()

    const userString = users.length ? users.join(',') : null
    const sequencesString = sequences.length ? sequences.join(',') : null
    const folderString = folders.length ? (folders.join(',')) : null
    const groupString = groups.length ? groups.join(',') : null

    const currentRoute = this.getCurrentRoute()
    const reportsMissingFilters = this.getReportsMissingFilters()
    const currentReportMissingFilters = reportsMissingFilters.some((report) => report === currentRoute)

    let query = {
      start,
      end,
      users: userString,
      sequences: sequencesString,
      folders: folderString,
      groups: groupString
    }

    if (!query.users || query.users.length <= 0) {
      delete query.users
    }

    if (!query.sequences || query.sequences.length <= 0) {
      delete query.sequences
    }

    if (!query.folders || query.folders.length <= 0) {
      delete query.folders
    }

    if (!query.groups || query.groups.length <= 0) {
      delete query.groups
    }

    if (currentReportMissingFilters) {
      query = {}
    }

    router.push({ ...location, query })
  }

  setDateRange = (newStartDate, newEndDate) => {
    if (!newStartDate || !newEndDate || (newStartDate > newEndDate)) {
      return
    }

    this.setState({
      startDate: startOfDay(newStartDate),
      endDate: endOfDay(newEndDate)
    }, () => {
      // set url parameters
      this.setRouteParameters()

      // make request for new report
      this.fetchReports()
    })
  }

  setSequenceIds = (selectedSequenceIds, callback) => {
    this.setState({
      selectedSequenceIds,
      allSequencesUsersSelected: false
    }, () => {
      // update the route parameters
      this.setRouteParameters()

      // update the data coming back for the report
      this.fetchReports()

      // invoke `callback` of anything the caller might want after updating the state
      if (callback) {
        callback()
      }
    })
  }

  setUserIds = (selectedUserIds, callback) => {
    this.setState({
      selectedUserIds,
      allSequencesUsersSelected: false
    }, () => {
      // update the route parameters
      this.setRouteParameters()

      // make request for new report
      this.fetchReports()

      // invoke `callback` of anything the caller might want after updating the state
      if (callback) {
        callback()
      }
    })
  }

  setFilter = ({ selectedUserIds = Immutable.Set(), selectedSequenceIds = Immutable.Set() } = {}, callback) => {
    this.setState({
      selectedUserIds,
      selectedSequenceIds,
      allSequencesUsersSelected: false
    }, () => {
      // update the route parameters
      this.setRouteParameters()

      // make request for new report
      this.fetchReports()

      // invoke `callback` of anything the caller might want after updating the state
      if (callback) {
        callback()
      }
    })
  }

  setFolderNames = (selectedFolderNames, callback) => {
    this.setState({
      selectedFolderNames,
      allSequencesUsersSelected: false
    }, () => {
      // update the route parameters
      this.setRouteParameters()

      // make request for new report
      this.fetchReports()
      if (callback) {
        callback()
      }
    })
  }

  setGroups = (selectedGroups, callback) => {
    this.setState({
      selectedGroups,
      allSequencesUsersSelected: false
    }, () => {
      // update the route parameters
      this.setRouteParameters()

      // make request for new report
      this.fetchReports()
      if (callback) {
        callback()
      }
    })
  }

  setSelectedDateType = (newDateRange) => {
    if (newDateRange !== DATE_RANGE.WEEK && newDateRange !== DATE_RANGE.DAY) {
      return
    }

    this.setState({ selectedDateRange: newDateRange })
  }

  toggleSelectAllSequencesUsers = callback => {
    const { allSequencesUsersSelected } = this.state
    this.setState({
      allSequencesUsersSelected: !allSequencesUsersSelected,
      selectedUserIds: Immutable.Set(),
      selectedSequenceIds: Immutable.Set(),
      selectedFolderNames: Immutable.Set(),
      selectedGroups: Immutable.Set()
    }, () => {
      // update the route parameters
      this.setRouteParameters()

      // make request for new report
      this.fetchReports()

      if (callback) {
        callback()
      }
    })
  }

  toggleLeaderboardContrastData = event => {
    const { showLeaderboardContrastData } = this.state
    this.setState({
      showLeaderboardContrastData: !showLeaderboardContrastData
    })
  }

  toggleLeaderboardGroupsData = e => {
    const { showLeaderboardGroupsData } = this.state

    this.setState({
      showLeaderboardGroupsData: !showLeaderboardGroupsData
    })
  }

  togglePersonalizedMessagePerformanceData = e => {
    const { showPersonalizedMessagePerformance } = this.state
    this.setState({
      showPersonalizedMessagePerformance: !showPersonalizedMessagePerformance
    })
  }

  togglePersonalizedContactPerformanceData = e => {
    const { showPersonalizedContactPerformance } = this.state
    this.setState({
      showPersonalizedContactPerformance: !showPersonalizedContactPerformance
    })
  }

  getReportsMissingFilters = () => {
    return [
      REPORT_TYPES.SEQUENCE_ACTIVITY
    ]
  }

  getSelectedUserIds () {
    return this.state.selectedUserIds.toArray()
  }

  getMembersFromGroups (groups = []) {
    const { users } = this.props

    return users
      .get('data')
      .filter((g) => groups.includes(g.get('group')))
      .map((user) => user.get('id'))
      .toArray()
  }

  getSelectedSequenceIds () {
    return this.state.selectedSequenceIds.toArray()
  }

  getCurrentRoute = () => {
    const { router } = this.props
    if (!router) {
      return null
    }

    const routes = router.routes
    const currentRoute = routes[routes.length - 1].path
    return currentRoute
  }

  render () {
    const {
      // site level props
      actions,
      router,
      theme,
      users,
      children,
      sequences,
      state,
      session,

      // different reports
      reports,
      contrastPeriodReports,
      bookedReports,
      repliedReports,
      messagedReports,
      contactsCreatedReports,
      bounceRateReports,
      replyHoursReports,
      openHoursReports,
      sequencesStats,
      contactTaskReports,
      leaderboard,
      leaderboardContrast,
      leaderboardGroups,
      leaderboardGroupsContrast,
      personalizedMessagesReports,
      stepPerformance,
      sequencesReports,
      bounceReasonReports
    } = this.props

    const {
      startDate,
      endDate,
      allSequencesUsersSelected,
      selectedUserIds,
      selectedSequenceIds,
      selectedFolderNames,
      selectedGroups,
      showLeaderboardContrastData,
      selectedDateRange,
      loadedParamsOnStart,
      showLeaderboardGroupsData,
      showPersonalizedMessagePerformance,
      showPersonalizedContactPerformance
    } = this.state

    const currentRoute = this.getCurrentRoute()

    if (!loadedParamsOnStart) {
      return <Loading width='100%' />
    }

    let dateRangeLabel = null
    let warningMessage = null
    let showExportButton = false
    let showSequenceSelector = true
    let showLegacySelector = false
    let showSequenceFolder = true
    let showDateRangeSelector = true
    let iconColor
    switch (this.getCurrentRoute()) {
      case REPORT_TYPES.MESSAGE_PERFORMANCE: {
        showSequenceSelector = false
        dateRangeLabel = 'Date Range (Message Sent)'
        warningMessage = 'Message performance looks at each message sent individually rather than counting by contact (e.g. a contact can have multiple messages sent and respond to only one message).'
        break
      }
      case REPORT_TYPES.CONTACT_PERFORMANCE: {
        showSequenceSelector = false
        dateRangeLabel = 'Date Range (Contact Added)'
        warningMessage = 'Contact performance looks at contacts when they were added. Percentages are based on the number of contacts that were actually messaged.'
        break
      }
      case REPORT_TYPES.STEP_PERFORMANCE: {
        showSequenceFolder = false
        break
      }
      case REPORT_TYPES.REPLIED: {
        showExportButton = true
        break
      }
      case REPORT_TYPES.LEADERBOARD: {
        showExportButton = true
        showSequenceSelector = false
        break
      }
      case REPORT_TYPES.BOOKED: {
        showExportButton = true
        break
      }
      case REPORT_TYPES.BOUNCED: {
        showSequenceSelector = false
        warningMessage = <Warning>Your bounce rate should be below 5% for optimal deliverability performance.</Warning>
        iconColor = theme.colors.red
        break
      }
      case REPORT_TYPES.ACTIVITY: {
        showSequenceSelector = false
        showLegacySelector = true
        warningMessage = 'Contact activity reports can count multiple opens from one contact and does not account for when the message was sent.'
        break
      }
      case REPORT_TYPES.TASK: {
        showExportButton = true
        showSequenceSelector = true
        break
      }
      case REPORT_TYPES.CREATED: {
        showExportButton = true
        break
      }
      case REPORT_TYPES.MESSAGED: {
        showExportButton = true
        break
      }
      case REPORT_TYPES.SEQUENCE_ACTIVITY: {
        showDateRangeSelector = false
        showSequenceSelector = false
        warningMessage = 'Sequence activity looks at the current number of active sequences and the sending activity across any of a user’s sequences. This report includes archived sequences as well.'
        break
      }
    }

    return (
      <Wrapper>
        <ContentWrapper>
          <ReportMenu
            router={router}
            users={users}
            sequences={sequences}
            actions={actions}
            session={session}
            state={state}
            ref={this.graphMenuRef}
            setDateRange={this.setDateRange}
            dateRangeLabel={dateRangeLabel}
            startDate={startDate}
            endDate={endDate}
            setSequenceIds={this.setSequenceIds}
            setUserIds={this.setUserIds}
            setFolderNames={this.setFolderNames}
            setGroups={this.setGroups}
            setFilter={this.setFilter}
            toggleSelectAllSequencesUsers={this.toggleSelectAllSequencesUsers}
            allSequencesUsersSelected={allSequencesUsersSelected}
            selectedSequenceIds={selectedSequenceIds}
            selectedUserIds={selectedUserIds}
            selectedFolderNames={selectedFolderNames}
            selectedGroups={selectedGroups}
            toggleLeaderboardContrastData={this.toggleLeaderboardContrastData}
            toggleLeaderboardGroupsData={this.toggleLeaderboardGroupsData}
            showLeaderboardContrastData={showLeaderboardContrastData}
            showLeaderboardGroupsData={showLeaderboardGroupsData}
            showGroupsToggle={currentRoute === REPORT_TYPES.LEADERBOARD}
            showReportsSelector
            showDateRangeSelector={showDateRangeSelector}
            showPreviousPeriodToggle={currentRoute === REPORT_TYPES.LEADERBOARD}
            showSequenceSelector={showSequenceSelector}
            showLegacySelector={showLegacySelector}
            showSequenceFolder={showSequenceFolder}
            showExportButton={showExportButton}
            warningMessage={warningMessage}
            iconColor={iconColor}
            fetchReports={this.fetchReports}
            exportReport={this.exportReport}
            showPersonalizedMessagePerformance={showPersonalizedMessagePerformance}
            showPersonalizedContactPerformance={showPersonalizedContactPerformance}
            showPersonalizedMessagePerformanceToggle={currentRoute === REPORT_TYPES.MESSAGE_PERFORMANCE}
            showPersonalizedContactPerformanceToggle={currentRoute === REPORT_TYPES.CONTACT_PERFORMANCE}
            togglePersonalizedMessagePerformanceData={this.togglePersonalizedMessagePerformanceData}
            togglePersonalizedContactPerformanceData={this.togglePersonalizedContactPerformanceData}
          />

          {loadedParamsOnStart && children && React.cloneElement(children, {
            actions,
            router,
            theme,
            state,
            sequences,
            sequencesStats,
            reports,
            contrastPeriodReports,
            bookedReports,
            repliedReports,
            contactsCreatedReports,
            messagedReports,
            bounceRateReports,
            replyHoursReports,
            openHoursReports,
            contactTaskReports,
            showLeaderboardContrastData,
            showLeaderboardGroupsData,
            selectedDateRange,
            leaderboard,
            leaderboardContrast,
            leaderboardGroups,
            leaderboardGroupsContrast,
            setSelectedDateType: this.setSelectedDateType,
            graphMenuRef: this.graphMenuRef,
            users,
            showPersonalizedMessagePerformance,
            showPersonalizedContactPerformance,
            personalizedMessagesReports,
            stepPerformance,
            sequencesReports,
            bounceReasonReports
          })}

        </ContentWrapper>
      </Wrapper>
    )
  }
}

Reports.propTypes = {
  children: PropTypes.any,
  actions: PropTypes.object,
  router: PropTypes.object,
  theme: PropTypes.object,
  location: PropTypes.object,
  users: PropTypes.object,
  state: PropTypes.object,
  session: PropTypes.object,
  requests: PropTypes.object,
  sequences: PropTypes.object,
  reports: PropTypes.object,
  contrastPeriodReports: PropTypes.object,
  bookedReports: PropTypes.object,
  repliedReports: PropTypes.object,
  messagedReports: PropTypes.object,
  contactsCreatedReports: PropTypes.object,
  bounceRateReports: PropTypes.object,
  exportedReport: PropTypes.object,
  replyHoursReports: PropTypes.object,
  contactTaskReports: PropTypes.object,
  openHoursReports: PropTypes.object,
  sequencesStats: PropTypes.object,
  leaderboard: PropTypes.object,
  leaderboardContrast: PropTypes.object,
  leaderboardGroups: PropTypes.object,
  leaderboardGroupsContrast: PropTypes.object,
  personalizedMessagesReports: PropTypes.object,
  stepPerformance: PropTypes.object,
  sequencesReports: PropTypes.object,
  bounceReasonReports: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  session: selectSession(),
  state: selectState(),
  users: selectMembers(),
  requests: selectRequests(),
  sequences: selectSequencesProper(),
  reports: selectReports(),
  contrastPeriodReports: selectContrastPeriodReports(),
  bookedReports: selectBookedReports(),
  repliedReports: selectRepliedReports(),
  sequenceStats: selectSequenceReports(),
  messagedReports: selectMessagedReports(),
  contactsCreatedReports: selectContactsCreatedReports(),
  exportedReport: selectExportedReport(),
  bounceRateReports: selectBounceRateReports(),
  replyHoursReports: selectReplyHoursReports(),
  openHoursReports: selectOpenHoursReports(),
  contactTaskReports: selectContactTaskReports(),
  sequencesStats: selectSequencesStats(),
  leaderboard: selectLeaderboard(),
  leaderboardContrast: selectLeaderboardContrast(),
  leaderboardGroups: selectLeaderboardGroups(),
  leaderboardGroupsContrast: selectLeaderboardGroupsContrast(),
  personalizedMessagesReports: selectPersonalizedMessagesReports(),
  stepPerformance: selectStepPerformance(),
  sequencesReports: selectSequencesReports(),
  bounceReasonReports: selectBounceReasonReports()
})

const mapDispatchToProps = dispatch => {
  return {
    actions: bindActionCreators({
      fetchRequests,
      fetchTeamReportsContrastPeriod,
      fetchTeamMembers,
      fetchSequences,
      fetchBookedReports,
      fetchRepliedReports,
      fetchSequenceReports,
      fetchSequencesStats,
      fetchContactsCreatedReports,
      fetchMessagedReports,
      exportReportCSV,
      fetchTeamReports,
      fetchBounceRateReports,
      fetchOpenHoursReports,
      fetchReplyHoursReports,
      fetchMessagePerformaceReports,
      fetchContactPerformaceReports,
      fetchContactTaskReports,
      fetchLeaderboard,
      fetchLeaderboardContrast,
      fetchLeaderboardGroups,
      fetchLeaderboardGroupsContrast,
      fetchPersonalizedMessagePerformanceReports,
      fetchPersonalizedContactPerformanceReports,
      fetchStepPerformance,
      fetchSequencesReports,
      fetchBounceReason
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withSession(withTheme(Reports)))
