import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled, { withTheme } from 'styled-components'

import { apiBaseURI } from 'utils/api'

import {
  selectSession,
  selectLoading,
  selectState
} from 'containers/App/selectors'
import {
  fetchSession,
  fetchSessionState
} from 'containers/App/actions'

import Loading from 'components/Loading'
import NavigationLink from 'components/NavigationLink'
import ExternalLink from 'components/ExternalLink'

const Wrapper = styled.div`
  background: ${props => props.theme.background};
  -webkit-app-region: drag;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
`

const Container = styled.div`
  width: 100%;
  min-height: 100vh;
  overflow: auto;
  display: block;
`

const Navigation = styled.div`
  padding: 3rem;
  padding-right: 3rem;
  padding-bottom: 1.5rem;
  text-align: center;
`

class Paywall extends Component {
  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props

    actions.fetchSession()
    actions.fetchSessionState()
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      state
    } = newProps

    if (state.get('active')) {
      this.props.router.push('/')
    }

    if (state.get('subscribed') && this.props.router) {
      this.props.router.push('/billing')
    }
  }

  render () {
    const {
      children,
      session,
      loading,
      theme,
      actions,
      router,
      state
    } = this.props

    if (loading || !state) {
      return (
        <Wrapper>
          <Loading />
        </Wrapper>
      )
    }

    return (
      <Wrapper>
        <Navigation>
          <NavigationLink
            key='link_paywall_billing'
            to='/paywall/billing'
            label='Billing'
          />
          <NavigationLink
            key='link_paywall_team'
            to='/paywall/team'
            label='Team'
          />
          <ExternalLink href={`${apiBaseURI}/logout`}>Logout</ExternalLink>
        </Navigation>
        <Container>
          {children && React.cloneElement(children, {
            theme,
            actions,
            session,
            loading,
            state,
            router,
            navigation: false
          })}
        </Container>
      </Wrapper>
    )
  }
}

Paywall.propTypes = {
  actions: PropTypes.object,
  state: PropTypes.any,
  router: PropTypes.object,
  route: PropTypes.object,
  theme: PropTypes.object,
  children: PropTypes.object,
  session: PropTypes.object,
  loading: PropTypes.bool,
  discovery: PropTypes.object,
  magic: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  session: selectSession(),
  loading: selectLoading(),
  state: selectState()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSession,
      fetchSessionState
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(withTheme(Paywall))
