import {
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,
  SAVE_TEMPLATE_REQUEST,
  SAVE_TEMPLATE_SUCCESS,
  SAVE_TEMPLATE_FAILURE,
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE
} from './constants'

export function fetchTemplates (filter) {
  return {
    type: FETCH_TEMPLATES_REQUEST,
    filter
  }
}

export function fetchTemplatesSuccess (templates) {
  return {
    type: FETCH_TEMPLATES_SUCCESS,
    payload: {
      templates
    }
  }
}

export function fetchTemplatesFailure (err) {
  return {
    type: FETCH_TEMPLATES_FAILURE,
    err
  }
}

export function saveTemplate (payload) {
  return {
    type: SAVE_TEMPLATE_REQUEST,
    payload
  }
}

export function saveTemplateSuccess (template) {
  return {
    type: SAVE_TEMPLATE_SUCCESS,
    payload: {
      template
    }
  }
}

export function saveTemplateFailure (err) {
  return {
    type: SAVE_TEMPLATE_FAILURE,
    err
  }
}

export function deleteTemplate (template) {
  return {
    type: DELETE_TEMPLATE_REQUEST,
    template
  }
}

export function deleteTemplateSuccess ({ template }) {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    template
  }
}

export function deleteTemplateFailure (err) {
  return {
    type: DELETE_TEMPLATE_FAILURE,
    err
  }
}
