import React, { useRef, useState, useEffect } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import Label from 'elements/Label'
import Title from 'elements/Title'
import Divider from 'elements/Divider'
import DangerLabel from 'elements/DangerLabel'

import Button from 'components/Button'
import DropArea from 'components/DropArea'

import {
  slateHelpers,
  slateConstants
} from 'components/SlateEditor/utils/slate'

import { fileApi } from 'utils/api'

import {
  Wrapper,
  Window,
  Curtain,
  ButtonContainer,
  TabContainer
} from '../ToolbarModal'

import { Img } from 'svg'

const StyledTitle = styled(Title)`
  text-align: center;
`

const UploadArea = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  margin-top: 2px;
  padding: 5rem 0;

  cursor: pointer;
`

const UploadContainer = styled.div`
  color: ${props => props.theme.labelColor};
  padding: 2rem;
  margin: 2rem 0 1rem 0;
  border: 1px dashed ${props => props.theme.colors.gray60};
  border-radius: 3px;
  background-color: ${
    props => props.dragOver
    ? props.theme.colors.blueGray
    : props.theme.colors.white
  };

  &:hover {
    background-color: ${props => props.theme.colors.blueGray};
  }
`

const InvalidLabel = styled(Label)`
  color: ${props => props.theme.colors.red};
  font: ${props => props.theme.fonts.normal};
  text-align: center;
`

const ImageSizeMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  padding: 2rem;
  padding-top: 1.5rem;
`

const ImageMenuContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-evenly;
`

const ImagePreview = styled.div`
  min-height: 400px;
  min-width: 600px;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`

function DragNDropImageModal ({
  editorRef,
  isBrandedDomain,
  setToolbarMenuVisibility
}) {
  const uploadImageRef = useRef(null)

  const [dragOver, setDragOver] = useState(false)
  const [images, setImages] = useState(null)
  const [selectedImageSize, setSelectedImageSize] = useState(slateConstants.IMAGE_SIZES.LARGE)
  const [errorMessage, setErrorMessage] = useState('')
  const [uploadAttempted, setUploadAttempted] = useState(false)
  const [uploadFailed, setUploadFailed] = useState(false)

  useEffect(() => {
    window.addEventListener('keydown', handleEscapeKey, false)
    window.addEventListener('keydown', handleEnterKey, false)

    const uploadImage = uploadImageRef?.current
    if (uploadImage) {
      uploadImage.addEventListener('click', handleOnChange, false)
    }

    return () => {
      window.removeEventListener('keydown', handleEscapeKey, false)
      window.removeEventListener('keydown', handleEnterKey, false)
    }
  })

  const handleEscapeKey = (event) => {
    const ESCAPE_KEY = 27
    if (event.keyCode === ESCAPE_KEY) {
      setToolbarMenuVisibility('showImageMenu', slateConstants.IMAGE_MENUS.NONE)
    }
  }

  const handleEnterKey = (event) => {
    if (!uploadFailed && uploadAttempted && images) {
      const ENTER_KEY = 13
      if (event.keyCode === ENTER_KEY) {
        handleInsertImage(event)
      }
    }
  }

  const handleOnChange = (event) => {
    event.preventDefault()
    event.stopPropagation()

    const uploadedFile = event?.target?.files[0]
    if (uploadedFile) {
      const { isValid, error } = slateHelpers.isValidImageFile(uploadedFile)
      if (isValid) {
        setUploadAttempted(true)
        handleUploadImage(uploadedFile)
      } else {
        setUploadAttempted(true)
        setUploadFailed(true)
        setErrorMessage(error)
      }
    }
  }

  const handleDropImage = event => {
    const uploadedFile = event?.dataTransfer?.files[0]
    const { isValid, error } = slateHelpers.isValidImageFile(uploadedFile)
    if (isValid) {
      setDragOver(false)
      setUploadAttempted(true)
      handleUploadImage(uploadedFile)
    } else {
      setUploadAttempted(true)
      setUploadFailed(true)
      setErrorMessage(error)
    }
  }

  const handleDragOverDropArea = (event) => {
    setDragOver(true)
  }

  const handleDragAwayDropArea = (event) => {
    setDragOver(false)
  }

  const handleUploadImage = (imageUploadInput) => {
    const formData = new window.FormData()
    formData.append('file', imageUploadInput)

    fileApi
      .post('/files?version=2', formData)
      .then(response => {
        const images = response.data
        if (images?.original && images?.small && images?.medium && images?.large) {
          setImages(images)
          setUploadFailed(false)
        }
      })
      .catch((err) => {
        setUploadFailed(true)
        setImages(null)
        console.log(err)
      })
  }

  const handleInsertImage = (event) => {
    event.preventDefault()

    if (images && selectedImageSize) {
      const url = images[selectedImageSize]?.url
      Promise
        .resolve()
        .then(() => {
          if (slateHelpers.isValidUrl(url)) {
            editorRef
              .current
              .insertImage(url)

            return true
          } else {
            setUploadAttempted(false)
            return false
          }
        })
        .then((result) => {
          if (result) {
            setToolbarMenuVisibility('showImageMenu', slateConstants.IMAGE_MENUS.NONE)
          }
        })
    }
  }

  const renderImageSizeMenu = () => {
    return (
      <ImageSizeMenu>
        <Button
          label='small'
          primary={selectedImageSize === slateConstants.IMAGE_SIZES.SMALL}
          small
          m='0 .25rem'
          handleClick={() => setSelectedImageSize(slateConstants.IMAGE_SIZES.SMALL)}
        />
        <Button
          label='medium'
          primary={selectedImageSize === slateConstants.IMAGE_SIZES.MEDIUM}
          small
          m='0 .25rem'
          handleClick={() => setSelectedImageSize(slateConstants.IMAGE_SIZES.MEDIUM)}
        />
        <Button
          label='large'
          primary={selectedImageSize === slateConstants.IMAGE_SIZES.LARGE}
          small
          m='0 .25rem'
          handleClick={() => setSelectedImageSize(slateConstants.IMAGE_SIZES.LARGE)}
        />
        <Button
          label='original'
          primary={selectedImageSize === slateConstants.IMAGE_SIZES.ORIGINAL}
          small
          m='0 .25rem'
          handleClick={() => setSelectedImageSize(slateConstants.IMAGE_SIZES.ORIGINAL)}
        />
      </ImageSizeMenu>
    )
  }

  return (
    <Wrapper>
      <Curtain
        visible
        onClick={() => {
          setToolbarMenuVisibility('showImageMenu', slateConstants.IMAGE_MENUS.NONE)
        }}
      />
      <Window>
        <TabContainer>
          <StyledTitle>Add Image</StyledTitle>
          {!images && <Label mt='0.5rem' mb='0.5rem' align='center'>Upload an image you'd like to display in your email</Label>}
          {images && <Label mt='0.5rem' mb='0.5rem' align='center'>Select the size of the image you'd like to display in your email</Label>}

          {!isBrandedDomain &&
            <DangerLabel>
              Adding images may decrease your chances of your email being delivered. We recommend setting up a <a href='https://help.interseller.io/article/12-branded-domains' target='_new'>branded domain</a>.
            </DangerLabel>}

          {!images &&
            <>
              <input
                ref={uploadImageRef}
                style={{ display: 'none' }}
                accept='image/*'
                id='img-uploader'
                type='file'
              />
              <UploadContainer dragOver={dragOver}>
                <DropArea
                  onDrop={handleDropImage}
                  onChange={handleOnChange}
                  onDragOver={handleDragOverDropArea}
                  onDragAway={handleDragAwayDropArea}
                >
                  <UploadArea
                    onClick={(event) => {
                      event.preventDefault()
                      uploadImageRef.current.click()
                    }}
                  >
                    <Img
                      width='36px'
                      height='36px'
                    />

                    {(uploadAttempted && errorMessage)
                      ? <InvalidLabel mt='1rem'>{errorMessage}</InvalidLabel>
                      : <Label mt='1rem' align='center'> Drag and drop your image here, or click to browse for a file to upload </Label>}
                  </UploadArea>
                </DropArea>
              </UploadContainer>
            </>}

          {images && selectedImageSize &&
            <ImageMenuContainer>
              {renderImageSizeMenu()}
              <ImagePreview>
                <img src={images[selectedImageSize].url} />
              </ImagePreview>
            </ImageMenuContainer>}
        </TabContainer>

        <Divider />

        <ButtonContainer>
          <Button
            label='cancel'
            mr='0.5rem'
            handleClick={() => {
              setToolbarMenuVisibility('showImageMenu', slateConstants.IMAGE_MENUS.NONE)
            }}
          />
          <Button
            label='add image'
            primary
            disabled={uploadFailed || !uploadAttempted || !images}
            handleClick={handleInsertImage}
          />
        </ButtonContainer>
      </Window>
    </Wrapper>
  )
}

DragNDropImageModal.propTypes = {
  isBrandedDomain: PropTypes.bool,
  editorRef: PropTypes.object,
  setToolbarMenuVisibility: PropTypes.func
}

export default DragNDropImageModal
