/**
*
* DetailRow Component
*
*/

import React, { Component } from 'react'
import PropTypes from 'prop-types'

import styled from 'styled-components'
import { space, width, textAlign } from 'styled-system'

import Label from 'elements/Label'

const Wrapper = styled.div`
  width: 100%;
  margin-top: auto;
  margin-bottom: auto;
  display: inline-block;
  float: left;

  padding-top: 1rem;
  padding-bottom: 1rem;

  ${space}
  ${width}
  ${textAlign}

  &:after {
    content: "";
    clear: both;
    display: table;
  }
`

const FormTitle = styled(Label)`
  display: inline-block;
  float: left;
  text-transform: uppercase;
  font: ${props => props.theme.fonts.button};
  margin-right: 0.5rem;
  line-height: 22px;
`

const FormValue = styled(Label)`
  display: inline;
  float: right;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 0.25rem;
  line-height: 22px;
  word-break: break-word;
  color: ${props => props.anonymized ? 'transparent' : props.theme.titleColor};
  text-shadow: ${props => props.anonymized ? '0 0 6px rgba(0,0,0,0.75)' : 'none'};
  padding: ${props => props.anonymized ? '0 2px' : 'none'};
`

class DetailRow extends Component {
  render () {
    const {
      label,
      value,
      anonymized
    } = this.props

    return (
      <Wrapper>
        <FormTitle>{label}</FormTitle>
        {value && <FormValue anonymized={anonymized}>{value}</FormValue>}
        {this.props.children}
      </Wrapper>
    )
  }
}

DetailRow.propTypes = {
  label: PropTypes.string,
  value: PropTypes.string,
  anonymized: PropTypes.bool,
  children: PropTypes.any
}

export default DetailRow
