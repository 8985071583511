import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { space, width, height } from 'styled-system'
import styled from 'styled-components'
import Button from 'components/Button'
import SlateEditor from 'components/SlateEditor'
import { SLATE_EDITORS } from '../SlateEditor/utils/slate/constants'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const EditorContainer = styled.div`
  border: 1px solid ${props => props.theme.colors.gray30};
  border-radius: 3px;
`

const Label = styled.label`
  width: 100%;
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

class RichTextFieldForm extends Component {
  constructor (props) {
    super(props)
    this.state = {
      value: props.value
    }
  }

  valid = () => (
    this.props.value !== this.state.value
  )

  render () {
    const {
      label,
      description,
      inputId,
      onSave,
      value,
      disabled,
      isBrandedDomain,
      ...rest
    } = this.props

    return (
      <Wrapper {...rest}>
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        {description &&
          <Description htmlFor={inputId}>{description}</Description>}
        <EditorContainer>
          <SlateEditor
            innerRef={(element) => (this.editor = element)}
            hideSubjectLine
            hideVariables
            hideTemplates
            hideEditorCoach
            body={value}
            isBrandedDomain={isBrandedDomain}
            readOnly={disabled}
          />
        </EditorContainer>
        <Button
          full
          primary
          label='Save'
          handleClick={() => {
            const markdown = this.editor.getMarkdown(SLATE_EDITORS.BODY)
            onSave(markdown)
          }}
          mt='1rem'
          disabled={disabled}
        />
      </Wrapper>
    )
  }
}

RichTextFieldForm.propTypes = {
  label: PropTypes.string,
  description: PropTypes.any,
  value: PropTypes.string,
  disabled: PropTypes.bool,
  inputId: PropTypes.string,
  onSave: PropTypes.func,
  isBrandedDomain: PropTypes.bool
}

export default RichTextFieldForm
