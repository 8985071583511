import React, { PureComponent } from 'react'
import { ToastContainer, toast } from 'react-toastify'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled, { ThemeProvider } from 'styled-components'
import light from 'themes/light'
import PageError from 'elements/PageError'
import { success, error, persistentWarning, loading, done } from 'utils/toast'
import Wrapper from './Wrapper'
import { selectSession } from 'containers/App/selectors'
import {
  selectLoading,
  selectNotification
} from './selectors'
import {
  fetchSession,
  fetchSessionScopes,
  fetchSessionState,
  resetNotification,
  deleteAuthToken
} from './actions'

import { NOTIFICATION_TYPES } from './constants'
import * as Sentry from '@sentry/react'

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
`

const StyledToastContainer = styled(ToastContainer).attrs({
  className: 'toast-container',
  toastClassName: 'toast',
  bodyClassName: 'body',
  progressClassName: 'progress'
})`
  /* .toast-container */
   /* .toast is passed to toastClassName */
  .toast {
    padding: 0;
    min-height: 32px;
  }
  button[aria-label="close"] {
    display: none;
  }

  /* .body is passed to bodyClassName */
  .body {
    margin-top: 0;
    opacity: 1;
    background-color: white;
  }

  /* .progress is passed to progressClassName */
  .progress {}
`

class App extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      showBrowserWarning: false
    }
  }

  UNSAFE_componentWillMount () {
    const {
      actions
    } = this.props

    actions.fetchSession()
    actions.fetchSessionState()
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const {
      notification
    } = nextProps
    if (notification) {
      this.props.actions.resetNotification()
      done()
      switch (notification.type) {
        case NOTIFICATION_TYPES.SUCCESS:
          success(notification.message)
          break
        case NOTIFICATION_TYPES.ERROR:
          error(notification.message || 'An unexpected error has occured.')
          break
        case NOTIFICATION_TYPES.LOADING:
          loading(notification.message)
          break
        default:
          break
      }
    }

    const { session } = this.props
    if (session?.get('_id') !== nextProps.session?.get('_id')) {
      const isBrowserChrome = navigator?.userAgent?.indexOf('Chrome') > -1
      const { showBrowserWarning } = this.state
      if (nextProps.session?.get('_id') && !showBrowserWarning && !isBrowserChrome) {
        persistentWarning(
          'For an optimal experience, we recommend using Google Chrome.',
          () => window.open('https://www.google.com/chrome/')
        )
        this.setState({ showBrowserWarning: true })
      }
    }
  }

  componentDidCatch (e, info) {
    this.setState({
      hasError: true
    })

    Sentry.captureException(e, { extra: info })
  }

  render () {
    const {
      children
    } = this.props

    const {
      hasError
    } = this.state

    // Uncaught error has occurred show 500 page
    if (hasError) {
      return (
        <ThemeProvider theme={light}>
          <Wrapper>
            <ContentContainer style={{
              flex: 1,
              display: 'flex',
              alignItems: 'center',
              justifyContent: 'center'
            }}
            >
              <PageError />
            </ContentContainer>
          </Wrapper>
        </ThemeProvider>
      )
    }

    return (
      <ThemeProvider theme={light}>
        <Wrapper>
          <ContentContainer>
            {children}
          </ContentContainer>
          <StyledToastContainer
            position={toast.POSITION.TOP_RIGHT}
            draggable={false}
            autoClose={5000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            pauseOnHover
            style={{
              padding: 0
            }}
          />
        </Wrapper>
      </ThemeProvider>
    )
  }
}

App.propTypes = {
  children: PropTypes.object.isRequired,
  actions: PropTypes.object,
  notification: PropTypes.object,
  session: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  loading: selectLoading(),
  notification: selectNotification(),
  session: selectSession()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSession,
      fetchSessionState,
      fetchSessionScopes,
      resetNotification,
      deleteAuthToken
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(App)
