import React from 'react'

/* eslint-disable */
const FileValid = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 28} height={height || 34}  viewBox="0 0 28 34" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g id="Page-1" strokeWidth="1" fill="none" fillRule="evenodd">
        <g id="Valid" fillRule="nonzero">
            <g id="upload">
                <g id="Group" stroke={color || "#7A859F"}>
                    <path d="M24.578,33.293 L2.715,33.293 C1.337,33.293 0.215,32.172 0.215,30.793 L0.215,2.5 C0.215,1.121 1.337,0 2.715,0 L18.592,0 C18.868,0 19.092,0.224 19.092,0.5 C19.092,0.776 18.868,1 18.592,1 L2.715,1 C1.888,1 1.215,1.673 1.215,2.5 L1.215,30.793 C1.215,31.62 1.888,32.293 2.715,32.293 L24.578,32.293 C25.405,32.293 26.078,31.62 26.078,30.793 L26.078,8.704 C26.078,8.428 26.302,8.204 26.578,8.204 C26.854,8.204 27.078,8.428 27.078,8.704 L27.078,30.794 C27.078,32.172 25.957,33.293 24.578,33.293 Z" id="Shape"></path>
                    <path d="M26.578,9.204 L18.592,9.204 C18.316,9.204 18.092,8.98 18.092,8.704 L18.092,0.717 C18.092,0.441 18.316,0.217 18.592,0.217 C18.868,0.217 19.092,0.441 19.092,0.717 L19.092,8.203 L26.578,8.203 C26.854,8.203 27.078,8.427 27.078,8.703 C27.078,8.979 26.855,9.204 26.578,9.204 Z" id="Shape"></path>
                    <path d="M26.578,9.204 C26.448,9.204 26.318,9.153 26.22,9.053 L18.234,0.85 C18.042,0.653 18.046,0.335 18.244,0.143 C18.441,-0.05 18.758,-0.045 18.951,0.153 L26.937,8.356 C27.129,8.553 27.125,8.871 26.927,9.063 C26.83,9.157 26.704,9.204 26.578,9.204 Z" id="Shape"></path>
                </g>
                <g id="tick" transform="translate(7.000000, 12.000000)" fill={color || "#1FC866"}>
                    <path d="M14.7803286,0.219368896 C14.4874481,-0.0731229652 14.012605,-0.0731229652 13.7196659,0.219368896 L4.73420809,9.19209355 L1.28034512,5.74315872 C0.987464615,5.45066686 0.512621445,5.45069612 0.219682349,5.74315872 C-0.0732274497,6.03562133 -0.0732274497,6.50978697 0.219682349,6.80227883 L4.2038767,10.7807298 C4.49666932,11.0731924 4.97186405,11.0729877 5.26453947,10.7807298 L14.7803286,1.27851826 C15.0732384,0.986055652 15.0732091,0.511860756 14.7803286,0.219368896 Z" id="Shape"></path>
                </g>
            </g>
        </g>
    </g>
  </svg>
);

export default FileValid;
