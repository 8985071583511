import { createSelector } from 'reselect'

const selectIntegrationsDomain = () => state => state.get('integrations')

const selectCrmList = () => createSelector(
  selectIntegrationsDomain(),
  integrationState => integrationState.get('crmList')
)

const selectZaps = () => createSelector(
  selectIntegrationsDomain(),
  integrationState => integrationState.get('zaps')
)

const selectZapTemplates = () => createSelector(
  selectIntegrationsDomain(),
  integrationState => integrationState.get('zapTemplates')
)

const selectActiveCrm = () => createSelector(
  selectIntegrationsDomain(),
  integrationState => integrationState.get('crm')
)

const selectSequenceFields = () => createSelector(
  selectIntegrationsDomain(),
  integrationState => integrationState.get('sequenceFields')
)

const selectApiKey = () => createSelector(
  selectIntegrationsDomain(),
  integrationState => integrationState.get('apiKey')
)

const selectModifiedSettings = () => createSelector(
  selectIntegrationsDomain(),
  integrationState => integrationState.get('modifiedSettings')
)

export {
  selectCrmList,
  selectActiveCrm,
  selectSequenceFields,
  selectZaps,
  selectZapTemplates,
  selectApiKey,
  selectModifiedSettings
}
