const SLATE_EDITORS = {
  SUBJECT: 'subject',
  BODY: 'body'
}

const LINK_MENUS = {
  PREVIEW: 'showPreviewMenu',
  EDIT: 'showEditMenu',
  NONE: false
}

const IMAGE_MENUS = {
  URL: 'showImageUrlMenu',
  DRAG_N_DROP: 'showImageDragNDropMenu',
  NONE: false
}

const IMAGE_SIZES = {
  SMALL: 'small',
  MEDIUM: 'medium',
  LARGE: 'large',
  ORIGINAL: 'original'
}

const UNSUBSCRIBE_URL_VAR = '{{ interseller_unsubscribe_url }}'

/* eslint-disable */
const CUSTOM_FIELD_REGEX = () => {
  return new RegExp(/{{ *?[a-z0-9_]+ *?}}/, 'ig')
}

const FALLBACK_CUSTOM_FIELD_REGEX = () => {
  return new RegExp(/{{\s*fallback ([a-zA-Z0-9_])+ '([a-zA-Z]+)'\s*}}/, 'ig')
}
/* eslint-enable */

export {
  IMAGE_MENUS,
  IMAGE_SIZES,
  SLATE_EDITORS,
  LINK_MENUS,
  UNSUBSCRIBE_URL_VAR,
  CUSTOM_FIELD_REGEX,
  FALLBACK_CUSTOM_FIELD_REGEX
}
