import { library } from '@fortawesome/fontawesome-svg-core'
import {
  faBookmark as faSolidBookmark,
  faGlobeAmericas,
  faExclamationTriangle,
  faCheck,
  faInfoCircle,
  faPaperPlane,
  faReply,
  faTasks,
  faCalendar,
  faFolder,
  faEnvelope,
  faPhoneAlt,
  faRedo
} from '@fortawesome/free-solid-svg-icons'
import {
  faBookmark as faRegBookmark,
  faFolderOpen,
  faClock
} from '@fortawesome/free-regular-svg-icons'
import {
  faFacebookF,
  faLinkedinIn,
  faTwitter,
  faGithub,
  faChrome,
  faCcVisa,
  faCcAmex,
  faCcMastercard,
  faCcDiscover,
  faCcDinersClub,
  faCcJcb
} from '@fortawesome/free-brands-svg-icons'
import {
  faAngleDown,
  faAngleLeft,
  faTrashAlt,
  faCog,
  faEyeSlash,
  faExternalLinkSquare,
  faCopy,
  faCheckCircle,
  faCircle,
  faTimesCircle,
  faPen,
  faUnlink,
  faFileTimes,
  faFileCheck,
  faFileUpload,
  faPauseCircle,
  faPlayCircle,
  faMousePointer,
  faForward
} from '@fortawesome/pro-light-svg-icons'
import {
  faFolderTimes,
  faArchive,
  faEllipsisV,
  faBold,
  faItalic,
  faUnderline,
  faQuoteRight,
  faList,
  faListOl,
  faLink,
  faSmile,
  faBracketsCurly,
  faEllipsisH,
  faClipboardListCheck,
  faAngleUp,
  faAngleDown as regFaAngleDown,
  faMailBulk,
  faUser,
  faUsers,
  faFileExport,
  faPlus,
  faEye,
  faTimes,
  faFastForward,
  faExternalLink,
  faFilter,
  faSearch,
  faSpinnerThird
} from '@fortawesome/pro-regular-svg-icons'
import {
  faCreditCardFront
} from '@fortawesome/pro-duotone-svg-icons'

library.add(
  faAngleUp,
  faAngleDown,
  faAngleLeft,
  regFaAngleDown,
  faFileUpload,
  faFileTimes,
  faFileCheck,
  faSolidBookmark,
  faRegBookmark,
  faTrashAlt,
  faPaperPlane,
  faCog,
  faFolderOpen,
  faFolderTimes,
  faArchive,
  faEllipsisV,
  faEyeSlash,
  faExternalLink,
  faExternalLinkSquare,
  faCopy,
  faLinkedinIn,
  faTwitter,
  faFacebookF,
  faGithub,
  faCircle,
  faClipboardListCheck,
  faCheckCircle,
  faTimesCircle,
  faChrome,
  faCreditCardFront,
  faCcVisa,
  faCcAmex,
  faCcMastercard,
  faCcDiscover,
  faCcDinersClub,
  faCcJcb,
  faGlobeAmericas,
  faPen,
  faUnlink,
  faBold,
  faItalic,
  faUnderline,
  faQuoteRight,
  faList,
  faListOl,
  faLink,
  faSmile,
  faBracketsCurly,
  faEllipsisH,
  faExclamationTriangle,
  faCheck,
  faInfoCircle,
  faMailBulk,
  faUser,
  faUsers,
  faFileExport,
  faPauseCircle,
  faPlayCircle,
  faReply,
  faCalendar,
  faEye,
  faMousePointer,
  faPlus,
  faTasks,
  faTimes,
  faFolder,
  faFastForward,
  faForward,
  faClock,
  faFilter,
  faSearch,
  faEnvelope,
  faPhoneAlt,
  faRedo,
  faSpinnerThird
)
