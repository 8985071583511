import React from 'react'

/* eslint-disable */
const Dots = ({
  color,
  height,
  width,
  style = {},
  onClick,
  degrees = 0,
  className,
}) => (
  <svg onClick={onClick} fill={color || '#7A859F'} className={className} style={{ ...style, transform: `rotate(${degrees}deg)` }} width={width || 12} height={height || 12}  viewBox="0 0 408 408" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlnsXlink="http://www.w3.org/1999/xlink">
    <g>
        <g id="more-vert">
            <path d="M204,102c28.05,0,51-22.95,51-51S232.05,0,204,0s-51,22.95-51,51S175.95,102,204,102z M204,153c-28.05,0-51,22.95-51,51
                s22.95,51,51,51s51-22.95,51-51S232.05,153,204,153z M204,306c-28.05,0-51,22.95-51,51s22.95,51,51,51s51-22.95,51-51
                S232.05,306,204,306z"/>
        </g>
    </g>
  </svg>
);

export default Dots;
