import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import DropArea from 'components/DropArea'
import Button from 'components/Button'
import Label from 'elements/Label'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`

const DropAreaContent = styled.div`
  height: 100%;
  width: 100%;
  margin-right: 1rem;
  padding: 2rem;
  border-radius: 3px;
  border: ${props => `dashed 1px ${props.theme.colors.gray60}`};

  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  flex: 1;

  user-select: none;
  cursor: pointer;

  &:hover {
    background-color: ${props => props.theme.colors.blueGray};
  }
`

const Overlay = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: ${props => props.theme.colors.gray60};
  opacity: 0.3;
`

const DropContents = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const ButtonContainer = styled.div`
  margin: 2rem auto 1rem auto;
`

const SplitWrapper = styled.div`
  height: 100%;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
`

const Description = styled.p`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  margin: 0;
  padding-top: 0;
  margin-bottom: 1rem;
  font-size: 14px;
`

const Details = styled.div`
  padding-left: 1rem;
  text-align: left;
`

const Title = styled.h3`
  width: 100%;
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.formtitle};
  text-align: left;
  padding-top: 0;
  margin: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const List = styled.ul`
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  padding-left: 2rem;
  font-size: 14px;
`

const ListItem = styled.li`
  text-align: left;
`

const HelpLinks = styled.div`
  font: ${props => props.theme.fonts.small};
  color: ${props => props.theme.labelColor};
`

class ImportPicker extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      files: [],
      dropAreaActive: false
    }
  }

  handleOnChange = (event) => {
    const files = Array.from(event.target.files)
    this.setState({ files, dropAreaActive: false })
  }

  handleOnDrop = (event) => {
    const files = Array.from(event.dataTransfer.files)
    this.setState({ files, dropAreaActive: false })
  }

  handleOnDragEnter = () => {
    this.setState({ dropAreaActive: true })
  }

  handleOnDragLeave = () => {
    this.setState({ dropAreaActive: false })
  }

  render () {
    const { onFileSelect } = this.props
    const { dropAreaActive, files } = this.state

    const file = files[0]
    const valid = file && (file.name.endsWith('.csv') || file.name.endsWith('.xls') || file.name.endsWith('.xlsx'))

    // assign the correct file icon and label
    let fileIcon = null
    let label = null
    if (!file) {
      label = (
        <Label mt='1rem' mb='1rem' align='center'>
          Drag and drop your CSV here, or <u>browse</u> for a file to upload.
        </Label>
      )
      fileIcon = (
        <FontAwesomeIcon
          key='upload'
          icon={['fal', 'file-upload']}
          size='2x'
          color={dropAreaActive ? '#FFF' : '#7A859F'}
        />
      )
    } else if (file && !valid) {
      label = (
        <Label mt='1rem' mb='1rem' align='center'>
          {file.name}, is not a CSV. <u>Select</u> a CSV to upload.
        </Label>
      )
      fileIcon = (
        <FontAwesomeIcon
          key='invalid'
          icon={['fal', 'file-times']}
          size='2x'
          color={dropAreaActive ? '#FFF' : '#7A859F'}
        />
      )
    } else if (file && valid) {
      label = (
        <Label mt='1rem' mb='1rem' align='center'>
          {file.name}, <u>select</u> a different file?
        </Label>
      )
      fileIcon = (
        <FontAwesomeIcon
          key='upload'
          icon={['fal', 'file-check']}
          size='2x'
          color={dropAreaActive ? '#FFF' : '#7A859F'}
        />
      )
    }

    return (
      <Wrapper>
        <SplitWrapper>
          <DropArea
            key='drop-area'
            accept='.csv, .xlsx, .xls'
            style={{ height: '100%' }}
            onChange={this.handleOnChange}
            onDrop={this.handleOnDrop}
            onDragOver={this.handleOnDragEnter}
            onDragAway={this.handleOnDragLeave}
          >
            {dropAreaActive && <Overlay />}
            <DropContents>
              <DropAreaContent>
                {fileIcon}
                {label}
              </DropAreaContent>
            </DropContents>
          </DropArea>

          <Details>
            <Title>Uploading CSV Files</Title>
            <Description>
              Drag and drop a CSV or Excel file to the left to import contacts to your sequence.
            </Description>
            <List>
              <ListItem>You must have a header row and an email column</ListItem>
              <ListItem>You will have the option to look up missing emails using your lookup credits or to skip them</ListItem>
              <ListItem>Rows without an email address will be skipped and not imported</ListItem>
              <ListItem>If you choose to look up emails, it may take some time for them to import. You will receive an email with the results once it&apos;s complete. Rows with emails will not use a lookup credit.</ListItem>
            </List>
            <HelpLinks>
              <a
                href='https://s3.amazonaws.com/helpscout.net/docs/assets/5ec82f662c7d3a5ea54b9ce8/attachments/603c2f9624d2d21e45edaf25/Interseller---CSV-Format-for-Personal-Emails---Sheet1-(1).csv'
                target='_new'
              >Download example CSV
              </a>
              &nbsp;&nbsp;&mdash;&nbsp;&nbsp;
              <a
                href='https://help.interseller.io/article/8-importing-contacts-through-csv-files'
                target='_new'
              >Learn more
              </a>
            </HelpLinks>
          </Details>
        </SplitWrapper>

        <ButtonContainer>
          <Button
            label='Upload File'
            primary
            disabled={!file || !valid}
            handleClick={(e) => {
              e.stopPropagation()
              e.preventDefault()
              onFileSelect(file)
            }}
          />
        </ButtonContainer>
      </Wrapper>
    )
  }
}

ImportPicker.propTypes = {
  onFileSelect: PropTypes.func
}

export default ImportPicker
