import {
  // Fetch Tasks
  FETCH_TASKS_REQUEST,
  FETCH_TASKS_SUCCESS,
  FETCH_TASKS_FAILURE,

  // Fetch Messages
  FETCH_MESSAGES_REQUEST,
  FETCH_MESSAGES_SUCCESS,
  FETCH_MESSAGES_FAILURE,
  FETCH_MESSAGES_RESET,

  // Update Contact Message
  UPDATE_CONTACT_MESSAGE_REQUEST,
  UPDATE_CONTACT_MESSAGE_SUCCESS,
  UPDATE_CONTACT_MESSAGE_FAILURE,

  FETCH_CONTACT_ERRORS_REQUEST,
  FETCH_CONTACT_ERRORS_SUCCESS,
  FETCH_CONTACT_ERRORS_FAILURE,

  FETCH_CONTACT_ERRORS_STATS_REQUEST,
  FETCH_CONTACT_ERRORS_STATS_SUCCESS,
  FETCH_CONTACT_ERRORS_STATS_FAILURE,

  REMOVE_CONTACT_ERRORS_REQUEST,
  REMOVE_CONTACT_ERRORS_SUCCESS,
  REMOVE_CONTACT_ERRORS_FAILURE,

  REMOVE_CONTACT_REQUEST,
  REMOVE_CONTACT_SUCCESS,
  REMOVE_CONTACT_FAILURE,

  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,

  FETCH_REPLIED_CONTACT_REQUEST,
  FETCH_REPLIED_CONTACT_SUCCESS,
  FETCH_REPLIED_CONTACT_FAILURE,

  FETCH_SENTIMENT_SCORE_REQUEST,
  FETCH_SENTIMENT_SCORE_SUCCESS,
  FETCH_SENTIMENT_SCORE_FAILURE,

  UPDATE_CONTACT_ERRORS_REQUEST,

  UPDATE_CONTACT_SENTIMENT_REQUEST,
  UPDATE_CONTACT_SENTIMENT_SUCCESS,
  UPDATE_CONTACT_SENTIMENT_FAILURE,

  FETCH_MANUAL_TASKS_REQUEST,
  FETCH_MANUAL_TASKS_SUCCESS,
  FETCH_MANUAL_TASKS_FAILURE,

  FETCH_CONTACT_TASK_REQUEST,
  FETCH_CONTACT_TASK_SUCCESS,
  FETCH_CONTACT_TASK_FAILURE,
  FETCH_CONTACT_TASK_RESET,

  UPDATE_CONTACT_TASK_REQUEST,
  UPDATE_CONTACT_TASK_SUCCESS,
  UPDATE_CONTACT_TASK_FAILURE,

  FETCH_CONTACT_ACTIONS_REQUEST,
  FETCH_CONTACT_ACTIONS_SUCCESS,
  FETCH_CONTACT_ACTIONS_FAILURE,
  FETCH_CONTACT_ACTIONS_RESET,

  SET_PAUSE_CONTACT_TASK,
  CLEAR_PAUSE_CONTACT_TASK,

  SKIP_MANUAL_TASK_REQUEST,
  SKIP_MANUAL_TASK_SUCCESS,
  SKIP_MANUAL_TASK_FAILURE,

  SKIP_MANUAL_TASKS_REQUEST,
  SKIP_MANUAL_TASKS_SUCCESS,
  SKIP_MANUAL_TASKS_FAILURE
} from './constants'

export function fetchTasks (userId = null, sequenceId = null, step = null) {
  return {
    type: FETCH_TASKS_REQUEST,
    userId,
    sequenceId,
    step
  }
}

export function fetchTasksSuccess ({ tasks }) {
  return {
    type: FETCH_TASKS_SUCCESS,
    payload: {
      tasks
    }
  }
}

export function fetchTasksFailure (err) {
  return {
    type: FETCH_TASKS_FAILURE,
    err
  }
}

export function fetchContactErrors ({
  sort,
  skip,
  limit,
  error
}) {
  return {
    type: FETCH_CONTACT_ERRORS_REQUEST,
    sort,
    skip,
    limit,
    error
  }
}

export function fetchContactErrorsSuccess ({ contacts, hasMore, paginating }) {
  return {
    type: FETCH_CONTACT_ERRORS_SUCCESS,
    payload: {
      contacts,
      hasMore,
      paginating
    }
  }
}

export function fetchContactErrorsFailure (err) {
  return {
    type: FETCH_CONTACT_ERRORS_FAILURE,
    err
  }
}

export function fetchContactErrorsStats () {
  return {
    type: FETCH_CONTACT_ERRORS_STATS_REQUEST
  }
}

export function fetchContactErrorsStatsSuccess ({ stats }) {
  return {
    type: FETCH_CONTACT_ERRORS_STATS_SUCCESS,
    payload: {
      stats
    }
  }
}

export function fetchContactErrorsStatsFailure (err) {
  return {
    type: FETCH_CONTACT_ERRORS_STATS_FAILURE,
    err
  }
}

export function removeContactErrors ({
  contactIds,
  all = false,
  error
}) {
  return {
    type: REMOVE_CONTACT_ERRORS_REQUEST,
    contactIds,
    error,
    all
  }
}

export function removeContactErrorsSuccess ({ contactIds }) {
  return {
    type: REMOVE_CONTACT_ERRORS_SUCCESS,
    contactIds
  }
}

export function removeContactErrorsFailure (err) {
  return {
    type: REMOVE_CONTACT_ERRORS_FAILURE,
    err
  }
}

export function fetchContactMessages (contactId) {
  return {
    type: FETCH_MESSAGES_REQUEST,
    contactId
  }
}

export function fetchContactMessagesSuccess ({ messages }) {
  return {
    type: FETCH_MESSAGES_SUCCESS,
    payload: {
      messages
    }
  }
}

export function fetchContactMessagesFailure (err) {
  return {
    type: FETCH_MESSAGES_FAILURE,
    err
  }
}

export function fetchContactMessagesReset () {
  return {
    type: FETCH_MESSAGES_RESET
  }
}

export function updateContactMessage (contactId, step, subject, markdown, immediate = false) {
  return {
    type: UPDATE_CONTACT_MESSAGE_REQUEST,
    contactId,
    step,
    params: {
      subject,
      markdown,
      immediate,
      step
    }
  }
}

export function updateContactMessageSuccess ({ message, contactId, step }) {
  return {
    type: UPDATE_CONTACT_MESSAGE_SUCCESS,
    payload: {
      message,
      contactId,
      step
    }
  }
}

export function updateContactMessageFailure (err) {
  return {
    type: UPDATE_CONTACT_MESSAGE_FAILURE,
    err
  }
}

export function removeContact (contactId) {
  return {
    type: REMOVE_CONTACT_REQUEST,
    contactId
  }
}

export function removeContactSuccess ({ contactId }) {
  return {
    type: REMOVE_CONTACT_SUCCESS,
    contactId
  }
}

export function removeContactFailure (err) {
  return {
    type: REMOVE_CONTACT_FAILURE,
    err
  }
}

export function updateContact (contactId, data, { sequenceId } = {}) {
  return {
    type: UPDATE_CONTACT_REQUEST,
    contactId,
    data,
    sequenceId
  }
}

export function updateContactSuccess ({ contact }) {
  return {
    type: UPDATE_CONTACT_SUCCESS,
    payload: {
      contact
    }
  }
}

export function updateContactFailure (err) {
  return {
    type: UPDATE_CONTACT_FAILURE,
    err
  }
}

export function updateContactErrors ({
  contactIds,
  all = false,
  error,
  data
}) {
  return {
    type: UPDATE_CONTACT_ERRORS_REQUEST,
    contactIds,
    all,
    error,
    data
  }
}

export function fetchContactActions (contactId) {
  return {
    type: FETCH_CONTACT_ACTIONS_REQUEST,
    contactId
  }
}

export function fetchContactActionsSuccess ({ actions }) {
  return {
    type: FETCH_CONTACT_ACTIONS_SUCCESS,
    payload: {
      actions
    }
  }
}

export function fetchContactActionsFailure (err) {
  return {
    type: FETCH_CONTACT_ACTIONS_FAILURE,
    err
  }
}

export function fetchContactActionsReset () {
  return {
    type: FETCH_CONTACT_ACTIONS_RESET
  }
}

export function fetchRepliedContact (userId) {
  return {
    type: FETCH_REPLIED_CONTACT_REQUEST,
    userId
  }
}

export function fetchRepliedContactSuccess ({ contacts }) {
  return {
    type: FETCH_REPLIED_CONTACT_SUCCESS,
    payload: {
      contacts
    }
  }
}

export function fetchRepliedContactFailure (err) {
  return {
    type: FETCH_REPLIED_CONTACT_FAILURE,
    err
  }
}

export function fetchSentimentScore (contactId) {
  return {
    type: FETCH_SENTIMENT_SCORE_REQUEST,
    contactId
  }
}

export function fetchSentimentScoreSuccess ({ sentimentScore }) {
  return {
    type: FETCH_SENTIMENT_SCORE_SUCCESS,
    payload: {
      sentimentScore
    }
  }
}

export function fetchSentimentScoreFailure (err) {
  return {
    type: FETCH_SENTIMENT_SCORE_FAILURE,
    err
  }
}

export function updateContactSentiment (sentimentAction, contactId, userId) {
  return {
    type: UPDATE_CONTACT_SENTIMENT_REQUEST,
    sentimentAction,
    contactId,
    userId
  }
}

export function updateContactSentimentSuccess ({ contactId }) {
  return {
    type: UPDATE_CONTACT_SENTIMENT_SUCCESS,
    payload: {
      contactId
    }
  }
}

export function updateContactSentimentFailure (err) {
  return {
    type: UPDATE_CONTACT_SENTIMENT_FAILURE,
    err
  }
}

export function fetchManualTasks (userId = null, sequenceId = null, step = null) {
  return {
    type: FETCH_MANUAL_TASKS_REQUEST,
    userId,
    sequenceId,
    step
  }
}

export function fetchManualTasksSuccess ({ manualTasks }) {
  return {
    type: FETCH_MANUAL_TASKS_SUCCESS,
    payload: {
      manualTasks
    }
  }
}

export function fetchManualTasksFailure (err) {
  return {
    type: FETCH_MANUAL_TASKS_FAILURE,
    err
  }
}

export function fetchContactTask (contactId, stepIndex) {
  return {
    type: FETCH_CONTACT_TASK_REQUEST,
    contactId,
    stepIndex
  }
}

export function fetchContactTaskSuccess ({ contactTask }) {
  return {
    type: FETCH_CONTACT_TASK_SUCCESS,
    payload: {
      contactTask
    }
  }
}

export function fetchContactTaskFailure (err) {
  return {
    type: FETCH_CONTACT_TASK_FAILURE,
    err
  }
}

export function fetchContactTaskReset () {
  return {
    type: FETCH_CONTACT_TASK_RESET
  }
}

export function updateContactTask (contactId, stepIndex, markdown, { completed, replied, sequenceId }) {
  return {
    type: UPDATE_CONTACT_TASK_REQUEST,
    contactId,
    stepIndex,
    markdown,
    sequenceId,
    completed,
    replied
  }
}

export function updateContactTaskSuccess ({ contactTask, contactId }) {
  return {
    type: UPDATE_CONTACT_TASK_SUCCESS,
    payload: {
      contactTask,
      contactId
    }
  }
}

export function updateContactTaskFailure (err) {
  return {
    type: UPDATE_CONTACT_TASK_FAILURE,
    err
  }
}

export function setPauseContactTask (task) {
  return {
    type: SET_PAUSE_CONTACT_TASK,
    payload: task
  }
}

export function clearPauseContactTask () {
  return {
    type: CLEAR_PAUSE_CONTACT_TASK
  }
}

export function skipManualTask (contactId, stepIndex, sequenceId) {
  return {
    type: SKIP_MANUAL_TASK_REQUEST,
    contactId,
    stepIndex,
    sequenceId
  }
}

export function skipManualTaskSuccess ({ contactId }) {
  return {
    type: SKIP_MANUAL_TASK_SUCCESS,
    payload: {
      contactId
    }
  }
}

export function skipManualTaskFailure (err) {
  return {
    type: SKIP_MANUAL_TASK_FAILURE,
    err
  }
}

export function skipManualTasks (contactIds) {
  return {
    type: SKIP_MANUAL_TASKS_REQUEST,
    contactIds
  }
}

export function skipManualTasksSuccess ({ contactIds }) {
  return {
    type: SKIP_MANUAL_TASKS_SUCCESS,
    payload: {
      contactIds
    }
  }
}

export function skipManualTasksFailure (err) {
  return {
    type: SKIP_MANUAL_TASKS_FAILURE,
    err
  }
}
