import React, { PureComponent } from 'react'
import { withRouter } from 'react-router'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { pluralize } from 'utils/strings'
import DropDownFieldForm from 'components/DropDownFieldForm'
import Button from 'components/Button'
import ConfirmModal from 'components/ConfirmModal'

const Wrapper = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding: 1rem;
`

const Danger = styled.span`
  color: ${props => props.theme.colors.red};
`

class ImportLookupPicker extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      lookup: false
    }
  }

  render () {
    const {
      importCsv,
      onSubmit,
      goBack,
      mapping
    } = this.props

    const {
      lookup,
      warningMessage
    } = this.state

    const csv = importCsv.get('data')
    const csvId = csv.get('_id')

    let label = 'Lookup missing emails'

    // skip this step if there's nothing needed to be found
    const emailColumnIndex = mapping.email
    if (Number.isInteger(emailColumnIndex)) {
      const emailColumn = csv.getIn(['columns', emailColumnIndex])
      const missingEmailCount = emailColumn.get('missing')

      if (missingEmailCount === 0) {
        onSubmit(csvId, false)
      }

      if (Number.isInteger(missingEmailCount)) {
        label = `Lookup ${missingEmailCount} missing ${pluralize('email', 'emails', missingEmailCount)}`
      }
    }

    const listOptions = Immutable.List([
      {
        value: false,
        label: 'Don\'t lookup any contacts missing emails & skip them'
      },
      {
        value: 'WORK',
        label: 'Find work emails for contacts missing emails (slow import & uses lookup credits)'
      },
      {
        value: 'PERSONAL',
        label: 'Find personal emails for contacts missing emails (slow import & uses lookup credits)'
      }
    ])

    return (
      <Wrapper>
        <DropDownFieldForm
          label={label}
          description={
            <span>
              Find missing emails in your import using either their name, company name, company website or profile URL. Finding personal emails requires a profile URL. <Danger>Turning this option on will use your lookup credits and can slow down your import significantly.</Danger>
            </span>
          }
          clearable={false}
          options={listOptions}
          value={lookup}
          inputId='lookup'
          onOptionChange={(option) => {
            this.setState({
              lookup: option
            })
          }}
          mb='2rem'
        />
        <Button
          label='Import CSV'
          handleClick={() => {
            if (this.state.lookup === 'PERSONAL' && !mapping.profile_url) {
              this.setState({
                warningMessage: 'In order to look up personal emails, you need to map a Profile URL. Continuing will not find any new emails. Are you sure you want to continue?'
              })
            } else if (this.state.lookup === 'WORK' && mapping.company != null && mapping.website != null) {
              this.setState({
                warningMessage: 'In order to look up work emails, you need to map the company\'s website to the Website URL field. Continuing will not find any new emails. Are you sure you want to continue?'
              })
            } else {
              onSubmit(csvId, lookup)
            }
          }}
        />
        <ConfirmModal
          title='Are you sure?'
          description={warningMessage}
          onConfirm={() => {
            onSubmit(csvId, lookup)
          }}
          onCancel={() => {
            goBack()
            this.setState({
              warningMessage: null
            })
          }}
          cancelLabel='Edit Mapping'
          confirmLabel='Continue'
          persistent
          isOpen={!!warningMessage}
        />
      </Wrapper>
    )
  }
}

ImportLookupPicker.propTypes = {
  importCsv: PropTypes.instanceOf(Immutable.Map),
  mapping: PropTypes.object,
  onSubmit: PropTypes.func,
  goBack: PropTypes.func
}

export default withRouter(ImportLookupPicker)
