import React, { PureComponent } from 'react'
import Immutable from 'immutable'
import R from 'utils/ramda'
import PropTypes from 'prop-types'
import Wrapper from './Wrapper'
import StepBox from './StepBox'

class StepList extends PureComponent {
  constructor (props) {
    super(props)
    this.state = {
      selectedContactId: props.selectedContactId
    }
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    if (!this.state.selectedContactIndex) {
      const selectedContactIndex = this.indexOfContact(nextProps)
      if (selectedContactIndex >= 0) {
        this.setState({
          selectedContactIndex: selectedContactIndex + 1
        })
      }
    }
  }

  indexOfContact = (props) => {
    const {
      contacts,
      selectedContactId
    } = props

    if (contacts && selectedContactId) {
      return contacts.findIndex(c => (c.get('_id') === selectedContactId))
    }
    return null
  }

  getStepsMap = (steps) => {
    const byStep = R.groupBy(option => (option.get('step')))

    let stepsMap
    if (steps.get('data')) {
      stepsMap = byStep(steps.get('data'))
    }

    return stepsMap
  }

  getStepsIdMap = (steps) => {
    const byStep = R.indexBy(option => (option.get('_step')))

    let stepsMap
    if (steps.get('data')) {
      stepsMap = byStep(steps.get('data'))
    }

    return stepsMap
  }

  getStepByIndex = (stepsMap, stepIndex) => {
    if (!stepsMap) {
      return null
    }

    if (!stepsMap.get(stepIndex)) {
      return null
    }

    const steps = stepsMap.get(stepIndex)
    if (!steps) {
      return null
    }

    return steps.first()
  }

  getStepById = (stepsIdMap, stepId) => {
    if (!stepsIdMap) {
      return null
    }

    return stepsIdMap[stepId]
  }

  render () {
    const {
      sequence,
      selectedStepIndex,
      onStepSelected,
      testSelected,
      contactSteps,
      previewSteps,
      showPersonalized
    } = this.props

    const { selectedContactId } = this.state

    const sequenceSteps = sequence.get('steps') || []
    const contactStepsMap = this.getStepsMap(contactSteps)
    const previewStepsMap = this.getStepsMap(previewSteps)
    const previewStepsIdMap = this.getStepsIdMap(previewSteps)
    const otherSequenceSteps = sequence.get('other_steps') || Immutable.List([])
    const otherSequenceStepsMap = otherSequenceSteps.reduce((acc, step) => {
      acc[step.get('_step')] = step
      return acc
    }, {})

    const subject = sequence.get('subject')

    let subjects = Immutable.List()
    subjects = subjects.push(subject)

    const stepRows = sequenceSteps.map((sequenceStep, stepIndex) => {
      const stepId = sequenceStep.get('_id')

      const sequenceTestStep = otherSequenceStepsMap[stepId]
      const contactStep = this.getStepByIndex(contactStepsMap, stepIndex)
      const previewStep = this.getStepByIndex(previewStepsMap, stepIndex)
      const previewTestStep = sequenceTestStep ? this.getStepById(previewStepsIdMap, sequenceTestStep.get('_id')) : null

      const contactStepSubject = contactStep ? contactStep.getIn(['object', 'subject']) : null
      const previewStepSubject = previewStep ? previewStep.getIn(['object', 'subject']) : null
      const sequenceStepSubject = sequenceStep ? sequenceStep.get('subject') : null

      // determine the subject line to display
      let re = false
      if (contactStepSubject) {
        subjects = subjects.push(contactStepSubject)
      } else if (previewStepSubject) {
        subjects = subjects.push(previewStepSubject)
      } else if (sequenceStepSubject) {
        subjects = subjects.push(sequenceStepSubject)
      } else {
        re = true
      }

      let stepSubject = subjects.last()
      if (re) {
        stepSubject = `RE: ${stepSubject}`
      }

      return (
        <StepBox
          key={stepId}
          data-index={stepIndex}
          sequence={sequence}
          contactStep={contactStep}
          previewStep={previewStep}
          previewTestStep={!selectedContactId && previewTestStep}
          sequenceStep={sequenceStep}
          sequenceTestStep={!selectedContactId && sequenceTestStep}
          testSelected={testSelected}
          subject={stepSubject}
          stepIndex={stepIndex}
          selected={selectedStepIndex === stepIndex}
          showPersonalized={showPersonalized}
          onClick={(selectedTestStep) => {
            onStepSelected(stepIndex, selectedTestStep)
          }}
        />
      )
    })

    return (
      <Wrapper>
        {!contactSteps.get('loading') && stepRows}
      </Wrapper>
    )
  }
}

StepList.propTypes = {
  contacts: PropTypes.instanceOf(Immutable.List),
  sequence: PropTypes.object,
  selectedStepIndex: PropTypes.number,
  onStepSelected: PropTypes.func,
  contactSteps: PropTypes.object,
  previewSteps: PropTypes.object,
  selectedContactId: PropTypes.string,
  testSelected: PropTypes.bool,
  togglePersonalized: PropTypes.func,
  showPersonalized: PropTypes.bool
}

StepList.defaultProps = {
  selectedStepIndex: 0,
  contacts: Immutable.List([])
}

export default StepList
