import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { AllHtmlEntities } from 'html-entities'

const Container = styled.div`
  padding: ${props => props.theme.padding};
  padding-bottom: calc(${props => props.theme.padding} - 1px);
  display: flex;
  flex-direction: row;
  max-width: 100%;
  width: 100%;
  background: ${props => (props.selected ? props.theme.highlightBackground : props.theme.containerBackground)};
  cursor: ${props => (props.selected ? 'cursor' : 'pointer')};
`

const PreviewWrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  align-items: start;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  margin-right: 0.5rem;
`

const Subject = styled.div`
  font: ${props => props.theme.fonts.small};
  font-weight: 700;
  color: ${props => props.theme.titleColor};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  line-height: 1.25rem;
`

const Body = styled.div`
  font: ${props => props.theme.fonts.small};
  color: ${props => props.theme.titleColor};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  margin-top: auto;
  margin-bottom: auto;
  text-align: left;
  line-height: 1.25rem;
`

const DoubleBody = styled(Body)`
  white-space: normal;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
`

const SelectedIndictator = styled.div`
  background-color: ${props => props.theme.colors.blue};

  width: 10px;
  height: 10px;
  border-radius: 100%;

  margin-top: auto;
  margin-bottom: auto;
  margin-right: 0.75rem;

  position: relative;
`

class StepContent extends Component {
  render () {
    const {
      subject,
      body,
      selected = false,
      onClick,
      hideSubject = false
    } = this.props

    const entities = new AllHtmlEntities()
    const decodedBody = entities.decode(body).trim()

    if (hideSubject) {
      return (
        <Container selected={selected} onClick={onClick}>
          {selected && <SelectedIndictator />}
          <PreviewWrapper>
            <DoubleBody>{decodedBody}</DoubleBody>
          </PreviewWrapper>
        </Container>
      )
    }

    return (
      <Container selected={selected} onClick={onClick}>
        {selected && <SelectedIndictator />}
        <PreviewWrapper>
          <Subject>{subject}</Subject>
          <Body>{decodedBody}</Body>
        </PreviewWrapper>
      </Container>
    )
  }
}

StepContent.propTypes = {
  subject: PropTypes.string,
  body: PropTypes.string,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  hideSubject: PropTypes.bool
}

export default withTheme(StepContent)
