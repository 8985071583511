import { fromJS } from 'immutable'
import { put, takeLatest, call } from 'redux-saga/effects'
import api, { messageFromError } from 'utils/api'

import { setNotification } from 'containers/App/actions'
import { NOTIFICATION_TYPES } from 'containers/App/constants'

import {
  fetchCrmListSuccess,
  fetchCrmListFailure,
  fetchCrmSuccess,
  fetchCrmFailure,
  updateCrmSuccess,
  updateCrmFailure,
  deleteCrmSuccess,
  deleteCrmFailure,
  createCrmSuccess,
  createCrmFailure,
  fetchSequenceFieldsSuccess,
  fetchSequenceFieldsFailure,
  fetchZapsSuccess,
  fetchZapsFailure,
  fetchZapTemplatesSuccess,
  fetchZapTemplatesFailure,
  deleteCronSuccess,
  deleteCronFailure,
  fetchApiKeySuccess,
  fetchApiKeyFailure,
  cloneSettingsSuccess,
  cloneSettingsFailure
} from './actions'
import {
  FETCH_CRM_LIST_REQUEST,
  FETCH_CRM_REQUEST,
  REFRESH_CRM_REQUEST,
  UPDATE_CRM_REQUEST,
  DELETE_CRM_REQUEST,
  CREATE_CRM_REQUEST,
  FETCH_SEQUENCE_FIELDS_REQUEST,
  FETCH_ZAPS_REQUEST,
  FETCH_ZAP_TEMPLATES_REQUEST,
  DELETE_CRON_REQUEST,
  FETCH_API_KEY_REQUEST,
  CLONE_SETTINGS_REQUEST
} from './constants'

const get = (url, query) => api.get(url, {
  params: query
})
const post = (url, params) => api.post(url, params)
const update = (url, params) => api.put(url, params)
const remove = url => api.delete(url)

export function * fetchCrmList () {
  try {
    const response = yield call(get, '/crm/list')
    const crms = fromJS(response.data)

    yield put(fetchCrmListSuccess({
      crms
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(fetchCrmListFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchCrm (action) {
  const params = action.params
  try {
    const response = yield call(api.get, '/crm', { params, timeout: 60000 })
    const crm = fromJS(response.data)

    yield put(fetchCrmSuccess({
      crm
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(fetchCrmFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * updateCrm (action) {
  const params = action.params
  try {
    const response = yield call(update, '/crm', params)
    const crm = fromJS(response.data)

    yield put(updateCrmSuccess({
      crm
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Integration settings updated! 🚀'
    }))
  } catch (err) {
    // ignore failed error according to
    yield put(updateCrmFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * deleteCrm () {
  try {
    yield call(remove, '/crm')
    yield put(deleteCrmSuccess())
  } catch (err) {
    // ignore failed error according to
    yield put(deleteCrmFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * deleteCron (action) {
  const id = action.params.id
  const path = `/crm/crm_import/${id}`
  try {
    yield call(remove, path)
    yield put(deleteCronSuccess(id))
  } catch (err) {
    // ignore failed error according to
    yield put(deleteCronFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * createCrm (action) {
  const params = {
    type: action.crmType,
    auth: action.auth
  }
  try {
    const response = yield call(api.post, '/crm', params, { timeout: 60000 })
    const crm = fromJS(response.data)
    yield put(createCrmSuccess({
      crm
    }))
  } catch (err) {
    yield put(createCrmFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchSequenceFields () {
  try {
    const response = yield call(get, '/campaigns/fields')
    const fields = fromJS(response.data)

    yield put(fetchSequenceFieldsSuccess({
      fields
    }))
  } catch (err) {
    yield put(fetchSequenceFieldsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchZaps () {
  try {
    const response = yield call(get, '/zapier/zaps')
    const zaps = fromJS(response.data)

    yield put(fetchZapsSuccess({
      zaps
    }))
  } catch (err) {
    yield put(fetchZapsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchZapTemplates () {
  try {
    const response = yield call(get, '/zapier/templates')
    const zapTemplates = fromJS(response.data)

    yield put(fetchZapTemplatesSuccess({
      zapTemplates
    }))
  } catch (err) {
    yield put(fetchZapTemplatesFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * fetchApiKey (action) {
  try {
    const requestType = action.regenerate ? update : post
    const response = yield call(requestType, '/me/api_key')
    const data = fromJS(response.data)

    yield put(fetchApiKeySuccess(data.get('api_key')))
  } catch (err) {
    yield put(fetchApiKeyFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

export function * cloneSettings () {
  try {
    const response = yield call(update, '/crm/clone_settings')
    const modified = fromJS(response.data)

    yield put(cloneSettingsSuccess({
      modified
    }))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.SUCCESS,
      message: 'Successfully cloned settings!'
    }))
  } catch (err) {
    yield put(cloneSettingsFailure(err))
    yield put(setNotification({
      type: NOTIFICATION_TYPES.ERROR,
      message: messageFromError(err)
    }))
  }
}

function * fetchCrmListRequest () {
  yield takeLatest(FETCH_CRM_LIST_REQUEST, fetchCrmList)
}

function * fetchCrmRequest () {
  yield takeLatest(FETCH_CRM_REQUEST, fetchCrm)
  yield takeLatest(REFRESH_CRM_REQUEST, fetchCrm)
}

function * updateCrmRequest () {
  yield takeLatest(UPDATE_CRM_REQUEST, updateCrm)
}

function * deleteCrmRequest () {
  yield takeLatest(DELETE_CRM_REQUEST, deleteCrm)
}

function * deleteCronRequest () {
  yield takeLatest(DELETE_CRON_REQUEST, deleteCron)
}

function * createCrmRequest () {
  yield takeLatest(CREATE_CRM_REQUEST, createCrm)
}

function * fetchSequenceFieldsRequest () {
  yield takeLatest(FETCH_SEQUENCE_FIELDS_REQUEST, fetchSequenceFields)
}

function * fetchZapsRequest () {
  yield takeLatest(FETCH_ZAPS_REQUEST, fetchZaps)
}

function * fetchZapTemplatesRequest () {
  yield takeLatest(FETCH_ZAP_TEMPLATES_REQUEST, fetchZapTemplates)
}

function * fetchApiKeyRequest () {
  yield takeLatest(FETCH_API_KEY_REQUEST, fetchApiKey)
}

function * cloneSettingsRequest () {
  yield takeLatest(CLONE_SETTINGS_REQUEST, cloneSettings)
}

export default [
  fetchCrmListRequest,
  fetchCrmRequest,
  updateCrmRequest,
  deleteCrmRequest,
  createCrmRequest,
  deleteCronRequest,
  fetchSequenceFieldsRequest,
  fetchZapsRequest,
  fetchZapTemplatesRequest,
  fetchApiKeyRequest,
  cloneSettingsRequest
]
