import Immutable from 'immutable'

const errors = {
  CRM_VALIDATION: 'Integration Error',
  MISSING_VARIABLE: 'Missing Info',
  BLACKLISTED: 'Blocklist',
  BLOCKED_CONTACT: 'Blocklist',
  TEAM_BLACKLISTED: 'Team Blocklist',
  TEAM_BLOCKED_CONTACT: 'Team Blocklist',
  INVALID: 'Invalid Email',
  DUPLICATE_CAMPAIGN: 'Exists in Sequence',
  DUPLICATE_TEAM: 'Exists in Team',
  DUPLICATE_DOMAIN: 'Teammate\'s Account',
  DUPLICATE_CRM: 'Exists in CRM',
  UNSUBSCRIBED: 'Unsubscribed',
  REPLIED_DOMAIN: 'Replied for Domain',
  REPLIED_CAMPAIGN: 'Replied to Sequence',
  REPLIED_INDIRECTLY: 'Replied Outside Sequences',
  REPLIED_TEAM: 'Replied to Teammate',
  BOUNCED: 'Bounced',
  MISSING_EMAIL: 'Missing Email'
}

export function contactErrorToString (error, crm = 'CRM') {
  if (error === 'DUPLICATE_CRM') {
    return `Exists in ${crm}`
  }

  if (error === 'CRM_VALIDATION') {
    return `${crm} Error`
  }

  if (errors[error]) {
    return errors[error]
  }

  return error || 'Error'
}

export function contactErrorsToOptions () {
  const errorOptions = []

  Object.keys(errors).map((key) => {
    return errorOptions.push({
      value: key,
      label: errors[key]
    })
  })

  errorOptions.sort(function (a, b) {
    return a.label.localeCompare(b.label)
  })

  return Immutable.List(errorOptions)
}
