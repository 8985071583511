import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import { connect } from 'react-redux'
import { bindActionCreators } from 'redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'

import { apiBaseURI } from 'utils/api'
import { persistentError, persistentWarning, reconnect } from 'utils/toast'
import { bootSession } from 'utils/user'
// import { guessBrowserTimezone } from 'utils/dates'

import { updateTimezone, setNotification } from 'containers/App/actions'

import Loading from 'components/Loading'

import {
  selectSession,
  selectState,
  selectLoading
} from 'containers/App/selectors'
import Navigation from 'containers/Navigation'

const Wrapper = styled.div`
  background: ${props => props.theme.background};
  -webkit-app-region: drag;
  height: 100%;
  width: 100%;
  flex: 1 1 auto;
  flex-direction: column;
`

const ContentContainer = styled.div`
  width: 100%;
  height: 100%;
`

class EnsureLoggedIn extends PureComponent {
  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      session,
      loading,
      router,
      state
    } = newProps
    if (!session && !loading) {
      router.push('/login')
    }

    if (session && session !== this.props.session) {
      // this.props.actions.updateTimezone(guessBrowserTimezone())
      bootSession(session)
    }

    if (state && state !== this.props.state) {
      if (window.sp) {
        window.sp.status({
          success: (data) => {
            const {
              // description,
              indicator
            } = data.status
            if (indicator === 'critical' && indicator === 'major') {
              persistentError(
                'Interseller is currently experiencing a major outage, click here to learn more',
                () => {
                  window.open(data.page.url)
                }
              )
            } else if (indicator === 'minor') {
              persistentWarning(
                'Interseller is currently experiencing minor service disruptions, click here to learn more',
                () => {
                  window.open(data.page.url)
                }
              )
            }
          }
        })
      }

      const auth = state.get('auth')
      const crmAuth = state.get('crm_auth')
      const crmName = state.get('crm_name') || 'CRM/ATS'
      const accountName = state.get('account_name') || 'email'

      // TODO: Check session for current email provider and show logo and name
      if (auth && this.props.state.get('auth') !== auth) {
        reconnect(
          `Connection with your ${accountName} account has been lost. Emails will not send or sync until you reconnect.`,
          () => {
            const redirect = `${window.location.protocol}//${window.location.hostname + (window.location.port ? `:${window.location.port}` : '')}/`
            window.location = `${apiBaseURI}${auth}&redirect=${redirect}`
          }
        )
      }

      // TODO: Check session for current integration and show logo and name
      if (crmAuth && this.props.state.get('crm_auth') !== crmAuth) {
        reconnect(
          `We no longer have access to your ${crmName} account. Syncing with ${crmName} has been paused and may affect newly added contacts from receiving emails.`,
          () => {
            const redirect = `${window.location.protocol}//${window.location.hostname + (window.location.port ? `:${window.location.port}` : '')}/integrations/crm`
            window.location = `${apiBaseURI}${crmAuth}?redirect=${redirect}`
          }
        )
      }

      const paywall = state.get('paywall')

      if (paywall && this.props.state.get('paywall') !== paywall) {
        this.props.router.push('/paywall')
      }
    }
  }

  render () {
    const {
      session
    } = this.props
    return (
      <Wrapper>
        {session &&
          <>
            <Navigation
              session={this.props.session}
              state={this.props.state}
              handleLogout={() => {
                window.location = `${apiBaseURI}/logout`
              }}
            />
            <ContentContainer>
              {this.props.children}
            </ContentContainer>
          </>}
        {!session && <Loading />}
      </Wrapper>
    )
  }
}

EnsureLoggedIn.propTypes = {
  children: PropTypes.object.isRequired,
  router: PropTypes.object,
  session: PropTypes.object,
  state: PropTypes.object,
  loading: PropTypes.bool,
  actions: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  session: selectSession(),
  state: selectState(),
  loading: selectLoading()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      updateTimezone,
      setNotification
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(EnsureLoggedIn)
