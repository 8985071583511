import React from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space } from 'styled-system'

import Label from 'elements/Label'

import { RocketCrash } from 'svg'

const Wrapper = styled.div`
  color: white;
  text-align: center;
  border-radius: 9999px;
  flex: 0 0 auto;
  display: flex;
  flex-direction: column;
  padding: .25rem;
  align-items: center;
  justify-content: center;
  height: 100%;

  ${space}
`

const ComponentError = ({
  message = 'We\'re sorry — something\'s gone wrong. Our team has been notified!',
  ...rest
}) => (
  <Wrapper {...rest}>
    <RocketCrash />
    <Label mt='2rem' mb='0' align='center'>
      {message}
    </Label>
  </Wrapper>
)

ComponentError.propTypes = {
  message: PropTypes.string
}

export default ComponentError
