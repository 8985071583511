import {
  FETCH_TALENT_POOL_REQUEST,
  FETCH_TALENT_POOL_SUCCESS,
  FETCH_TALENT_POOL_FAILURE,
  FETCH_MORE_TALENTS_REQUEST,
  FETCH_MORE_TALENTS_SUCCESS,
  FETCH_MORE_TALENTS_FAILURE,
  UPDATE_TALENT_POOL_REQUEST,
  UPDATE_TALENT_POOL_SUCCESS,
  UPDATE_TALENT_POOL_FAILURE,
  CREATE_TALENT_REQUEST,
  CREATE_TALENT_SUCCESS,
  CREATE_TALENT_FAILURE,
  ADD_TALENT_TO_SEQUENCE_REQUEST,
  ADD_TALENT_TO_SEQUENCE_SUCCESS,
  ADD_TALENT_TO_SEQUENCE_FAILURE,
  DELETE_CANDIDATE_REQUEST,
  DELETE_CANDIDATE_SUCCESS,
  DELETE_CANDIDATE_FAILURE
} from './constants'

export function fetchTalentPool (query) {
  return {
    type: FETCH_TALENT_POOL_REQUEST,
    query
  }
}

export function fetchTalentPoolSuccess ({ data }) {
  return {
    type: FETCH_TALENT_POOL_SUCCESS,
    payload: {
      data
    }
  }
}

export function fetchTalentPoolFailure (err) {
  return {
    type: FETCH_TALENT_POOL_FAILURE,
    err
  }
}

export function fetchMoreTalents (params) {
  return {
    type: FETCH_MORE_TALENTS_REQUEST,
    params
  }
}

export function fetchMoreTalentsSuccess ({ data }) {
  return {
    type: FETCH_MORE_TALENTS_SUCCESS,
    payload: {
      data
    }
  }
}

export function fetchMoreTalentsFailure (err) {
  return {
    type: FETCH_MORE_TALENTS_FAILURE,
    err
  }
}

export function updateTalentPool (records) {
  return {
    type: UPDATE_TALENT_POOL_REQUEST,
    records
  }
}

export function updateTalentPoolSuccess ({ data }) {
  return {
    type: UPDATE_TALENT_POOL_SUCCESS,
    data
  }
}

export function updateTalentPoolFailure (err) {
  return {
    type: UPDATE_TALENT_POOL_FAILURE,
    err
  }
}

export function createTalent (params) {
  return {
    type: CREATE_TALENT_REQUEST,
    params
  }
}

export function createTalentSuccess (data) {
  return {
    type: CREATE_TALENT_SUCCESS,
    payload: {
      data
    }
  }
}

export function createTalentFailure (err) {
  return {
    type: CREATE_TALENT_FAILURE,
    err
  }
}

export function addTalentToSequence (params) {
  return {
    type: ADD_TALENT_TO_SEQUENCE_REQUEST,
    params
  }
}

export function addTalentToSequenceSuccess (data) {
  return {
    type: ADD_TALENT_TO_SEQUENCE_SUCCESS,
    payload: {
      data
    }
  }
}

export function addTalentToSequenceFailure (err) {
  return {
    type: ADD_TALENT_TO_SEQUENCE_FAILURE,
    err
  }
}

export function deleteCandidate (peopleIds, message) {
  return {
    type: DELETE_CANDIDATE_REQUEST,
    peopleIds,
    message
  }
}

export function deleteCandidateSuccess (data) {
  return {
    type: DELETE_CANDIDATE_SUCCESS,
    payload: {
      ...data
    }
  }
}

export function deleteCandidateFailure (err) {
  return {
    type: DELETE_CANDIDATE_FAILURE,
    err
  }
}
