import React, { Component } from 'react'
import PropTypes from 'prop-types'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import { selectState } from 'containers/App/selectors'
import SideNavigation from 'components/SideNavigation'
import { updateSession } from 'containers/Settings/actions'
import { selectSession, selectUpdateSessionError } from 'containers/Settings/selectors'
import { success } from 'utils/toast'
import Wrapper from './Wrapper'
import {
  selectCrmList,
  selectActiveCrm,
  selectSequenceFields,
  selectZaps,
  selectZapTemplates,
  selectApiKey
} from './selectors'
import {
  fetchCrmList,
  fetchActiveCrm,
  refreshActiveCrm,
  deleteCrm,
  updateCrm,
  createCrm,
  fetchSequenceFields,
  fetchZaps,
  fetchZapTemplates,
  deleteCron,
  fetchApiKey,
  cloneSettings
} from './actions'
import Immutable from 'immutable'

class Integrations extends Component {
  UNSAFE_componentWillMount () {
    document.title = 'Interseller | Integrations'

    const query = this.props.location.query || {}

    if (query.reconnected) {
      success('Successfully reconnected!')
      delete query.reconnected
      this.props.router.push({
        ...this.props.location,
        query
      })
    }
  }

  onSettingUpdate = (params) => {
    this.props.actions.updateSession(params)
  }

  render () {
    const {
      actions,
      children,
      crmList,
      activeCRM,
      sequenceFields,
      session,
      zaps,
      zapTemplates,
      router,
      state,
      apiKey
    } = this.props

    return (
      <Wrapper>
        <SideNavigation state={state} session={session} />
        {children && React.cloneElement(children, {
          actions,
          crmList,
          activeCRM,
          sequenceFields,
          session,
          zaps,
          zapTemplates,
          onSettingUpdate: this.onSettingUpdate,
          router,
          state,
          apiKey
        })}
      </Wrapper>
    )
  }
}

Integrations.propTypes = {
  children: PropTypes.object.isRequired,
  actions: PropTypes.object,
  crmList: PropTypes.object,
  activeCRM: PropTypes.instanceOf(Immutable.Map),
  session: PropTypes.object,
  sequenceFields: PropTypes.object,
  zaps: PropTypes.object,
  zapTemplates: PropTypes.object,
  router: PropTypes.object,
  state: PropTypes.object,
  apiKey: PropTypes.object,
  location: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  crmList: selectCrmList(),
  activeCRM: selectActiveCrm(),
  session: selectSession(),
  sequenceFields: selectSequenceFields(),
  zaps: selectZaps(),
  zapTemplates: selectZapTemplates(),
  updateError: selectUpdateSessionError(),
  state: selectState(),
  apiKey: selectApiKey()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchCrmList,
      fetchActiveCrm,
      refreshActiveCrm,
      deleteCrm,
      updateCrm,
      createCrm,
      deleteCron,
      fetchSequenceFields,
      fetchZaps,
      fetchZapTemplates,
      updateSession,
      fetchApiKey,
      cloneSettings
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Integrations)
