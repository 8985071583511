import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import { bindActionCreators } from 'redux'
import { connect } from 'react-redux'
import { createStructuredSelector } from 'reselect'
import styled from 'styled-components'
import SideNavigation from 'components/SideNavigation'
import Wrapper from './Wrapper'
import { selectState } from 'containers/App/selectors'
import { deleteAuthToken, fetchSessionState } from 'containers/App/actions'

import {
  selectSession,
  selectAddresses,
  selectUpdateSessionError,
  selectTeamSettings,
  selectTeamDomain,
  selectBlocklist,
  selectTeamBlocklist,
  selectConfig,
  selectCalendars,
  selectTeamLogs,
  selectPurgeEmailCount,
  selectShowPurgeModal
} from './selectors'

import {
  fetchSessionAddresses,
  updateSession,
  fetchTeamSettings,
  updateTeamSettings,
  fetchTeamDomain,
  fetchBlocklist,
  fetchTeamBlocklist,
  updateBlocklist,
  updateTeamBlocklist,
  fetchConfig,
  resubscribeEmail,
  fetchContactCSV,
  fetchCalendars,
  updateCalendars,
  fetchTeamLogs,
  fetchCampaignStepCopyRequest,
  fetchPurgeEmailCount,
  hidePurgeModal,
  deletePurgeEmail
} from './actions'

const ContentContainer = styled.div`
  background-color: ${props => props.theme.containerBackground};
  border: ${props => `solid 1px ${props.theme.borderColor}`};
  flex: 1 0 auto;
  padding: ${props => props.theme.padding};
  margin-bottom: 2rem;
  max-width: 42rem;
`

class Settings extends Component {
  UNSAFE_componentWillMount () {
    const {
      state,
      actions
    } = this.props
    const isOwner = state.get('is_owner')
    if (isOwner) {
      actions.fetchTeamDomain()
    }

    actions.fetchConfig()
    actions.fetchTeamSettings()

    document.title = 'Interseller | Settings'
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const {
      actions
    } = this.props
    if (this.props.state !== newProps.state) {
      const isOwner = newProps.state.get('is_owner')
      if (isOwner) {
        actions.fetchTeamDomain()
      }
    }
  }

  onSettingUpdate = (params) => {
    this.props.actions.updateSession(params)
  }

  onTeamSettingUpdate = (params) => {
    this.props.actions.updateTeamSettings(params)
  }

  getPurgeEmailCount = (email) => {
    this.props.actions.fetchPurgeEmailCount(email)
  }

  deletePurgeEmail = (email) => {
    this.props.actions.deletePurgeEmail(email)
  }

  hidePurgeModal = () => {
    this.props.actions.hidePurgeModal()
  }

  render () {
    const {
      session,
      state,
      children,
      addresses,
      teamSettings,
      teamDomain,
      blocklist,
      teamBlocklist,
      actions,
      config,
      calendars,
      teamLogs,
      purgeEmailCount,
      showPurgeModal
    } = this.props

    return (
      <Wrapper>
        <SideNavigation state={state} session={session} />
        <ContentContainer>
          {children && React.cloneElement(children, {
            session,
            state,
            addresses,
            teamSettings,
            teamDomain,
            blocklist,
            teamBlocklist,
            actions,
            config,
            calendars,
            teamLogs,
            purgeEmailCount,
            showPurgeModal,
            onSettingUpdate: this.onSettingUpdate,
            onTeamSettingUpdate: this.onTeamSettingUpdate,
            getPurgeEmailCount: this.getPurgeEmailCount,
            hidePurgeModal: this.hidePurgeModal,
            deletePurgeEmail: this.deletePurgeEmail
          })}
        </ContentContainer>
      </Wrapper>
    )
  }
}

Settings.propTypes = {
  children: PropTypes.object,
  session: PropTypes.object,
  state: PropTypes.object,
  actions: PropTypes.object,
  teamSettings: PropTypes.object,
  teamDomain: PropTypes.object,
  addresses: PropTypes.instanceOf(Immutable.List),
  blocklist: PropTypes.object,
  teamBlocklist: PropTypes.object,
  config: PropTypes.object,
  teamLogs: PropTypes.object,
  calendars: PropTypes.any,
  purgeEmailCount: PropTypes.object,
  showPurgeModal: PropTypes.object
}

const mapStateToProps = createStructuredSelector({
  state: selectState(),
  session: selectSession(),
  addresses: selectAddresses(),
  updateError: selectUpdateSessionError(),
  teamSettings: selectTeamSettings(),
  teamDomain: selectTeamDomain(),
  blocklist: selectBlocklist(),
  teamBlocklist: selectTeamBlocklist(),
  config: selectConfig(),
  calendars: selectCalendars(),
  teamLogs: selectTeamLogs(),
  purgeEmailCount: selectPurgeEmailCount(),
  showPurgeModal: selectShowPurgeModal()
})

function mapDispatchToProps (dispatch) {
  return {
    actions: bindActionCreators({
      fetchSessionAddresses,
      updateSession,
      fetchSessionState,
      fetchTeamSettings,
      updateTeamSettings,
      fetchTeamDomain,
      fetchBlocklist,
      fetchTeamBlocklist,
      updateBlocklist,
      updateTeamBlocklist,
      fetchConfig,
      resubscribeEmail,
      fetchContactCSV,
      fetchCalendars,
      updateCalendars,
      fetchTeamLogs,
      fetchCampaignStepCopyRequest,
      deleteAuthToken,
      fetchPurgeEmailCount,
      hidePurgeModal,
      deletePurgeEmail
    }, dispatch)
  }
}

export default connect(mapStateToProps, mapDispatchToProps)(Settings)
