import React, { useState } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import Immutable from 'immutable'
import { format } from 'date-fns'

import Button from 'components/Button'
import Dropdown from 'components/DropDown'
import Input from 'components/Input'
import Modal from 'components/Modal'
import Loading from 'components/Loading'
import DropDownSelectInputField from 'components/DropDownSelectInputField'
import SwitchFieldForm from 'components/SwitchFieldForm'

import Divider from 'elements/Divider'
import Label from 'elements/Label'
import Title from 'elements/Title'

import { validateEmail } from 'utils/strings'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding: 3rem 0 1rem 0;
`

const FormWrapper = styled.div`
  padding: 0 3rem 2rem 3rem;
`

const FormInput = styled.div`
  margin-top: 1.5rem;
  width: 100%;
  /* padding-bottom: 1rem; */
`

const SwitchFieldContainer = styled.div`
  width: 100%;
  margin-top: 1.5rem;
`

const ModalButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding-top: 2rem;
  padding-bottom: 1rem;
`

const roleOptions = Immutable.List([
  { label: 'Manager', value: 'manager' },
  { label: 'User', value: 'user' }
])

function InviteMemberModal ({
  actions,
  groups,
  isManager = false,
  isOpen = false,
  isOwner = false,
  onCancel,
  onSave,
  session,
  subscription,
  seats,
  preview,
  invites,
  areSeatsRemaining
}) {
  const featureFlags = session.get('feature_flags') || session.get('whitelist')
  const group = session.get('group') || null
  const chargeToday = preview?.getIn(['data', 'now'])
  const chargeLater = preview?.getIn(['data', 'later'])
  const chargeLabel = chargeToday < 0 ? 'credits added to account' : 'prorated charge'
  const interval = subscription?.getIn(['data', 'plan', 'interval'])
  const laterAt = preview?.getIn(['data', 'later_at'])
  const date = format(new Date(laterAt * 1000), 'MMM Do')

  const [emailAddress, setEmailAddress] = useState('')
  const [selectedGroup, setSelectedGroup] = useState(group)
  const [selectedRole, setSelectedRole] = useState('user')
  const [willAddSeat, setWillAddSeat] = useState(false)

  const valid = () => (validateEmail(emailAddress))

  const onModalClose = () => {
    onCancel()
    setEmailAddress('')
    setSelectedGroup(group)
    setSelectedRole('user')
    setWillAddSeat(false)
    actions.resetUpdatePreview()
  }

  const formatToCurrency = (amount) => {
    return `$${Number(Math.abs(amount) / 100).toFixed(2)}`
  }

  const renderContent = () => {
    if (invites?.get('loading')) {
      return <Loading />
    }

    return (
      <FormWrapper>
        <FormInput>
          <Input
            autoFocus
            label='Email Address'
            description=''
            inputId='email'
            onValueChange={setEmailAddress}
            value={emailAddress}
          />
        </FormInput>

        {isOwner &&
          <FormInput>
            <Dropdown
              options={roleOptions}
              clearable={false}
              label='Role'
              description='Assign a role to your new teammate.  Managers have access to team-level permissions such as creating sequences as other users, assigning seats, inviting users, and viewing team reports.'
              value={selectedRole}
              onOptionChange={(option) => {
                setSelectedRole(option.value)
              }}
              mb='1rem'
            />
          </FormInput>}

        {featureFlags.includes('show_group_ui') &&
            (isOwner || isManager) &&
              <FormInput>
                <DropDownSelectInputField
                  options={groups}
                  clearable
                  label='Group'
                  customCreateLabel='Create new group...'
                  disabled={!!group}
                  description="Assign a group to your new teammate.  Users assigned a group are restricted to seeing only their own group's sequences and contacts"
                  value={selectedGroup}
                  onOptionChange={(option) => {
                    const newValue = option && option.value ? option.value : null
                    setSelectedGroup(newValue)
                  }}
                  mb='1rem'
                />
              </FormInput>}

        {isOwner && !areSeatsRemaining &&
          <SwitchFieldContainer>
            <SwitchFieldForm
              condensed
              small
              inputId='add_seat'
              label='Add seat'
              description='You do not have any remaining seat licenses. Would you like to purchase an extra seat?'
              descriptionOn={`Your ${chargeLabel} is ${formatToCurrency(chargeToday)} and next charge on ${date} is ${formatToCurrency(chargeLater)} per ${interval || 'month'}.`}
              value={willAddSeat}
              onSave={(value) => {
                setWillAddSeat(value)

                if (value) {
                  // fetch updated pricing for an additional seat
                  actions.fetchUpdatePreview({ seatCount: seats + 1 })
                } else {
                  actions.resetUpdatePreview()
                }
              }}
            />
          </SwitchFieldContainer>}

      </FormWrapper>
    )
  }

  return (
    <Modal
      isOpen={isOpen}
      onModalClose={onModalClose}
    >
      <Wrapper>
        <Title align='center'>Add a Teammate</Title>
        <Label align='center' mt='0.5rem'>Invite more members to your team. Your teammate will receive an email with instructions to join your team.</Label>

        {renderContent()}

        <Divider />

        <ModalButtonContainer>
          <Button
            label='Cancel'
            handleClick={() => {
              onCancel()
              setEmailAddress('')
              setSelectedGroup(null)
              setSelectedRole('user')
              setWillAddSeat(false)
              actions.resetUpdatePreview()
            }}
            mr='0.5rem'
          />
          <Button
            primary
            label='Send Invite'
            disabled={!valid()}
            handleClick={() => {
              onSave(emailAddress, selectedRole, selectedGroup, willAddSeat)
              setEmailAddress('')
              setSelectedGroup(null)
              setSelectedRole('user')
            }}
          />
        </ModalButtonContainer>

      </Wrapper>
    </Modal>
  )
}

InviteMemberModal.propTypes = {
  actions: PropTypes.object,
  groups: PropTypes.instanceOf(Immutable.List),
  isManager: PropTypes.modal,
  isOwner: PropTypes.bool,
  isOpen: PropTypes.bool,
  onCancel: PropTypes.func,
  onSave: PropTypes.func,
  value: PropTypes.string,
  session: PropTypes.object,
  subscription: PropTypes.object,
  seats: PropTypes.number,
  preview: PropTypes.object,
  invites: PropTypes.object,
  areSeatsRemaining: PropTypes.bool
}

export default InviteMemberModal
