import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { getTextFromMarkdown } from 'components/SlateEditor/utils/showdown/'
import StepHeader from 'elements/StepHeader'
import StepContent from './StepContent'

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: start;
  background: ${props => (props.selected ? props.theme.highlightBackground : props.theme.containerBackground)};
  width: 100%;
  cursor: ${props => (props.selected ? 'cursor' : 'pointer')};
  margin-bottom: 1rem;
  border: ${props => `solid 1px ${props.theme.borderColor}`};
`

const StepBanner = styled.div`
  display: inline-block;
  background-color: ${props => props.theme.colors.gray10};
  margin-top: auto;
  width: 100%;
`

class StepBox extends Component {
  render () {
    const {
      contactStep,
      previewStep,
      previewTestStep,
      sequenceStep,
      sequenceTestStep,
      selected = false,
      onClick,
      stepIndex,
      subject,
      testSelected,
      showPersonalized
    } = this.props

    const wait = sequenceStep.get('wait_days') === undefined ? null : sequenceStep.get('wait_days')
    const manual = sequenceStep.get('manual')

    let time = null
    let schedule = null
    let label
    const hideSubject = manual === 'task'
    if (showPersonalized && contactStep) {
      switch (contactStep.get('type')) {
        case 'message': {
          const message = contactStep.get('object')
          time = message.get('sent_at')
          label = message.get('type') === 'manual' ? 'Personalized' : null
          break
        }
        case 'task': {
          const task = contactStep.get('object')
          time = task.get('completed_at')
          break
        }
      }

      schedule = contactStep.get('scheduled_for')
    }

    let bodyMarkdown = sequenceStep.get('body')

    if (contactStep) {
      bodyMarkdown = contactStep.getIn(['object', 'markdown'])
    } else if (previewStep) {
      bodyMarkdown = previewStep.getIn(['object', 'markdown'])
    }

    const body = getTextFromMarkdown(bodyMarkdown)

    let testSubject
    let testBodyMarkdown
    if (sequenceTestStep) {
      testSubject = sequenceTestStep.get('subject') || subject
      if (previewTestStep) {
        testSubject = previewTestStep.getIn(['object', 'subject']) || testSubject
      }

      testBodyMarkdown = sequenceTestStep.get('body')
      if (previewTestStep) {
        testBodyMarkdown = previewTestStep.getIn(['object', 'markdown'])
      }
    }

    const testBody = getTextFromMarkdown(testBodyMarkdown)

    return (
      <Wrapper selected={selected}>
        <StepBanner>
          <StepHeader
            time={time}
            schedule={schedule}
            stepCount={stepIndex}
            wait={wait}
            manual={manual}
            onClick={() => { onClick(false) }}
            rightLabel={label}
            isPreview
          />
        </StepBanner>
        <StepContent
          hideSubject={hideSubject}
          subject={subject}
          body={body}
          selected={selected && !testSelected}
          onClick={() => { onClick(false) }}
        />
        {sequenceTestStep &&
          <StepContent
            hideSubject={hideSubject}
            subject={testSubject}
            body={testBody}
            selected={(selected && testSelected)}
            onClick={() => { onClick(true) }}
          />}
      </Wrapper>
    )
  }
}

StepBox.propTypes = {
  sequenceStep: PropTypes.object,
  previewStep: PropTypes.object,
  contactStep: PropTypes.object,
  stepIndex: PropTypes.number,
  selected: PropTypes.bool,
  onClick: PropTypes.func,
  sequenceTestStep: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  previewTestStep: PropTypes.oneOfType([
    PropTypes.object,
    PropTypes.bool
  ]),
  testSelected: PropTypes.bool,
  subject: PropTypes.string,
  showPersonalized: PropTypes.bool
}

export default StepBox
