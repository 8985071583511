import React, { Component } from 'react'
import Immutable from 'immutable'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import { Tooltip } from 'react-tippy'
import Button from 'components/Button'
import IconButton from 'components/IconButton'
import Popper from 'components/Popper'
import ToolbarButton from 'components/ToolbarButton'
import { Menu, MenuHeader, MenuItem } from 'elements/Menu'
import PlusButton from 'elements/PlusButton'
import Label from 'elements/Label'

import { fadeInRight } from 'utils/animations'
import ContactFilter from './ContactFilter'
import ContactSearch from './ContactSearch'
import ImportDropdown from './ImportDropdown'

const Wrapper = styled.div`
  color: ${props => props.theme.titleColor};
  width: 100%;
  text-align: left;
  margin-top: auto;
  margin-bottom: auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: ${props => props.theme.colors.gray10};
  width: ${props => props.width};
  padding: ${props => `${props.theme.padding} 2rem`};
  z-index: 3;
`

const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
`

const ButtonContainer = styled.div`
  animation: ${fadeInRight} .2s linear 1;
  display: flex;
  flex-direction: row;
  align-items: center;
`

const CaretContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-top: auto;
  margin-bottom: auto;
`

const LabelWrapper = styled.div`
  margin: 0 1rem;
  display: flex;
  align-items: center;
  justify-content: center;
  animation: ${fadeInRight} .2s linear 1;
`

class ContactToolbar extends Component {
  state = {
    showPhoneNumberLookupModal: false
  }

  addFilter (newFilter) {
    const {
      filter,
      onFilterContacts
    } = this.props

    const newFilters = filter || []
    newFilters.push(newFilter)
    onFilterContacts(newFilters, newFilter)
  }

  getSelectedLabel () {
    const {
      allSelected,
      selectedContactCount,
      selectedContacts,
      deselectedContacts
    } = this.props

    if (allSelected) {
      if (selectedContactCount.get('loading')) {
        return (<LabelWrapper />)
      }

      const totalCount = selectedContactCount.get('data') - deselectedContacts.length

      return (
        <LabelWrapper><Label fontWeight='600' color='darkBlue'>{totalCount} selected</Label></LabelWrapper>
      )
    } else if (selectedContacts.length > 0) {
      return (
        <LabelWrapper>
          <Label fontWeight='600' color='darkBlue'>{selectedContacts.length} selected</Label>
        </LabelWrapper>
      )
    }

    return (<LabelWrapper />)
  }

  render () {
    const {
      style,
      selectedContacts,
      allSelected,
      onExportCsv,
      onExportPdf,
      onImportCsv,
      onImportCrm,
      onAddContact,
      onMove,
      onCopy,
      onDelete,
      onClearError,
      onFilterContacts,
      onSearchContacts,
      onPauseContacts,
      onResumeEmails,
      filter,
      search,
      filterAdded,
      activeCRM,
      isArchived,
      onResyncCrm,
      crm,
      session
    } = this.props

    const hasCrm = !!crm.getIn(['data', 'type'])
    const crmName = crm.getIn(['data', 'name']) || 'CRM / ATS'
    const featureFlags = session.get('feature_flags') || session.get('whitelist')

    let selectedButtons

    if (selectedContacts.length > 0 || allSelected) {
      selectedButtons = [
        !isArchived &&
          <ToolbarButton
            tooltip='Move contacts to another sequence'
            icon={['fal', 'external-link-square']}
            onClick={onMove}
            key='move'
          />,
        <ToolbarButton
          tooltip='Copy contacts to another sequence'
          icon={['fal', 'copy']}
          onClick={onCopy}
          key='copy'
        />,
        !isArchived &&
          <ToolbarButton
            tooltip='Remove contacts from sequence'
            icon={['fal', 'trash-alt']}
            onClick={onDelete}
            key='remove'
          />,
        <Popper
          position='bottom-start'
          trigger={
            <IconButton
              type={['far', 'ellipsis-v']}
              translateX={-1}
            />
          }
          key='ellipses'
        >
          {close => (
            <Menu style={{ minWidth: '10rem' }} onClick={close}>
              <MenuItem
                onClick={onExportCsv}
              >
                Export to CSV
              </MenuItem>
              <MenuItem
                onClick={onExportPdf}
              >
                Export to PDF
              </MenuItem>
              {!isArchived &&
                <MenuItem
                  onClick={onClearError}
                >
                  Clear Errors
                </MenuItem>}
              {!isArchived &&
                <MenuItem
                  onClick={onPauseContacts}
                >
                  Pause Contacts
                </MenuItem>}
              {!isArchived &&
                <MenuItem
                  onClick={onResumeEmails}
                >
                  Resume Sending
                </MenuItem>}
              {hasCrm &&
                <MenuItem
                  onClick={() => {
                    onResyncCrm()
                  }}
                >
                  Resync to {crmName}
                </MenuItem>}
              {featureFlags.includes('phone_query') && featureFlags.includes('email_query') &&
                <MenuItem
                  onClick={() => {
                    this.props.onOpenPhoneLookupModal()
                  }}
                >
                  Lookup Phone Numbers
                </MenuItem>}
            </Menu>
          )}
        </Popper>
      ]
    }

    let filters = []
    if (filter) {
      filters = filter.map((f, i) => {
        return (
          <ContactFilter
            key={`contactfilter_${i}`}
            filter={f}
            isOpen={filterAdded && i === filter.length - 1}
            onRemoveFilter={() => {
              const newFilters = filter
              newFilters.splice(i, 1)
              onFilterContacts(newFilters)
            }}
            onUpdateFilter={(newFilter) => {
              const newFilters = filter
              newFilters.splice(i, 1, newFilter)
              onFilterContacts(newFilters)
            }}
          />
        )
      })
    }

    return (
      <Wrapper style={style}>
        <Container>
          {filters.length <= 0 && <ContactFilter filter='all' />}
          {filters}
          <Popper
            position='bottom-start'
            maxHeight='18rem'
            trigger={
              <Tooltip
                key='filter'
                style={{ display: 'block' }}
                title='Filter Contacts'
                position='top'
                trigger='mouseenter'
                arrow
              >
                <CaretContainer>
                  <PlusButton />
                </CaretContainer>
              </Tooltip>
            }
          >
            {close => (
              <Menu style={{ width: '12rem' }} onClick={close}>
                <MenuHeader>
                  Filters
                </MenuHeader>
                <MenuItem onClick={() => { this.addFilter('ongoing') }}>
                  Ongoing
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('messaged') }}>
                  Messaged
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('last_messaged') }}>
                  Last Messaged
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('created') }}>
                  Created
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('paused') }}>
                  Paused
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('customized') }}>
                  Personalized
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('step') }}>
                  Step
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('opened') }}>
                  Opened
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('visited') }}>
                  Clicked
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('replied') }}>
                  Replied
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('sentiment') }}>
                  Sentiment
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('booked') }}>
                  Booked
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('unsubscribed') }}>
                  Unsubscribed
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('bounced') }}>
                  Bounced
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('all_errors') }}>
                  Error
                </MenuItem>
                <MenuItem onClick={() => { this.addFilter('!phone_number') }}>
                  Phone Number
                </MenuItem>
              </Menu>
            )}
          </Popper>
          <ContactSearch
            search={search}
            onSearchContacts={onSearchContacts}
          />
        </Container>
        {selectedButtons &&
          <ButtonContainer style={{ marginLeft: '1rem' }}>
            {this.getSelectedLabel()}
            {selectedButtons}
          </ButtonContainer>}
        {!selectedButtons &&
          <ButtonContainer>
            <Button
              label='export csv'
              small
              mr='0.5rem'
              handleClick={onExportCsv}
            />
            {!isArchived &&
            (activeCRM.getIn(['data', 'import_contacts_supported'])
              ? <ImportDropdown
                  onClickCrm={onImportCrm}
                  onClickCsv={onImportCsv}
                  activeCRM={activeCRM}
                />
              : <Button
                  label='import csv'
                  small
                  mr='0.5rem'
                  handleClick={onImportCsv}
                />
            )}
            {!isArchived &&
              <Button
                label='Add Contact'
                small
                primary
                handleClick={onAddContact}
              />}
          </ButtonContainer>}
      </Wrapper>
    )
  }
}

ContactToolbar.propTypes = {
  style: PropTypes.object,
  session: PropTypes.object,
  selectedContacts: PropTypes.array,
  deselectedContacts: PropTypes.array,
  allSelected: PropTypes.bool,
  selectedContactCount: PropTypes.object,
  onExportCsv: PropTypes.func,
  onExportPdf: PropTypes.func,
  onImportCsv: PropTypes.func,
  onImportCrm: PropTypes.func,
  onAddContact: PropTypes.func,
  onMove: PropTypes.func,
  onCopy: PropTypes.func,
  onDelete: PropTypes.func,
  onClearError: PropTypes.func,
  onFilterContacts: PropTypes.func,
  onSearchContacts: PropTypes.func,
  onPauseContacts: PropTypes.func,
  onResumeEmails: PropTypes.func,
  filter: PropTypes.array,
  search: PropTypes.string,
  activeCRM: PropTypes.instanceOf(Immutable.Map),
  actions: PropTypes.object,
  isArchived: PropTypes.bool,
  filterAdded: PropTypes.bool,
  onResyncCrm: PropTypes.func,
  crm: PropTypes.object,
  onOpenPhoneLookupModal: PropTypes.func
}

export default withTheme(ContactToolbar)
