import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'recharts'

import Loading from 'components/Loading'
import EmptyState from 'components/EmptyState'

import { DATE_RANGE } from 'containers/Reports/utils/date_helpers'
import * as reportHelpers from 'containers/Reports/utils/report_helpers'

import SpreadSheet from 'containers/Reports/components/SpreadSheet'
import Graph from 'containers/Reports/components/Graph'
import UserHUD from 'containers/Reports/components/Graph/components/UserHUD'
import DateSelector from 'containers/Reports/components/Graph/components/DateSelector'

import { ReportContainer, EmptyContainer, GraphContainer } from 'containers/Reports/styles'

import { Satellite } from 'svg'

class ContactTaskReport extends React.Component {
  formatTableData = () => {
    const { contactTaskReports } = this.props

    const totals = contactTaskReports.getIn(['data', 'totals', 'campaigns'])
    const users = contactTaskReports.getIn(['data', 'populate', 'users'])
    const sequences = contactTaskReports.getIn(['data', 'populate', 'campaigns'])

    const tableColumns = ['sequence', 'owner', 'tasks completed']
    const tableRows = []
    const columnWidths = ['flex', 'min', 140]
    totals
      .entrySeq()
      .forEach(record => {
        const sequenceId = record[0]
        const count = record[1]

        const sequence = sequences.get(sequenceId)
        const sequenceTitle = sequences.getIn([sequenceId, 'title'])
        const user = users.getIn([sequence.get('_user'), 'full_name'])

        tableRows.push({
          sequence: sequenceTitle,
          'tasks completed': count,
          sequenceID: sequenceId,
          owner: user
        })
      })

    return { tableColumns, tableRows, columnWidths }
  }

  render () {
    const {
      theme,
      contactTaskReports,
      setSelectedDateType,
      selectedDateRange,
      router
    } = this.props

    if (!!contactTaskReports && contactTaskReports.get('loading')) {
      return <Loading width='100%' />
    }

    let graphValues = null
    let userTotals = null
    let totalUnits = 0
    let tableData = null
    if (contactTaskReports &&
      !contactTaskReports.get('loading') &&
      contactTaskReports.get('data') &&
      contactTaskReports.get('data').size > 0) {
      ({
        graphValues,
        userTotals,
        totalUnits
      } = reportHelpers.formatBarGraphData(contactTaskReports))
      tableData = this.formatTableData()
    }

    if (!graphValues || graphValues.length < 0) {
      return (
        <ReportContainer>
          <EmptyContainer>
            <EmptyState
              height='100%'
              mt='0'
              p='3rem'
              icon={<Satellite />}
              title='Nothing To Report'
              description="Looks like we don't have any data for the timeframe and filter you have selected"
            />
          </EmptyContainer>
        </ReportContainer>
      )
    }

    return (
      <ReportContainer>
        <GraphContainer>
          <DateSelector
            dayDateRangeUpdate={() => setSelectedDateType(DATE_RANGE.DAY)}
            weekDateRangeUpdate={() => setSelectedDateType(DATE_RANGE.WEEK)}
            selectedDateRange={selectedDateRange}
          />

          <Graph
            theme={theme}
            graphData={graphValues}
            unitLabel='Task Completed'
            unitLabelPlural='Tasks Completed'
            selectedDateRange={selectedDateRange}
            defaultTheme
          >
            <Bar dataKey='value' fill={this.props.theme.colors.blue} />
          </Graph>

          <UserHUD
            unitLabel='Tasks'
            unitLabelPlural='Tasks Completed'
            userData={userTotals}
            totalUnits={totalUnits}
          />
        </GraphContainer>

        <SpreadSheet
          theme={theme}
          router={router}
          tableData={tableData}
        />
      </ReportContainer>
    )
  }
}

ContactTaskReport.propTypes = {
  theme: PropTypes.object,
  router: PropTypes.object,
  graphMenuRef: PropTypes.object,
  contactTaskReports: PropTypes.object,
  selectedDateRange: PropTypes.string,
  setSelectedDateType: PropTypes.func
}

export default ContactTaskReport
