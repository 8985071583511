import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { space, width, height, textAlign, flex, color } from 'styled-system'

import Subtitle from 'elements/Subtitle'
import Label from 'elements/Label'

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  margin-top: 1rem;
  padding: 5rem;

  ${space}
  ${height}
  ${width}
  ${textAlign}
  ${flex}
  ${color}
`

const ButtonContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
  margin-top: 2rem;
`

class EmptyState extends PureComponent {
  render () {
    const {
      title,
      icon,
      description,
      children,
      shadow = false,
      ...rest
    } = this.props

    return (
      <Wrapper shadow={shadow} {...rest}>
        {icon && icon}
        {title && <Subtitle mt='1.5rem' mb='0'>{title}</Subtitle>}
        {description && <Label align='center' mt='0.5rem' mb='0'>{description}</Label>}
        <ButtonContainer>
          {children}
        </ButtonContainer>
      </Wrapper>
    )
  }
}

EmptyState.propTypes = {
  children: PropTypes.any,
  icon: PropTypes.any,
  title: PropTypes.string,
  description: PropTypes.string,
  shadow: PropTypes.bool
}

export default EmptyState
