import randomColor from 'randomcolor'

export function generateColor (seed, hue = '#4D83E1') {
  return randomColor({ hue, seed })
}

export function generateColors (count, hue = '#4D83E1') {
  return randomColor({ hue, count })
}

export function generateSeedColors (array, hue = '#4D83E1') {
  if (array) {
    return array.map(o => (generateColor(o, hue)))
  }
  return []
}
