import React, { Component } from 'react'
import ReactDOM from 'react-dom'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { height } from 'styled-system'
import {
  AutoSizer,
  List,
  CellMeasurerCache
} from 'react-virtualized'
import Loading from 'components/Loading'
import EmptyState from 'components/EmptyState'
import { Satellite } from 'svg'
import { pluralize } from 'utils/strings'
import TaskToolbar from './TaskToolbar'
import TaskRowRenderer from './TaskRowRenderer'

const Wrapper = styled.div`
  background: ${props => props.theme.containerBackground};
  height: auto;
  width: 100%;
  border: ${props => `solid 1px ${props.theme.borderColor}`};

  .ReactVirtualized__Grid{
    outline: none;
  }

  ${height}
`

class TaskList extends Component {
  constructor (props) {
    super(props)

    this.state = {
      // current height of task list in pixels
      taskListCurrentHeight: props.tasks.get('data').count() * 78,
      // stores `height: 100%` of current element in pixels
      taskListMaxHeight: 0
    }

    this.listRef = React.createRef()
    this.rowCache = new CellMeasurerCache({
      fixedWidth: true,
      minHeight: 78
    })
  }

  UNSAFE_componentWillMount () {
    const { activeTask } = this.props
    this.fetchContactFromTask(activeTask)
  }

  componentDidMount () {
    const { tasks } = this.props
    this.setState({
      // eslint-disable-next-line react/no-find-dom-node
      taskListMaxHeight: ReactDOM.findDOMNode(this).parentNode.clientHeight,
      taskListCurrentHeight: tasks.get('data').count() * 78
    })
  }

  UNSAFE_componentWillReceiveProps (newProps) {
    const { activeTask } = newProps
    if (activeTask !== this.props.activeTask) {
      this.fetchContactFromTask(activeTask)
    }
  }

  componentDidUpdate (prevProps, prevState) {
    const { activeTask, tasks } = this.props
    if (tasks && activeTask && tasks.get('data').count() !== prevProps.tasks.get('data').count()) {
      this.rowCache.clearAll()
    }
  }

  UNSAFE_componentWillUpdate (nextProps) {
    const prevProps = this.props
    if (prevProps.contactCompany.get('loading') && !nextProps.contactCompany.get('loading') && this.listRef.current) {
      this.listRef.current.forceUpdateGrid()
    }

    if (prevProps.selectedContact.get('loading') && !nextProps.selectedContact.get('loading') && this.listRef.current) {
      this.listRef.current.forceUpdateGrid()
    }

    if (prevProps.tasks !== nextProps.tasks) {
      this.setState({
        taskListCurrentHeight: nextProps.tasks.get('data').count() * 78
      })
    }

    if (this.listRef && this.listRef.current && prevProps.checkedContacts !== nextProps.checkedContacts) {
      this.listRef.current.forceUpdateGrid()
    }
  }

  fetchContactFromTask (activeTask) {
    if (!activeTask) {
      return
    }

    const { actions } = this.props
    const domain = activeTask.get('domain') || activeTask.get('email').split('@')[1]
    actions.fetchContactCompany(domain)

    const sequenceId = activeTask.getIn(['_campaign', 'id'])
    const contactId = activeTask.get('id')
    actions.fetchContact(sequenceId, contactId)

    // * NOTE: I don't like that this but it has to be here
    // When a user clicks on a row inside React-Virtualized, the selected row isn't
    // re-rendered until the user either scrolls or a prop is updated. React-Virtuaized
    // lists offer the `forceUpdateGrid` function to allow a forced re-render on all
    // visible rows in the list. For *some* reason, the very first call to
    // `forceUpdateGrid` doesn't (seem to) cause a re-render, but all other future calls do.
    // We call `forceUpdateGrid` here, while the component is mounting to force that intial re-render.
    if (this.listRef.current) {
      this.listRef.current.forceUpdateGrid()
    }
  }

  onTaskClick = (task, index) => {
    const { activeTask } = this.props
    if (task !== activeTask) {
      this.props.handleTaskSelected(task, index)
      this.listRef.current.forceUpdateGrid()
    }
  }

  handleCellLoadingComplete = (selectedCellHeight) => {
    const { tasks } = this.props
    const finalMenuHeight = ((tasks.get('data').count() - 1) * 78) + selectedCellHeight + 1
    this.setState({ taskListCurrentHeight: finalMenuHeight })
  }

  getRowRenderer = ({ key, index, style, parent }) => {
    const {
      tasks,
      contactCompany,
      selectedContact,
      activeTask,
      deleteContact,
      editContact,
      onChecked,
      checkedContacts
    } = this.props
    const task = tasks.getIn(['data', index])
    const count = tasks.get('data').count()

    return (
      <TaskRowRenderer
        key={`trr_${key}`}
        task={task}
        index={index}
        activeTask={activeTask}
        totalTasks={count}
        contactCompany={contactCompany}
        selectedContact={selectedContact}
        onClick={() => this.onTaskClick(task, index)}
        style={style}
        cache={this.rowCache}
        parent={parent}
        rowKey={key}
        onCellLoadingComplete={this.handleCellLoadingComplete}
        deleteContact={deleteContact}
        editContact={editContact}
        onChecked={onChecked}
        checkedContacts={checkedContacts}
        listRef={this.listRef}
      />
    )
  }

  onResize = ({ width, height }) => {
    this.rowCache.clearAll()
  }

  render () {
    const {
      tasks,
      taskType,
      emptyDescription,
      onAllChecked,
      allChecked,
      checkedContacts,
      onPause,
      onSkip
    } = this.props

    const {
      taskListCurrentHeight,
      taskListMaxHeight
    } = this.state

    const loading = tasks.get('loading')
    if (loading) {
      return (
        <Wrapper height='100%'>
          <Loading height='100%' />
        </Wrapper>
      )
    }

    const taskCount = tasks.get('data').count()
    if (tasks.get('data').count() === 0) {
      return (
        <Wrapper height='100%'>
          <EmptyState
            icon={<Satellite />}
            height='100%'
            title='No Pending Tasks'
            description={emptyDescription || "Switch steps to 'require personalization' to create a message queue or switch to another teammate's or sequence's tasks"}
          />
        </Wrapper>
      )
    }

    return (
      <Wrapper ref={this.wrapper}>
        <TaskToolbar
          title={`${taskCount} ${taskType || ''} ${pluralize('Task', 'Tasks', taskCount)}`}
          selectedLabel={checkedContacts ? `${checkedContacts.length} ${pluralize('task', 'tasks', checkedContacts.length)} selected` : ''}
          checked={allChecked}
          onChecked={onAllChecked}
          showButtons={checkedContacts && !!checkedContacts.length}
          listRef={this.listRef}
          onPause={onPause}
          onSkip={onSkip}
        />

        <AutoSizer style={{ transform: 'translateX(-1px)' }} onResize={this.onResize}>
          {({ width }) => (
            <List
              ref={this.listRef}
              height={taskListCurrentHeight >= taskListMaxHeight
                ? taskListMaxHeight
                : taskListCurrentHeight}
              width={width}
              rowCount={taskCount}
              rowHeight={this.rowCache.rowHeight || 78}
              rowRenderer={this.getRowRenderer}
              overscanRowCount={2}
              deferredMeasurementCache={this.rowCache}
              scrollToAlignment='start'
            />
          )}
        </AutoSizer>
      </Wrapper>
    )
  }
}

TaskList.propTypes = {
  task: PropTypes.object,
  tasks: PropTypes.object,
  taskType: PropTypes.string,
  handleTaskSelected: PropTypes.func,
  activeTask: PropTypes.object,
  contactCompany: PropTypes.object,
  actions: PropTypes.object,
  selectedContact: PropTypes.object,
  setActiveTaskIndex: PropTypes.func,
  deleteContact: PropTypes.func,
  onChecked: PropTypes.func,
  onAllChecked: PropTypes.func,
  allChecked: PropTypes.bool,
  checkedContacts: PropTypes.array,
  onPause: PropTypes.func,
  onSkip: PropTypes.func,
  editContact: PropTypes.func,
  emptyDescription: PropTypes.object
}

export default TaskList
