import { createSelector } from 'reselect'

const selectOnboarding = () => state => state.get('onboarding')

const selectDiscovery = () => createSelector(
  selectOnboarding(),
  onboardingState => onboardingState.get('discovery')
)

const selectMagic = () => createSelector(
  selectOnboarding(),
  onboardingState => onboardingState.get('magic')
)

export {
  selectDiscovery,
  selectMagic
}
