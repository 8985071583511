import React from 'react'
import PropTypes from 'prop-types'
import { Bar } from 'recharts'

import EmptyState from 'components/EmptyState'
import Loading from 'components/Loading'

import { DATE_RANGE } from 'containers/Reports/utils/date_helpers'
import * as dateHelpers from 'containers/Reports/utils/date_helpers'
import * as reportHelpers from 'containers/Reports/utils/report_helpers'

import Graph from 'containers/Reports/components/Graph'
import SpreadSheet from 'containers/Reports/components/SpreadSheet'

import UserHUD from 'containers/Reports/components/Graph/components/UserHUD'
import DateSelector from 'containers/Reports/components/Graph/components/DateSelector'

import { ReportContainer, EmptyContainer, GraphContainer } from 'containers/Reports/styles'

import { Satellite } from 'svg'

class BookedReport extends React.Component {
  formatTableData = () => {
    const { bookedReports } = this.props
    const records = bookedReports.getIn(['data', 'records'])
    const users = bookedReports.getIn(['data', 'populate', 'users'])
    const sequences = bookedReports.getIn(['data', 'populate', 'campaigns'])

    const tableColumns = ['name', 'date', 'user', 'sequence']
    const tableRows = []
    records.entrySeq().forEach((record, idx) => {
      // date meeting was booked
      const bookedAt = record[1].get('booked_at')
      const bookedAtDateStr = dateHelpers.generateFormattedDateFromUTCString(bookedAt)

      // who we booked the meeting with
      const name = record[1].get('name')
      const contactID = record[1].get('_id')

      // who booked the meeting
      const sequence = sequences.getIn([record[1].get('_campaign'), 'title'])
      const sequenceID = sequences.getIn([record[1].get('_campaign'), '_id'])
      const user = users.getIn([record[1].get('_user'), 'full_name'])

      // store the data into their reptective columns
      tableRows.push({
        date: bookedAtDateStr,
        unformattedDate: bookedAt,
        name,
        user,
        sequenceID,
        sequence,
        contactID
      })
    })

    return { tableColumns, tableRows }
  }

  render () {
    const {
      theme,
      router,
      bookedReports,
      selectedDateRange,
      setSelectedDateType
    } = this.props

    if (!!bookedReports && bookedReports.get('loading')) {
      return <Loading width='100%' />
    }

    let graphValues = null
    let userTotals = null
    let totalUnits = 0
    let tableData = null
    if (bookedReports &&
      !bookedReports.get('loading') &&
      bookedReports.get('data') &&
      bookedReports.get('data').size > 0) {
      ({ graphValues, userTotals, totalUnits } = reportHelpers.formatBarGraphData(bookedReports))
      tableData = this.formatTableData()
    }

    if (!graphValues || graphValues.length < 0) {
      return (
        <ReportContainer>
          <EmptyContainer>
            <EmptyState
              height='100%'
              mt='0'
              p='3rem'
              icon={<Satellite />}
              title='Nothing To Report'
              description="Looks like we don't have any data for the timeframe and filter you have selected"
            />
          </EmptyContainer>
        </ReportContainer>
      )
    }

    return (
      <ReportContainer>
        <GraphContainer>
          <DateSelector
            dayDateRangeUpdate={() => setSelectedDateType(DATE_RANGE.DAY)}
            weekDateRangeUpdate={() => setSelectedDateType(DATE_RANGE.WEEK)}
            selectedDateRange={selectedDateRange}
          />

          <Graph
            theme={theme}
            graphData={graphValues}
            unitLabel='Meeting Booked'
            unitLabelPlural='Meetings Booked'
            selectedDateRange={selectedDateRange}
            defaultTheme
          >
            <Bar dataKey='value' fill={this.props.theme.colors.blue} />
          </Graph>

          <UserHUD
            unitLabel='Meeting Booked'
            unitLabelPlural='Meetings Booked'
            userData={userTotals}
            totalUnits={totalUnits}
          />
        </GraphContainer>

        <SpreadSheet
          theme={theme}
          tableData={tableData}
          router={router}
        />
      </ReportContainer>
    )
  }
}

BookedReport.propTypes = {
  theme: PropTypes.object,
  router: PropTypes.object,
  bookedReports: PropTypes.object,
  selectedDateRange: PropTypes.string,
  setSelectedDateType: PropTypes.func
}

export default BookedReport
