import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'

import { space, width, height } from 'styled-system'
import styled, { css } from 'styled-components'

const Wrapper = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;

  ${space}
  ${width}
  ${height}
`

const Label = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.label};
  text-align: left;
  padding-top: 0;
  margin-bottom: 0.25rem;
  text-transform: uppercase;
  letter-spacing: 0.05em;
`

const Description = styled.label`
  width: 100%;
  color: ${props => props.theme.labelColor};
  font: ${props => props.theme.fonts.small};
  text-align: left;
  padding-top: 0;
  margin-bottom: 1rem;
  letter-spacing: 0.05em;
`

const Field = styled.input`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.normal};
  width: 100%;
  height: ${props => (props.height) ? props.height : 'auto'};
  padding: ${props => (props.small ? '0.65rem' : '1rem')};
  border-radius: 3px;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray60};
  border-style: solid;
  background-color: white;

  ${props => props.disabled && css`
    cursor: default;
    opacity: .5;
  `};
`

const TextArea = styled.textarea`
  color: ${props => props.theme.titleColor};
  font: ${props => props.theme.fonts.normal};
  width: 100%;
  height: ${props => (props.height) ? `${props.height}px` : 'auto'};
  padding: ${props => (props.small ? '0.65rem' : '1rem')};
  border-radius: 3px;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray60};
  border-style: solid;
`

class Input extends PureComponent {
  render () {
    const {
      label,
      description,
      inputId,
      multiline = false,
      small = false,
      step = 1,
      value,
      defaultValue,
      disabled,
      type = 'text',
      min,
      height,
      autoFocus,
      placeholder,
      ...rest
    } = this.props
    return (
      <Wrapper {...rest}>
        {label &&
          <Label htmlFor={inputId}>{label}</Label>}
        {description &&
          <Description>{description}</Description>}
        {!multiline &&
          <Field
            id={inputId}
            value={value}
            defaultValue={defaultValue}
            min={min}
            type={type}
            step={step}
            onChange={e => (this.props.onValueChange(e.target.value))}
            small={small}
            height={height}
            disabled={disabled}
            autoFocus={autoFocus}
            placeholder={placeholder}
          />}
        {multiline &&
          <TextArea
            id={inputId}
            height={height}
            value={value}
            type={type}
            onChange={e => (this.props.onValueChange(e.target.value))}
            small={small}
            disabled={disabled}
            placeholder={placeholder}
          />}
      </Wrapper>
    )
  }
}

Input.propTypes = {
  label: PropTypes.string,
  description: PropTypes.string,
  inputId: PropTypes.string,
  onValueChange: PropTypes.func,
  multiline: PropTypes.bool,
  small: PropTypes.bool,
  step: PropTypes.number,
  value: PropTypes.any,
  defaultValue: PropTypes.any,
  disabled: PropTypes.bool,
  type: PropTypes.string,
  min: PropTypes.number,
  autoFocus: PropTypes.bool,
  placeholder: PropTypes.string,
  height: PropTypes.number
}

export default Input
