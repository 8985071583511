import React, { Component } from 'react'
import PropTypes from 'prop-types'
import Immutable, { fromJS } from 'immutable'
import styled, { withTheme } from 'styled-components'
import { isValidEmail } from 'utils/strings'

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

import ConfirmModal from 'components/ConfirmModal'
import OnboardingTutorialModal from '../OnboardingTutorialModal'

const ONBOARDING_LOCALSTORE = 'ONBOARDING_LOCALSTRE'

const Wrapper = styled.div`
  position: fixed;
  bottom: 14px;
  left: 25px;
  z-index: 99;
`

const DisplayButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;

  width: fit-content;
  padding: 0.75rem 1.25rem;
  padding-bottom: calc(0.75rem);
  border-radius: 9999px;

  background-color: ${props => props.theme.colors.darkBlue};
  color: ${props => props.theme.colors.white};

  box-shadow: ${props => props.theme.shadow};
  cursor: pointer;
  user-select: none;
`

const Checklist = styled.div`
  border-radius: 3px;
  background-color: ${props => props.theme.colors.white};
  box-shadow: ${props => props.theme.shadow};
  max-width: 360px;

  margin-bottom: 1rem;
`

const ChecklistWrapper = styled.div`
  padding: 1rem 1.25rem;
`

const ChecklistTitle = styled.div`
  font: ${props => props.theme.fonts.header};
  margin-bottom: 0.25rem;

  display: flex;
`

const ChecklistTitleText = styled.div`
  flex: 1;
`

const CloseWrapper = styled.div`
  line-height: 27px;
  color: ${props => props.theme.colors.gray30};
  cursor: pointer;
`

const ChecklistDescription = styled.div`
  color: ${props => props.theme.colors.darkGray};
  margin-bottom: 1rem;
`

const ChecklistProgress = styled.div`
  display: flex;
`

const ChecklistProgressPercentage = styled.div`
  margin-right: 1rem;
`

const ChecklistProgressBar = styled.div`
  flex: 1;
  background-color: ${props => props.theme.colors.gray30};
  border-radius: 9999px;
`

const ChecklistProgressBarInner = styled.div`
  display: block;
  width: ${props => props.width}%;
  height: 100%;
  background-color: ${props => props.theme.colors.darkBlue};
  border-radius: 9999px;
`

const ChecklistItems = styled.div`
  border-top: 1px solid ${props => props.theme.colors.gray20};
  padding: 1rem 1.25rem;
  width: 100%;
  margin-top: 1rem;
`

const ChecklistItemArrow = styled.div`
  display: none;
  height: 16px;
  margin-top: -6px;
`

const ChecklistItem = styled.div`
  color: ${props => props.theme.colors.darkGray};
  display: flex;
  align-items: center;
  padding: 0.5rem;
  cursor: pointer;
  border-radius: 3px;
  &:hover {
    background-color: ${props => props.theme.colors.gray20};
    ${ChecklistItemArrow} {
      display: block;
    }
  }
`

const ChecklistItemIcon = styled.div`
  color: ${props => props.theme.colors.darkBlue};
  width: 24px;
  height: 24px;

  margin-right: 0.75rem;
`

const ChecklistItemTitle = styled.div`
  flex: 1;
  margin-top: -1px;
  text-decoration: ${props => props.completed ? 'line-through' : 'none'};
`

const RemainingCount = styled.div`
  background-color: ${props => props.theme.colors.yellow};

  color: ${props => props.theme.colors.darkBlue};
  text-align: center;

  width: 24px;
  height: 24px;
  border-radius: 100%;

  position: absolute;
  right: 0;
  top: -10px;
`

const SkipButton = styled.div`
  color: ${props => props.theme.colors.gray70};
  font: ${props => props.theme.fonts.xsmall};
  cursor: pointer;
  margin-right: 0.5rem;
  float: right;
  line-height: 30px;

  &:hover {
    text-decoration: underline;
  }
`

class OnboardingChecklist extends Component {
  constructor (props) {
    super(props)
    this.state = {
      showOnboardingChecklist: false,
      showCloseModal: false,
      showTutorialModal: false,
      tutorialModalIndex: -1
    }
  }

  componentDidMount () {
    const skipped = window.localStorage.getItem(ONBOARDING_LOCALSTORE)
    this.setState({ skipped })
  }

  getChecklistItems = () => {
    const { checklist, router, session } = this.props
    const emailSetupItem = {
      title: 'Add SPF/DKIM records to your domain',
      description: '',
      wistiaVideoId: '',
      completed: checklist.getIn(['data', 'email_dns_setup']),
      onClick: () => {
        window.open(`https://toolbox.googleapps.com/apps/checkmx/check?domain=${session.get('email_domain')}&dkim_selector=`, '_blank')
      }
    }

    const checkListItems = [
      {
        title: 'Create your first email sequence',
        description: 'Sequences are the core of Interseller. Sequences are lists of contacts that you are reaching out to about a specific position you are recruiting for. Each sequence has its own cadence of emails that your contacts will receive too.',
        wistiaVideoId: 'ndt1348s7k',
        completed: checklist.getIn(['data', 'created_campaign']),
        onClick: (index) => {
          router.push('/')
          this.openTutorialModal(index)
        }
      },
      {
        title: 'Write your email steps',
        description: 'Your email steps are templates that you send to your contacts within your sequence. Every email sent is one-to-one and can be personalized to each contact. We recommend that each of your emails are easy to read and to the point. And make sure to use a follow up email!',
        wistiaVideoId: 'q4bvur4qzw',
        completed: checklist.getIn(['data', 'wrote_steps']),
        onClick: (index) => {
          router.push('/?redirect=steps')
          this.openTutorialModal(index)
        }
      },
      {
        title: 'Connect your CRM/ATS',
        description: 'Add an integration to Interseller to have data automatically be added into your CRM or ATS. Contact information added into Interseller can seamlessly flow into your integration and emails will be logged automatically.',
        wistiaVideoId: '78omwvi0qc',
        completed: checklist.getIn(['data', 'connected_integration']),
        onClick: (index) => {
          router.push('/integrations/crm')
          this.openTutorialModal(index)
        }
      },
      {
        title: 'Install the Chrome extension',
        description: 'The Interseller Chrome extension is the core for sourcing contacts from social media websites like LinkedIn, AngelList and GitHub. The Chrome extension can also find contact information even if you are not connected with them. You can add contacts directly from the Chrome extension to any of your sequences.',
        wistiaVideoId: 'mh1odkx6x6',
        completed: checklist.getIn(['data', 'installed_chrome_extension']),
        onClick: (index) => {
          window.open('https://chrome.google.com/webstore/detail/coggaiakecmfpkamlplbpacajahgeofh')
          this.openTutorialModal(index)
        }
      },
      {
        title: 'Add contacts to your sequence',
        description: 'You can add contacts to your sequences through three different methods. You can use the Chrome extension, add contacts one-by-one, or upload a spreadsheet/CSV in bulk.',
        wistiaVideoId: 'z0hjn4fx1k',
        completed: checklist.getIn(['data', 'added_contacts']),
        onClick: (index) => {
          router.push('/?redirect=contacts')
          this.openTutorialModal(index)
        }
      },
      {
        title: 'Launch your email sequence',
        description: 'Once you have your contacts loaded into Interseller and your emails written, it is time to launch your sequence.',
        wistiaVideoId: 'jlv04v47u2',
        completed: checklist.getIn(['data', 'launched_campaign']),
        onClick: (index) => {
          router.push('/?redirect=contacts')
          this.openTutorialModal(index)
        }
      },
      ...((this.props.state.get('is_owner') || this.props.state.get('is_manager')) && checklist.getIn(['data', 'email_dns_setup']) !== null ? [emailSetupItem] : [])
    ]

    const isNylas = session.get('account_type') === 'nylas'
    if (isNylas) {
      const fullName = isValidEmail(session.get('full_name')) ? '' : session.get('full_name')

      checkListItems
        .unshift({
          title: 'Add a name to your account',
          completed: !!fullName,
          onClick: () => {
            router.push('/settings/general')
          }
        })
    }

    return fromJS(checkListItems)
  }

  toggleOnboardingChecklist = () => {
    const { showOnboardingChecklist } = this.state
    this.setState({
      showOnboardingChecklist: !showOnboardingChecklist
    })
  }

  openTutorialModal = (tutorialModalIndex) => {
    this.setState({
      showTutorialModal: true,
      tutorialModalIndex
    })
  }

  closeTutorialModal = () => {
    this.setState({
      showTutorialModal: false,
      tutorialModalIndex: -1
    })
  }

  closeOnboardingChecklist = () => {
    this.setState({
      showOnboardingChecklist: false
    })
  }

  skip = () => {
    window.localStorage.setItem(ONBOARDING_LOCALSTORE, new Date().toISOString())
    this.setState({ skipped: true })
  }

  renderTutorialModal = () => {
    const { showTutorialModal, tutorialModalIndex } = this.state
    const item = this.getChecklistItems().get(tutorialModalIndex)

    return (
      <OnboardingTutorialModal
        isOpen={showTutorialModal}
        handleOnModalClose={this.closeTutorialModal}
        modalTitle={item.get('title')}
        modalDescription={item.get('description')}
        modalWistiaVideoId={item.get('wistiaVideoId')}
      />
    )
  }

  renderConfirmModal = () => {
    const { showCloseModal } = this.state

    return (
      <ConfirmModal
        title='Are you sure?'
        description='Are you sure you want to complete your checklist? Doing so will remove the checklist from being visible.'
        isOpen={showCloseModal}
        onCancel={() => this.setState({ showCloseModal: false })}
        onConfirm={() => {
          this.skip()
          this.setState({ showCloseModal: false })
        }}
      />
    )
  }

  renderDisplayButton = (remainingCount) => {
    const { theme } = this.props
    const { showOnboardingChecklist } = this.state

    return (
      <DisplayButton
        onClick={() => this.toggleOnboardingChecklist()}
      >
        <FontAwesomeIcon
          size='lg'
          icon={['far', 'clipboard-list-check']}
          color={theme.colors.white}
          style={{ marginRight: '.75rem' }}
        />
        Getting Started
        {remainingCount > 0 && !showOnboardingChecklist && <RemainingCount>{remainingCount}</RemainingCount>}
      </DisplayButton>
    )
  }

  renderOnboardingChecklist = (completedPercentage, remainingCount) => {
    const checklistItems = this.getChecklistItems().map((item, index) => {
      let icon = (
        <FontAwesomeIcon
          icon={['fal', 'circle']}
          size='lg'
          color={this.props.theme.colors.black}
          style={{
            marginRight: '.75rem',
            marginTop: '2px'
          }}
        />
      )

      if (item.get('completed')) {
        icon = (
          <FontAwesomeIcon
            icon={['fal', 'check-circle']}
            size='lg'
            color={this.props.theme.colors.black}
            style={{
              marginRight: '.75rem',
              marginTop: '2px'
            }}
          />
        )
      }

      return (
        <ChecklistItem
          key={index}
          onClick={() => {
            if (item.has('onClick')) {
              item.get('onClick')(index)
            }
          }}
        >
          <ChecklistItemIcon> {icon} </ChecklistItemIcon>
          <ChecklistItemTitle completed={item.get('completed')}> {item.get('title')} </ChecklistItemTitle>
          <ChecklistItemArrow>
            <FontAwesomeIcon
              icon={['far', 'angle-up']}
              color={this.props.theme.colors.darkGray}
              rotation={90}
            />
          </ChecklistItemArrow>
        </ChecklistItem>
      )
    })

    return (
      <Checklist>
        <ChecklistWrapper>
          <ChecklistTitle>
            <ChecklistTitleText>Getting Started</ChecklistTitleText>
            <SkipButton
              onClick={() => {
                if (remainingCount === 0) {
                  this.skip()
                } else {
                  this.setState({ showCloseModal: true })
                }
              }}
            >
              {remainingCount > 0 ? 'skip/close' : 'finish/close'}
            </SkipButton>
            <CloseWrapper onClick={() => this.closeOnboardingChecklist()}>
              <FontAwesomeIcon
                icon={['far', 'angle-up']}
                size='sm'
                color={this.props.theme.colors.gray40}
                rotation={180}
              />
            </CloseWrapper>
          </ChecklistTitle>
          <ChecklistDescription>
            Here are a couple of tasks to help guide you on starting an email sequence with Interseller.
          </ChecklistDescription>

          <ChecklistProgress>
            <ChecklistProgressPercentage>
              {completedPercentage}%
            </ChecklistProgressPercentage>
            <ChecklistProgressBar>
              <ChecklistProgressBarInner width={completedPercentage} />
            </ChecklistProgressBar>
          </ChecklistProgress>
        </ChecklistWrapper>

        <ChecklistItems>{checklistItems}</ChecklistItems>
      </Checklist>
    )
  }

  render () {
    const { checklist } = this.props
    const { showOnboardingChecklist, skipped } = this.state

    if (skipped || checklist.get('loading')) {
      return (<div />)
    }

    const completedCount = this.getChecklistItems().filter(i => i.get('completed')).count()
    const completedPercentage = Math.round(completedCount / this.getChecklistItems().count() * 100)
    const remainingCount = this.getChecklistItems().count() - completedCount
    if (!remainingCount) {
      return (<></>)
    }

    return (
      <Wrapper>
        {showOnboardingChecklist && this.renderOnboardingChecklist(completedPercentage, remainingCount)}
        {this.renderDisplayButton(remainingCount)}

        {/* these modals are hidden by default */}
        {this.renderConfirmModal()}
        {this.renderTutorialModal()}
      </Wrapper>
    )
  }
}

OnboardingChecklist.propTypes = {
  checklist: PropTypes.instanceOf(Immutable.Map),
  router: PropTypes.object,
  location: PropTypes.object,
  session: PropTypes.object,
  state: PropTypes.object,
  theme: PropTypes.object
}

export default withTheme(OnboardingChecklist)
