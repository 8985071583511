import React from 'react'
import PropTypes from 'prop-types'
import styled, { css } from 'styled-components'
import { space, width, textAlign } from 'styled-system'
import { Tooltip } from 'react-tippy'

import { glow } from 'utils/animations'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  color: ${props => props.theme.titleColor};
  text-align: left;

  ${space}
  ${width}
  ${textAlign}
`

const StateDot = styled.div`
  background: ${props => (props.active ? props.theme.colors.green : props.theme.colors.gray40)};
  width: 0.75rem;
  height: 0.75rem;
  border-radius: 50%;

  ${props => props.active && css`
    border: 1px solid transparent;
    animation: ${glow(props.theme.colors.green, 8)} 1.5s infinite alternate;
    transition: border 0.1s linear, box-shadow 0.1s linear;
  `}
`

const ActiveState = ({
  active,
  ...rest
}) => (
  <Wrapper {...rest}>
    <Tooltip
      title={active ? 'Actively sending messages' : 'Not sending messages'}
      position='top'
      trigger='mouseenter'
      arrow
    >
      <StateDot active={active} />
    </Tooltip>
  </Wrapper>
)

ActiveState.propTypes = {
  active: PropTypes.bool
}

export default ActiveState
