import React from 'react'
import PropTypes from 'prop-types'
import { withRouter } from 'react-router'
import styled, { withTheme } from 'styled-components'
import Avatar from 'react-avatar'
import format from 'date-fns/format'
import Immutable from 'immutable'
import EmptyState from 'components/EmptyState'
import DropDown from 'components/DropDown'
import Loading from 'components/Loading'
import MessagePreview from 'components/MessagePreview'
import Button from 'components/Button'
import Label from 'elements/Label'
import SequencePickerModal from 'components/SequencePickerModal'
import { ConfusedAlien } from 'svg'
import EditContactSidebar from '../EditContactSidebar'
import SentimentSuggestions from './SentimentSuggestions'
import ContactActivity from './ContactActivity'

const Wrapper = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: flex-start;
  width: 892px;
`

const LoadingWrapper = styled.div`
  height: 100%;
  width: 100%;
  background: ${props => props.theme.containerBackground};
  padding: 5rem;
`

const ProfileDivider = styled.div`
  width: 100%;
  max-width: 300px;
  transform: translateY(-1.5rem);
`

const ProfileContentContainer = styled.div`
  border: 1px solid ${props => props.theme.borderColor};
`

const MessagePreviewContainer = styled.div`
  border: 1px solid ${props => props.theme.borderColor};
`

const ProfileContent = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 2.6rem 1rem 2.25rem 1rem;
  background-color: ${props => props.theme.colors.white};
  border-bottom: 1px solid ${props => props.theme.colors.gray30};
`

const ContentHistory = styled.div`
  background-color: ${props => props.theme.colors.white};
`

const MessageSentimentDivider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`

const EmptyMessageSentimentDivider = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
  width: 100%;
  padding: 5rem;
  background-color: ${props => props.theme.colors.white};
  border: 1px solid ${props => props.theme.borderColor};
`

const HorizontalDivider = styled.div`
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
`

const VerticalDivider = styled.div`
  padding-left: 0.5rem;
  padding-right: 0.5rem;
`

const ContactName = styled.div`
  color: ${props => props.theme.colors.darkBlue};
  font: ${props => props.theme.fonts.header};
  padding-top: 0.5rem;

  cursor: pointer;
  border-bottom: 1px solid transparent;
  &:hover {
    border-bottom: 1px solid ${props => props.theme.darkBlue};
  }
`

const ButtonMenu = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: flex-end;
  align-items: center;
`

const SpaceBetween = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
`

const LinkButton = styled.div`
  padding: 0 0.5rem;
  color: ${props => props.theme.colors.lightBlue};
  font: ${props => props.theme.fonts.small};
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    text-decoration: underline;
  }
`

const SequenceLink = styled.div`
  color: ${props => props.theme.colors.grayBlue};
  cursor: pointer;
  text-decoration: underline;
`

const EmptyStateContainer = styled.div`
  border: 1px solid ${props => props.theme.borderColor};
  background-color: white;
`

const SENTIMENT_VALUES = [
  'INTERESTED',
  'NOT_INTERESTED',
  'BAD_FIT',
  'TIMING',
  'WRONG_CONTACT',
  'BAD_DATA',
  'SKIP',
  'UNSUBSCRIBE'
]

class ReplyTasks extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      selectedUser: null,
      selectedSentimentIndex: null,
      sentimentGuessIndex: null,
      hasResponse: false,

      repliedContactId: null,
      repliedContactName: null,
      repliedContactResponseTime: null,
      repliedContactSubject: null,
      repliedContactBody: null,

      showUnsubscribeModal: false,
      showEditContact: false
    }
  }

  UNSAFE_componentWillMount () {
    const { actions } = this.props
    actions.fetchTeamMembers()
    actions.fetchRepliedContact()
  }

  UNSAFE_componentWillUpdate (nextProps, nextState) {
    const prevProps = this.props
    const prevState = this.state

    const prevSelectedUser = prevState.selectedUser
    const contactUserId = prevProps.session.get('id')
    if (!prevSelectedUser && contactUserId) {
      this.setState({
        selectedUser: contactUserId
      })
    }

    const prevUserId = prevState.selectedUser
    const nextUserId = nextProps.members.get('id')
    if (prevUserId && nextUserId && nextUserId !== prevUserId) {
      this.setState({ selectedUser: nextUserId })
    }

    const prevContactId = prevProps.repliedContact && prevProps.repliedContact.getIn(['data', '0', 'id'])
    const nextContactId = nextProps.repliedContact && nextProps.repliedContact.getIn(['data', '0', 'id'])
    if (nextContactId && prevContactId !== nextContactId) {
      this.props.actions.fetchContactActions(nextContactId)
      this.props.actions.fetchSentimentScore(nextContactId)
      this.setState({
        selectedSentimentIndex: null
      })
      return
    }

    if (prevProps.sentimentScore.get('loading') && !nextProps.sentimentScore.get('loading')) {
      const sentimentGuess = nextProps.sentimentScore.getIn(['data', 'classification'])
      let sentimentGuessIndex = null
      let selectedSentimentIndex = null

      SENTIMENT_VALUES.forEach((value, index) => {
        if (value === sentimentGuess) {
          sentimentGuessIndex = index
          selectedSentimentIndex = index
        }
      })
      this.setState({ sentimentGuessIndex, selectedSentimentIndex })
    }

    const prevContactActivitySize = prevProps.contactActivity.size
    const nextContactActivitySize = nextProps.contactActivity.size
    if (nextContactActivitySize > 0 && prevContactActivitySize !== nextContactActivitySize) {
      const { repliedContact } = nextProps

      const contactId = repliedContact.getIn(['data', '0', 'id'])
      const hasResponse = this.isValidReply(nextProps.contactActivity)
      if (!hasResponse && contactId) {
        this.setState({ hasResponse: false })
      } else {
        this.setState({ hasResponse: true })
      }
    }

    const nextSelectedUser = nextState.selectedUser
    if (prevSelectedUser && nextSelectedUser && nextSelectedUser !== prevSelectedUser) {
      this.props.actions.fetchRepliedContact(nextSelectedUser)
    }
  }

  isValidReply = (contactActivity) => {
    for (const activity of contactActivity) {
      const verb = activity.get('verb')
      const hasData = activity.has('data')
      if (verb === 'replied' && hasData) {
        return true
      }
    }

    return false
  }

  getReplyData = (props) => {
    const { repliedContact, contactActivity } = props

    const contact = repliedContact.getIn(['data', 0]) || Immutable.Map()
    const name = repliedContact.getIn(['data', 0, 'name'])
    const photo = repliedContact.getIn(['data', 0, 'photo'])
    const email = repliedContact.getIn(['data', 0, 'email'])
    const sequenceTitle = repliedContact.getIn(['data', 0, '_campaign', 'title'])
    const sequenceId = repliedContact.getIn(['data', 0, '_campaign', '_id'])
    const contactId = repliedContact.getIn(['data', 0, '_id'])

    let time = null
    let subject = null
    let body = null

    for (const activity of contactActivity) {
      const verb = activity.get('verb')
      if (verb === 'replied') {
        const aggregate = activity.get('aggregate')
        const dateString = aggregate ? activity.get('start_at') : activity.get('created_at')
        time = dateString

        const strippedBody = activity.getIn(['data', 'reply'])
        const fullBody = activity.getIn(['data', 'body'])
        body = (strippedBody) || fullBody

        subject = activity.getIn(['data', 'subject'])
      }
    }

    return {
      repliedContact: contact,
      contactActivity,
      repliedContactName: name,
      repliedContactId: contactId,
      repliedContactPhoto: photo,
      repliedContactEmail: email,
      repliedContactSubject: subject,
      repliedContactBody: body,
      repliedContactResponseTime: time,
      repliedContactSequenceId: sequenceId,
      repliedContactSequenceTitle: sequenceTitle
    }
  }

  saveSentiment = (skip) => {
    const { actions, repliedContact } = this.props
    const contactId = repliedContact.getIn(['data', 0, 'id'])
    const { selectedSentimentIndex, selectedUser } = this.state

    if (skip) {
      actions.updateContactSentiment(skip, contactId, selectedUser)
    } else {
      if (selectedSentimentIndex === null) {
        return
      }

      actions.updateContactSentiment(SENTIMENT_VALUES[selectedSentimentIndex], contactId, selectedUser)
    }

    this.setState({
      showEditContact: false
    })
  }

  getDropdown = () => {
    const {
      repliedContact,
      members,
      session
    } = this.props

    const {
      selectedUser
    } = this.state

    if (!repliedContact) {
      return null
    }

    let options = []
    members.get('data')
      .filter(member => !session.get('group') || session.get('group') === member.get('group'))
      .map((member, i) => {
        options.push({
          label: member.get('full_name') || member.get('email'),
          value: JSON.stringify({
            userId: member.get('id')
          }),
          index: i
        })
        return null
      })

    options = Immutable.List(options)

    let selectedOption
    selectedOption = {}
    selectedOption.userId = selectedUser
    selectedOption = JSON.stringify(selectedOption)

    return (
      <DropDown
        options={options}
        value={selectedOption}
        clearable={false}
        controlled
        searchable
        onOptionChange={option => {
          const value = option ? JSON.parse(option.value) : {}
          this.setState({
            selectedUser: value.userId,
            selectedSentimentIndex: null,
            sentimentGuessIndex: null
          })
        }}
        mt='1.5rem'
      />
    )
  }

  render () {
    const {
      theme,
      router,
      sequence,
      sequences,
      actions
    } = this.props

    const {
      repliedContact,
      repliedContactName,
      repliedContactId,
      repliedContactPhoto,
      repliedContactEmail,
      repliedContactSubject,
      repliedContactBody,
      repliedContactSequenceId,
      repliedContactSequenceTitle
    } = this.getReplyData(this.props)

    const {
      showCopyModal
    } = this.state

    if (this.props.members.get('loading')) {
      return (
        <LoadingWrapper>
          <Loading />
        </LoadingWrapper>
      )
    }

    const hasContact = !!repliedContact.get('id')

    const contactStatus = `Replied on ${format(Date.parse(repliedContact.get('replied_at')), 'MMM Do [at] h:mma')}`

    return (
      <Wrapper>
        <ProfileDivider>
          {this.getDropdown()}
          <HorizontalDivider />
          {!this.props.repliedContact.get('loading') && !this.props.contactActivityLoading && hasContact &&
            <ProfileContentContainer>
              <ProfileContent>
                <Avatar
                  email={repliedContactEmail}
                  maxInitials={2}
                  src={repliedContactPhoto}
                  name={repliedContactName}
                  textSizeRatio={2}
                  size={80}
                  round
                  colors={theme.avatarColors}
                />
                <ContactName onClick={() => {
                  this.props.actions.fetchSequence(repliedContactSequenceId)
                  this.setState({
                    showEditContact: true
                  })
                }}
                >
                  {repliedContactName}
                </ContactName>
                <Label align='center' pt='0.25rem'>{contactStatus}</Label>
                <Label align='center' pt='0.25rem'>
                  <SequenceLink
                    onClick={() => {
                      router.push(`/sequence/${repliedContactSequenceId}/contacts/${repliedContactId}`)
                    }}
                  >
                    {`${repliedContactSequenceTitle}`}
                  </SequenceLink>
                </Label>
              </ProfileContent>
              <ContentHistory>
                <HorizontalDivider />
                <ContactActivity
                  contactActivity={this.props.contactActivity}
                  theme={this.props.theme}
                  router={this.props.router}
                />
              </ContentHistory>
            </ProfileContentContainer>}
          {(this.props.repliedContact.get('loading') || this.props.contactActivityLoading) && <Loading />}
        </ProfileDivider>

        <VerticalDivider />

        {!this.props.repliedContact.get('loading') && !this.props.contactActivityLoading && !hasContact &&
          <EmptyStateContainer>
            <EmptyState
              title='No Replies Need Attention'
              description="When a contact replies, you'll find them here for you to read and organize."
            />
          </EmptyStateContainer>}

        {!this.props.repliedContact.get('loading') && !this.props.contactActivityLoading && hasContact &&
          <MessageSentimentDivider>
            {!this.state.hasResponse &&
              <EmptyStateContainer>
                <EmptyState
                  icon={
                    <ConfusedAlien
                      width='163px'
                      height='163px'
                    />
                }
                  title='No Reply Found'
                  description="We couldn't find replied message content for this contact &mdash; you may have manually marked this contact as replied."
                />
              </EmptyStateContainer>}

            {this.state.hasResponse &&
              <MessagePreviewContainer>
                <MessagePreview
                  subject={repliedContactSubject}
                  body={repliedContactBody}
                />
              </MessagePreviewContainer>}

            <HorizontalDivider />

            <SentimentSuggestions
              sentimentValues={SENTIMENT_VALUES}
              selectedSentimentIndex={this.state.selectedSentimentIndex}
              sentimentGuessIndex={this.state.sentimentGuessIndex}
              setSentimentState={selectedSentimentIndex => {
                this.setState({ selectedSentimentIndex })
              }}
            />

            <HorizontalDivider />
            <ButtonMenu>
              <LinkButton
                onClick={() => {
                  this.setState({
                    showCopyModal: true
                  })
                }}
              >
                Copy Contact
              </LinkButton>
              <SpaceBetween />
              <LinkButton
                onClick={() => {
                  this.saveSentiment('SKIP')
                }}
              >
                Skip
              </LinkButton>
              <VerticalDivider />
              <Button
                label='Save & Continue ⭢'
                handleClick={() => {
                  this.saveSentiment(null)
                }}
                disabled={this.state.selectedSentimentIndex === null}
                primary
              />
            </ButtonMenu>
          </MessageSentimentDivider>}

        {(this.props.repliedContact.get('loading') || this.props.contactActivityLoading) &&
          <EmptyMessageSentimentDivider>
            <Loading />
          </EmptyMessageSentimentDivider>}

        {this.state.showEditContact &&
          <EditContactSidebar
            contact={repliedContact}
            sequence={sequence.get('data')}
            loading={sequence.get('loading')}
            theme={this.props.theme}
            router={this.props.router}
            onClose={() => {
              this.setState({
                showEditContact: false
              })
            }}
            onSave={(params) => {
              this.props.actions.updateSequenceContact(repliedContactId, params)
              this.setState({
                showEditContact: false
              })
            }}
          />}

        <SequencePickerModal
          title='Copy Contact'
          description={`Choose the sequence to copy ${repliedContact.get('name')} to:`}
          sequences={sequences}
          actions={actions}
          isOpen={showCopyModal}
          onCancel={() => {
            this.setState({
              showCopyModal: false
            })
          }}
          onConfirm={(s) => {
            actions.copyContacts(repliedContactSequenceId, [repliedContactId], s, false)
            this.setState({
              showCopyModal: false
            })
          }}
        />
      </Wrapper>
    )
  }
}

ReplyTasks.propTypes = {
  router: PropTypes.object,
  tasks: PropTypes.object,
  repliedContact: PropTypes.object,
  contactCompany: PropTypes.object,
  contactActivity: PropTypes.object,
  contactActivityLoading: PropTypes.bool,
  actions: PropTypes.object,
  sentimentScore: PropTypes.object,
  templates: PropTypes.object,
  selectedContact: PropTypes.object,
  session: PropTypes.object,
  members: PropTypes.object,
  sequences: PropTypes.object,
  theme: PropTypes.object,
  sequence: PropTypes.object
}

export default withRouter(withTheme(ReplyTasks))
