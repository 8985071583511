import { Block } from 'slate'

const subjectSchema = {
  document: {
    nodes: [{
      match: { type: 'paragraph' },
      min: 1,
      max: 1
    }]
  },
  inlines: {
    emoji: {
      isVoid: true
    }
  }
}

const bodySchema = {
  document: {
    last: { type: 'paragraph' },
    normalize: (editor, { code, node, child }) => {
      switch (code) {
        case 'last_child_type_invalid': {
          const paragraph = Block.create('paragraph')
          return editor.insertNodeByKey(node.key, node.nodes.size, paragraph)
        }
      }
    }
  },
  blocks: {
    image: {
      isVoid: true
    }
  },
  inlines: {
    emoji: {
      isVoid: true
    }
  }
}

export {
  subjectSchema,
  bodySchema
}
