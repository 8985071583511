import React, { PureComponent } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme, css } from 'styled-components'
import { Tooltip } from 'react-tippy'

import { pluralize } from 'utils/strings'
import Label from 'elements/Label'
import Popover from 'components/Popover'
import { Menu, MenuItem, MenuDivider } from 'elements/Menu'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { apiBaseURI } from 'utils/api'

import { ACCOUNT_NAMES } from 'containers/App/constants'

const Wrapper = styled.div`
  flex: 0 1 auto;
  background: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const UserContainer = styled.div`
  flex: 0 1 auto;
  background: #FFF;
  color: #FFF;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin: 1 rem;
  height: 100%;
`

const UserLabel = styled(Label)`
  color: ${props => props.theme.primaryColor};
  padding-left: 1rem;
  cursor: pointer;

  ${props => props.userState === 'INACTIVE' && css`
    color: ${props.theme.colors.red};
  `};

  ${props => props.userState === 'EXPIRED' && css`
    color: ${props.theme.colors.red};
  `};
`

const AuthStatusWrapper = styled.div`
  background-color: ${props => props.theme.colors.red};
  padding: 0.5rem 1rem;
  cursor: pointer;
  font: ${props => props.theme.fonts.searchtitle};
  border-radius: 1000px;
  width: 100%;
`

const AvatarContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 100%;
`

const Avatar = styled.div`
  background-size: cover;
  background-position: center;
  background-color: #FFF;
  border-radius: 9999px;
  width: 35px;
  height: 35px;
  margin-top: auto;
  margin-bottom: auto;
  margin-left: 1rem;
  background-image: url(${props => props.photoUrl || '/bear_avatar.png'});
`

const HeadwayContainer = styled.div`
  #HW_badge_cont {
    height: 16px!important;
    width: 24px!important;
  }

  #HW_badge {
    top: 0px!important;
    left: 4px!important;
  }
`

const HeadwayBlock = styled.div`
  display: inline-block;
  left: -24px;
  width: 24px;
  height: 16px;
  position: relative;
`

const NameLabel = styled(Label)`
  white-space: nowrap;
`

class UserAvatar extends PureComponent {
  state = {
    isConnectionLost: false,
    isCrmConnectionLost: false
  }

  static getDerivedStateFromProps (props, st) {
    const { state } = props
    let newState = null

    const auth = state?.get('auth')
    const crmAuth = state?.get('crm_auth')

    // TODO: Check session for current email provider and show logo and name
    // TODO: Check session for current integration and show logo and name
    newState = {
      ...st,
      isConnectionLost: !!auth,
      isCrmConnectionLost: !!crmAuth
    }

    return newState
  }

  timeLeftInTrial = (trialSeconds) => {
    if (trialSeconds < 86400) {
      return 'Last day'
    }
    const trialDays = Math.ceil(trialSeconds / 86400)
    return `${trialDays} ${pluralize('day', 'days', trialDays)} left`
  }

  componentDidUpdate () {
    if (window.Headway) {
      window.Headway.init({
        selector: '#headwayapp',
        account: '7z8rRy',
        trigger: '#null'
      })
    }
  }

  checkSubscriptionStatus ({ subscribed, trialSeconds, inactive, unpaid, isCustomer, trialGiven, needsBillingAddressUpdate, isOwner }) {
    if (!subscribed) {
      const inTrial = trialSeconds && trialSeconds > 0
      if (inTrial) {
        return {
          userStateLabel: `${this.timeLeftInTrial(trialSeconds)} of your trial`,
          userState: 'TRIALING'
        }
      } else if (isCustomer) {
        return { userStateLabel: 'Subscription expired', userState: 'EXPIRED' }
      } else if (trialGiven) {
        return { userStateLabel: 'Trial expired', userState: 'EXPIRED' }
      } else {
        return { userStateLabel: 'Add payment method', userState: 'PAYMENT' }
      }
    }

    // if (inactive) {
    //   return { userStateLabel: 'Deactivated', userState: 'INACTIVE', userStateUrl: '/team' }
    // }

    if (subscribed) {
      if (unpaid) {
        return { userStateLabel: 'Payment failed', userState: 'EXPIRED', userStateUrl: '/billing?change' }
      }
      if (isOwner && needsBillingAddressUpdate) {
        return { userStateLabel: 'Update Billing Address', userState: 'EXPIRED', userStateUrl: '/billing?change' }
      }
      return {}
    }

    return {}
  }

  renderBadge () {
    const {
      state,
      session,
      router
    } = this.props

    let userStateLabel
    let userState
    let userStateUrl
    if (state && state.has('active')) {
      const subscribed = state.get('subscribed')
      const trialSeconds = state.get('trial')
      const inactive = state.get('is_inactive')
      const unpaid = state.get('unpaid')
      const isCustomer = state.get('customer')
      const trialGiven = state.get('trial_given')
      const needsBillingAddressUpdate = state.get('needs_billing_address_update')
      const isOwner = state.get('is_owner')

      const subscriptionStatus = this.checkSubscriptionStatus({
        subscribed,
        trialSeconds,
        inactive,
        unpaid,
        isCustomer,
        trialGiven,
        needsBillingAddressUpdate,
        isOwner
      })

      userStateLabel = subscriptionStatus.userStateLabel
      userState = subscriptionStatus.userState
      userStateUrl = subscriptionStatus.userStateUrl
    }

    const { isConnectionLost, isCrmConnectionLost } = this.state

    const auth = state.get('auth')
    const crmAuth = state.get('crm_auth')
    const crmName = state.get('crm_name') || 'Integration'
    const accountName = ACCOUNT_NAMES[session.get('account_type')] || 'Email account'

    if (isConnectionLost) {
      return (
        <Tooltip
          style={{ marginLeft: '1rem' }}
          key='filter'
          title={`Email sending and syncing have been paused until you reconnect your ${accountName} account. Click to reconnect.`}
          position='bottom'
          trigger='mouseenter'
          arrow
        >
          <AuthStatusWrapper
            onClick={() => {
              const redirect = `${window.location.protocol}//${window.location.hostname + (window.location.port ? `:${window.location.port}` : '')}/`
              window.location = `${apiBaseURI}${auth}?redirect=${redirect}`
            }}
          >
            {accountName} disconnected
          </AuthStatusWrapper>
        </Tooltip>
      )
    }

    if (isCrmConnectionLost) {
      return (
        <Tooltip
          style={{ marginLeft: '1rem' }}
          key='filter'
          title={`Syncing to ${crmName} has been paused. Click to reconnect.`}
          position='bottom'
          trigger='mouseenter'
          arrow
        >
          <AuthStatusWrapper
            userState={userState}
            onClick={() => {
              const redirect = `${window.location.protocol}//${window.location.hostname + (window.location.port ? `:${window.location.port}` : '')}/integrations/crm`
              window.location = `${apiBaseURI}${crmAuth}?redirect=${redirect}`
            }}
          >
            {crmName} disconnected
          </AuthStatusWrapper>
        </Tooltip>
      )
    }

    if (userStateLabel) {
      return (
        <UserLabel
          userState={userState}
          onClick={() => {
            router.push(userStateUrl || '/billing')
          }}
        >
          {userStateLabel}
        </UserLabel>
      )
    }
  }

  render () {
    const {
      session,
      router,
      handleLogout,
      theme
    } = this.props

    let photoUrl = ''
    if (session) {
      photoUrl = session.get('photo_url')
    }

    return (
      <Wrapper>
        <UserContainer>
          {this.renderBadge()}
          <Popover
            ref={(element) => { this.popover = element }}
            minWidth='10rem'
            right='1rem'
            position='right'
            content={
              <Menu>
                <NameLabel mt='0.5rem' ml='0.5rem' mr='0.5rem' color={theme.titleColor}>
                  <strong>
                    {session.get('full_name')}
                  </strong>
                </NameLabel>
                <NameLabel ml='0.5rem' mr='0.5rem' mb='1rem'>
                  {session.get('email')}
                </NameLabel>
                <MenuDivider />
                <MenuItem onClick={() => {
                  router.push('/team')
                }}
                >
                  Team
                </MenuItem>
                <MenuItem onClick={() => {
                  router.push('/integrations/crm')
                }}
                >
                  Integrations
                </MenuItem>
                <MenuItem onClick={() => {
                  router.push('/billing')
                }}
                >
                  Billing
                </MenuItem>
                <MenuItem onClick={() => {
                  router.push('/settings/general')
                }}
                >
                  Settings
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={() => {
                  window.open('https://help.interseller.io')
                }}
                >
                  Help
                </MenuItem>
                <MenuItem onClick={() => {
                  window.open('https://updates.interseller.io')
                  window.Headway.markAllSeen()
                }}
                >
                  Updates
                  <HeadwayContainer id='headwayapp' />
                  <HeadwayBlock />
                </MenuItem>
                <MenuItem onClick={() => {
                  window.open('https://www.interseller.io/blog')
                }}
                >
                  Blog
                </MenuItem>
                <MenuDivider />
                <MenuItem onClick={handleLogout}>
                  Logout
                </MenuItem>
              </Menu>
            }
          >
            {session &&
              <AvatarContainer>
                <Avatar photoUrl={photoUrl} />
                <FontAwesomeIcon
                  size='lg'
                  icon={['fal', 'angle-down']}
                  color={theme.colors.gray70}
                  style={{ margin: '1px .5rem 0 .5rem' }}
                />
              </AvatarContainer>}
          </Popover>
        </UserContainer>
      </Wrapper>
    )
  }
}

UserAvatar.propTypes = {
  session: PropTypes.object,
  state: PropTypes.object,
  router: PropTypes.object,
  handleLogout: PropTypes.func,
  theme: PropTypes.object
}

export default withTheme(UserAvatar)
