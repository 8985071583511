import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'
import { rotate } from 'utils/animations'

import Loading from 'components/Loading'
import SwitchFieldForm from 'components/SwitchFieldForm'
import DropDownFieldForm from 'components/DropDownFieldForm'
import EmptyState from 'components/EmptyState'

import { Satellite } from 'svg'

import withSession from 'hocs/session'
import Immutable from 'immutable'

import { CRM_FIELDS } from 'containers/Integrations/constants'

const Wrapper = styled.div`
  width: 100%;
  padding: ${props => props.theme.padding};

  .svg-inline--fa.fa-spinner-third {
    animation: ${rotate} 0.8s linear infinite;
  }
`

const FieldContainer = styled.div`
  padding: 1rem;
  text-align: left;
`

const EmptyStateWrapper = styled.div`
  padding: 2rem;
`

class Integration extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      sequence,
      onSettingUpdate,
      crm,
      onCRMRefresh
    } = this.props

    if (crm.get('loading')) {
      return <Loading />
    }

    const crmName = crm.getIn(['data', 'name'])

    if (!crmName) {
      return (
        <EmptyStateWrapper>
          <EmptyState
            icon={<Satellite />}
            title='Connect Your Integration'
            description='You can modify your sync settings to your integration for each sequence here. But first, you need to connect your integration.'
          />
        </EmptyStateWrapper>
      )
    }

    const crmDisabled = sequence.get('crm_disabled')

    const listType = crm.getIn(['data', 'list_type'])
    const list = sequence.get('crm_list') || crm.getIn(['data', 'list'])
    const supportedLists = crm.getIn(['data', 'lists'])
    let listOptions
    if (supportedLists) {
      listOptions = supportedLists.map(l =>
        ({
          value: l.get('id'),
          label: l.get('title')
        }))
    }

    const stageType = crm.getIn(['data', 'stage_type'])
    const stage = sequence.get('crm_stage') || crm.getIn(['data', 'stage'])
    const supportedStages = crm.getIn(['data', 'stages'])
    let stageOptions
    if (supportedStages) {
      stageOptions = supportedStages.map(s =>
        ({
          value: s.get('id'),
          label: s.get('title')
        }))
    }

    const model = sequence.get('crm_model')
    const supportedModels = crm.getIn(['data', 'models'])
    const hasModels = supportedModels ? supportedModels.count() > 0 : false
    let modelOptions
    if (hasModels) {
      modelOptions = supportedModels.map(m =>
        ({
          value: m,
          label: m
        }))
    }

    return (
      <Wrapper>
        <FieldContainer>
          <SwitchFieldForm
            label={`Sync to ${crmName}`}
            value={!crmDisabled}
            description={`Syncing contacts to ${crmName} is disabled for this sequence`}
            descriptionOn={`Syncing to ${crmName} is enabled. Turning this option off will disable syncing contacts to ${crmName} for this sequence.`}
            inputId='shared'
            onSave={(value) => {
              onSettingUpdate({
                crm_disabled: !(value || false)
              })
            }}
          />
        </FieldContainer>
        {supportedLists &&
          <FieldContainer>
            <DropDownFieldForm
              label={`${listType}`}
              description={`Set ${listType} this sequence syncs new contacts to in ${crmName}`}
              clearable
              searchable
              options={listOptions}
              value={list}
              inputId='list'
              refreshing={crm?.get('refreshing') && this.field === CRM_FIELDS.LISTS}
              onOptionChange={(option) => {
                onSettingUpdate({ crm_list: option })
              }}
              onRefresh={() => {
                this.field = CRM_FIELDS.LISTS
                onCRMRefresh()
              }}
              refreshTooltip={`Refresh ${listType} from ${crmName}`}
            />
          </FieldContainer>}
        {supportedStages &&
          <FieldContainer>
            <DropDownFieldForm
              label={`${stageType}`}
              description={`Set ${stageType} this sequence syncs new contacts to in ${crmName}`}
              clearable
              searchable
              options={stageOptions}
              value={stage}
              inputId='stage'
              refreshing={crm?.get('refreshing') && this.field === CRM_FIELDS.STAGES}
              onOptionChange={(option) => {
                onSettingUpdate({ crm_stage: option })
              }}
              onRefresh={() => {
                this.field = CRM_FIELDS.STAGES
                onCRMRefresh()
              }}
              refreshTooltip={`Refresh ${stageType} from ${crmName}`}

            />
          </FieldContainer>}
        {modelOptions &&
          <FieldContainer>
            <DropDownFieldForm
              label='Sync as'
              description={`Set how new contacts are synced for this sequence in ${crmName}`}
              clearable
              options={modelOptions}
              value={model}
              inputId='list'
              onOptionChange={(option) => {
                onSettingUpdate({ crm_model: option })
              }}
            />
          </FieldContainer>}
      </Wrapper>
    )
  }
}

Integration.propTypes = {
  sequence: PropTypes.instanceOf(Immutable.Map),
  onSettingUpdate: PropTypes.func,
  crm: PropTypes.instanceOf(Immutable.Map),
  onCRMRefresh: PropTypes.func
}

export default withSession(Integration)
