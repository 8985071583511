import {
  // Data requests
  FETCH_CONTACTS_REQUEST,
  FETCH_CONTACTS_SUCCESS,
  FETCH_CONTACTS_FAILURE,

  // Fetch sequence stats
  FETCH_SEQUENCE_STATS_REQUEST,
  FETCH_SEQUENCE_STATS_SUCCESS,
  FETCH_SEQUENCE_STATS_FAILURE,

  // Fetch sequence
  FETCH_SEQUENCE_REQUEST,
  FETCH_SEQUENCE_SUCCESS,
  FETCH_SEQUENCE_FAILURE,

  // Delete sequence
  DELETE_SEQUENCE_REQUEST,
  DELETE_SEQUENCE_SUCCESS,
  DELETE_SEQUENCE_FAILURE,

  // Fetch sequence state
  FETCH_SEQUENCE_STATE_REQUEST,
  FETCH_SEQUENCE_STATE_SUCCESS,
  FETCH_SEQUENCE_STATE_FAILURE,

  // Fetch step stats
  FETCH_STEP_STATS_REQUEST,
  FETCH_STEP_STATS_SUCCESS,
  FETCH_STEP_STATS_FAILURE,

  // Fetch export csv link
  FETCH_EXPORT_CSV_REQUEST,
  FETCH_EXPORT_CSV_SUCCESS,
  FETCH_EXPORT_CSV_FAILURE,

  // Fetch export pdf link
  FETCH_EXPORT_PDF_REQUEST,
  FETCH_EXPORT_PDF_SUCCESS,
  FETCH_EXPORT_PDF_FAILURE,

  // Fetch contact
  FETCH_CONTACT_REQUEST,
  FETCH_CONTACT_SUCCESS,
  FETCH_CONTACT_FAILURE,

  // Update Contact
  UPDATE_CONTACT_REQUEST,
  UPDATE_CONTACT_SUCCESS,
  UPDATE_CONTACT_FAILURE,

  // Clone Contact
  CLONE_CONTACT_REQUEST,
  CLONE_CONTACT_SUCCESS,
  CLONE_CONTACT_FAILURE,

  // Replied Contact
  REPLIED_CONTACT_REQUEST,
  REPLIED_CONTACT_SUCCESS,
  REPLIED_CONTACT_FAILURE,

  // Unsubscribe Contact
  UNSUBSCRIBE_CONTACT_REQUEST,
  UNSUBSCRIBE_CONTACT_SUCCESS,
  UNSUBSCRIBE_CONTACT_FAILURE,

  // Blocklist Contact
  BLOCK_CONTACT_REQUEST,
  BLOCK_CONTACT_SUCCESS,
  BLOCK_CONTACT_FAILURE,

  // Fetch contact company
  FETCH_CONTACT_COMPANY_REQUEST,
  FETCH_CONTACT_COMPANY_SUCCESS,
  FETCH_CONTACT_COMPANY_FAILURE,

  // Fetch contact actions
  FETCH_CONTACT_ACTIONS_REQUEST,
  FETCH_CONTACT_ACTIONS_SUCCESS,
  FETCH_CONTACT_ACTIONS_FAILURE,
  FETCH_CONTACT_ACTIONS_RESET,

  // Fetch contact integration
  FETCH_CONTACT_INTEGRATION_REQUEST,
  FETCH_CONTACT_INTEGRATION_SUCCESS,
  FETCH_CONTACT_INTEGRATION_FAILURE,

  // Fetch contact messages
  FETCH_CONTACT_MESSAGES_REQUEST,
  FETCH_CONTACT_MESSAGES_SUCCESS,
  FETCH_CONTACT_MESSAGES_FAILURE,
  FETCH_CONTACT_MESSAGES_RESET,

  // Report inaccurate contact
  REPORT_CONTACT_INACCURACY_REQUEST,
  REPORT_CONTACT_INACCURACY_SUCCESS,
  REPORT_CONTACT_INACCURACY_FAILURE,

  // Create contact
  CREATE_CONTACT_REQUEST,
  CREATE_CONTACT_SUCCESS,
  CREATE_CONTACT_FAILURE,
  CREATE_CONTACT_RESET,

  // Fetch sequence preview
  FETCH_SEQUENCE_PREVIEW_REQUEST,
  FETCH_SEQUENCE_PREVIEW_SUCCESS,
  FETCH_SEQUENCE_PREVIEW_FAILURE,

  // Update sequence
  UPDATE_SEQUENCE_REQUEST,
  UPDATE_SEQUENCE_SUCCESS,
  UPDATE_SEQUENCE_FAILURE,
  UPDATE_SEQUENCE_RESET,

  // Restore sequence
  RESTORE_SEQUENCE_REQUEST,
  RESTORE_SEQUENCE_SUCCESS,
  RESTORE_SEQUENCE_FAILURE,

  // Update custom field
  UPDATE_CUSTOM_FIELDS_REQUEST,
  UPDATE_CUSTOM_FIELDS_SUCCESS,
  UPDATE_CUSTOM_FIELDS_FAILURE,

  // Send test email
  SEND_TEST_EMAIL_REQUEST,

  // Mass Update Contacts
  DELETE_CONTACTS_REQUEST,
  MOVE_CONTACTS_REQUEST,
  COPY_CONTACTS_REQUEST,
  REMOVE_SEQUENCE_CONTACTS,
  UPDATE_CONTACTS_REQUEST,
  UPDATE_CONTACTS_SUCCESS,

  // Import CSV
  CREATE_CSV_REQUEST,
  CREATE_CSV_SUCCESS,
  CREATE_CSV_FAILURE,
  CREATE_CSV_RESET,

  // Update CSV
  UPDATE_CSV_REQUEST,
  UPDATE_CSV_SUCCESS,
  UPDATE_CSV_FAILURE,

  // Import CSV
  IMPORT_CSV_REQUEST,
  IMPORT_CSV_SUCCESS,
  IMPORT_CSV_FAILURE,

  // Fetch templates
  FETCH_TEMPLATES_REQUEST,
  FETCH_TEMPLATES_SUCCESS,
  FETCH_TEMPLATES_FAILURE,

  // Create templates
  CREATE_TEMPLATE_REQUEST,
  CREATE_TEMPLATE_SUCCESS,
  CREATE_TEMPLATE_FAILURE,

  // Delete Templates
  DELETE_TEMPLATE_REQUEST,
  DELETE_TEMPLATE_SUCCESS,
  DELETE_TEMPLATE_FAILURE,

  // Customize contact step
  UPDATE_CONTACT_STEP_REQUEST,
  UPDATE_CONTACT_STEP_SUCCESS,
  UPDATE_CONTACT_STEP_FAILURE,

  // Remove contact step
  REMOVE_CONTACT_STEP_REQUEST,
  REMOVE_CONTACT_STEP_SUCCESS,
  REMOVE_CONTACT_STEP_FAILURE,

  // Fetch lookup status
  FETCH_LOOKUP_STATUS_REQUEST,
  FETCH_LOOKUP_STATUS_SUCCESS,
  FETCH_LOOKUP_STATUS_FAILURE,

  // Fetch preview contacts
  FETCH_PREVIEW_CONTACTS_REQUEST,
  FETCH_PREVIEW_CONTACTS_SUCCESS,
  FETCH_PREVIEW_CONTACTS_FAILURE,

  // Send now
  FORCE_SEND_STEP_REQUEST,

  // Upload images / attachments
  CREATE_FILE_REQUEST,
  CREATE_FILE_SUCCESS,
  CREATE_FILE_FAILURE,
  CREATE_FILE_RESET,

  // Check for similar contacts
  FETCH_SIMILAR_CONTACTS_REQUEST,
  FETCH_SIMILAR_CONTACTS_SUCCESS,
  FETCH_SIMILAR_CONTACTS_FAILURE,

  // Sequence's CRM
  FETCH_CRM_REQUEST,
  FETCH_CRM_SUCCESS,
  FETCH_CRM_FAILURE,

  // Refresh CRM
  REFRESH_CRM_REQUEST,

  // Fetch contact's reply
  FETCH_CONTACT_REPLY_REQUEST,
  FETCH_CONTACT_REPLY_SUCCESS,
  FETCH_CONTACT_REPLY_FAILURE,

  // Transfer sequence
  TRANSFER_SEQUENCE_REQUEST,
  TRANSFER_SEQUENCE_SUCCESS,
  TRANSFER_SEQUENCE_FAILURE,
  NEW_SEQUENCE_RESET,

  // fetch sent messages
  FETCH_SENT_MESSAGES_REQUEST,
  FETCH_SENT_MESSAGES_SUCCESS,
  FETCH_SENT_MESSAGES_FAILURE,
  FETCH_SENT_MESSAGES_RESET,

  // fetch all errors on a contact
  FETCH_CONTACT_ERRORS_REQUEST,
  FETCH_CONTACT_ERRORS_SUCCESS,
  FETCH_CONTACT_ERRORS_FAILURE,
  FETCH_CONTACT_ERRORS_RESET,

  // clear errors on a contact
  UPDATE_CONTACT_ERRORS_REQUEST,
  UPDATE_CONTACT_ERRORS_SUCCESS,
  UPDATE_CONTACT_ERRORS_FAILURE,

  // fetch selected contact count
  FETCH_SELECTED_CONTACT_COUNT_REQUEST,
  FETCH_SELECTED_CONTACT_COUNT_SUCCESS,
  FETCH_SELECTED_CONTACT_COUNT_FAILURE,

  // fetch ogp data from a url
  FETCH_OGP_REQUEST,
  FETCH_OGP_SUCCESS,
  FETCH_OGP_FAILURE,

  // fetch addresses / aliases
  FETCH_ADDRESSES_REQUEST,
  FETCH_ADDRESSES_SUCCESS,
  FETCH_ADDRESSES_FAILURE,

  FETCH_CONTACT_STEPS_REQUEST,
  FETCH_CONTACT_STEPS_SUCCESS,
  FETCH_CONTACT_STEPS_FAILURE,
  FETCH_CONTACT_STEPS_RESET,

  FETCH_CONTACT_PHONE_REQUEST,
  FETCH_CONTACT_PHONE_SUCCESS,
  FETCH_CONTACT_PHONE_FAILURE,

  IMPORT_CRM_CONTACTS_REQUEST,
  IMPORT_CRM_CONTACTS_SUCCESS,
  IMPORT_CRM_CONTACTS_FAILURE,

  IMPORT_CRM_OPTION_SELECTED,
  IMPORT_CRM_CONTACTS_RESET,

  SHOW_WARNING_MODAL,
  HIDE_WARNING_MODAL,

  RESYNC_TO_CRM_REQUEST,
  RESYNC_TO_CRM_SUCCESS,
  RESYNC_TO_CRM_FAILURE,

  PHONE_NUMBER_LOOKUPS_REQUEST,
  PHONE_NUMBER_LOOKUPS_SUCCESS,
  PHONE_NUMBER_LOOKUPS_FAILURE,

  FETCH_SEQUENCE_SIGNATURE_REQUEST,
  FETCH_SEQUENCE_SIGNATURE_SUCCESS,
  FETCH_SEQUENCE_SIGNATURE_FAILURE
} from './constants'

export function fetchSequence (sequenceId) {
  return {
    type: FETCH_SEQUENCE_REQUEST,
    sequenceId
  }
}

export function fetchSequenceSuccess ({ sequence }) {
  return {
    type: FETCH_SEQUENCE_SUCCESS,
    payload: {
      sequence
    }
  }
}

export function fetchSequenceFailure (err) {
  return {
    type: FETCH_SEQUENCE_FAILURE,
    err
  }
}

export function deleteSequence (sequenceId) {
  return {
    type: DELETE_SEQUENCE_REQUEST,
    sequenceId
  }
}

export function deleteSequenceSuccess (sequence) {
  return {
    type: DELETE_SEQUENCE_SUCCESS,
    payload: {
      sequence,
      sequenceId: sequence.get('id')
    }
  }
}

export function deleteSequenceFailure (err) {
  return {
    type: DELETE_SEQUENCE_FAILURE,
    err
  }
}

export function fetchSequenceState (sequenceId) {
  return {
    type: FETCH_SEQUENCE_STATE_REQUEST,
    sequenceId
  }
}

export function refreshSequenceState (sequenceId) {
  return {
    type: FETCH_SEQUENCE_STATE_REQUEST,
    sequenceId
  }
}

export function fetchSequenceStateSuccess ({ state, sequenceId }) {
  return {
    type: FETCH_SEQUENCE_STATE_SUCCESS,
    payload: {
      state,
      sequenceId
    }
  }
}

export function fetchSequenceStateFailure (err) {
  return {
    type: FETCH_SEQUENCE_STATE_FAILURE,
    err
  }
}

export function fetchContacts (sequenceId, query) {
  return {
    type: FETCH_CONTACTS_REQUEST,
    sequenceId,
    query
  }
}

export function fetchContactsSuccess ({ contacts, paginating = false, hasMore = false }) {
  return {
    type: FETCH_CONTACTS_SUCCESS,
    payload: {
      contacts,
      paginating,
      hasMore
    }
  }
}

export function fetchContactsFailure (err) {
  return {
    type: FETCH_CONTACTS_FAILURE,
    err
  }
}

export function fetchSequenceStats (sequenceId) {
  return {
    type: FETCH_SEQUENCE_STATS_REQUEST,
    sequenceId
  }
}

export function fetchSequenceStatsSuccess ({ stats, sequenceId }) {
  return {
    type: FETCH_SEQUENCE_STATS_SUCCESS,
    payload: {
      stats,
      sequenceId
    }
  }
}

export function fetchSequenceStatsFailure (err) {
  return {
    type: FETCH_SEQUENCE_STATS_FAILURE,
    err
  }
}

export function fetchStepStats (sequenceId) {
  return {
    type: FETCH_STEP_STATS_REQUEST,
    sequenceId
  }
}

export function fetchStepStatsSuccess ({ stepStats }) {
  return {
    type: FETCH_STEP_STATS_SUCCESS,
    payload: {
      stepStats
    }
  }
}

export function fetchStepStatsFailure (err) {
  return {
    type: FETCH_STEP_STATS_FAILURE,
    err
  }
}

export function fetchExportCsv (sequenceId, query) {
  return {
    type: FETCH_EXPORT_CSV_REQUEST,
    sequenceId,
    query
  }
}

export function fetchExportCsvSuccess ({ csvDownloadLink }) {
  return {
    type: FETCH_EXPORT_CSV_SUCCESS,
    payload: {
      csvDownloadLink
    }
  }
}

export function fetchExportCsvFailure (err) {
  return {
    type: FETCH_EXPORT_CSV_FAILURE,
    err
  }
}

export function fetchExportPdf (sequenceId, contactIds, showContactInfo, all = false, filter = null, search = null) {
  return {
    type: FETCH_EXPORT_PDF_REQUEST,
    sequenceId,
    contactIds,
    showContactInfo,
    all,
    filter,
    search
  }
}

export function fetchExportPdfSuccess ({ downloadLink, type }) {
  return {
    type: FETCH_EXPORT_PDF_SUCCESS,
    payload: {
      type,
      downloadLink
    }
  }
}

export function fetchExportPdfFailure (err) {
  return {
    type: FETCH_EXPORT_PDF_FAILURE,
    err
  }
}

export function fetchContact (sequenceId, contactId) {
  return {
    type: FETCH_CONTACT_REQUEST,
    sequenceId,
    contactId
  }
}

export function fetchContactSuccess ({ contact }) {
  return {
    type: FETCH_CONTACT_SUCCESS,
    payload: {
      contact
    }
  }
}

export function fetchContactFailure (err) {
  return {
    type: FETCH_CONTACT_FAILURE,
    err
  }
}

export function updateContact (contactId, params, { sequenceId } = {}) {
  return {
    type: UPDATE_CONTACT_REQUEST,
    contactId,
    sequenceId,
    params
  }
}

export function updateContactSuccess ({ contact }) {
  return {
    type: UPDATE_CONTACT_SUCCESS,
    payload: {
      contact
    }
  }
}

export function updateContactFailure (err) {
  return {
    type: UPDATE_CONTACT_FAILURE,
    err
  }
}

export function cloneContact (sequenceId, contactId, email) {
  return {
    type: CLONE_CONTACT_REQUEST,
    sequenceId,
    contactId,
    email
  }
}

export function cloneContactSuccess ({ contact }) {
  return {
    type: CLONE_CONTACT_SUCCESS,
    payload: {
      contact
    }
  }
}

export function cloneContactFailure (err) {
  return {
    type: CLONE_CONTACT_FAILURE,
    err
  }
}

export function repliedContact (contactId, { remove, booked, sentiment, replyType }) {
  return {
    type: REPLIED_CONTACT_REQUEST,
    contactId,
    remove,
    booked,
    sentiment,
    replyType
  }
}

export function repliedContactSuccess ({ contact }) {
  return {
    type: REPLIED_CONTACT_SUCCESS,
    payload: {
      contact
    }
  }
}

export function repliedContactFailure (err) {
  return {
    type: REPLIED_CONTACT_FAILURE,
    err
  }
}

export function unsubscribeContact (contactId) {
  return {
    type: UNSUBSCRIBE_CONTACT_REQUEST,
    contactId
  }
}

export function unsubscribeContactSuccess ({ contact }) {
  return {
    type: UNSUBSCRIBE_CONTACT_SUCCESS,
    payload: {
      contact
    }
  }
}

export function unsubscribeContactFailure (err) {
  return {
    type: UNSUBSCRIBE_CONTACT_FAILURE,
    err
  }
}

export function blockContact (contactId, addToTeam) {
  return {
    type: BLOCK_CONTACT_REQUEST,
    contactId,
    addToTeam
  }
}

export function blockContactSuccess ({ contact }) {
  return {
    type: BLOCK_CONTACT_SUCCESS,
    payload: {
      contact
    }
  }
}

export function blockContactFailure (err) {
  return {
    type: BLOCK_CONTACT_FAILURE,
    err
  }
}

export function fetchContactActions (contactId) {
  return {
    type: FETCH_CONTACT_ACTIONS_REQUEST,
    contactId
  }
}

export function fetchContactActionsSuccess ({ actions }) {
  return {
    type: FETCH_CONTACT_ACTIONS_SUCCESS,
    payload: {
      actions
    }
  }
}

export function fetchContactActionsFailure (err) {
  return {
    type: FETCH_CONTACT_ACTIONS_FAILURE,
    err
  }
}

export function fetchContactActionsReset () {
  return {
    type: FETCH_CONTACT_ACTIONS_RESET
  }
}

export function fetchContactCompany (domain) {
  return {
    type: FETCH_CONTACT_COMPANY_REQUEST,
    domain
  }
}

export function fetchContactCompanySuccess ({ company }) {
  return {
    type: FETCH_CONTACT_COMPANY_SUCCESS,
    payload: {
      company
    }
  }
}

export function fetchContactCompanyFailure (err) {
  return {
    type: FETCH_CONTACT_COMPANY_FAILURE,
    err
  }
}

export function fetchContactIntegration (contactId) {
  return {
    type: FETCH_CONTACT_INTEGRATION_REQUEST,
    contactId
  }
}

export function fetchContactIntegrationSuccess ({ integration }) {
  return {
    type: FETCH_CONTACT_INTEGRATION_SUCCESS,
    payload: {
      integration
    }
  }
}

export function fetchContactIntegrationFailure (err) {
  return {
    type: FETCH_CONTACT_INTEGRATION_FAILURE,
    err
  }
}

export function fetchContactMessages (contactId) {
  return {
    type: FETCH_CONTACT_MESSAGES_REQUEST,
    contactId
  }
}

export function fetchContactMessagesSuccess ({ messages }) {
  return {
    type: FETCH_CONTACT_MESSAGES_SUCCESS,
    payload: {
      messages
    }
  }
}

export function fetchContactMessagesFailure (err) {
  return {
    type: FETCH_CONTACT_MESSAGES_FAILURE,
    err
  }
}

export function fetchContactMessagesReset () {
  return {
    type: FETCH_CONTACT_MESSAGES_RESET
  }
}

export function reportContactInaccuracy (contactId, inaccuracy) {
  return {
    type: REPORT_CONTACT_INACCURACY_REQUEST,
    contactId,
    inaccuracy
  }
}

export function reportContactInaccuracySuccess () {
  return {
    type: REPORT_CONTACT_INACCURACY_SUCCESS
  }
}

export function reportContactInaccuracyFailure (err) {
  return {
    type: REPORT_CONTACT_INACCURACY_FAILURE,
    err
  }
}

export function createContact (sequenceId, params) {
  return {
    type: CREATE_CONTACT_REQUEST,
    sequenceId,
    params
  }
}

export function createContactSuccess ({ contacts }) {
  return {
    type: CREATE_CONTACT_SUCCESS,
    payload: {
      contacts
    }
  }
}

export function createContactFailure (err) {
  return {
    type: CREATE_CONTACT_FAILURE,
    err
  }
}

export function createContactReset () {
  return {
    type: CREATE_CONTACT_RESET
  }
}

export function fetchSequencePreview (sequenceId, contactId) {
  return {
    type: FETCH_SEQUENCE_PREVIEW_REQUEST,
    sequenceId,
    contactId
  }
}

export function fetchSequencePreviewSuccess ({ previewSteps }) {
  return {
    type: FETCH_SEQUENCE_PREVIEW_SUCCESS,
    payload: {
      previewSteps
    }
  }
}

export function fetchSequencePreviewFailure (err) {
  return {
    type: FETCH_SEQUENCE_PREVIEW_FAILURE,
    err
  }
}

export function updateSequence (sequenceId, params, message, callback) {
  return {
    type: UPDATE_SEQUENCE_REQUEST,
    sequenceId,
    params,
    message,
    callback
  }
}

export function updateSequenceSuccess ({ sequence }) {
  return {
    type: UPDATE_SEQUENCE_SUCCESS,
    payload: {
      sequence
    }
  }
}

export function updateSequenceFailure (err) {
  return {
    type: UPDATE_SEQUENCE_FAILURE,
    err
  }
}

export function updateSequenceReset () {
  return {
    type: UPDATE_SEQUENCE_RESET
  }
}

export function restoreSequence (sequenceId) {
  return {
    type: RESTORE_SEQUENCE_REQUEST,
    sequenceId
  }
}

export function restoreSequenceSuccess ({ sequence }) {
  return {
    type: RESTORE_SEQUENCE_SUCCESS,
    payload: {
      sequence
    }
  }
}

export function restoreSequenceFailure (err) {
  return {
    type: RESTORE_SEQUENCE_FAILURE,
    err
  }
}

export function addCustomField (sequenceId, fields) {
  return {
    type: UPDATE_CUSTOM_FIELDS_REQUEST,
    sequenceId,
    fields
  }
}

export function addCustomFieldSuccess ({ fields }) {
  return {
    type: UPDATE_CUSTOM_FIELDS_SUCCESS,
    payload: {
      fields
    }
  }
}

export function addCustomFieldFailure (err) {
  return {
    type: UPDATE_CUSTOM_FIELDS_FAILURE,
    err
  }
}

export function sendTestEmail (sequenceId, contactId, stepIndex, stepId, showPersonalized, params) {
  return {
    type: SEND_TEST_EMAIL_REQUEST,
    sequenceId,
    contactId,
    stepIndex,
    stepId,
    showPersonalized,
    params
  }
}

export function deleteContacts (sequenceId, contactIds, all = false, filter = null, search = null) {
  return {
    type: DELETE_CONTACTS_REQUEST,
    sequenceId,
    contactIds,
    all,
    filter,
    search
  }
}

export function moveContacts (sequenceId, contactIds, sequence, all = false, filter = null, search = null) {
  return {
    type: MOVE_CONTACTS_REQUEST,
    sequenceId,
    contactIds,
    sequence,
    all,
    filter,
    search
  }
}

export function copyContacts (sequenceId, contactIds, sequence, all = false, filter = null, search = null) {
  return {
    type: COPY_CONTACTS_REQUEST,
    sequenceId,
    contactIds,
    sequence,
    all,
    filter,
    search
  }
}

export function updateContacts (sequenceId, contactIds, data, message = 'Updated', all = false, filter = null, search = null) {
  return {
    type: UPDATE_CONTACTS_REQUEST,
    sequenceId,
    contactIds,
    data,
    message,
    all,
    filter,
    search
  }
}

export function updateContactsSuccess (contactIds, params) {
  return {
    type: UPDATE_CONTACTS_SUCCESS,
    contactIds,
    params
  }
}

export function removeSequenceContacts (contactIds) {
  return {
    type: REMOVE_SEQUENCE_CONTACTS,
    contactIds
  }
}

export function createCsv (file) {
  return {
    type: CREATE_CSV_REQUEST,
    file
  }
}

export function createCsvSuccess ({ csv }) {
  return {
    type: CREATE_CSV_SUCCESS,
    payload: {
      csv
    }
  }
}

export function createCsvFailure (err) {
  return {
    type: CREATE_CSV_FAILURE,
    err
  }
}

export function createCsvReset () {
  return {
    type: CREATE_CSV_RESET
  }
}

export function updateCsv (sequenceId, csvId, params, lookup) {
  return {
    type: UPDATE_CSV_REQUEST,
    sequenceId,
    csvId,
    params,
    lookup
  }
}

export function updateCsvSuccess ({ csv }) {
  return {
    type: UPDATE_CSV_SUCCESS,
    payload: {
      csv
    }
  }
}

export function updateCsvFailure (err) {
  return {
    type: UPDATE_CSV_FAILURE,
    err
  }
}

export function importCsv (sequenceId, csvId, lookup = false) {
  return {
    type: IMPORT_CSV_REQUEST,
    sequenceId,
    csvId,
    lookup
  }
}

export function importCsvSuccess ({ csvSequence }) {
  return {
    type: IMPORT_CSV_SUCCESS,
    payload: {
      csvSequence
    }
  }
}

export function importCsvFailure (err) {
  return {
    type: IMPORT_CSV_FAILURE,
    err
  }
}

export function updateContactStep (contactId, stepType, stepIndex, subject, markdown, immediate = false) {
  return {
    type: UPDATE_CONTACT_STEP_REQUEST,
    contactId,
    stepIndex,
    stepType,
    params: {
      subject,
      markdown,
      immediate,
      stepIndex
    }
  }
}

export function updateContactStepSuccess ({ object, contactId, stepIndex }) {
  return {
    type: UPDATE_CONTACT_STEP_SUCCESS,
    payload: {
      object,
      contactId,
      stepIndex
    }
  }
}

export function updateContactStepFailure (err) {
  return {
    type: UPDATE_CONTACT_STEP_FAILURE,
    err
  }
}

export function removeContactStep (contactId, stepType, stepIndex) {
  return {
    type: REMOVE_CONTACT_STEP_REQUEST,
    contactId,
    stepType,
    stepIndex
  }
}

export function removeContactStepSuccess ({ object, contactId, stepIndex }) {
  return {
    type: REMOVE_CONTACT_STEP_SUCCESS,
    payload: {
      object,
      contactId,
      stepIndex
    }
  }
}

export function removeContactStepFailure (err) {
  return {
    type: REMOVE_CONTACT_STEP_FAILURE,
    err
  }
}

export function fetchTemplates () {
  return {
    type: FETCH_TEMPLATES_REQUEST
  }
}

export function fetchTemplatesSuccess ({ templates }) {
  return {
    type: FETCH_TEMPLATES_SUCCESS,
    payload: {
      templates
    }
  }
}

export function fetchTemplatesFailure (err) {
  return {
    type: FETCH_TEMPLATES_FAILURE,
    err
  }
}

export function createTemplate ({
  name,
  subject,
  markdown,
  shared
}) {
  return {
    type: CREATE_TEMPLATE_REQUEST,
    params: {
      name,
      subject,
      markdown,
      shared
    }
  }
}

export function createTemplateSuccess ({ template }) {
  return {
    type: CREATE_TEMPLATE_SUCCESS,
    payload: {
      template
    }
  }
}

export function createTemplateFailure (err) {
  return {
    type: CREATE_TEMPLATE_FAILURE,
    err
  }
}

export function fetchLookupStatus (sequenceId) {
  return {
    type: FETCH_LOOKUP_STATUS_REQUEST,
    sequenceId
  }
}

export function fetchLookupStatusSuccess ({ lookups }) {
  return {
    type: FETCH_LOOKUP_STATUS_SUCCESS,
    payload: {
      lookups
    }
  }
}

export function fetchLookupStatusFailure (err) {
  return {
    type: FETCH_LOOKUP_STATUS_FAILURE,
    err
  }
}

export function fetchPreviewContacts (sequenceId) {
  return {
    type: FETCH_PREVIEW_CONTACTS_REQUEST,
    sequenceId
  }
}

export function fetchPreviewContactsSuccess ({ contacts }) {
  return {
    type: FETCH_PREVIEW_CONTACTS_SUCCESS,
    payload: {
      contacts
    }
  }
}

export function fetchPreviewContactsFailure (err) {
  return {
    type: FETCH_PREVIEW_CONTACTS_FAILURE,
    err
  }
}

export function deleteTemplate (template) {
  return {
    type: DELETE_TEMPLATE_REQUEST,
    template
  }
}

export function deleteTemplateSuccess ({ template }) {
  return {
    type: DELETE_TEMPLATE_SUCCESS,
    template
  }
}

export function deleteTemplateFailure (err) {
  return {
    type: DELETE_TEMPLATE_FAILURE,
    err
  }
}

export function forceSendStep ({ sequenceId, contactId, nextStepIndex }) {
  return {
    type: FORCE_SEND_STEP_REQUEST,
    sequenceId,
    contactId,
    nextStepIndex
  }
}

export function createFile (file) {
  return {
    type: CREATE_FILE_REQUEST,
    file
  }
}

export function createFileSuccess ({ file }) {
  return {
    type: CREATE_FILE_SUCCESS,
    payload: {
      file
    }
  }
}

export function createFileFailure (err) {
  return {
    type: CREATE_FILE_FAILURE,
    err
  }
}

export function createFileReset () {
  return {
    type: CREATE_FILE_RESET
  }
}

export function fetchSimilarContacts (contactId) {
  return {
    type: FETCH_SIMILAR_CONTACTS_REQUEST,
    contactId
  }
}

export function fetchSimilarContactsSuccess ({ similar }) {
  return {
    type: FETCH_SIMILAR_CONTACTS_SUCCESS,
    payload: {
      similar
    }
  }
}

export function fetchSimilarContactsFailure (err) {
  return {
    type: FETCH_SIMILAR_CONTACTS_FAILURE,
    err
  }
}

export function fetchCrm (sequenceId) {
  return {
    type: FETCH_CRM_REQUEST,
    sequenceId
  }
}

export function fetchCrmSuccess ({ crm }, loading = false) {
  return {
    type: FETCH_CRM_SUCCESS,
    loading,
    payload: {
      crm
    }
  }
}

export function fetchCrmFailure (err) {
  return {
    type: FETCH_CRM_FAILURE,
    err
  }
}

export function refreshCrm (sequenceId, params = {}) {
  return {
    type: REFRESH_CRM_REQUEST,
    sequenceId,
    params: {
      ...params,
      refresh: true
    }
  }
}

export function fetchContactReply (contactId) {
  return {
    type: FETCH_CONTACT_REPLY_REQUEST,
    contactId
  }
}

export function fetchContactReplySuccess ({ contactReply }) {
  return {
    type: FETCH_CONTACT_REPLY_SUCCESS,
    payload: {
      contactReply
    }
  }
}

export function fetchContactReplyFailure (err) {
  return {
    type: FETCH_CONTACT_REPLY_FAILURE,
    err
  }
}

export function transferSequence (sequenceId, userId, params) {
  return {
    type: TRANSFER_SEQUENCE_REQUEST,
    sequenceId,
    params: {
      ...params,
      userId
    }
  }
}

export function transferSequenceSuccess ({ sequence }) {
  return {
    type: TRANSFER_SEQUENCE_SUCCESS,
    payload: {
      sequence
    }
  }
}

export function transferSequenceFailure (err) {
  return {
    type: TRANSFER_SEQUENCE_FAILURE,
    err
  }
}

export function fetchSentMessages (contactId) {
  return {
    type: FETCH_SENT_MESSAGES_REQUEST,
    contactId
  }
}

export function fetchSentMessagesSuccess ({ messages }) {
  return {
    type: FETCH_SENT_MESSAGES_SUCCESS,
    payload: {
      messages
    }
  }
}

export function fetchSentMessagesFailure (err) {
  return {
    type: FETCH_SENT_MESSAGES_FAILURE,
    err
  }
}

export function fetchSentMessagesReset () {
  return {
    type: FETCH_SENT_MESSAGES_RESET
  }
}

export function newSequenceReset () {
  return {
    type: NEW_SEQUENCE_RESET
  }
}

export function fetchContactErrors (sequenceId, contactIds, all = false, filter = null, search = null) {
  return {
    type: FETCH_CONTACT_ERRORS_REQUEST,
    sequenceId,
    contactIds,
    all,
    filter,
    search
  }
}

export function fetchContactErrorsSuccess ({ contactErrors }) {
  return {
    type: FETCH_CONTACT_ERRORS_SUCCESS,
    payload: { contactErrors }
  }
}

export function fetchContactErrorsFailure (err) {
  return {
    type: FETCH_CONTACT_ERRORS_FAILURE,
    err
  }
}

export function fetchContactErrorsReset () {
  return {
    type: FETCH_CONTACT_ERRORS_RESET
  }
}

export function updateContactErrors (sequenceId, contactIds, errorTypes, all = false, filter = null) {
  return {
    type: UPDATE_CONTACT_ERRORS_REQUEST,
    sequenceId,
    contactIds,
    errorTypes,
    all,
    filter
  }
}

export function updateContactErrorsSuccess ({ contacts }) {
  return {
    type: UPDATE_CONTACT_ERRORS_SUCCESS,
    payload: { contacts }
  }
}

export function updateContactErrorsFailure (err) {
  return {
    type: UPDATE_CONTACT_ERRORS_FAILURE,
    err
  }
}

export function fetchSelectedContactCount (sequenceId, selectedContacts, all = false, filter = null, search = null) {
  return {
    type: FETCH_SELECTED_CONTACT_COUNT_REQUEST,
    sequenceId,
    selectedContacts,
    all,
    filter,
    search
  }
}

export function fetchSelectedContactCountSuccess ({ selectedCount }) {
  return {
    type: FETCH_SELECTED_CONTACT_COUNT_SUCCESS,
    selectedCount
  }
}

export function fetchSelectedContactCountFailure (err) {
  return {
    type: FETCH_SELECTED_CONTACT_COUNT_FAILURE,
    err
  }
}

export function fetchOgp (url) {
  return {
    type: FETCH_OGP_REQUEST,
    url
  }
}

export function fetchOgpSuccess ({ ogpData }) {
  return {
    type: FETCH_OGP_SUCCESS,
    ogpData
  }
}

export function fetchOgpFailure (err) {
  return {
    type: FETCH_OGP_FAILURE,
    err
  }
}

export function fetchAddresses (sequenceId) {
  return {
    type: FETCH_ADDRESSES_REQUEST,
    sequenceId
  }
}

export function fetchAddressesSuccess ({ addresses }) {
  return {
    type: FETCH_ADDRESSES_SUCCESS,
    payload: {
      addresses
    }
  }
}

export function fetchAddressesFailure (err) {
  return {
    type: FETCH_ADDRESSES_FAILURE,
    err
  }
}

export function fetchContactSteps (sequenceId, contactId) {
  return {
    type: FETCH_CONTACT_STEPS_REQUEST,
    sequenceId,
    contactId
  }
}

export function fetchContactStepsSuccess (contactSteps) {
  return {
    type: FETCH_CONTACT_STEPS_SUCCESS,
    payload: {
      contactSteps
    }
  }
}

export function fetchContactStepsFailure (err) {
  return {
    type: FETCH_CONTACT_STEPS_FAILURE,
    err
  }
}

export function fetchContactStepsReset () {
  return {
    type: FETCH_CONTACT_STEPS_RESET
  }
}

export function fetchContactPhone (contactId) {
  return {
    type: FETCH_CONTACT_PHONE_REQUEST,
    contactId
  }
}

export function fetchContactPhoneSuccess (contactId, contact) {
  return {
    type: FETCH_CONTACT_PHONE_SUCCESS,
    payload: {
      contactId,
      contact
    }
  }
}

export function fetchContactPhoneFailure (err) {
  return {
    type: FETCH_CONTACT_PHONE_FAILURE,
    err
  }
}

export function importCrmContacts (sequenceId, importOptionId, createCron) {
  return {
    type: IMPORT_CRM_CONTACTS_REQUEST,
    sequenceId,
    importOptionId,
    createCron
  }
}

export function importCrmOptionSelected () {
  return {
    type: IMPORT_CRM_OPTION_SELECTED
  }
}

export function importCrmContactsSuccess () {
  return {
    type: IMPORT_CRM_CONTACTS_SUCCESS
  }
}

export function importCrmContactsReset () {
  return {
    type: IMPORT_CRM_CONTACTS_RESET
  }
}

export function importCrmContactsFailure (err) {
  return {
    type: IMPORT_CRM_CONTACTS_FAILURE,
    err
  }
}

export function showWarningModal () {
  return {
    type: SHOW_WARNING_MODAL
  }
}

export function hideWarningModal () {
  return {
    type: HIDE_WARNING_MODAL
  }
}

export function resyncToCrm (sequenceId, contactIds, all = false, filter = null, search = null) {
  return {
    type: RESYNC_TO_CRM_REQUEST,
    sequenceId,
    contactIds,
    all,
    filter,
    search
  }
}

export function resyncToCrmSuccess () {
  return {
    type: RESYNC_TO_CRM_SUCCESS
  }
}

export function resyncToCrmFailure (err) {
  return {
    type: RESYNC_TO_CRM_FAILURE,
    err
  }
}

export function phoneNumberLookups (sequenceId, contactIds, all = false, filter = null, search = null) {
  return {
    type: PHONE_NUMBER_LOOKUPS_REQUEST,
    sequenceId,
    contactIds,
    all,
    filter,
    search
  }
}

export function phoneNumberLookupsSuccess () {
  return {
    type: PHONE_NUMBER_LOOKUPS_SUCCESS
  }
}

export function phoneNumberLookupsFailure (err) {
  return {
    type: PHONE_NUMBER_LOOKUPS_FAILURE,
    err
  }
}

export function fetchSequenceSignature (sequenceId) {
  return {
    type: FETCH_SEQUENCE_SIGNATURE_REQUEST,
    sequenceId
  }
}

export function fetchSequenceSignatureSuccess (signature) {
  return {
    type: FETCH_SEQUENCE_SIGNATURE_SUCCESS,
    signature
  }
}

export function fetchSequenceSignatureFailure (err) {
  return {
    type: FETCH_SEQUENCE_SIGNATURE_FAILURE,
    err
  }
}
