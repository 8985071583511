import Immutable from 'immutable'

export const getSelectedStep = (sequence, stepIndex, testSelected) => {
  // get the selected step
  const steps = sequence.get('steps')
  const otherSteps = sequence.get('other_steps')

  let selectedStep = steps.get(stepIndex)
  if (testSelected) {
    selectedStep = otherSteps
      .find(s => s.get('_step') === selectedStep.get('_id'))
  }

  return selectedStep
}

export const getFirstMessageStepIndex = (sequence) => {
  const steps = sequence.get('steps')
  return steps.findIndex(s => s.get('type') === 'message')
}

export const getSelectedStepSubject = (sequence, stepIndex, testSelected) => {
  const firstMessageStepIndex = getFirstMessageStepIndex(sequence)

  if (stepIndex === firstMessageStepIndex && !testSelected) {
    return {
      subject: sequence.get('subject'),
      newThread: true,
      threadLabel: null
    }
  }

  const selectedStep = getSelectedStep(sequence, stepIndex, testSelected)

  if (typeof selectedStep.get('subject') === 'string') {
    return {
      subject: selectedStep.get('subject'),
      newThread: true,
      threadLabel: stepIndex !== 0 ? 'new' : null
    }
  }

  let subjects = Immutable.List([
    sequence.get('subject')
  ])

  const steps = sequence.get('steps')
  for (let i = 0; i < stepIndex; i++) {
    const step = steps.get(i)
    const subject = step.get('subject')

    if (subject) {
      subjects = subjects.push(subject)
    }
  }

  return {
    subject: subjects.last(),
    newThread: stepIndex === 0,
    threadLabel: 'reply'
  }
}
