import {
  FETCH_SEARCH_REQUEST,
  FETCH_SEARCH_SUCCESS,
  FETCH_SEARCH_FAILURE,
  FETCH_SEARCH_RESET,
  FETCH_RECENT_SEQUENCES,
  ADD_RECENT_SEQUENCE,
  FETCH_CHECKLIST_REQUEST,
  FETCH_CHECKLIST_SUCCESS,
  FETCH_CHECKLIST_FAILURE
} from './constants'

export function fetchSearch ({ query }) {
  return {
    type: FETCH_SEARCH_REQUEST,
    query
  }
}

export function fetchSearchSuccess ({ contacts, campaigns, totalContacts, totalCampaigns }) {
  return {
    type: FETCH_SEARCH_SUCCESS,
    payload: {
      contacts,
      campaigns,
      totalContacts,
      totalCampaigns
    }
  }
}

export function fetchSearchFailure (err) {
  return {
    type: FETCH_SEARCH_FAILURE,
    err
  }
}

export function fetchSearchReset () {
  return {
    type: FETCH_SEARCH_RESET
  }
}

export function fetchRecentSequences () {
  return {
    type: FETCH_RECENT_SEQUENCES
  }
}

export function addRecentSequence (sequence) {
  return {
    type: ADD_RECENT_SEQUENCE,
    sequence
  }
}

export function fetchChecklist () {
  return {
    type: FETCH_CHECKLIST_REQUEST
  }
}

export function fetchChecklistSuccess ({ data }) {
  return {
    type: FETCH_CHECKLIST_SUCCESS,
    payload: {
      data
    }
  }
}

export function fetchChecklistFailure (err) {
  return {
    type: FETCH_CHECKLIST_FAILURE,
    err
  }
}
