import React from 'react'
import TaskRow from '../TaskRow'

export default function taskRowRenderer ({
  rowKey,
  task,
  activeTask,
  totalTasks,
  contactCompany,
  selectedContact,
  onCellLoadingComplete,
  onClick,
  registerChild,
  measure,
  style,
  index,
  parent,
  cache,
  deleteContact,
  editContact,
  onChecked,
  checkedContacts,
  listRef
}) {
  const id = task.get('id')
  const selected = activeTask === task

  return (
    <TaskRow
      key={`taskrow_${rowKey}`}
      rowKey={rowKey}
      id={id}
      task={task}
      parent={parent}
      cache={cache}
      index={index}
      style={style}
      registerChild={registerChild}
      measure={measure}
      last={index + 1 === totalTasks}
      selected={selected}
      contactCompany={contactCompany}
      selectedContact={selectedContact}
      onClick={() => { onClick(task) }}
      handleCellLoadingComplete={onCellLoadingComplete}
      deleteContact={deleteContact}
      editContact={editContact}
      onChecked={onChecked}
      checkedContacts={checkedContacts}
      listRef={listRef}
    />
  )
}
