import {
  MSFT_LIST_NODES,
  NODES,
  PLATFORMS
} from './constants'

import {
  extractInlineCSS,
  extractNodeMetadata,
  isValidTextNode,
  isValidInlineNode,
  isValidListNode
} from './utils/default-helpers'

import * as wordHelpers from './utils/msft-word-helpers'

class HTMLParser {
  constructor (inputHTML, currentPlatform = PLATFORMS.NONE) {
    this.inputHTML = inputHTML
    this.currentPlatform = currentPlatform

    this.dom = this._createVirtualDOM(inputHTML)
    this.isInParagraphNode = false
    this.isInListNode = false
  }

  _createVirtualDOM (html) {
    const dom = new window.DOMParser().parseFromString(html, 'text/html')

    if (!dom.childNodes) {
      return null
    }

    return dom
  }

  parse () {
    if (!this.dom || !this.dom.body) {
      return null
    }

    return this._parseBody(this.dom.body)
  }

  _parseBody (node) {
    if (!node) {
      console.error('Error in `parseBody`: DOM contains no Body element')
      return null
    }

    let currentChildrenHTML = ''
    const { childNodes } = extractNodeMetadata(node)

    // msft worf will sometimes insert TextNodes that contain newline characteres.
    // they are needed for the parser therefore remove that to avoid confusion while parsing
    if (this.currentPlatform === PLATFORMS.WORD) {
      for (let i = childNodes.length - 1; i >= 0; i--) {
        const child = childNodes[i]
        const { data } = extractNodeMetadata(child)
        if (isValidTextNode(child) && /\s{1,}/g.test(data)) {
          node.removeChild(child)
        }
      }
    }

    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]

      if (wordHelpers.isWordListNode(child)) {
        const { html: resultHtml, listItemCount } = this._parseMsftList(child)
        i += listItemCount
        currentChildrenHTML += resultHtml
        continue
      } else if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.DIVIDER: {
          currentChildrenHTML += this._parseDivider(child)
          break
        }

        case NODES.PARAGRAPH: {
          currentChildrenHTML += this._parseParagraph(child)
          break
        }

        case NODES.UNORDERED_LIST:
        case NODES.ORDERED_LIST: {
          currentChildrenHTML += this._parseList(child)
          break
        }

        case NODES.LIST_ITEM: {
          currentChildrenHTML += this._parseListItem(child)
          break
        }

        case NODES.HEADER_1:
        case NODES.HEADER_2:
        case NODES.HEADER_3:
        case NODES.HEADER_4:
        case NODES.HEADER_5:
        case NODES.HEADER_6: {
          currentChildrenHTML += this._parseHeader(child)
          break
        }

        case NODES.BREAK: {
          currentChildrenHTML += this._parseBreak(child)
          break
        }

        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.TIME: {
          currentChildrenHTML += this._parseTime(child)
          break
        }

        case NODES.ANCHOR: {
          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.BOLD: {
          if (this.currentPlatform === PLATFORMS.DRIVE) {
            currentChildrenHTML += this._parseBody(child)
          } else {
            currentChildrenHTML += this._parseDecoration(child)
          }
          break
        }

        case NODES.UNDERLINE:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        default: {
          if (this.isInParagraphNode) {
            currentChildrenHTML += `</p>${this._skipOverChild(child)}`
          } else {
            currentChildrenHTML += this._skipOverChild(child)
          }
          break
        }
      }
    }

    if (this.isInParagraphNode) {
      return `${currentChildrenHTML}</p>`
    }

    return currentChildrenHTML
  }

  _parseDivider (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parseDivider\``)
      return ''
    }

    let currentChildrenHTML = ''
    if (this.isInParagraphNode) {
      currentChildrenHTML += '</p>'
      this.isInParagraphNode = false
    }
    /* eslint-disable */
    // disabling eslint so it doesn't complain about the else-if from being on the same as the closing brace

    // br tags at the end of a div don't render a new line in the browser
    // therefore removed to prevent any possible inconsistent newlines in slate
    const { childNodes, lastChild } = extractNodeMetadata(node)
    if (childNodes.length > 1 && lastChild) {
      const { tag } = extractNodeMetadata(lastChild)
      if (tag === NODES.BREAK) {
        node.removeChild(lastChild)
      }
    }

    // Platforms like gmail will store all groups of text in a div.
    // thus any empty line with more content beneath looks like:
    //    <div> <br> <div>
    //
    // a paragrah tag replaces the <br> tag to keep newlines in slate conistent
    else if (childNodes.length === 1) {
      const onlyChild = childNodes[0]
      const { tag } = extractNodeMetadata(onlyChild)
      if (tag === 'br') {
        return this._parseBreak(onlyChild)
      }
    }
    /* eslint-enable */

    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]
      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.PARAGRAPH: {
          if (this.isInParagraphNode) {
            currentChildrenHTML += '</p>'
            this.isInParagraphNode = false
          }

          currentChildrenHTML += this._parseParagraph(child)
          break
        }

        case NODES.DIVIDER: {
          currentChildrenHTML += this._parseDivider(child)
          break
        }

        case NODES.UNORDERED_LIST:
        case NODES.ORDERED_LIST: {
          if (this.isInParagraphNode) {
            currentChildrenHTML += '</p>'
            this.isInParagraphNode = false
          }

          currentChildrenHTML += this._parseList(child)
          break
        }

        case NODES.LIST_ITEM: {
          currentChildrenHTML += this._parseListItem(child)
          break
        }

        case NODES.HEADER_1:
        case NODES.HEADER_2:
        case NODES.HEADER_3:
        case NODES.HEADER_4:
        case NODES.HEADER_5:
        case NODES.HEADER_6: {
          if (this.isInParagraphNode) {
            currentChildrenHTML += '</p>'
            this.isInParagraphNode = false
          }

          currentChildrenHTML += this._parseHeader(child)
          break
        }

        case NODES.BREAK: {
          currentChildrenHTML += this._parseBreak(child)
          break
        }

        case NODES.SPAN: {
          // paragraph tags are not allowed inside any list nodes or a list node's child.
          // but for all other cases, span tags must be wrapped in a paragraph tag
          if (!this.isInListNode && !this.isInParagraphNode) {
            currentChildrenHTML += '<p>'
            this.isInParagraphNode = true
          }

          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.TIME: {
          // paragraph tags are not allowed inside any list nodes or a list node's child.
          // but for all other cases, time tags must be wrapped in a paragraph tag
          if (!this.isInListNode && !this.isInParagraphNode) {
            currentChildrenHTML += '<p>'
            this.isInParagraphNode = true
          }

          currentChildrenHTML += this._parseTime(child)
          break
        }

        case NODES.ANCHOR: {
          // paragraph tags are not allowed inside any list nodes or a list node's child.
          // but for all other cases, anchor tags must be wrapped in a paragraph tag
          if (!this.isInListNode && !this.isInParagraphNode) {
            currentChildrenHTML += '<p>'
            this.isInParagraphNode = true
          }

          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.UNDERLINE:
        case NODES.BOLD:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          // paragraph tags are not allowed inside any list nodes or a list node's child.
          // but for all other cases, decoration tags must be wrapped in a paragraph tag
          if (!this.isInListNode && !this.isInParagraphNode) {
            currentChildrenHTML += '<p>'
            this.isInParagraphNode = true
          }

          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    if (this.isInParagraphNode) {
      currentChildrenHTML += '</p>'
      this.isInParagraphNode = false
    }

    return currentChildrenHTML
  }

  _parseParagraph (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parseParagraph\``)
      return ''
    }

    let currentChildrenHTML = ''
    if (this.isInParagraphNode) {
      currentChildrenHTML += '</p>'
    }

    this.isInParagraphNode = true
    const { childNodes } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]

      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.BREAK: {
          currentChildrenHTML += this._parseBreak(child)
          break
        }

        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.TIME: {
          currentChildrenHTML += this._parseTime(child)
          break
        }

        case NODES.ANCHOR: {
          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.UNDERLINE:
        case NODES.BOLD:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    // set global flag to false after recursing through children
    this.isInParagraphNode = false

    const { openTags, closeTags, marginBottom } = extractNodeMetadata(node)
    if (!!marginBottom && !!openTags && !!closeTags) {
      return `<p>${openTags}${currentChildrenHTML}${closeTags}</p><p></p>`
    } else if (!!openTags && !!closeTags) {
      return `<p>${openTags}${currentChildrenHTML}${closeTags}</p>`
    }
    return `<p>${currentChildrenHTML}</p>`
  }

  _parseList (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parseList\``)
      return
    }

    let currentChildrenHTML = ''
    // keep track if we are in a list node
    this.isInListNode = true

    if (this.isInParagraphNode) {
      currentChildrenHTML += '</p>'
      this.isInParagraphNode = false
    }

    const { tag, childNodes } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]

      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.LIST_ITEM: {
          currentChildrenHTML += this._parseListItem(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child, true)
          break
        }
      }
    }

    // set list node flag to false after parsing all children
    this.isInListNode = false

    return `<${tag}>${currentChildrenHTML}</${tag}>`
  }

  _parseListItem (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parseListItem\``)
      return ''
    }

    const value = node.value
      ? node.value
      : null

    let currentChildrenHTML = ''
    const { childNodes } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]

      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.HEADER_1:
        case NODES.HEADER_2:
        case NODES.HEADER_3:
        case NODES.HEADER_4:
        case NODES.HEADER_5:
        case NODES.HEADER_6: {
          currentChildrenHTML += this._parseHeader(child)
          break
        }

        case NODES.BREAK: {
          currentChildrenHTML += this._parseBreak(child)
          break
        }

        case NODES.UNORDERED_LIST:
        case NODES.PARAGRAPH:
        case NODES.ORDERED_LIST: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }

        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.TIME: {
          currentChildrenHTML += this._parseTime(child)
          break
        }

        case NODES.ANCHOR: {
          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.UNDERLINE:
        case NODES.BOLD:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    if (value) {
      return `<li value="${value}">${currentChildrenHTML}</li>`
    }

    return `<li>${currentChildrenHTML}</li>`
  }

  _parseHeader (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parseHeader\``)
      return ''
    }

    let currentChildrenHTML = ''
    if (this.isInParagraphNode) {
      currentChildrenHTML += '</p>'
      this.isInParagraphNode = false
    }

    const { childNodes } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]
      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.BREAK: {
          currentChildrenHTML += this._parseBreak(child)
          break
        }

        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.TIME: {
          currentChildrenHTML += this._parseTime(child)
          break
        }

        case NODES.ANCHOR: {
          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.UNDERLINE:
        case NODES.EMPHASIS:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    return `<p></p><p><b>${currentChildrenHTML}</b></p><p></p>`
  }

  _parseSpan (node) {
    if (!node) {
      console.warn(`\`node \` is ${node} in \`_parseSpan\``)
      return ''
    }

    // `<span></span><br>` => `<span></span>`
    // TODO: Figure out why we were checking for this
    // if (node.nextSibling !== null) {
    //   const nextTagName = node.nextSibling.tagName.toLowerCase();
    //   if (!!nextTagName && nextTagName === 'br' ) {
    //     // node.parentNode.removeChild(node.nextSibling);
    //   }
    // }
    let parentTag = ''
    let currentChildrenHTML = ''

    // paragraph tags are not allowed inside any list nodes or a list node's child.
    // but for all other cases, span tags must be wrapped in a paragrah tags
    if (!this.isInListNode && !this.isInParagraphNode) {
      parentTag = '<p>'
      this.isInParagraphNode = true
    }

    const { childNodes } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]
      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.BREAK: {
          currentChildrenHTML += this._parseBreak(child)
          break
        }

        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.TIME: {
          currentChildrenHTML += this._parseTime(child)
          break
        }

        case NODES.ANCHOR: {
          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.UNDERLINE:
        case NODES.BOLD:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    const { openTags, closeTags } = extractInlineCSS(node)
    if (!!openTags && !!closeTags) {
      return `${parentTag}${openTags}${currentChildrenHTML}${closeTags}`
    }

    return `${parentTag}<span>${currentChildrenHTML}</span>`
  }

  _parseTime (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parseTime\``)
      return ''
    }

    let parentTag = ''
    // paragraph tags are not allowed inside any list nodes or a list node's child.
    // but for all other cases, time tags must be wrapped in a paragraph tag
    if (!this.isInListNode && !this.isInParagraphNode) {
      parentTag = '<p>'
      this.isInParagraphNode = true
    }

    const datetime = (node.dateTime)
      ? node.dateTime
      : null

    let currentChildrenHTML = ''
    const { childNodes } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]
      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    if (datetime) {
      return `${parentTag}<time datetime=${datetime}>${currentChildrenHTML}</time>`
    }

    return `${parentTag}<time>${currentChildrenHTML}</time>`
  }

  _parseAnchor (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parseAnchor\``)
      return '<a href="#"></a>'
    }

    let parentTag = ''
    // paragraph tags are not allowed inside any list nodes or a list node's child.
    // but for all other cases, anchor tags must be wrapped in a paragraph tag
    if (!this.isInListNode && !this.isInParagraphNode) {
      parentTag = '<p>'
      this.isInParagraphNode = true
    }

    const href = (node.href)
      ? node.href
      : '#'

    let currentChildrenHTML = ''
    const { childNodes } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]
      const { tag } = extractNodeMetadata(child)

      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      switch (tag) {
        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.BREAK: {
          // TODO: This is a weird edge case ... not sure how to handle this yet
          break
        }

        case NODES.HEADER_1:
        case NODES.HEADER_2:
        case NODES.HEADER_3:
        case NODES.HEADER_4:
        case NODES.HEADER_5:
        case NODES.HEADER_6: {
          currentChildrenHTML += this._parseHeader(child)
          break
        }

        // Because anchor tags can wrap divs, and divs can have paragraph tags as children,
        // this is commented out because it could lead to nested paragrah tags.
        //
        // case NODES.DIVIDER: {
        //   currentChildrenHTML = _parseDivider(child)
        // }

        case NODES.UNDERLINE:
        case NODES.BOLD:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        case NODES.PARAGRAPH: {
          currentChildrenHTML += this._parseParagraph(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    return `${parentTag}<a href="${href}">${currentChildrenHTML}</a>`
  }

  _parseDecoration (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parseDecoration\``)
      return ''
    }

    let parentTag = ''
    // paragraph tags are not allowed inside any list nodes or a list node's child.
    // but for all other cases, decoration tags must be wrapped in a paragraph tag
    if (!this.isInListNode && !this.isInParagraphNode) {
      parentTag = '<p>'
      this.isInParagraphNode = true
    }

    let currentChildrenHTML = ''
    const { childNodes, tag } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]

      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      switch (tag) {
        case NODES.ANCHOR: {
          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.UNDERLINE:
        case NODES.BOLD:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    return `${parentTag}<${tag}>${currentChildrenHTML}</${tag}>`
  }

  _parseBreak (node) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_parsebBreak\``)
      return ''
    }

    const { nextSibling } = extractNodeMetadata(node)
    if (this.isInParagraphNode && isValidInlineNode(nextSibling)) {
      this.isInParagraphNode = true
      return '</p><p>'
    } else if (!this.isInParagraphNode && isValidInlineNode(nextSibling)) {
      this.isInParagraphNode = true
      return '<p>'
    } else if (!this.isInParagraphNode && isValidListNode(nextSibling)) {
      // if not in a paragraph node, the current node is a br,
      // and the next node is a list, don't insert a break node
      // because showdown removes new line. The goal is to catch this
      // condition while pasting to ensure a consisten format
      this.isInParagraphNode = false
      return ''
    } else if (this.isInParagraphNode) {
      this.isInParagraphNode = false
      return '</p>'
    }

    this.isInParagraphNode = false
    return '<p>&#65279;</p>'
  }

  _parseText (node) {
    if (!node || !isValidTextNode(node)) {
      console.warn(`\`node\` is ${node} in \`_parseText\``)
      return ''
    } else if (!this.isInListNode && !this.isInParagraphNode) {
      this.isInParagraphNode = true
      return `<p>${node.data}`
    } else if (this.isInListNode || this.isInParagraphNode) {
      return node.data
    }

    return ''
  }

  /**
   * Purpose: Used to skip over parsing a node that you may situationally want to skip,
   *          but still allow for the children of that node to be parsed and appended
   *          to the HTML.
   *
   * @param {HTMLElement} node
   */
  _skipOverChild (node, isInList = false) {
    if (!node) {
      console.warn(`\`node\` is ${node} in \`_skipOverChild\``)
      return ''
    }

    let currentChildrenHTML = ''
    const { childNodes } = extractNodeMetadata(node)
    for (let i = 0; i < childNodes.length; i++) {
      const child = childNodes[i]

      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.DIVIDER: {
          currentChildrenHTML += this._parseDivider(child)
          break
        }

        case NODES.PARAGRAPH: {
          currentChildrenHTML += this._parseParagraph(child)
          break
        }

        case NODES.UNORDERED_LIST:
        case NODES.ORDERED_LIST: {
          if (isInList) {
            currentChildrenHTML += this._skipOverChild(child, true)
          } else {
            currentChildrenHTML += this._parseList(child)
          }

          break
        }

        case NODES.LIST_ITEM: {
          currentChildrenHTML += this._parseListItem(child)
          break
        }

        case NODES.HEADER_1:
        case NODES.HEADER_2:
        case NODES.HEADER_3:
        case NODES.HEADER_4:
        case NODES.HEADER_5:
        case NODES.HEADER_6: {
          currentChildrenHTML += this._parseHeader(child)
          break
        }

        case NODES.BREAK: {
          currentChildrenHTML += this._parseBreak(child)
          break
        }

        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.TIME: {
          currentChildrenHTML += this._parseTime(child)
          break
        }

        case NODES.ANCHOR: {
          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.BOLD: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        case NODES.UNDERLINE:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }

        default: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }
      }
    }

    return currentChildrenHTML
  }

  /**
   * Purpose: Parse an entire Microsoft word list
   *
   * Paremeters:
   * @param {HTMLElement} node - Root Msft List Item Node
   * @param {Number} listItemCount - Default param keeping track of how many list items this function parses
   *
   * Return:
   * @return {resultHtml} - HTML returned after parsing the msft list nodes.
   * @return {listItemCount} - Total number of sibiling nodes that were parsed.
   *  Deeper `listItemCount` Explanantion:
   *  Msft word lists are a flat list of paragraph nodes, like so:
   *
   *  <p class="MsoListFirst">  root node  </p>
   *  <p class="MsoListMiddle"> midd node  </p>
   *  <p class="MsoListMiddle"> more stuff </p>
   *  <p class="MsoListLast">   last node  </p>
   *
   *  All of the nodes in the list are syballings. This function will
   *  call itself rescusivley and create the HTML going down as well as
   *  keeo a count of how many lists nodes were processsed along the way.
   *  `listItemCount` is the running count of list nodes that were processed.
   *
   *  TL;DR: `listItemCount` is the number of sibling nodes that need to be skipped afte returning
   */
  _parseMsftList (node, listItemCount = 1) {
    let currentChildrenHTML = ''
    const { childNodes } = extractNodeMetadata(node)
    for (let i = 3; i < childNodes.length; i++) {
      const child = childNodes[i]
      if (isValidTextNode(child)) {
        currentChildrenHTML += this._parseText(child)
        continue
      }

      const { tag } = extractNodeMetadata(child)
      switch (tag) {
        case NODES.HEADER_1:
        case NODES.HEADER_2:
        case NODES.HEADER_3:
        case NODES.HEADER_4:
        case NODES.HEADER_5:
        case NODES.HEADER_6: {
          currentChildrenHTML += this._parseHeader(child)
          break
        }

        case NODES.BREAK: {
          currentChildrenHTML += this._parseBreak(child)
          break
        }

        case NODES.UNORDERED_LIST:
        case NODES.ORDERED_LIST: {
          currentChildrenHTML += this._skipOverChild(child)
          break
        }

        case NODES.SPAN: {
          currentChildrenHTML += this._parseSpan(child)
          break
        }

        case NODES.TIME: {
          currentChildrenHTML += this._parseTime(child)
          break
        }

        case NODES.ANCHOR: {
          currentChildrenHTML += this._parseAnchor(child)
          break
        }

        case NODES.UNDERLINE:
        case NODES.BOLD:
        case NODES.EMPHASIS:
        case NODES.STRONG:
        case NODES.ITALICS: {
          currentChildrenHTML += this._parseDecoration(child)
          break
        }
      }
    }

    let html = ''
    const listType = wordHelpers.extractMsftListType(node)
    const { className: currentNodeClassName, nextSibling } = extractNodeMetadata(node)
    if (currentNodeClassName === MSFT_LIST_NODES.SINGLETON) {
      html = `<${listType}><li>${currentChildrenHTML}</li></${listType}>`
    } else if (currentNodeClassName === MSFT_LIST_NODES.FIRST) {
      const { html: resultHTML, listItemCount: resultListItemCount } = this._parseMsftList(nextSibling, listItemCount + 1)
      html = `<${listType}><li>${currentChildrenHTML}</li>${resultHTML}`
      listItemCount = (resultListItemCount > listItemCount) ? resultListItemCount : listItemCount
    } else if (currentNodeClassName === MSFT_LIST_NODES.MIDDLE) {
      const { html: resultHTML, listItemCount: resultListItemCount } = this._parseMsftList(nextSibling, listItemCount + 1)
      html = `<li>${currentChildrenHTML}</li>${resultHTML}`
      listItemCount = (resultListItemCount > listItemCount) ? resultListItemCount : listItemCount
    } else if (currentNodeClassName === MSFT_LIST_NODES.LAST) {
      html = `<li>${currentChildrenHTML}</li></${listType}>`
    }

    return { html, listItemCount }
  }
}

export default HTMLParser
