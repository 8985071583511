import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled from 'styled-components'

import SwitchFieldForm from 'components/SwitchFieldForm'

import Subtitle from 'elements/Subtitle'

const Wrapper = styled.div`
  padding: ${props => props.theme.padding};
`

const FieldContainer = styled.div`
  margin-top: 2rem;
  padding: 2rem;
  border-width: 1px;
  border-color: ${props => props.theme.colors.gray30};
  border-style: solid;
`

class Notifications extends Component {
  constructor (props) {
    super(props)
    this.state = {}
  }

  render () {
    const {
      session,
      onSettingUpdate
    } = this.props

    return (
      <Wrapper>
        <Subtitle>Notifications</Subtitle>
        <FieldContainer>
          <SwitchFieldForm
            label='Sequence Logs'
            description='Receive an email anytime a teammate creates, archives or restores a sequence owned by you'
            inputId='archive_auto'
            value={session.get('notify_campaign')}
            onSave={(value) => {
              onSettingUpdate({ notify_campaign: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Weekly Report'
            description='Receive an email report every Monday of your stats for the previous week including the number of contacts added, your open rate, and your reply rate'
            value={session.get('notify_weekly_report')}
            onSave={(value) => {
              onSettingUpdate({ notify_weekly_report: value })
            }}
          />
        </FieldContainer>
        <FieldContainer>
          <SwitchFieldForm
            label='Daily Activity'
            description="Receive an email every morning of your activity within Interseller such as the emails you need to personalize, the number of emails to be sent, and the number of replies you've received"
            value={session.get('notify_tasks')}
            onSave={(value) => {
              onSettingUpdate({ notify_tasks: value })
            }}
          />
        </FieldContainer>
      </Wrapper>
    )
  }
}

Notifications.propTypes = {
  session: PropTypes.object,
  onSettingUpdate: PropTypes.func
}

export default Notifications
