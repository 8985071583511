import { fromJS } from 'immutable'
import {
  FETCH_TEAM_MEMBERS_REQUEST,
  FETCH_TEAM_MEMBERS_SUCCESS,
  FETCH_TEAM_MEMBERS_FAILURE,
  FETCH_TEAM_INVITES_REQUEST,
  FETCH_TEAM_INVITES_SUCCESS,
  FETCH_TEAM_INVITES_FAILURE,
  SEND_TEAM_INVITE_REQUEST,
  SEND_TEAM_INVITE_SUCCESS,
  SEND_TEAM_INVITE_FAILURE,
  DELETE_TEAM_INVITES_SUCCESS,
  DELETE_TEAM_MEMBER_SUCCESS,
  UPDATE_TEAM_MEMBER_SUCCESS,
  FETCH_TEAM_COUNTS_REQUEST,
  FETCH_TEAM_COUNTS_SUCCESS,
  FETCH_TEAM_COUNTS_FAILURE,
  FETCH_OWNER_CRM_REQUEST,
  FETCH_OWNER_CRM_SUCCESS,
  FETCH_OWNER_CRM_FAILURE,
  REFRESH_OWNER_CRM_REQUEST
} from './constants'

const initialArray = {
  loading: false,
  data: [],
  error: null
}

const initialObject = {
  loading: false,
  data: null,
  error: null
}

const initialState = fromJS({
  members: initialArray,
  invites: initialArray,
  counts: initialObject,
  ownerCrm: {
    ...initialObject,
    refreshing: false
  }
})

function teamReducer (state = initialState, action) {
  switch (action.type) {
    // Member List
    case FETCH_TEAM_MEMBERS_REQUEST:
      return state.set('members', state.get('members').merge({ loading: true }))
    case FETCH_TEAM_MEMBERS_SUCCESS:
      return state
        .set('members', fromJS({
          data: action.payload.members,
          loading: false,
          error: null
        }))
        .set('groupMembers', fromJS({
          data: action.payload.group,
          loading: false,
          error: null
        }))
    case FETCH_TEAM_MEMBERS_FAILURE:
      return state.set('members', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    // Member List
    case FETCH_TEAM_COUNTS_REQUEST:
      return state.set('counts', state.get('counts').merge({ loading: true }))
    case FETCH_TEAM_COUNTS_SUCCESS:
      return state.set('counts', fromJS({
        data: action.payload.counts,
        loading: false,
        error: null
      }))
    case FETCH_TEAM_COUNTS_FAILURE:
      return state.set('counts', fromJS({
        data: null,
        loading: false,
        error: action.err
      }))

    // Invite List
    case FETCH_TEAM_INVITES_REQUEST:
      return state.set('invites', state.get('invites').merge({ loading: true }))
    case FETCH_TEAM_INVITES_SUCCESS:
      return state.set('invites', fromJS({
        data: action.payload.invites,
        loading: false,
        error: null
      }))
    case FETCH_TEAM_INVITES_FAILURE:
      return state.set('invites', fromJS({
        data: [],
        loading: false,
        error: action.err
      }))

    // Delete Member
    case DELETE_TEAM_MEMBER_SUCCESS: {
      const memberId = action.member.get('id')
      const data = state.getIn(['members', 'data']).filter(m => (
        !memberId.includes(m.get('id'))
      ))
      return state.set('members', state.get('members').merge({ data }))
    }

    // Delete Invite
    case DELETE_TEAM_INVITES_SUCCESS: {
      const inviteId = action.invite.get('id')
      const data = state.getIn(['invites', 'data']).filter(i => (
        !inviteId.includes(i.get('id'))
      ))
      return state.set('invites', state.get('invites').merge({ data }))
    }

    // Send Invite
    case SEND_TEAM_INVITE_REQUEST:
      return state.set('invites', state.get('invites').merge({ loading: true }))
    case SEND_TEAM_INVITE_SUCCESS: {
      const invite = action.invite
      const data = state.getIn(['invites', 'data']).push(invite)
      return state.set('invites', state.get('invites').merge({
        data,
        loading: false,
        error: null
      }))
    }
    case SEND_TEAM_INVITE_FAILURE:
      return state.set('invites', state.get('invites').merge({
        data: [],
        loading: false,
        error: action.err
      }))

    case UPDATE_TEAM_MEMBER_SUCCESS: {
      const member = action.member
      const memberId = member.get('id')
      const data = state.getIn(['members', 'data']).map((m) => {
        if (memberId.includes(m.get('id'))) {
          return member
        }
        return m
      })
      return state.set('members', state.get('members').merge({ data }))
    }

    // Owner CRM for create sequence
    case FETCH_OWNER_CRM_REQUEST:
      return state.set('ownerCrm', state.get('ownerCrm').merge({
        data: [],
        loading: true,
        owner: null
      }))
    case FETCH_OWNER_CRM_SUCCESS:
      return state.set('ownerCrm', fromJS({
        data: action.crm,
        loading: false,
        refreshing: false,
        error: null
      }))
    case FETCH_OWNER_CRM_FAILURE:
      return state.set('ownerCrm', fromJS({
        data: [],
        loading: false,
        refreshing: false,
        error: action.err
      }))

    case REFRESH_OWNER_CRM_REQUEST:
      return state.set('ownerCrm', state.get('ownerCrm').merge({
        refreshing: true
      }))

    default:
      return state
  }
}

export default teamReducer
