import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { css, withTheme } from 'styled-components'
import { format } from 'date-fns'

import Popper from 'components/Popper'
import DropdownArrow from 'elements/DropdownArrow'
import { Menu, MenuItem } from 'elements/Menu'

const Wrapper = styled.div`
  background: ${props => props.theme.containerBackground};
  height: auto;
  width: 100%;
  border: ${props => `solid 1px ${props.theme.borderColor}`};
  margin-top: 1rem;
  max-height: 70vh;
  overflow: scroll;
`

const TemplateRow = styled.div`
  cursor: pointer;
  white-space: nowrap;
  text-overflow: ellipsis;
  display: flex;
  flex-direction: row;
  align-items: start;
  justify-content: start;
  text-align: left;
  background-color: ${props => props.theme.colors.white};
  padding: 1rem 1.25rem;
  min-height: fit-content;

  border-bottom: ${props => `solid 1px ${props.theme.borderColor}`};

  ${props => props.selected && css`
    background-color: ${props.theme.colors.blueGray};
    cursor: default;
  `};
`

const TemplateContent = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  min-width: 0;
`

const TemplateTitle = styled.div`
  font: ${props => props.theme.fonts.formtitle};
  color: ${props => props.theme.titleColor};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  margin-top: auto;
  margin-bottom: auto;
`

const TemplateDetails = styled.div`
  font: ${props => props.theme.fonts.small};
  color: ${props => props.theme.titleColor};
  max-width: 100%;
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  flex: 1 1 auto;
  margin-top: auto;
  margin-bottom: auto;
`

const CaretContainer = styled.div`
  flex: 1 0 auto;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  height: 90%;
  margin-top: auto;
  margin-bottom: auto;
  margin-top: 0.5rem;
  padding: 0;
`

class TemplateList extends Component {
  getRows (templates) {
    const {
      selectedTemplate,
      onSelectTemplate,
      onDeleteTemplate,
      onRenameTemplate,
      session,
      theme
    } = this.props
    return templates.map((template) => {
      const user = template.getIn(['_user', 'full_name']) || template.getIn(['_user', 'email'])
      const date = format(new Date(template.get('updated_at')), 'MMMM Do, YYYY')
      const isOwner = template && !template.get('public') && template.getIn(['_user', '_id']) === session.get('id')

      return (
        <TemplateRow
          key={`template-row-${template.get('id')}`}
          selected={selectedTemplate && template.get('id') === selectedTemplate.get('id')}
          onClick={() => {
            onSelectTemplate(template)
          }}
        >
          <TemplateContent>
            <TemplateTitle>{template.get('name')}</TemplateTitle>
            <TemplateDetails>
              {user && <span>Created by {user} &mdash;&nbsp;</span>}
              Last updated on {date}
            </TemplateDetails>
          </TemplateContent>
          {isOwner &&
            <Popper
              position='bottom-start'
              trigger={<CaretContainer><DropdownArrow /></CaretContainer>}
            >
              {close => (
                <Menu style={{ width: '10rem' }} onClick={close}>
                  <MenuItem onClick={() => { onRenameTemplate(template) }}>
                    Rename
                  </MenuItem>
                  <MenuItem color={theme.colors.red} onClick={() => { onDeleteTemplate(template) }}>
                    Delete
                  </MenuItem>
                </Menu>
              )}
            </Popper>}
        </TemplateRow>
      )
    })
  }

  render () {
    const {
      templates
    } = this.props

    return (
      <Wrapper>
        {this.getRows(templates.get('data'))}
      </Wrapper>
    )
  }
}

TemplateList.propTypes = {
  theme: PropTypes.object,
  templates: PropTypes.object,
  selectedTemplate: PropTypes.object,
  onSelectTemplate: PropTypes.func,
  onDeleteTemplate: PropTypes.func,
  onRenameTemplate: PropTypes.func,
  session: PropTypes.object
}

export default withTheme(TemplateList)
