/* eslint-disable no-return-assign */
import React from 'react'
import PropTypes from 'prop-types'
import Immutable from 'immutable'
import R from 'utils/ramda'

import Modal from 'components/Modal'
import Button from 'components/Button'
import EmptyState from 'components/EmptyState'
import Divider from 'elements/Divider'
import Title from 'elements/Title'
import Label from 'elements/Label'
import Checkbox from 'elements/Checkbox'

import { Satellite } from 'svg'

import * as ErrorStyles from '../components/ErrorStyles'
import * as ModalWrappers from '../components/ModalWrappers'
import { getSingleContactErrorData } from '../utils/ModalUtils'

class SingleContactErrorModal extends React.Component {
  constructor (props) {
    super(props)
    this.state = {
      // groups data together into an array of objects.
      // an errors objects structure looks like:
      selectedContactErrors: [],

      // a running count of the numbers of contact errors selected
      // used to determine if the `confirm` button is active
      noSelectedContactErrors: 0
    }

    this.handleOnCancel = this.handleOnCancel.bind(this)
    this.handleKeyDown = this.handleKeyDown.bind(this)
    this.handleSelectingRow = this.handleSelectingRow.bind(this)
    this.handleOnConfirm = this.handleOnConfirm.bind(this)
    this.mapPropsToState = this.mapPropsToState.bind(this)
  }

  UNSAFE_componentWillReceiveProps (nextProps) {
    const prevProps = this.props
    if (nextProps.selectedContact && !R.equals(prevProps.selectedContact, nextProps.selectedContact)) {
      this.mapPropsToState(nextProps.selectedContact, nextProps.crm)
    }
  }

  mapPropsToState = (selectedContact, crm) => {
    if (!selectedContact) {
      return
    }

    const seenErrors = new Set()
    const newSelectedContactErrors = []
    selectedContact
      .get('all_errors')
      .forEach(errMap => {
        const error = errMap.get('error')
        if (!seenErrors.has(error)) {
          const errorMessage = errMap.get('error_message')
          const {
            title, description, clearingDisabled
          } = getSingleContactErrorData(error, errorMessage, crm)

          newSelectedContactErrors.push({
            title, description, error, clearingDisabled, selected: false
          })

          this.setState({
            selectedContactErrors: newSelectedContactErrors
          })

          seenErrors.add(error)
        }
      })
  }

  handleKeyDown = event => {
    const ENTER = 13
    if (event.keyCode === ENTER) {
      return this.handleOnConfirm()
    }

    return false
  }

  handleOnCancel = () => {
    const { onClose } = this.props
    const { selectedContactErrors } = this.state

    // reset all contact errors back to `unselected`
    const deselectedContactErrors = [...selectedContactErrors]
    deselectedContactErrors.forEach(err => err.selected = false)

    // reset number of selected contact errors back to 0 before closing
    this.setState({
      selectedContactErrors: deselectedContactErrors,
      noSelectedContactErrors: 0
    }, onClose)
  }

  handleOnConfirm = event => {
    event.preventDefault()

    const { actions, sequenceId, onClose, selectedContact } = this.props
    const { selectedContactErrors } = this.state

    // get contact id from selected contact
    const contactId = [selectedContact.get('_id')]

    // get all errors from selected contact
    const errorTypes = []
    selectedContactErrors.forEach(errObj => {
      if (errObj.selected) {
        errorTypes.push(errObj.error)
      }
    })

    // reset all contact errors back to `unselected`
    const deselectedContactErrors = [...selectedContactErrors]
    deselectedContactErrors.forEach(err => err.selected = false)

    Promise
      .resolve()
      .then(() => {
        if (errorTypes && errorTypes.length > 0) {
          actions.updateContactErrors(sequenceId, contactId, errorTypes)
          return true
        }

        return false
      })
      .then(result => {
        if (result) {
          this.setState({
            noSelectedContactErrors: 0,
            selectedContactErrors: deselectedContactErrors
          }, () => {
            onClose()
          })
        }
      })
  }

  handleSelectingRow = (idx) => {
    const { selectedContactErrors, noSelectedContactErrors } = this.state
    const newSelectedContactErrors = [...selectedContactErrors]
    if (selectedContactErrors[idx].selected) {
      newSelectedContactErrors[idx].selected = false
      this.setState({
        selectedContactErrors: newSelectedContactErrors,
        noSelectedContactErrors: noSelectedContactErrors - 1
      })
    } else {
      newSelectedContactErrors[idx].selected = true
      this.setState({
        selectedContactErrors: newSelectedContactErrors,
        noSelectedContactErrors: noSelectedContactErrors + 1
      })
    }
  }

  renderErrors () {
    const { isOpen, selectedContact } = this.props
    const { selectedContactErrors } = this.state

    const NO_ERRORS = !selectedContact ||
      (selectedContact && selectedContact.get('all_errors').count() === 0)

    if (NO_ERRORS) {
      return (
        <EmptyState
          icon={<Satellite />}
          title='No Errors Found'
          description="We couldn't find any errors on the contact you have selected, try selecting a different contact"
        />
      )
    } else if (isOpen) {
      return selectedContactErrors.map((contact, idx) => {
        const { title, description, clearingDisabled, selected } = contact

        return (
          <ErrorStyles.ErrorRow key={`er_${idx}`}>
            <ErrorStyles.ErrorRowDivider />
            <ErrorStyles.ErrorRowCell>
              <ErrorStyles.ErrorRowStatus>
                <Checkbox
                  disabled={clearingDisabled}
                  handleClick={() => this.handleSelectingRow(idx)}
                  selected={selected}
                />
                <ErrorStyles.ErrorLabel
                  disabled={clearingDisabled}
                  onClick={() => this.handleSelectingRow(idx)}
                >
                  {title}
                </ErrorStyles.ErrorLabel>
              </ErrorStyles.ErrorRowStatus>
            </ErrorStyles.ErrorRowCell>
            <ErrorStyles.ErrorRowCell>
              <Label align='left'>
                {description}
              </Label>
            </ErrorStyles.ErrorRowCell>
            <ErrorStyles.ErrorRowDivider />
          </ErrorStyles.ErrorRow>
        )
      })
    }
  }

  render () {
    const { noSelectedContactErrors } = this.state
    const { isOpen, selectedContact } = this.props

    return (
      <Modal
        width='fit-content'
        maxWidth='40rem'
        isOpen={isOpen}
        onModalClose={this.handleOnCancel}
      >
        <ModalWrappers.Wrapper
          onKeyDown={this.handleKeyDown}
        >
          <ModalWrappers.InputContainer>
            <Title align='center'>Clear Contact Errors</Title>
            <Label mt='0.5rem' mb='0.5rem' align='center'>
              {`Choose the errors that you'd like to clear ${(selectedContact) ? ('on ' + selectedContact.get('display_name')) : 'from the selected contact'}`}
            </Label>
          </ModalWrappers.InputContainer>
          <Divider />
          <ErrorStyles.ErrorContainer>
            {this.renderErrors()}
          </ErrorStyles.ErrorContainer>
          <Divider />
          <ModalWrappers.ButtonContainer>
            <Button
              mr='0.5rem'
              label='cancel'
              handleClick={this.handleOnCancel}
            />
            <Button
              primary
              label='clear errors'
              handleClick={this.handleOnConfirm}
              disabled={noSelectedContactErrors === 0}
            />
          </ModalWrappers.ButtonContainer>
        </ModalWrappers.Wrapper>
      </Modal>
    )
  }
}

SingleContactErrorModal.propTypes = {
  sequenceId: PropTypes.string,
  isOpen: PropTypes.bool,
  onClose: PropTypes.func,
  onConfim: PropTypes.func,
  actions: PropTypes.object,
  selectedContact: PropTypes.object,
  contactErrors: PropTypes.instanceOf(Immutable.Map),
  crm: PropTypes.object
}

export default SingleContactErrorModal
