import React, { Component } from 'react'
import PropTypes from 'prop-types'
import styled, { withTheme } from 'styled-components'
import Immutable from 'immutable'
import DropDown from 'components/DropDown'

const DropDownContainer = styled.div`
  width: 100%;
  margin-bottom: 0.5rem;
  text-align: right;
`

class SequenceTaskDropdown extends Component {
  render () {
    const {
      sequence,
      selectedStep,
      onOptionChange
    } = this.props

    let options = []
    // options.push({
    //   label: 'Steps',
    //   type: 'header'
    // })
    sequence.getIn(['data', 'steps'])
      .map((step, i) => {
        const stepType = step.get('manual')
        if (stepType) { // only want to show steps that can have pending tasks
          options.push({
            label: `Step ${i + 1} — ${stepType === 'task' ? 'Manual Task' : 'Email Task'}`,
            value: JSON.stringify({
              selectedStep: i
            }),
            index: i
          })
        }
        return stepType
      })
    options = Immutable.List(options)

    let selectedOption
    if (selectedStep || selectedStep === 0) {
      selectedOption = JSON.stringify({ selectedStep })
    }

    return (
      <DropDownContainer>
        <DropDown
          options={options}
          clearable
          controlled
          searchable
          height='3rem'
          placeholder='Filter by step…'
          value={selectedOption}
          onOptionChange={onOptionChange}
          borderColor={this.props.theme.borderColor}
          style={{
            zIndex: 100
          }}
        />
      </DropDownContainer>
    )
  }
}

SequenceTaskDropdown.propTypes = {
  sequence: PropTypes.any,
  theme: PropTypes.any,
  steps: PropTypes.object,
  selectedStep: PropTypes.string,
  onOptionChange: PropTypes.func
}

export default withTheme(SequenceTaskDropdown)
